import logo from "../../../../assets/icons/logo.png";
import userImg from "../../../../assets/images/profilepic.png";
import screenshottest1 from "../../../../assets/images/Screenshottest1.png";
import screenshottest2 from "../../../../assets/images/Screenshottest2.png";
import Screenshotallmenu from "../../../../assets/images/Screenshotallmenunew.png";
import foreignLanguage from "../../../../assets/icons/foreignLanguage.png";
import InputsOfText from "../../../InputFields/InputsOfText";
import { AddLanguagesHooks } from "../../../../screens/Languages/hooks/LanguagesHooks";

const CurriculumPreview = (fieldValues) => {
  console.log(fieldValues, "teticurr");
  const { setShowData, showData } = AddLanguagesHooks();

  return (
    <div className="flex flex-col h-full relative bg-black">
      <div className="absolute  bottom-0 left-0 right-0 z-10 ">
        <img src={Screenshotallmenu} alt="menu" />
      </div>

      <div className="flex flex-col small-bar  overflow-auto pb-14">
        <div className="flex flex-row gap-2 pt-5 px-3 items-center">
          <img className="w-7" src={logo} alt="company logo" />
          <div className="w-24 h-7 bg-slate-50 opacity-40 rounded-3xl"></div>
          <img className="w-7" src={foreignLanguage} alt="language support" />
          <img className="w-8" src={userImg} alt="user avatar" />
        </div>

        <div className="flex flex-row text-center gap-2 pt-3 justify-center w-full font-semibold opacity-70 py-3 ">
          <InputsOfText
            value={fieldValues?.fieldValues?.learn_lbl}
            inputStyle={
              !showData
                ? "text-yellowish w-10 text-[12px] cursor-pointer"
                : "text-slate-200 w-10 text-[12px] cursor-pointer"
            }
            onClick={() => setShowData(false)}
          />
          <InputsOfText
            value={fieldValues?.fieldValues?.yourachievement_lbl}
            inputStyle={
              showData
                ? "text-yellowish w-36 text-[12px] cursor-pointer"
                : "text-slate-200 w-36 text-[12px] cursor-pointer"
            }
            onClick={() => setShowData(true)}
          />
        </div>

        {!showData ? (
          <div>
            <div className="flex flex-col bg-gradient-to-b from-zinc-800 to-black rounded-t-xl pt-3">
              <div className="flex fex-row justify-between items-center px-3">
                <InputsOfText
                  value={fieldValues?.fieldValues?.learn_the_word_with_us_lbl}
                  inputStyle={"text-slate-200 w-40 font-bold text-[12px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.see_all_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>

              <div className="flex justify-center  py-3 ">
                <img src={screenshottest1} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col p-1 pl-2">
            <InputsOfText
              value={fieldValues?.fieldValues?.congratulations_lbl}
              inputStyle={"text-slate-200 w-36 font-semibold text-[14px]"}
            />
            <div className="flex justify-center">
              <img className=" pt-3" src={screenshottest2} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CurriculumPreview;
