import { Fragment, useCallback, useEffect, useState } from "react";
import eye from "../../assets/icons/eye.svg";
import edit from "../../assets/icons/edit.svg";
import ToggleButton from "./ToggleButton";
import { Link } from "react-router-dom";
import redCross from "../../assets/icons/redCross.svg";
import greenTick from "../../assets/icons/greenTick.svg";
import close from "../../assets/icons/close.svg";
import { useNavigate } from "react-router-dom";
import ToggleStar from "./ToggleStar";
import RatingStars from "./RatingStars";
import { Tooltip } from "react-tooltip";
import LoaderSpinner from "./LoaderSpinner";
import { IoArrowBackSharp, IoArrowForwardSharp } from "react-icons/io5";
import _debounce from "lodash/debounce";
import { AiOutlineDelete } from "react-icons/ai";

function CustomTable({
  activeTab,
  headers,
  data,
  width,
  heightDesktop,
  heightMobile,
  reverseTable,
  nthChildWidth,
  nthChildColor,
  nthChildStyle,
  handleToggleClick,
  handleEyeClick,
  handleGreenTick = null,
  handleRedCross = null,
  handleEditClick,
  openGivingHandler,
  testimonialTable,
  notificationTable,
  handleDeleteClick,
  layoutTable,
  showStar = false,
  searchFor = "Data",
  customTabs = null,
  customDataFetch = false,
  customDataFetchLimit = 20,
  reloadData = false,
  setReloadData = () => {},
  customDataFetchFunction = () => {},
  updateCustomCurrentPage = null,
  updateCustomSearchCriteria = null,
  paginationContainerClass = "",
  customTabsClickHandler = null,
}) {
  const navigate = useNavigate();

  const [dataList, setDataList] = useState(customDataFetch ? [] : data);

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(customDataFetch);
  const [searchCriteria, setSearchCriteria] = useState({});

  const totalPageCount = Math.ceil(totalRecords / customDataFetchLimit);

  console.log("page state searchCriteria", searchCriteria);
  console.log("page state search", search);
  console.log("page state data", data);
  console.log("page state dataList", dataList);
  console.log("page state customDataFetch", customDataFetch);

  const fetchCustomData = (page, searchKeys = null) => {
    const actualSearchCriteria = searchKeys ?? searchCriteria;
    console.log(
      "page",
      page,
      "searchKeys",
      searchKeys,
      "searchCriteria",
      searchCriteria,
      "actualSearchCriteria",
      actualSearchCriteria
    );
    setLoading(true);
    customDataFetchFunction(
      page,
      customDataFetchLimit,
      actualSearchCriteria,
      ({ data: resultData, total, pageNum }) => {
        setLoading(false);
        setTotalRecords(total);
        console.log("page check", pageNum);
        if (pageNum !== undefined && pageNum !== page) setCurrentPage(pageNum);
        setDataList(resultData);
      }
    );
  };

  useEffect(() => {
    if (customDataFetch) {
      fetchCustomData(currentPage);
      if (updateCustomCurrentPage) updateCustomCurrentPage(currentPage);
    }
  }, []);

  useEffect(() => {
    if (reloadData) {
      fetchCustomData(currentPage);
      setReloadData(false);
    }
  }, [reloadData]);

  const filteredData = () => {
    if (search.length === 0) {
      return dataList;
    } else {
      const newData = dataList?.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        )
      );

      return newData;
    }
  };

  const updatedData = customDataFetch ? dataList : filteredData();

  const updateSearchCriteria = useCallback(
    _debounce((e) => {
      const searchKeys = {};
      console.log("page searchKeys", searchKeys, e.target.value, headers);
      setSearchCriteria(searchKeys);
      if (updateCustomCurrentPage) updateCustomCurrentPage(1);
      setCurrentPage(1);
      if (e.target.value === "") {
        if (updateCustomSearchCriteria) updateCustomSearchCriteria(searchKeys);
        fetchCustomData(1, searchKeys);
      } else {
        headers?.map((d) => (searchKeys[d?.headerId] = e.target.value));
        if (updateCustomSearchCriteria) updateCustomSearchCriteria(searchKeys);
        fetchCustomData(1, searchKeys);
      }
    }, 400),
    []
  );

  const handleFilterChange = (e) => {
    if (customDataFetch) {
      updateSearchCriteria(e);
    }
    setSearch(e.target.value);
  };

  return (
    <div className="overflow-auto custom-table">
      <div className="flex justify-between w-full">
        <div className="text-white p-2 px-3 w-1/4 border border-white border-opacity-40 rounded-md mt-2">
          <input
            type="text"
            placeholder={"Search " + searchFor}
            className="bg-transparent rounded-md w-full focus:outline-none"
            onChange={handleFilterChange}
          />
        </div>
        {customTabs && (
          <div
            onClick={(e) => {
              if (customTabsClickHandler)
                customTabsClickHandler({
                  e,
                  fetchCustomData,
                  setCurrentPage,
                  setSearchCriteria,
                  searchCriteria,
                });
            }}
          >
            {customTabs}
          </div>
        )}
      </div>
      <div className="md:w-full w-[92vw]">
        {loading ? (
          <div className="w-full flex flex-col h-[64vh] justify-center items-center">
            <LoaderSpinner className="" />
            <p className="text-white font-medium mt-2">Loading data..</p>
          </div>
        ) : (
          <div className="TABLE flex flex-col mt-4 overflow-auto relative w-full remove-scrollbar">
            {/* Header */}
            <div
              className={` HEADER gap-x-4 z-10 ${nthChildWidth} flex items-center text-[12px] font-medium justify-between w-fit min-w-full sticky top-0  ${
                reverseTable
                  ? "text-greyish bg-blackish py-4 px-4"
                  : " text-greyish bg-blackishV2 pb-4"
              } `}
            >
              {headers.map((header, index) => {
                return (
                  <div className={`${width}`} key={"header-item" + header.headerId}>
                    {header.headerName}
                  </div>
                );
              })}
            </div>
            {/* Rows */}

            <div
              className={`ROWS  ${heightDesktop ? heightDesktop : "md:h-[42vh]"} ${
                heightMobile ? heightMobile : ""
              } `}
            >
              {/* new refined code  */}

              {updatedData ? (
                <>
                  {updatedData?.map((row, index) => {
                    return (
                      <Fragment key={"row-item" + index}>
                        {layoutTable ? (
                          <div
                            className={`ROW gap-x-4  ${nthChildWidth} ${nthChildColor} ${nthChildStyle}  flex items-center text-white text-sm font-medium py-4 justify-between w-fit min-w-full ${
                              reverseTable ? "px-4 bg-blackishV2 mb-2 rounded-lg" : ""
                            } `}
                          >
                            {headers.map((header, index) => {
                              switch (header.headerId) {
                                case "actions":
                                  return (
                                    <div
                                      className={`cursor-pointer text-white  flex items-center text-[13px] ${width}`}
                                      key={"action-item" + header.headerId}
                                    >
                                      {testimonialTable === true ? (
                                        row.selected === true ? (
                                          <div className="flex gap-2 items-center">
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                          </div>
                                        ) : activeTab === "Approved" ? (
                                          <div className="flex gap-2 items-center">
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                            {handleRedCross && (
                                              <img
                                                className="cursor-pointer"
                                                src={redCross}
                                                alt="eye"
                                                onClick={() => {
                                                  handleRedCross(row);
                                                }}
                                              />
                                            )}
                                            {showStar && (
                                              <ToggleStar
                                                handleToggleClick={(isActive) => {
                                                  handleToggleClick(row, isActive);
                                                }}
                                                status={row?.status || row?.active ? 1 : 0}
                                              />
                                            )}
                                          </div>
                                        ) : activeTab === "Rejected" ? (
                                          <div className="flex gap-2 items-center">
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                            {handleGreenTick && (
                                              <img
                                                className="cursor-pointer"
                                                src={greenTick}
                                                alt="eye"
                                                onClick={() => {
                                                  handleGreenTick(row);
                                                }}
                                              />
                                            )}
                                            {showStar && (
                                              <ToggleStar
                                                handleToggleClick={(isActive) => {
                                                  handleToggleClick(row, isActive);
                                                }}
                                                status={row?.status || row?.active ? 1 : 0}
                                              />
                                            )}
                                          </div>
                                        ) : (
                                          <div className="flex gap-2 items-center">
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                            {handleGreenTick && (
                                              <img
                                                className="cursor-pointer"
                                                src={greenTick}
                                                alt="eye"
                                                onClick={() => {
                                                  handleGreenTick(row);
                                                }}
                                              />
                                            )}
                                            {handleRedCross && (
                                              <img
                                                className="cursor-pointer"
                                                src={redCross}
                                                alt="eye"
                                                onClick={() => {
                                                  handleRedCross(row);
                                                }}
                                              />
                                            )}
                                            {showStar && (
                                              <ToggleStar
                                                handleToggleClick={(isActive) => {
                                                  handleToggleClick(row, isActive);
                                                }}
                                                status={row?.status || row?.active ? 1 : 0}
                                              />
                                            )}
                                          </div>
                                        )
                                      ) : notificationTable === true ? (
                                        <div className="flex gap-2 items-center">
                                          {handleEyeClick && (
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                          )}
                                          {handleEditClick && (
                                            <img
                                              className="cursor-pointer"
                                              src={edit}
                                              alt="edit"
                                              onClick={() => {
                                                handleEditClick(row);
                                              }}
                                            />
                                          )}
                                          {handleDeleteClick && (
                                            <AiOutlineDelete
                                              className="cursor-pointer text-[#979592] text-lg"
                                              onClick={() => {
                                                handleDeleteClick(row);
                                              }}
                                            />
                                          )}
                                        </div>
                                      ) : layoutTable === true ? (
                                        <div className="flex gap-2 items-center">
                                          {handleEyeClick && (
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                          )}
                                          {handleEditClick && (
                                            <img
                                              className="cursor-pointer"
                                              src={edit}
                                              alt="edit"
                                              onClick={() => {
                                                handleEditClick(row);
                                              }}
                                            />
                                          )}
                                          {row?.static !== "1" && handleDeleteClick && (
                                            <AiOutlineDelete
                                              className="cursor-pointer text-[#979592] text-lg"
                                              onClick={() => {
                                                handleDeleteClick(row);
                                              }}
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div className="flex gap-2 items-center">
                                          {handleToggleClick && (
                                            <ToggleButton
                                              handleToggleClick={(isActive) => {
                                                const result = handleToggleClick(row, isActive);
                                                return result;
                                              }}
                                              status={row?.status || row?.active ? 1 : 0}
                                            />
                                          )}
                                          {handleEyeClick && (
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                          )}
                                          {handleEditClick && (
                                            <img
                                              className="cursor-pointer"
                                              src={edit}
                                              alt="edit"
                                              onClick={() => {
                                                handleEditClick(row);
                                              }}
                                            />
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  );
                                case "status":
                                  return (
                                    <div
                                      className={` ${
                                        row[header.headerId] === 1
                                          ? "bg-[#23E33E] text-[#23E33E]"
                                          : "bg-[#FF544D] text-[#FF544D] "
                                      }  cursor-pointer p-2 flex items-center justify-center bg-opacity-10   text-[13px] rounded-lg ${width}`}
                                      key={header.headerId}
                                    >
                                      {row[header.headerId] === 1 ? "Active" : "In-Active"}
                                    </div>
                                  );
                                case "videostatus":
                                  return (
                                    <>
                                      <div
                                        className={`cursor-pointer p-2 flex items-center justify-center bg-opacity-10   text-[13px] rounded-lg ${width}`}
                                        key={header.headerId}
                                        data-tooltip-id={row[header.headerId] + index}
                                        data-tooltip-content={row["tooltip"] ?? ""}
                                      >
                                        {row[header.headerId]}
                                      </div>
                                      {row["tooltip"] && (
                                        <Tooltip id={row[header.headerId] + index} />
                                      )}
                                    </>
                                  );
                                case "publish":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces`}
                                      key={header.headerId}
                                    >
                                      {row[header.headerId] === "true" ? "Yes" : "No"}
                                      {header.headerId}
                                    </div>
                                  );
                                case "active":
                                  return (
                                    <div
                                      className={` ${
                                        row[header.headerId] === 1
                                          ? "bg-[#23E33E] text-[#23E33E]"
                                          : "bg-[#FF544D] text-[#FF544D] "
                                      }  cursor-pointer p-2 flex items-center justify-center bg-opacity-10   text-[13px] rounded-lg ${width}`}
                                      key={header.headerId}
                                    >
                                      {row[header.headerId] === 1 ? "Active" : "In-Active"}
                                    </div>
                                  );
                                case "userName":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces`}
                                      key={header.headerId}
                                      onClick={() => navigate("/users/099129")}
                                    >
                                      {header.image ? (
                                        <div className="flex items-center gap-4">
                                          <div className="h-8 w-8 rounded-full overflow-hidden">
                                            {row[header?.headerId]?.imgUrl && (
                                              <img
                                                className="h-full w-full object-contain"
                                                src={row[header?.headerId]?.imgUrl}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          {row[header.headerId].name}
                                        </div>
                                      ) : (
                                        <div>{row[header.headerId]}</div>
                                      )}
                                    </div>
                                  );
                                case "language_name":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "layout_name":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "category_name":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "firstName":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "video_title":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "curriculum_name":
                                case "journal_title":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "twg_title":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "userId":
                                  return (
                                    <Link
                                      key={index}
                                      to={"/users/099129"}
                                      // to={`/users/${row[header.headerId].substring(
                                      //   1
                                      // )}`}
                                    >
                                      <div
                                        className={`${width} whitespace-break-spaces ${
                                          header.hyperLink ? "underline" : ""
                                        } `}
                                        key={header.headerId}
                                      >
                                        {row[header.headerId]}
                                      </div>
                                    </Link>
                                  );
                                case "_id":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces ${
                                        header.hyperLink ? "underline" : ""
                                      } `}
                                      key={header.headerId}
                                    >
                                      {row[header.headerId]
                                        ? row[header.headerId]
                                        : "Language Not Assigned"}
                                    </div>
                                  );
                                case "daysToExpiry":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces ${
                                        header.hyperLink ? "underline" : ""
                                      } `}
                                      key={header.headerId}
                                    >
                                      {Math.round(row[header.headerId])}
                                    </div>
                                  );
                                case "payment_gateway_reff":
                                  return (
                                    <div
                                      key={row[header.headerId]}
                                      onClick={() => openGivingHandler(row)}
                                      className="flex items-center gap-4 underline cursor-pointer"
                                    >
                                      <div className="h-8 w-8 rounded-full overflow-hidden">
                                        {row[header.headerId]?.imgUrl && (
                                          <img
                                            className="h-full w-full object-contain"
                                            src={row[header.headerId]?.imgUrl}
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      {row[header.headerId]}
                                    </div>
                                  );
                                case "notificationTitle":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces`}
                                      key={header.headerId}
                                      onClick={() => navigate("/users/099129")}
                                    >
                                      <div className="flex items-center gap-4">
                                        <div className="h-8 w-8 rounded-full overflow-hidden">
                                          <img
                                            className="h-full w-full object-contain"
                                            src={row[header.headerId].imgUrl}
                                            alt=""
                                          />
                                        </div>
                                        {row[header.headerId].title}
                                      </div>
                                    </div>
                                  );
                                default:
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces`}
                                      key={header.headerId}
                                    >
                                      {row[header.headerId]}
                                    </div>
                                  );
                              }
                            })}
                          </div>
                        ) : (
                          <div
                            key={index}
                            className={`ROW gap-x-4  ${nthChildWidth} ${nthChildColor} ${nthChildStyle}  flex items-center text-white text-sm font-medium py-4 justify-between w-fit min-w-full ${
                              reverseTable ? "px-4 bg-blackishV2 mb-2 rounded-lg" : ""
                            } `}
                          >
                            {headers.map((header, index) => {
                              switch (header.headerId) {
                                case "actions":
                                  return (
                                    <div
                                      className={`cursor-pointer text-white  flex items-center text-[13px] ${width}`}
                                      key={"action-items" + header.headerId}
                                    >
                                      {testimonialTable === true ? (
                                        row.selected === true ? (
                                          <div className="flex gap-2 items-center">
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                          </div>
                                        ) : row?.approval_status === 1 ? (
                                          <div className="flex gap-2 items-center">
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                            {handleRedCross && (
                                              <img
                                                className="cursor-pointer"
                                                src={redCross}
                                                alt="eye"
                                                onClick={() => {
                                                  handleRedCross(row);
                                                }}
                                              />
                                            )}
                                            {showStar && (
                                              <ToggleStar
                                                handleToggleClick={(isActive) => {
                                                  handleToggleClick(row, isActive);
                                                }}
                                                status={row?.status || row?.active ? 1 : 0}
                                              />
                                            )}
                                          </div>
                                        ) : row?.approval_status === 2 ? (
                                          <div className="flex gap-2 items-center">
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                            {handleGreenTick && (
                                              <img
                                                className="cursor-pointer"
                                                src={greenTick}
                                                alt="eye"
                                                onClick={() => {
                                                  handleGreenTick(row);
                                                }}
                                              />
                                            )}
                                            {showStar && (
                                              <ToggleStar
                                                handleToggleClick={(isActive) => {
                                                  handleToggleClick(row, isActive);
                                                }}
                                                status={row?.status || row?.active ? 1 : 0}
                                              />
                                            )}
                                          </div>
                                        ) : (
                                          <div className="flex gap-2 items-center">
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                            {handleGreenTick && (
                                              <img
                                                className="cursor-pointer"
                                                src={greenTick}
                                                alt="eye"
                                                onClick={() => {
                                                  handleGreenTick(row);
                                                }}
                                              />
                                            )}
                                            {handleRedCross && (
                                              <img
                                                className="cursor-pointer"
                                                src={redCross}
                                                alt="eye"
                                                onClick={() => {
                                                  handleRedCross(row);
                                                }}
                                              />
                                            )}
                                            {showStar && (
                                              <ToggleStar
                                                handleToggleClick={(isActive) => {
                                                  handleToggleClick(row, isActive);
                                                }}
                                                status={row?.status || row?.active ? 1 : 0}
                                              />
                                            )}
                                          </div>
                                        )
                                      ) : notificationTable === true ? (
                                        <div className="flex gap-2 items-center">
                                          {handleEyeClick && (
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                          )}
                                          {handleEditClick && (
                                            <img
                                              className="cursor-pointer"
                                              src={edit}
                                              alt="edit"
                                              onClick={() => {
                                                handleEditClick(row);
                                              }}
                                            />
                                          )}
                                          {handleDeleteClick && (
                                            <AiOutlineDelete
                                              className="cursor-pointer text-[#979592] text-lg"
                                              onClick={() => {
                                                handleDeleteClick(row);
                                              }}
                                            />
                                          )}
                                        </div>
                                      ) : layoutTable === true ? (
                                        <div className="flex gap-2 items-center">
                                          {handleEyeClick && (
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                          )}
                                          {handleEditClick && (
                                            <img
                                              className="cursor-pointer"
                                              src={edit}
                                              alt="edit"
                                              onClick={() => {
                                                handleEditClick(row);
                                              }}
                                            />
                                          )}
                                          {row?.static !== "1" && handleDeleteClick && (
                                            <AiOutlineDelete
                                              className="cursor-pointer text-[#979592] text-lg"
                                              onClick={() => {
                                                handleDeleteClick(row);
                                              }}
                                            />
                                          )}
                                        </div>
                                      ) : (
                                        <div className="flex gap-2 items-center">
                                          {handleToggleClick && (
                                            <ToggleButton
                                              handleToggleClick={(isActive) => {
                                                const result = handleToggleClick(row, isActive);
                                                return result;
                                              }}
                                              status={row?.status || row?.active ? 1 : 0}
                                            />
                                          )}
                                          {handleEyeClick && (
                                            <img
                                              className="cursor-pointer"
                                              src={eye}
                                              alt="eye"
                                              onClick={() => {
                                                handleEyeClick(row);
                                              }}
                                            />
                                          )}
                                          {handleEditClick && (
                                            <img
                                              className="cursor-pointer"
                                              src={edit}
                                              alt="edit"
                                              onClick={() => {
                                                handleEditClick(row);
                                              }}
                                            />
                                          )}
                                          {handleDeleteClick && (
                                            <AiOutlineDelete
                                              className="cursor-pointer text-[#979592] text-lg"
                                              onClick={() => {
                                                handleDeleteClick(row);
                                              }}
                                            />
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  );
                                case "publish":
                                  return (
                                    <div
                                      className={`${width} text-center whitespace-break-spaces`}
                                      key={header.headerId}
                                    >
                                      {row[header.headerId] === "true" ? "Yes" : "No"}
                                    </div>
                                  );
                                case "videostatus":
                                  return (
                                    <>
                                      <div
                                        className={`cursor-pointer p-2 flex items-center justify-center bg-opacity-10   text-[13px] rounded-lg ${width}`}
                                        key={header.headerId}
                                        data-tooltip-id={row[header.headerId] + index}
                                        data-tooltip-content={row["tooltip"] ?? ""}
                                      >
                                        {row[header.headerId]}
                                      </div>
                                      {row["tooltip"] && (
                                        <Tooltip
                                          id={row[header.headerId] + index}
                                          className="!bg-yellowish !text-black drop-shadow-lg z-40"
                                        />
                                      )}
                                    </>
                                  );
                                case "status":
                                  return (
                                    <div
                                      className={` ${
                                        row[header.headerId] === 1
                                          ? "bg-[#23E33E] text-[#23E33E]"
                                          : "bg-[#FF544D] text-[#FF544D] "
                                      }  cursor-pointer p-2 flex items-center justify-center bg-opacity-10   text-[13px] rounded-lg ${width}`}
                                      key={header.headerId}
                                    >
                                      {row[header.headerId] === 1 ? "Active" : "In-Active"}
                                    </div>
                                  );
                                case "rating":
                                  return <RatingStars rating={parseFloat(row[header.headerId])} />;
                                case "userDetails":
                                  return (
                                    <div className="flex items-center gap-3">
                                      <div
                                        style={{
                                          backgroundImage: `url(${row?.user?.[0]?.profilePicUrl})`,
                                        }}
                                        className="h-10 w-10 bg-black rounded-full bg-cover bg-no-repeat"
                                      ></div>
                                      <p className="text-white font-bold">
                                        {row?.user?.[0]?.firstName}
                                      </p>
                                    </div>
                                  );
                                case "active":
                                  return (
                                    <div
                                      className={` ${
                                        row[header.headerId] === 1
                                          ? "bg-[#23E33E] text-[#23E33E]"
                                          : "bg-[#FF544D] text-[#FF544D] "
                                      }  cursor-pointer p-2 flex items-center justify-center bg-opacity-10   text-[13px] rounded-lg ${width}`}
                                      key={header.headerId}
                                    >
                                      {row[header.headerId] === 1 ? "Active" : "In-Active"}
                                    </div>
                                  );
                                case "userName":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces`}
                                      key={header.headerId}
                                      onClick={() => navigate("/users/099129")}
                                    >
                                      {header.image ? (
                                        <div className="flex items-center gap-4">
                                          <div className="h-8 w-8 rounded-full overflow-hidden">
                                            {row[header?.headerId]?.imgUrl && (
                                              <img
                                                className="h-full w-full object-contain"
                                                src={row[header?.headerId]?.imgUrl}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          {row[header.headerId].name}
                                        </div>
                                      ) : (
                                        <div>{row[header.headerId]}</div>
                                      )}
                                    </div>
                                  );
                                case "language_name":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "layout_name":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "category_name":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "firstName":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "video_title":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "curriculum_name":
                                case "journal_title":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "twg_title":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces cursor-pointer hover:underline`}
                                      key={header.headerId}
                                      onClick={() => handleEyeClick(row)}
                                    >
                                      <div>{row[header.headerId]}</div>
                                    </div>
                                  );
                                case "userId":
                                  return (
                                    <Link
                                      key={index}
                                      to={"/users/099129"}
                                      // to={`/users/${row[header.headerId].substring(
                                      //   1
                                      // )}`}
                                    >
                                      <div
                                        className={`${width} whitespace-break-spaces ${
                                          header.hyperLink ? "underline" : ""
                                        } `}
                                        key={header.headerId}
                                      >
                                        {row[header.headerId]}
                                      </div>
                                    </Link>
                                  );
                                case "_id":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces ${
                                        header.hyperLink ? "underline" : ""
                                      } `}
                                      key={header.headerId}
                                    >
                                      {row[header.headerId]
                                        ? row[header.headerId]
                                        : "Language Not Assigned"}
                                    </div>
                                  );
                                case "daysToExpiry":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces ${
                                        header.hyperLink ? "underline" : ""
                                      } `}
                                      key={header.headerId}
                                    >
                                      {Math.round(row[header.headerId])}
                                    </div>
                                  );
                                case "payment_gateway_reff":
                                  return (
                                    <div
                                      key={row[header.headerId]}
                                      onClick={() => openGivingHandler(row)}
                                      className="flex items-center gap-4 underline cursor-pointer"
                                    >
                                      <div className="h-8 w-8 rounded-full overflow-hidden">
                                        {row[header.headerId]?.imgUrl && (
                                          <img
                                            className="h-full w-full object-contain"
                                            src={row[header.headerId]?.imgUrl}
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      {row[header.headerId]}
                                    </div>
                                  );
                                case "notificationTitle":
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces`}
                                      key={header.headerId}
                                      onClick={() => navigate("/users/099129")}
                                    >
                                      <div className="flex items-center gap-4">
                                        <div className="h-8 w-8 rounded-full overflow-hidden">
                                          <img
                                            className="h-full w-full object-contain"
                                            src={row[header.headerId].imgUrl}
                                            alt=""
                                          />
                                        </div>
                                        {row[header.headerId].title}
                                      </div>
                                    </div>
                                  );
                                default:
                                  return (
                                    <div
                                      className={`${width} whitespace-break-spaces`}
                                      key={header.headerId}
                                    >
                                      {row[header.headerId]?.length > 35
                                        ? row[header.headerId]?.substring(0, 35) + "..."
                                        : row[header.headerId]}
                                    </div>
                                  );
                              }
                            })}
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                </>
              ) : (
                <div className="flex justify-center items-center h-full w-full">
                  <p className="font-bold text-sm text-greyish ">Loading...</p>
                </div>
              )}

              {updatedData?.length === 0 && (
                <div className="flex justify-center items-center h-full w-full">
                  <p className="font-bold text-sm text-greyish ">No Data Found</p>
                </div>
              )}
              {/* ends here  */}
            </div>
          </div>
        )}
        {customDataFetch && (
          <div
            className={`w-full text-white flex justify-between items-center mt-3 border border-white border-opacity-40 text-opacity-80 py-3 px-5 rounded-md ${paginationContainerClass}`}
          >
            <div>
              Showing {currentPage}-{totalPageCount}
            </div>
            <div className="flex items-center">
              <div className="px-5 flex items-center">Total Rows: {totalRecords}</div>
              <div className="border-x pl-5">
                The page you're on
                <select
                  className="bg-white bg-opacity-80 mx-2 text-black rounded-md px-2 py-1.5 outline-none"
                  value={currentPage}
                  onChange={(e) => {
                    e.stopPropagation();
                    fetchCustomData(Number(e.target.value));
                    setCurrentPage(Number(e.target.value));
                    if (updateCustomCurrentPage) updateCustomCurrentPage(Number(e.target.value));
                  }}
                >
                  {Array.from({ length: totalPageCount }, (_, index) => (
                    <option key={"page-item" + index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>
              <div className="pl-2 flex items-center gap-3">
                <button
                  value="prev"
                  className={`border border-white border-opacity-40 py-2 px-2.5 rounded-md text-white text-opacity-80 transition-all duration-300 ${
                    currentPage === 1
                      ? "bg-white/5 hover:bg-none"
                      : "hover:bg-white hover:text-black"
                  }`}
                  disabled={currentPage === 1}
                  onClick={() => {
                    if (currentPage > 1) {
                      fetchCustomData(currentPage - 1);
                      setCurrentPage((prev) => {
                        if (updateCustomCurrentPage) updateCustomCurrentPage(prev - 1);
                        return prev - 1;
                      });
                    }
                  }}
                >
                  <IoArrowBackSharp className="" />
                </button>
                <button
                  value="next"
                  className={`border border-white border-opacity-40 py-2 px-2.5 rounded-md text-white text-opacity-80 transition-all duration-300 ${
                    currentPage === totalPageCount
                      ? "bg-white/5 hover:bg-none"
                      : "hover:bg-white hover:text-black"
                  }`}
                  disabled={currentPage === totalPageCount}
                  onClick={() => {
                    if (currentPage < totalPageCount) {
                      fetchCustomData(currentPage + 1);
                      setCurrentPage((prev) => {
                        if (updateCustomCurrentPage) updateCustomCurrentPage(prev + 1);
                        return prev + 1;
                      });
                    }
                  }}
                >
                  <IoArrowForwardSharp />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomTable;
