import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import GlobalLoaderReducer from "./store/GlobalLoader/GlobalLoaderReducer";
import UsersReducer from "./store/Users/UsersReducer";
import { webportalAppRootSaga } from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Auth0Provider } from "@auth0/auth0-react";
import LanguageReducer from "./store/Languages/LanguageReducer";
import BibleReducer from "./store/Bible/BibleReducer";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const reducers = combineReducers({
  globalLoader: GlobalLoaderReducer,
  user: UsersReducer,
  language: LanguageReducer,
  bible: BibleReducer,
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducers, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(webportalAppRootSaga);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUDIENCE_URL,
    }}
    scope="openid email"
  >
    <Provider store={store}>
      <GoogleOAuthProvider clientId="490413240407-jrqrupdrda8gimvrbt3o3bvs4qtcjgv2.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </Auth0Provider>
  // </React.StrictMode>
);
