import logo from "../../../../assets/icons/logo.png";
import userImg from "../../../../assets/images/profilepic.png";
import Screenshotallmenu from "../../../../assets/images/Screenshotallmenu.png";
import Screenshotmygiv from "../../../../assets/images/Screenshotmygiv.png";

import InputsOfText from "../../../InputFields/InputsOfText";
import { AddLanguagesHooks } from "../../../../screens/Languages/hooks/LanguagesHooks";

const MyGivingsPreview = (fieldValues) => {
  console.log(fieldValues, "teti");
  const { setShowData, showData } = AddLanguagesHooks();

  return (
    <div className="flex flex-col h-full relative bg-zinc-900 ">
      <div className="bg-zinc-800 absolute  bottom-0 left-0 right-0   rounded-t-xl  z-10 ">
        <img src={Screenshotallmenu} />
      </div>

      <div className="flex flex-col small-bar  overflow-auto pb-14">
        <div className="flex flex-row gap-2 pt-8 px-3 items-center">
          <img className="w-10 " src={logo} />
          <div className="w-24 h-7 bg-slate-50 opacity-40 rounded-3xl"></div>
          <img className="w-8 " src={logo} />
          <img className="w-8 " src={userImg} />
        </div>

        <div className="flex flex-col justify-center items-center gap-3 pt-8">
          <InputsOfText
            value={fieldValues?.fieldValues?.my_givings_lbl}
            inputStyle={"text-yellowish w-40 font-bold text-[20px]"}
          />

          <img src={Screenshotmygiv} />
        </div>
      </div>
    </div>
  );
};

export default MyGivingsPreview;
