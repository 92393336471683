import HorizontalTile from "../../components/universal/HorizontalTile";
import avatar from "../../assets/icons/squareCard/story1.png";
import avatar2 from "../../assets/icons/StoryCircles/story1.png";

export const tabsTitleArray = ["Users", "Testimonies", "Givings"];
export const tabsContentObject = {
  Users: (
    <>
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
    </>
  ),
  Testimonies: (
    <>
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
    </>
  ),
};
export const videosHeaders = [
  {
    headerName: "Video Title",
    headerId: "videoTitle",
  },
  {
    headerName: "Description",
    headerId: "description",
  },
  {
    headerName: "Views",
    headerId: "views",
  },
];

export const videosData = [
  {
    videoTitle: "Prayers",
    description: "We become what we thick ab...",
    views: "223",
  },
  {
    videoTitle: "History of God",
    description: "We become what we thick ab...",
    views: "331",
  },
  {
    videoTitle: "Believe in God",
    description: "We become what we thick ab...?",
    views: "331",
  },
  {
    videoTitle: "History of God",
    description: "We become what we thick ab...",
    views: "331",
  },
  {
    videoTitle: "Believe in God",
    description: "We become what we thick ab...?",
    views: "331",
  },
  {
    videoTitle: "Prayers",
    description: "We become what we thick ab...",
    views: "14431",
  },
  {
    videoTitle: "11 Stages of Life",
    description: "We become what we thick ab...",
    views: "4311",
  },
];
export const allVideosHeaders = [
  {
    headerName: "Video Title",
    headerId: "video_title",
  },
  {
    headerName: "Description",
    headerId: "video_description",
  },
  {
    headerName: "Last Modified Date",
    headerId: "lastUpdatedDate",
    date: true,
  },
  // {
  //   headerName: "Views",
  //   headerId: "views",
  // },
  {
    headerName: "Actions",
    headerId: "actions",
  },
];
export const allVideosData = [
  {
    title: "Book of John",
    description: "We become what we thick ab...",
    date: "12/03/2023",
    views: "122",
  },
  {
    title: "Parabies",
    description: "We become what we thick ab...",
    date: "12/03/2023",
    views: "122",
  },
  {
    title: "Jesus Speaks",
    description: "We become what we thick ab...",
    date: "12/03/2023",
    views: "122",
  },
  {
    title: "Terminator",
    description: "We become what we thick ab...",
    date: "12/03/2023",
    views: "122",
  },
];

// add languages const

export const langArray = [
  { value: "tamil", label: "Tamil" },
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
];
export const LoginInFields = [
  {
    label: "Login",
    placeholder: "Login Id",
  },
  {
    label: "Email or Phone",
    placeholder: "Email or Phone",
  },
  {
    label: "Password",
    placeholder: "Password",
  },
];
export const SignUpFields = [
  {
    label: "Sign Up",
    placeholder: "Sign Up",
  },
  {
    label: "Email or Phone",
    placeholder: "Email or Phone",
  },
];
