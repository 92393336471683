import * as actionTypes from "../ActionTypes";

const INITIAL = {
  bibleLanguage: "eng",
  bibleVersion: "ENGESV",
  versionDetails: {},
  bookChapterDetails: [],
  bookChapterDetailsLoading: true,
};

const bibleReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case actionTypes.SET_BIBLE_LANGUAGE:
      return {
        ...state,
        bibleLanguage: action.payload,
      };
    case actionTypes.SET_BIBLE_VERSION:
      return {
        ...state,
        bibleVersion: action.payload,
      };
    case actionTypes.SET_VERSION_DETAILS:
      return {
        ...state,
        versionDetails: action.payload,
      };
    case actionTypes.SET_BOOK_CHAPTER_DETAILS:
      return {
        ...state,
        bookChapterDetails: action.payload,
      };
    case actionTypes.SET_BOOK_CHAPTER_DETAILS_LOADING:
      return {
        ...state,
        bookChapterDetailsLoading: action.payload,
      };
    default:
      return state;
  }
};

export default bibleReducer;
