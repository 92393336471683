import React, { useEffect, useState } from "react";
import SearchBar from "../../components/universal/SearchBar";
import SquareCard from "../../components/universal/SquareCard";
import userIcon from "../../assets/icons/userIcon.svg";
import CustomCarousel from "../../components/universal/CustomCarousel";
import {
  APP_USER,
  CMS_ADMIN,
  CURRICULUM_ADMIN,
  languagesHeaders,
  SUPER_ADMIN,
  TESTIMONIAL_ADMIN,
} from "./Constants";
import SingleTab from "../../components/universal/SingleTab";
import CustomTable from "../../components/universal/CustomTable";
import { AiOutlinePlus, AiOutlineReload } from "react-icons/ai";
import { Link } from "react-router-dom";
import UsersHooks from "./hooks/UsersHooks";
import CustomLineChart from "../../components/charts/lineChart/CustomLineChart";
import Navbar from "../../components/universal/Navbar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCMSUserAction } from "../../store/Users/UsersAction";
import { downloadExcel } from "../../utils/DownloadExcel";
import { subDays } from "date-fns";
import { languageDashboardAction } from "../../store/Languages/LanguageAction";
import { FaDownload } from "react-icons/fa6";
import ReactECharts from "echarts-for-react";
import DateFilter from "../../components/universal/DateFilter";

function Users() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { value, onChangeSearchBar, fetchAllUsers, reloadData, setReloadData } = UsersHooks();
  // tab clicking state for language table
  const [activeTab, setActiveTab] = useState("All");
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [allUserData, setAllUserData] = useState([]);
  const [userSignUpVsTimePeriod, setUserSignUpVsTimePeriod] = useState({ xAxis: [], yAxis: [] });
  const [filterRange, setFilterRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  console.log("allUserData", allUserData);
  console.log("userSignUpVsTimePeriod", userSignUpVsTimePeriod);

  const fetchReportsData = ({ startDate, endDate }) => {
    setLoading(true);
    const languagePromise = new Promise((resolve, reject) => {
      dispatch(
        languageDashboardAction({
          apiPayloadRequest: {
            startDate: startDate ?? null,
            endDate: endDate ?? null,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const data = res?.data;
              setUserSignUpVsTimePeriod({
                xAxis: data
                  .filter((item) => item.dash === "Users signup vs time period")[0]
                  .data?.map((d) => ({ ...d, value: d?._id?.date })),
                yAxis: data
                  .filter((item) => item.dash === "Users signup vs time period")[0]
                  .data?.map((d) => ({ ...d, value: d?.count })),
              });
            }
            resolve();
          },
        })
      );
    });

    Promise.all([languagePromise]).finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchReportsData({
      startDate: filterRange?.[0]?.startDate,
      endDate: filterRange?.[0]?.endDate,
    });
  }, [filterRange]);

  useEffect(() => {
    dispatch(
      getCMSUserAction({
        callback: (res) => {
          if (res?.type === 1) {
            setUserData(res?.data);
            setAllUserData(res?.data);
          }
        },
      })
    );
  }, []);

  console.log("userData", userData);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium flex items-center gap-3">
                Users
                <AiOutlineReload
                  className={`cursor-pointer text-2xl text-yellowish ${
                    loading ? "animate-spin" : ""
                  }`}
                  onClick={() =>
                    fetchReportsData({
                      startDate: filterRange?.[0]?.startDate,
                      endDate: filterRange?.[0]?.endDate,
                    })
                  }
                />
              </p>
              <div className="flex gap-4 items-center">
                <DateFilter range={filterRange} setRange={setFilterRange} />
                <Link to={"/users/all"}>
                  <button className=" text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                    See All Users
                  </button>
                </Link>
                <Link to={"/users/add"}>
                  <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
                    <AiOutlinePlus /> Add CMS User
                  </button>
                </Link>
              </div>
            </div>

            <div className="flex gap-4 flex-wrap">
              <SquareCard
                heading={"Total No. of Users"}
                icon={userIcon}
                iconStyle={"h-11 w-11"}
                trail={""}
                info={allUserData?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(allUserData?.map((d) => ({
                      "TWG ID": d?.twgId,
                      "First Name": d?.firstName,
                      "Last Name": d?.lastName,
                      Mobile: d?.mobile,
                      Email: d?.email,
                    })) ?? []),
                  ]);
                }}
                handleOnClick={() => {
                  navigate("/users/all");
                }}
              />
              <SquareCard
                heading={"Total No. of Active Users"}
                icon={userIcon}
                iconStyle={"h-11 w-11"}
                info={allUserData?.filter((d) => d?.active === 1)?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(allUserData
                      ?.filter((d) => d?.active === 1)
                      ?.map((d) => ({
                        "TWG ID": d?.twgId,
                        "First Name": d?.firstName,
                        "Last Name": d?.lastName,
                        Mobile: d?.mobile,
                        Email: d?.email,
                      })) ?? []),
                  ]);
                }}
                handleOnClick={() => {
                  navigate("/users/all?filter=1");
                }}
              />
              <SquareCard
                heading={"Total No. of In-Active Users"}
                icon={userIcon}
                iconStyle={"h-11 w-11"}
                info={allUserData?.filter((d) => d?.active !== 1)?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(allUserData
                      ?.filter((d) => d?.active !== 1)
                      ?.map((d) => ({
                        "TWG ID": d?.twgId,
                        "First Name": d?.firstName,
                        "Last Name": d?.lastName,
                        Mobile: d?.mobile,
                        Email: d?.email,
                      })) ?? []),
                  ]);
                }}
                handleOnClick={() => {
                  navigate("/users/all?filter=0");
                }}
              />
              {/* <SquareCard
                heading={"Total No. of Recent Login"}
                icon={userIcon}
                iconStyle={"h-11 w-11"}
                info={"0"}
                trail={""}
                percentage={"0%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              /> */}
              {/* <SquareCard
                heading={"Total No. of Views"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={"29k"}
                trail={"Views"}
                percentage={"+3.32%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              /> */}
            </div>
            <div className="grid grid-rows-1 md:grid-cols-6 grid-cols-1 flex-1 h-full gap-x-4 gap-y-4 my-4">
              <div className="md:col-span-3 row-span-1 bg-blackishV2 px-6 py-4 rounded-2xl flex justify-between">
                <div className="text-white w-full">
                  <div className="block relative">
                    <button
                      className="text-yellowish absolute top-2 right-2 z-50"
                      onClick={() => {
                        downloadExcel([
                          ...(userSignUpVsTimePeriod?.xAxis?.map((d) => ({
                            Date: d?.value,
                            "Total Signups": d?.count,
                          })) ?? []),
                        ]);
                      }}
                    >
                      <FaDownload />
                    </button>
                    <ReactECharts
                      className="py-4 bg-blackish mt-3"
                      option={{
                        title: {
                          text: "Users Signup vs Time Period",
                          textStyle: {
                            color: "#fff",
                          },
                        },
                        tooltip: {
                          trigger: "axis",
                          position: function (pt) {
                            return [pt[0], "10%"];
                          },
                        },
                        xAxis: {
                          type: "category",
                          boundaryGap: false,
                          axisTick: { show: false },
                          data: [...userSignUpVsTimePeriod?.xAxis],
                        },
                        yAxis: {
                          type: "value",
                          boundaryGap: [0, "100%"],
                          axisLabel: { show: false },
                          axisTick: { show: false },
                          splitLine: { show: false },
                        },
                        dataZoom: [
                          {
                            type: "inside",
                            start: 0,
                            end: 10,
                          },
                          {
                            start: 0,
                            end: 10,
                          },
                        ],
                        series: [
                          {
                            name: "Users SignUp",
                            type: "line",
                            symbol: "none",
                            smooth: true,
                            sampling: "lttb",
                            itemStyle: {
                              color: "rgb(239,188,81)",
                            },
                            lineStyle: {
                              width: 4,
                            },
                            areaStyle: {
                              color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                  {
                                    offset: 0,
                                    color: "rgb(239,188,81)",
                                  },
                                  {
                                    offset: 1,
                                    color: "rgba(239,188,81, 0.2)",
                                  },
                                ],
                                global: false,
                              },
                            },
                            data: [...userSignUpVsTimePeriod?.yAxis],
                          },
                        ],
                      }}
                      notMerge={true}
                      lazyUpdate={true}
                      theme={"halloween"}
                      onChartReady={() => console.log("chart loaded")}
                    />
                  </div>
                </div>
              </div>
              <div className="md:col-span-3 row-span-1 bg-blackishV2 px-6 py-4 rounded-2xl flex flex-col gap-4">
                <div className="flex justify-between md:items-center md:flex-row flex-col gap-4">
                  <p className="text-lg font-semibold text-white">Users List</p>
                  <div className="md:w-[20vw] w-full">
                    <CustomCarousel
                      rows={1}
                      cols={2}
                      gapX={12}
                      gapY={0}
                      // easier to pass this array like this rather than separating it
                      completeSliders={[
                        <SingleTab activeTab={activeTab} tab={"All"} />,
                        <SingleTab activeTab={activeTab} tab={"Super Admin"} />,
                        <SingleTab activeTab={activeTab} tab={"CMS Admin"} />,
                        <SingleTab activeTab={activeTab} tab={"Testimonial Admin"} />,
                        <SingleTab activeTab={activeTab} tab={"Curriculum Admin"} />,
                        <SingleTab activeTab={activeTab} tab={"App Users"} />,
                      ]}
                      numberOfCardsShownInSingleSlide={2}
                      handleCardOnClick={(card) => {
                        setActiveTab(card.props.tab);
                        switch (card.props.tab) {
                          case "All":
                            setUserData(allUserData);
                            break;
                          case "Super Admin":
                            setUserData(allUserData?.filter((d) => d?.roleCode === SUPER_ADMIN));
                            break;
                          case "CMS Admin":
                            setUserData(allUserData?.filter((d) => d?.roleCode === CMS_ADMIN));
                            break;
                          case "Testimonial Admin":
                            setUserData(
                              allUserData?.filter((d) => d?.roleCode === TESTIMONIAL_ADMIN)
                            );
                            break;
                          case "Curriculum Admin":
                            setUserData(
                              allUserData?.filter((d) => d?.roleCode === CURRICULUM_ADMIN)
                            );
                            break;
                          case "App Users":
                            setUserData(
                              allUserData?.filter((d) => d?.roleCode === APP_USER || !d?.roleCode)
                            );
                            break;
                          // add other tabs here
                          default:
                            break;
                        }
                      }}
                      topNext={"top-3"}
                      topPrev={"top-9"}
                    />
                  </div>
                </div>
                <div>
                  {/* custom table with different data as per the selected carousel  */}
                  <CustomTable
                    searchFor="Users"
                    data={[]}
                    headers={languagesHeaders}
                    customDataFetch={true}
                    customDataFetchFunction={fetchAllUsers}
                    reloadData={reloadData}
                    setReloadData={setReloadData}
                    paginationContainerClass="text-xs"
                    width={"w-24"}
                    handleEyeClick={(row) => {
                      console.log(row);
                      navigate("/users/" + row?._id, {
                        state: { _id: row?._id, userAuthId: row?.userAuthId },
                      });
                    }}
                    heightDesktop={"h-[32vh]"}
                    // giving extra width to 2nd column
                    nthChildWidth={"[&>*:nth-child(2)]:w-48"}
                    nthChildColor={
                      "[&>*:nth-child(2)]:text-white [&>*:nth-child(2)]:text-opacity-50 [&>*:nth-child(3)]:text-white [&>*:nth-child(3)]:text-opacity-50"
                    }
                    nthChildStyle={"[&>*:nth-child(2)]:h-5 [&>*:nth-child(2)]:overflow-hidden"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
