import React from 'react'
import logo from '../../../../assets/icons/logo.png'
import Input from '../../../InputFields/Input'
import SignInButton from '../../../Buttons/SignInButton'

const ForgotPswdPreview = ({fieldValues }) => {
  
    return (
        <div className='relative h-full'>

        <div className='flex flex-col gap-7 justify-center  items-center'>

            <div className='flex flex-col items-center justify-between pt-8'>
                <img className='opacity-40' src={logo} alt="" />
                <p className='text-4xl font-semibold text-yellowish pt-2'>{fieldValues?.forgotpassword_lbl }</p>
                
            </div>

            <div className='flex flex-col gap-2 items-center w-full'>
                <Input value={fieldValues?.emailorphone_lbl} />
            </div>

            <div className='w-full px-5 flex flex-col gap-3'>
                <SignInButton Label={fieldValues?.resetPassword_btn} style={'bg-yellow-300 '} />
                <SignInButton Label={fieldValues?.cancel_btn}  style={'border-[1px] border-yellowish text-yellowish opacity-60'} />

            </div>

        </div>

    </div>
    )
}

export default ForgotPswdPreview
