import React, { useState } from "react";

function CustomTabs({ tabsTitleArray, tabsContentObject, profileView }) {
  const [activeTab, setActiveTab] = useState(tabsTitleArray[0]);

  const handleTabSuper = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className="tab-list overflow-x-auto flex gap-2 w-full ">
        {tabsTitleArray.map((tab, i) => (
          <div
            onClick={() => handleTabSuper(tab)}
            key={i + 100}
            className={`${
              activeTab === tab ? "text-blackish bg-yellowish" : "text-greyish"
            } px-4 rounded-md flex whitespace-nowrap justify-center items-center h-[30px] focus:bg-yellowish focus:text-blackish focus:bg-opacity-10 hover:bg-yellowish cursor-pointer font-semibold text-[12px] leading-3 hover:text-blackish`}
          >
            {tab}
          </div>
        ))}
      </div>

      <div className="outlet">
        {Object.keys(tabsContentObject).map((key, i) => {
          if (tabsTitleArray.includes(key)) {
            return (
              <div key={i + 2}>
                {activeTab === key ? (
                  <div>
                    <div className="relative">{tabsContentObject[key]}</div>
                  </div>
                ) : null}
              </div>
            );
          }
          return null;
        })}
      </div>
    </>
  );
}

export default CustomTabs;
