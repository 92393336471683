import SearchBar from "../../components/universal/SearchBar";
import { twgHeaders } from "./Constants";
import CustomTable from "../../components/universal/CustomTable";
import Modal from "react-modal";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import tIcon from "../../assets/icons/transactionIcon.svg";
import { AllTwgHooks } from "./hooks/TwgHooks";
import Navbar from "../../components/universal/Navbar";
import { Link, useNavigate } from "react-router-dom";
import ConfirmToast from "../../components/universal/ConfirmToast";
import { confirmAlert } from "react-confirm-alert";

function AllTwg() {
  // search bar component state with handler
  const {
    value,
    onChangeSearchBar,
    openGivingHandler,
    modalIsOpen,
    setModalIsOpen,
    transactionInfo,
    handleDelete,
    reloadData,
    setReloadData,
    fetchAllTwg,
  } = AllTwgHooks();

  const navigate = useNavigate();

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium">Today With God - Daily Devotional</p>
              <div className="flex gap-4">
                {/* <button className="flex items-center gap-16 text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                  All
                  <HiOutlineFilter />
                </button> */}

                <Link to={"/twg/add"}>
                  <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
                    <AiOutlinePlus /> Add Promise
                  </button>
                </Link>
              </div>
            </div>
            <div className="grid grid-cols-3 grid-flow-row gap-x-4">
              <div className=" md:col-span-3 col-span-1">
                <CustomTable
                  searchFor="Promises"
                  openGivingHandler={(row) => openGivingHandler(row)}
                  heightDesktop={"h-[72vh]"}
                  width={"w-24"}
                  // giving extra width to 2nd column
                  nthChildWidth={"[&>*:nth-child(2)]:w-40 [&>*:nth-child(5)]:w-32"}
                  nthChildStyle={"text-white text-opacity-50"}
                  data={[]}
                  headers={twgHeaders}
                  customDataFetch={true}
                  customDataFetchFunction={fetchAllTwg}
                  reloadData={reloadData}
                  setReloadData={setReloadData}
                  reverseTable={true}
                  handleToggleClick={(row, isActive) => {
                    console.log(isActive, row);
                  }}
                  handleEyeClick={(row) => {
                    console.log(row);
                    navigate("/twg/add", { state: { _id: row?._id } });
                  }}
                  handleEditClick={(row) => {
                    console.log(row);
                    navigate("/twg/add", { state: { _id: row?._id } });
                  }}
                  handleDeleteClick={(row) => {
                    const options = {
                      customUI: ({ onClose }) => {
                        return (
                          <ConfirmToast
                            title="Are you sure?"
                            message="You want to delete this promise?"
                            onClose={() => onClose()}
                            onSuccess={() => {
                              handleDelete(row);
                              onClose();
                            }}
                          />
                        );
                      },
                      closeOnEscape: true,
                      closeOnClickOutside: true,
                      keyCodeForClose: [8, 32],
                      overlayClassName: "!bg-gray-500 !bg-opacity-75",
                    };

                    confirmAlert(options);
                  }}
                />
              </div>
              {/* <div className="col-span-1 bg-blackishV2 rounded-2xl">
                <CustomTabsV2
                  tabHeight={"h-[60px]"}
                  tabsTitleArray={["Preview", "Stats"]}
                  tabsContentObject={{
                    Preview: (
                      <div className="mt-8 flex items-center justify-center w-full text-white">
                        No Data Found...
                      </div>
                    ),
                    Stats: (
                      <div className="p-4">
                        <div className="p-1 flex justify-between items-center w-full">
                          <div className="flex items-center gap-8">
                            <p className="font-semibold text-white">Givings</p>
                          </div>
                          <div className="md:w-[15vw] w-full">
                            <div className="md:block hidden">
                              <CustomCarousel
                                rows={1}
                                cols={3}
                                gapX={0}
                                gapY={0}
                                // easier to pass this array like this rather than separating it
                                completeSliders={[
                                  <SingleTab activeTab={userTab} tab={"Week"} />,
                                  <SingleTab activeTab={userTab} tab={"Month"} />,
                                  <SingleTab activeTab={userTab} tab={"Year"} />,
                                ]}
                                numberOfCardsShownInSingleSlide={3}
                                handleCardOnClick={(card) => {
                                  setUserTab(card.props.tab);
                                  switch (card.props.tab) {
                                    case "Month":
                                      // change data for trending tab
                                      break;
                                    // add other tabs here
                                    default:
                                      break;
                                  }
                                }}
                                topNext={"hidden"}
                                topPrev={"hidden"}
                              />
                            </div>
                            <div className="md:hidden block">
                              <CustomCarousel
                                rows={1}
                                cols={2}
                                gapX={0}
                                gapY={0}
                                // easier to pass this array like this rather than separating it
                                completeSliders={[
                                  <SingleTab activeTab={userTab} tab={"Week"} />,
                                  <SingleTab activeTab={userTab} tab={"Month"} />,
                                  <SingleTab activeTab={userTab} tab={"Year"} />,
                                ]}
                                numberOfCardsShownInSingleSlide={2}
                                handleCardOnClick={(card) => {
                                  setUserTab(card.props.tab);
                                  switch (card.props.tab) {
                                    case "Month":
                                      // change data for trending tab
                                      break;
                                    // add other tabs here
                                    default:
                                      break;
                                  }
                                }}
                                topNext={"hidden"}
                                topPrev={"hidden"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="block">
                          <CustomLineChart
                            customHeight="300"
                            customWidth="450"
                            data={[100, 300, 250, 350, 200, 280]}
                          />
                        </div>
                        <div className="p-2">
                          <p className="p-2 text-white text-opacity-50 text-sm">
                            Total No. of Videos Liked and Shared
                          </p>
                          <div className="p-2 flex justify-between">
                            <img className="h-6 w-6" src={like} alt="" />
                            <p className="text-white text-sm">38,891 Likes</p>
                          </div>
                          <div className="p-2 flex justify-between">
                            <img className="h-6 w-6" src={share} alt="" />
                            <p className="text-white text-sm">891 Shared</p>
                          </div>
                        </div>
                      </div>
                    ),
                  }}
                />
              </div> */}
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="Custom Modal"
            className="custom-modal absolute z-[60] bg-black bg-opacity-70 h-screen w-screen"
            ariaHideApp={false}
          >
            <>
              <div className="MODAL-BODY h-full flex items-center justify-center">
                <div className="min-h-[64vh] h-fit bg-blackish w-[28vw] flex flex-col rounded-2xl p-4">
                  <div className="h-fit flex justify-between items-center">
                    <p className="text-lg w-full font-semibold text-white mb-0">
                      Transaction Details
                    </p>
                    <button className="w-full text-right" onClick={() => setModalIsOpen(false)}>
                      <AiOutlineClose className="ml-auto text-white" />
                    </button>
                  </div>
                  <div className="flex-1 pt-8">
                    <div className="w-full flex justify-center">
                      <img src={tIcon} className="w-full h-28" alt="" />
                    </div>
                    <p className="text-white w-full text-center text-[15px] text-medium">
                      Successful!!
                    </p>
                    <div className="flex flex-col items-center gap-4 pt-8">
                      <div className="w-full justify-between items-center flex">
                        <p className="text-greyish text-[12px]">Transaction ID</p>
                        <p className="text-white text-[12px] font-medium">
                          {transactionInfo?.transactionId}
                        </p>
                      </div>
                      <div className="w-full justify-between items-center flex">
                        <p className="text-greyish text-[12px]">Date & Time</p>
                        <p className="text-white text-[12px] font-medium">
                          {transactionInfo?.date}
                        </p>
                      </div>
                      <div className="w-full justify-between items-center flex">
                        <p className="text-greyish text-[12px]">Country</p>
                        <p className="text-white text-[12px] font-medium">
                          {transactionInfo?.country}
                        </p>
                      </div>
                      <div className="w-full justify-between items-center flex">
                        <p className="text-greyish text-[12px]">Payment Type</p>
                        <p className="text-white text-[12px] font-medium">
                          {transactionInfo?.paymentType}
                        </p>
                      </div>
                      <div className="w-full justify-between items-center flex">
                        <p className="text-greyish text-[12px]">Payment Source</p>
                        <p className="text-white text-[12px] font-medium">{transactionInfo?.MOP}</p>
                      </div>
                      <div className="w-full justify-between items-center flex">
                        <p className="text-greyish text-[12px]">Amount</p>
                        <p className="text-white text-[12px] font-medium">
                          {transactionInfo?.amount}
                        </p>
                      </div>
                      <button className="mt-4 mb-4 flex items-center gap-2 text-[13px] text-yellowish border px-8 py-2 border-yellowish bg-blackish rounded-xl">
                        Refund Payment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AllTwg;
