import { useEffect, useState } from "react";
import {
  validateDropdown,
  validateEmail,
  validateText,
} from "../../../components/InputFields/ValidationFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  getCMSUserAction,
  getRolesAction,
  saveCMSUserAction,
  updateCMSUserAction,
} from "../../../store/Users/UsersAction";
import Toastiy from "../../../components/universal/Toastiy";
import { useNavigate } from "react-router-dom";
import { readLanguageAction } from "../../../store/Languages/LanguageAction";
import { setShowNavigationAlertAction } from "../../../store/GlobalLoader/GlobalLoaderAction";

export const AddUserHooks = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };
  const plus = "+";
  const [profilePic, setProfilePic] = useState("");

  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    country: "",
    dob: "",
    password: "",
    confirmPassword: "",
  };

  const [userValues, setUserValues] = useState(initialValues);
  const [currentData, setCurrectData] = useState();
  const [roles, setRoles] = useState([]);
  const [languages, setLanguages] = useState([]);

  console.log("userValues1", userValues);
  // states for forms here

  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [role, setRole] = useState("");
  //handlers for forms here

  useEffect(() => {
    return () => setShowNavigationAlert(false);
  }, []);

  const setShowNavigationAlert = (value) => {
    dispatch(setShowNavigationAlertAction(value));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setShowNavigationAlert(true);
  };

  const handleSelectChange = (event) => {
    const { value, name } = event.target;
    if (name === "roleCode") {
      setUserValues((prev) => ({
        ...prev,
        roleCode: value,
      }));
    } else if (name === "gender") {
      setUserValues((prev) => ({
        ...prev,
        gender: value,
      }));
    } else if (name === "language_prefference") {
      setUserValues((prev) => ({
        ...prev,
        language_prefference: value,
      }));
    }
    setShowNavigationAlert(true);
  };

  const handleDateChange = (value) => {
    setUserValues((prev) => ({
      ...prev,
      dob: value,
    }));
    setShowNavigationAlert(true);
  };

  const handlePhoneChange = (value) => {
    setUserValues((prev) => ({
      ...prev,
      phone: value,
    }));
    setShowNavigationAlert(true);
  };

  const onSave = (e) => {
    const data = {
      firstName: userValues?.firstName,
      lastName: userValues?.lastName,
      gender: userValues?.gender,
      phone: userValues?.phone,
      email: userValues?.email,
      country: userValues?.country,
      language_prefference: userValues?.language_prefference,
      roleCode: userValues?.roleCode,
      password: userValues?.password,
      dob: userValues?.dob,
      profilePicUrl: profilePic,
    };

    console.log("data", data);

    if (id !== undefined) {
      dispatch(
        updateCMSUserAction({
          apiPayloadRequest: {
            userAuthId: userValues?.userAuthId,
            lastName: userValues?.lastName,
            firstName: userValues?.firstName,
            gender: userValues?.gender,
            mobile: userValues?.phone,
            email: userValues?.email,
            country: userValues?.country,
            language_prefference: userValues?.language_prefference,
            roleCode: userValues?.roleCode,
            dob: userValues?.dob,
            profilePicUrl: profilePic,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("User Updated Successfully!", 1);
              setShowNavigationAlert(false);
            } else {
              Toastiy("Failed to update user details");
            }
          },
        })
      );
    } else {
      console.log("userValues", userValues);
      if (userValues?.password !== userValues?.confirmPassword) {
        Toastiy("Password do not match");
        return;
      }
      dispatch(
        saveCMSUserAction({
          apiPayloadRequest: data,
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("User Saved Successfully!", 1);
              setShowNavigationAlert(false);
              setTimeout(() => navigate("/users/all"), 500);
            } else {
              Toastiy("Failed to save user details");
            }
          },
        })
      );
    }
  };
  const onCancel = (e) => {};

  const UserFields = [
    {
      type: "text",
      label: "First Name",
      placeholder: "Enter first name",
      htmlFor: "firstName",
      name: "firstName",
      value: userValues.firstName,
      handleInputChange: handleInputChange,
      validateFunction: validateText,
    },
    {
      type: "text",
      label: "Last Name",
      placeholder: "Enter last name",
      htmlFor: "lastName",
      name: "lastName",
      value: userValues.lastName,
      handleInputChange: handleInputChange,
      validateFunction: validateText,
    },
    {
      type: "text",
      label: "Set Password",
      placeholder: "Set your Password",
      htmlFor: "password",
      name: "password",
      value: userValues.password,
      handleInputChange: handleInputChange,
      validateFunction: validateText,
    },
    {
      type: "text",
      label: "Confirm Password",
      placeholder: "Confirm your Password",
      htmlFor: "password",
      name: "confirmPassword",
      value: userValues.confirmPassword,
      handleInputChange: handleInputChange,
      validateFunction: validateText,
    },
    {
      type: "text",
      label: "Email ID",
      placeholder: "Enter Email ID",
      htmlFor: "email",
      name: "email",
      value: userValues.email,
      handleInputChange: handleInputChange,
      validateFunction: validateEmail,
    },
    {
      type: "phone",
      label: "Phone",
      placeholder: "Enter Phone Number",
      htmlFor: "phone",
      name: "phone",
      value: userValues.phone,
      handleInputChange: handlePhoneChange,
      validateFunction: validateText,
    },
    {
      type: "image",
      name: "profilePic",
      value: profilePic,
      handleInputChange: setProfilePic,
      validateFunction: validateText,
    },
    {
      type: "dropdown",
      label: "Role",
      placeholder: "Select role",
      options: roles,
      htmlFor: "roleCode",
      name: "roleCode",
      defaultValue: "placeholder",
      value: userValues.roleCode,
      handleInputChange: handleSelectChange,
      validateFunction: validateDropdown,
    },
    {
      type: "dropdown",
      label: "Gender",
      placeholder: "Select gender",
      options: [
        { value: "Male", roleName: "Male", shortCode: "Male" },
        { value: "Female", roleName: "Female", shortCode: "Female" },
      ],
      htmlFor: "gender",
      name: "gender",
      defaultValue: "placeholder",
      value: userValues.gender,
      handleInputChange: handleSelectChange,
      validateFunction: validateDropdown,
    },
    {
      type: "text",
      label: "Country",
      placeholder: "Enter Country Name",
      htmlFor: "country",
      name: "country",
      value: userValues.country,
      handleInputChange: handleInputChange,
      validateFunction: validateText,
    },
    {
      type: "dropdown",
      label: "Language Preference",
      placeholder: "Select language",
      defaultValue: "placeholder",
      options: languages,
      name: "language_prefference",
      htmlFor: "language_prefference",
      value: userValues.language_prefference,
      handleInputChange: handleSelectChange,
      validateFunction: validateDropdown,
    },
    {
      type: "date",
      label: "Date of Birth",
      placeholder: "Select Date",
      htmlFor: "dob",
      name: "dob",
      value: userValues.dob,
      handleInputChange: handleDateChange,
      validateFunction: validateText,
    },
  ];

  const UserFields2 = [
    {
      type: "text",
      label: "First Name",
      placeholder: "Enter first name",
      htmlFor: "firstName",
      name: "firstName",
      value: userValues.firstName,
      handleInputChange: handleInputChange,
      validateFunction: validateText,
    },
    {
      type: "text",
      label: "Last Name",
      placeholder: "Enter last name",
      htmlFor: "lastName",
      name: "lastName",
      value: userValues.lastName,
      handleInputChange: handleInputChange,
      validateFunction: validateText,
    },
    {
      type: "text",
      label: "Email ID",
      placeholder: "Enter Email ID",
      htmlFor: "email",
      name: "email",
      value: userValues.email,
      handleInputChange: handleInputChange,
      validateFunction: validateEmail,
    },
    {
      type: "phone",
      label: "Mobile",
      placeholder: "Enter Phone Number",
      htmlFor: "phone",
      name: "phone",
      value: userValues.phone,
      handleInputChange: handlePhoneChange,
      validateFunction: validateText,
    },
    {
      type: "image",
      name: "profilePic",
      value: profilePic,
      handleInputChange: setProfilePic,
      validateFunction: validateText,
    },
    {
      type: "dropdown",
      label: "Role",
      placeholder: "Select role",
      options: roles,
      htmlFor: "roleCode",
      name: "roleCode",
      defaultValue: "placeholder",
      value: userValues.roleCode,
      handleInputChange: handleSelectChange,
      validateFunction: validateDropdown,
    },
    {
      type: "dropdown",
      label: "Gender",
      placeholder: "Select gender",
      options: [
        { value: "Male", roleName: "Male", shortCode: "Male" },
        { value: "Female", roleName: "Female", shortCode: "Female" },
      ],
      htmlFor: "gender",
      name: "gender",
      defaultValue: "placeholder",
      value: userValues.gender,
      handleInputChange: handleSelectChange,
      validateFunction: validateDropdown,
    },
    {
      type: "text",
      label: "Country",
      placeholder: "Enter Country Name",
      htmlFor: "country",
      name: "country",
      value: userValues.country,
      handleInputChange: handleInputChange,
      validateFunction: validateText,
    },
    {
      type: "dropdown",
      label: "Language Preference",
      placeholder: "Select language",
      defaultValue: "placeholder",
      options: languages,
      name: "language_prefference",
      htmlFor: "language_prefference",
      value: userValues.language_prefference,
      handleInputChange: handleSelectChange,
      validateFunction: validateDropdown,
    },
    {
      type: "date",
      label: "Date of Birth",
      placeholder: "Select Date",
      htmlFor: "dob",
      name: "dob",
      value: userValues.dob,
      handleInputChange: handleDateChange,
      validateFunction: validateText,
    },
  ];

  useEffect(() => {
    dispatch(
      getRolesAction({
        callback: (res) => {
          if (res?.type === 1) {
            setRoles(res?.data?.map((d) => ({ ...d, value: d?.r, shortCode: d?.r })));
          }
        },
      })
    );
    dispatch(
      readLanguageAction({
        callback: (res) => {
          console.log("res", res);
          if (res?.type === 1) {
            setLanguages(
              res?.data?.map((d) => ({
                roleName: d?.language_name,
                value: d?.language_name,
                shortCode: d?.language_code,
              }))
            );
          }
        },
      })
    );

    if (id !== undefined) {
      dispatch(
        getCMSUserAction({
          apiPayloadRequest: {
            _id: id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const data = res?.data.filter((item) => item._id === id)[0];
              const dob =
                data?.dob && new Date(data?.dob)?.toString() !== "Invalid Date" ? data?.dob : "";

              setUserValues({
                firstName: data?.firstName,
                lastName: data?.lastName,
                gender: data?.gender,
                phone: data?.mobile,
                email: data?.email,
                country: data?.country,
                profilePicUrl: data?.profilePicUrl,
                language_prefference: data?.language_prefference,
                roleCode: data?.roleCode,
                dob: dob,
                password: data?.password,
                userAuthId: data?.userAuthId,
              });
              setProfilePic(data?.profilePicUrl);

              setCurrectData({
                firstName: data?.firstName,
                lastName: data?.lastName,
                gender: data?.gender,
                phone: data?.mobile,
                email: data?.email,
                country: data?.country,
                profilePicUrl: data?.profilePicUrl,
                language_prefference: data?.language_prefference,
                roleCode: data?.roleCode,
                dob: dob,
                password: data?.password,
              });
            }
          },
        })
      );
    }
  }, []);

  return {
    value,
    onChangeSearchBar,
    UserFields,
    UserFields2,
    onSave,
    onCancel,
  };
};
