import { all } from "redux-saga/effects";
import DashboardWatcherSaga from "./Dashboard/DashboardWatcherSaga";
import LanguagesWatcherSaga from "./Languages/LanguageWatcherSaga";
import LoginAndRegisterWatcherSaga from "./LoginAndRegister/LoginAndRegisterWatcherSaga";
import ProfileWatcherSaga from "./Profile/ProfileWatcherSaga";
import LayoutWatcherSaga from "./Layout/LayoutWatcherSaga";
import TWGWatcherSaga from "./TWG/TWGWatcherSaga";
import UsersWatcherSaga from "./Users/UsersWatcherSaga";
import NotificationWatcherSaga from "./Notifications/NotificationWatcherSaga";
import GivingsWatcherSaga from "./Givings/GivingsWatcherSaga";
import TestimonyWatcherSaga from "./Testimony/TestimonyWatcherSaga";
import BibleWatcherSaga from "./Bible/BibleWatcherSaga";

export function* webportalAppRootSaga() {
  yield all([
    DashboardWatcherSaga(),
    LanguagesWatcherSaga(),
    LoginAndRegisterWatcherSaga(),
    ProfileWatcherSaga(),
    LayoutWatcherSaga(),
    TWGWatcherSaga(),
    UsersWatcherSaga(),
    NotificationWatcherSaga(),
    GivingsWatcherSaga(),
    TestimonyWatcherSaga(),
    BibleWatcherSaga(),
  ]);
}
