import { useState } from "react";
import SearchBar from "../../components/universal/SearchBar";
import JournalsHooks, { AddJournalsHooks } from "./hooks/JournalsHooks";
import Navbar from "../../components/universal/Navbar";
import { Link } from "react-router-dom";
import AddJournalFields from "../../components/journals/AddJournalFields";

function AddJournals() {
  // search bar component state with handler
  const { value, onChangeSearchBar } = JournalsHooks();
  const [loading, setLoading] = useState(false);

  return (
    <div className="App bg-[#191919] relative h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex justify-end">
              <Link to={"/journals/all"}>
                <button className=" text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                  See All Journals
                </button>
              </Link>
            </div>
            <AddJournalFields setLoading={setLoading} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddJournals;
