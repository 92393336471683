import { useState } from "react";
import { deleteJournalAction, readJournalAction } from "../../../store/Languages/LanguageAction";
import { useDispatch } from "react-redux";
import Toastiy from "../../../components/universal/Toastiy";

const JournalsHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export default JournalsHooks;

export const AllJournalsHooks = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [reloadData, setReloadData] = useState(false);

  const fetchAllJournals = (page, limit, searchCriteria = {}, callbackFn) => {
    dispatch(
      readJournalAction({
        apiPayloadRequest: {
          page: page,
          limit: limit,
          searchCriteria: searchCriteria,
        },
        callback: (Data) => {
          if (Data?.type === 1) {
            if (callbackFn)
              callbackFn({
                data: Data?.data?.map((d) => ({ ...d, views: d?.views ?? 0 })),
                total: Data?.totalRecords ?? 0,
              });
          } else if (callbackFn) callbackFn({ data: [], total: Data?.totalRecords ?? 0 });
        },
      })
    );
  };

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const handleDeleteItem = (_id) => {
    dispatch(
      deleteJournalAction({
        apiPayloadRequest: {
          _id: _id,
        },
        callback: (res) => {
          if (res?.type === 1) {
            setReloadData(true);
            Toastiy("Journal Deleted Successfully!", res?.type);
          } else {
            Toastiy("Journal Failed To Delete!");
          }
        },
      })
    );
  };

  return {
    value,
    onChangeSearchBar,
    handleDeleteItem,
    reloadData,
    setReloadData,
    fetchAllJournals,
  };
};
