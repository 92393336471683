import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AboutTWGInitialState,
  CurriculumInitialState,
  FavouritesInitialState,
  GivingsInitialState,
  HelpAndSupportAccordianInitialState,
  HelpAndSupportInitialState,
  LoginInFields,
  LoginInitialState,
  MenuInitialState,
  MyDownloadInitialState,
  MyGivingsInitialState,
  MyProfileInitialState,
  achievementInitialState,
  forgotPasswordInitialState,
  homeInitialState,
  johnInitialState,
  promisesInitialState,
  signUpInitialField,
  videoLikedInitialState,
} from "../Constants";
import langPreview1 from "../../../assets/images/langPreview1.png";
import {
  readLanguageAction,
  translateLanguageAction,
  ReadLanguageSignUpAction,
  deleteLanguageSignUpAction,
  readLanguageAboutTWGAction,
  readLanguageCurriculumAction,
  readLanguageFavouritesAction,
  readLanguageForgotPasswordAction,
  readLanguageGivingsAction,
  readLanguageHelpAndSupportAccordianAction,
  readLanguageHelpAndSupportAction,
  readLanguageHomeDataAction,
  readLanguageMenuAction,
  readLanguageMyDownloadsAction,
  readLanguageMyGivingsAction,
  readLanguageMyProfileAction,
  readLanguageTestimoniesAction,
  saveLanguageAboutTWGAction,
  saveLanguageCurriculumAction,
  saveLanguageFavouritesAction,
  saveLanguageForgotPasswordAction,
  saveLanguageGivingsAction,
  saveLanguageHelpAndSupportAccordianAction,
  saveLanguageHelpAndSupportAction,
  saveLanguageHomeDataAction,
  saveLanguageLoginAction,
  saveLanguageMenuAction,
  saveLanguageMyDownloadsAction,
  saveLanguageMyGivingsAction,
  saveLanguageMyProfileAction,
  saveLanguageSignUpAction,
  saveLanguageTestimoniesAction,
  updateLanguageAboutTWGAction,
  updateLanguageCurriculumAction,
  updateLanguageFavouritesAction,
  updateLanguageForgotPasswordAction,
  updateLanguageGivingsAction,
  updateLanguageHelpAndSupportAccordianAction,
  updateLanguageHelpAndSupportAction,
  updateLanguageHomeDataAction,
  updateLanguageMenuAction,
  updateLanguageMyDownloadsAction,
  updateLanguageMyGivingsAction,
  updateLanguageMyProfileAction,
  updateLanguageSignUpAction,
  updateLanguageTestimoniesAction,
  saveLanguageAction,
  activeOrInactiveLanguageAction,
  updateLanguageLoginAction,
  updateLanguagePromisesDataAction,
  saveLanguagePromisesDataAction,
  languageDashboardAction,
  deleteLanguageHelpAndSupportAccordianAction,
  deleteLanguageAction,
} from "../../../store/Languages/LanguageAction";
import Toastiy from "../../../components/universal/Toastiy";
import { tr } from "translate-google/languages";
import { setShowNavigationAlertAction } from "../../../store/GlobalLoader/GlobalLoaderAction";
import { reject } from "lodash";

const { languages } = require("google-cloud-translation-languages");

const LanguagesHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export default LanguagesHooks;

export const AllLanguagesHooks = () => {
  const [value, setValue] = useState("");
  const [reloadData, setReloadData] = useState(false);

  const dispatch = useDispatch();
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const deleteLanguage = (id) => {
    dispatch(
      deleteLanguageAction({
        apiPayloadRequest: { _id: id },
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Language Deleted Successfully", res?.type);
            setReloadData(true);
          } else {
            Toastiy("Failed to Deleted Language!!", res?.type);
          }
        },
      })
    );
  };

  const handleActiveInActiveToggle = (id, status) => {
    dispatch(
      activeOrInactiveLanguageAction({
        apiPayloadRequest: { _id: id, status: status },
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Language Status Successfully Changed", res?.type);
            setReloadData(true);
          } else {
            Toastiy("Language Status Successfully Changed", res?.type);
          }
        },
      })
    );
  };
  const fetchAllLangauges = (page, limit, searchCriteria = {}, callbackFn) => {
    console.log(
      "page",
      page,
      "limit",
      limit,
      "searchCriteria",
      searchCriteria,
      "callbackFn",
      callbackFn
    );
    dispatch(
      readLanguageAction({
        apiPayloadRequest: {
          page: page,
          limit: limit,
          searchCriteria: searchCriteria,
        },
        callback: (Data) => {
          if (Data?.type === 1) {
            if (callbackFn)
              callbackFn({
                data: Data?.data?.map((d) => ({
                  ...d,
                  status: Number(d?.status ?? 0),
                  views: d?.views ?? 0,
                })),
                total: Data?.totalRecords ?? 0,
              });
          } else if (callbackFn) callbackFn({ data: [], total: Data?.totalRecords ?? 0 });
        },
      })
    );
  };

  return {
    value,
    onChangeSearchBar,
    fetchAllLangauges,
    reloadData,
    setReloadData,
    handleActiveInActiveToggle,
    deleteLanguage,
  };
};

export const AddLanguagesHooks = (data) => {
  const [showAccordionModal, setShowAccordionModal] = useState(false);
  const [isAccordionEdit, setIsAccordionEdit] = useState(false);
  const [currentLanguageData, setCurrentLanguageData] = useState([]);
  const [enLanguageData, setEnLanguageData] = useState([]);
  const [targetLanguage, setTargetLanguage] = useState("English - en");
  const [value, setValue] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const [forceEditorUpdate, setForceEditorUpdate] = useState(false);

  const LoginInitialState = {
    loginId_lbl: "Log In",
    email_phone_lbl: "Email or Phone",
    password_lbl: "Password",
    forgot_Password_lbl: "Forgot Password",
    continue_btn: "Continue",
    continue_with_google_btn: "Continue With Google",
    continue_with_facebook_btn: "Continue With Facebook",
    continue_with_apple_btn: "Continue With Apple",
    login_to_cotinue_lbl: "Login to Continue Label",
    go_back_home_btn: "Go Back Home Button",
    cancel_btn: "Cancel Button",
  };

  const signUpInitialField = {
    signup_lbl: "Sign Up",
    phone_lbl: "Phone",
    emailid_lbl: "Email ID",
    password_lbl: "Password",
    repeatePassword_lbl: "Repeat Password",
    termsandcond_lbl: "I agree to the Terms & Conditions and the Privacy Policy",
    createAccount_btn: "Create Account",
  };

  const forgotPasswordInitialState = {
    forgotpassword_lbl: "Email id",
    emailorphone_lbl: "Email or Phone",
    resetPassword_btn: "Reset Password",
    cancel_btn: "Cancel",
  };

  const homeInitialState = {
    welcome_lbl: "Welcome",
    todaywithgod_lbl: "Today With God",
    pick_where_you_are_left_lbl: "Pickup where you Left!",
    top10_lbl: "Top 10",
    seeall_btn: "see All",
    who_is_jesus_lbl: "Who is jesus?",
    parables_lbl: "Parables",
    jesus_speaks_lbl: "Jesus Speaks?",
    promises_lbl: "Promises",
    testimony_of_the_day_lbl: "Testimony of the day",
    more_videos_lbl: "more videos",
    support_us_lbl: "Support Us",
    support_btn: "Support",
    support_feedback_message: "Support Feedback",
    need_prayer_lbl: "Need Prayer?",
    watchnow: "Watch Now",
    readnow: "Read Now",
    footer_copyright: "Copyright TWG",
    testimony_videos_lbl: "Testimony Videos",
    how_you_rate_lbl: "How do you rate the overall App",
    what_most_you_liked_lbl: "What did you like the most",
    videos_category_lbl: "Videos Category Label",
    curriculum_category_lbl: "Curriculum Category Label",
    testimonies_category_lbl: "Testimonies Category Label",
    twg_category_lbl: "TWG Category Label",
    category_lbl: "Category Label",
    what_explore_next_lbl: "What would you like to explore next",
    type_here_placeholder_lbl: "Type Here Placeholder",
    send_feedback_btn: "Send Feedback Button",
    healing_category_lbl: "Healing Category Label",
    salvation_category_lbl: "Salvation Category Label",
    general_category_lbl: "General Category Label",
    prayer_submit_btn: "Prayer Submit Button",
    subject_lbl: "Subject label",
    prayer_request_lbl: "Prayer Request Label",
    type_request_placeholder_lbl: "Type Request Placeholder",
    prayer_feedback_message: "Prayer Feedback Message",
    search_lbl: "Search",
    want_to_quit_lbl: "Are you sure you want to quit this page",
    cancel_btn: "Cancel",
    ok_btn: "Ok",
    preferred_language_lbl: "Preferred Language",
    privacy_policy_description: "Privacy Policy Description",
  };

  const accordionInitialState = {
    panel_title: "",
    panel_description: "",
  };

  const [currAccordion, setCurrAccordion] = useState(accordionInitialState);
  const [activeTab, setActiveTab] = useState("Log In");
  const [activeTabData, setActiveTabData] = useState(LoginInFields);
  const [activeTabsList, setActiveTabsList] = useState({});
  const [langPreview, setLangPreview] = useState(langPreview1);
  const [loginFieldValues, setloginFieldValues] = useState(LoginInitialState);

  const [signUpfieldValues, setSignUpfieldValues] = useState(signUpInitialField);

  const [forgotPasswordValues, setForgotPasswordValues] = useState(forgotPasswordInitialState);

  const [homePageValues, setHomePageValues] = useState(homeInitialState);

  const [promisesValues, setPromisesValues] = useState(promisesInitialState);

  const [testimonies, setTestimonies] = useState(johnInitialState);

  const [achievementValues, setAchievementValues] = useState(achievementInitialState);

  const [videoLikedValues, setVideoLikedValues] = useState(videoLikedInitialState);

  const [curriculumValues, setCurriculumValues] = useState(CurriculumInitialState);

  const [favouriteValues, setFavouriteValues] = useState(FavouritesInitialState);

  const [givingsValues, setGivingsValues] = useState(GivingsInitialState);

  const [menuValues, setMenuValues] = useState(MenuInitialState);

  const [myProfileValues, setMyProfileValues] = useState(MyProfileInitialState);

  const [myDownloadsValues, setMyDownloadsValues] = useState(MyDownloadInitialState);

  const [myGivingsValues, setMyGivingsValues] = useState(MyGivingsInitialState);

  const [helpAndSupportValues, setHelpAndSupportValues] = useState(HelpAndSupportInitialState);

  const [aboutTWGValues, setAboutTWGValues] = useState(AboutTWGInitialState);

  const [showData, setShowData] = useState(false);

  const [showAdd, setShowAdd] = useState(false);

  const [languageStats, setLanguageStats] = useState(null);

  // Remove This an use "arrayOfValues"
  const [currentState, setCurrentState] = useState({
    "Log In": {
      state: loginFieldValues,
      setState: setloginFieldValues,
    },
    "Sign Up": {
      state: signUpfieldValues,
      setState: setSignUpfieldValues,
    },
    "Forgot Password": {
      state: forgotPasswordValues,
      setState: setForgotPasswordValues,
    },
    "Home page": {
      state: homePageValues,
      setState: setHomePageValues,
    },
    Promises: {
      state: promisesValues,
      setState: setPromisesValues,
    },
    Curriculum: {
      state: curriculumValues,
      setState: setCurriculumValues,
    },
    Favourites: {
      state: favouriteValues,
      setState: setFavouriteValues,
    },
    Givings: {
      state: givingsValues,
      setState: setGivingsValues,
    },
    Menu: {
      state: menuValues,
      setState: setMenuValues,
    },
    "My Profile": {
      state: myProfileValues,
      setState: setMyProfileValues,
    },
    "My Downloads": {
      state: myDownloadsValues,
      setState: setMyDownloadsValues,
    },
    Testimonies: {
      state: testimonies,
      setState: setTestimonies,
    },
    "My Givings": {
      state: myGivingsValues,
      setState: setMyGivingsValues,
    },
    "Help and Support": {
      state: helpAndSupportValues,
      setState: setHelpAndSupportValues,
    },
    "About TWG": {
      state: aboutTWGValues,
      setState: setAboutTWGValues,
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    return () => setShowNavigationAlert(false);
  }, []);

  const setShowNavigationAlert = (value) => {
    dispatch(setShowNavigationAlertAction(value));
  };

  useEffect(() => {
    if (data && data?.targetLanguage) {
      setTargetLanguage(data?.targetLanguage);
    }
  }, []);

  useEffect(() => {
    const languageCode = targetLanguage.split(" - ");
    console.log("targetLanguage", targetLanguage);
    if (
      languages?.filter(
        (item) => item.name === languageCode[0] && item.language === languageCode[1]
      )?.length !== 0
    ) {
      readAllLanguages(languageCode[1]);
      dispatch(
        languageDashboardAction({
          apiPayloadRequest: { language_code: languageCode[1] },
          callback: (res) => {
            if (res?.type === 1) {
              setLanguageStats(res?.data);
              setForceEditorUpdate(true);
            } else {
              setLanguageStats(null);
            }
          },
        })
      );
    }
  }, [targetLanguage]);

  useEffect(() => {
    if (isUpdated === true) {
      const languageCode = targetLanguage.split(" - ");
      if (
        languages?.filter(
          (item) => item.name === languageCode[0] && item.language === languageCode[1]
        )?.length !== 0
      ) {
        readAllLanguages(languageCode[1]);
        setIsUpdated(false);
      }
    }
  }, [isUpdated]);

  const handleAccordionInput = (event) => {
    const { value, name } = event.target;
    setCurrAccordion((prev) => ({ ...prev, [name]: value }));
    setShowNavigationAlert(true);
  };

  const readAllLanguages = (code) => {
    console.log("read readAllLanguages", code);
    dispatch(
      readLanguageAction({
        apiPayloadRequest: { language_code: code },
        callback: (res) => {
          if (res?.type === 1) {
            if (res?.data?.length) {
              setCurrentLanguageData(res?.data);
              setShowAdd(false);
              if (code === "en") {
                setEnLanguageData(res?.data);
              }
            } else {
              readAllNotFoundData();
            }
          } else {
            readAllNotFoundData();
          }
        },
      })
    );
  };

  const readAllNotFoundData = () => {
    setShowAdd(true);
    setCurrentLanguageData([]);
    setloginFieldValues(LoginInitialState);
    setSignUpfieldValues(signUpInitialField);
    setForgotPasswordValues(forgotPasswordInitialState);
    setHomePageValues(homeInitialState);
    setPromisesValues(promisesInitialState);
    setTestimonies(johnInitialState);
    // setAchievementValues(achievementInitialState);
    // setVideoLikedValues(videoLikedInitialState);
    setCurriculumValues(CurriculumInitialState);
    setFavouriteValues(FavouritesInitialState);
    setGivingsValues(GivingsInitialState);
    setMenuValues(MenuInitialState);
    setMyProfileValues(MyProfileInitialState);
    setMyDownloadsValues(MyDownloadInitialState);
    setMyGivingsValues(MyGivingsInitialState);
    setHelpAndSupportValues(HelpAndSupportInitialState);
    setAboutTWGValues(AboutTWGInitialState);
  };

  useEffect(() => {
    if (currentLanguageData?.length !== 0) {
      if (currentLanguageData[0]?.login_meta) {
        setloginFieldValues(currentLanguageData[0]?.login_meta);
      } else {
        setloginFieldValues(LoginInitialState);
      }
      if (currentLanguageData[0]?.signup_meta) {
        setSignUpfieldValues(currentLanguageData[0]?.signup_meta);
      } else {
        setSignUpfieldValues(signUpInitialField);
      }
      if (currentLanguageData[0]?.forgotpassword_meta) {
        setForgotPasswordValues(currentLanguageData[0]?.forgotpassword_meta);
      } else {
        setForgotPasswordValues(forgotPasswordInitialState);
      }
      if (currentLanguageData[0]?.home_meta) {
        setHomePageValues(currentLanguageData[0]?.home_meta);
      } else {
        setHomePageValues(homeInitialState);
      }
      if (currentLanguageData[0]?.promises_meta) {
        setPromisesValues(currentLanguageData[0]?.promises_meta);
      } else {
        setPromisesValues(promisesInitialState);
      }
      if (currentLanguageData[0]?.testimonies) {
        setTestimonies(currentLanguageData[0]?.testimonies);
      } else {
        setTestimonies(johnInitialState);
      }
      if (currentLanguageData[0]?.curricullum_meta) {
        setCurriculumValues(currentLanguageData[0]?.curricullum_meta);
      } else {
        setCurriculumValues(CurriculumInitialState);
      }
      if (currentLanguageData[0]?.my_favourites) {
        setFavouriteValues(currentLanguageData[0]?.my_favourites);
      } else {
        setFavouriteValues(FavouritesInitialState);
      }
      if (currentLanguageData[0]?.givings) {
        setGivingsValues(currentLanguageData[0]?.givings);
      } else {
        setGivingsValues(GivingsInitialState);
      }
      if (currentLanguageData[0]?.menus) {
        setMenuValues(currentLanguageData[0]?.menus);
      } else {
        setMenuValues(MenuInitialState);
      }
      if (currentLanguageData[0]?.my_profile) {
        setMyProfileValues(currentLanguageData[0]?.my_profile);
      } else {
        setMyProfileValues(MyProfileInitialState);
      }
      if (currentLanguageData[0]?.my_downloads) {
        setMyDownloadsValues(currentLanguageData[0]?.my_downloads);
      } else {
        setMyDownloadsValues(MyDownloadInitialState);
      }
      if (currentLanguageData[0]?.my_givings) {
        setMyGivingsValues(currentLanguageData[0]?.my_givings);
      } else {
        setMyGivingsValues(MyGivingsInitialState);
      }
      if (currentLanguageData[0]?.help_n_supports?.[0]) {
        setHelpAndSupportValues(currentLanguageData[0]?.help_n_supports?.[0]);
      } else {
        setHelpAndSupportValues(HelpAndSupportInitialState);
      }
      if (currentLanguageData[0]?.about_twg) {
        setAboutTWGValues(currentLanguageData[0]?.about_twg);
      } else {
        setAboutTWGValues(AboutTWGInitialState);
      }
    }
  }, [currentLanguageData]);

  const arrayOfValues = [
    {
      title: "Log In",
      key: "login_meta",
      values: loginFieldValues,
      setState: setloginFieldValues,
    },
    {
      title: "Sign Up",
      key: "signup_meta",
      values: signUpfieldValues,
      setState: setSignUpfieldValues,
    },
    {
      title: "Forgot Password",
      key: "forgotpassword_meta",
      values: forgotPasswordValues,
      setState: setForgotPasswordValues,
    },
    {
      title: "Home page",
      key: "home_meta",
      values: homePageValues,
      setState: setHomePageValues,
    },
    {
      title: "Promises",
      key: "promises_meta",
      values: promisesValues,
      setState: setPromisesValues,
    },
    {
      title: "Testimonies",
      key: "testimonies",
      values: testimonies,
      setState: setTestimonies,
    },
    {
      title: "Your achievements",
      key: "forgotpassword_meta",
      values: achievementValues,
      setState: setAchievementValues,
    },
    {
      title: "Videos liked",
      key: "forgotpassword_meta",
      values: videoLikedValues,
      setState: setVideoLikedValues,
    },
    {
      title: "Curriculum",
      key: "curricullum_meta",
      values: curriculumValues,
      setState: setCurriculumValues,
    },
    {
      title: "Favourites",
      key: "my_favourites",
      values: favouriteValues,
      setState: setFavouriteValues,
    },
    {
      title: "Givings",
      key: "my_givings",
      values: givingsValues,
      setState: setGivingsValues,
    },
    {
      title: "Menu",
      key: "menus",
      values: menuValues,
      setState: setMenuValues,
    },
    {
      title: "My Profile",
      key: "my_profile",
      values: myProfileValues,
      setState: setMyProfileValues,
    },
    {
      title: "My Downloads",
      key: "my_downloads",
      values: myDownloadsValues,
      setState: setMyDownloadsValues,
    },
    {
      title: "My Givings",
      key: "my_givings",
      values: myGivingsValues,
      setState: setMyGivingsValues,
    },
    {
      title: "Help and Support",
      key: "help_n_supports",
      values: helpAndSupportValues,
      setState: setHelpAndSupportValues,
    },
    {
      title: "About TWG",
      key: "about_twg",
      values: aboutTWGValues,
      setState: setAboutTWGValues,
    },
  ];

  const updateTabData = (tabDetails) => {
    let enData;
    let isCurrentLanguageMetaAvailable;
    if (tabDetails?.key === "help_n_supports") {
      enData = enLanguageData?.[0]?.[tabDetails?.key]?.[0];
      isCurrentLanguageMetaAvailable = currentLanguageData?.[0]?.[tabDetails?.key]?.[0]
        ? true
        : false;
    } else {
      enData = enLanguageData?.[0]?.[tabDetails?.key];
      isCurrentLanguageMetaAvailable = currentLanguageData?.[0]?.[tabDetails?.key] ? true : false;
    }
    console.log(
      "values",
      tabDetails?.values,
      "enLanguageData",
      enLanguageData,
      "enData",
      enData,
      "tabKey",
      tabDetails?.key,
      "isCurrentLanguageMetaAvailable",
      isCurrentLanguageMetaAvailable,
      "currentLanguageData",
      currentLanguageData
    );
    if (!isCurrentLanguageMetaAvailable && tabDetails?.values && enData) {
      const tabValues = structuredClone(tabDetails?.values);
      Object.keys(tabValues)?.forEach((field) => {
        tabValues[field] = enData?.[field] ?? tabValues[field];
      });
      if (tabDetails?.key === "help_n_supports" && enData?.accordians) {
        tabValues["accordians"] = enData?.accordians?.map((d) => ({
          panel_description: d?.panel_description,
          panel_title: d?.panel_title,
        }));
      }
      tabDetails?.setState(tabValues);
      currentState?.[tabDetails?.title]?.setState(tabValues);
    }
    return null;
  };

  const combineStateKeys = (state) => {
    const values = Object.values(state);
    const combinedString = values.join("///");
    return combinedString;
  };

  const updateStateWithTranslation = (language, myState, setState, tabKey) => {
    const langCode = language.split(" - ");

    console.log("myState", myState);
    console.log("tabKey", tabKey);

    let combinedText;

    if (tabKey === "help_n_supports") {
      let combinedString = myState?.help_n_support_lbl + "///" + myState?.help_center_lbl;
      if (!currentLanguageData?.[0]?.[tabKey]?.[0] && myState?.accordians?.length > 0) {
        combinedString +=
          ":::" +
          myState?.accordians
            ?.map((d) => d?.panel_title + "///" + d?.panel_description)
            .join("%%%");
      } else if (
        currentLanguageData?.[0]?.[tabKey]?.[0] &&
        !currentLanguageData?.[0]?.[tabKey]?.[0]?.accordians &&
        enLanguageData?.[0]?.help_n_supports?.[0]?.accordians?.length > 0
      ) {
        combinedString +=
          ":::" +
          enLanguageData?.[0]?.help_n_supports?.[0]?.accordians
            ?.map((d) => d?.panel_title + "///" + d?.panel_description)
            .join("%%%");
      }
      combinedText = combinedString;
    } else {
      combinedText = combineStateKeys(myState);
    }

    if (langCode[1]?.length > 0) {
      dispatch(
        translateLanguageAction({
          apiPayloadRequest: {
            targetcode: langCode[1],
            translatetext: combinedText,
          },
          callback: (res) => {
            if (res?.type === 1) {
              console.log("res?.data", res?.data);
              onTranslateSuccess(res?.data, myState, setState, tabKey);
            } else if (res?.type === 2) {
              Toastiy("Language Translation failed!!");
              Toastiy(res?.message);
            }
          },
        })
      );
    }
  };

  const onTranslateSuccess = (data, myState, setState, tabKey) => {
    console.log(myState, "myState");
    console.log("translated data", data);

    if (tabKey === "help_n_supports") {
      if (
        !currentLanguageData?.[0]?.[tabKey]?.[0] ||
        (currentLanguageData?.[0]?.[tabKey]?.[0] &&
          !currentLanguageData?.[0]?.[tabKey]?.[0]?.accordians &&
          enLanguageData?.[0]?.help_n_supports?.[0]?.accordians?.length > 0)
      ) {
        const arrayOfValues = data.split(":::");
        const helpSupportTranslatedValues = arrayOfValues?.[0]?.split("///");
        const helpSupportTranslatedAccordians = arrayOfValues?.[1]?.split("%%%");
        console.log("arrayOfValues", arrayOfValues);
        console.log("helpSupportTranslatedValues", helpSupportTranslatedValues);
        console.log("helpSupportTranslatedAccordians", helpSupportTranslatedAccordians);
        setState((preState) => {
          const intialAccordians =
            currentLanguageData?.[0]?.[tabKey]?.[0] &&
            !currentLanguageData?.[0]?.[tabKey]?.[0]?.accordians &&
            enLanguageData?.[0]?.help_n_supports?.[0]?.accordians?.length > 0
              ? enLanguageData?.[0]?.help_n_supports?.[0]?.accordians
              : preState?.accordians;
          return {
            ...preState,
            help_n_support_lbl: helpSupportTranslatedValues?.[0],
            help_center_lbl: helpSupportTranslatedValues?.[1],
            accordians: intialAccordians?.map((accordian, index) => {
              const translatedValues = helpSupportTranslatedAccordians?.[index]?.split("///");
              return {
                ...accordian,
                panel_title: translatedValues?.[0],
                panel_description: translatedValues?.[1],
              };
            }),
          };
        });
      } else {
        const arrayOfValues = data.split("///");
        setState((preState) => ({
          ...preState,
          help_n_support_lbl: arrayOfValues?.[0],
          help_center_lbl: arrayOfValues?.[1],
        }));
      }
    } else {
      const arrayOfValues = data.split("///");
      const arrayOfKeys = Object.keys(myState);
      for (let key in arrayOfKeys) {
        setState((preState) => ({
          ...preState,
          [arrayOfKeys[key]]: arrayOfValues[key],
        }));
        setForceEditorUpdate(true);
      }
    }
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    if (activeTab === "Log In") {
      setloginFieldValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Sign Up") {
      console.log(name, value, "FNEKMFEK");
      setSignUpfieldValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Forgot Password") {
      setForgotPasswordValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Home page") {
      setForceEditorUpdate(false);
      setHomePageValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Promises") {
      setPromisesValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Testimonies") {
      setTestimonies((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Your achievements") {
      setAchievementValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Videos liked") {
      setVideoLikedValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Curriculum") {
      setCurriculumValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Favourites") {
      setFavouriteValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Givings") {
      setGivingsValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Menu") {
      setMenuValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "My Profile") {
      setMyProfileValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "My Downloads") {
      setMyDownloadsValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "My Givings") {
      setMyGivingsValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "Help and Support") {
      setHelpAndSupportValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else if (activeTab === "About TWG") {
      setAboutTWGValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
    setShowNavigationAlert(true);
  };

  const handleSubmit = async () => {
    if (activeTab === "Log In") {
      if (currentLanguageData[0]?.login_meta) {
        dispatch(
          updateLanguageLoginAction({
            apiPayloadRequest: {
              ...loginFieldValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
        console.log("Update", activeTab);
      } else {
        dispatch(
          saveLanguageLoginAction({
            apiPayloadRequest: {
              ...loginFieldValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Sign Up") {
      if (currentLanguageData[0]?.signup_meta) {
        dispatch(
          updateLanguageSignUpAction({
            apiPayloadRequest: {
              ...signUpfieldValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageSignUpAction({
            apiPayloadRequest: {
              ...signUpfieldValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Forgot Password") {
      if (currentLanguageData[0]?.forgotpassword_meta) {
        dispatch(
          updateLanguageForgotPasswordAction({
            apiPayloadRequest: {
              ...forgotPasswordValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageForgotPasswordAction({
            apiPayloadRequest: {
              ...forgotPasswordValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Home page") {
      if (currentLanguageData[0]?.home_meta) {
        dispatch(
          updateLanguageHomeDataAction({
            apiPayloadRequest: {
              ...homePageValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageHomeDataAction({
            apiPayloadRequest: {
              ...homePageValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Promises") {
      if (currentLanguageData[0]?.promises_meta) {
        dispatch(
          updateLanguagePromisesDataAction({
            apiPayloadRequest: {
              ...promisesValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguagePromisesDataAction({
            apiPayloadRequest: {
              ...promisesValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Testimonies") {
      if (currentLanguageData[0]?.testimonies) {
        dispatch(
          updateLanguageTestimoniesAction({
            apiPayloadRequest: {
              ...testimonies,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageTestimoniesAction({
            apiPayloadRequest: {
              ...testimonies,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Favourites") {
      if (currentLanguageData[0]?.my_favourites) {
        dispatch(
          updateLanguageFavouritesAction({
            apiPayloadRequest: {
              ...favouriteValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageFavouritesAction({
            apiPayloadRequest: {
              ...favouriteValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Curriculum") {
      if (currentLanguageData[0]?.curricullum_meta) {
        dispatch(
          updateLanguageCurriculumAction({
            apiPayloadRequest: {
              ...curriculumValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageCurriculumAction({
            apiPayloadRequest: {
              ...curriculumValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Givings") {
      if (currentLanguageData[0]?.givings) {
        dispatch(
          updateLanguageGivingsAction({
            apiPayloadRequest: {
              ...givingsValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageGivingsAction({
            apiPayloadRequest: {
              ...givingsValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Menu") {
      if (currentLanguageData[0]?.menus) {
        dispatch(
          updateLanguageMenuAction({
            apiPayloadRequest: {
              ...menuValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageMenuAction({
            apiPayloadRequest: {
              ...menuValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "My Profile") {
      if (currentLanguageData[0]?.my_profile) {
        dispatch(
          updateLanguageMyProfileAction({
            apiPayloadRequest: {
              ...myProfileValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageMyProfileAction({
            apiPayloadRequest: {
              ...myProfileValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "My Downloads") {
      if (currentLanguageData[0]?.my_downloads) {
        dispatch(
          updateLanguageMyDownloadsAction({
            apiPayloadRequest: {
              ...myDownloadsValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageMyDownloadsAction({
            apiPayloadRequest: {
              ...myDownloadsValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "My Givings") {
      if (currentLanguageData[0]?.my_givings) {
        dispatch(
          updateLanguageMyGivingsAction({
            apiPayloadRequest: {
              ...myGivingsValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageMyGivingsAction({
            apiPayloadRequest: {
              ...myGivingsValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    } else if (activeTab === "Help and Support") {
      if (currentLanguageData[0]?.help_n_supports?.[0]) {
        dispatch(
          updateLanguageHelpAndSupportAction({
            apiPayloadRequest: {
              ...helpAndSupportValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: async (resp) => {
              if (
                helpAndSupportValues?.accordians?.length > 0 &&
                !currentLanguageData?.[0]?.help_n_supports?.[0]?.accordians
              ) {
                const promiseArray = [];
                helpAndSupportValues?.accordians?.forEach((accordian, accordianIndex) => {
                  promiseArray.push(
                    new Promise((resolve, reject) => {
                      dispatch(
                        saveLanguageHelpAndSupportAccordianAction({
                          apiPayloadRequest: {
                            _id: currentLanguageData[0]?._id,
                            helpsuppObjId: helpAndSupportValues?.helpsuppObjId,
                            panel_title: accordian?.panel_title,
                            panel_description: accordian?.panel_description,
                          },
                          callback: (res) => {
                            if (res?.type === 1) {
                              setShowAccordionModal(false);
                              setHelpAndSupportValues((prev) => ({
                                ...prev,
                                accordians: prev?.accordians?.map((acc, i) => {
                                  if (accordianIndex === i) {
                                    return {
                                      ...acc,
                                      panel_title: accordian?.panel_title,
                                      panel_description: accordian?.panel_description,
                                      accorObjId: res?.data?.accorObjId,
                                    };
                                  }
                                  return acc;
                                }),
                              }));
                              Toastiy("Accordion Added Successfully!", 1);
                              resolve();
                            }
                          },
                        })
                      );
                    })
                  );
                });
                await Promise.allSettled(promiseArray);
              }
              languagePreviewResponse(resp, activeTab + " Updated Successfully!");
            },
          })
        );
      } else {
        dispatch(
          saveLanguageHelpAndSupportAction({
            apiPayloadRequest: {
              ...helpAndSupportValues,
              _id: currentLanguageData[0]?._id,
            },

            callback: async (resp) => {
              if (helpAndSupportValues?.accordians?.length > 0) {
                const promiseArray = [];
                helpAndSupportValues?.accordians?.forEach((accordian, accordianIndex) => {
                  promiseArray.push(
                    new Promise((resolve, reject) => {
                      dispatch(
                        saveLanguageHelpAndSupportAccordianAction({
                          apiPayloadRequest: {
                            _id: currentLanguageData[0]?._id,
                            helpsuppObjId: resp?.data?.helpsuppObjId,
                            panel_title: accordian?.panel_title,
                            panel_description: accordian?.panel_description,
                          },
                          callback: (res) => {
                            if (res?.type === 1) {
                              setShowAccordionModal(false);
                              setHelpAndSupportValues((prev) => ({
                                ...prev,
                                accordians: prev?.accordians?.map((acc, i) => {
                                  if (accordianIndex === i) {
                                    return {
                                      ...acc,
                                      panel_title: accordian?.panel_title,
                                      panel_description: accordian?.panel_description,
                                      accorObjId: res?.data?.accorObjId,
                                    };
                                  }
                                  return acc;
                                }),
                              }));
                              Toastiy("Accordion Added Successfully!", 1);
                              resolve();
                            }
                          },
                        })
                      );
                    })
                  );
                });
                await Promise.allSettled(promiseArray);
              }
              languagePreviewResponse(resp, activeTab + " Saved Successfully!");
            },
          })
        );
      }
    } else if (activeTab === "About TWG") {
      if (currentLanguageData[0]?.about_twg) {
        dispatch(
          updateLanguageAboutTWGAction({
            apiPayloadRequest: {
              ...aboutTWGValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Updated Successfully!"),
          })
        );
      } else {
        dispatch(
          saveLanguageAboutTWGAction({
            apiPayloadRequest: {
              ...aboutTWGValues,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => languagePreviewResponse(res, activeTab + " Saved Successfully!"),
          })
        );
      }
    }
  };

  const handleAccordionSave = () => {
    if (!currentLanguageData[0]?.help_n_supports?.[0]) {
      dispatch(
        saveLanguageHelpAndSupportAction({
          apiPayloadRequest: {
            ...helpAndSupportValues,
            _id: currentLanguageData[0]?._id,
          },
          callback: (resp) => {
            languagePreviewResponse(resp, activeTab + " Updated Successfully!");
            dispatch(
              saveLanguageHelpAndSupportAccordianAction({
                apiPayloadRequest: {
                  ...currAccordion,
                  helpsuppObjId: resp?.data?.helpsuppObjId,
                  _id: currentLanguageData[0]?._id,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    setShowAccordionModal(false);
                    setHelpAndSupportValues((prev) => ({
                      ...prev,
                      accordians: [
                        ...(prev?.accordians ?? []),
                        { ...currAccordion, accorObjId: res?.data?.accorObjId },
                      ],
                    }));
                    setCurrAccordion(accordionInitialState);
                    Toastiy("Accordion Added Successfully!", 1);
                  }
                },
              })
            );
          },
        })
      );
    } else {
      if (!currAccordion?.accorObjId) {
        dispatch(
          saveLanguageHelpAndSupportAccordianAction({
            apiPayloadRequest: {
              ...currAccordion,
              helpsuppObjId: helpAndSupportValues?.helpsuppObjId,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => {
              if (res?.type === 1) {
                setShowAccordionModal(false);
                setHelpAndSupportValues((prev) => ({
                  ...prev,
                  accordians: [
                    ...(prev?.accordians ?? []),
                    { ...currAccordion, accorObjId: res?.data?.accorObjId },
                  ],
                }));
                setCurrAccordion(accordionInitialState);
                Toastiy("Accordion Added Successfully!", 1);
                setShowNavigationAlert(false);
              }
            },
          })
        );
      } else {
        dispatch(
          updateLanguageHelpAndSupportAccordianAction({
            apiPayloadRequest: {
              ...currAccordion,
              helpsuppObjId: helpAndSupportValues?.helpsuppObjId,
              _id: currentLanguageData[0]?._id,
            },
            callback: (res) => {
              if (res?.type === 1) {
                setShowAccordionModal(false);
                setHelpAndSupportValues((prev) => ({
                  ...prev,
                  accordians: prev?.accordians?.map((accordion) =>
                    accordion?.accorObjId === currAccordion?.accorObjId ? currAccordion : accordion
                  ),
                }));
                setCurrAccordion(accordionInitialState);
                Toastiy("Accordion Updated Successfully!", 1);
              }
              setShowNavigationAlert(false);
            },
          })
        );
      }
    }
  };

  const handleAccordionDelete = (accorObj) => {
    dispatch(
      deleteLanguageHelpAndSupportAccordianAction({
        apiPayloadRequest: {
          _id: currentLanguageData[0]?._id,
          helpsuppObjId: helpAndSupportValues?.helpsuppObjId,
          accorObjId: accorObj?.accorObjId,
          panel_title: accorObj?.panel_title,
        },
        callback: (res) => {
          if (res?.type === 1) {
            console.log("currAccordion", currAccordion);
            console.log("helpAndSupportValues", helpAndSupportValues);
            setHelpAndSupportValues((prev) => ({
              ...prev,
              accordians: prev?.accordians?.filter(
                (accordion) => accordion?.accorObjId !== accorObj?.accorObjId
              ),
            }));
            setCurrAccordion(accordionInitialState);
            Toastiy("Accordion Deleted Successfully!", 1);
            setShowNavigationAlert(false);
          }
        },
      })
    );
  };

  const handleLanguageSave = () => {
    const languageCode = targetLanguage?.split(" - ");
    dispatch(
      saveLanguageAction({
        apiPayloadRequest: {
          language_code: languageCode[1],
          language_name: languageCode[0],
        },
        callback: (res) => {
          if (res?.type === 1) {
            onLanguageSaveSuccess(languageCode[1], res?.type);
          }
        },
      })
    );
  };

  const onLanguageSaveSuccess = (code, type) => {
    readAllLanguages(code);
    Toastiy("Language Added Successfully!", type);
    setShowAdd(false);
    setShowNavigationAlert(false);
  };

  const handleDeleteLanguage = (item) => {
    dispatch(
      deleteLanguageSignUpAction({
        apiPayloadRequest: { _id: "give the id over here" },
        callback: deleteSuccessResponse,
      })
    );
  };

  const languagePreviewResponse = (res, message) => {
    // console.log(data, "Preview Response")
    if (res?.type === 1) {
      setIsUpdated(true);
      Toastiy(message, res?.type);
      setShowNavigationAlert(false);
      setShowNavigationAlert(false);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", res?.type);
    }
  };

  const deleteSuccessResponse = (data) => {};

  return {
    value,
    onChangeSearchBar,
    handleSubmit,
    activeTab,
    arrayOfValues,
    langPreview,
    setShowData,
    showData,
    handleDeleteLanguage,
    setLangPreview,
    activeTabData,
    setActiveTab,
    handleFieldChange,
    setActiveTabData,
    languages,
    setTargetLanguage,
    targetLanguage,
    updateStateWithTranslation,
    setCurrentState,
    currentState,
    handleLanguageSave,
    currentLanguageData,
    showAdd,
    languageStats,
    showAccordionModal,
    setShowAccordionModal,
    isAccordionEdit,
    setIsAccordionEdit,
    currAccordion,
    setCurrAccordion,
    accordionInitialState,
    handleAccordionInput,
    handleAccordionSave,
    handleAccordionDelete,
    forceEditorUpdate,
    updateTabData,
  };
};
