import * as actionTypes from "../ActionTypes";
import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import * as api from "./LanguageAPI";
import { actionUpdateGlobalLoaderSagaAction } from "../GlobalLoader/GlobalLoaderAction";
import Toastiy from "../../components/universal/Toastiy";

function* translateLanguageRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.translateLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readLanguageListRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageList, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* languageDashboardRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.languageDashboard, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* uploadAssestsRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // console.log(percentage, " % percentage loaded");
        const uploadingStatus = document.querySelector(".uploading");
        if (uploadingStatus) uploadingStatus.textContent = `${percentage}%`;
      },
    };
    const response = yield call(api.uploadAssests, payload, config);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* uploadAssestsV2RequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const uploadProgressId = action.payload.uploadProgressId;
    const uploadingStatus = document.querySelector(
      uploadProgressId ? `#${uploadProgressId}` : ".uploading"
    );
    const uploadingStatusProgress = document.querySelector(
      uploadProgressId ? `#${uploadProgressId}Progress` : ".uploading"
    );
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (uploadingStatus) uploadingStatus.value = `${percentage}`;
        if (uploadingStatusProgress) uploadingStatusProgress.textContent = `${percentage}%`;
      },
    };

    const response = yield call(api.uploadAssests, payload, config);
    if (uploadingStatusProgress) uploadingStatusProgress.textContent = `Completed`;
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* uploadVideosRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // console.log(percentage, " % percentage loaded");
        const uploadingStatus = document.querySelector(".uploading");
        if (uploadingStatus) uploadingStatus.textContent = `${percentage}%`;
      },
    };
    const response = yield call(api.uploadVideos, payload, config);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* uploadVideosV2RequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));

  const uploadProgressId = action.payload.uploadProgressId;
  const uploadingStatusProgress = document.querySelector(
    uploadProgressId ? `#${uploadProgressId}Progress` : ".uploading"
  );
  const uploadingStatus = document.querySelector(
    uploadProgressId ? `#${uploadProgressId}` : ".uploading"
  );

  if (uploadingStatus) uploadingStatus.classList.remove("error");

  const onUploadError = (error) => {
    if (uploadingStatusProgress) uploadingStatusProgress.textContent = `Failed!`;
    if (uploadingStatus) uploadingStatus.classList.add("error");
    Toastiy(
      error?.errors[0].message ??
        error?.message ??
        "Something went wrong, please contact Site Administrator"
    );
    action.payload.callback({
      filename: "",
      url: "",
    });
  };

  try {
    let videoUrl = "";
    let mediaId = "";

    const file = action.payload.apiPayloadRequest?.get("file");
    const extension = file.name.split(".").pop(); // Get the file extension
    const uniqueFileName = `${new Date().getTime()}.${extension}`;
    const payload = action.payload.apiPayloadRequest;

    const onUploadProgress = (percentage) => {
      if (uploadingStatus) uploadingStatus.value = `${percentage}`;
      if (uploadingStatusProgress) uploadingStatusProgress.textContent = `${percentage}%`;
      if (uploadingStatusProgress && percentage === 100)
        uploadingStatusProgress.textContent = `Encoding..`;
    };

    mediaId = yield call(api.uploadVideosV3, file, uniqueFileName, onUploadProgress, onUploadError);

    let response1 = null;
    let errorEncountered = false;
    while (true) {
      response1 = yield call(api.uploadedVideosStatusCheck, mediaId, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CLOUDFLARE_TOKEN}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (response1?.result?.status?.state === "error") {
        errorEncountered = true;
        if (uploadingStatusProgress) uploadingStatusProgress.textContent = `Failed!`;
        if (uploadingStatus) uploadingStatus.classList.add("error");
        Toastiy(
          response1?.result?.status?.errorReasonText ??
            "Something went wrong, please contact Site Administrator"
        );
        break;
      }
      if (
        (payload.get("downloadable") !== "true" && response1?.result?.readyToStream === true) ||
        (payload.get("downloadable") === "true" &&
          response1?.result?.readyToStream &&
          !response1?.result?.status?.step)
      ) {
        break;
      }
      continue;
    }
    if (uploadingStatusProgress && !errorEncountered)
      uploadingStatusProgress.textContent = `Completed`;

    // videoUrl = response?.result?.playback?.hls;
    if (response1?.result?.playback?.hls) {
      videoUrl = response1?.result?.playback?.hls;
    } else {
      const customerDomain = process.env.REACT_APP_CLOUDFLARE_CUSTOMERDOMAIN.replace(/"/g, "");
      videoUrl = `${customerDomain}${mediaId}/manifest/video.m3u8`;
      console.log("videoUrl", videoUrl);
    }

    //Get Downloadable Media URL if downloadable = true
    if (payload.get("downloadable") === "true" && !errorEncountered) {
      const videoDownloadResponse = yield call(
        api.getUploadedVideoDownloadUrl,
        mediaId,
        { meta: { downloadable: true } },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CLOUDFLARE_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (payload.get("onlyEnableDownload") !== "true")
        videoUrl = videoDownloadResponse?.result?.default?.url;
    }

    action.payload.callback({
      filename: uniqueFileName,
      url: videoUrl,
    });

    // Check Status of media
  } catch (err) {
    if (err?.response) {
      onUploadError();
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something went Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateLanguageRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readLanguageRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* activeorinactiveLanguageRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.activeorinactiveLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageLoginSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageLoginMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateLanguageLoginRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageLogin, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readLanguageLoginRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageLogin, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageLoginRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageLogin, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageSignUpRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    console.log(payload, "payl");
    const response = yield call(api.saveLanguageSignUp, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateLanguageSignUpRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageSignUp, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readLanguageSignUpRequestSaga(action) {
  console.log(action, "aaa");

  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const response = yield call(api.readLanguageSignUp);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteLanguageSignUpRequestSaga(action) {
  console.log(action, "aaa");

  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const response = yield call(api.deleteLanguageSignUp);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageCurriculumMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageHomeDataRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageHomeData, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateLanguageHomeDataRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageHomeData, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguagePromisesDataRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguagePromisesData, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateLanguagePromisesDataRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguagePromisesData, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readLanguageHomeDataRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageHomeData, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteLanguageHomeDataRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageHomeData, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageTestimoniesRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageTestimoniesData, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateLanguageTestimoniesRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageTestimoniesData, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readLanguageTestimoniesRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageTestimoniesData, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteLanguageTestimoniesRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageTestimoniesData, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateLanguageCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageCurriculumMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteLanguageCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageCurriculumMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readLanguageCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageCurriculumMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageFavouritesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageFavouritesMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageFavouritesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageFavouritesMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageFavouritesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageFavouritesMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageFavouritesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageFavouritesMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageGivingsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageGivingsMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageGivingsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageGivingsMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageGivingsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageGivingsMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageGivingsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageGivingsMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageMenuSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageMenuMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageMenuSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageMenuMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageMenuSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageMenuMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageMenuSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageMenuMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageMyProfileSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageMyProfileMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageMyProfileSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageMyProfileMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageMyProfileSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageMyProfileMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageMyProfileSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageMyProfileMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageMyDownloadsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageMyDownloadsMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageMyDownloadsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageMyDownloadsMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageMyDownloadsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageMyDownloadsMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageMyDownloadsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageMyDownloadsMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageMyGivingsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageMyGivingsMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageMyGivingsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageMyGivingsMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageMyGivingsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageMyGivingsMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageMyGivingsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageMyGivingsMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageHelpAndSupportSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageHelpAndSupportMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageHelpAndSupportSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageHelpAndSupportMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageHelpAndSupportSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageHelpAndSupportMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageHelpAndSupportSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageHelpAndSupportMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageHelpAndSupportAccordianSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageHelpAndSupportAccordianMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageHelpAndSupportAccordianSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageHelpAndSupportAccordianMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageHelpAndSupportAccordianSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageHelpAndSupportAccordianMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageHelpAndSupportAccordianSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageHelpAndSupportAccordianMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageAboutTWGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageAboutTWGMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageAboutTWGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageAboutTWGMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageAboutTWGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageAboutTWGMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageAboutTWGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageAboutTWGMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageForgotPasswordSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageForgotPasswordMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageForgotPasswordSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageForgotPasswordMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageForgotPasswordSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageForgotPasswordMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageForgotPasswordSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageForgotPasswordMeta, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateJournalSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateJournal, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readJournalSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readJournal, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteJournalSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteJournal, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* saveJournalSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveJournal, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      action.payload.callback(err?.response);
      console.log(err?.response?.data?.errors?.[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveBGCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveBGCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateBGCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateBGCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveBGJournalSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveBGJournal, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      action.payload.callback(err?.response);
      console.log(err?.response?.data?.errors?.[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateBGJournalSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateBGJournal, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      action.payload.callback(err?.response);
      console.log(err?.response?.data?.errors?.[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveJournalBannerSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveJournalBanner, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      action.payload.callback(err?.response);
      console.log(err?.response?.data?.errors?.[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateJournalBannerSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateJournalBanner, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      action.payload.callback(err?.response);
      console.log(err?.response?.data?.errors?.[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* addJournalNoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.addJournalNote, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      action.payload.callback(err?.response);
      console.log(err?.response?.data?.errors?.[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateJournalNoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateJournalNote, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      action.payload.callback(err?.response);
      console.log(err?.response?.data?.errors?.[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteJournalNoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteJournalNote, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      action.payload.callback(err?.response);
      console.log(err?.response?.data?.errors?.[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* addJournalNoteItemSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.addJournalNoteItem, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      action.payload.callback(err?.response);
      console.log(err?.response?.data?.errors?.[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateJournalNoteItemSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateJournalNoteItem, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      action.payload.callback(err?.response);
      console.log(err?.response?.data?.errors?.[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteJournalNoteItemSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteJournalNoteItem, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      action.payload.callback(err?.response);
      console.log(err?.response?.data?.errors?.[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCommonBGCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCommonBGCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCommonBGCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCommonBGCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumPreviewSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumPreview, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCurriculumPreviewSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumPreview, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLanguagePreviewSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLanguagePreview, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCurriculumLanguagePreviewSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLanguagePreview, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readBGCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readBGCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteBGCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteBGCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguagesCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguagesCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguagesCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguagesCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguagesCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguagesCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguagesCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguagesCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveBGLanguagesCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveBGLanguagesCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateBGLanguagesCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateBGLanguagesCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readBGLanguagesCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readBGLanguagesCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteBGLanguagesCurriculumSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteBGLanguagesCurriculum, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLesson, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLessonSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLesson, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLessonSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLesson, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLessonSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLesson, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLessonLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonPreviewLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonPreviewLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLessonPreviewLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonPreviewLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readCurriculumLessonLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLessonLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLessonLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLessonLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonThumbNailSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonThumbNail, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLessonThumbNailSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonThumbNail, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonCommonThumbNailSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonCommonThumbNail, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCurriculumLessonCommonThumbNailSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonCommonThumbNail, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonPreviewSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonPreview, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCurriculumLessonPreviewSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonPreview, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readCurriculumLessonThumbNailSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLessonThumbNail, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLessonThumbNailSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLessonThumbNail, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLanguageLessonThumbNailSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLanguageLessonThumbNail, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLanguageLessonThumbNailSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLanguageLessonThumbNail, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLanguageLessonThumbNailSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLanguageLessonThumbNail, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLanguageLessonThumbNailSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLanguageLessonThumbNail, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonSlideSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonSlide, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLessonSlideSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonSlide, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonSlideLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonSlideLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCurriculumLessonSlideLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonSlideLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readCurriculumLessonSlideSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLessonSlide, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLessonSlideSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLessonSlide, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLanguageLessonSlideSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLanguageLessonSlide, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLanguageLessonSlideSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLanguageLessonSlide, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLanguageLessonSlideSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLanguageLessonSlide, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLanguageLessonSlideSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLanguageLessonSlide, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonSlideBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonSlideBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLessonSlideBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonSlideBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLessonSlideBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLessonSlideBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLessonSlideBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLessonSlideBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLanguageLessonSlideBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLanguageLessonSlideBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLanguageLessonSlideBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLanguageLessonSlideBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLanguageLessonSlideBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLanguageLessonSlideBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLanguageLessonSlideBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLanguageLessonSlideBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonSlideTypeSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonSlideType, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCurriculumLessonSlideTypeSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonSlideType, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLessonSlideTypeSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLessonSlideType, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLessonSlideTypeSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLessonSlideType, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLanguageLessonSlideTypeSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLanguageLessonSlideType, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLanguageLessonSlideTypeSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLanguageLessonSlideType, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLanguageLessonSlideTypeSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLanguageLessonSlideType, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLanguageLessonSlideTypeSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLanguageLessonSlideType, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonSlideTypeBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonSlideTypeBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLessonSlideTypeBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonSlideTypeBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLessonSlideTypeBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLessonSlideTypeBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLessonSlideTypeBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLessonSlideTypeBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLanguageLessonSlideTypeBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLanguageLessonSlideTypeBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLanguageLessonSlideTypeBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLanguageLessonSlideTypeBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLanguageLessonSlideTypeBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLanguageLessonSlideTypeBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLanguageLessonSlideTypeBGSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLanguageLessonSlideTypeBG, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonSlideTypeAssetSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonSlideTypeAsset, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLessonSlideTypeAssetSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonSlideTypeAsset, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLessonSlideTypeAssetSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLessonSlideTypeAsset, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLessonSlideTypeAssetSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLessonSlideTypeAsset, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLanguageLessonSlideTypeAssetSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLanguageLessonSlideTypeAsset, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLanguageLessonSlideTypeAssetSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLanguageLessonSlideTypeAsset, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLanguageLessonSlideTypeAssetSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLanguageLessonSlideTypeAsset, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLanguageLessonSlideTypeAssetSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLanguageLessonSlideTypeAsset, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonSlideTypeLinkSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonSlideTypeLink, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLessonSlideTypeLinkSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonSlideTypeLink, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLessonSlideTypeLinkSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLessonSlideTypeLink, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLessonSlideTypeLinkSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLessonSlideTypeLink, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLanguageLessonSlideTypeLinkSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLanguageLessonSlideTypeLink, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLanguageLessonSlideTypeLinkSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLanguageLessonSlideTypeLink, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLanguageLessonSlideTypeLinkSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLanguageLessonSlideTypeLink, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLanguageLessonSlideTypeLinkSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLanguageLessonSlideTypeLink, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonSlideTypePollSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonSlideTypePoll, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLessonSlideTypePollSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonSlideTypePoll, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLessonSlideTypePollSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLessonSlideTypePoll, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLessonSlideTypePollSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLessonSlideTypePoll, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLanguageLessonSlideTypePollSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLanguageLessonSlideTypePoll, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLanguageLessonSlideTypePollSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLanguageLessonSlideTypePoll, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLanguageLessonSlideTypePollSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLanguageLessonSlideTypePoll, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLanguageLessonSlideTypePollSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLanguageLessonSlideTypePoll, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLessonSlideTypePollOptionsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonSlideTypePollOptions, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLessonSlideTypePollOptionsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonSlideTypePollOptions, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLessonSlideTypePollOptionsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLessonSlideTypePollOptions, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLessonSlideTypePollOptionsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLessonSlideTypePollOptions, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* saveCurriculumLessonSlideTypePollOptionsVoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLessonSlideTypePollOptionsVote, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLessonSlideTypePollOptionsVoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLessonSlideTypePollOptionsVote, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLessonSlideTypePollOptionsVoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLessonSlideTypePollOptionsVote, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLessonSlideTypePollOptionsVoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLessonSlideTypePollOptionsVote, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLanguageLessonSlideTypePollOptionsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLanguageLessonSlideTypePollOptions, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLanguageLessonSlideTypePollOptionsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLanguageLessonSlideTypePollOptions, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLanguageLessonSlideTypePollOptionsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLanguageLessonSlideTypePollOptions, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLanguageLessonSlideTypePollOptionsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLanguageLessonSlideTypePollOptions, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* saveCurriculumLanguageLessonSlideTypePollOptionsVoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLanguageLessonSlideTypePollOptionsVote, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCurriculumLanguageLessonSlideTypePollOptionsVoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(
      api.updateCurriculumLanguageLessonSlideTypePollOptionsVote,
      payload
    );
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCurriculumLanguageLessonSlideTypePollOptionsVoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLanguageLessonSlideTypePollOptionsVote, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCurriculumLanguageLessonSlideTypePollOptionsVoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(
      api.deleteCurriculumLanguageLessonSlideTypePollOptionsVote,
      payload
    );
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveVideosSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveVideos, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateVideosSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateVideos, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readVideosSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readVideos, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteVideosSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteVideos, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageVideosSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageVideos, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageVideosSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageVideos, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageVideosSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageVideos, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageVideosSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageVideos, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveVideosUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveVideosUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCommonsUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCommonsUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCommonsUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCommonsUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveVideoPreviewUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveVideoPreviewUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateVideoPreviewUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateVideoPreviewUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveVideoLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveVideoLanguag, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLayoutLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLayoutLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateLayoutLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLayoutLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCategoriesLanguageSage(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCategoriesLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCategoriesLanguageSage(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCategoriesLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCategoriesPreviewLanguageSage(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCategoriesPreviewLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCategoriesPreviewLanguageSage(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCategoriesPreviewLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateVideoLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateVideoLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveVideoUrlLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveVideoUrlLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateVideoUrlLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateVideoUrlLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveDownloadVideoUrlLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveDownloadVideoUrlLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateDownloadVideoUrlLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateDownloadVideoUrlLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveVideoPreviewLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveVideoPreviewLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateVideoPreviewLanguageSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateVideoPreviewLanguage, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateVideosUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateVideosUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readVideosUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readVideosUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteVideosUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteVideosUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageVideosUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageVideosUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageVideosUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageVideosUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageVideosUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageVideosUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageVideosUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageVideosUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveVideosDownloadUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveVideosDownloadUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateVideosDownloadUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateVideosDownloadUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readVideosDownloadUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readVideosDownloadUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteVideosDownloadUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteVideosDownloadUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageVideosDownloadUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageVideosDownloadUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageVideosDownloadUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageVideosDownloadUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageVideosDownloadUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageVideosDownloadUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageVideosDownloadUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageVideosDownloadUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveVideosThumbNailUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveVideosThumbNailUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateVideosThumbNailUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateVideosThumbNailUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readVideosThumbNailUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readVideosThumbNailUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteVideosThumbNailUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteVideosThumbNailUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguageVideosThumbNailUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguageVideosThumbNailUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguageVideosThumbNailUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguageVideosThumbNailUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguageVideosThumbNailUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguageVideosThumbNailUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguageVideosThumbNailUrlsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguageVideosThumbNailUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveRelatedVideosSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveRelatedVideos, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readRelatedVideosSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readRelatedVideos, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteRelatedVideosSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteRelatedVideos, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCategoriesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCommonThumbnailCategoriesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCommonThumbnailCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCommonThumbnailCategoriesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCommonThumbnailCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCategoryPreviewSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCategoryPreview, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCategoryPreviewSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCategoryPreview, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCategoriesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCategoriesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCategoriesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCategoriesIconSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCategoriesIcon, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCategoriesIconSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCategoriesIcon, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCategoriesIconSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCategoriesIcon, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCategoriesIconSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCategoriesIcon, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCategoriesRelatedSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCategoriesRelated, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateCategoriesRelatedSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCategoriesRelated, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readCategoriesRelatedSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCategoriesRelated, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteCategoriesRelatedSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCategoriesRelated, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveLanguagesCategoriesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLanguagesCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* updateLanguagesCategoriesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLanguagesCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* readLanguagesCategoriesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLanguagesCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}
function* deleteLanguagesCategoriesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLanguagesCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

export default function* LanguagesWatcherSaga() {
  yield takeLatest(actionTypes.TRANSLATE_TEXT_REQUEST, translateLanguageRequestSaga);
  yield takeLatest(actionTypes.READ_LANGUAGE_LIST_REQUEST, readLanguageListRequestSaga);

  yield takeLatest(actionTypes.LANGUAGE_DASHBOARD_REQUEST, languageDashboardRequestSaga);
  yield takeEvery(actionTypes.UPLOAD_ASSEST_REQUEST, uploadAssestsRequestSaga);
  yield takeEvery(actionTypes.UPLOAD_ASSEST_V2_REQUEST, uploadAssestsV2RequestSaga);
  yield takeEvery(actionTypes.UPLOAD_VIDEOS_REQUEST, uploadVideosRequestSaga);
  yield takeEvery(actionTypes.UPLOAD_VIDEOS_V2_REQUEST, uploadVideosV2RequestSaga);
  yield takeLatest(actionTypes.SAVE_LANGUAGES_REQUEST, saveLanguageRequestSaga);
  yield takeLatest(actionTypes.UPDATE_LANGUAGES_REQUEST, updateLanguageRequestSaga);
  yield takeEvery(actionTypes.READ_LANGUAGES_REQUEST, readLanguageRequestSaga);
  yield takeLatest(
    actionTypes.ACTIVE_OR_INACTIVE_LANGUAGES_REQUEST,
    activeorinactiveLanguageRequestSaga
  );
  yield takeLatest(actionTypes.SAVE_LANGUAGE_LOGIN_META, saveLanguageLoginSaga);
  yield takeLatest(actionTypes.UPDATE_LANGUAGES_LOGIN_META, updateLanguageLoginRequestSaga);
  yield takeLatest(actionTypes.READ_LANGUAGES_LOGIN_META, readLanguageLoginRequestSaga);
  yield takeLatest(actionTypes.DELETE_LANGUAGES_LOGIN_META, deleteLanguageLoginRequestSaga);
  yield takeLatest(actionTypes.SAVE_LANGUAGES_SIGN_UP_REQUEST, saveLanguageSignUpRequestSaga);
  yield takeLatest(actionTypes.DELETE_LANGUAGE_REQUEST, deleteLanguageRequestSaga);

  yield takeLatest(actionTypes.UPDATE_LANGUAGES_SIGN_UP_REQUEST, updateLanguageSignUpRequestSaga);
  yield takeLatest(actionTypes.READ_LANGUAGES_SIGN_UP_REQUEST, readLanguageSignUpRequestSaga);
  yield takeLatest(actionTypes.SAVE_LANGUAGE_CURRICULUM, saveLanguageCurriculumSaga);
  yield takeLatest(actionTypes.UPDATE_LANGUAGE_CURRICULUM, updateLanguageCurriculumSaga);
  yield takeLatest(actionTypes.DELETE_LANGUAGE_CURRICULUM, deleteLanguageCurriculumSaga);
  yield takeLatest(actionTypes.READ_LANGUAGE_CURRICULUM, readLanguageCurriculumSaga);
  yield takeLatest(actionTypes.SAVE_LANGUAGE_FAVOURITES, saveLanguageFavouritesSaga);
  yield takeLatest(actionTypes.UPDATE_LANGUAGE_FAVOURITES, updateLanguageFavouritesSaga);
  yield takeLatest(actionTypes.DELETE_LANGUAGE_FAVOURITES, deleteLanguageFavouritesSaga);
  yield takeLatest(actionTypes.READ_LANGUAGE_FAVOURITES, readLanguageFavouritesSaga);
  yield takeLatest(actionTypes.DELETE_LANGUAGES_SIGN_UP_REQUEST, deleteLanguageSignUpRequestSaga);
  yield takeLatest(actionTypes.SAVE_LANGUAGES_HOME_DATA_REQUEST, saveLanguageHomeDataRequestSaga);
  yield takeLatest(
    actionTypes.UPDATE_LANGUAGES_HOME_DATA_REQUEST,
    updateLanguageHomeDataRequestSaga
  );
  yield takeLatest(
    actionTypes.SAVE_LANGUAGES_PROMISES_DATA_REQUEST,
    saveLanguagePromisesDataRequestSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_LANGUAGES_PROMISES_DATA_REQUEST,
    updateLanguagePromisesDataRequestSaga
  );
  yield takeLatest(actionTypes.READ_LANGUAGES_HOME_DATA_REQUEST, readLanguageHomeDataRequestSaga);
  yield takeLatest(
    actionTypes.DELETE_LANGUAGES_HOME_DATA_REQUEST,
    deleteLanguageHomeDataRequestSaga
  );
  yield takeLatest(
    actionTypes.SAVE_LANGUAGE_TESTIMONIES_REQUEST,
    saveLanguageTestimoniesRequestSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_LANGUAGE_TESTIMONIES_REQUEST,
    updateLanguageTestimoniesRequestSaga
  );
  yield takeLatest(
    actionTypes.READ_LANGUAGE_TESTIMONIES_REQUEST,
    readLanguageTestimoniesRequestSaga
  );
  yield takeLatest(
    actionTypes.DELETE_LANGUAGE_TESTIMONIES_REQUEST,
    deleteLanguageTestimoniesRequestSaga
  );

  yield takeLatest(actionTypes.SAVE_LANGUAGES_GIVINGS_REQUEST, saveLanguageGivingsSaga);
  yield takeLatest(actionTypes.UPDATE_LANGUAGES_GIVINGS_REQUEST, updateLanguageGivingsSaga);
  yield takeLatest(actionTypes.READ_LANGUAGES_GIVINGS_REQUEST, readLanguageGivingsSaga);
  yield takeLatest(actionTypes.DELETE_LANGUAGES_GIVINGS_REQUEST, deleteLanguageGivingsSaga);

  yield takeLatest(actionTypes.SAVE_LANGUAGES_MENU_REQUEST, saveLanguageMenuSaga);
  yield takeLatest(actionTypes.UPDATE_LANGUAGES_MENU_REQUEST, updateLanguageMenuSaga);
  yield takeLatest(actionTypes.READ_LANGUAGES_MENU_REQUEST, readLanguageMenuSaga);
  yield takeLatest(actionTypes.DELETE_LANGUAGES_MENU_REQUEST, deleteLanguageMenuSaga);

  yield takeLatest(actionTypes.SAVE_LANGUAGES_MY_PROFILE_REQUEST, saveLanguageMyProfileSaga);
  yield takeLatest(actionTypes.UPDATE_LANGUAGES_MY_PROFILE_REQUEST, updateLanguageMyProfileSaga);
  yield takeLatest(actionTypes.READ_LANGUAGES_MY_PROFILE_REQUEST, readLanguageMyProfileSaga);
  yield takeLatest(actionTypes.DELETE_LANGUAGES_MY_PROFILE_REQUEST, deleteLanguageMyProfileSaga);

  yield takeLatest(actionTypes.SAVE_LANGUAGES_MY_DOWNLOADS_REQUEST, saveLanguageMyDownloadsSaga);
  yield takeLatest(
    actionTypes.UPDATE_LANGUAGES_MY_DOWNLOADS_REQUEST,
    updateLanguageMyDownloadsSaga
  );
  yield takeLatest(actionTypes.READ_LANGUAGES_MY_DOWNLOADS_REQUEST, readLanguageMyDownloadsSaga);
  yield takeLatest(
    actionTypes.DELETE_LANGUAGES_MY_DOWNLOADS_REQUEST,
    deleteLanguageMyDownloadsSaga
  );

  yield takeLatest(actionTypes.SAVE_LANGUAGES_MY_GIVINGS_REQUEST, saveLanguageMyGivingsSaga);
  yield takeLatest(actionTypes.UPDATE_LANGUAGES_MY_GIVINGS_REQUEST, updateLanguageMyGivingsSaga);
  yield takeLatest(actionTypes.READ_LANGUAGES_MY_GIVINGS_REQUEST, readLanguageMyGivingsSaga);
  yield takeLatest(actionTypes.DELETE_LANGUAGES_MY_GIVINGS_REQUEST, deleteLanguageMyGivingsSaga);

  yield takeLatest(
    actionTypes.SAVE_LANGUAGES_HELP_AND_SUPPORT_REQUEST,
    saveLanguageHelpAndSupportSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_LANGUAGES_HELP_AND_SUPPORT_REQUEST,
    updateLanguageHelpAndSupportSaga
  );
  yield takeLatest(
    actionTypes.READ_LANGUAGES_HELP_AND_SUPPORT_REQUEST,
    readLanguageHelpAndSupportSaga
  );
  yield takeLatest(
    actionTypes.DELETE_LANGUAGES_HELP_AND_SUPPORT_REQUEST,
    deleteLanguageHelpAndSupportSaga
  );

  yield takeEvery(
    actionTypes.SAVE_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST,
    saveLanguageHelpAndSupportAccordianSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST,
    updateLanguageHelpAndSupportAccordianSaga
  );
  yield takeLatest(
    actionTypes.READ_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST,
    readLanguageHelpAndSupportAccordianSaga
  );
  yield takeLatest(
    actionTypes.DELETE_LANGUAGES_HELP_AND_SUPPORT_ACCORDIAN_REQUEST,
    deleteLanguageHelpAndSupportAccordianSaga
  );

  yield takeLatest(actionTypes.SAVE_LANGUAGES_ABOUT_TWG_REQUEST, saveLanguageAboutTWGSaga);
  yield takeLatest(actionTypes.UPDATE_LANGUAGES_ABOUT_TWG_REQUEST, updateLanguageAboutTWGSaga);
  yield takeLatest(actionTypes.READ_LANGUAGES_ABOUT_TWG_REQUEST, readLanguageAboutTWGSaga);
  yield takeLatest(actionTypes.DELETE_LANGUAGES_ABOUT_TWG_REQUEST, deleteLanguageAboutTWGSaga);

  yield takeLatest(
    actionTypes.SAVE_LANGUAGES_FORGOT_PASSWORD_REQUEST,
    saveLanguageForgotPasswordSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_LANGUAGES_FORGOT_PASSWORD_REQUEST,
    updateLanguageForgotPasswordSaga
  );
  yield takeLatest(
    actionTypes.READ_LANGUAGES_FORGOT_PASSWORD_REQUEST,
    readLanguageForgotPasswordSaga
  );
  yield takeLatest(
    actionTypes.DELETE_LANGUAGES_FORGOT_PASSWORD_REQUEST,
    deleteLanguageForgotPasswordSaga
  );

  yield takeLatest(actionTypes.SAVE_CURRICULUM_REQUEST, saveCurriculumSaga);
  yield takeLatest(actionTypes.UPDATE_CURRICULUM_REQUEST, updateCurriculumSaga);
  yield takeLatest(actionTypes.READ_CURRICULUM_REQUEST, readCurriculumSaga);
  yield takeLatest(actionTypes.DELETE_CURRICULUM_REQUEST, deleteCurriculumSaga);

  yield takeLatest(actionTypes.READ_JOURNALS_REQUEST, readJournalSaga);
  yield takeLatest(actionTypes.SAVE_JOURNAL_REQUEST, saveJournalSaga);
  yield takeLatest(actionTypes.UPDATE_JOURNAL_REQUEST, updateJournalSaga);
  yield takeLatest(actionTypes.DELETE_JOURNALS_REQUEST, deleteJournalSaga);
  yield takeLatest(actionTypes.SAVE_BG_JOURNAL_REQUEST, saveBGJournalSaga);
  yield takeLatest(actionTypes.UPDATE_BG_JOURNAL_REQUEST, updateBGJournalSaga);
  yield takeLatest(actionTypes.SAVE_JOURNAL_BANNER_REQUEST, saveJournalBannerSaga);
  yield takeLatest(actionTypes.UPDATE_JOURNAL_BANNER_REQUEST, updateJournalBannerSaga);

  yield takeLatest(actionTypes.ADD_JOURNAL_NOTE_REQUEST, addJournalNoteSaga);
  yield takeLatest(actionTypes.UPDATE_JOURNAL_NOTE_REQUEST, updateJournalNoteSaga);
  yield takeLatest(actionTypes.DELETE_JOURNAL_NOTE_REQUEST, deleteJournalNoteSaga);

  yield takeEvery(actionTypes.ADD_JOURNAL_NOTE_ITEM_REQUEST, addJournalNoteItemSaga);
  yield takeEvery(actionTypes.UPDATE_JOURNAL_NOTE_ITEM_REQUEST, updateJournalNoteItemSaga);
  yield takeEvery(actionTypes.DELETE_JOURNAL_NOTE_ITEM_REQUEST, deleteJournalNoteItemSaga);

  yield takeLatest(actionTypes.SAVE_COMMON_BG_CURRICULUM_REQUEST, saveCommonBGCurriculumSaga);
  yield takeLatest(actionTypes.UPDATE_COMMON_BG_CURRICULUM_REQUEST, updateCommonBGCurriculumSaga);

  yield takeLatest(actionTypes.SAVE_CURRICULUM_PREVIEW_REQUEST, saveCurriculumPreviewSaga);
  yield takeLatest(actionTypes.UPDATE_CURRICULUM_PREVIEW_REQUEST, updateCurriculumPreviewSaga);

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LANGUAGE_PREVIEW_REQUEST,
    saveCurriculumLanguagePreviewSaga
  );
  yield takeEvery(
    actionTypes.UPDATE_CURRICULUM_LANGUAGE_PREVIEW_REQUEST,
    updateCurriculumLanguagePreviewSaga
  );

  yield takeLatest(actionTypes.SAVE_BG_CURRICULUM_REQUEST, saveBGCurriculumSaga);
  yield takeLatest(actionTypes.UPDATE_BG_CURRICULUM_REQUEST, updateBGCurriculumSaga);
  yield takeLatest(actionTypes.READ_BG_CURRICULUM_REQUEST, readBGCurriculumSaga);
  yield takeLatest(actionTypes.DELETE_BG_CURRICULUM_REQUEST, deleteBGCurriculumSaga);

  yield takeLatest(actionTypes.SAVE_CURRICULUM_LANGUAGE_REQUEST, saveLanguagesCurriculumSaga);
  yield takeLatest(actionTypes.UPDATE_CURRICULUM_LANGUAGE_REQUEST, updateLanguagesCurriculumSaga);
  yield takeLatest(actionTypes.READ_CURRICULUM_LANGUAGE_REQUEST, readLanguagesCurriculumSaga);
  yield takeLatest(actionTypes.DELETE_CURRICULUM_LANGUAGE_REQUEST, deleteLanguagesCurriculumSaga);

  yield takeEvery(actionTypes.SAVE_BG_CURRICULUM_LANGUAGE_REQUEST, saveBGLanguagesCurriculumSaga);
  yield takeEvery(
    actionTypes.UPDATE_BG_CURRICULUM_LANGUAGE_REQUEST,
    updateBGLanguagesCurriculumSaga
  );
  yield takeLatest(actionTypes.READ_BG_CURRICULUM_REQUEST, readBGLanguagesCurriculumSaga);
  yield takeLatest(actionTypes.DELETE_BG_CURRICULUM_REQUEST, deleteBGLanguagesCurriculumSaga);

  yield takeEvery(actionTypes.SAVE_CURRICULUM_LESSON_REQUEST, saveCurriculumLessonSaga);
  yield takeLatest(actionTypes.UPDATE_CURRICULUM_LESSON_REQUEST, updateCurriculumLessonSaga);
  yield takeLatest(actionTypes.READ_CURRICULUM_LESSON_REQUEST, readCurriculumLessonSaga);
  yield takeLatest(actionTypes.DELETE_CURRICULUM_LESSON_REQUEST, deleteCurriculumLessonSaga);

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_REQUEST,
    saveCurriculumLessonLanguageSaga
  );
  yield takeEvery(
    actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_REQUEST,
    updateCurriculumLessonLanguageSaga
  );

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_PREVIEW_REQUEST,
    saveCurriculumLessonPreviewLanguageSaga
  );
  yield takeEvery(
    actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_PREVIEW_REQUEST,
    updateCurriculumLessonPreviewLanguageSaga
  );

  yield takeLatest(
    actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_REQUEST,
    readCurriculumLessonLanguageSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_REQUEST,
    deleteCurriculumLessonLanguageSaga
  );

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LESSON_THUMBNAIL_REQUEST,
    saveCurriculumLessonThumbNailSaga
  );
  yield takeEvery(
    actionTypes.UPDATE_CURRICULUM_LESSON_THUMBNAIL_REQUEST,
    updateCurriculumLessonThumbNailSaga
  );

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LESSON_COMMON_THUMBNAIL_REQUEST,
    saveCurriculumLessonCommonThumbNailSaga
  );
  yield takeEvery(
    actionTypes.UPDATE_CURRICULUM_LESSON_COMMON_THUMBNAIL_REQUEST,
    updateCurriculumLessonCommonThumbNailSaga
  );

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LESSON_PREVIEW_REQUEST,
    saveCurriculumLessonPreviewSaga
  );
  yield takeEvery(
    actionTypes.UPDATE_CURRICULUM_LESSON_PREVIEW_REQUEST,
    updateCurriculumLessonPreviewSaga
  );

  yield takeLatest(
    actionTypes.READ_CURRICULUM_LESSON_THUMBNAIL_REQUEST,
    readCurriculumLessonThumbNailSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LESSON_THUMBNAIL_REQUEST,
    deleteCurriculumLessonThumbNailSaga
  );

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST,
    saveCurriculumLanguageLessonThumbNailSaga
  );
  yield takeEvery(
    actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST,
    updateCurriculumLanguageLessonThumbNailSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST,
    readCurriculumLanguageLessonThumbNailSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_THUMBNAIL_REQUEST,
    deleteCurriculumLanguageLessonThumbNailSaga
  );

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_LANGUAGE_REQUEST,
    saveCurriculumLessonSlideLanguageSaga
  );
  yield takeEvery(
    actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_LANGUAGE_REQUEST,
    updateCurriculumLessonSlideLanguageSaga
  );

  yield takeEvery(actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_REQUEST, saveCurriculumLessonSlideSaga);
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_REQUEST,
    updateCurriculumLessonSlideSaga
  );
  yield takeLatest(actionTypes.READ_CURRICULUM_LESSON_SLIDE_REQUEST, readCurriculumLessonSlideSaga);
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_REQUEST,
    deleteCurriculumLessonSlideSaga
  );

  yield takeLatest(
    actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST,
    saveCurriculumLanguageLessonSlideSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST,
    updateCurriculumLanguageLessonSlideSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST,
    readCurriculumLanguageLessonSlideSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_REQUEST,
    deleteCurriculumLanguageLessonSlideSaga
  );

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LESSON_BG_SLIDE_REQUEST,
    saveCurriculumLessonSlideBGSaga
  );
  yield takeEvery(
    actionTypes.UPDATE_CURRICULUM_LESSON_BG_SLIDE_REQUEST,
    updateCurriculumLessonSlideBGSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LESSON_BG_SLIDE_REQUEST,
    readCurriculumLessonSlideBGSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LESSON_BG_SLIDE_REQUEST,
    deleteCurriculumLessonSlideBGSaga
  );

  yield takeLatest(
    actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_BG_SLIDE_REQUEST,
    saveCurriculumLanguageLessonSlideBGSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_BG_SLIDE_REQUEST,
    updateCurriculumLanguageLessonSlideBGSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_BG_SLIDE_REQUEST,
    readCurriculumLanguageLessonSlideBGSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_BG_SLIDE_REQUEST,
    deleteCurriculumLanguageLessonSlideBGSaga
  );

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST,
    saveCurriculumLessonSlideTypeSaga
  );

  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST,
    updateCurriculumLessonSlideTypeSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST,
    readCurriculumLessonSlideTypeSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_REQUEST,
    deleteCurriculumLessonSlideTypeSaga
  );

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST,
    saveCurriculumLanguageLessonSlideTypeSaga
  );
  yield takeEvery(
    actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST,
    updateCurriculumLanguageLessonSlideTypeSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST,
    readCurriculumLanguageLessonSlideTypeSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_REQUEST,
    deleteCurriculumLanguageLessonSlideTypeSaga
  );

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST,
    saveCurriculumLessonSlideTypeBGSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST,
    updateCurriculumLessonSlideTypeBGSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST,
    readCurriculumLessonSlideTypeBGSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_BG_REQUEST,
    deleteCurriculumLessonSlideTypeBGSaga
  );

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST,
    saveCurriculumLanguageLessonSlideTypeBGSaga
  );
  yield takeEvery(
    actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST,
    updateCurriculumLanguageLessonSlideTypeBGSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST,
    readCurriculumLanguageLessonSlideTypeBGSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_BG_REQUEST,
    deleteCurriculumLanguageLessonSlideTypeBGSaga
  );

  yield takeLatest(
    actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    saveCurriculumLessonSlideTypeAssetSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    updateCurriculumLessonSlideTypeAssetSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    readCurriculumLessonSlideTypeAssetSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    deleteCurriculumLessonSlideTypeAssetSaga
  );

  yield takeLatest(
    actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    saveCurriculumLanguageLessonSlideTypeAssetSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    updateCurriculumLanguageLessonSlideTypeAssetSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    readCurriculumLanguageLessonSlideTypeAssetSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_ASSET_REQUEST,
    deleteCurriculumLanguageLessonSlideTypeAssetSaga
  );

  yield takeEvery(
    actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST,
    saveCurriculumLessonSlideTypeLinkSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST,
    updateCurriculumLessonSlideTypeLinkSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST,
    readCurriculumLessonSlideTypeLinkSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_LINK_REQUEST,
    deleteCurriculumLessonSlideTypeLinkSaga
  );

  yield takeLatest(
    actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST,
    saveCurriculumLanguageLessonSlideTypeLinkSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST,
    updateCurriculumLanguageLessonSlideTypeLinkSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST,
    readCurriculumLanguageLessonSlideTypeLinkSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_LINK_REQUEST,
    deleteCurriculumLanguageLessonSlideTypeLinkSaga
  );

  yield takeLatest(
    actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST,
    saveCurriculumLessonSlideTypePollSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST,
    updateCurriculumLessonSlideTypePollSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST,
    readCurriculumLessonSlideTypePollSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_REQUEST,
    deleteCurriculumLessonSlideTypePollSaga
  );

  yield takeLatest(
    actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST,
    saveCurriculumLanguageLessonSlideTypePollSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST,
    updateCurriculumLanguageLessonSlideTypePollSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST,
    readCurriculumLanguageLessonSlideTypePollSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_REQUEST,
    deleteCurriculumLanguageLessonSlideTypePollSaga
  );

  yield takeLatest(
    actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    saveCurriculumLessonSlideTypePollOptionsSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    updateCurriculumLessonSlideTypePollOptionsSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    readCurriculumLessonSlideTypePollOptionsSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    deleteCurriculumLessonSlideTypePollOptionsSaga
  );
  yield takeLatest(
    actionTypes.SAVE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    saveCurriculumLessonSlideTypePollOptionsVoteSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    updateCurriculumLessonSlideTypePollOptionsVoteSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    readCurriculumLessonSlideTypePollOptionsVoteSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    deleteCurriculumLessonSlideTypePollOptionsVoteSaga
  );

  yield takeLatest(
    actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    saveCurriculumLanguageLessonSlideTypePollOptionsSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    updateCurriculumLanguageLessonSlideTypePollOptionsSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    readCurriculumLanguageLessonSlideTypePollOptionsSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_REQUEST,
    deleteCurriculumLanguageLessonSlideTypePollOptionsSaga
  );
  yield takeLatest(
    actionTypes.SAVE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    saveCurriculumLanguageLessonSlideTypePollOptionsVoteSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    updateCurriculumLanguageLessonSlideTypePollOptionsVoteSaga
  );
  yield takeLatest(
    actionTypes.READ_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    readCurriculumLanguageLessonSlideTypePollOptionsVoteSaga
  );
  yield takeLatest(
    actionTypes.DELETE_CURRICULUM_LANGUAGE_LESSON_SLIDE_TYPE_POLL_OPTIONS_VOTE_REQUEST,
    deleteCurriculumLanguageLessonSlideTypePollOptionsVoteSaga
  );

  yield takeLatest(actionTypes.SAVE_VIDEOS_REQUEST, saveVideosSaga);
  yield takeLatest(actionTypes.UPDATE_VIDEOS_REQUEST, updateVideosSaga);
  yield takeEvery(actionTypes.READ_VIDEOS_REQUEST, readVideosSaga);
  yield takeLatest(actionTypes.DELETE_VIDEOS_REQUEST, deleteVideosSaga);

  yield takeLatest(actionTypes.SAVE_VIDEOS_LANGUAGE_REQUEST, saveLanguageVideosSaga);
  yield takeLatest(actionTypes.UPDATE_VIDEOS_LANGUAGE_REQUEST, updateLanguageVideosSaga);
  yield takeLatest(actionTypes.READ_VIDEOS_LANGUAGE_REQUEST, readLanguageVideosSaga);
  yield takeLatest(actionTypes.DELETE_VIDEOS_LANGUAGE_REQUEST, deleteLanguageVideosSaga);

  yield takeLatest(actionTypes.SAVE_VIDEOS_URLS_REQUEST, saveVideosUrlsSaga);

  yield takeLatest(actionTypes.SAVE_COMMON_URLS_REQUEST, saveCommonsUrlsSaga);

  yield takeLatest(actionTypes.UPDATE_COMMON_URLS_REQUEST, updateCommonsUrlsSaga);

  yield takeLatest(actionTypes.SAVE_VIDEO_PREVIEW_URLS_REQUEST, saveVideoPreviewUrlsSaga);

  yield takeLatest(actionTypes.UPDATE_VIDEO_PREVIEW_URLS_REQUEST, updateVideoPreviewUrlsSaga);

  yield takeLatest(actionTypes.SAVE_VIDEO_LANGUAGE_REQUEST, saveVideoLanguageSaga);

  yield takeLatest(actionTypes.SAVE_LAYOUT_LANGUAGE_REQUEST, saveLayoutLanguageSaga);

  yield takeLatest(actionTypes.UPDATE_LAYOUT_LANGUAGE_REQUEST, updateLayoutLanguageSaga);

  yield takeLatest(actionTypes.SAVE_CATEGORIES_LANGUAGE_REQUEST, saveCategoriesLanguageSage);

  yield takeLatest(actionTypes.UPDATE_CATEGORIES_LANGUAGE_REQUEST, updateCategoriesLanguageSage);

  yield takeLatest(
    actionTypes.SAVE_CATEGORIES_PREVIEW_LANGUAGE_REQUEST,
    saveCategoriesPreviewLanguageSage
  );

  yield takeLatest(
    actionTypes.UPDATE_CATEGORIES_PREVIEW_LANGUAGE_REQUEST,
    updateCategoriesPreviewLanguageSage
  );

  yield takeLatest(actionTypes.UPDATE_VIDEO_LANGUAGE_REQUEST, updateVideoLanguageSaga);

  yield takeLatest(actionTypes.SAVE_VIDEO_URL_LANGUAGE_REQUEST, saveVideoUrlLanguageSaga);

  yield takeLatest(actionTypes.UPDATE_VIDEO_URL_LANGUAGE_REQUEST, updateVideoUrlLanguageSaga);
  yield takeLatest(
    actionTypes.SAVE_VIDEO_DOWNLOAD_URL_LANGUAGE_REQUEST,
    saveDownloadVideoUrlLanguageSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_VIDEO_DOWNLOAD_URL_LANGUAGE_REQUEST,
    updateDownloadVideoUrlLanguageSaga
  );
  yield takeLatest(actionTypes.SAVE_VIDEO_PREVIEW_LANGUAGE_REQUEST, saveVideoPreviewLanguageSaga);
  yield takeLatest(
    actionTypes.UPDATE_VIDEO_PREVIEW_LANGUAGE_REQUEST,
    updateVideoPreviewLanguageSaga
  );
  yield takeLatest(actionTypes.UPDATE_VIDEOS_URLS_REQUEST, updateVideosUrlsSaga);
  yield takeLatest(actionTypes.READ_VIDEOS_URLS_REQUEST, readVideosUrlsSaga);
  yield takeLatest(actionTypes.DELETE_VIDEOS_URLS_REQUEST, deleteVideosUrlsSaga);

  yield takeLatest(actionTypes.SAVE_VIDEOS_URLS_LANGUAGE_REQUEST, saveLanguageVideosUrlsSaga);
  yield takeLatest(actionTypes.UPDATE_VIDEOS_URLS_LANGUAGE_REQUEST, updateLanguageVideosUrlsSaga);
  yield takeLatest(actionTypes.READ_VIDEOS_URLS_LANGUAGE_REQUEST, readLanguageVideosUrlsSaga);
  yield takeLatest(actionTypes.DELETE_VIDEOS_URLS_LANGUAGE_REQUEST, deleteLanguageVideosUrlsSaga);

  yield takeLatest(actionTypes.SAVE_VIDEOS_DOWNLOAD_URLS_REQUEST, saveVideosDownloadUrlsSaga);
  yield takeLatest(actionTypes.UPDATE_VIDEOS_DOWNLOAD_URLS_REQUEST, updateVideosDownloadUrlsSaga);
  yield takeLatest(actionTypes.READ_VIDEOS_DOWNLOAD_URLS_REQUEST, readVideosDownloadUrlsSaga);
  yield takeLatest(actionTypes.DELETE_VIDEOS_DOWNLOAD_URLS_REQUEST, deleteVideosDownloadUrlsSaga);

  yield takeLatest(
    actionTypes.SAVE_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST,
    saveLanguageVideosDownloadUrlsSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST,
    updateLanguageVideosDownloadUrlsSaga
  );
  yield takeLatest(
    actionTypes.READ_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST,
    readLanguageVideosDownloadUrlsSaga
  );
  yield takeLatest(
    actionTypes.DELETE_LANGUAGE_VIDEOS_DOWNLOAD_URLS_REQUEST,
    deleteLanguageVideosDownloadUrlsSaga
  );

  yield takeLatest(actionTypes.SAVE_VIDEOS_THUMBNAIL_URLS_REQUEST, saveVideosThumbNailUrlsSaga);
  yield takeLatest(actionTypes.UPDATE_VIDEOS_THUMBNAIL_URLS_REQUEST, updateVideosThumbNailUrlsSaga);
  yield takeLatest(actionTypes.READ_VIDEOS_THUMBNAIL_URLS_REQUEST, readVideosThumbNailUrlsSaga);
  yield takeLatest(actionTypes.DELETE_VIDEOS_THUMBNAIL_URLS_REQUEST, deleteVideosThumbNailUrlsSaga);

  yield takeLatest(
    actionTypes.SAVE_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST,
    saveLanguageVideosThumbNailUrlsSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST,
    updateLanguageVideosThumbNailUrlsSaga
  );
  yield takeLatest(
    actionTypes.READ_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST,
    readLanguageVideosThumbNailUrlsSaga
  );
  yield takeLatest(
    actionTypes.DELETE_LANGUAGE_VIDEOS_THUMBNAIL_URLS_REQUEST,
    deleteLanguageVideosThumbNailUrlsSaga
  );

  yield takeLatest(actionTypes.SAVE_VIDEOS_RELATED_REQUEST, saveRelatedVideosSaga);
  yield takeLatest(actionTypes.READ_VIDEOS_RELATED_REQUEST, readRelatedVideosSaga);
  yield takeLatest(actionTypes.DELETE_VIDEOS_RELATED_REQUEST, deleteRelatedVideosSaga);

  yield takeLatest(actionTypes.SAVE_CATEGORIES_REQUEST, saveCategoriesSaga);

  yield takeLatest(
    actionTypes.SAVE_COMMON_THUMBNAIL_CATEGORIES_REQUEST,
    saveCommonThumbnailCategoriesSaga
  );

  yield takeLatest(
    actionTypes.UPDATE_COMMON_THUMBNAIL_CATEGORIES_REQUEST,
    updateCommonThumbnailCategoriesSaga
  );

  yield takeLatest(actionTypes.SAVE_CATEGORY_PREVIEW_REQUEST, saveCategoryPreviewSaga);

  yield takeLatest(actionTypes.UPDATE_CATEGORY_PREVIEW_REQUEST, updateCategoryPreviewSaga);

  yield takeLatest(actionTypes.UPDATE_CATEGORIES_REQUEST, updateCategoriesSaga);
  yield takeEvery(actionTypes.READ_CATEGORIES_REQUEST, readCategoriesSaga);
  yield takeLatest(actionTypes.DELETE_CATEGORIES_REQUEST, deleteCategoriesSaga);

  yield takeLatest(actionTypes.SAVE_CATEGORIES_ICON_REQUEST, saveCategoriesIconSaga);
  yield takeLatest(actionTypes.UPDATE_CATEGORIES_ICON_REQUEST, updateCategoriesIconSaga);
  yield takeLatest(actionTypes.READ_CATEGORIES_ICON_REQUEST, readCategoriesIconSaga);
  yield takeLatest(actionTypes.DELETE_CATEGORIES_ICON_REQUEST, deleteCategoriesIconSaga);

  yield takeLatest(actionTypes.SAVE_CATEGORIES_RELATED_REQUEST, saveCategoriesRelatedSaga);
  yield takeLatest(actionTypes.UPDATE_CATEGORIES_RELATED_REQUEST, updateCategoriesRelatedSaga);
  yield takeLatest(actionTypes.READ_CATEGORIES_RELATED_REQUEST, readCategoriesRelatedSaga);
  yield takeEvery(actionTypes.DELETE_CATEGORIES_RELATED_REQUEST, deleteCategoriesRelatedSaga);

  yield takeLatest(actionTypes.SAVE_LANGUAGE_CATEGORIES_REQUEST, saveLanguagesCategoriesSaga);
  yield takeLatest(actionTypes.UPDATE_LANGUAGE_CATEGORIES_REQUEST, updateLanguagesCategoriesSaga);
  yield takeLatest(actionTypes.READ_LANGUAGE_CATEGORIES_REQUEST, readLanguagesCategoriesSaga);
  yield takeLatest(actionTypes.DELETE_LANGUAGE_CATEGORIES_REQUEST, deleteLanguagesCategoriesSaga);
}
