import { useState } from "react";
import { useDispatch } from "react-redux";
import { getUserTransactionsAction } from "../../../store/Givings/GivingsAction";

const GivingsHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export default GivingsHooks;

export const AllGivingsHooks = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState({});
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };
  const openGivingHandler = (row) => {
    setModalIsOpen(true);
    setTransactionInfo({
      _id: row?._id,
      transactionId: row.payment_gateway_reff || "N/A",
      date: row.payment_status_update_date,
      country: row.country || 'N/A',
      paymentType: row.mode_of_payment || "N/A",
      MOP: row.MOP,
      amount: row.amount,
    });
  };

  const handleRefund = (row) => {
    dispatch(getUserTransactionsAction({
      apiPayloadRequest: {
        _id: row?._id,
        payment_status: 1
      }
    }))
  }

  return {
    value,
    onChangeSearchBar,
    openGivingHandler,
    modalIsOpen,
    setModalIsOpen,
    transactionInfo,
    handleRefund
  };
};

export const AddGivingHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar, };
};
