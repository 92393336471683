import * as actionTypes from "../ActionTypes";
import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import * as api from "./TestimonyAPI";
import { actionUpdateGlobalLoaderSagaAction } from "../GlobalLoader/GlobalLoaderAction";

function* readTestimonyCategoriesRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readTestimonyCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveTestimonyCategoriesRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveTestimonyCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateTestimonyCategoriesRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateTestimonyCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteTestimonyCategoriesRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteTestimonyCategories, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readTestimonyRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readTestimony, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* accetTestimonyRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.acceptTestimony, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* rejectTestimonyRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.rejectTestimony, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* testimonyOfTheDayRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.testimonyOfTheDay, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

export default function* TestimonyWatcherSaga() {
  yield takeLatest(actionTypes.READ_TESTIMONY_CATEGORY_REQUEST, readTestimonyCategoriesRequestSaga);
  yield takeLatest(
    actionTypes.DELETE_TESTIMONY_CATEGORY_REQUEST,
    deleteTestimonyCategoriesRequestSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_TESTIMONY_CATEGORY_REQUEST,
    updateTestimonyCategoriesRequestSaga
  );
  yield takeLatest(actionTypes.SAVE_TESTIMONY_CATEGORY_REQUEST, saveTestimonyCategoriesRequestSaga);

  yield takeEvery(actionTypes.READ_TESTIMONY_REQUEST, readTestimonyRequestSaga);

  yield takeLatest(actionTypes.POST_ACCEPT_TESTIMONY_REQUEST, accetTestimonyRequestSaga);

  yield takeLatest(actionTypes.POST_REJECT_TESTIMONY_REQUEST, rejectTestimonyRequestSaga);

  yield takeLatest(actionTypes.POST_TESTIMONY_OF_THE_DAY_REQUEST, testimonyOfTheDayRequestSaga);
}
