import SearchBar from "../../components/universal/SearchBar";
import { AddNotificationsListHooks } from "./hooks/NotificationsListHooks";
import AddNotificationsFields from "../../components/notificationsList/AddNotificationsFields";
import Navbar from "../../components/universal/Navbar";
import { useLocation } from "react-router-dom";
function AddNotifications() {
  // search bar component state with handler
  const { value, onChangeSearchBar } = AddNotificationsListHooks();
  const location = useLocation();

  console.log(location, "testid");

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex justify-between"></div>
            <AddNotificationsFields id={location?.state?._id} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNotifications;
