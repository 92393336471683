import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import {
  deleteCategoriesAction,
  deleteCategoriesRelatedAction,
  readCategoriesAction,
  readVideosAction,
  saveCategoriesAction,
  saveCategoriesIconAction,
  saveCategoriesLanguageAction,
  saveCategoriesPreviewLanguageAction,
  saveCategoriesRelatedAction,
  saveCategoryPreviewAction,
  saveCommonThumbnailCategoriesAction,
  translateLanguageAction,
  updateCategoriesAction,
  updateCategoriesIconAction,
  updateCategoriesLanguageAction,
  updateCategoriesPreviewLanguageAction,
  updateCategoriesRelatedAction,
  updateCategoryPreviewAction,
  updateCommonThumbnailCategoriesAction,
  uploadAssestsV2Action,
  uploadVideosV2Action,
} from "../../../store/Languages/LanguageAction";
import {
  deleteCategoryLayoutAction,
  readCategoryLayoutAction,
  saveCategoryLayoutAction,
  updateCategoryLayoutAction,
} from "../../../store/Layout/LayoutAction";

import Toastiy from "../../../components/universal/Toastiy";
import { setShowNavigationAlertAction } from "../../../store/GlobalLoader/GlobalLoaderAction";
import { sendCustomNotificationAction } from "../../../store/Notifications/NotificationAction";

export const CategoriesHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export const AllCategoriesHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [reloadData, setReloadData] = useState(false);

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const fetchAllCategories = (page, limit, searchCriteria = {}, callbackFn) => {
    console.log(
      "page",
      page,
      "limit",
      limit,
      "searchCriteria",
      searchCriteria,
      "callbackFn",
      callbackFn
    );
    dispatch(
      readCategoriesAction({
        apiPayloadRequest: {
          page: page,
          limit: limit,
          searchCriteria: searchCriteria,
        },
        callback: (Data) => {
          if (Data?.type === 1) {
            if (callbackFn)
              callbackFn({
                data: Data?.data?.map((d) => ({ ...d, views: d?.views ?? 0 })),
                total: Data?.totalRecords ?? 0,
              });
          } else if (callbackFn) callbackFn({ data: [], total: Data?.totalRecords ?? 0 });
        },
      })
    );
  };

  const handleEyeClick = (row) => {
    navigate("/categories/add", { state: { _id: row._id } });
    // setAllCategoriesData((prevData) => ({
    //   ...prevData,
    //   category_name: row?.category_name,
    //   category_description: row?.category_description,
    //   mobile_layout: row?.mobile_layout,
    // }));
  };

  const handleDelete = (row, index) => {
    dispatch(
      deleteCategoriesAction({
        apiPayloadRequest: { _id: row?._id },
        callback: (res) => getCategoriesDeletedResponse(res, "Categories Deleted Successfully"),
      })
    );

    const getCategoriesDeletedResponse = (data, messsage) => {
      if (data?.type === 1) {
        Toastiy(messsage, data?.type);
        setReloadData(true);
      } else {
        Toastiy("Issue performing the operation, contact admin!!", data?.type);
      }
    };
  };

  return {
    value,
    onChangeSearchBar,
    handleDelete,
    handleEyeClick,
    fetchAllCategories,
    reloadData,
    setReloadData,
  };
};

export const AddCategoriesHooks = (data, options, setLoading) => {
  // search logic here
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [languagemeta, setLanguagemeta] = useState([]);
  const [isLanguageSave, setIsLanguageSave] = useState(true);
  const [activeLanguageDetails, setActiveLanguageDetails] = useState({
    language_code: "en",
    language_name: "English",
  });

  const [showVideoLibraryModal, setShowVideoLibraryModal] = useState(false);
  const [activeTab, setActiveTab] = useState("en");

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedRelatedVideoIndex, setSelectedRelatedVideoIndex] = useState(null);
  const [translateCategories, setTranslateCategories] = useState([]);

  // const [options, setOptions] = useState([]);
  const [layoutInfo, setLayoutInfo] = useState(false);
  const [addedLayout, setAddedLayout] = useState(false);
  const [layoutArray, setLayoutArray] = useState([]);
  const [isLayoutEdit, setIsLayoutEdit] = useState(false);
  const [editLayoutIndex, setEditLayoutIndex] = useState(null);
  const [editTargetLayout, setEditTargetLayout] = useState("");
  const [categoryStats, setCategoryStats] = useState({});

  const initialLayoutValues = {
    _id: "",
    mobile_layout: "1",
    layout_name: "",
    order: "",
    // title: "",
    // subtitle: "",
    // expiry: "",
  };

  const [layoutValues, setLayoutValues] = useState(initialLayoutValues);

  const initialCategory = {
    category_name: "",
    category_description: "",
    mobile_layout: layoutValues.mobile_layout,
    mobileUploadUrl: "",
    tabletUploadUrl: "",
    webUploadUrl: "",
    commonMobileUploadUrl: "",
    commonTabletUploadUrl: "",
    commonWebUploadUrl: "",
    mobilePreviewUrl: "",
    tabletPreviewUrl: "",
    webPreviewUrl: "",
  };

  const [inputs, setInputs] = useState("");
  const [categoryInputs, setCategoryInputs] = useState(initialCategory);
  const [categoryErrors, setCategoryErrors] = useState({
    category_name: false,
    category_description: false,
    video_urls: false,
    common_thumbnail_urls: false,
    preview_urls: false,
    selectedOptions: false,
  });

  const [currentInputs, setCurrentInputs] = useState({});

  const languages = ["english", "hindi", "tamil"]; // List of languages
  // Set the first language as active

  useEffect(() => {
    return () => setShowNavigationAlert(false);
  }, []);

  const setShowNavigationAlert = (value) => {
    dispatch(setShowNavigationAlertAction(value));
  };

  const handleDropdownChange = (selectedValues) => {
    console.log("selectedValues", selectedValues);
    console.log("selectedOptions", selectedOptions);
    console.log("selectedRelatedVideoIndex", selectedRelatedVideoIndex);
    const videoItem = {
      value: selectedValues?._id,
      label: selectedValues?.video_title,
      image:
        selectedValues?.tumbnailurls?.length > 0 ? selectedValues?.tumbnailurls[0]?.url_link : "",
      order:
        selectedRelatedVideoIndex !== null
          ? selectedOptions[selectedRelatedVideoIndex]?.order
          : selectedOptions?.length + 1,
    };

    if (data?._id !== "" && data?._id !== undefined) {
      if (selectedRelatedVideoIndex !== null && selectedOptions[selectedRelatedVideoIndex]?.value) {
        dispatch(
          deleteCategoriesRelatedAction({
            apiPayloadRequest: {
              _id: data?._id,
              videoObjId: selectedOptions[selectedRelatedVideoIndex]?.value,
            },
            callback: (res) => {
              if (res?.type === 1) {
                //show message
                dispatch(
                  saveCategoriesRelatedAction({
                    apiPayloadRequest: {
                      _id: data?._id,
                      videoObjId: videoItem?.value,
                      order: videoItem?.order,
                    },
                    callback: (res) => {
                      if (res?.type === 1) {
                        Toastiy("Related Video Updated Successfully!", 1);
                        setSelectedOptions((prev) =>
                          prev?.map((d, i) => (i === selectedRelatedVideoIndex ? videoItem : d))
                        );
                      } else {
                        Toastiy("Failed to Update Video");
                      }
                    },
                  })
                );
              }
            },
          })
        );
      } else {
        dispatch(
          saveCategoriesRelatedAction({
            apiPayloadRequest: {
              _id: data?._id,
              videoObjId: videoItem?.value,
              order: videoItem?.order,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Related Video Added Successfully!", 1);
                setSelectedOptions((prev) => [...prev, videoItem]);
              } else {
                Toastiy("Failed to Add Video");
              }
            },
          })
        );
      }
    } else {
      if (selectedRelatedVideoIndex !== null) {
        setSelectedOptions((prev) =>
          prev?.map((d, i) => (i === selectedRelatedVideoIndex ? videoItem : d))
        );
      } else {
        setSelectedOptions((prev) => [...prev, videoItem]);
      }
      setShowNavigationAlert(true);
    }
    setShowVideoLibraryModal(false);
    setCategoryErrors({ ...categoryErrors, selectedOptions: false });
    setSelectedRelatedVideoIndex(null);
  };

  const deleteRelatedVideo = (videoObjId) => {
    if (data?._id) {
      dispatch(
        deleteCategoriesRelatedAction({
          apiPayloadRequest: {
            _id: data?._id,
            videoObjId: videoObjId,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Related Video Removed Successfully!", 1);
              setSelectedOptions((prev) => prev.filter((item) => item.value !== videoObjId));
            } else {
              Toastiy("Failed to Delete Video");
            }
          },
        })
      );
    } else {
      setSelectedOptions((prev) => prev.filter((item) => item.value !== videoObjId));
    }
    setSelectedRelatedVideoIndex(null);
  };

  const updateRelatedVideoOrder = (item) => {
    if (data?._id) {
      dispatch(
        updateCategoriesRelatedAction({
          apiPayloadRequest: {
            _id: data?._id,
            videoObjId: item?.value,
            order: item?.order,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Related Video Updated Successfully!", 1);
              setSelectedOptions((prev) => prev.map((d) => (d?.value !== item?.value ? d : item)));
            } else {
              Toastiy("Failed to Update Video");
            }
          },
        })
      );
    } else {
      setSelectedOptions((prev) => prev.map((d) => (d?.value !== item?.value ? d : item)));
    }
    setSelectedRelatedVideoIndex(null);
  };

  const handleDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
    setShowNavigationAlert(true);
  };

  const handleCategoryLayoutChange = (event) => {
    const { name, value } = event.target;
    setLayoutValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setShowNavigationAlert(true);
  };

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const handleUploadInput = (event, errorKeyName, uploadProgressId = null) => {
    const { name, files } = event.target;

    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadAssestsV2Action({
        apiPayloadRequest: formData,
        uploadProgressId: uploadProgressId,
        callback: (res) => {
          setCategoryInputs((prevInputs) => ({
            ...prevInputs,
            [name]: res?.url,
          }));
          setCategoryErrors({ ...categoryErrors, [errorKeyName]: false });
          setLoading(false);
          setShowNavigationAlert(true);
        },
      })
    );
  };

  const handleUploadVideo = (event, errorKeyName, uploadProgressId = null) => {
    const { name, files } = event.target;

    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadVideosV2Action({
        apiPayloadRequest: formData,
        uploadProgressId: uploadProgressId,
        callback: (res) => {
          setCategoryInputs((prevInputs) => ({
            ...prevInputs,
            [name]: res?.url,
          }));
          setCategoryErrors({ ...categoryErrors, [errorKeyName]: false });
          setLoading(false);
          setShowNavigationAlert(true);
        },
      })
    );
  };

  const handleInputChange = (event, errorKeyName = null) => {
    const { name, value } = event.target;
    setCategoryInputs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setCategoryErrors({ ...categoryErrors, [errorKeyName ?? name]: false });
    setShowNavigationAlert(true);
    // switch (name) {
    //   case 'mobile_layout':
    //     setLayoutValue(value)
    // }
  };

  const handleVideoDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
    setShowNavigationAlert(true);
  };

  const initialUrls = {
    mobileUploadUrl: "",
    webUploadUrl: "",
    tabletUploadUrl: "",
  };

  const initialCommonUrls = {
    commonMobileUploadUrl: "",
    commonWebUploadUrl: "",
    commonTabletUploadUrl: "",
  };

  const initialPreviewUrls = {
    mobilePreviewUrl: "",
    webPreviewUrl: "",
    tabletPreviewUrl: "",
  };

  //Layout handles here

  const handleAddLayoutToArray = () => {
    if (data?._id !== undefined && data?._id !== "") {
      dispatch(
        saveCategoryLayoutAction({
          apiPayloadRequest: {
            _id: layoutValues?._id,
            categoryObjId: data?._id,
            order: layoutValues?.order,
            // expiry: layoutValues?.expiry,
            // title: layoutValues?.title,
            // subtitle: layoutValues?.subtitle,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Layout Added Successfully!", 1);
              setLayoutArray((prev) => [...prev, layoutValues]);
              setLayoutValues(initialLayoutValues);
              setAddedLayout(true);
              setShowNavigationAlert(false);
            }
          },
        })
      );
    } else {
      setLayoutArray((prev) => [...prev, layoutValues]);
      setLayoutValues(initialLayoutValues);
      setAddedLayout(true);
      setShowNavigationAlert(true);
    }
  };

  //Change to Category API
  const handleLayoutUpdate = () => {
    if (data?._id !== undefined && data?._id !== "") {
      dispatch(
        updateCategoryLayoutAction({
          apiPayloadRequest: {
            _id: layoutValues._id,
            categoryObjId: data?._id,
            order: layoutValues?.order,
            // title: layoutArray._id,
            // subtitle: layoutArray._id,
            // expiry: layoutArray._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setLayoutValues(initialLayoutValues);
              setIsLayoutEdit(false);
              Toastiy("Layout Updated Successfully!", 1);
              setShowNavigationAlert(false);
            }
          },
        })
      );
    } else {
      setLayoutValues(initialLayoutValues);
      setIsLayoutEdit(false);
      setShowNavigationAlert(true);
    }
  };

  const handleDeleteLayoutFromArray = (id) => {
    if (data?._id !== undefined && data?._id !== "") {
      dispatch(
        deleteCategoryLayoutAction({
          apiPayloadRequest: {
            _id: id,
            categoryObjId: data?._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const updatedArray = layoutArray.filter((item) => item._id !== id);
              setLayoutArray(updatedArray);
            }
          },
        })
      );
    } else {
      const updatedArray = layoutArray.filter((item) => item._id !== id);
      setLayoutArray(updatedArray);
    }
  };

  const handleVideoLayoutChange = (event) => {
    if (isLayoutEdit) {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));

      setLayoutArray((prev) => {
        const updatedArray = [...prev];

        updatedArray[editLayoutIndex] = {
          ...updatedArray[editLayoutIndex],
          [name]: value,
        };
        return updatedArray;
      });
      setShowNavigationAlert(true);
    } else {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));
      setShowNavigationAlert(true);
    }
  };

  const handleSave = () => {
    if (data?._id !== "" && data?._id !== undefined) {
      const promiseArray = [];
      if (
        categoryInputs?.category_name !== currentInputs?.category_name ||
        categoryInputs?.category_description !== currentInputs?.category_description
      ) {
        setLoading(true);
        promiseArray.push(
          new Promise((resolve, reject) => {
            dispatch(
              updateCategoriesAction({
                apiPayloadRequest: {
                  _id: data?._id,
                  category_name: categoryInputs?.category_name,
                  category_description: categoryInputs?.category_description,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Categories Updated Sucessfully", 1);
                    navigate("/categories/all");
                  } else {
                    Toastiy("Categories Update Failed", 2);
                  }
                  setLoading(false);
                  setShowNavigationAlert(false);
                  resolve();
                },
              })
            );
          })
        );
      }

      const keysArray = Object.keys(initialUrls);
      keysArray.map((item) => {
        if (!currentInputs[item] && categoryInputs[item]) {
          setLoading(true);
          promiseArray.push(
            new Promise((resolve, reject) => {
              dispatch(
                saveCategoriesIconAction({
                  apiPayloadRequest: {
                    _id: data?._id,
                    type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                    url_link: categoryInputs[item],
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Categories Icon Sucessfully", 1);
                    } else {
                      Toastiy("Categories Icon Failed", 2);
                    }
                    setLoading(false);
                    setShowNavigationAlert(false);
                    resolve();
                  },
                })
              );
            })
          );
        } else if (categoryInputs[item] !== currentInputs[item]) {
          setLoading(true);
          promiseArray.push(
            new Promise((resolve, reject) => {
              dispatch(
                updateCategoriesIconAction({
                  apiPayloadRequest: {
                    _id: data?._id,
                    type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                    url_link: categoryInputs[item],
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Categories Icon Sucessfully", 1);
                    } else {
                      Toastiy("Categories Icon Failed", 2);
                    }
                    setLoading(false);
                    setShowNavigationAlert(false);
                    resolve();
                  },
                })
              );
            })
          );
        }
      });

      const commonKeysArray = Object.keys(initialCommonUrls);
      commonKeysArray.map((item) => {
        if (!currentInputs[item] && categoryInputs[item]) {
          setLoading(true);
          promiseArray.push(
            new Promise((resolve, reject) => {
              dispatch(
                saveCommonThumbnailCategoriesAction({
                  apiPayloadRequest: {
                    _id: data?._id,
                    type_of_url:
                      item === "commonMobileUploadUrl" ? 0 : item === "commonWebUploadUrl" ? 1 : 2,
                    url_link: categoryInputs[item],
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Categories Common Thumbnail Updated Sucessfully", 1);
                      navigate("/categories/all");
                    } else {
                      Toastiy("Categories Common Thumbnail Update Failed", 2);
                    }
                    setLoading(false);
                    setShowNavigationAlert(false);
                    resolve();
                  },
                })
              );
            })
          );
        } else if (categoryInputs[item] !== currentInputs[item]) {
          setLoading(true);
          promiseArray.push(
            new Promise((resolve, reject) => {
              dispatch(
                updateCommonThumbnailCategoriesAction({
                  apiPayloadRequest: {
                    _id: data?._id,
                    type_of_url:
                      item === "commonMobileUploadUrl" ? 0 : item === "commonWebUploadUrl" ? 1 : 2,
                    url_link: categoryInputs[item],
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Categories Common Thumbnail Updated Sucessfully", 1);
                      navigate("/categories/all");
                    } else {
                      Toastiy("Categories Common Thumbnail Update Failed", 2);
                    }
                    setLoading(false);
                    setShowNavigationAlert(false);
                    resolve();
                  },
                })
              );
            })
          );
        }
      });

      const previewKeysArray = Object.keys(initialPreviewUrls);
      previewKeysArray.map((item) => {
        if (!currentInputs[item] && categoryInputs[item]) {
          setLoading(true);
          promiseArray.push(
            new Promise((resolve, reject) => {
              dispatch(
                saveCategoryPreviewAction({
                  apiPayloadRequest: {
                    _id: data?._id,
                    type_of_url: item === "mobilePreviewUrl" ? 0 : item === "webPreviewUrl" ? 1 : 2,
                    url_link: categoryInputs[item],
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Categories Preview Updated Sucessfully", 1);
                      navigate("/categories/all");
                    } else {
                      Toastiy("Categories Preview Update Failed", 2);
                    }
                    setLoading(false);
                    setShowNavigationAlert(false);
                    resolve();
                  },
                })
              );
            })
          );
        } else if (categoryInputs[item] !== currentInputs[item]) {
          setLoading(true);
          promiseArray.push(
            new Promise((resolve, reject) => {
              dispatch(
                updateCategoryPreviewAction({
                  apiPayloadRequest: {
                    _id: data?._id,
                    type_of_url: item === "mobilePreviewUrl" ? 0 : item === "webPreviewUrl" ? 1 : 2,
                    url_link: categoryInputs[item],
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Categories Preview Updated Sucessfully", 1);
                      navigate("/categories/all");
                    } else {
                      Toastiy("Categories Preview Update Failed", 2);
                    }
                    setLoading(false);
                    setShowNavigationAlert(false);
                    resolve();
                  },
                })
              );
            })
          );
        }
      });

      Promise.all(promiseArray).then(() => getCategoryDetails());
    } else {
      // validating fields
      let inputErrors = categoryErrors;
      if (!categoryInputs?.category_name) inputErrors = { ...inputErrors, category_name: true };
      if (!categoryInputs?.category_description)
        inputErrors = { ...inputErrors, category_description: true };
      if (
        !categoryInputs?.mobileUploadUrl ||
        !categoryInputs?.tabletUploadUrl ||
        !categoryInputs?.webUploadUrl
      )
        inputErrors = { ...inputErrors, video_urls: true };
      // if (
      //   !categoryInputs?.commonMobileUploadUrl ||
      //   !categoryInputs?.commonWebUploadUrl ||
      //   !categoryInputs?.commonTabletUploadUrl
      // )
      //   inputErrors = { ...inputErrors, common_thumbnail_urls: true };
      // if (
      //   !categoryInputs?.mobilePreviewUrl ||
      //   !categoryInputs?.webPreviewUrl ||
      //   !categoryInputs?.tabletPreviewUrl
      // )
      //   inputErrors = { ...inputErrors, preview_urls: true };
      if (!selectedOptions || selectedOptions?.length === 0)
        inputErrors = { ...inputErrors, selectedOptions: true };
      setCategoryErrors({ ...categoryErrors, ...inputErrors });
      if (Object.values(inputErrors)?.some((d) => d)) {
        return;
      } else {
        setLoading(true);
        dispatch(
          saveCategoriesAction({
            apiPayloadRequest: categoryInputs,
            callback: getSaveCategoriesResponse,
          })
        );
      }
    }
  };

  const getUpdateCategoriesResponse = (data) => {
    if (data?.type === 1) {
    } else {
      Toastiy("Issue performing the operation, contact admin!!", data?.type);
    }
  };

  const getSaveCategoriesResponse = (data) => {
    if (data?.type === 1) {
      Toastiy("Categories Saved Sucessfully", data?.type);
      setLoading(false);
      selectedOptions.map((item) => {
        if (item?.value) {
          dispatch(
            saveCategoriesRelatedAction({
              apiPayloadRequest: {
                _id: data?.data[0]?._id,
                videoObjId: item?.value,
                order: item?.order,
              },
            })
          );
        }
      });
      const keysArray = Object.keys(initialUrls);
      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveCategoriesIconAction({
              apiPayloadRequest: {
                _id: data?.data[0]?._id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: categoryInputs[item],
              },
            })
          );
        }, (index + 1) * 500);
      });

      const commonKeysArray = Object.keys(initialCommonUrls);
      commonKeysArray.map((item, index) => {
        setTimeout(() => {
          if (categoryInputs[item]) {
            dispatch(
              saveCommonThumbnailCategoriesAction({
                apiPayloadRequest: {
                  _id: data?.data[0]?._id,
                  type_of_url:
                    item === "commonMobileUploadUrl" ? 0 : item === "commonWebUploadUrl" ? 1 : 2,
                  url_link: categoryInputs[item],
                },
              })
            );
          }
        }, (index + 1) * 600);
      });

      const previewKeysArray = Object.keys(initialPreviewUrls);
      previewKeysArray.map((item, index) => {
        setTimeout(() => {
          if (categoryInputs[item]) {
            dispatch(
              saveCategoryPreviewAction({
                apiPayloadRequest: {
                  _id: data?.data[0]?._id,
                  type_of_url: item === "mobilePreviewUrl" ? 0 : item === "webPreviewUrl" ? 1 : 2,
                  url_link: categoryInputs[item],
                },
              })
            );
          }
        }, (index + 1) * 600);
      });

      layoutArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveCategoryLayoutAction({
              apiPayloadRequest: {
                _id: item?._id,
                categoryObjId: data?.data[0]?._id,
                order: item?.order,
                // expiry: item?.expiry,
                // title: item?.title || "",
                // subtitle: item?.subtitle || "",
              },
            })
          );
        }, 700 * (index + 1));
      });
      navigate("/categories/all");
    } else {
      setLoading(false);
      Toastiy("Issue performing the operation, contact admin!!", data?.type);
    }
  };

  const getCategoryDetails = () => {
    if (data?._id !== "" && data?._id !== undefined) {
      dispatch(
        readCategoriesAction({
          apiPayloadRequest: {
            _id: data?._id,
          },
          callback: (res) => {
            console.log("res", res);
            if (res?.type === 1) {
              const categoryData = res?.data[0];
              console.log("categoryData", categoryData);
              const iconData = categoryData?.icon_images;
              const gnerictumbnailurls = categoryData?.gnerictumbnailurls;
              const previews = categoryData?.trailervideos;
              const isValidRelatedVideos = categoryData?.relatedvideos?.find(
                (v) => v?.videoObjId?.length > 0
              );
              const relatedvideosData = isValidRelatedVideos ? categoryData?.relatedvideos : [];

              setLanguagemeta(categoryData?.languagemetas);

              setCategoryStats({
                totalLikes: categoryData?.totallikes ?? 0,
                totalViews: categoryData?.category_views ?? 0,
              });

              setTranslateCategories({
                category_name: categoryData?.category_name || "",
                category_description: categoryData?.category_description || "",
              });

              setCategoryInputs({
                category_name: categoryData?.category_name || "",
                category_description: categoryData?.category_description || "",
                mobile_layout: categoryData?.mobile_layout || "",
                mobileUploadUrl:
                  iconData?.filter((item) => item?.type_of_url === "0")?.[0]?.url_link || "",
                tabletUploadUrl:
                  iconData?.filter((item) => item?.type_of_url === "1")?.[0]?.url_link || "",
                webUploadUrl:
                  iconData?.filter((item) => item?.type_of_url === "2")?.[0]?.url_link || "",
                commonMobileUploadUrl:
                  gnerictumbnailurls?.filter((item) => item?.type_of_url === "0")?.[0]?.url_link ||
                  "",
                commonTabletUploadUrl:
                  gnerictumbnailurls?.filter((item) => item?.type_of_url === "1")?.[0]?.url_link ||
                  "",
                commonWebUploadUrl:
                  gnerictumbnailurls?.filter((item) => item?.type_of_url === "2")?.[0]?.url_link ||
                  "",
                mobilePreviewUrl:
                  previews?.filter((item) => item?.type_of_url === "0")?.[0]?.url_link || "",
                webPreviewUrl:
                  previews?.filter((item) => item?.type_of_url === "1")?.[0]?.url_link || "",
                tabletPreviewUrl:
                  previews?.filter((item) => item?.type_of_url === "2")?.[0]?.url_link || "",
              });

              setCurrentInputs({
                category_name: categoryData?.category_name || "",
                category_description: categoryData?.category_description || "",
                mobile_layout: categoryData?.mobile_layout || "",
                mobileUploadUrl:
                  iconData?.filter((item) => item?.type_of_url === "0")?.[0]?.url_link || "",
                tabletUploadUrl:
                  iconData?.filter((item) => item?.type_of_url === "1")?.[0]?.url_link || "",
                webUploadUrl:
                  iconData?.filter((item) => item?.type_of_url === "2")?.[0]?.url_link || "",
                commonMobileUploadUrl:
                  gnerictumbnailurls?.filter((item) => item?.type_of_url === "0")?.[0]?.url_link ||
                  "",
                commonTabletUploadUrl:
                  gnerictumbnailurls?.filter((item) => item?.type_of_url === "1")?.[0]?.url_link ||
                  "",
                commonWebUploadUrl:
                  gnerictumbnailurls?.filter((item) => item?.type_of_url === "2")?.[0]?.url_link ||
                  "",
                mobilePreviewUrl:
                  previews?.filter((item) => item?.type_of_url === "0")?.[0]?.url_link || "",
                webPreviewUrl:
                  previews?.filter((item) => item?.type_of_url === "1")?.[0]?.url_link || "",
                tabletPreviewUrl:
                  previews?.filter((item) => item?.type_of_url === "2")?.[0]?.url_link || "",
              });

              relatedvideosData
                ?.sort((a, b) => Number(a?.order) - Number(b?.order))
                ?.map((item) => {
                  setSelectedOptions((prev) => [
                    ...prev,
                    {
                      order: item?.order || "",
                      value: item?.videoObjId[0]?._id || "",
                      label: item?.videoObjId[0]?.video_title || "",
                      image:
                        item?.videoObjId[0]?.tumbnailurls.filter(
                          (thumbnail) => thumbnail.type_of_url === "0"
                        )[0].url_link || "",
                    },
                  ]);
                });

              dispatch(
                readCategoryLayoutAction({
                  apiPayloadRequest: {
                    categoryObjId: data?._id,
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      console.log("res.data989", res?.data);
                      res?.data.forEach((layout) => {
                        const requiredData = layout?.categories?.categoryObjId === data;

                        // console.log("requiredData", requiredData);

                        // const requiredId = layout._id;
                        // const requiredMobileLayout = layout.mobile_layout;
                        // const requiredName = layout.layout_name;

                        const layouts = {
                          _id: layout._id,
                          mobile_layout: layout.mobile_layout,
                          layout_name: layout.layout_name,
                          order: layout?.categories?.order,
                          // title: item.title,
                          // subtitle: item.subtitle,
                          // expiry: item.expiry,
                        };
                        setLayoutArray((prev) => [...prev, layouts]);
                      });
                    }
                  },
                })
              );

              // setRelatedVideos(data?.relatedvideos)
            }
          },
        })
      );
      // setCategoryInputs(data?.categoryInputs)
      // setRelatedVideos(data?.relatedvideos)
    }
  };

  useEffect(() => {
    getCategoryDetails();
  }, []);

  const combineStateKeys = () => {
    const values = Object.values(translateCategories);
    const combinedString = values.join(" @@@@ ");
    return combinedString;
  };

  const updateStateWithTranslation = () => {
    const combinedText = combineStateKeys();
    dispatch(
      translateLanguageAction({
        apiPayloadRequest: {
          targetcode: activeTab,
          translatetext: combinedText,
        },
        callback: (res) => {
          if (res?.type === 1) {
            onTranslateSuccess(res?.data);
          } else if (res?.type === 2) {
            Toastiy("Language Translation failed!!");
            Toastiy(res?.message);
          }
        },
      })
    );
  };

  const onTranslateSuccess = (data) => {
    console.log(translateCategories, "myState");
    const arrayOfValues = data.split(" @@@@ ");
    const arrayOfKeys = Object.keys(translateCategories);

    for (let key in arrayOfKeys) {
      setTranslateCategories((preState) => ({
        ...preState,
        [arrayOfKeys[key]]: arrayOfValues[key],
      }));

      setCategoryInputs((preState) => ({
        ...preState,
        [arrayOfKeys[key]]: arrayOfValues[key],
      }));
    }
    setShowNavigationAlert(true);
  };

  const [currentLanguageInputs, setCurrentLanguageInputs] = useState();
  const [currentLanguagePreviews, setCurrentLanguagePreviews] = useState();

  console.log("currentInputs", currentInputs);

  useEffect(() => {
    if (activeTab === "en") {
      if (currentInputs) {
        setCategoryInputs(currentInputs);
      }
    } else {
      const isPresent = languagemeta?.some((language) => language?.language_code === activeTab);
      if (isPresent) {
        const data = languagemeta?.filter((item) => item?.language_code === activeTab)[0];

        setCategoryInputs((prev) => ({
          ...prev,
          category_name: data?.category_name || "",
          category_description: data?.category_description || "",
          mobilePreviewUrl:
            data?.trailervideos?.filter((item) => item.type_of_url === "0")[0].url_link || "",
          webPreviewUrl:
            data?.trailervideos?.filter((item) => item.type_of_url === "1")[0].url_link || "",
          tabletPreviewUrl:
            data?.trailervideos?.filter((item) => item.type_of_url === "2")[0].url_link || "",
        }));

        setCurrentLanguageInputs((prev) => ({
          ...prev,
          category_name: data?.category_name || "",
          category_description: data?.category_description || "",
        }));

        if (data?.trailervideos) {
          setCurrentLanguagePreviews({
            mobilePreviewUrl:
              data?.trailervideos?.filter((item) => item.type_of_url === "0")[0].url_link || "",
            webPreviewUrl:
              data?.trailervideos?.filter((item) => item.type_of_url === "1")[0].url_link || "",
            tabletPreviewUrl:
              data?.trailervideos?.filter((item) => item.type_of_url === "2")[0].url_link || "",
          });
        }
      } else {
        setCategoryInputs({ ...currentInputs, ...initialPreviewUrls });
        setCurrentLanguageInputs(currentInputs);
        setCurrentLanguagePreviews(initialPreviewUrls);
      }
    }
  }, [activeTab]);

  const handleSaveLanguage = () => {
    if (isLanguageSave) {
      const previewKeysArray = Object.keys(initialPreviewUrls);

      dispatch(
        saveCategoriesLanguageAction({
          apiPayloadRequest: {
            _id: data?._id,
            language_code: activeTab,
            category_name: categoryInputs?.category_name,
            category_description: categoryInputs?.category_description,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Categories Language Saved Successfully", 1);
              setShowNavigationAlert(false);
            }
          },
        })
      );

      previewKeysArray.map((item, index) => {
        setTimeout(() => {
          if (categoryInputs[item]) {
            dispatch(
              saveCategoriesPreviewLanguageAction({
                apiPayloadRequest: {
                  _id: data?._id,
                  language_code: activeTab,
                  type_of_url: item === "mobilePreviewUrl" ? 0 : item === "webPreviewUrl" ? 1 : 2,
                  url_link: categoryInputs[item],
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Category Preview URLs Saved Successfully", 1);
                    setShowNavigationAlert(false);
                  }
                },
              })
            );
          }
        }, (index + 1) * 600);
      });
    } else {
      const previewKeysArray = Object.keys(initialPreviewUrls);

      if (
        categoryInputs?.category_name !== currentLanguageInputs?.category_name ||
        categoryInputs?.category_description !== currentLanguageInputs?.category_description
      ) {
        dispatch(
          updateCategoriesLanguageAction({
            apiPayloadRequest: {
              _id: data?._id,
              language_code: activeTab,
              category_name: categoryInputs?.category_name,
              category_description: categoryInputs?.category_description,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Categories Language Updated Successfully", 1);
                setShowNavigationAlert(false);
              }
            },
          })
        );
      }

      previewKeysArray.map((item) => {
        if (!currentLanguagePreviews[item] && categoryInputs[item]) {
          dispatch(
            saveCategoriesPreviewLanguageAction({
              apiPayloadRequest: {
                _id: data?._id,
                language_code: activeTab,
                type_of_url: item === "mobilePreviewUrl" ? 0 : item === "webPreviewUrl" ? 1 : 2,
                url_link: categoryInputs[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Category Preview URLs Updated Successfully", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        } else if (categoryInputs[item] && categoryInputs[item] !== currentLanguagePreviews[item]) {
          dispatch(
            updateCategoriesPreviewLanguageAction({
              apiPayloadRequest: {
                _id: data?._id,
                language_code: activeTab,
                type_of_url: item === "mobilePreviewUrl" ? 0 : item === "webPreviewUrl" ? 1 : 2,
                url_link: categoryInputs[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Category Preview URLs Updated Successfully", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        }
      });
    }
  };

  const handleSendNotification = () => {
    dispatch(
      sendCustomNotificationAction({
        apiPayloadRequest: {
          _id: data?._id,
          type: "1",
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Notification Sent Successfully", 1);
          }
        },
      })
    );
  };

  return {
    value,
    onChangeSearchBar,
    languages,
    activeTab,
    setActiveTab,
    inputs,
    handleInputChange,
    //handleFileInputChange,
    // handleRelatedVideosChange,
    //handleCategoryDescriptionChange,
    handleSave,
    categoryInputs,
    categoryErrors,
    layoutValues,
    initialLayoutValues,
    selectedOptions,
    //options,
    handleDropdownChange,
    setLayoutValues,
    handleCategoryLayoutChange,
    layoutInfo,
    setLayoutInfo,
    handleUploadInput,
    handleUploadVideo,
    handleDateChange,

    //Layout
    addedLayout,
    setAddedLayout,
    setLayoutArray,
    setIsLayoutEdit,
    setEditLayoutIndex,
    setEditTargetLayout,
    isLayoutEdit,
    editLayoutIndex,
    editTargetLayout,
    handleDeleteLayoutFromArray,
    handleLayoutUpdate,
    handleVideoLayoutChange,
    handleAddLayoutToArray,
    handleVideoDateChange,
    layoutArray,
    setIsLanguageSave,
    languagemeta,
    updateStateWithTranslation,
    handleSaveLanguage,
    activeLanguageDetails,
    setActiveLanguageDetails,
    showVideoLibraryModal,
    setShowVideoLibraryModal,
    deleteRelatedVideo,
    updateRelatedVideoOrder,
    setSelectedOptions,
    setSelectedRelatedVideoIndex,
    categoryStats,
    handleSendNotification,
  };
};

export const GetRelatedVideosHooks = () => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);

  const getRelatedVideos = (response) => {
    if (response?.type === 1) {
      response?.data?.map((item) =>
        setOptions((prevData) => [
          ...prevData,
          {
            value: item?._id,
            label: item?.video_title,
            image: item?.tumbnailurls?.length > 0 ? item?.tumbnailurls[0]?.url_link : "",
          },
        ])
      );
    } else {
      Toastiy("Failed To Get Related Videos Data");
    }
  };

  const dispatchReadVideos = () => {
    if (options?.length === 0) {
      dispatch(readVideosAction({ callback: getRelatedVideos }));
    }
  };

  useEffect(() => {
    dispatchReadVideos();
  }, []);

  return {
    options,
  };
};
