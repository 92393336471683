import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import story from "../../../assets/images/story.png";
import {
  deleteCurriculumAction,
  readCurriculumAction,
  readLanguageAction,
  saveBGCurriculumAction,
  saveCurriculumAction,
  saveCurriculumLanguageAction,
  saveCurriculumLanguageLessonBySlideAction,
  saveCurriculumLanguageLessonSlideAction,
  saveCurriculumLanguageLessonSlideTypeBGAction,
  saveCurriculumLanguageLessonSlideTypeLinkAction,
  saveCurriculumLanguageLessonThumbNailAction,
  saveCurriculumLessonAction,
  saveCurriculumLessonLanguageAction,
  saveCurriculumLessonSlideAction,
  saveCurriculumLessonSlideTypeAction,
  saveCurriculumLessonSlideTypeBGAction,
  saveCurriculumLessonSlideTypeLinkAction,
  saveCurriculumLessonThumbNailAction,
  translateLanguageAction,
  updateBGCurriculumAction,
  updateCurriculumAction,
  updateCurriculumLessonAction,
  updateCurriculumLessonSlideAction,
  updateCurriculumLessonSlideTypeAction,
  updateCurriculumLessonSlideTypeBGAction,
  updateCurriculumLessonSlideTypeLinkAction,
  updateCurriculumLessonThumbNailAction,
  uploadAssestsAction,
} from "../../../store/Languages/LanguageAction";
import { useDispatch } from "react-redux";
import Toastiy from "../../../components/universal/Toastiy";
import {
  deleteCurriculumLayoutAction,
  readCurriculumLayoutAction,
  saveCurriculumLayoutAction,
  updateCurriculumLayoutAction,
} from "../../../store/Layout/LayoutAction";

const CurriculumHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export default CurriculumHooks;

export const AllCurriculumHooks = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [reloadData, setReloadData] = useState(false);

  const fetchAllCurriculums = (page, limit, searchCriteria = {}, callbackFn) => {
    console.log(
      "page",
      page,
      "limit",
      limit,
      "searchCriteria",
      searchCriteria,
      "callbackFn",
      callbackFn
    );
    dispatch(
      readCurriculumAction({
        apiPayloadRequest: {
          page: page,
          limit: limit,
          searchCriteria: searchCriteria,
        },
        callback: (Data) => {
          if (Data?.type === 1) {
            if (callbackFn)
              callbackFn({
                data: Data?.data?.map((d) => ({ ...d, views: d?.views ?? 0 })),
                total: Data?.totalRecords ?? 0,
              });
          } else if (callbackFn) callbackFn({ data: [], total: Data?.totalRecords ?? 0 });
        },
      })
    );
  };

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const handleDeleteItem = (_id) => {
    dispatch(
      deleteCurriculumAction({
        apiPayloadRequest: {
          _id: _id,
        },
        callback: (res) => {
          if (res?.type === 1) {
            setReloadData(true);
            Toastiy("Curriculum Deleted Successfully!", res?.type);
          } else {
            Toastiy("Curriculum Failed To Delete!");
          }
        },
      })
    );
  };

  return {
    value,
    onChangeSearchBar,
    handleDeleteItem,
    reloadData,
    setReloadData,
    fetchAllCurriculums,
  };
};

export const AddCurriculumHooks = (setLoading, curriculum_id) => {
  // search logic here
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [topicData, setTopicData] = useState({
    english: [],
    hindi: [],
    tamil: [],
    // Add more languages as needed
  });

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };
  // story logic here

  // add category logic here

  const initialUrls = {
    mobileUploadUrl: "",
    webUploadUrl: "",
    tabletUploadUrl: "",
  };

  const initialCurriculumValues = {
    curriculum_name: "",
    curriculum_description: "",
    mobileUploadUrl: "",
    webUploadUrl: "",
    tabletUploadUrl: "",
  };

  const initialLessonValues = {
    lesson_title: "",
    lesson_duration: "1",
    lesson_description: "",
    mobileUploadUrl: "",
    webUploadUrl: "",
    tabletUploadUrl: "",
    slides: [],
  };

  const initialSlideValues = {
    _id: "",
    slide_title: "",
    slide_description: "",
    slide_bg_type: "0",
    slide_bg_type_color: "#FFFFFF",
    slide_bg_type_img_vid: {
      mobileUploadUrl: "",
      webUploadUrl: "",
      tabletUploadUrl: "",
    },
    slide_link: "",
  };

  const languages = ["english", "hindi", "tamil"]; // List of languages
  const [activeTab, setActiveTab] = useState("en"); // Set the first language as active
  const [openCreateStoryModal, setOpenCreateStoryModal] = useState(false);

  console.log(activeTab, "activeTab");
  const [layoutInfo, setLayoutInfo] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [curriculumValues, setCurriculumValues] = useState(initialCurriculumValues);
  const [enCurriculum, setEnCurriculum] = useState(initialCurriculumValues);
  const [curriculumErrors, setCurriculumErrors] = useState({
    curriculum_name: false,
    curriculum_description: false,
    background_urls: false,
    lessons: false,
  });
  const [currentCurriculumValues, setCurrentCurriculumValues] = useState({});

  const [lessonValues, setLessonValues] = useState(initialLessonValues);
  const [lessonErrors, setLessonErrors] = useState({
    lesson_title: false,
    lesson_description: false,
    thumbnail_urls: false,
  });
  const [currentLessonValues, setCurrentLessonValues] = useState(initialLessonValues);

  const [slideData, setSlideData] = useState(initialSlideValues);
  const [currentSlideData, setCurrentSlideData] = useState(initialSlideValues);
  const [readLanguageData, setReadLanguageData] = useState([]);
  const [isLanguageSave, setIsLanguageSave] = useState(true);
  const [languagemeta, setLanguagemeta] = useState();
  const [lessonsArray, setLessonArray] = useState([]);
  const [enLessonArray, setEnLessonArray] = useState([]);
  const [currentLessonArray, setCurrentLessonArray] = useState([]);
  const [isSlideEdit, setIsSlideEdit] = useState(false);
  const [indexEdit, setIndexEdit] = useState(null);

  const [slideArray, setSlideArray] = useState([]);
  const [indexEditSlide, setIndexEditSlide] = useState(null);
  const [activeStory, setActiveStory] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (activeTab !== "en") {
      setCurriculumValues((prev) => ({
        ...prev,
        mobileUploadUrl: "",
        webUploadUrl: "",
        tabletUploadUrl: "",
      }));

      setLessonArray((prev) =>
        prev.map((lesson) => ({
          ...lesson,
          mobileUploadUrl: "",
          webUploadUrl: "",
          tabletUploadUrl: "",
          slides: lesson.slides.map((slide) => ({
            ...slide,
            slide_bg_type_img_vid: {
              mobileUploadUrl: "",
              webUploadUrl: "",
              tabletUploadUrl: "",
            },
          })),
        }))
      );
    } else {
      setCurriculumValues(enCurriculum);
      setLessonArray(enLessonArray);
    }
  }, [activeTab]);

  //layout States
  const initialLayoutValues = {
    _id: "",
    mobile_layout: "1",
    title: "",
    subtitle: "",
    layout_name: "",
    order: "",
    expiry: "",
  };

  const [layoutValues, setLayoutValues] = useState(initialLayoutValues);
  const [addedLayout, setAddedLayout] = useState(false);
  const [editTargetLayout, setEditTargetLayout] = useState("");
  const [isLayoutEdit, setIsLayoutEdit] = useState(false);
  const [layoutArray, setLayoutArray] = useState([]);
  const [editLayoutIndex, setEditLayoutIndex] = useState(null);

  const storyData = [
    {
      content: (props) => (
        <div
          className="flex flex-col justify-center relative bg-red-100"
          style={{
            height: "100%",
            width: "100%",
            marginTop: "40px",
            borderRadius: "10px",
          }}
        >
          <div className="px-[5%]" style={{ zIndex: 100 }}>
            <p
              style={{
                marginTop: 5,
                fontSize: "10px",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              {slideData?.slide_title}
            </p>
            <p
              style={{
                marginTop: 5,
                fontSize: "25px",
                textTransform: "capitalize",
                fontFamily: "serif",
                fontWeight: "bold",
              }}
            >
              {slideData?.slide_description}
            </p>
          </div>
          {slideData?.slide_bg_type === "0" && (
            <div className="absolute w-full h-full">
              <img
                className="h-full w-full"
                src={slideData?.slide_bg_type_img_vid?.mobileUploadUrl}
              />
            </div>
          )}
          {slideData?.slide_bg_type === "1" && (
            <div className="absolute w-full h-full">
              <video className="h-full w-full">
                <source src={slideData?.slide_bg_type_img_vid?.mobileUploadUrl} />
              </video>
            </div>
          )}
          {slideData?.slide_bg_type === "3" && (
            <div
              className={`absolute w-full h-full`}
              style={{ backgroundColor: `${slideData?.slide_bg_type_color}` }}
            ></div>
          )}
        </div>
      ),
    },
  ];

  const convertCurriculumToString = (curriculumObject, lessons) => {
    let curriculumString = `${curriculumObject?.curriculum_name}$#${curriculumObject?.curriculum_description}$#`;

    for (const lesson of lessons) {
      curriculumString += `${lesson?.lesson_title}@#${lesson?.lesson_description}@#`;

      for (const slide of lesson?.slides) {
        curriculumString += `${slide?.slide_title}!@${slide?.slide_description}!@`;
        curriculumString += "***";
      }

      curriculumString += "+++";
    }
    console.log(curriculumString, "curriculumString");
    return curriculumString;
  };

  const updateStateWithTranslation = () => {
    // const landCode = activeTab.split(" - ");
    console.log("updateStateWithTranslationing", activeTab.length, activeTab);
    const combinedText = convertCurriculumToString(curriculumValues, lessonsArray);

    if (activeTab.length > 0) {
      console.log("updateStateWithTranslationing 2");
      dispatch(
        translateLanguageAction({
          apiPayloadRequest: {
            targetcode: activeTab,
            translatetext: combinedText,
          },
          callback: (res) => {
            if (res?.type === 1) {
              reconstructCurriculumFromString(res?.data);
            } else if (res?.type === 2) {
              Toastiy("Language Translation failed!!");
              Toastiy(res?.message);
            }
          },
        })
      );
    }
  };

  const reconstructCurriculumFromString = (curriculumString) => {
    if (!curriculumString) return null;

    const [curriculum_name, curriculum_description, lessonsString] = curriculumString.split("$#");
    const lessonsArray = lessonsString?.split("+++") || [];

    const curriculumObject = {
      curriculum_name,
      curriculum_description,
      lessons: [],
    };

    lessonsArray.forEach((lessonString) => {
      if (!lessonString) return;

      const [lesson_title, lesson_description, slidesString] = lessonString.split("@#");
      const slideArray = slidesString?.split("***") || [];

      const lessonObject = {
        lesson_title,
        lesson_description,
        slides: [],
      };

      slideArray.forEach((slideString) => {
        if (!slideString) return;

        const [slide_title, slide_description] = slideString.split("!@");
        lessonObject.slides.push({
          slide_title,
          slide_description,
        });
      });

      curriculumObject.lessons.push(lessonObject);
    });

    setCurriculumValues((prev) => ({
      ...prev,
      curriculum_name: curriculumObject.curriculum_name,
      curriculum_description: curriculumObject.curriculum_description,
    }));

    setLessonArray((prev) =>
      prev.map((lesson, index) => ({
        ...lesson,
        lesson_title: curriculumObject.lessons[index]?.lesson_title,
        lesson_description: curriculumObject.lessons[index]?.lesson_description,
        slides: lesson.slides.map((slide, i) => ({
          ...slide,
          slide_title: curriculumObject.lessons[index]?.slides[i]?.slide_title,
          slide_description: curriculumObject.lessons[index]?.slides[i]?.slide_description,
        })),
      }))
    );

    return curriculumObject;
  };

  const onTranslateSuccess = (data) => {
    const arrayOfValues = data.split(" @@@@ ");
    const arrayOfKeys = Object.keys();

    for (let key in arrayOfKeys) {
      // setState((preState) => ({
      //   ...preState,
      //   [arrayOfKeys[key]]: arrayOfValues[key],
      // }));
    }
  };

  //layout handles
  const handleVideoLayoutChange = (event) => {
    if (isLayoutEdit) {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));

      setLayoutArray((prev) => {
        const updatedArray = [...prev];

        updatedArray[editLayoutIndex] = {
          ...updatedArray[editLayoutIndex],
          [name]: value,
        };
      });
    } else {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleVideoDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
  };

  const handleLayoutUpdate = () => {
    if (curriculum_id !== undefined && curriculum_id !== "") {
      dispatch(
        updateCurriculumLayoutAction({
          apiPayloadRequest: {
            _id: layoutArray._id,
            curriculumObjId: curriculum_id,
            order: layoutArray._id,
            title: layoutArray._id,
            subtitle: layoutArray._id,
            expiry: layoutArray._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setLayoutValues(initialLayoutValues);
              setIsLayoutEdit(false);
              Toastiy("Layout Updated Successfully!", 1);
            }
          },
        })
      );
    } else {
      setLayoutValues(initialLayoutValues);
      setIsLayoutEdit(false);
    }
  };

  const handleAddLayoutToArray = () => {
    if (curriculum_id !== undefined && curriculum_id !== "") {
      dispatch(
        saveCurriculumLayoutAction({
          apiPayloadRequest: {
            _id: layoutValues?._id,
            curriculumObjId: curriculum_id,
            order: layoutValues?.order,
            expiry: layoutValues?.expiry,
            title: layoutValues?.title,
            subtitle: layoutValues?.subtitle,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Layout Added Successfully!", 1);
              setLayoutArray((prev) => [...prev, layoutValues]);
              setLayoutValues(initialLayoutValues);
              setAddedLayout(true);
            }
          },
        })
      );
    } else {
      setLayoutArray((prev) => [...prev, layoutValues]);
      setLayoutValues(initialLayoutValues);
      setAddedLayout(true);
    }
  };

  const handleDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
  };

  const handleDeleteLayoutFromArray = (id) => {
    if (curriculum_id !== undefined && curriculum_id !== "") {
      dispatch(
        deleteCurriculumLayoutAction({
          apiPayloadRequest: {
            _id: id,
            curriculumObjId: curriculum_id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const updatedArray = layoutArray.filter((item) => item._id !== id);
              setLayoutArray(updatedArray);
            }
          },
        })
      );
    } else {
      const updatedArray = layoutArray.filter((item) => item._id !== id);
      setLayoutArray(updatedArray);
    }
  };

  const handleCurriculumLayoutChange = (event) => {
    const { name, value } = event.target;
    setLayoutValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //Curriculum handleChanges Here
  const handleCurriculumInputChange = (event) => {
    const { name, value } = event.target;

    setCurriculumValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    // setCurriculumErrors({
    //   ...curriculumErrors,
    //   [name]: false,
    //   background_urls: false,
    // });
  };

  const handleCurriculumUploadInput = (event) => {
    const { name, files } = event.target;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => {
          setCurriculumValues((prevInputs) => ({
            ...prevInputs,
            [name]: res?.url,
          }));
          setCurriculumErrors({ ...curriculumErrors, background_urls: false });
          setLoading(false);
        },
      })
    );
  };

  const handleCategoryDescriptionChange = (e) => {
    handleInputChange(activeTab, "categoryDescription", e.target.value);
  };

  //Lesson HandleChanges Here
  const handleLessonInputChange = (event) => {
    if (isEdit) {
      const { name, value } = event.target;
      if (name === "lesson_description") {
        setLessonValues((prev) => ({
          ...prev,
          [name]: value.toString("html"),
        }));

        setLessonArray((prev) => {
          const updatedArray = [...prev];
          updatedArray[indexEdit] = {
            ...updatedArray[indexEdit],
            [name]: value.toString("html"),
          };
          return updatedArray;
        });
      } else {
        setLessonValues((prev) => ({
          ...prev,
          [name]: value,
        }));

        setLessonArray((prev) => {
          const updatedArray = [...prev];
          updatedArray[indexEdit] = {
            ...updatedArray[indexEdit],
            [name]: value,
          };
          return updatedArray;
        });
      }
    } else {
      const { name, value } = event.target;
      if (name === "lesson_description") {
        setLessonValues((prev) => ({
          ...prev,
          [name]: value.toString("html"),
        }));
      } else {
        const { name, value } = event.target;
        setLessonValues((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      setLessonErrors({
        ...lessonErrors,
        [name]: false,
        thumbnail_urls: false,
      });
    }
  };

  const handleLessonUploadInput = (event) => {
    const { name, files } = event.target;

    if (isEdit) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", files[0]);
      dispatch(
        uploadAssestsAction({
          apiPayloadRequest: formData,
          callback: (res) => {
            setLessonValues((prevInputs) => ({
              ...prevInputs,
              [name]: res?.url,
            }));
            setLessonArray((prev) => {
              const updatedArray = [...prev];
              updatedArray[indexEdit] = {
                ...updatedArray[indexEdit],
                [name]: res?.url,
              };
              return updatedArray;
            });
            setLoading(false);
          },
        })
      );
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", files[0]);
      dispatch(
        uploadAssestsAction({
          apiPayloadRequest: formData,
          callback: (res) => {
            setLessonValues((prevInputs) => ({
              ...prevInputs,
              [name]: res?.url,
            }));
            setLoading(false);
          },
        })
      );
    }
    setLessonErrors({ ...lessonErrors, thumbnail_urls: false });
  };

  const handleTopicDescriptionChange = (e) => {
    handleInputChange(activeTab, "topicDescription", e.target.value);
  };

  //Lesson CRUD Here
  const handleAddTopic = () => {
    if (curriculum_id !== "" && curriculum_id !== undefined) {
      dispatch(
        saveCurriculumLessonAction({
          apiPayloadRequest: {
            _id: curriculum_id,
            lesson_title: lessonValues?.lesson_title,
            lesson_description: lessonValues?.lesson_description,
            lesson_duration: lessonValues?.lesson_duration,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Lesson Saved Successfully!", 1);

              const keysArray = Object.keys(initialUrls);
              keysArray.map((item) => {
                dispatch(
                  saveCurriculumLessonThumbNailAction({
                    apiPayloadRequest: {
                      _id: curriculum_id,
                      lesson_title: lessonValues?.lesson_title,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: lessonValues[item],
                    },
                    callback: (res) => {
                      if (res?.type == 1) {
                        console.log("Lessone Thumbnail Saved!");
                      } else {
                        Toastiy("Failed to Save Lesson Thumbnails");
                      }
                    },
                  })
                );
              });
            } else {
              Toastiy("Failed to Save Lesson");
            }
          },
        })
      );

      setLessonArray((prev) => [...prev, lessonValues]);

      setLessonValues(initialLessonValues);
    } else {
      if (!lessonValues.lesson_title) setLessonErrors({ ...lessonErrors, lesson_title: true });
      else if (!lessonValues.lesson_description)
        setLessonErrors({ ...lessonErrors, lesson_description: true });
      else if (
        !lessonValues.mobileUploadUrl ||
        !lessonValues.webUploadUrl ||
        !lessonValues.tabletUploadUrl
      )
        setLessonErrors({ ...lessonErrors, thumbnail_urls: true });
      else {
        setLoading(true);
        setLessonArray((prev) => [...prev, lessonValues]);
        setCurriculumErrors({ ...curriculumErrors, lessons: false });
        setLessonValues(initialLessonValues);
        setLoading(false);
      }
    }
  };

  const handleEditLesson = () => {
    if (activeTab === "en" && curriculum_id !== "" && curriculum_id !== undefined) {
      if (
        lessonValues?.lesson_title !== currentLessonValues?.lesson_title ||
        lessonValues?.lesson_description !== currentLessonValues?.lesson_description ||
        lessonValues?.lesson_duration !== currentLessonValues?.lesson_duration
      )
        dispatch(
          updateCurriculumLessonAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              lesson_title: lessonValues?.lesson_title,
              lesson_description: lessonValues?.lesson_description,
              lesson_duration: lessonValues?.lesson_duration,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Lesson Updated Successfully!", 1);
              }
            },
          })
        );

      const keysArray = Object.keys(initialUrls);
      keysArray.map((item) => {
        if (lessonValues[item] !== currentLessonValues[item]) {
          dispatch(
            updateCurriculumLessonThumbNailAction({
              apiPayloadRequest: {
                _id: curriculum_id,
                lesson_title: lessonValues?.lesson_title,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: lessonValues[item],
              },
              callback: (res) => {
                if (res?.type == 1) {
                  Toastiy("Lesson Thumbnails Updated Successfully!", 1);
                } else {
                  Toastiy("Failed to Update Lesson Thumbnails");
                }
              },
            })
          );
        }
      });

      setIsEdit(false);
      setLessonValues(initialLessonValues);
    } else {
      setIsEdit(false);
      setLessonValues(initialLessonValues);
    }
  };

  const handleDeleteLesson = (id, index) => {
    if (id === "") {
      const updatedState = lessonsArray.filter((_, idx) => idx !== index);
      setLessonArray(updatedState);
    }
  };

  //Slide HandleChanges Here
  const handleSlideInputChange = (event) => {
    const { name, value } = event.target;

    if (isSlideEdit) {
      if (name === "mobileUploadUrl" || name === "tabletUploadUrl" || name === "webUploadUrl") {
        setSlideData((prev) => ({
          ...prev,
          slide_bg_type_img_vid: {
            ...prev.slide_bg_type_img_vid,
            [name]: value,
          },
        }));

        setLessonValues((prev) => {
          const updatedData = { ...prev };
          updatedData.slides[indexEditSlide].slide_bg_type_img_vid = {
            ...updatedData.slides[indexEditSlide].slide_bg_type_img_vid,
            [name]: value,
          };
          return updatedData;
        });

        setLessonArray((prev) => {
          const updatedArray = [...prev];
          updatedArray[indexEdit].slides[indexEditSlide].slide_bg_type_img_vid = {
            ...updatedArray[indexEdit].slides[indexEditSlide].slide_bg_type_img_vid,
            [name]: value,
          };
          return updatedArray;
        });
      } else {
        setSlideData((prev) => ({
          ...prev,
          [name]: value,
        }));

        setLessonValues((prev) => {
          const updatedData = { ...prev };
          updatedData.slides[indexEditSlide] = {
            ...updatedData.slides[indexEditSlide],
            [name]: value,
          };
          return updatedData;
        });

        setLessonArray((prev) => {
          const updatedArray = [...prev];
          updatedArray[indexEdit].slides[indexEditSlide] = {
            ...updatedArray[indexEdit].slides[indexEditSlide],
            [name]: value,
          };
          return updatedArray;
        });
      }
    } else {
      if (name === "mobileUploadUrl" || name === "tabletUploadUrl" || name === "webUploadUrl") {
        setSlideData((prev) => ({
          ...prev,
          slide_bg_type_img_vid: {
            ...prev.slide_bg_type_img_vid,
            [name]: value,
          },
        }));
      } else {
        setSlideData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };

  const handleSlideFileChange = (value, name) => {
    if (isSlideEdit) {
      setSlideData((prev) => ({
        ...prev,
        slide_bg_type_img_vid: {
          ...prev.slide_bg_type_img_vid,
          [name]: value,
        },
      }));

      setLessonValues((prev) => {
        const updatedData = { ...prev };
        updatedData.slides[indexEditSlide].slide_bg_type_img_vid = {
          ...updatedData.slides[indexEditSlide].slide_bg_type_img_vid,
          [name]: value,
        };
        return updatedData;
      });

      setLessonArray((prev) => {
        const updatedArray = [...prev];
        updatedArray[indexEdit].slides[indexEditSlide].slide_bg_type_img_vid = {
          ...updatedArray[indexEdit].slides[indexEditSlide].slide_bg_type_img_vid,
          [name]: value,
        };
        return updatedArray;
      });
    } else {
      setSlideData((prev) => ({
        ...prev,
        slide_bg_type_img_vid: {
          ...prev.slide_bg_type_img_vid,
          [name]: value,
        },
      }));
    }
  };

  const handleColorChange = (color) => {
    if (isSlideEdit) {
      setSlideData((prev) => ({
        ...prev,
        slide_bg_type_color: color.hex,
      }));

      setLessonValues((prev) => {
        const updatedData = { ...prev };
        updatedData.slides[indexEditSlide] = {
          ...updatedData.slides[indexEditSlide],
          slide_bg_type_color: color.hex,
        };
        return updatedData;
      });

      setLessonArray((prev) => {
        const updatedArray = [...prev];
        updatedArray[indexEdit].slides[indexEditSlide] = {
          ...updatedArray[indexEdit].slides[indexEditSlide],
          slide_bg_type_color: color.hex,
        };
        return updatedArray;
      });
    } else {
      setSlideData((prev) => ({
        ...prev,
        slide_bg_type_color: color.hex,
      }));
    }
  };

  const handleSlideUploadInput = (event) => {
    const { name, files } = event.target;

    if (isSlideEdit) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", files[0]);
      dispatch(
        uploadAssestsAction({
          apiPayloadRequest: formData,
          callback: (res) => {
            setSlideData((prevInputs) => ({
              ...prevInputs,
              slide_bg_type_img_vid: {
                ...prevInputs.slide_bg_type_img_vid,
                [name]: res?.url,
              },
            }));

            setLessonValues((prev) => {
              const updatedData = { ...prev };
              updatedData.slides[indexEditSlide] = {
                ...updatedData.slides[indexEditSlide],
                slide_bg_type_img_vid: {
                  ...updatedData.slides[indexEditSlide].slide_bg_type_img_vid,
                  [name]: res?.url,
                },
              };
              return updatedData;
            });

            setLessonArray((prev) => {
              const updatedArray = [...prev];
              updatedArray[indexEdit].slides[indexEditSlide] = {
                ...updatedArray[indexEdit].slides[indexEditSlide],
                slide_bg_type_img_vid: {
                  ...updatedArray[indexEdit].slides[indexEditSlide].slide_bg_type_img_vid,
                  [name]: res?.url,
                },
              };
              return updatedArray;
            });
            setLoading(false);
          },
        })
      );
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", files[0]);
      dispatch(
        uploadAssestsAction({
          apiPayloadRequest: formData,
          callback: (res) => {
            setSlideData((prevInputs) => ({
              ...prevInputs,
              slide_bg_type_img_vid: {
                ...prevInputs.slide_bg_type_img_vid,
                [name]: res?.url,
              },
            }));
            setLoading(false);
          },
        })
      );
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setSlideData((prev) => ({
      ...prev,
      slide_bg_type: e.target.value,
      slide_bg_type_img_vid: {
        ...prev.slide_bg_type_img_vid,
        mobileUploadUrl: "",
        webUploadUrl: "",
        tabletUploadUrl: "",
      },
    }));
  };

  //Slide CRUD Here
  const handleSlideEdit = () => {
    if (activeTab === "en" && curriculum_id !== undefined && curriculum_id !== "") {
      if (
        slideData?.slide_title !== currentSlideData?.slide_title ||
        slideData?.slide_description !== currentSlideData?.slide_description
      ) {
        dispatch(
          updateCurriculumLessonSlideAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              lesson_title: lessonValues?.lesson_title,
              slide_title: slideData?.slide_title,
              slide_description: slideData?.slide_description,
            },
            callback: (response) => {
              if (response?.type === 1) {
                //updateLessonSlideBgColor(curriculum_id, slideData, lessonValues?.lesson_title);
                Toastiy("Slide Updated Successfully!", 1);
              } else {
                Toastiy("Failed to Save Lesson Slide");
              }
            },
          })
        );
      }

      if (
        slideData?.slide_title !== currentSlideData?.slide_title ||
        slideData?.type_of_slide !== currentSlideData?.type_of_slide ||
        slideData?.slide_bg_color !== currentSlideData?.slide_bg_color
      ) {
        dispatch(
          updateCurriculumLessonSlideTypeAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              lesson_title: lessonValues?.lesson_title,
              slide_title: slideData?.slide_title,
              type_of_slide: slideData?.slide_bg_type,
              slide_bg_color: slideData?.slide_bg_type_color,
            },
            callback: (res) => {
              if (res?.type === 1) {
                if (slideData?.type_of_slide !== "2") {
                  if (
                    slideData?.type_of_slide !== currentSlideData?.type_of_slide ||
                    slideData?.slide_bg_type_img_vid?.mobileUploadUrl !==
                      currentSlideData?.slide_bg_type_img_vid?.mobileUploadUrl ||
                    slideData?.slide_bg_type_img_vid?.webUploadUrl !==
                      currentSlideData?.slide_bg_type_img_vid?.webUploadUrl ||
                    slideData?.slide_bg_type_img_vid?.tabletUploadUrl !==
                      currentSlideData?.slide_bg_type_img_vid?.tabletUploadUrl
                  ) {
                    const keysArray = Object.keys(initialUrls);
                    keysArray.map((item) => {
                      if (
                        slideData?.slide_bg_type_img_vid[item] !==
                        currentSlideData?.slide_bg_type_img_vid[item]
                      ) {
                        dispatch(
                          updateCurriculumLessonSlideTypeBGAction({
                            apiPayloadRequest: {
                              _id: curriculum_id,
                              lesson_title: lessonValues?.lesson_title,
                              slide_title: slideData?.slide_title,
                              type_of_slide: slideData?.slide_bg_type,
                              type_of_url:
                                item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                              url_link: slideData.slide_bg_type_img_vid[item],
                            },
                            callback: (res) => {
                              if (res?.type === 1) {
                                Toastiy("Slide BG of Image or Video Updated Successfully!");
                              } else {
                                Toastiy("Failed to Save slide BG of Image or Video");
                              }
                            },
                          })
                        );
                      }
                    });
                  }
                  if (
                    slideData?.type_of_slide !== currentSlideData?.type_of_slide ||
                    slideData?.slide_link !== currentSlideData?.slide_link
                  ) {
                    updateLessonSlideLink(curriculum_id, slideData, lessonValues?.lesson_title);
                  }
                } else {
                  if (
                    slideData?.type_of_slide !== currentSlideData?.type_of_slide ||
                    slideData?.slide_link !== currentSlideData?.slide_link
                  ) {
                    updateLessonSlideLink(curriculum_id, slideData, lessonValues?.lesson_title);
                  }
                }
              } else {
                Toastiy("Failed to Save Slide BG Type");
              }
            },
          })
        );
      }

      setIsSlideEdit(false);
      setSlideData(initialSlideValues);
    } else {
      setIsSlideEdit(false);

      setSlideData(initialSlideValues);
    }
  };

  const handleAddStory = () => {
    if (curriculum_id !== "" && curriculum_id !== undefined) {
      dispatch(
        saveCurriculumLessonSlideAction({
          apiPayloadRequest: {
            _id: curriculum_id,
            lesson_title: lessonValues?.lesson_title,
            slide_title: slideData?.slide_title,
            slide_description: slideData?.slide_description,
          },
          callback: (response) => {
            if (response?.type === 1) {
              saveLessonSlideBgColor(curriculum_id, slideData, lessonValues?.lesson_title);
            } else {
              Toastiy("Failed to Save Lesson Slide");
            }
          },
        })
      );

      setSlideArray((prev) => [...prev, slideData]);

      setLessonValues((prev) => ({
        ...prev,
        slides: [...prev.slides, slideData],
      }));

      setLessonArray((prev) => {
        const updatedArray = [...prev];
        updatedArray[indexEdit] = {
          ...updatedArray[indexEdit],
          slides: [...updatedArray[indexEdit].slides, slideData],
        };

        return updatedArray;
      });

      setSlideData(initialSlideValues);
    } else {
      setSlideArray((prev) => [...prev, slideData]);

      setLessonValues((prev) => ({
        ...prev,
        slides: [...prev.slides, slideData],
      }));

      setLessonArray((prev) => {
        const updatedArray = [...prev];
        updatedArray[indexEdit] = {
          ...updatedArray[indexEdit],
          slides: [...updatedArray[indexEdit].slides, slideData],
        };
        return updatedArray;
      });

      setSlideData(initialSlideValues);
    }
  };

  const handleCloseStory = () => {
    setOpenCreateStoryModal(false);
    setLessonValues(initialLessonValues);
    setSlideData(initialSlideValues);
    setIsSlideEdit(false);
  };

  //Save Curriculum API Handles ***
  const handlePublishCurriculum = () => {
    if (curriculum_id !== undefined && curriculum_id !== "") {
      if (
        curriculumValues?.curriculum_name !== currentCurriculumValues?.curriculum_name ||
        curriculumValues?.curriculum_description !== currentCurriculumValues?.curriculum_description
      ) {
        dispatch(
          updateCurriculumAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              curriculum_name: curriculumValues?.curriculum_name,
              curriculum_description: curriculumValues?.curriculum_description,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Curriculum Updated Successfully!", 1);
                setCurrentCurriculumValues((prev) => ({
                  ...prev,
                  _id: curriculum_id,
                  curriculum_name: curriculumValues?.curriculum_name,
                  curriculum_description: curriculumValues?.curriculum_description,
                }));
                navigate("/curriculum/all");
              }
            },
          })
        );
      }

      const keysArray = Object.keys(initialUrls);
      keysArray.map((item, index) => {
        if (curriculumValues[item] !== currentCurriculumValues[item]) {
          setTimeout(() => {
            dispatch(
              updateBGCurriculumAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: curriculumValues[item],
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Curriculum BG Updated Successfully!", 1);
                    setCurrentCurriculumValues((prev) => ({
                      ...prev,
                      [item]: curriculumValues[item],
                    }));
                  } else {
                    Toastiy("Failed to Save Curriculum BG");
                  }
                },
              })
            );
          }, index * 500);
        }
      });
    } else {
      if (!curriculumValues?.curriculum_name)
        setCurriculumErrors({ ...curriculumErrors, curriculum_name: true });
      else if (!curriculumValues?.curriculum_description)
        setCurriculumErrors({
          ...curriculumErrors,
          curriculum_description: true,
        });
      else if (
        !curriculumValues?.mobileUploadUrl ||
        !curriculumValues?.tabletUploadUrl ||
        !curriculumValues?.webUploadUrl
      )
        setCurriculumErrors({ ...curriculumErrors, background_urls: true });
      else if (!lessonsArray || lessonsArray?.length === 0)
        setCurriculumErrors({ ...curriculumErrors, lessons: true });
      else {
        setLoading(true);
        dispatch(
          saveCurriculumAction({
            apiPayloadRequest: {
              curriculum_name: curriculumValues?.curriculum_name,
              curriculum_description: curriculumValues?.curriculum_description,
            },
            callback: async (response) => {
              await saveCurriculumBg(response);
              await saveLessons(response);

              layoutArray.map((layout, index) => {
                setTimeout(() => {
                  dispatch(
                    saveCurriculumLayoutAction({
                      apiPayloadRequest: {
                        _id: layout?._id,
                        curriculumObjId: response?.data[0]?._id,
                        order: layout?.order,
                        expiry: layout?.expiry,
                        title: layout?.title || "",
                        subtitle: layout?.subtitle || "",
                      },
                    })
                  );
                }, index * 500);
              });
              Toastiy("Curriculum Updated Successfully!", 1);
              navigate("/curriculum/all");
            },
          })
        );
      }
    }
  };

  const handleSaveLanguage = () => {
    dispatch(
      saveCurriculumLanguageAction({
        apiPayloadRequest: {
          _id: curriculum_id,
          language_code: activeTab,
          curriculum_name: curriculumValues.curriculum_name
            ? curriculumValues.curriculum_name
            : "No Data",
          curriculum_description: curriculumValues.curriculum_description,
        },
      })
    );
    lessonsArray.map((lesson) => {
      dispatch(
        saveCurriculumLessonLanguageAction({
          apiPayloadRequest: {
            _id: curriculum_id,
            language_code: activeTab,
            lesson_title: lesson.lesson_title,
            lesson_description: lesson.lesson_description,
          },
          callback: (res) => saveSlideLanguageResponce(res, lesson),
        })
      );

      const keysArray = Object.keys(initialUrls);
      keysArray.map((item) => {
        dispatch(
          saveCurriculumLanguageLessonThumbNailAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              language_code: activeTab,
              type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
              url_link: lesson[item],
            },
          })
        );
      });
    });
  };

  const saveSlideLanguageResponce = (res, lesson) => {
    if (res?.type === 1) {
      lesson?.slides?.map((slide) => {
        dispatch(
          saveCurriculumLanguageLessonSlideAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              language_code: activeTab,
              slide_title: slide?.slide_title,
              slide_description: slide?.slide_description,
            },
            callback: (res) => saveLessonSlideTypeLanguage(res, slide),
          })
        );
      });
    }
  };

  const saveLessonSlideTypeLanguage = (res, slide) => {
    if (res?.type === 1) {
      dispatch(
        saveCurriculumLanguageLessonBySlideAction({
          apiPayloadRequest: {
            _id: curriculum_id,
            language_code: activeTab,
            type_of_slide: slide?.type_of_slide,
            slide_bg_color: slide?.slide_bg_color,
          },
          callback: (res) => saveLessonSlideTypeBgLanguage(res, slide),
        })
      );
    }
  };

  const saveLessonSlideTypeBgLanguage = (res, slide) => {
    if (res?.type === 1) {
      const keysArray = Object.keys(initialUrls);
      keysArray.map((item) => {
        dispatch(
          saveCurriculumLanguageLessonSlideTypeBGAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              language_code: activeTab,
              type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
              url_link: slide?.slide_bg_type_img_vid[item],
            },
          })
        );

        dispatch(
          saveCurriculumLanguageLessonSlideTypeLinkAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              language_code: activeTab,
              type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
              url_link: slide?.slide_link,
            },
          })
        );
      });
    }
  };

  const saveCurriculumBg = async (curriculumResponse) => {
    if (curriculumResponse?.type === 1) {
      const id = curriculumResponse?.data[0]?._id;
      const keysArray = Object.keys(initialUrls);
      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveBGCurriculumAction({
              apiPayloadRequest: {
                _id: id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: curriculumValues[item],
              },
              callback: (res) => {
                if (res?.type !== 1) {
                  Toastiy("Failed to Save Curriculum BG");
                }
              },
            })
          );
        }, 500 * (index + 1));
      });
    }
  };

  //Save Lesson API Handles
  const saveLessons = async (curriculumResponse) => {
    if (curriculumResponse?.type === 1) {
      const id = curriculumResponse?.data[0]?._id;
      lessonsArray.map((lessonItem, index) => {
        setTimeout(() => {
          dispatch(
            saveCurriculumLessonAction({
              apiPayloadRequest: {
                _id: id,
                lesson_title: lessonItem?.lesson_title,
                lesson_description: lessonItem?.lesson_description,
                lesson_duration: lessonItem?.lesson_duration,
              },
              callback: (response) => {
                if (response?.type === 1) {
                  saveLessonThumbnails(id, lessonItem);
                  saveLessonSlides(id, lessonItem);
                } else {
                  Toastiy("Failed to Save Curriculum BG");
                }
              },
            })
          );
        }, 5000 * (index + 1));
      });
    }
  };

  const saveLessonThumbnails = (id, lessonItem) => {
    const keysArray = Object.keys(initialUrls);
    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          saveCurriculumLessonThumbNailAction({
            apiPayloadRequest: {
              _id: id,
              lesson_title: lessonItem?.lesson_title,
              type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
              url_link: lessonItem[item],
            },
            callback: (res) => {
              if (res?.type !== 1) {
                Toastiy("Failed to Save Lesson Thumbnails");
              }
            },
          })
        );
      }, 500 * (index + 1));
    });
  };

  //Save Slide API Handles
  const saveLessonSlides = (id, lessonsData) => {
    lessonsData?.slides.map((slideItem, index) => {
      setTimeout(() => {
        dispatch(
          saveCurriculumLessonSlideAction({
            apiPayloadRequest: {
              _id: id,
              lesson_title: lessonsData?.lesson_title,
              slide_title: slideItem?.slide_title,
              slide_description: slideItem?.slide_description,
            },
            callback: (response) => {
              if (response?.type === 1) {
                saveLessonSlideBgColor(id, slideItem, lessonsData?.lesson_title);
              } else {
                Toastiy("Failed to Save Lesson Slide");
              }
            },
          })
        );
      }, 3000 * (index + 1));
    });
  };

  const saveLessonSlideBgColor = (id, slideData, lesson_title) => {
    dispatch(
      saveCurriculumLessonSlideTypeAction({
        apiPayloadRequest: {
          _id: id,
          lesson_title: lesson_title,
          slide_title: slideData?.slide_title,
          type_of_slide: slideData?.slide_bg_type,
          slide_bg_color: slideData?.slide_bg_type_color,
        },
        callback: (res) => {
          if (res?.type === 1) {
            if (slideData?.type_of_slide !== "2") {
              saveLessonSlideBgImgVid(id, slideData, lesson_title);
              saveLessonSlideLink(id, slideData, lesson_title);
            } else {
              saveLessonSlideLink(id, slideData, lesson_title);
            }
          } else {
            Toastiy("Failed to Save Slide BG Type");
          }
        },
      })
    );
  };

  const saveLessonSlideBgImgVid = (id, slideData, lesson_title) => {
    const keysArray = Object.keys(initialUrls);
    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          saveCurriculumLessonSlideTypeBGAction({
            apiPayloadRequest: {
              _id: id,
              lesson_title: lesson_title,
              slide_title: slideData?.slide_title,
              type_of_slide: slideData?.slide_bg_type,
              type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
              url_link: slideData.slide_bg_type_img_vid[item],
            },
            callback: (res) => {
              if (res?.type !== 1) {
                Toastiy("Failed to Save slide BG of Image or Video");
              }
            },
          })
        );
      }, 500 * (index + 1));
    });
  };

  const saveLessonSlideLink = (id, slideData, lesson_title) => {
    const keysArray = Object.keys(initialUrls);
    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          saveCurriculumLessonSlideTypeLinkAction({
            apiPayloadRequest: {
              _id: id,
              lesson_title: lesson_title,
              slide_title: slideData?.slide_title,
              type_of_slide: slideData?.slide_bg_type,
              type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
              url_link: slideData?.slide_link,
            },
            callback: (res) => {
              if (res?.type !== 1) {
                Toastiy("Failed to Save Slide Link");
              }
            },
          })
        );
      }, 500 * (index + 1));
    });
  };

  //Update Slide API Handles (Maybe Remove After Checking) ***
  const updateLessonSlideBgColor = (id, slideData, lesson_title) => {
    dispatch(
      updateCurriculumLessonSlideTypeAction({
        apiPayloadRequest: {
          _id: id,
          lesson_title: lesson_title,
          slide_title: slideData?.slide_title,
          type_of_slide: slideData?.slide_bg_type,
          slide_bg_color: slideData?.slide_bg_type_color,
        },
        callback: (res) => {
          if (res?.type === 1) {
            if (slideData?.slide_title !== "2") {
              updateLessonSlideBgImgVid(id, slideData, lesson_title);
              updateLessonSlideLink(id, slideData, lesson_title);
            } else {
              updateLessonSlideLink(id, slideData, lesson_title);
            }
          } else {
            Toastiy("Failed to Save Slide BG Type");
          }
        },
      })
    );
  };

  const updateLessonSlideBgImgVid = (id, slideData, lesson_title) => {
    const keysArray = Object.keys(initialUrls);
    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          updateCurriculumLessonSlideTypeBGAction({
            apiPayloadRequest: {
              _id: id,
              lesson_title: lesson_title,
              slide_title: slideData?.slide_title,
              type_of_slide: slideData?.slide_bg_type,
              type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
              url_link: slideData.slide_bg_type_img_vid[item],
            },
            callback: (res) => {
              if (res?.type !== 1) {
                Toastiy("Failed to Save slide BG of Image or Video");
              }
            },
          })
        );
      }, 500 * (index + 1));
    });
  };

  const updateLessonSlideLink = (id, slideData, lesson_title) => {
    const keysArray = Object.keys(initialUrls);
    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          updateCurriculumLessonSlideTypeLinkAction({
            apiPayloadRequest: {
              _id: id,
              lesson_title: lesson_title,
              slide_title: slideData?.slide_title,
              type_of_slide: slideData?.slide_bg_type,
              type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
              url_link: slideData?.slide_link,
            },
            callback: (res) => {
              if (res?.type !== 1) {
                Toastiy("Failed to Save Slide Link");
              }
            },
          })
        );
      }, 500 * (index + 1));
    });
  };

  //Get Data
  useEffect(() => {
    if (curriculum_id !== undefined) {
      dispatch(
        readCurriculumAction({
          apiPayloadRequest: { _id: curriculum_id },
          callback: (res) => {
            if (res?.type === 1) {
              const requiredCurriculum = res.data.find((item) => item?._id === curriculum_id);
              if (!requiredCurriculum) {
                Toastiy("Failed Get The Curriculum Data");
                return;
              }

              const { bg_Images, lessons, languagemeta } = requiredCurriculum;

              setLanguagemeta(languagemeta || []);

              setCurriculumValues({
                curriculum_name: requiredCurriculum.curriculum_name,
                curriculum_description: requiredCurriculum.curriculum_description,
                mobileUploadUrl:
                  bg_Images.find((item) => item?.type_of_url === "0")?.url_link || "",
                webUploadUrl: bg_Images.find((item) => item?.type_of_url === "1")?.url_link || "",
                tabletUploadUrl:
                  bg_Images.find((item) => item?.type_of_url === "2")?.url_link || "",
              });

              setEnCurriculum({
                curriculum_name: requiredCurriculum.curriculum_name,
                curriculum_description: requiredCurriculum.curriculum_description,
                mobileUploadUrl:
                  bg_Images.find((item) => item?.type_of_url === "0")?.url_link || "",
                webUploadUrl: bg_Images.find((item) => item?.type_of_url === "1")?.url_link || "",
                tabletUploadUrl:
                  bg_Images.find((item) => item?.type_of_url === "2")?.url_link || "",
              });

              setCurrentCurriculumValues({
                curriculum_name: requiredCurriculum.curriculum_name,
                curriculum_description: requiredCurriculum.curriculum_description,
                mobileUploadUrl:
                  bg_Images.find((item) => item?.type_of_url === "0")?.url_link || "",
                webUploadUrl: bg_Images.find((item) => item?.type_of_url === "1")?.url_link || "",
                tabletUploadUrl:
                  bg_Images.find((item) => item?.type_of_url === "2")?.url_link || "",
              });

              const updatedLessonArray = lessons.map((item) => ({
                lesson_title: item?.lesson_title || "",
                lesson_duration: item?.lesson_duration || "",
                lesson_description: item?.lesson_description || "",
                mobileUploadUrl:
                  (
                    item?.lesson_tumbnail_urls?.find(
                      (thumbnail) => thumbnail?.type_of_url === "0"
                    ) || {}
                  )?.url_link || "",
                webUploadUrl:
                  (
                    item?.lesson_tumbnail_urls?.find(
                      (thumbnail) => thumbnail?.type_of_url === "1"
                    ) || {}
                  )?.url_link || "",
                tabletUploadUrl:
                  (
                    item?.lesson_tumbnail_urls?.find(
                      (thumbnail) => thumbnail?.type_of_url === "2"
                    ) || {}
                  )?.url_link || "",
                slides: (item?.slides || []).map((slide) => ({
                  slide_title: slide?.slide_title || "",
                  slide_description: slide?.slide_description || "",
                  slide_bg_type: slide?.slide_type?.[0]?.type_of_slide || "",
                  slide_bg_type_color: slide?.slide_type?.[0]?.slide_bg_color || "",
                  slide_link: slide?.slide_type?.[0]?.slide_link_url?.[0]?.url_link || "",
                  slide_bg_type_img_vid: {
                    mobileUploadUrl:
                      (
                        slide?.slide_type?.[0]?.bg_asset_url?.find(
                          (asset) => asset?.type_of_url === "0"
                        ) || {}
                      )?.url_link || "",
                    webUploadUrl:
                      (
                        slide?.slide_type?.[0]?.bg_asset_url?.find(
                          (asset) => asset?.type_of_url === "1"
                        ) || {}
                      )?.url_link || "",
                    tabletUploadUrl:
                      (
                        slide?.slide_type?.[0]?.bg_asset_url?.find(
                          (asset) => asset?.type_of_url === "2"
                        ) || {}
                      )?.url_link || "",
                  },
                })),
              }));

              setLessonArray((prev) => [...prev, ...updatedLessonArray]);
              setCurrentLessonArray((prev) => [...prev, ...updatedLessonArray]);
              setEnLessonArray((prev) => [...prev, ...updatedLessonArray]);

              dispatch(
                readCurriculumLayoutAction({
                  apiPayloadRequest: { curriculumObjId: curriculum_id },
                  callback: (res) => {
                    if (res?.type === 1) {
                      res.data.forEach((layout) => {
                        const requiredData = layout?.curriculums?.filter(
                          (item) => item.curriculumObjId === curriculum_id
                        );
                        const {
                          _id: requiredId,
                          mobile_layout: requiredMobileLayout,
                          layout_name: requiredName,
                        } = layout;
                        requiredData.forEach((item) => {
                          setLayoutArray((prev) => [
                            ...prev,
                            {
                              _id: requiredId,
                              mobile_layout: requiredMobileLayout,
                              title: item?.title,
                              subtitle: item?.subtitle,
                              layout_name: requiredName,
                              order: item?.order,
                              expiry: item?.expiry,
                            },
                          ]);
                        });
                      });
                    }
                  },
                })
              );
            } else {
              Toastiy("Failed Get The Curriculum Data");
            }
          },
        })
      );
    }
  }, [curriculum_id]);

  //Check and Remove
  const Englishdata = topicData?.english?.map((item, index) => ({
    item,
    index,
  }));

  //Check and Remove
  const Hindidata = topicData?.hindi?.map((item, index) => ({ item, index }));

  //Check and Remove
  const Tamildata = topicData?.tamil?.map((item, index) => ({ item, index }));

  //Check and Remove
  const [selectedOption, setSelectedOption] = useState(slideData?.slide_bg_type);

  //Check and Remove
  const [inputs, setInputs] = useState(
    languages.reduce((acc, lang) => {
      acc[lang] = {
        categoryName: "DAILY PRAYER",
        categoryCode: "111",
        categoryDescription:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
        topicTitle: "Verse of the Day Story ",
        topicDuration: "2",
        uoloadThumbnail: "Uploads",
        topicDescription: "It is a long established fact that a reader will be distracted.",
      };
      return acc;
    }, {})
  );

  //Check and Remove
  const handleInputChange = (language, field, value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [language]: {
        ...prevInputs[language],
        [field]: value,
      },
    }));
  };

  //Check and Remove
  const handleFileInputChange = (keyName, file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => {
          setLessonValues((prevInputs) => ({
            ...prevInputs,
            [keyName]: res?.url,
          }));
          setLoading(false);
        },
      })
    );
  };

  //Check and Remove
  const handleSave = (language) => {
    const oldData = topicData[language];
    const newData = inputs[language];
    const updatedData = [...oldData, newData];
    if (activeTab === "english") {
      setTopicData((prevData) => ({
        ...prevData,
        [language]: updatedData,
      }));
    } else if (activeTab === "hindi") {
      setTopicData((prevData) => ({
        ...prevData,
        [language]: updatedData,
      }));
    } else {
      setTopicData((prevData) => ({
        ...prevData,
        [language]: updatedData,
      }));
    }
  };

  //Check and Remove
  const handleStoryClick = (index) => {
    setActiveStory(index);
  };
  const handlePreviewClick = () => {
    setShowPreview(true);
  };
  const handleClosePreview = () => {
    setShowPreview(false);
  };

  useEffect(() => {
    // setLoading(true);
    if (curriculum_id) {
      dispatch(
        readLanguageAction({
          callback: getAllLanguageResponse,
        })
      );
    }
  }, [curriculum_id]);

  const getAllLanguageResponse = (response) => {
    if (response?.type === 1) {
      setReadLanguageData(response?.data);
    }
  };

  return {
    value,
    onChangeSearchBar,
    languages,
    activeTab,
    setActiveTab,
    inputs,
    handleInputChange,
    handleFileInputChange,
    handleCategoryDescriptionChange,
    handleSave,
    setOpenCreateStoryModal,
    openCreateStoryModal,
    handleStoryClick,
    setActiveStory,
    activeStory,
    handlePreviewClick,
    handleClosePreview,
    setShowPreview,
    showPreview,
    storyData,
    topicData,
    handleTopicDescriptionChange,
    Englishdata,
    Hindidata,
    Tamildata,
    handleCurriculumInputChange,
    handleCurriculumUploadInput,
    setCurriculumValues,
    handleLessonInputChange,
    curriculumValues,
    curriculumErrors,
    lessonValues,
    lessonErrors,
    handleAddTopic,
    lessonsArray,
    isEdit,
    handleEditLesson,
    handleDeleteLesson,
    setLessonValues,
    setCurrentLessonValues,
    setIsEdit,
    setIndexEdit,
    handleSlideInputChange,
    handleColorChange,
    slideData,
    selectedOption,
    handleOptionChange,
    slideArray,
    handleAddStory,
    setSlideData,
    setCurrentSlideData,
    setIndexEditSlide,
    indexEditSlide,
    setIsSlideEdit,
    isSlideEdit,
    handleSlideEdit,
    handleCloseStory,
    handlePublishCurriculum,
    handleLessonUploadInput,
    handleSlideUploadInput,
    handleCurriculumLayoutChange,
    setLayoutValues,
    layoutValues,
    layoutInfo,
    setLayoutInfo,
    handleDateChange,
    setAddedLayout,
    addedLayout,
    editTargetLayout,
    isLayoutEdit,
    handleVideoLayoutChange,
    handleVideoDateChange,
    handleLayoutUpdate,
    handleAddLayoutToArray,
    layoutArray,
    setIsLayoutEdit,
    setEditLayoutIndex,
    setEditTargetLayout,
    handleDeleteLayoutFromArray,
    handleSlideFileChange,
    readLanguageData,
    setIsLanguageSave,
    languagemeta,
    updateStateWithTranslation,
    handleSaveLanguage,
  };
};
