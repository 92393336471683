import React, { useState } from "react";
import classNames from "classnames";

const ToggleButton = ({ handleToggleClick, status }) => {
  const [isActive, setIsActive] = useState(status === 1 ? true : false);

  const toggleButtonClasses = classNames(
    "relative inline-block w-[30px] h-[18px] rounded-full transition duration-300 ease-in-out",
    {
      "bg-[#979592]": !isActive,
      "bg-green-400": isActive,
    }
  );

  const handleToggle = () => {
    setIsActive(!isActive);
    const result = handleToggleClick(!isActive);
    if (result === false) {
      setIsActive(false);
    }
  };

  return (
    <button className={toggleButtonClasses} onClick={handleToggle}>
      <span
        className={classNames(
          "inline-block relative top-[1px] -left-[6px] w-4 h-4 rounded-full bg-white shadow transform transition duration-300 ease-in-out",
          {
            "translate-x-3": isActive,
            "translate-x-0": !isActive,
          }
        )}
      ></span>
    </button>
  );
};

export default ToggleButton;
