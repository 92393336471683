import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { readTestimonyAction } from "../../../store/Testimony/TestimonyAction";
import { useLocation } from "react-router-dom";
import Toastiy from "../../../components/universal/Toastiy";
import { sendCustomNotificationAction } from "../../../store/Notifications/NotificationAction";

export const AllTestimonialsHooks = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { rawLanguagesList } = useSelector((state) => state.language);

  console.log("location", location);
  console.log("rawLanguagesList", rawLanguagesList);
  const [activeTab, setActiveTab] = useState("New");
  const [oldActiveTab, setOldActiveTab] = useState("New");
  const [value, setValue] = useState("");
  const [reloadData, setReloadData] = useState(false);
  const [selectedView, setSelectedView] = useState();
  const [showPreview, setShowPreview] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [oldSelectedLanguage, setOldSelectedLanguage] = useState("");

  const handleSendNotification = (id) => {
    dispatch(
      sendCustomNotificationAction({
        apiPayloadRequest: {
          _id: id,
          type: "4",
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Notification Sent Successfully", 1);
          }
        },
      })
    );
  };

  useEffect(() => {
    if (location.state?._id) {
      dispatch(
        readTestimonyAction({
          apiPayloadRequest: {
            _id: location.state?._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setSelectedView(res?.data[0]);
              setShowPreview(true);
            } else {
              Toastiy("Testimony details not found.");
            }
          },
        })
      );
    }
  }, [location.state]);

  const fetchAllTestimonials = (page, limit, searchCriteria = {}, callbackFn) => {
    console.log(
      "page",
      page,
      "limit",
      limit,
      "searchCriteria",
      searchCriteria,
      "callbackFn",
      callbackFn
    );
    const pageNum =
      activeTab !== oldActiveTab || selectedLanguage !== oldSelectedLanguage ? 1 : page;
    const payload = {
      page: pageNum,
      limit: limit,
      searchCriteria: searchCriteria,
    };
    if (activeTab === "New") {
      payload["approval_status"] = 0;
    } else if (activeTab === "Approved") {
      payload["approval_status"] = 1;
    } else if (activeTab === "Rejected") {
      payload["approval_status"] = 2;
    }

    if (selectedLanguage) {
      payload["language_code"] = selectedLanguage;
    }
    dispatch(
      readTestimonyAction({
        apiPayloadRequest: payload,
        callback: (Data) => {
          setOldActiveTab(activeTab);
          setOldSelectedLanguage(selectedLanguage);
          if (Data?.type === 1) {
            if (callbackFn)
              callbackFn({
                data: Data?.data?.map((d) => ({
                  ...d,
                  views: d?.views ?? 0,
                  category_name: d?.categories?.[0]?.category_name ?? "",
                  language: rawLanguagesList?.[d?.language] ?? d?.language,
                })),
                total: Data?.totalRecords ?? 0,
                pageNum: pageNum,
              });
          } else if (callbackFn)
            callbackFn({
              data: [],
              total: Data?.totalRecords ?? 0,
              pageNum: pageNum,
            });
        },
      })
    );
  };

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (activeTab !== oldActiveTab || selectedLanguage !== oldSelectedLanguage) {
      setReloadData(true);
    }
  }, [activeTab, oldActiveTab, selectedLanguage, oldSelectedLanguage]);

  return {
    value,
    onChangeSearchBar,
    reloadData,
    setReloadData,
    fetchAllTestimonials,
    selectedView,
    setSelectedView,
    showPreview,
    setShowPreview,
    activeTab,
    setActiveTab,
    setSelectedLanguage,
    rawLanguagesList,
    handleSendNotification,
  };
};
