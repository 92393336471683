import { useEffect, useState } from "react";
import Eye from "../../assets/icons/eye2.svg";
import PhoneInput from "react-phone-input-2";
import { FaInfoCircle } from "react-icons/fa";

export default function TextInputField({
  tooltipId,
  tooltipText,
  labelText,
  labelColor,
  errorMessage,
  placeholder,
  isFieldRequired,
  htmlFor,
  name,
  value,
  handleInputChange,
  validateFunction = () => false,
  margin,
  type,
  handleViewPassword,
  repeatPasswordCheck,
  repeatPasswordFunction,
  isSubmitted,
  isPhoneInput = false,
  disabled = false,
}) {
  const [ifInvalid, setIfInvalid] = useState(false);

  useEffect(() => {
    if (validateFunction !== false) {
      if (value?.length > 0 && validateFunction(value) === true) {
        setIfInvalid(false);
      }
    }
  }, [validateFunction, value]);

  console.log(isFieldRequired && value?.length === 0 && isSubmitted, "isSubmitted");

  return (
    <>
      {isPhoneInput ? (
        <div className="relative">
          <label
            htmlFor={htmlFor}
            style={{
              color: `${
                // isSubmitted &&
                // validateFunction(value) &&
                isFieldRequired ? "border-red-500" : labelColor
              }`,
            }}
            className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2 mb-2"
          >
            {labelText}
            {isFieldRequired && labelText?.length ? <span className="text-red-500">*</span> : ""}
            {tooltipId && tooltipText && (
              <FaInfoCircle data-tooltip-id={tooltipId} data-tooltip-content={tooltipText} />
            )}
          </label>
          <PhoneInput
            inputStyle={{ color: "white" }}
            country={"in"}
            value={value}
            onChange={handleInputChange}
            inputProps={{
              required: true,
            }}
          />
          {!validateFunction(value) && isFieldRequired && value?.length > 0 && (
            <p className="text-red-500  absolute bottom-[-18px] text-[10px] left-2">
              {errorMessage}
            </p>
          )}
          {isFieldRequired && value?.length === 0 && isSubmitted && (
            <p className="text-red-500  absolute bottom-[-18px] text-[10px] left-2">
              Required Field
            </p>
          )}
        </div>
      ) : (
        <div className="relative w-full">
          <label
            htmlFor={htmlFor}
            style={{
              color: `${
                // isSubmitted &&
                // validateFunction(value) &&
                isFieldRequired ? "border-red-500" : labelColor
              }`,
            }}
            className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
          >
            {labelText}
            {isFieldRequired && labelText?.length ? <span className="text-red-500">*</span> : ""}
            {tooltipId && tooltipText && (
              <FaInfoCircle data-tooltip-id={tooltipId} data-tooltip-content={tooltipText} />
            )}
          </label>
          <input
            type={type ? type : "text"}
            id={name}
            name={name}
            value={value}
            onChange={handleInputChange}
            placeholder={placeholder}
            style={{ marginBottom: `${margin}` }}
            disabled={disabled}
            onInvalid={(e) => {
              e.preventDefault();
              setIfInvalid(true);
              e.target.setCustomValidity("");
            }}
            onInput={(e) => {
              if (
                // isSubmitted &&
                // validateFunction(value) &&
                isFieldRequired
              ) {
                setIfInvalid(false);
              }
            }}
            className={`bg-blackish p-2 w-full focus:outline-none ${
              disabled ? "text-opacity-50" : ""
            } text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 placeholder:text-greyish placeholder:opacity-30 border ${
              // isSubmitted &&
              (!validateFunction(value) &&
                isFieldRequired &&
                value.length > 0 &&
                name !== "password") ||
              (repeatPasswordCheck?.length > 0 &&
                !repeatPasswordFunction(repeatPasswordCheck, value) &&
                value?.length > 0 &&
                name !== "password")
                ? "border-red-500 border-opacity-20"
                : "border-none"
            }`}
            required={isFieldRequired}
          />
          {
            // isSubmitted &&
            !validateFunction(value) && isFieldRequired && value?.length > 0 && (
              <p className="text-red-500  absolute bottom-[-18px] text-[10px] left-2">
                {errorMessage}
              </p>
            )
          }
          {isFieldRequired && value?.length === 0 && isSubmitted && (
            <p className="text-red-500  absolute bottom-[-18px] text-[10px] left-2">
              Required Field
            </p>
          )}

          {repeatPasswordCheck?.length > 0 &&
            !repeatPasswordFunction(repeatPasswordCheck, value) &&
            value?.length > 0 && (
              <p className="text-red-500  absolute bottom-[-18px] text-[10px] left-2">
                {errorMessage}
              </p>
            )}
          {value?.length > 0 && type && (
            <img
              className="absolute top-5 right-5 h-[30%] cursor-pointer"
              src={Eye}
              onClick={() => handleViewPassword((prevState) => !prevState)}
            />
          )}
        </div>
      )}
    </>
  );
}

// <PhoneInput
// country={'in'}
// value={signupValues.phone}
// onChange={handlePhoneChange}
// inputProps={{
//   required: true,
// }}
// />
