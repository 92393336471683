import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import ReactECharts from "echarts-for-react";
import { subDays } from "date-fns";

import {
  categoriesDashboardAction,
  curriculumDashboardAction,
} from "../../store/Dashboard/DashboardAction";
import { readLanguageAction } from "../../store/Languages/LanguageAction";
import {
  layoutDashboardAction,
  readLayoutAction,
  videosDashboardAction,
} from "../../store/Layout/LayoutAction";
import DashboardHooks from "./hooks/DashboardHooks";

import Navbar from "../../components/universal/Navbar";
import CustomProgressBar from "../../components/universal/CustomProgressBar";
import CustomCarousel from "../../components/universal/CustomCarousel";
import SingleTab from "../../components/universal/SingleTab";
import DateFilter from "../../components/universal/DateFilter";
import SearchBar from "../../components/universal/SearchBar";
import SquareCard from "../../components/universal/SquareCard";

import squareIcon1 from "../../assets/icons/squareCard/icon1.svg";
import userIcon from "../../assets/icons/userIcon.svg";
import startBox from "../../assets/icons/startBox.svg";
import LanguageIcon from "../../assets/icons/languageIcon.svg";
import CustomTabs from "../../components/universal/CustomTabs";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { FaDownload } from "react-icons/fa6";
import { downloadExcel } from "../../utils/DownloadExcel";
import { AiOutlineReload } from "react-icons/ai";
import moment from "moment";

function Dashboard() {
  // search bar component state with handler
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { value, onChangeSearchBar } = DashboardHooks();
  const { isAuthenticated, isLoading } = useAuth0();
  const { accessToken } = useSelector((state) => state.user);
  // tab clicking state for language table
  const [statsActiveTab, setStatsActiveTab] = useState("Videos");
  //states for stats, reports and graphs
  const [languages, setLanguages] = useState([]);
  const [allLayouts, setAllLayouts] = useState([]);
  const [mostLikedCategory, setMostLikedCategory] = useState({ xAxis: [], yAxis: [] });
  const [trendingCategory, setTrendingCategory] = useState({ xAxis: [], yAxis: [] });
  const [mostLikedVideos, setMostLikedVideos] = useState({ xAxis: [], yAxis: [] });
  const [trendingVideos, setTrendingVideos] = useState({ xAxis: [], yAxis: [] });
  const [mostLikedCurriculum, setMostLikedCurriculum] = useState({ xAxis: [], yAxis: [] });
  const [trendingCurriculum, setTrendingCurriculum] = useState({ xAxis: [], yAxis: [] });
  const [mostLikedCategories, setMostLikedCategories] = useState([]);
  const [animate, setAnimate] = useState(false);
  // const [animateLangaugeGraph, setAnimateLangaugeGraph] = useState(false);
  const [barGraphData, setBarGraphData] = useState({ xAxis: [], yAxis: [] });
  const [loading, setLoading] = useState(true);

  const [filterRange, setFilterRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const fetchReportsData = async ({ startDate, endDate }) => {
    setLoading(true);
    const layoutPromise = new Promise((resolve, reject) => {
      dispatch(
        readLayoutAction({
          callback: (res) => {
            if (res?.type === 1) {
              setAllLayouts(res?.data);
            }
            resolve();
          },
        })
      );
    });

    const categoriesPromise = new Promise((resolve, reject) => {
      dispatch(
        categoriesDashboardAction({
          apiPayloadRequest: {
            startDate: startDate ?? null,
            endDate: endDate ?? null,
          },
          callback: (res) => {
            setMostLikedCategory({
              xAxis: res?.data
                ?.filter((item) => item?.dash === "Most Like Categories")[0]
                ?.data?.map((item) => ({ ...item, value: item?.likeCount })),
              yAxis: res?.data
                ?.filter((item) => item?.dash === "Most Like Categories")[0]
                ?.data?.map((item) => ({ ...item, value: item?.title })),
            });
            setTrendingCategory({
              xAxis: res?.data
                ?.filter((item) => item?.dash === "Trending Categories")[0]
                ?.data?.map((item) => ({ ...item, value: item?.recentViews })),
              yAxis: res?.data
                ?.filter((item) => item?.dash === "Trending Categories")[0]
                ?.data?.map((item) => ({ ...item, value: item?.title })),
            });
            setMostLikedCategories(
              res?.data?.filter((item) => item?.dash === "Most Like Categories")[0]?.data || []
            );
            resolve();
          },
        })
      );
    });

    const videosPromise = new Promise((resolve, reject) => {
      dispatch(
        videosDashboardAction({
          apiPayloadRequest: {
            startDate: startDate ?? null,
            endDate: endDate ?? null,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setMostLikedVideos({
                xAxis: res?.data
                  ?.filter((item) => item?.dash === "Most Like Video")[0]
                  ?.data?.map((item) => ({ ...item, value: item?.likeCount })),
                yAxis: res?.data
                  ?.filter((item) => item?.dash === "Most Like Video")[0]
                  ?.data?.map((item) => ({ ...item, value: item?.title })),
              });
              setBarGraphData({
                xAxis: res?.data
                  ?.filter((item) => item?.dash === "Trending Videos")[0]
                  ?.data?.map((item) => ({ ...item, value: item?.recentViews })),
                yAxis: res?.data
                  ?.filter((item) => item?.dash === "Trending Videos")[0]
                  ?.data?.map((item) => ({ ...item, value: item?.title })),
              });
              setTrendingVideos({
                xAxis: res?.data
                  ?.filter((item) => item?.dash === "Trending Videos")[0]
                  ?.data?.map((item) => ({ ...item, value: item?.recentViews })),
                yAxis: res?.data
                  ?.filter((item) => item?.dash === "Trending Videos")[0]
                  ?.data?.map((item) => ({ ...item, value: item?.title })),
              });
            }
            resolve();
          },
        })
      );
    });

    const curriculumPromise = new Promise((resolve, reject) => {
      dispatch(
        curriculumDashboardAction({
          apiPayloadRequest: {
            startDate: startDate ?? null,
            endDate: endDate ?? null,
          },
          callback: (res) => {
            setMostLikedCurriculum({
              xAxis: res?.data
                ?.filter((item) => item?.dash === "Most Like Curriculum")[0]
                ?.data?.map((item) => ({ ...item, value: item?.likeCount })),
              yAxis: res?.data
                ?.filter((item) => item?.dash === "Most Like Curriculum")[0]
                ?.data?.map((item) => ({ ...item, value: item?.title })),
            });
            setTrendingCurriculum({
              xAxis: res?.data
                ?.filter((item) => item?.dash === "Trending Curriculum")[0]
                ?.data?.map((item) => ({ ...item, value: item?.recentViews })),
              yAxis: res?.data
                ?.filter((item) => item?.dash === "Trending Curriculum")[0]
                ?.data?.map((item) => ({ ...item, value: item?.title })),
            });
            resolve();
          },
        })
      );
    });

    const languagePromise = new Promise((resolve, reject) => {
      dispatch(
        readLanguageAction({
          apiPayloadRequest: {
            startDate: startDate ?? null,
            endDate: endDate ?? null,
          },
          callback: (res) => {
            setLanguages(res?.data);
            resolve();
          },
        })
      );
    });

    Promise.all([
      layoutPromise,
      categoriesPromise,
      videosPromise,
      curriculumPromise,
      languagePromise,
    ]).finally(() => setLoading(false));
  };

  useEffect(() => {
    const fetchToken = async () => {
      if (!isLoading) {
        if (isAuthenticated) {
          if (accessToken) {
            fetchReportsData({
              startDate: filterRange?.[0]?.startDate,
              endDate: filterRange?.[0]?.endDate,
            });
          }
        } else {
          localStorage.removeItem("token");
          navigate("/login");
        }
      }
    };

    fetchToken();
  }, [isAuthenticated, accessToken, isLoading, filterRange]);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div
            className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4 relative"
            id="reportContainer"
          >
            <div className="flex justify-between items-center w-full">
              <p className=" text-white text-3xl font-medium flex items-center gap-3">
                Dashboard{" "}
                <AiOutlineReload
                  className={`cursor-pointer text-2xl text-yellowish ${
                    loading ? "animate-spin" : ""
                  }`}
                  onClick={() =>
                    fetchReportsData({
                      startDate: filterRange?.[0]?.startDate,
                      endDate: filterRange?.[0]?.endDate,
                    })
                  }
                />
              </p>
              <p className="flex gap-3 items-center">
                <DateFilter range={filterRange} setRange={setFilterRange} />
              </p>
            </div>
            <div className="flex flex-wrap gap-4">
              <SquareCard
                heading={"Total No. of Languages"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={languages?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(languages?.map((d) => ({
                      "Language Name": d?.language_name,
                      "Language Code": d?.language_code,
                    })) ?? []),
                  ]);
                }}
                trail={""}
                handleOnClick={() => {
                  // setAnimateLangaugeGraph((prev) => !prev);
                  navigate("/languages/all");
                }}
              />
              <SquareCard
                heading={"Total No. of Layouts"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={allLayouts?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(allLayouts?.map((d) => ({
                      "Layout Name": d?.layout_name,
                      Order: d?.order,
                      "Created At": d?.createdDate
                        ? moment(d?.createdDate)?.format("DD/MM/YYYY")
                        : "",
                    })) ?? []),
                  ]);
                }}
                trail={""}
                handleOnClick={() => {
                  navigate("/layout/all");
                }}
              />
              <SquareCard
                heading={"No. Trending of Categories"}
                icon={userIcon}
                iconStyle={"h-11 w-11"}
                info={trendingCategory?.xAxis?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(trendingCategory?.xAxis?.map((d) => ({
                      "Category Name": d?.title,
                      "Total Likes": d?.value,
                    })) ?? []),
                  ]);
                }}
                trail={""}
                handleOnClick={() => {
                  setAnimate((prev) => !prev);
                  setStatsActiveTab("Categories");
                  setBarGraphData({
                    xAxis: [...trendingCategory?.xAxis],
                    yAxis: [...trendingCategory?.yAxis],
                  });
                }}
              />
              <SquareCard
                heading={"No. Trending of Videos"}
                icon={LanguageIcon}
                iconStyle={"h-11 w-11"}
                info={trendingVideos?.xAxis?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(trendingVideos?.xAxis?.map((d) => ({
                      "Video Title": d?.title,
                      "Total Likes": d?.value,
                    })) ?? []),
                  ]);
                }}
                trail={""}
                handleOnClick={() => {
                  setAnimate((prev) => !prev);
                  setStatsActiveTab("Videos");
                  setBarGraphData({
                    xAxis: [...trendingVideos?.xAxis],
                    yAxis: [...trendingVideos?.yAxis],
                  });
                }}
              />
              <SquareCard
                heading={"No. Trending of Curriculum"}
                icon={startBox}
                iconStyle={"h-11 w-11"}
                info={trendingCurriculum?.xAxis?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(trendingCurriculum?.xAxis?.map((d) => ({
                      "Curriculum Name": d?.title,
                      "Total Likes": d?.value,
                    })) ?? []),
                  ]);
                }}
                trail={""}
                handleOnClick={() => {
                  setAnimate((prev) => !prev);
                  setStatsActiveTab("Curriculum");
                  setBarGraphData({
                    xAxis: [...trendingCurriculum?.xAxis],
                    yAxis: [...trendingCurriculum?.yAxis],
                  });
                }}
              />
            </div>
            <div className="grid grid-rows-2 md:grid-cols-6 grid-cols-1 flex-1 h-fit gap-x-8 gap-y-8 my-4">
              <div className="md:col-span-3 row-span-1 bg-blackishV2 px-6 py-4 rounded-2xl flex flex-col gap-4">
                <div className="flex justify-between">
                  <p className="text-lg font-semibold text-white">Most Liked </p>
                </div>
                <CustomTabs
                  tabsContentObject={{
                    Videos: (
                      <>
                        <button
                          className="text-yellowish absolute top-2 right-2 z-50"
                          onClick={() => {
                            downloadExcel([
                              ...(mostLikedVideos?.xAxis?.map((d) => ({
                                "Video Title": d?.title,
                                "Total Likes": d?.value,
                              })) ?? []),
                            ]);
                          }}
                        >
                          <FaDownload />
                        </button>
                        <ReactECharts
                          className="py-4 bg-blackish mt-5"
                          onEvents={{
                            click: (e) => {
                              if (e?.componentType === "series") {
                                navigate("/videos/add", {
                                  state: {
                                    _id: e?.data?._id || "",
                                  },
                                });
                              }
                            },
                          }}
                          option={{
                            tooltip: {
                              trigger: "item",
                              axisPointer: {
                                type: "shadow",
                              },
                            },
                            grid: {
                              left: "3%",
                              right: "4%",
                              bottom: "3%",
                              containLabel: true,
                            },
                            yAxis: [
                              {
                                type: "category",
                                data: [...mostLikedVideos?.yAxis],
                                axisLabel: { width: 100, overflow: "truncate" },
                                axisTick: { show: false },
                              },
                            ],
                            xAxis: [
                              {
                                type: "value",
                                splitLine: {
                                  show: false,
                                  lineStyle: {
                                    type: "dashed",
                                  },
                                },
                              },
                            ],
                            series: [
                              {
                                name: "Video",
                                type: "bar",
                                barWidth: "65%",
                                color: ["#EFBC51"],
                                itemStyle: {
                                  normal: {
                                    shadowColor: "rgb(239,188,81, 0.3)",
                                    shadowBlur: 15,
                                  },
                                },
                                data: [...mostLikedVideos?.xAxis],
                                layout: "horizontal",
                                smooth: true,
                              },
                            ],
                          }}
                          notMerge={true}
                          lazyUpdate={true}
                          theme={"halloween"}
                          onChartReady={() => console.log("chart loaded")}
                        />
                      </>
                    ),
                    Categories: (
                      <>
                        <button
                          className="text-yellowish absolute top-2 right-2 z-50"
                          onClick={() => {
                            downloadExcel([
                              ...(mostLikedCategories?.map((d) => ({
                                "Category Name": d?.title,
                                "Total Likes": d?.likeCount,
                              })) ?? []),
                            ]);
                          }}
                        >
                          <FaDownload />
                        </button>
                        <ReactECharts
                          className="py-4 bg-blackish mt-5"
                          onEvents={{
                            click: (e) => {
                              if (e?.componentType === "series") {
                                navigate("/categories/add", {
                                  state: {
                                    _id: e?.data?._id,
                                  },
                                });
                              }
                            },
                          }}
                          option={{
                            tooltip: {
                              trigger: "item",
                              axisPointer: {
                                type: "shadow",
                              },
                            },
                            grid: {
                              left: "3%",
                              right: "4%",
                              bottom: "3%",
                              containLabel: true,
                            },
                            yAxis: [
                              {
                                type: "category",
                                data: [...mostLikedCategory?.yAxis],
                                axisLabel: { width: 100, overflow: "truncate" },
                                axisTick: { show: false },
                              },
                            ],
                            xAxis: [
                              {
                                type: "value",
                                splitLine: {
                                  show: false,
                                  lineStyle: {
                                    type: "dashed",
                                  },
                                },
                              },
                            ],
                            series: [
                              {
                                name: "Category",
                                type: "bar",
                                barWidth: "65%",
                                color: ["#EFBC51"],
                                itemStyle: {
                                  normal: {
                                    shadowColor: "rgb(239,188,81, 0.3)",
                                    shadowBlur: 15,
                                  },
                                },
                                data: [...mostLikedCategory?.xAxis],
                                layout: "horizontal",
                                smooth: true,
                              },
                            ],
                          }}
                          notMerge={true}
                          lazyUpdate={true}
                          theme={"halloween"}
                          onChartReady={() => console.log("chart loaded")}
                        />
                      </>
                    ),
                    Curriculum: (
                      <>
                        <button
                          className="text-yellowish absolute top-2 right-2 z-50"
                          onClick={() => {
                            downloadExcel([
                              ...(mostLikedCurriculum?.xAxis?.map((d) => ({
                                "Curriculum Name": d?.title,
                                "Total Likes": d?.value,
                              })) ?? []),
                            ]);
                          }}
                        >
                          <FaDownload />
                        </button>
                        <ReactECharts
                          className="py-4 bg-blackish mt-5"
                          onEvents={{
                            click: (e) => {
                              if (e?.componentType === "series") {
                                navigate("/curriculum/add", {
                                  state: {
                                    _id: e?.data?._id || "",
                                  },
                                });
                              }
                            },
                          }}
                          option={{
                            tooltip: {
                              trigger: "item",
                              axisPointer: {
                                type: "shadow",
                              },
                            },
                            grid: {
                              left: "3%",
                              right: "4%",
                              bottom: "3%",
                              containLabel: true,
                            },
                            yAxis: [
                              {
                                type: "category",
                                data: [...mostLikedCurriculum?.yAxis],
                                axisLabel: { width: 100, overflow: "truncate" },
                                axisTick: { show: false },
                              },
                            ],
                            xAxis: [
                              {
                                type: "value",
                                splitLine: {
                                  show: false,
                                  lineStyle: {
                                    type: "dashed",
                                  },
                                },
                              },
                            ],
                            series: [
                              {
                                name: "Curriculum",
                                type: "bar",
                                barWidth: "65%",
                                color: ["#EFBC51"],
                                itemStyle: {
                                  normal: {
                                    shadowColor: "rgb(239,188,81, 0.3)",
                                    shadowBlur: 15,
                                  },
                                },
                                data: [...mostLikedCurriculum?.xAxis],
                                layout: "horizontal",
                                smooth: true,
                              },
                            ],
                          }}
                          notMerge={true}
                          lazyUpdate={true}
                          theme={"halloween"}
                          onChartReady={() => console.log("chart loaded")}
                        />
                      </>
                    ),
                  }}
                  tabsTitleArray={["Videos", "Categories", "Curriculum"]}
                  profileView={true}
                />
              </div>
              <div className="md:col-span-3 row-span-1 bg-blackishV2 px-6 py-4 rounded-2xl flex flex-col gap-4">
                <div className="flex justify-between">
                  <p className="text-lg font-semibold text-white">Trending</p>
                </div>
                <div className="flex justify-end md:items-center md:flex-row flex-col gap-4">
                  <div className="md:w-[20vw] w-full">
                    <CustomCarousel
                      rows={1}
                      cols={2}
                      gapX={0}
                      gapY={0}
                      // easier to pass this array like this rather than separating it
                      completeSliders={[
                        <SingleTab activeTab={statsActiveTab} tab={"Videos"} />,
                        <SingleTab activeTab={statsActiveTab} tab={"Categories"} />,
                        <SingleTab activeTab={statsActiveTab} tab={"Curriculum"} />,
                      ]}
                      numberOfCardsShownInSingleSlide={2}
                      handleCardOnClick={(card) => {
                        setStatsActiveTab(card.props.tab);
                        switch (card.props.tab) {
                          case "Videos":
                            setBarGraphData({
                              xAxis: [...trendingVideos?.xAxis],
                              yAxis: [...trendingVideos?.yAxis],
                            });
                            break;
                          case "Categories":
                            setBarGraphData({
                              xAxis: [...trendingCategory?.xAxis],
                              yAxis: [...trendingCategory?.yAxis],
                            });
                            break;
                          case "Curriculum":
                            setBarGraphData({
                              xAxis: [...trendingCurriculum?.xAxis],
                              yAxis: [...trendingCurriculum?.yAxis],
                            });
                            break;
                          // add other tabs here
                          default:
                            break;
                        }
                      }}
                      topNext={"top-3"}
                      topPrev={"top-9"}
                    />
                  </div>
                </div>
                <div
                  className={`${
                    animate
                      ? "absolute bg-blackishV2 w-full h-full left-0  top-0 p-5 rounded-lg z-50 transition-all duration-500 ease-out overflow-y-auto"
                      : "transition-all duration-500 ease-in-out"
                  }`}
                >
                  {animate && (
                    <button
                      className="flex gap-2 font-bold text-xs px-4 py-2 bg-yellowish rounded-md"
                      onClick={() => setAnimate((prev) => !prev)}
                    >
                      <FaRegArrowAltCircleLeft size={16} /> <p>Back</p>
                    </button>
                  )}
                  <div className="w-full h-full relative">
                    <button
                      className="text-yellowish absolute top-2 right-2 z-50"
                      onClick={() => {
                        downloadExcel([
                          ...(barGraphData?.xAxis?.map((d) => ({
                            [`${
                              statsActiveTab === "Videos"
                                ? "Video"
                                : statsActiveTab === "Categories"
                                ? "Category"
                                : statsActiveTab
                            } Name`]: d?.title,
                            "Total Likes": d?.value,
                          })) ?? []),
                        ]);
                      }}
                    >
                      <FaDownload />
                    </button>

                    <ReactECharts
                      key={barGraphData?.yAxis?.length}
                      className="py-4 bg-blackish mt-5"
                      onEvents={{
                        click: (e) => {
                          if (e?.componentType === "series") {
                            if (statsActiveTab === "Videos") {
                              navigate("/videos/add", {
                                state: {
                                  _id: e?.data?._id || "",
                                },
                              });
                            } else if (statsActiveTab === "Categories") {
                              navigate("/categories/add", {
                                state: {
                                  _id: e?.data?._id || "",
                                },
                              });
                            } else if (statsActiveTab === "Curriculum") {
                              navigate("/curriculum/add", {
                                state: {
                                  _id: e?.data?._id || "",
                                },
                              });
                            }
                          }
                        },
                      }}
                      option={{
                        resposive: true,
                        // maintainAspectRatio: true,
                        tooltip: {
                          trigger: "axis",
                          axisPointer: {
                            type: "shadow",
                          },
                        },
                        grid: {
                          left: "3%",
                          right: "4%",
                          bottom: "3%",
                          containLabel: true,
                        },
                        yAxis: [
                          {
                            type: "category",
                            data: [...barGraphData?.yAxis],
                            axisLabel: { width: 100, overflow: "truncate" },
                            axisTick: { show: false },
                            // triggerEvent: true,
                          },
                        ],
                        xAxis: [
                          {
                            // triggerEvent: true,
                            type: "value",
                            splitLine: {
                              show: false,
                              lineStyle: {
                                type: "dashed",
                              },
                            },
                          },
                        ],
                        series: [
                          {
                            name: "Most Liked",
                            type: "bar",
                            barWidth: "65%",
                            data: [...barGraphData?.xAxis],
                            color: ["#EFBC51"],
                            itemStyle: {
                              normal: {
                                shadowColor: "rgb(239,188,81, 0.3)",
                                shadowBlur: 15,
                              },
                            },
                          },
                        ],
                        graph: {},
                      }}
                      notMerge={true}
                      lazyUpdate={true}
                      theme={"halloween"}
                      onChartReady={() => console.log("chart loaded")}
                    />
                  </div>
                </div>
              </div>
              <div className="md:col-span-3 row-span-1 bg-blackishV2 px-6 py-4 rounded-2xl">
                <div className="flex justify-between">
                  <p className="text-white font-semibold text-lg">Most Liked Categories</p>
                  <button
                    className="text-yellowish"
                    onClick={() => {
                      downloadExcel([
                        ...(mostLikedCategories?.map((d) => ({
                          "Category Name": d?.title,
                          "Total Likes": d?.likeCount,
                        })) ?? []),
                      ]);
                    }}
                  >
                    <FaDownload />
                  </button>
                </div>
                <div className="flex flex-col gap-6 h-[244px] overflow-auto mt-4">
                  {mostLikedCategories?.map((item) => {
                    return (
                      <CustomProgressBar
                        parentClassName="cursor-pointer"
                        key={"most-liked-category-" + item?._id}
                        width={`w-[${(
                          (Number(
                            mostLikedCategories.reduce((total, item) => total + item?.likeCount, 0)
                          ) *
                            100) /
                          item?.likeCount
                        )?.toFixed(0)}%]`}
                        title={item?.title ?? "-"}
                        data={item?.likeCount}
                        clickHandler={() => {
                          navigate("/categories/add", {
                            state: {
                              _id: item?._id,
                            },
                          });
                        }}
                      />
                    );
                  })}
                </div>
              </div>
              {/* <div className="md:col-span-3 bg-blackishV2 px-6 py-4 rounded-2xl">
                <p className="text-white font-semibold text-lg">Languages</p>
                <div
                  className={`${
                    animateLangaugeGraph
                      ? "absolute bg-blackishV2 w-full h-full left-0  top-0 p-5 rounded-lg z-50 transition-all duration-500 ease-out overflow-y-auto"
                      : "transition-all duration-500 ease-in-out"
                  }`}
                >
                  {animateLangaugeGraph && (
                    <button
                      className="flex gap-2 font-bold text-xs px-4 py-2 bg-yellowish rounded-md"
                      onClick={() => setAnimateLangaugeGraph((prev) => !prev)}
                    >
                      <FaRegArrowAltCircleLeft size={16} /> <p>Back</p>
                    </button>
                  )}
                  <ReactECharts
                    className="py-4 bg-blackish mt-5 !h-[350px]"
                    onEvents={{
                      click: (e) => {
                        if (e?.componentType === "series") {
                          navigate("/languages/add", {
                            state: {
                              targetLanguage:
                                e?.data?.language_name + " - " + e?.data?.language_code,
                            },
                          });
                        }
                      },
                    }}
                    option={{
                      tooltip: {
                        trigger: "item",
                        formatter: "{b}",
                      },
                      series: [
                        {
                          name: "Language",
                          type: "pie",
                          radius: [20, 140],
                          center: ["50%", "50%"],
                          roseType: "area",
                          itemStyle: {
                            borderRadius: 5,
                          },
                          label: {
                            color: "#fff",
                          },
                          data: [
                            ...languages?.map((item, index) => ({
                              ...item,
                              value: index + Math.round(Math.random() * languages?.length),
                              name: item?.language_name,
                            })),
                          ],
                          color: [
                            ...languages?.map(
                              (item, index) => `rgba(239,188,81, ${Math.random().toFixed(2)})`
                            ),
                          ],
                        },
                      ],
                      responsive: true,
                    }}
                    notMerge={true}
                    lazyUpdate={true}
                    theme={"halloween"}
                    onChartReady={() => console.log("chart loaded")}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
