import React, { useEffect, useState } from "react";
import SearchBar from "../../components/universal/SearchBar";
import SquareCard from "../../components/universal/SquareCard";
import squareIcon1 from "../../assets/icons/squareCard/icon1.svg";
import userIcon from "../../assets/icons/userIcon.svg";
import CustomCarousel from "../../components/universal/CustomCarousel";
import SingleTab from "../../components/universal/SingleTab";
import { AiOutlinePlus, AiOutlineReload } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import VideosHooks from "./hooks/VideosHooks";
import Navbar from "../../components/universal/Navbar";
import { useDispatch } from "react-redux";
import { videosDashboardAction } from "../../store/Layout/LayoutAction";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import ReactECharts from "echarts-for-react";
import DateFilter from "../../components/universal/DateFilter";
import { subDays } from "date-fns";
import { FaDownload } from "react-icons/fa6";
import { downloadExcel } from "../../utils/DownloadExcel";

function Videos() {
  // search bar component state with handler
  const dispatch = useDispatch();
  const { value, onChangeSearchBar } = VideosHooks();
  // tab clicking state for language table
  const [activeTab, setActiveTab] = useState("Trending");
  const [loading, setLoading] = useState(true);
  const [userTab, setUserTab] = useState("By Language");
  const [trendingVideos, setTrendingVideos] = useState([]);
  const [mostLikedVideos, setMostLikedVideos] = useState([]);
  const [mostWatchedVideos, setMostWatchedVideos] = useState([]);
  const [languageTabData, setLanguageTabData] = useState({ xAxis: [], yAxis: [] });

  console.log("mostWatchedVideos", mostWatchedVideos);
  console.log("mostLikedVideos", mostLikedVideos);
  console.log("languageTabData", languageTabData);
  console.log("trendingVideos", trendingVideos);

  const [chartData, setChartData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [animate, setAnimate] = useState(false);

  const navigate = useNavigate();

  console.log("chartData", chartData);
  console.log("countryData", countryData);

  const onNavigateToAddVideos = () => {
    navigate("/videos/add", { state: { _id: "" } });
  };

  const [filterRange, setFilterRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const fetchReportsData = ({ startDate, endDate }) => {
    setLoading(true);
    const videosPromise = new Promise((resolve, reject) => {
      dispatch(
        videosDashboardAction({
          apiPayloadRequest: {
            startDate: startDate ?? null,
            endDate: endDate ?? null,
          },
          callback: (res) => {
            const data = res?.data ? res?.data : [];
            if (res?.type === 1) {
              setCountryData(
                data?.filter((item) => item.dash === "Country based video Likes")[0]?.data
              );

              setLanguageData(
                data?.filter((item) => item.dash === "Language based video likes")[0]?.data
              );

              setChartData({
                xAxis: data
                  ?.filter((item) => item.dash === "Language based video likes")[0]
                  ?.data?.map((item) => item?._id),
                yAxis: data
                  ?.filter((item) => item.dash === "Language based video likes")[0]
                  ?.data?.map((item) => ({ value: item?.count, name: item?._id })),
              });

              setLanguageTabData({
                xAxis: (
                  data?.filter((item) => item.dash === "Trending Videos")[0]?.data || []
                )?.map((d) => ({ ...d, value: d?.recentViews })),
                yAxis: (
                  data?.filter((item) => item.dash === "Trending Videos")[0]?.data || []
                )?.map((d) => ({ ...d, value: d?.title })),
              });

              setMostLikedVideos(
                data?.filter((item) => item.dash === "Most Like Video")[0]?.data || []
              );
              setMostWatchedVideos(
                data?.filter((item) => item.dash === "Most Watched Videos")[0]?.data || []
              );
            }
            resolve();
          },
        })
      );
    });

    Promise.all([videosPromise]).finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchReportsData({
      startDate: filterRange?.[0]?.startDate,
      endDate: filterRange?.[0]?.endDate,
    });
  }, [filterRange]);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div
            className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4 relative"
            id="reportContainer"
          >
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <div className="flex justify-between items-center flex-1">
                <p className=" text-white text-3xl font-medium flex items-center gap-3">
                  Videos
                  <AiOutlineReload
                    className={`cursor-pointer text-2xl text-yellowish ${
                      loading ? "animate-spin" : ""
                    }`}
                    onClick={() =>
                      fetchReportsData({
                        startDate: filterRange?.[0]?.startDate,
                        endDate: filterRange?.[0]?.endDate,
                      })
                    }
                  />
                </p>
                <p className="flex gap-3 items-center">
                  <DateFilter range={filterRange} setRange={setFilterRange} />
                </p>
              </div>
              <div className="flex gap-4">
                <Link to={"/videos/all"}>
                  <button className=" text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                    See All Videos
                  </button>
                </Link>

                <button
                  onClick={onNavigateToAddVideos}
                  className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl"
                >
                  <AiOutlinePlus /> Add Video
                </button>
              </div>
            </div>

            <div className="flex gap-4 flex-wrap">
              <SquareCard
                heading={"No. of Trending Videos"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={trendingVideos?.length || 0}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(trendingVideos?.map((d) => ({
                      "Video Title": d?.title,
                      "Total Likes": d?.likeCount,
                    })) ?? []),
                  ]);
                }}
                trail={""}
                percentage={"0%"}
                handleOnClick={() => {
                  setAnimate((prev) => !prev);
                  setActiveTab("Trending");
                  setLanguageTabData({
                    xAxis: trendingVideos?.map((d) => ({ ...d, value: d?.likeCount })),
                    yAxis: trendingVideos?.map((d) => ({ ...d, value: d?.title })),
                  });
                }}
              />
              <SquareCard
                heading={"No. of most liked videos"}
                icon={userIcon}
                iconStyle={"h-11 w-11"}
                info={mostLikedVideos?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(mostLikedVideos?.map((d) => ({
                      "Video Title": d?.title,
                      "Total Likes": d?.likeCount,
                    })) ?? []),
                  ]);
                }}
                trail={""}
                percentage={"0%"}
                handleOnClick={() => {
                  setAnimate((prev) => !prev);
                  setActiveTab("Most Liked");
                  setLanguageTabData({
                    xAxis: mostLikedVideos?.map((d) => ({ ...d, value: d?.likeCount })),
                    yAxis: mostLikedVideos?.map((d) => ({ ...d, value: d?.title })),
                  });
                }}
              />
              <SquareCard
                heading={"No. of most watched videos"}
                icon={userIcon}
                iconStyle={"h-11 w-11"}
                info={mostWatchedVideos?.length || 0}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(mostWatchedVideos?.map((d) => ({
                      "Video Title": d?.title,
                      "Average Watch Duration": d?.averageWatched ?? 0,
                    })) ?? []),
                  ]);
                }}
                trail={""}
                percentage={"0%"}
                handleOnClick={() => {
                  setAnimate((prev) => !prev);
                  setActiveTab("Most Watched");
                  setLanguageTabData({
                    xAxis: mostWatchedVideos?.map((d) => ({ ...d, value: d?.averageWatched })),
                    yAxis: mostWatchedVideos?.map((d) => ({ ...d, value: d?.title })),
                  });
                }}
              />
            </div>
            <div className="grid grid-rows-1 md:grid-cols-6 grid-cols-1 flex-1 h-full gap-x-4 gap-y-4 my-4">
              <div className="md:col-span-3 row-span-1 bg-blackishV2 px-6 py-4 rounded-2xl flex justify-between">
                <div className="text-white w-full">
                  <div className="flex justify-between items-center w-full">
                    <div className="flex items-center gap-8">
                      <p className="text-lg font-semibold text-white">Reports</p>
                    </div>
                    <div className="md:w-[15vw] w-full">
                      <div className="">
                        <CustomCarousel
                          rows={1}
                          cols={2}
                          gapX={0}
                          gapY={0}
                          // easier to pass this array like this rather than separating it
                          completeSliders={[
                            <SingleTab
                              activeTab={userTab}
                              tab={"By Language"}
                              tabClassName="whitespace-nowrap"
                            />,
                            <SingleTab
                              activeTab={userTab}
                              tab={"By Country"}
                              tabClassName="whitespace-nowrap"
                            />,
                          ]}
                          numberOfCardsShownInSingleSlide={3}
                          handleCardOnClick={(card) => {
                            setUserTab(card.props.tab);
                            switch (card.props.tab) {
                              case "By Language":
                                setChartData({
                                  xAxis: languageData?.map((item) => item?._id),
                                  yAxis: languageData?.map((item) => ({
                                    value: item?.count,
                                    name: item?._id,
                                  })),
                                });
                                break;
                              case "By Country":
                                setChartData({
                                  xAxis: countryData?.map((item) => item?._id),
                                  yAxis: countryData?.map((item) => ({
                                    value: item?.count,
                                    name: item?._id,
                                  })),
                                });
                                break;
                              // add other tabs here
                              default:
                                break;
                            }
                          }}
                          topNext={"hidden"}
                          topPrev={"hidden"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="block relative">
                    {chartData?.xAxis?.length > 0 && (
                      <>
                        <button
                          className="text-yellowish absolute top-2 right-2 z-50"
                          onClick={() => {
                            downloadExcel([
                              ...(chartData?.yAxis?.map((d) => ({
                                [`${userTab === "By Language" ? "Language" : "Country"}`]: d?.name,
                                "Total Likes": d?.value,
                              })) ?? []),
                            ]);
                          }}
                        >
                          <FaDownload />
                        </button>
                        <ReactECharts
                          className="py-4 bg-blackish mt-5"
                          option={{
                            tooltip: {
                              trigger: "item",
                              axisPointer: {
                                type: "shadow",
                              },
                            },
                            series: [
                              {
                                data: [...chartData?.yAxis],
                                color: ["#EFBC51"],
                                label: {
                                  color: "#fff",
                                },
                                name: userTab,
                                type: "pie",
                                radius: ["40%", "70%"],
                                avoidLabelOverlap: true,
                                padAngle: 5,
                                itemStyle: {
                                  borderRadius: 10,
                                },
                              },
                            ],
                          }}
                          notMerge={true}
                          lazyUpdate={true}
                          theme={"halloween"}
                          onChartReady={() => console.log("chart loaded")}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="md:col-span-3 row-span-1 bg-blackishV2 px-6 py-4 rounded-2xl flex flex-col gap-4">
                <div className="flex justify-between md:items-center md:flex-row flex-col gap-4">
                  <p className="text-lg font-semibold text-white">Videos</p>
                  <div className="md:w-[20vw] w-full">
                    <CustomCarousel
                      rows={1}
                      cols={2}
                      gapX={0}
                      gapY={0}
                      // easier to pass this array like this rather than separating it
                      completeSliders={[
                        <SingleTab activeTab={activeTab} tab={"Trending"} />,
                        <SingleTab activeTab={activeTab} tab={"Most Liked"} />,
                        <SingleTab activeTab={activeTab} tab={"Most Watched"} />,
                      ]}
                      numberOfCardsShownInSingleSlide={2}
                      handleCardOnClick={(card) => {
                        setActiveTab(card.props.tab);
                        switch (card.props.tab) {
                          case "Trending":
                            setLanguageTabData({
                              xAxis: trendingVideos?.map((d) => ({ ...d, value: d?.likeCount })),
                              yAxis: trendingVideos?.map((d) => ({ ...d, value: d?.title })),
                            });
                            break;
                          case "Most Liked":
                            setLanguageTabData({
                              xAxis: mostLikedVideos?.map((d) => ({ ...d, value: d?.likeCount })),
                              yAxis: mostLikedVideos?.map((d) => ({ ...d, value: d?.title })),
                            });
                            break;
                          case "Most Watched":
                            setLanguageTabData({
                              xAxis: mostWatchedVideos?.map((d) => ({
                                ...d,
                                value: d?.averageWatched,
                              })),
                              yAxis: mostWatchedVideos?.map((d) => ({ ...d, value: d?.title })),
                            });
                            break;
                          // add other tabs here
                          default:
                            break;
                        }
                      }}
                      topNext={"top-3"}
                      topPrev={"top-9"}
                    />
                  </div>
                </div>
                <div
                  className={`${
                    animate
                      ? "absolute bg-blackishV2 w-full h-full left-0  top-0 p-5 rounded-lg z-50 transition-all duration-500 ease-out overflow-y-auto"
                      : "transition-all duration-500 ease-in-out"
                  }`}
                >
                  {animate && (
                    <button
                      className="flex gap-2 font-bold text-xs px-4 py-2 bg-yellowish rounded-md"
                      onClick={() => setAnimate((prev) => !prev)}
                    >
                      <FaRegArrowAltCircleLeft size={16} /> <p>Back</p>
                    </button>
                  )}
                  <div className="w-full h-full relative">
                    <button
                      className="text-yellowish absolute top-2 right-2 z-50"
                      onClick={() => {
                        downloadExcel([
                          ...(languageTabData?.xAxis?.map((d) => ({
                            "Video Title": d?.title,
                            "Total Likes": d?.value,
                          })) ?? []),
                        ]);
                      }}
                    >
                      <FaDownload />
                    </button>
                    <ReactECharts
                      key={languageTabData?.yAxis?.length}
                      className="py-4 bg-blackish mt-5"
                      onEvents={{
                        click: (e) => {
                          if (e?.componentType === "series") {
                            navigate("/videos/add", {
                              state: {
                                _id: e?.data?._id || "",
                              },
                            });
                          }
                        },
                      }}
                      option={{
                        resposive: true,
                        // maintainAspectRatio: true,
                        tooltip: {
                          trigger: "axis",
                          axisPointer: {
                            type: "shadow",
                          },
                        },
                        grid: {
                          left: "3%",
                          right: "4%",
                          bottom: "3%",
                          containLabel: true,
                        },
                        yAxis: [
                          {
                            type: "category",
                            data: [...languageTabData?.yAxis],
                            axisLabel: { width: 100, overflow: "truncate" },
                            axisTick: { show: false },
                            // triggerEvent: true,
                          },
                        ],
                        xAxis: [
                          {
                            // triggerEvent: true,
                            type: "value",
                            splitLine: {
                              show: false,
                              lineStyle: {
                                type: "dashed",
                              },
                            },
                          },
                        ],
                        series: [
                          {
                            name: "Most Liked",
                            type: "bar",
                            barWidth: "65%",
                            data: [...languageTabData?.xAxis],
                            color: ["#EFBC51"],
                            itemStyle: {
                              normal: {
                                shadowColor: "rgb(239,188,81, 0.3)",
                                shadowBlur: 15,
                              },
                            },
                          },
                        ],
                        graph: {},
                      }}
                      notMerge={true}
                      lazyUpdate={true}
                      theme={"halloween"}
                      onChartReady={() => console.log("chart loaded")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Videos;
