import React, { useState, useRef } from "react";
import { AiOutlineArrowUp } from "react-icons/ai";
import { uploadAssestsAction } from "../../store/Languages/LanguageAction";
import { useDispatch } from "react-redux";

const ImageUpload = ({ placeholderImage, imageUrl, setImageUrl, isUpload }) => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => setImageUrl(res?.url),
      })
    );
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="relative h-40 w-40 flex flex-col gap-2 items-center rounded-full ">
      <div className="image-container relative h-full w-full rounded-md">
        {isUpload && (
          <div className="upload-button h-full rounded-full absolute w-full top-0 left-0 backdrop-blur-[0.5px] backdrop-contrast-50 backdrop-brightness-75  cursor-pointer  z-20 ">
            <button
              className="absolute flex items-center gap-2 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-[12px] font-medium px-4 py-2 rounded-full border"
              onClick={handleUploadButtonClick}
            >
              <AiOutlineArrowUp />
              <span>Upload</span>
            </button>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageUpload}
              className="h-50"
              style={{ display: "none" }}
            />
          </div>
        )}
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="Uploaded"
            className="absolute top-0 z-10 h-full rounded-full w-full object-cover object-top overflow-hidden"
          />
        ) : (
          <img
            src={placeholderImage}
            alt="Placeholder"
            className="absolute rounded-full top-0 z-10 h-full w-full object-cover overflow-hidden"
          />
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
