import SearchBar from "../../components/universal/SearchBar";
import CustomTabsV2 from "../../components/universal/CustomTabsV2";

import CustomUserFields from "../../components/users/CustomUserFields";
import { AddUserHooks } from "./hooks/AddUserHooks";
import Navbar from "../../components/universal/Navbar";
import { useLocation } from "react-router-dom";
function AddUser() {
  // search bar component state with handler
  const location = useLocation();

  const id = location?.state?._id;
  const { value, onChangeSearchBar, UserFields, UserFields2, onSave, onCancel } = AddUserHooks(id);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium">Users</p>
              {/* <Link to={"/users/all"}>
                <button className=" text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                  Back
                </button>
              </Link> */}
            </div>
            <div className="grid md:grid-cols-3 grid-cols-1 grid-rows-1 h-full w-full rounded-2xl overflow-hidden">
              <div className="md:col-span-2 col-span-1 row-span-1 bg-blackishV2 p-4 px-6">
                <div className="flex gap-4 items-center justify-between">
                  <p className="text-white font-semibold text-lg whitespace-nowrap">
                    {id ? "Update" : "Add"} User
                  </p>
                  <div className="w-full flex items-end justify-end gap-4">
                    <button
                      className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                      onClick={(e) => {
                        onSave(e);
                      }}
                    >
                      {id ? "Update" : "Save"}
                    </button>
                  </div>
                </div>

                <div className="w-full h-[1px] border-greyishV2 border my-4 mb-6"></div>

                <div className="FIELDS h-fit">
                  <CustomUserFields
                    data={id !== undefined ? UserFields2 : UserFields}
                    onCancel={onCancel}
                    onSave={onSave}
                  />
                </div>
              </div>
              <div className="col-span-1 row-span-1 bg-[#1B1B1B]">
                <CustomTabsV2
                  tabHeight={"h-[60px]"}
                  tabsTitleArray={["Stats"]}
                  tabsContentObject={{
                    Stats: (
                      <div className="mt-8 flex items-center justify-center w-full text-white">
                        {id ? "No Data Found..." : "No stats available for user"}
                      </div>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddUser;
