import React, { useState } from "react";
import { AddCategoriesHooks } from "./hooks/CategoriesHooks";
import SearchBar from "../../components/universal/SearchBar";
import AddCategoryFields from "../../components/categories/AddCategoryFields";
import Navbar from "../../components/universal/Navbar";
import { Link, useLocation } from "react-router-dom";
function AddCategory() {
  // search bar component state with handler
  const { value, onChangeSearchBar } = AddCategoriesHooks();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  return (
    <div className="MAIN-CONTENT bg-[#191919] flex flex-col h-full">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="pb-6 pt-6 pr-6">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
        </div>
        <div className="flex justify-end pb-2">
          {/* <Link to={"/categories/all"}>
            <button className=" text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
              Back
            </button>
          </Link> */}
          <Link to={"/categories/all"}>
            <button className=" text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
              See All Categories
            </button>
          </Link>
        </div>
        <AddCategoryFields data={location?.state} setLoading={setLoading} />
      </div>
    </div>
  );
}

export default AddCategory;
