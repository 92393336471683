import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsNavigationAlertActiveAction } from "../../store/GlobalLoader/GlobalLoaderAction";

const NavigationConfirmModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isNavigationAlertActive } = useSelector((state) => state.globalLoader);

  return (
    <ReactModal
      isOpen={isNavigationAlertActive}
      className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
    >
      <div className="bg-blackishV2 w-[30dvw] gap-3 text-white flex flex-col items-center px-20 py-12">
        <h1 className="font-bold text-xl mb-5">Are you sure you want to quit this page?</h1>
        <div className="w-full gap-4 grid grid-cols-2">
          <button
            onClick={() => dispatch(setIsNavigationAlertActiveAction(false))}
            className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish py-2 px-6"
          >
            Cancel
          </button>
          <button
            className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish py-2 px-6"
            onClick={() => {
              dispatch(setIsNavigationAlertActiveAction(false));
              navigate(-1);
            }}
          >
            Ok
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default NavigationConfirmModal;
