import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { AllLanguagesHooks } from "./hooks/LanguagesHooks";

import SearchBar from "../../components/universal/SearchBar";
import Navbar from "../../components/universal/Navbar";
import CustomTable from "../../components/universal/CustomTable";
import CustomCarousel from "../../components/universal/CustomCarousel";
import SingleTab from "../../components/universal/SingleTab";
import { allLanguagesHeaders } from "./Constants";

import { AiOutlinePlus } from "react-icons/ai";
import ConfirmToast from "../../components/universal/ConfirmToast";
import { confirmAlert } from "react-confirm-alert";
import Toastiy from "../../components/universal/Toastiy";

function AllLanguages() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("All");
  const [searchParams, setSearchParams] = useSearchParams("All");
  const {
    value,
    onChangeSearchBar,
    handleActiveInActiveToggle,
    fetchAllLangauges,
    reloadData,
    setReloadData,
    deleteLanguage,
  } = AllLanguagesHooks();

  useEffect(() => {
    if (searchParams.has("filter")) {
      setActiveTab(searchParams.get("filter"));
    }
  }, [searchParams]);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium">Languages</p>
              <div className="flex gap-4">
                <Link to={"/languages/add"}>
                  <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
                    <AiOutlinePlus /> Add New Language
                  </button>
                </Link>
              </div>
            </div>
            <div className="">
              <CustomTable
                searchFor={"Languages"}
                heightDesktop={"h-[72vh]"}
                width={"w-24"}
                // giving extra width to 2nd column
                nthChildWidth={"[&>*:nth-child(2)]:w-40 [&>*:nth-child(4)]:w-40"}
                data={[]}
                headers={allLanguagesHeaders}
                customDataFetch={true}
                customDataFetchFunction={fetchAllLangauges}
                reloadData={reloadData}
                setReloadData={setReloadData}
                reverseTable={true}
                handleToggleClick={(row, isActive) => {
                  const isLanguageMetasSaved =
                    row?.login_meta &&
                    row?.signup_meta &&
                    row?.home_meta &&
                    row?.promises_meta &&
                    row?.testimonies &&
                    row?.curricullum_meta &&
                    row?.my_favourites &&
                    row?.givings &&
                    row?.menus &&
                    row?.my_profile &&
                    row?.my_downloads &&
                    row?.help_n_supports?.[0] &&
                    row?.about_twg;
                  if (isActive && !isLanguageMetasSaved) {
                    Toastiy("Please save all the fields of the Language and try again!!");
                    return false;
                  }
                  handleActiveInActiveToggle(row?._id, isActive ? 1 : 0);
                }}
                handleEyeClick={(row) => {
                  console.log(row, "Calling");
                  navigate("/languages/add", {
                    state: {
                      targetLanguage: row?.language_name + " - " + row?.language_code,
                    },
                  });
                }}
                handleEditClick={(row) => {
                  console.log(row);
                  navigate("/languages/add", {
                    state: {
                      targetLanguage: row?.language_name + " - " + row?.language_code,
                    },
                  });
                }}
                handleDeleteClick={(row) => {
                  console.log(row);
                  const options = {
                    customUI: ({ onClose }) => {
                      return (
                        <ConfirmToast
                          title="Are you sure?"
                          message="You want to delete this language?"
                          onClose={() => onClose()}
                          onSuccess={() => {
                            deleteLanguage(row._id);
                            onClose();
                          }}
                        />
                      );
                    },
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                    keyCodeForClose: [8, 32],
                    overlayClassName: "!bg-gray-500 !bg-opacity-75",
                  };

                  confirmAlert(options);
                }}
                customTabs={
                  <CustomCarousel
                    className="ml-10"
                    rows={1}
                    cols={3}
                    gapX={12}
                    gapY={0}
                    // easier to pass this array like this rather than separating it
                    completeSliders={[
                      <SingleTab activeTab={activeTab} tab={"All"} />,
                      <SingleTab activeTab={activeTab} tab={"Active"} />,
                      <SingleTab activeTab={activeTab} tab={"In-Active"} />,
                    ]}
                    numberOfCardsShownInSingleSlide={5}
                    handleCardOnClick={(card) => {
                      setActiveTab(card.props.tab);
                    }}
                    topNext={"top-3"}
                    topPrev={"top-9"}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllLanguages;
