import React, { useEffect, useRef, useState } from "react";
import { IoCamera } from "react-icons/io5";
import { MdVideoLibrary } from "react-icons/md";
import { LuGalleryVerticalEnd } from "react-icons/lu";
import VideoLibraryModal from "../TWG/VideoLibraryModal";
import WebCamModal from "./WebCamModal";
import { FiCamera } from "react-icons/fi";
import { SiAudiomack } from "react-icons/si";

const UploadVideoButton = ({
  showVideoLibrarySelection = false,
  handleLibraryVideoSelection,
  handleVideoUpload,
  handleVideoCapture,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [showVideoLibraryModal, setShowVideoLibraryModal] = useState(false);
  const [cameraModalOpen, setCameraModalOpen] = useState(false);
  const handleVideoSelection = (details) => {
    if (handleLibraryVideoSelection) handleLibraryVideoSelection(details);
    setShowVideoLibraryModal(false);
  };

  const containerRef = useRef();
  const videoInputRef = useRef();

  useEffect(() => {
    const eventHandler = (e) => {
      if (containerRef?.current && !containerRef?.current?.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  return (
    <>
      <div className="relative">
        <button
          className="px-4 py-2 rounded-xl border border-white/30 text-white"
          onClick={() => setIsActive(true)}
        >
          <FiCamera className="text-xl" />
        </button>
        <input
          ref={videoInputRef}
          className="hidden"
          type="file"
          accept="video/*"
          multiple={true}
          onChange={handleVideoUpload}
        />
        <ul
          className={`${
            isActive ? "flex flex-col gap-1" : "hidden"
          } absolute -top-2 -translate-y-full text-xs transition-all duration-300 z-50`}
          ref={containerRef}
          onClick={() => setIsActive(false)}
        >
          {showVideoLibrarySelection && (
            <li
              onClick={() => setShowVideoLibraryModal(true)}
              className="flex items-center gap-2 bg-yellowish text-blackishV2 px-2.5 py-2 w-fit rounded-md whitespace-nowrap cursor-pointer"
            >
              <MdVideoLibrary className="text-base" />
              Select from library
            </li>
          )}
          <li
            onClick={() => videoInputRef.current.click()}
            className="flex items-center gap-2 bg-yellowish text-blackishV2 px-2.5 py-2 w-fit rounded-md whitespace-nowrap cursor-pointer"
          >
            <LuGalleryVerticalEnd className="text-base" />
            Select from Files
          </li>
          <li
            onClick={() => setCameraModalOpen(true)}
            className="flex items-center gap-2 bg-yellowish text-blackishV2 px-2.5 py-2 w-fit rounded-md whitespace-nowrap cursor-pointer"
          >
            <IoCamera className="text-base" />
            Open Camera
          </li>
        </ul>
      </div>
      {showVideoLibrarySelection && (
        <VideoLibraryModal
          showVideoLibraryModal={showVideoLibraryModal}
          setShowVideoLibraryModal={setShowVideoLibraryModal}
          handleVideoSelection={handleVideoSelection}
        />
      )}
      <WebCamModal
        isOpen={cameraModalOpen}
        onClose={() => {
          setCameraModalOpen(false);
        }}
        handleVideoSave={handleVideoCapture}
      />
    </>
  );
};

export default UploadVideoButton;
