import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCMSUserAction } from "../../../store/Users/UsersAction";
import { useSearchParams } from "react-router-dom";

const UsersHooks = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const [reloadData, setReloadData] = useState(false);

  const fetchAllUsers = (page, limit, searchCriteria = {}, callbackFn) => {
    console.log(
      "page",
      page,
      "limit",
      limit,
      "searchCriteria",
      searchCriteria,
      "callbackFn",
      callbackFn
    );
    dispatch(
      getCMSUserAction({
        apiPayloadRequest: {
          page: page,
          limit: limit,
          searchCriteria: searchCriteria,
        },
        callback: (Data) => {
          if (Data?.type === 1) {
            if (callbackFn)
              callbackFn({
                data: Data?.data?.map((d) => ({ ...d, views: d?.views ?? 0 })),
                total: Data?.totalRecords ?? 0,
              });
          } else if (callbackFn) callbackFn({ data: [], total: Data?.totalRecords ?? 0 });
        },
      })
    );
  };

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar, fetchAllUsers, reloadData, setReloadData };
};

export default UsersHooks;

export const AllUserHooks = (activeTab) => {
  const [reloadData, setReloadData] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const [value, setValue] = useState("");

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  console.log("user hook activeTab", activeTab);

  return {
    value,
    onChangeSearchBar,
    reloadData,
    setReloadData,
    searchCriteria,
    setSearchCriteria,
    currentPage,
    setCurrentPage,
  };
};
