import * as actionTypes from "../ActionTypes";

export const updatedNotificationsAction = (payload) => {
  return {
    type: actionTypes.GET_USERS_FOR_NOTIFICATIONS_REQUEST,
    payload: payload,
  };
};

export const sendNotificationAction = (payload) => {
  return {
    type: actionTypes.SEND_NOTIFICATION_REQUEST,
    payload: payload,
  };
};

export const sendCustomNotificationAction = (payload) => {
  return {
    type: actionTypes.SEND_CUSTOM_NOTIFICATION_REQUEST,
    payload: payload,
  };
};

export const getListOfNotificationsAction = (payload) => {
  return {
    type: actionTypes.GET_LIST_OF_NOTIFICATIONS_REQEST,
    payload: payload,
  };
};

export const getFeedbackAction = (payload) => {
  return {
    type: actionTypes.GET_LIST_OF_FEEDBACK_REQEST,
    payload: payload,
  };
};

export const getPrayerAction = (payload) => {
  return {
    type: actionTypes.GET_LIST_OF_PRAYER_REQEST,
    payload: payload,
  };
};

export const deleteNotificationAction = (payload) => {
  return {
    type: actionTypes.DELETE_NOTIFICATION_REQUEST,
    payload: payload,
  };
};
