import * as actionTypes from "../ActionTypes";

export const getBibleLanguagesAction = (payload) => {
  return {
    type: actionTypes.GET_BIBLE_LANGUAGES_REQUEST,
    payload: payload,
  };
};

export const getBibleVersionsAction = (payload) => {
  return {
    type: actionTypes.GET_BIBLE_VERSIONS_REQUEST,
    payload: payload,
  };
};

export const setBibleLanguage = (payload) => {
  return {
    type: actionTypes.SET_BIBLE_LANGUAGE,
    payload: payload,
  };
};

export const setBibleVersion = (payload) => {
  return {
    type: actionTypes.SET_BIBLE_VERSION,
    payload: payload,
  };
};

export const setVersionDetails = (payload) => {
  return {
    type: actionTypes.SET_VERSION_DETAILS,
    payload: payload,
  };
};

export const setBookChapterDetails = (payload) => {
  return {
    type: actionTypes.SET_BOOK_CHAPTER_DETAILS,
    payload: payload,
  };
};

export const setBookChapterDetailsLoading = (payload) => {
  return {
    type: actionTypes.SET_BOOK_CHAPTER_DETAILS_LOADING,
    payload: payload,
  };
};

export const getVersionBooks = (payload) => {
  return {
    type: actionTypes.GET_VERSION_BOOKS_REQUEST,
    payload: payload,
  };
};

export const getChapterDetails = (payload) => {
  return {
    type: actionTypes.GET_BIBLE_CHAPTER_DETAILS,
    payload: payload,
  };
};

export const getBibleSearch = (payload) => {
  return {
    type: actionTypes.GET_BIBLE_SEARCH,
    payload: payload,
  };
};

export const getChapterVideos = (payload) => {
  return {
    type: actionTypes.READ_CHAPTER_VIDEOS_REQUEST,
    payload: payload,
  };
};

export const saveChapterVideos = (payload) => {
  return {
    type: actionTypes.SAVE_CHAPTER_VIDEOS_REQUEST,
    payload: payload,
  };
};

export const deleteChapterVideos = (payload) => {
  return {
    type: actionTypes.DELETE_CHAPTER_VIDEOS_REQUEST,
    payload: payload,
  };
};

export const getChapterJournals = (payload) => {
  return {
    type: actionTypes.READ_CHAPTER_JOURNALS_REQUEST,
    payload: payload,
  };
};

export const saveChapterJournals = (payload) => {
  return {
    type: actionTypes.SAVE_CHAPTER_JOURNALS_REQUEST,
    payload: payload,
  };
};

export const deleteChapterJournals = (payload) => {
  return {
    type: actionTypes.DELETE_CHAPTER_JOURNALS_REQUEST,
    payload: payload,
  };
};

export const getVerseDetails = (payload) => {
  return {
    type: actionTypes.READ_VERSE_DETAILS_REQUEST,
    payload: payload,
  };
};
