import React, { useEffect, useState } from "react";
import { AllGivingsHooks } from "./hooks/GivingsHooks";
import SearchBar from "../../components/universal/SearchBar";
import { allGivingsData, allGivingsHeaders } from "./Constants";
import CustomTable from "../../components/universal/CustomTable";
import { HiOutlineFilter } from "react-icons/hi";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import tIcon from "../../assets/icons/transactionIcon.svg";
import CustomTabsV2 from "../../components/universal/CustomTabsV2";
import CustomLineChart from "../../components/charts/lineChart/CustomLineChart";
import CustomCarousel from "../../components/universal/CustomCarousel";
import SingleTab from "../../components/universal/SingleTab";
import Navbar from "../../components/universal/Navbar";
import { useDispatch } from "react-redux";
import { getGivingsListAction } from "../../store/Givings/GivingsAction";
function AllGivings() {
  // search bar component state with handler
  const {
    value,
    onChangeSearchBar,
    openGivingHandler,
    modalIsOpen,
    setModalIsOpen,
    transactionInfo,
    handleRefund,
  } = AllGivingsHooks();

  const dispatch = useDispatch();

  const [givingsData, setGivingsData] = useState([]);

  useEffect(() => {
    dispatch(
      getGivingsListAction({
        callback: (res) => {
          if (res?.type === 1) {
            setGivingsData(res?.data);
          }
        },
      })
    );
  }, []);

  const [userTab, setUserTab] = useState("Month");
  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar
            placeholder={"Search"}
            value={value}
            onChangeSearchBar={onChangeSearchBar}
          />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium">Givings</p>
              <div className="flex gap-4">
                {/* <button className="flex items-center gap-16 text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                  All
                  <HiOutlineFilter />
                </button> */}

                {/* <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
              <AiOutlinePlus /> Add New Category
            </button> */}
              </div>
            </div>
            <div className="grid grid-cols-3 grid-flow-row gap-x-4">
              <div className=" col-span-2">
                <CustomTable
                  searchFor={"Givings"}
                  openGivingHandler={(row) => openGivingHandler(row)}
                  heightDesktop={"h-[72vh]"}
                  width={"w-24"}
                  // giving extra width to 2nd column
                  nthChildWidth={
                    "[&>*:nth-child(2)]:w-40 [&>*:nth-child(5)]:w-32"
                  }
                  nthChildStyle={"text-white text-opacity-50"}
                  data={givingsData}
                  headers={allGivingsHeaders}
                  reverseTable={true}
                  handleToggleClick={(row, isActive) => {
                    console.log(isActive, row);
                  }}
                  handleEyeClick={(row) => {
                    console.log(row);
                  }}
                  handleEditClick={(row) => {
                    console.log(row);
                  }}
                />
              </div>
              <div className="col-span-1 bg-blackishV2 rounded-2xl">
                <CustomTabsV2
                  tabHeight={"h-[60px]"}
                  tabsTitleArray={["Stats"]}
                  tabsContentObject={{
                    Stats: (
                      <div className="p-4">
                        <div className="p-1 flex justify-between items-center w-full">
                          <div className="flex items-center gap-8">
                            <p className="font-semibold text-white">Givings</p>
                            {/* <p className="text-gray-400">Hindi</p> */}
                          </div>
                          <div className="md:w-[15vw] w-full">
                            <div className="md:block hidden">
                              <CustomCarousel
                                rows={1}
                                cols={3}
                                gapX={0}
                                gapY={0}
                                // easier to pass this array like this rather than separating it
                                completeSliders={[
                                  <SingleTab
                                    activeTab={userTab}
                                    tab={"Week"}
                                  />,
                                  <SingleTab
                                    activeTab={userTab}
                                    tab={"Month"}
                                  />,
                                  <SingleTab
                                    activeTab={userTab}
                                    tab={"Year"}
                                  />,
                                ]}
                                numberOfCardsShownInSingleSlide={3}
                                handleCardOnClick={(card) => {
                                  setUserTab(card.props.tab);
                                  switch (card.props.tab) {
                                    case "Month":
                                      // change data for trending tab
                                      break;
                                    // add other tabs here
                                    default:
                                      break;
                                  }
                                }}
                                topNext={"hidden"}
                                topPrev={"hidden"}
                              />
                            </div>
                            <div className="md:hidden block">
                              <CustomCarousel
                                rows={1}
                                cols={2}
                                gapX={0}
                                gapY={0}
                                // easier to pass this array like this rather than separating it
                                completeSliders={[
                                  <SingleTab
                                    activeTab={userTab}
                                    tab={"Week"}
                                  />,
                                  <SingleTab
                                    activeTab={userTab}
                                    tab={"Month"}
                                  />,
                                  <SingleTab
                                    activeTab={userTab}
                                    tab={"Year"}
                                  />,
                                ]}
                                numberOfCardsShownInSingleSlide={2}
                                handleCardOnClick={(card) => {
                                  setUserTab(card.props.tab);
                                  switch (card.props.tab) {
                                    case "Month":
                                      // change data for trending tab
                                      break;
                                    // add other tabs here
                                    default:
                                      break;
                                  }
                                }}
                                topNext={"hidden"}
                                topPrev={"hidden"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="block">
                          <CustomLineChart
                            customHeight="300"
                            customWidth="450"
                            data={[
                              100, 150, 180, 220, 300, 280, 320, 400, 380, 450,
                              500, 520,
                            ]}
                          />
                        </div>
                        <div className="p-2">
                          <p className="p-2 uppercase text-white text-opacity-50 text-sm">
                            top 5 givings by countries
                          </p>
                          <div className="p-2 flex justify-between">
                            <p className="text-white text-opacity-50 text-sm">
                              Australia
                            </p>
                            <p className="text-white text-sm">2,141k</p>
                          </div>
                          <div className="p-2 flex justify-between">
                            <p className="text-white text-opacity-50 text-sm">
                              India
                            </p>
                            <p className="text-white text-sm">1,289k</p>
                          </div>
                          <div className="p-2 flex justify-between">
                            <p className="text-white text-opacity-50 text-sm">
                              Uk
                            </p>
                            <p className="text-white text-sm">11,289k</p>
                          </div>
                          <div className="p-2 flex justify-between">
                            <p className="text-white text-opacity-50 text-sm">
                              Germany
                            </p>
                            <p className="text-white text-sm">10,289k</p>
                          </div>
                          <div className="p-2 flex justify-between">
                            <p className="text-white text-opacity-50 text-sm">
                              Canada
                            </p>
                            <p className="text-white text-sm">788k</p>
                          </div>
                        </div>
                      </div>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="Custom Modal"
            className="custom-modal absolute z-[60] bg-black bg-opacity-70 h-screen w-screen"
            ariaHideApp={false}
          >
            <>
              <div className="MODAL-BODY h-full flex items-center justify-center">
                <div className="min-h-[64vh] h-fit bg-blackish w-[28vw] flex flex-col rounded-2xl p-4">
                  <div className="h-fit flex justify-between items-center">
                    <p className="text-lg w-full font-semibold text-white mb-0">
                      Transaction Details
                    </p>
                    <button
                      className="w-full text-right"
                      onClick={() => setModalIsOpen(false)}
                    >
                      <AiOutlineClose className="ml-auto text-white" />
                    </button>
                  </div>
                  <div className="flex-1 pt-8">
                    <div className="w-full flex justify-center">
                      <img src={tIcon} className="w-full h-28" alt="" />
                    </div>
                    <p className="text-white w-full text-center text-[15px] text-medium">
                      Successful!!
                    </p>
                    <div className="flex flex-col items-center gap-4 pt-8">
                      <div className="w-full justify-between items-center flex">
                        <p className="text-greyish text-[12px]">
                          Transaction ID
                        </p>
                        <p className="text-white text-[12px] font-medium">
                          {transactionInfo?.transactionId}
                        </p>
                      </div>
                      <div className="w-full justify-between items-center flex">
                        <p className="text-greyish text-[12px]">Date & Time</p>
                        <p className="text-white text-[12px] font-medium">
                          {transactionInfo?.date}
                        </p>
                      </div>
                      <div className="w-full justify-between items-center flex">
                        <p className="text-greyish text-[12px]">Country</p>
                        <p className="text-white text-[12px] font-medium">
                          {transactionInfo?.country}
                        </p>
                      </div>
                      <div className="w-full justify-between items-center flex">
                        <p className="text-greyish text-[12px]">Payment Type</p>
                        <p className="text-white text-[12px] font-medium">
                          {transactionInfo?.paymentType}
                        </p>
                      </div>
                      <div className="w-full justify-between items-center flex">
                        <p className="text-greyish text-[12px]">
                          Payment Source
                        </p>
                        <p className="text-white text-[12px] font-medium">
                          {transactionInfo?.MOP}
                        </p>
                      </div>
                      <div className="w-full justify-between items-center flex">
                        <p className="text-greyish text-[12px]">Amount</p>
                        <p className="text-white text-[12px] font-medium">
                          {transactionInfo?.amount}
                        </p>
                      </div>
                      <button
                        onClick={() => handleRefund(transactionInfo)}
                        className="mt-4 mb-4 flex items-center gap-2 text-[13px] text-yellowish border px-8 py-2 border-yellowish bg-blackish rounded-xl"
                      >
                        Refund Payment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AllGivings;
