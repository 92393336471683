import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LoaderSpinner from "../../components/universal/LoaderSpinner";

import {
  getChapterDetails,
  setBookChapterDetails,
  setBookChapterDetailsLoading,
} from "../../store/Bible/BibleAction";
import { IoIosArrowBack } from "react-icons/io";

const ChapterDetails = ({
  chapterNo,
  setChapterNo,
  bookId,
  className = "",
  versionDetails = {},
}) => {
  const dispatch = useDispatch();

  const { bookChapterDetails, bookChapterDetailsLoading } = useSelector((state) => state.bible);

  const fetchChapterDetails = () => {
    dispatch(setBookChapterDetailsLoading(true));
    const bookDetails = versionDetails?.books?.find((book) => book?.book_id === bookId);
    const filesets = [
      ...(versionDetails?.filesets?.["dbp-prod"]?.filter(
        (d) => d?.size?.includes(bookDetails?.testament) || d?.size === "C"
      ) ?? []),
      ...(versionDetails?.filesets?.["dbp-vid"]?.filter(
        (d) => d?.size?.includes(bookDetails?.testament) || d?.size === "C"
      ) ?? []),
    ];
    dispatch(
      getChapterDetails({
        apiPayloadRequest: {
          filesetid: filesets?.map((d) => d?.id)?.join(","),
          bookid: bookId,
          chapterid: chapterNo,
        },
        callback: (res) => {
          dispatch(
            setBookChapterDetails(
              res?.data?.map((d, i) => ({ details: d, filesetDetail: filesets?.[i] }))
            )
          );
          dispatch(setBookChapterDetailsLoading(false));
        },
      })
    );
  };

  useEffect(() => {
    if (versionDetails?.abbr && chapterNo && bookId) {
      fetchChapterDetails();
    }
  }, [versionDetails?.abbr, bookId, chapterNo]);

  const bookName = versionDetails?.books?.find((d) => d?.book_id === bookId)?.name ?? "";
  const versesList =
    bookChapterDetails?.find(
      (d) => d?.filesetDetail?.type === "text_plain" || d?.filesetDetail?.type === "text_format"
    )?.details ?? [];

  return (
    <>
      {bookChapterDetailsLoading ? (
        <div className="w-full flex flex-col gap-3 text-white items-center justify-center h-[60vh]">
          <LoaderSpinner />
          <p>Loading Chapter...</p>
        </div>
      ) : (
        <div className={`w-full pt-6 flex flex-col gap-5 px-20 ${className}`}>
          <h5 className="text-yellowish text-2xl font-black flex items-center gap-2">
            <IoIosArrowBack className="cursor-pointer" onClick={() => setChapterNo(null)} />
            <span>
              {bookName} {chapterNo}
            </span>
          </h5>
          <div className="">
            <ul className="text-greyish">
              {versesList?.map((item, index) => {
                return (
                  <li
                    key={`version-item${item?.verse_start}`}
                    className="w-fit flex items-start gap-2 my-2"
                  >
                    <span>{item?.verse_start}</span>
                    <div key={index}>{item?.verse_text}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default ChapterDetails;
