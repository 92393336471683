import { useState } from "react";
import SearchBar from "../../components/universal/SearchBar";
import { AllTestimonialsHooks } from "./hooks/TestimonialsHooks";
import CustomCarousel from "../../components/universal/CustomCarousel";
import SingleTab from "../../components/universal/SingleTab";
import CustomTable from "../../components/universal/CustomTable";
import { TestimonialsHeaders } from "./Constants";
import CustomTabsV2 from "../../components/universal/CustomTabsV2";
import avatar from "../../assets/icons/avatar.svg";
import close from "../../assets/icons/close.svg";
import Navbar from "../../components/universal/Navbar";
import { useDispatch } from "react-redux";
import {
  accetTestimonyAction,
  testimonyOfTheDayAction,
} from "../../store/Testimony/TestimonyAction";
import ReactPlayer from "react-player";
import Toastiy from "../../components/universal/Toastiy";
import ReactModal from "react-modal";
// import DateTimePicker from "react-datetime-picker";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import ConfirmToast from "../../components/universal/ConfirmToast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineCalendarToday } from "react-icons/md";

function AllTestimonials() {
  const dispatch = useDispatch();

  // search bar component state with handler
  const {
    value,
    onChangeSearchBar,
    reloadData,
    setReloadData,
    fetchAllTestimonials,
    selectedView,
    setSelectedView,
    showPreview,
    setShowPreview,
    activeTab,
    setActiveTab,
    setSelectedLanguage,
    rawLanguagesList,
    handleSendNotification,
  } = AllTestimonialsHooks();

  const [showModal, setShowModal] = useState(false);
  const [scheduleDate, setScheduleDate] = useState("");
  const [currentId, setCurrentId] = useState("");
  const [notifyAll, setNotifyAll] = useState(true);

  // useEffect(() => {
  //   if (location?.state?._id && Object.keys(testimonyData)?.length > 0) {
  //     for (let key in testimonyData) {
  //       const find = testimonyData?.[key]?.find((d) => d?._id === location?.state?._id);
  //       if (find) {
  //         setSelectedView(find);
  //         setShowPreview(true);
  //         break;
  //       }
  //     }
  //   }
  // }, [location?.state?._id, testimonyData, activeTab]);

  const handleAccept = (id, status) => {
    dispatch(
      accetTestimonyAction({
        apiPayloadRequest: {
          _id: id,
          approval_status: status,
        },
        callback: (res) => {
          if (res?.type === 1) {
            if (status === 1) {
              Toastiy("Testimony Approved!", 1);
              setReloadData(true);
            } else {
              Toastiy("Testimony Rejected!", 1);
              setReloadData(true);
            }
            if (notifyAll && status === 1) {
              handleSendNotification(id);
            }
          }
        },
      })
    );
  };

  const handleActiveInActiveToggle = (id, status) => {};

  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const makeTestimonyOfTheDay = () => {
    dispatch(
      testimonyOfTheDayAction({
        apiPayloadRequest: {
          _id: currentId,
          testimony_date: scheduleDate,
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Testimony Scheduled Successfully!", 1);
            handleToggleModal();
            setScheduleDate("");
          } else {
            Toastiy("Failed To Schedule Testimony!");
          }
        },
      })
    );
  };

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium">Testimonials</p>
            </div>

            <div className="w-full flex-1 grid grid-cols-6 grid-rows-1 rounded-2xl overflow-hidden">
              <div className=" col-span-4 bg-[#1F1F1F] p-4 flex flex-col gap-4">
                <div className="HEADING flex items-center justify-between">
                  <p className="text-lg font-semibold text-white">Testimonials List</p>
                  <select
                    className="focus:outline-none  bg-blackish p-2 text-white text-[14px] font-medium leading-4 rounded-xl h-11 border border-greyishV2"
                    onChange={(e) => {
                      setSelectedLanguage((prev) => e.target.value);
                    }}
                  >
                    <option value="">All</option>
                    {Object.keys(rawLanguagesList ?? {})?.map((language) => (
                      <option value={language}>{rawLanguagesList[language]}</option>
                    ))}
                  </select>
                  <div>
                    <CustomCarousel
                      freeFlow={true}
                      gapX={12}
                      gapY={12}
                      topNext={"hidden"}
                      topPrev={"hidden"}
                      numberOfCardsShownInSingleSlide={4}
                      completeSliders={[
                        <SingleTab activeTab={activeTab} tab={"All"} />,
                        <SingleTab activeTab={activeTab} tab={"New"} />,
                        <SingleTab activeTab={activeTab} tab={"Approved"} />,
                        <SingleTab activeTab={activeTab} tab={"Rejected"} />,
                      ]}
                      handleCardOnClick={(card) => {
                        setActiveTab((prev) => card.props.tab);
                      }}
                    />
                  </div>
                </div>

                <CustomTable
                  searchFor={"Notifications"}
                  data={[]}
                  activeTab={activeTab}
                  headers={TestimonialsHeaders}
                  customDataFetch={true}
                  customDataFetchFunction={fetchAllTestimonials}
                  reloadData={reloadData}
                  setReloadData={setReloadData}
                  testimonialTable={true}
                  width={"w-24"}
                  heightDesktop={"h-[60vh]"}
                  // giving extra width to 2nd column
                  nthChildWidth={"[&>*:nth-child(2)]:w-48"}
                  nthChildStyle={"[&>*:nth-child(2)]:h-5 [&>*:nth-child(2)]:overflow-hidden"}
                  handleToggleClick={(row, isActive) => {
                    handleActiveInActiveToggle(row?._id, isActive ? 1 : 0);
                  }}
                  handleEyeClick={(row) => {
                    console.log(row);
                    setSelectedView(row);
                    setShowPreview(true);
                  }}
                  // handleEditClick={(row) => {
                  //   console.log(row);
                  // }}
                  // handleGreenTick={(row) => {
                  //   handleAccept(row?._id, 1);
                  // }}
                  // handleRedCross={(row) => {
                  //   const options = {
                  //     customUI: ({ onClose }) => {
                  //       return (
                  //         <ConfirmToast
                  //           title="Are you sure?"
                  //           message="You want to reject this testimony?"
                  //           onClose={() => onClose()}
                  //           onSuccess={() => {
                  //             handleAccept(row?._id, 2);
                  //             onClose();
                  //           }}
                  //         />
                  //       );
                  //     },
                  //     closeOnEscape: true,
                  //     closeOnClickOutside: true,
                  //     keyCodeForClose: [8, 32],
                  //     overlayClassName: "!bg-gray-500 !bg-opacity-75",
                  //   };

                  //   confirmAlert(options);
                  // }}
                />
              </div>
              <div className=" md:col-span-2 col-span-1">
                <CustomTabsV2
                  tabHeight={"h-[64px]"}
                  tabsTitleArray={["Preview", "Stats"]}
                  tabsContentObject={{
                    Preview: (
                      <>
                        {showPreview && (
                          <div className="flex items-center justify-center w-full h-full flex-col gap-4">
                            <div className="h-[60vh] overflow-auto w-full flex flex-col gap-6 py-4">
                              <div className="flex justify-between items-center w-full">
                                <div className="flex gap-2 items-center px-[2%]">
                                  <img
                                    src={
                                      selectedView?.user[0]?.profilePicUrl
                                        ? selectedView?.user[0]?.profilePicUrl
                                        : avatar
                                    }
                                    className="h-8 w-8 rounded-full object-cover object-top"
                                    alt=""
                                  />
                                  <p className="text-sm text-white font-medium">
                                    User: {selectedView?.user[0]?.firstName}
                                  </p>
                                </div>
                                {/* <p className="text-greyish text-[12px] font-medium">
                                  View User Details
                                </p> */}
                              </div>
                              <div className="px-[2%]">
                                <p className="text-white text-lg">
                                  {selectedView?.testimonial_name}
                                </p>
                                <p className="text-white opacity-60 mt-2">
                                  {" "}
                                  {selectedView?.testimonial_description}
                                </p>
                              </div>
                              <div className="relative flex justify-center items-center p-3">
                                <ReactPlayer
                                  url={selectedView?.testomonial_rec_videoUrl}
                                  height={"500px"}
                                  width={"100%"}
                                  controls={true}
                                />
                              </div>
                            </div>
                            <div className="flex justify-between items-center gap-2 w-full mb-[10%] p-2">
                              <div className="flex items-center gap-2">
                                <label className="text-yellowish whitespace-nowrap">
                                  Notify All
                                </label>
                                <input
                                  type="checkbox"
                                  checked={notifyAll}
                                  onChange={(e) => setNotifyAll(e.target.checked)}
                                />
                              </div>
                              {selectedView?.approval_status === 1 && (
                                <button
                                  onClick={() => {
                                    handleToggleModal();
                                    setCurrentId(selectedView?._id);
                                  }}
                                  className="text-blackish bg-yellow-500 px-4 py-2 rounded-md text-[10px] font-semibold w-2/4"
                                >
                                  Make Testimony Of The Day
                                </button>
                              )}
                              {selectedView?.approval_status === 1 ? (
                                <div className="flex justify-end items-center gap-2 w-full">
                                  <button
                                    onClick={() => handleAccept(selectedView?._id, 2)}
                                    className="text-white bg-[#EF6D51] px-4 py-2 rounded-md text-[13px] font-semibold"
                                  >
                                    Reject
                                  </button>
                                </div>
                              ) : selectedView?.approval_status === 2 ? (
                                <div className="flex justify-end items-center gap-2 w-full">
                                  <button
                                    onClick={() => handleAccept(selectedView?._id, 1)}
                                    className="text-blackish bg-[#7FBA7A] px-4 py-2 rounded-md text-[13px] font-semibold"
                                  >
                                    Accept
                                  </button>
                                </div>
                              ) : (
                                <div className="flex justify-end items-center gap-2 w-full">
                                  <button
                                    onClick={() => handleAccept(selectedView?._id, 1)}
                                    className="text-blackish bg-[#7FBA7A] px-4 py-2 rounded-md text-[13px] font-semibold"
                                  >
                                    Accept
                                  </button>
                                  <button
                                    onClick={() => handleAccept(selectedView?._id, 2)}
                                    className="text-white bg-[#EF6D51] px-4 py-2 rounded-md text-[13px] font-semibold"
                                  >
                                    Reject
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ),
                    Stats: (
                      <div className="mt-8 flex items-center justify-center w-full text-white">
                        No Data Found...
                      </div>
                    ),
                  }}
                />
                <ReactModal
                  isOpen={showModal}
                  onRequestClose={handleToggleModal}
                  className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
                >
                  <div className="relative bg-black text-white flex flex-col justify-between h-fit w-[25%] rounded-xl p-6">
                    <img
                      src={close}
                      className="absolute top-3 right-3 cursor-pointer"
                      onClick={handleToggleModal}
                      alt="toggle"
                    />
                    <div className="w-full flex flex-col mt-2">
                      <label className="text-[12px] text-greyish font-medium leading-4">
                        Schedule Date
                      </label>
                      {/* <DateTimePicker
                        className={
                          "bg-greyishV2 stroke-white p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md"
                        }
                        value={scheduleDate}
                        onChange={setScheduleDate}
                      /> */}
                      <DatePicker
                        selected={scheduleDate}
                        onChange={setScheduleDate}
                        isClearable
                        showIcon={true}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        dropdownMode="select"
                        toggleCalendarOnIconClick={true}
                        placeholderText="MM/DD/YYYY"
                        icon={<MdOutlineCalendarToday className="mr-2 cursor-pointer" />}
                        wrapperClassName="mt-2 custom-datepicker"
                        className="bg-greyishV2 stroke-white !p-3 !pl-8 w-full text-white text-[14px] font-medium leading-4 rounded-md"
                      />
                    </div>
                    <div className="flex w-full justify-end">
                      <button
                        onClick={makeTestimonyOfTheDay}
                        className="text-blackish bg-yellow-500 px-6 py-2 rounded-md text-[13px] font-semibold"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </ReactModal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllTestimonials;
