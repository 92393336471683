import * as actionTypes from "../ActionTypes";

export const getRolesAction = (payload) => {
  return {
    type: actionTypes.GET_ROLES_REQUEST,
    payload: payload,
  };
};

export const saveCMSUserAction = (payload) => {
  return {
    type: actionTypes.SAVE_CMS_USER_REQUEST,
    payload: payload,
  };
};

export const getCMSUserAction = (payload) => {
  return {
    type: actionTypes.GET_CMS_USER_REQUEST,
    payload: payload,
  };
};

export const updateCMSUserAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CMS_USER_REQUEST,
    payload: payload,
  };
};

export const updateUserProfileInfoAction = (payload) => {
  return {
    type: actionTypes.UPDATE_USER_PROFILE_INFO,
    payload: payload,
  };
};

export const setUserAccessTokenAction = (payload) => {
  return {
    type: actionTypes.SET_ACCESS_TOKEN,
    payload: payload,
  };
};
