import { useState } from "react";
import reload from "../../assets/icons/reload.svg";
import CustomTabsV2 from "../universal/CustomTabsV2";
import close from "../../assets/icons/close.svg";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import ReactModal from "react-modal";
import { ImCross } from "react-icons/im";
// eslint-disable-next-line no-unused-vars
import LanguageDrawer from "../universal/LanguageDrawer";
import { BsPlus } from "react-icons/bs";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { FaInfoCircle } from "react-icons/fa";
import UploadControlsV2 from "../TWG/UploadControlsV2";
import { JournalsHookNew } from "../../screens/Journals/hooks/JournalsHookNew";
import { FaCheck } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";
import UploadVideoButton from "./UploadVideoButton";
import UploadImageButton from "./UploadImageButton";
import UploadAudioButton from "./UploadAudioButton";
import { RiDeleteBin5Line } from "react-icons/ri";
import MyStatefulEditor from "../universal/MyStatefulEditor";
import AudioCard from "./AudioCard";
import NotePreviewCard from "./NotePreviewCard";
import LoaderSpinner from "../universal/LoaderSpinner";
import { JOURNAL_NOTE_ITEM_TYPES } from "../../constants/Common";
import { MdDelete, MdDeleteOutline } from "react-icons/md";
import { FiPlusCircle } from "react-icons/fi";

const AddJournalFields = ({ setLoading }) => {
  const location = useLocation();
  const [journalId, setJournalId] = useState(location?.state?._id);

  const {
    journalValues,
    handleChange,
    addNewNotes,
    currNotesIndex,
    currNotes,
    setCurrNotes,
    cancelNotes,
    saveNotes,
    setShowNotesBox,
    showNotesBox,
    setCurrNotesIndex,
    setIsNotesEdit,
    initialNotesState,
    cancelJournal,
    saveJournals,
    saveJournalsLanguage,
    journalsErrors,
    showJournalsErrors,
    setShowJournalsErrors,
    activeTab,
    updateJournalsWithTranslation,
    updateNotesWithTranslation,
    setShowJournalBox,
    showJournalBox,
    handleDeleteNotes,
    activeLanguageDetails,
    journalStats,
    forceEditorUpdate,
    handleAddNotesAsset,
    handlePublishJournal,
    journalPublishLoading,
    deleteNoteItem,
    handleAddNoteText,
  } = JournalsHookNew(setLoading, journalId, setJournalId);

  console.log("journalValues", journalValues);
  console.log("journalsErrors", journalsErrors);
  console.log("forceEditorUpdate", forceEditorUpdate);
  console.log("currNotes", currNotes);

  const [showModal, setShowModal] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  return (
    <div className="flex h-full w-full flex-1 rounded-2xl overflow-hidden">
      <div className="flex h-full flex-1">
        {/* <LanguageDrawer
          languageData={readLanguageData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          languagemeta={languagemeta}
          setIsJournalsSave={setIsJournalsSave}
          setActiveLanguageDetails={setActiveLanguageDetails}
        /> */}
        <div className="flex h-full flex-1">
          <div className="flex flex-col h-full w-full row-span-1 bg-greyishV2 bg-opacity-50 md:border-l md:border-r border-greyish border-opacity-30">
            <div className="flex items-center justify-end py-3">
              {activeTab === "en" && !journalValues?.journal_title && (
                <button
                  className="inline-flex items-center text-yellowish bg-blackishV2 font-medium text-[12px] border border-yellowish rounded-xl py-3 px-6 mx-1.5"
                  onClick={() => {
                    setShowJournalBox(true);
                  }}
                >
                  <BsPlus className="text-lg" /> Add Journal Details
                </button>
              )}

              {!showNotesBox && activeTab === "en" && journalId && (
                <button
                  className="inline-flex items-center text-yellowish bg-blackishV2 font-medium text-[12px] border border-yellowish rounded-xl py-3 px-6 mx-1.5"
                  onClick={() => {
                    setCurrNotesIndex(journalValues?.notes?.length ?? 0);
                    setShowNotesBox((prev) => !prev);
                    addNewNotes();
                    setCurrNotes(initialNotesState);
                    setIsNotesEdit(false);
                  }}
                >
                  <BsPlus className="text-lg" /> Add Notes
                </button>
              )}
              {journalId && (
                <button
                  className={`w-fit text-black font-medium text-[12px] border mx-1.5  py-3 px-12 rounded-xl ${
                    journalValues?.publish !== "true" && journalId
                      ? "bg-yellowish border-yellowish hover:border-yellowish hover:bg-yellowish"
                      : "bg-yellowish/40 border-yellowish/40"
                  }`}
                  {...(journalValues?.publish !== "true" && journalId
                    ? {}
                    : {
                        "data-tooltip-id": "publish_journal",
                        "data-tooltip-content": `${
                          journalValues?.publish === "true" ? "Update" : "Complete"
                        } Journal to publish`,
                        disabled: true,
                      })}
                  onClick={() => {
                    if (
                      journalValues?.journal_title &&
                      journalValues?.journal_description &&
                      journalValues?.bg_Images?.[0]?.url_link &&
                      journalValues?.bg_Images?.[1]?.url_link &&
                      journalValues?.bg_Images?.[2]?.url_link &&
                      journalValues?.banner_Images?.[0]?.url_link &&
                      journalValues?.banner_Images?.[1]?.url_link &&
                      journalValues?.banner_Images?.[2]?.url_link
                    ) {
                      handlePublishJournal();
                    }
                  }}
                >
                  {journalPublishLoading ? (
                    <LoaderSpinner className="!text-black" />
                  ) : !journalValues?.publish ? (
                    "Publish Journal"
                  ) : (
                    "Update Publish"
                  )}
                </button>
              )}

              <Tooltip id="publish_journal" className="border border-greyish border-opacity-20" />
            </div>
            {activeTab !== "en" && !journalId ? (
              <div className="flex items-center justify-center text-white gap-1 py-28">
                Journals translation in{" "}
                <span className="italic">{activeLanguageDetails?.language_name}</span> is available
                for saved journals
              </div>
            ) : (
              <div className="pl-12 pr-4 pt-8 flex flex-col overflow-auto">
                <div className="flex-1 flex flex-col gap-6 relative pb-8">
                  {journalValues?.journal_title && (
                    <div className="flex flex-col gap-2 relative">
                      <p className="text-2xl font-white font-medium text-white flex items-center">
                        {journalValues?.journal_title}{" "}
                        <button
                          className="text-yellow-500 text-[10px] h-fit ml-4 px-3 border border-yellow-500 rounded-md"
                          onClick={() => {
                            setShowJournalBox(true);
                          }}
                        >
                          Edit
                        </button>
                      </p>
                      <div className="text-greyish font-medium ">
                        {journalValues?.journal_description}
                        <div className="bg-yellowish h-5 w-5 absolute -left-8 top-0 rounded-full"></div>
                      </div>

                      <div className="grid grid-cols-3 gap-2 justify-between">
                        {journalValues?.bg_Images?.[0]?.url_link && (
                          <div className="flex flex-col gap-0.5">
                            <img
                              className="aspect-[16/9] object-cover object-top rounded-md"
                              src={journalValues?.bg_Images?.[0]?.url_link}
                              alt=""
                            />
                            <p className="text-white/50">Mobile Preview</p>
                          </div>
                        )}
                        {journalValues?.bg_Images?.[1]?.url_link && (
                          <div className="flex flex-col gap-0.5">
                            <img
                              className="aspect-[16/9] object-cover object-top rounded-md"
                              src={journalValues?.bg_Images?.[1]?.url_link}
                              alt=""
                            />
                            <p className="text-white/50">Web Preview</p>
                          </div>
                        )}
                        {journalValues?.bg_Images?.[2]?.url_link && (
                          <div className="flex flex-col gap-0.5">
                            <img
                              className="aspect-[16/9] object-cover object-top rounded-md"
                              src={journalValues?.bg_Images?.[2]?.url_link}
                              alt=""
                            />
                            <p className="text-white/50">Tablet Preview</p>
                          </div>
                        )}
                      </div>
                      <div className="grid grid-cols-3 gap-2 justify-between">
                        {journalValues?.banner_Images?.[0]?.url_link && (
                          <div className="flex flex-col gap-0.5">
                            <img
                              className="aspect-[16/9] object-cover object-top rounded-md"
                              src={journalValues?.banner_Images?.[0]?.url_link}
                              alt=""
                            />
                            <p className="text-white/50">Mobile Preview</p>
                          </div>
                        )}
                        {journalValues?.banner_Images?.[1]?.url_link && (
                          <div className="flex flex-col gap-0.5">
                            <img
                              className="aspect-[16/9] object-cover object-top rounded-md"
                              src={journalValues?.banner_Images?.[1]?.url_link}
                              alt=""
                            />
                            <p className="text-white/50">Web Preview</p>
                          </div>
                        )}
                        {journalValues?.banner_Images?.[2]?.url_link && (
                          <div className="flex flex-col gap-0.5">
                            <img
                              className="aspect-[16/9] object-cover object-top rounded-md"
                              src={journalValues?.banner_Images?.[2]?.url_link}
                              alt=""
                            />
                            <p className="text-white/50">Tablet Preview</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {journalValues?.notes?.map((note, index) => (
                    <NotePreviewCard
                      note={note}
                      key={index}
                      journalValues={journalValues}
                      index={index}
                      setCurrNotesIndex={setCurrNotesIndex}
                      setIsNotesEdit={setIsNotesEdit}
                      setCurrNotes={setCurrNotes}
                      setShowNotesBox={setShowNotesBox}
                      handleDeleteNotes={handleDeleteNotes}
                    />
                  ))}

                  {(journalValues?.curriculum_name || journalValues?.curriculum_description) && (
                    <div className="h-full w-[1px] border border-dotted border-yellowish border-opacity-40 absolute top-0 -left-6"></div>
                  )}
                </div>
                <Modal
                  isOpen={showJournalBox}
                  onRequestClose={cancelJournal}
                  contentLabel="Custom Modal"
                  className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
                  ariaHideApp={false}
                >
                  <div className="MODAL-BODY h-fit max-h-[85vh] overflow-y-auto md:w-[50%] w-[90%] md:absolute rounded-2xl p-4 bg-blackishV2">
                    <div className="w-full h-full flex flex-col">
                      <div className="flex items-center justify-between mb-2">
                        <p className="text-white text-lg font-semibold">Edit Journal</p>
                        <div className="flex">
                          {activeTab !== "en" && (
                            <button
                              className="flex items-center gap-1 text-greyish text-[11px] font-medium mr-3"
                              onClick={updateJournalsWithTranslation}
                            >
                              <span>
                                {" "}
                                <img src={reload} alt="" />{" "}
                              </span>
                              Auto fill data with google translation
                            </button>
                          )}
                          <div className="flex space-x-2">
                            <img
                              className="cursor-pointer"
                              onClick={cancelJournal}
                              src={close}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col flex-1 w-full row-span-1 bg-greyishV2 bg-opacity-80 md:border-l md:border-r border-greyish border-opacity-30 overflow-y-auto">
                        {/* TAB CONTENT  */}

                        <div className="overflow-y-auto overflow-x-hidden p-4">
                          <div className="grid md:grid-cols-2 grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 mx-1 w-full">
                            <div className="md:col-span-2 col-span-1">
                              <label
                                htmlFor="categoryName"
                                className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                              >
                                Journal Title *
                                <FaInfoCircle
                                  data-tooltip-id="add_journal"
                                  data-tooltip-content="Enter the Journal Title"
                                />
                              </label>
                              <input
                                name="journal_title"
                                type="text"
                                className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 placeholder:text-greyish `}
                                value={journalValues?.journal_title}
                                placeholder="Enter Journal Title"
                                onChange={(e) => handleChange(e, "journal")}
                              />
                              {showJournalsErrors && journalsErrors?.journal_title && (
                                <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
                                  Journal Title Is Required Field
                                </p>
                              )}
                            </div>
                            <div className="md:col-span-2 col-span-1">
                              <label
                                htmlFor="journal"
                                className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                              >
                                Journal Description *
                                <FaInfoCircle
                                  data-tooltip-id="add_journal"
                                  data-tooltip-content="Enter the Journal Description"
                                />
                              </label>
                              <input
                                name="journal_description"
                                type="text"
                                className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 placeholder:text-greyish `}
                                value={journalValues?.journal_description}
                                placeholder="Enter Journal Description"
                                onChange={(e) => handleChange(e, "journal")}
                              />
                              {showJournalsErrors && journalsErrors?.journal_description && (
                                <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
                                  Journal Description Is Required Field
                                </p>
                              )}
                            </div>
                          </div>

                          <UploadControlsV2
                            tooltipTitle="Upload Background Image"
                            className="my-2 col-span-2"
                            defaultCollapse={false}
                            title="Background *"
                            subtitle="Upload Image"
                            mobileUploadName="bg_Images"
                            handleMobileChangeUrl={(e) => handleChange(e, "journal", 0)}
                            mobileUploadProgressId="mobileUploadUrlAddJournalBackground"
                            handleMobileFileUpload={(e) =>
                              handleChange(
                                e,
                                "journalFile",
                                0,
                                null,
                                null,
                                "mobileUploadUrlAddJournalBackground"
                              )
                            }
                            mobileUploadUrl={journalValues?.bg_Images?.[0]?.url_link}
                            handleMobilePreview={() => {
                              setIsImage(true);
                              setShowModal(true);
                              setCurrentUrl(journalValues?.bg_Images?.[0]?.url_link);
                            }}
                            mobileUploadCropRequired={true}
                            mobileUploadCropAspect={3 / 4}
                            isMobileFieldRequired={
                              showJournalsErrors && journalsErrors?.bg_Images?.[0]?.url_link
                            }
                            tabletUploadName="bg_Images"
                            handleTabletChangeUrl={(e) => handleChange(e, "journal", 2)}
                            tabletUploadProgressId="tabletUploadUrlAddJournalBackground"
                            handleTabletFileUpload={(e) =>
                              handleChange(
                                e,
                                "journalFile",
                                2,
                                null,
                                null,
                                "tabletUploadUrlAddJournalBackground"
                              )
                            }
                            tabletUploadUrl={journalValues?.bg_Images?.[2]?.url_link}
                            handleTabletPreview={() => {
                              setIsImage(true);
                              setShowModal(true);
                              setCurrentUrl(journalValues?.bg_Images?.[2]?.url_link);
                            }}
                            tabletUploadCropRequired={true}
                            tabletUploadCropAspect={16 / 9}
                            isTabletFieldRequired={
                              showJournalsErrors && journalsErrors?.bg_Images?.[2]?.url_link
                            }
                            webUploadName="bg_Images"
                            handleWebChangeUrl={(e) => handleChange(e, "journal", 1)}
                            webUploadProgressId="webUploadUrlAddJournalBackground"
                            handleWebFileUpload={(e) =>
                              handleChange(
                                e,
                                "journalFile",
                                1,
                                null,
                                null,
                                "webUploadUrlAddJournalBackground"
                              )
                            }
                            webUploadUrl={journalValues?.bg_Images?.[1]?.url_link}
                            handleWebPreview={() => {
                              setIsImage(true);
                              setShowModal(true);
                              setCurrentUrl(journalValues?.bg_Images?.[1]?.url_link);
                            }}
                            webUploadCropRequired={true}
                            webUploadCropAspect={16 / 9}
                            isWebFieldRequired={
                              showJournalsErrors && journalsErrors?.bg_Images?.[1]?.url_link
                            }
                            requiredMessage="Please Upload the Image URL"
                          />
                          <UploadControlsV2
                            tooltipTitle="Upload Banner Image"
                            className="my-2 col-span-2"
                            defaultCollapse={false}
                            title="Banner *"
                            subtitle="Upload Image"
                            mobileUploadName="banner_Images"
                            handleMobileChangeUrl={(e) => handleChange(e, "journal", 0)}
                            mobileUploadProgressId="mobileUploadUrlAddJournalBanner"
                            handleMobileFileUpload={(e) =>
                              handleChange(
                                e,
                                "journalFile",
                                0,
                                null,
                                null,
                                "mobileUploadUrlAddJournalBanner"
                              )
                            }
                            mobileUploadUrl={journalValues?.banner_Images?.[0]?.url_link}
                            handleMobilePreview={() => {
                              setIsImage(true);
                              setShowModal(true);
                              setCurrentUrl(journalValues?.banner_Images?.[0]?.url_link);
                            }}
                            mobileUploadCropRequired={true}
                            mobileUploadCropAspect={3 / 4}
                            isMobileFieldRequired={
                              showJournalsErrors && journalsErrors?.banner_Images?.[0]?.url_link
                            }
                            tabletUploadName="banner_Images"
                            handleTabletChangeUrl={(e) => handleChange(e, "journal", 2)}
                            tabletUploadProgressId="tabletUploadUrlAddJournalBanner"
                            handleTabletFileUpload={(e) =>
                              handleChange(
                                e,
                                "journalFile",
                                2,
                                null,
                                null,
                                "tabletUploadUrlAddJournalBanner"
                              )
                            }
                            tabletUploadUrl={journalValues?.banner_Images?.[2]?.url_link}
                            handleTabletPreview={() => {
                              setIsImage(true);
                              setShowModal(true);
                              setCurrentUrl(journalValues?.banner_Images?.[2]?.url_link);
                            }}
                            tabletUploadCropRequired={true}
                            tabletUploadCropAspect={16 / 9}
                            isTabletFieldRequired={
                              showJournalsErrors && journalsErrors?.banner_Images?.[2]?.url_link
                            }
                            webUploadName="banner_Images"
                            handleWebChangeUrl={(e) => handleChange(e, "journal", 1)}
                            webUploadProgressId="webUploadUrlAddJournalBanner"
                            handleWebFileUpload={(e) =>
                              handleChange(
                                e,
                                "journalFile",
                                1,
                                null,
                                null,
                                "webUploadUrlAddJournalBanner"
                              )
                            }
                            webUploadUrl={journalValues?.banner_Images?.[1]?.url_link}
                            handleWebPreview={() => {
                              setIsImage(true);
                              setShowModal(true);
                              setCurrentUrl(journalValues?.banner_Images?.[1]?.url_link);
                            }}
                            webUploadCropRequired={true}
                            webUploadCropAspect={16 / 9}
                            isWebFieldRequired={
                              showJournalsErrors && journalsErrors?.banner_Images?.[1]?.url_link
                            }
                            requiredMessage="Please Upload the Image URL"
                          />
                          <div className="flex justify-end gap-5 py-5 w-full">
                            <button
                              className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                              onClick={() => {
                                setShowJournalBox(false);
                              }}
                            >
                              Close
                            </button>
                            <button
                              className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                              onClick={() => {
                                if (
                                  journalsErrors?.journal_title ||
                                  journalsErrors?.journal_description ||
                                  journalsErrors?.bg_Images?.[0].url_link ||
                                  journalsErrors?.bg_Images?.[1].url_link ||
                                  journalsErrors?.bg_Images?.[2].url_link ||
                                  journalsErrors?.banner_Images?.[0].url_link ||
                                  journalsErrors?.banner_Images?.[1].url_link ||
                                  journalsErrors?.banner_Images?.[2].url_link
                                ) {
                                  setShowJournalsErrors(true);
                                } else if (activeTab !== "en") {
                                  saveJournalsLanguage();
                                } else {
                                  saveJournals();
                                }
                              }}
                            >
                              {journalId ? "Update" : "Save"} Journal Draft
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Tooltip id="add_journal" className="border border-greyish border-opacity-20" />
                  </div>
                </Modal>
                <Modal
                  isOpen={showNotesBox}
                  onRequestClose={() => {
                    setShowNotesBox((prev) => !prev);
                    cancelNotes(journalValues?.notes?.[currNotesIndex], currNotesIndex);
                  }}
                  contentLabel="Custom Modal"
                  className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
                  ariaHideApp={false}
                >
                  <div className="MODAL-BODY h-[95%] md:w-[40%] w-[90%] md:absolute rounded-2xl p-5 bg-greyishV2">
                    <div className="w-full h-full flex flex-col">
                      <div className="flex items-center justify-between mb-4">
                        <p className="text-yellowish flex items-center text-lg font-semibold gap-1">
                          <IoIosArrowBack
                            className="text-2xl cursor-pointer"
                            onClick={() => setShowNotesBox(false)}
                          />
                          Add Notes
                        </p>
                        <div className="flex">
                          <div className="flex space-x-2">
                            {activeTab !== "en" && (
                              <button
                                className="flex items-center gap-1 text-greyish text-[11px] font-medium mr-3"
                                onClick={updateNotesWithTranslation}
                              >
                                <span>
                                  {" "}
                                  <img src={reload} alt="" />{" "}
                                </span>
                                Auto fill data with google translation
                              </button>
                            )}
                            {currNotes?.noteObjId && (
                              <RiDeleteBin5Line
                                className="text-xl text-red-500 cursor-pointer"
                                onClick={() =>
                                  handleDeleteNotes({
                                    noteObjId: currNotes?.noteObjId,
                                    notesIndex: currNotesIndex,
                                  })
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col rounded-3xl rounded-ss-none border border-yellowish pt-3 pb-7 overflow-hidden">
                        <div className="flex-1 overflow-y-auto flex flex-col custom-scrollbar pl-5 pr-8 pb-3">
                          <p className="text-greyish text-xs font-semibold mb-3">15 Dec 2024</p>
                          <ul className="flex flex-col gap-3">
                            {currNotes?.noteitems?.map((item, index) => (
                              <li key={`curr-note-item${index}`}>
                                {item?.type_of_notes === JOURNAL_NOTE_ITEM_TYPES.VIDEO &&
                                  !item?.isMulti && (
                                    <div className="relative group">
                                      <video
                                        src={item?.url_link}
                                        controls={true}
                                        className="w-5/12 aspect-video rounded-md overflow-hidden object-cover"
                                      ></video>
                                      <div className="hidden rounded group-hover:flex scale-105 py-1 left-0 top-0 absolute w-full min-h-full bg-yellowish/80 items-center justify-center">
                                        <button className="w-fit p-1 aspect-square bg-blackishV2 rounded-full">
                                          <MdDelete
                                            onClick={() => deleteNoteItem(index)}
                                            className="text-red-600 cursor-pointer"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                {item?.type_of_notes === JOURNAL_NOTE_ITEM_TYPES.VIDEO &&
                                  item?.isMulti && (
                                    <div className="grid w-full grid-cols-2 gap-3 relative group">
                                      {item?.url_link?.map((url, urlIndex) => (
                                        <video
                                          key={`url-item${urlIndex}${index}`}
                                          src={url}
                                          className="aspect-video rounded-md overflow-hidden object-cover"
                                          controls={true}
                                        ></video>
                                      ))}
                                      <div className="hidden rounded group-hover:flex scale-105 py-1 left-0 top-0 absolute w-full min-h-full bg-yellowish/80 items-center justify-center">
                                        <button className="w-fit p-1 aspect-square bg-blackishV2 rounded-full">
                                          <MdDelete
                                            onClick={() => deleteNoteItem(index)}
                                            className="text-red-600 cursor-pointer"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                {item?.type_of_notes === JOURNAL_NOTE_ITEM_TYPES.IMAGE &&
                                  !item?.isMulti && (
                                    <div className="relative group">
                                      <img
                                        src={item?.url_link}
                                        controls={true}
                                        className="w-5/12 aspect-video rounded-md overflow-hidden object-cover"
                                        alt=""
                                      />
                                      <div className="hidden rounded group-hover:flex scale-105 py-1 left-0 top-0 absolute w-full min-h-full bg-yellowish/80 items-center justify-center">
                                        <button className="w-fit p-1 aspect-square bg-blackishV2 rounded-full">
                                          <MdDelete
                                            onClick={() => deleteNoteItem(index)}
                                            className="text-red-600 cursor-pointer"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                {item?.type_of_notes === JOURNAL_NOTE_ITEM_TYPES.IMAGE &&
                                  item?.isMulti && (
                                    <div className="grid w-full grid-cols-2 gap-3 relative group overflow-hidden">
                                      {item?.url_link?.map((url, urlIndex) => (
                                        <img
                                          key={`url-item${urlIndex}${index}`}
                                          src={url}
                                          className="aspect-video rounded-md overflow-hidden object-cover"
                                          controls={true}
                                          alt=""
                                        />
                                      ))}
                                      <div className="hidden rounded group-hover:flex scale-105 py-1 left-0 top-0 absolute w-full min-h-full bg-yellowish/80 items-center justify-center">
                                        <button className="w-fit p-1 aspect-square bg-blackishV2 rounded-full">
                                          <MdDelete
                                            onClick={() => deleteNoteItem(index)}
                                            className="text-red-600 cursor-pointer"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                {item?.type_of_notes === JOURNAL_NOTE_ITEM_TYPES.TEXT && (
                                  <div className="relative group">
                                    <p
                                      className="text-white enable-list-style"
                                      dangerouslySetInnerHTML={{ __html: item?.notes }}
                                    ></p>
                                    <div className="hidden rounded group-hover:flex scale-105 py-1 left-0 top-0 absolute w-full min-h-full bg-yellowish/80 items-center justify-center">
                                      <button className="w-fit p-1 aspect-square bg-blackishV2 rounded-full">
                                        <MdDelete
                                          onClick={() => deleteNoteItem(index)}
                                          className="text-red-600 cursor-pointer"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                )}
                                {item?.type_of_notes === JOURNAL_NOTE_ITEM_TYPES.AUDIO &&
                                  !item?.isMulti && <AudioCard url={item?.url_link} />}
                                {item?.type_of_notes === JOURNAL_NOTE_ITEM_TYPES.AUDIO &&
                                  item?.isMulti && (
                                    <div className="flex items-center flex-wrap gap-3 relative group">
                                      {item?.url_link?.map((url, urlIndex) => (
                                        <AudioCard
                                          url={item?.url_link}
                                          key={`url-item${urlIndex}${index}`}
                                        />
                                      ))}
                                      <div className="hidden rounded group-hover:flex scale-105 py-1 left-0 top-0 absolute w-full min-h-full bg-yellowish/80 items-center justify-center">
                                        <button className="w-fit p-1 aspect-square bg-blackishV2 rounded-full">
                                          <MdDelete
                                            onClick={() => deleteNoteItem(index)}
                                            className="text-red-600 cursor-pointer"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                              </li>
                            ))}
                          </ul>
                          <div className="relative group">
                            {currNotes?.text && (
                              <FiPlusCircle
                                onClick={() => handleAddNoteText()}
                                className="text-white absolute z-[2000] hidden group-hover:block -right-2 bg-blackishV2 rounded-full text-lg cursor-pointer top-1/2 -translate-y-1/2"
                              />
                            )}
                            <MyStatefulEditor
                              forceEditorUpdate={forceEditorUpdate}
                              labelColor="white"
                              labelText=""
                              darkMode={true}
                              placeholder="| What's God speaking to you?"
                              htmlFor="currNotesText"
                              name="currNotesText"
                              value={currNotes?.text}
                              handleInputChange={(e) => {
                                setCurrNotes((prev) => ({ ...prev, text: e.target.value }));
                              }}
                              minimalToolbar={true}
                              showToolbarOnFocus={true}
                            />
                          </div>
                        </div>
                        <div className="flex items-center justify-between gap-3 pt-3 px-5">
                          <div className="flex items-center gap-3">
                            <UploadVideoButton
                              showVideoLibrarySelection={true}
                              handleLibraryVideoSelection={(details) => {
                                const item = details?.downloadurls?.find(
                                  (d) => d?.type_of_url === "1"
                                );
                                handleAddNotesAsset(
                                  currNotesIndex,
                                  JOURNAL_NOTE_ITEM_TYPES.VIDEO,
                                  null,
                                  item?.url_link
                                );
                              }}
                              handleVideoUpload={(e) => {
                                handleAddNotesAsset(
                                  currNotesIndex,
                                  JOURNAL_NOTE_ITEM_TYPES.VIDEO,
                                  e.target.files
                                );
                              }}
                              handleVideoCapture={(file) => {
                                handleAddNotesAsset(currNotesIndex, JOURNAL_NOTE_ITEM_TYPES.VIDEO, [
                                  file,
                                ]);
                              }}
                            />
                            <UploadAudioButton
                              handleAudioUpload={(e) => {
                                handleAddNotesAsset(
                                  currNotesIndex,
                                  JOURNAL_NOTE_ITEM_TYPES.AUDIO,
                                  e.target.files
                                );
                              }}
                              handleAudioCapture={(file) => {
                                handleAddNotesAsset(currNotesIndex, JOURNAL_NOTE_ITEM_TYPES.AUDIO, [
                                  file,
                                ]);
                              }}
                            />
                            <UploadImageButton
                              handleImageUpload={(e) => {
                                handleAddNotesAsset(
                                  currNotesIndex,
                                  JOURNAL_NOTE_ITEM_TYPES.IMAGE,
                                  e.target.files
                                );
                              }}
                              handleImageCapture={(file) => {
                                handleAddNotesAsset(currNotesIndex, JOURNAL_NOTE_ITEM_TYPES.IMAGE, [
                                  file,
                                ]);
                              }}
                            />
                          </div>
                          <button
                            className="px-8 py-2 rounded-xl border border-yellowish text-yellowish hover:bg-yellowish hover:text-blackishV2"
                            onClick={() => {
                              saveNotes();
                            }}
                          >
                            <FaCheck className="text-xl" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <Tooltip
                      id="add_journal_notes"
                      className="border border-greyish border-opacity-20"
                    />
                  </div>
                </Modal>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-[33%] bg-[#1b1b1b]">
        <CustomTabsV2
          tabHeight={"h-[64px]"}
          tabsTitleArray={["Stats"]}
          tabsContentObject={{
            Stats: (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                {journalId ? (
                  <div className="w-full px-8">
                    <p>
                      Views : <strong>{journalStats?.totalViews}</strong>
                    </p>
                    <p>
                      Likes: <strong>{journalStats?.totalLikes}</strong>
                    </p>
                  </div>
                ) : (
                  "No stats available for journal"
                )}
              </div>
            ),
          }}
        />
      </div>
      <ReactModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        className="relative"
        bodyOpenClassName="z-50"
      >
        <div className="flex justify-center h-full w-full relative">
          <div
            className="absolute top-0 right-0 cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            <ImCross />
          </div>
          {isImage ? (
            <img className="h-full" src={currentUrl} alt="" />
          ) : (
            <video className="h-full" controls autoPlay>
              <source src={currentUrl} />
            </video>
          )}
        </div>
      </ReactModal>
    </div>
  );
};

export default AddJournalFields;
