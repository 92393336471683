import { useState } from "react";
import { deleteLayoutAction, readLayoutAction } from "../../../store/Layout/LayoutAction";
import { useDispatch } from "react-redux";
import Toastiy from "../../../components/universal/Toastiy";

const LayoutHooks = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [reloadData, setReloadData] = useState(false);

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const handleDelete = (data) => {
    dispatch(
      deleteLayoutAction({
        apiPayloadRequest: { _id: data?._id },
        callback: (res) => {
          if (res?.type === 1) {
            setReloadData(true);
            Toastiy("Layout Deleted Successfully!", 1);
          } else {
            Toastiy("Failed To Delete Layout");
          }
        },
      })
    );
  };

  const fetchAllLayouts = (page, limit, searchCriteria = {}, callbackFn) => {
    console.log(
      "page",
      page,
      "limit",
      limit,
      "searchCriteria",
      searchCriteria,
      "callbackFn",
      callbackFn
    );
    dispatch(
      readLayoutAction({
        apiPayloadRequest: {
          page: page,
          limit: limit,
          searchCriteria: searchCriteria,
        },
        callback: (Data) => {
          if (Data?.type === 1) {
            if (callbackFn)
              callbackFn({
                data: Data?.data?.map((d) => ({ ...d, views: d?.views ?? 0 })),
                total: Data?.totalRecords ?? 0,
              });
          } else if (callbackFn) callbackFn({ data: [], total: Data?.totalRecords ?? 0 });
        },
      })
    );
  };

  return {
    value,
    onChangeSearchBar,
    handleDelete,
    fetchAllLayouts,
    reloadData,
    setReloadData,
  };
};

export default LayoutHooks;
