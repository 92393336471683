import * as actionTypes from "../ActionTypes";
import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import * as api from "./LayoutAPI";
import { actionUpdateGlobalLoaderSagaAction } from "../GlobalLoader/GlobalLoaderAction";
import { useNavigate } from "react-router-dom";

function* saveLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* layoutDashboardRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.layoutDashboard, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* videosDashboardRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.videosDashboard, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      action.payload.callback(err?.response);
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveVideoLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveVideoLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateVideoLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateVideoLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readVideoLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readVideoLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteVideoLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteVideoLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* searchVideoUrlRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.searchVideoUrls, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
    action.payload.callback(err);
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCategoryLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCategoryLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCategoryLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCategoryLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteCategoryLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCategoryLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readCategoryLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCategoryLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveCurriculumLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveCurriculumLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateCurriculumLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateCurriculumLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* readCurriculumLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.readCurriculumLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteCurriculumLayoutRequestSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteCurriculumLayout, payload);
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

export default function* LayoutWatcherSaga() {
  yield takeLatest(actionTypes.LAYOUT_DASHBOARD_REQUEST, layoutDashboardRequestSaga);

  yield takeLatest(actionTypes.VIDEOS_DASHBOARD_REQUEST, videosDashboardRequestSaga);

  yield takeLatest(actionTypes.SAVE_LAYOUT_REQUEST, saveLayoutRequestSaga);

  yield takeLatest(actionTypes.DELETE_LAYOUT_REQUEST, deleteLayoutRequestSaga);

  yield takeLatest(actionTypes.UPDATE_LAYOUT_REQUEST, updateLayoutRequestSaga);

  yield takeLatest(actionTypes.READ_LAYOUT_REQUEST, readLayoutRequestSaga);

  yield takeLatest(actionTypes.SAVE_CATEGORY_LAYOUT_REQUEST, saveCategoryLayoutRequestSaga);

  yield takeLatest(actionTypes.UPDATE_CATEGORY_LAYOUT_REQUEST, updateCategoryLayoutRequestSaga);

  yield takeLatest(actionTypes.DELETE_CATEGORY_LAYOUT_REQUEST, deleteCategoryLayoutRequestSaga);

  yield takeLatest(actionTypes.READ_CATEGORY_LAYOUT_REQUEST, readCategoryLayoutRequestSaga);

  yield takeLatest(actionTypes.SAVE_CURRICULUM_LAYOUT_REQUEST, saveCurriculumLayoutRequestSaga);

  yield takeLatest(actionTypes.UPDATE_CURRICULUM_LAYOUT_REQUEST, updateCurriculumLayoutRequestSaga);

  yield takeLatest(actionTypes.READ_CURRICULUM_LAYOUT_REQUEST, readCurriculumLayoutRequestSaga);

  yield takeLatest(actionTypes.DELETE_CURRICULUM_LAYOUT_REQUEST, deleteCurriculumLayoutRequestSaga);

  yield takeLatest(actionTypes.SAVE_VIDEO_LAYOUT_REQUEST, saveVideoLayoutRequestSaga);

  yield takeLatest(actionTypes.UPDATE_VIDEO_LAYOUT_REQUEST, updateVideoLayoutRequestSaga);

  yield takeLatest(actionTypes.READ_VIDEO_LAYOUT_REQUEST, readVideoLayoutRequestSaga);

  yield takeLatest(actionTypes.DELETE_VIDEO_LAYOUT_REQUEST, deleteVideoLayoutRequestSaga);

  yield takeEvery(actionTypes.SEARCH_VIDEO_URL_REQUEST, searchVideoUrlRequestSaga);
}
