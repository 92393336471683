import { FaInfoCircle } from "react-icons/fa";
import { MdDelete, MdVideoLibrary } from "react-icons/md";
import { RiVideoAddFill } from "react-icons/ri";

const RelatedVideoInput = ({
  categoryErrors,
  setShowVideoLibraryModal,
  selectedOptions,
  deleteRelatedVideo,
  updateRelatedVideoOrder,
  setSelectedOptions,
  showVideoOrder = true,
  setSelectedRelatedVideoIndex,
  isFieldRequired = true,
  videoLabelClassName = "",
}) => {
  return (
    <div className="col-span-2 max-h-[47vh] overflow-y-auto">
      <div className="flex flex-col p-2 col-span-2">
        <label
          htmlFor="relatedVideos"
          className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2 justify-between"
        >
          <span className="flex items-center gap-2">
            Related Videos {isFieldRequired && "*"}
            <FaInfoCircle
              data-tooltip-id="add_category"
              data-tooltip-content="Add Related Videos"
            />
          </span>
          <RiVideoAddFill
            className="text-white text-lg cursor-pointer"
            onClick={() => setShowVideoLibraryModal(true)}
          />
        </label>
        <div className="bg-blackish rounded-md px-3 py-5 mt-2 grid grid-cols-1 gap-y-2">
          {selectedOptions?.map((item, index) => (
            <div className="flex w-full justify-between items-center gap-3">
              <div className="flex-1 grid grid-cols-2 gap-3">
                <button
                  className={`bg-blackish text-white items-center flex gap-2 px-3 py-2 ${
                    item?.label ? "" : "justify-center"
                  } text-[14px] h-full font-medium mt-2 leading-4 rounded-md border border-opacity-10 border-white !m-0 whitespace-nowrap overflow-ellipsis ${videoLabelClassName}`}
                  onClick={() => {
                    setShowVideoLibraryModal(true);
                    setSelectedRelatedVideoIndex(index);
                  }}
                >
                  <MdVideoLibrary className="text-xl cursor-pointer" />
                  <span className="flex-1 overflow-hidden overflow-ellipsis text-start">
                    {item?.label ?? "Pickup from Gallery"}
                  </span>
                </button>
                {showVideoOrder && (
                  <input
                    type="number"
                    name="order"
                    disabled={!item?.value}
                    value={item?.order}
                    onChange={(e) =>
                      setSelectedOptions((prev) =>
                        prev?.map((d, i) => (i === index ? { ...d, order: e.target.value } : d))
                      )
                    }
                    onBlur={(e) => updateRelatedVideoOrder({ ...item, order: e.target.value })}
                    placeholder="Enter Video Order"
                    className="bg-greyishV2 py-2 px-3 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-full !m-0"
                  />
                )}
              </div>
              <MdDelete
                className="text-white text-2xl cursor-pointer"
                onClick={() => deleteRelatedVideo(item?.value)}
              />
            </div>
          ))}
        </div>
        {isFieldRequired && categoryErrors?.selectedOptions && (
          <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
            Related Videos Is Required Field
          </p>
        )}
      </div>
    </div>
  );
};

export default RelatedVideoInput;
