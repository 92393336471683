import Axios from "../../utils/axios";

//Get Country data
export const readTestimonyCategories = (payload) => {
  const URL = "/testimony/category/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const deleteTestimonyCategories = (payload) => {
  const URL = "/testimony/category/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveTestimonyCategories = (payload) => {
  const URL = "/testimony/category/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateTestimonyCategories = (payload) => {
  const URL = "/testimony/category/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const readTestimony = (payload) => {
  const URL = "/testimony/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const acceptTestimony = (payload) => {
  const URL = "/testimony/activateordeactivate";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const rejectTestimony = (payload) => {
  const URL = "/testimony/activateordeactivate";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const testimonyOfTheDay = (payload) => {
  const URL = "/testimony/makeTestimonyOfTheDay";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
