import Axios from "../../utils/axios";

export const getBibleLanguages = (payload) => {
  const URL = "/bible/countries";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getBibleVersions = (payload) => {
  const URL = "/bible/biblesforlanguage";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getVersionBooks = (payload) => {
  const URL = "/bible/bibledata";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getChapterDetails = (payload) => {
  const URL = "/bible/biblebookdata";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getBibleSearch = (payload) => {
  const URL = "/bible/search";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getChapterVideos = (payload) => {
  const URL = "/chapter/twgvideos/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveChapterVideos = (payload) => {
  const URL = "/chapter/videos/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deleteChapterVideos = (payload) => {
  const URL = "/chapter/videos/remove";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getChapterJournals = (payload) => {
  const URL = "/chapter/twgjournals/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveChapterJournals = (payload) => {
  const URL = "/chapter/journals/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deleteChapterJournals = (payload) => {
  const URL = "/chapter/journals/remove";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getVerseDetails = (payload) => {
  const URL = "/verse/read";
  return Axios.post(URL, payload).then((res) => res.data);
};
