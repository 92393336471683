import edit from "../../assets/icons/edit.svg";
import close from "../../assets/icons/close.svg";
import { useState } from "react";
import ConfirmToast from "../universal/ConfirmToast";
import { confirmAlert } from "react-confirm-alert";
import { format } from "date-fns";
import { JOURNAL_NOTE_ITEM_TYPES } from "../../constants/Common";

const NotePreviewCard = ({
  note,
  journalValues,
  index,
  setCurrNotesIndex,
  setIsNotesEdit,
  setCurrNotes,
  setShowNotesBox,
  handleDeleteNotes,
}) => {
  const text = note?.noteitems?.find(
    (d) => d?.type_of_notes === JOURNAL_NOTE_ITEM_TYPES.TEXT
  )?.notes;
  return (
    <div className="flex flex-col gap-3 relative p-4 border bg-black bg-opacity-30 rounded-lg border-opacity-25 border-white">
      <div className="bg-yellowish h-3 w-3 border-2  border-white  absolute -left-[30px] top-1 -translate-y-1/2 rounded-full"></div>
      <div className="cursor-pointer">
        <div className="flex justify-between">
          <p
            className="text-sm font-white font-medium text-white w-9/12"
            dangerouslySetInnerHTML={{ __html: text ?? "--" }}
          ></p>
          <div className="flex gap-2 justify-end items-center cursor-pointer">
            <span className="mr-8 text-greyish">
              {format(note?.lastUpdatedDate, "dd MMM yyyy")}
            </span>
            <img
              className="h-4"
              src={edit}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                setCurrNotes(note);
                setShowNotesBox(true);
                setCurrNotesIndex(index);
                setIsNotesEdit(true);
              }}
            />
            <img
              className="h-4 mr-4"
              src={close}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                const options = {
                  customUI: ({ onClose }) => {
                    return (
                      <ConfirmToast
                        title="Are you sure?"
                        message="You want to delete this lesson?"
                        onClose={() => onClose()}
                        onSuccess={() => {
                          handleDeleteNotes({
                            noteObjId: note?.noteObjId,
                            notesIndex: index,
                          });
                          onClose();
                        }}
                      />
                    );
                  },
                  closeOnEscape: true,
                  closeOnClickOutside: true,
                  keyCodeForClose: [8, 32],
                  overlayClassName: "!bg-gray-500 !bg-opacity-75",
                };
                confirmAlert(options);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotePreviewCard;
