import * as actionTypes from "../ActionTypes";

const INITIAL = {
  languagesList: [],
  rawLanguagesList: {},
  activeLanguage: "en",
};

const LanguageReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case actionTypes.SET_LANGUAGES_LIST:
      return { ...state, languagesList: action.payload };
    case actionTypes.SET_RAW_LANGUAGES_LIST:
      return { ...state, rawLanguagesList: action.payload };
    case actionTypes.SET_ACTIVE_LANGUAGE:
      return { ...state, activeLanguage: action.payload };
    default:
      return state;
  }
};

export default LanguageReducer;
