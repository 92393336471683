import { useEffect, useState } from "react";
import like from "../../assets/icons/like.svg";
import share from "../../assets/icons/share.svg";
import reload from "../../assets/icons/reload.svg";
import CustomTabsV2 from "../universal/CustomTabsV2";

import CustomLineChart from "../charts/lineChart/CustomLineChart";
import SingleTab from "../universal/SingleTab";
import CustomCarousel from "../universal/CustomCarousel";
// import DateTimePicker from "react-datetime-picker";
import {
  readLanguageAction,
  translateLanguageAction,
  uploadAssestsV2Action,
  uploadVideosV2Action,
} from "../../store/Languages/LanguageAction";
import { useDispatch } from "react-redux";
import {
  readCMSPromiseAction,
  savePromiseAction,
  savePromiseAudioAction,
  savePromiseAudioLanguageAction,
  savePromiseBgAction,
  savePromiseCommonBgAction,
  savePromiseCommonThumbnailAction,
  savePromiseHWGImageAction,
  savePromiseLanguageAction,
  savePromiseThumbnailAction,
  savePromiseVideoAction,
  savePromiseVideoLanguageAction,
  updatePromiseAction,
  updatePromiseAudioAction,
  updatePromiseAudioLanguageAction,
  updatePromiseBgAction,
  updatePromiseCommonBgAction,
  updatePromiseCommonThumbnailAction,
  updatePromiseHWGImageAction,
  updatePromiseLanguageAction,
  updatePromiseThumbnailAction,
  updatePromiseVideoAction,
  updatePromiseVideoLanguageAction,
} from "../../store/TWG/TWGAction";
import Toastiy from "../universal/Toastiy";
import LanguageDrawer from "../universal/LanguageDrawer";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { FaInfoCircle } from "react-icons/fa";
import UploadControlsV2 from "./UploadControlsV2";
import moment from "moment";
import { setShowNavigationAlertAction } from "../../store/GlobalLoader/GlobalLoaderAction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineCalendarToday } from "react-icons/md";
import { sendCustomNotificationAction } from "../../store/Notifications/NotificationAction";

const AddTWGFields = ({ setLoading, _id }) => {
  const dispatch = useDispatch();
  const [readLanguageData, setReadLanguageData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [activeTab, setActiveTab] = useState("en");

  const initialStateValues = {
    mobileUploadUrl: "",
    webUploadUrl: "",
    tabletUploadUrl: "",
  };

  const initialStateErrors = {
    mobileUploadUrl: false,
    webUploadUrl: false,
    tabletUploadUrl: false,
  };

  const initialPromiseValues = {
    id: _id,
    title: "",
    date: "",
    hearWords: "",
    readWords: "",
    image: initialStateValues,
    audio: initialStateValues,
    video: initialStateValues,
    commonThumbnail: initialStateValues,
    thumbnail: initialStateValues,
    hwg_image: initialStateValues,
    commonImage: initialStateValues,
  };

  const initialErrorValues = {
    title: false,
    date: false,
    hearWords: false,
    readWords: false,
    image: false,
    audio: false,
    video: false,
    // commonThumbnail: false,
    thumbnail: false,
    hwg_image: false,
    // commonImage: false,
  };

  const [translatePromise, setTranslatePromise] = useState();
  const [promiseValues, setPromiseValues] = useState(initialPromiseValues);
  const [currentPromise, setCurrentPromise] = useState(initialPromiseValues);
  const [englishValues, setEnglishValues] = useState(initialPromiseValues);
  const [isLanguageSave, setIsLanguageSave] = useState();
  const [activeLanguageDetails, setActiveLanguageDetails] = useState({
    language_code: "en",
    language_name: "English",
  });
  const [promiseErrors, setPromiseErrors] = useState(initialErrorValues);

  console.log(promiseValues, "promiseValues");
  console.log(promiseErrors, "promiseErrors");

  useEffect(() => {
    return () => setShowNavigationAlert(false);
  }, []);

  const setShowNavigationAlert = (value) => {
    dispatch(setShowNavigationAlertAction(value));
  };

  const handleLibraryVideoSelection = (details) => {
    setPromiseValues((prev) => ({
      ...prev,
      title: details?.video_title,
      hearWords: details?.video_description,
      readWords: details?.video_description,
      image: {
        mobileUploadUrl: details?.tumbnailurls?.find((d) => d?.type_of_url === "0")?.url_link,
        webUploadUrl: details?.tumbnailurls?.find((d) => d?.type_of_url === "1")?.url_link,
        tabletUploadUrl: details?.tumbnailurls?.find((d) => d?.type_of_url === "2")?.url_link,
      },
      commonImage: {
        mobileUploadUrl: details?.commonImage?.find((d) => d?.type_of_url === "0")?.url_link,
        webUploadUrl: details?.commonImage?.find((d) => d?.type_of_url === "1")?.url_link,
        tabletUploadUrl: details?.commonImage?.find((d) => d?.type_of_url === "2")?.url_link,
      },
      video: {
        mobileUploadUrl: details?.urls?.find((d) => d?.type_of_url === "0")?.url_link,
        webUploadUrl: details?.urls?.find((d) => d?.type_of_url === "1")?.url_link,
        tabletUploadUrl: details?.urls?.find((d) => d?.type_of_url === "2")?.url_link,
      },
      thumbnail: {
        mobileUploadUrl: details?.tumbnailurls?.find((d) => d?.type_of_url === "0")?.url_link,
        webUploadUrl: details?.tumbnailurls?.find((d) => d?.type_of_url === "1")?.url_link,
        tabletUploadUrl: details?.tumbnailurls?.find((d) => d?.type_of_url === "2")?.url_link,
      },
      commonThumbnail: {
        mobileUploadUrl: details?.commonThumbnail?.find((d) => d?.type_of_url === "0")?.url_link,
        webUploadUrl: details?.commonThumbnail?.find((d) => d?.type_of_url === "1")?.url_link,
        tabletUploadUrl: details?.commonThumbnail?.find((d) => d?.type_of_url === "2")?.url_link,
      },
      hwg_image: {
        mobileUploadUrl:
          details?.gnerictumbnailurls?.find((d) => d?.type_of_url === "0")?.url_link ||
          details?.tumbnailurls?.find((d) => d?.type_of_url === "0")?.url_link,
        webUploadUrl:
          details?.gnerictumbnailurls?.find((d) => d?.type_of_url === "1")?.url_link ||
          details?.tumbnailurls?.find((d) => d?.type_of_url === "1")?.url_link,
        tabletUploadUrl:
          details?.gnerictumbnailurls?.find((d) => d?.type_of_url === "2")?.url_link ||
          details?.tumbnailurls?.find((d) => d?.type_of_url === "2")?.url_link,
      },
    }));
    setPromiseErrors((prev) => ({
      ...prev,
      title: false,
      hearWords: false,
      readWords: false,
      image: false,
      commonImage: false,
      video: false,
      thumbnail: false,
      commonThumbnail: false,
      hwg_image: false,
    }));
    setShowNavigationAlert(true);
  };

  const handleFileUploadImage = (event, uploadProgressId) => {
    const { name, files } = event.target;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadAssestsV2Action({
        apiPayloadRequest: formData,
        uploadProgressId: uploadProgressId,
        callback: (res) => {
          setPromiseValues((prevInputs) => ({
            ...prevInputs,
            image: {
              ...prevInputs.image,
              [name]: res?.url,
            },
          }));
          setPromiseErrors((prev) => ({ ...prev, image: false }));
          setLoading(false);
        },
      })
    );
    setShowNavigationAlert(true);
  };

  const handleFileUploadCommonImage = (event, uploadProgressId) => {
    const { name, files } = event.target;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadAssestsV2Action({
        apiPayloadRequest: formData,
        uploadProgressId: uploadProgressId,
        callback: (res) => {
          setPromiseValues((prevInputs) => ({
            ...prevInputs,
            commonImage: {
              ...prevInputs.commonImage,
              [name]: res?.url,
            },
          }));
          setPromiseErrors((prev) => ({ ...prev, commonImage: false }));
        },
      })
    );
    setLoading(false);
    setShowNavigationAlert(true);
  };

  const handleFileUploadHWGImage = (event, uploadProgressId) => {
    const { name, files } = event.target;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadAssestsV2Action({
        apiPayloadRequest: formData,
        uploadProgressId: uploadProgressId,
        callback: (res) => {
          setPromiseValues((prevInputs) => ({
            ...prevInputs,
            hwg_image: {
              ...prevInputs.image,
              [name]: res?.url,
            },
          }));
          setPromiseErrors((prev) => ({ ...prev, hwg_image: false }));
          setLoading(false);
        },
      })
    );
    setShowNavigationAlert(true);
  };

  const handleFileUploadAudio = (event, uploadProgressId) => {
    const { name, files } = event.target;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadAssestsV2Action({
        apiPayloadRequest: formData,
        uploadProgressId: uploadProgressId,
        callback: (res) => {
          setPromiseValues((prevInputs) => ({
            ...prevInputs,
            audio: {
              ...prevInputs.audio,
              [name]: res?.url,
            },
          }));
          setPromiseErrors((prev) => ({ ...prev, audio: false }));
          setLoading(false);
        },
      })
    );
    setShowNavigationAlert(true);
  };

  const handleFileUploadVideo = (event, uploadProgressId) => {
    const { name, files } = event.target;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadVideosV2Action({
        apiPayloadRequest: formData,
        uploadProgressId: uploadProgressId,
        callback: (res) => {
          setPromiseValues((prevInputs) => ({
            ...prevInputs,
            video: {
              ...prevInputs.video,
              [name]: res?.url,
            },
          }));
          setPromiseErrors((prev) => ({ ...prev, video: false }));
          setLoading(false);
        },
      })
    );
    setShowNavigationAlert(true);
  };

  const handleFileUploadThumbnail = (event, uploadProgressId) => {
    const { name, files } = event.target;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadAssestsV2Action({
        apiPayloadRequest: formData,
        uploadProgressId: uploadProgressId,
        callback: (res) => {
          setPromiseValues((prevInputs) => ({
            ...prevInputs,
            thumbnail: {
              ...prevInputs.thumbnail,
              [name]: res?.url,
            },
          }));
          setPromiseErrors((prev) => ({ ...prev, thumbnail: false }));
          setLoading(false);
        },
      })
    );
    setShowNavigationAlert(true);
  };

  const handleFileUploadCommonThumbnail = (event, uploadProgressId) => {
    const { name, files } = event.target;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", files[0]);
    dispatch(
      uploadAssestsV2Action({
        apiPayloadRequest: formData,
        uploadProgressId: uploadProgressId,
        callback: (res) => {
          setPromiseValues((prevInputs) => ({
            ...prevInputs,
            commonThumbnail: {
              ...prevInputs.commonThumbnail,
              [name]: res?.url,
            },
          }));
          setPromiseErrors((prev) => ({ ...prev, commonThumbnail: false }));
          setLoading(false);
        },
      })
    );
    setShowNavigationAlert(true);
  };

  const handleChangeImage = (event) => {
    const { name, value } = event.target;

    setPromiseValues((prevInputs) => ({
      ...prevInputs,
      image: {
        ...prevInputs.image,
        [name]: value,
      },
    }));
    setPromiseErrors((prev) => ({ ...prev, image: false }));
    setShowNavigationAlert(true);
  };

  const handleChangeCommonImage = (event) => {
    const { name, value } = event.target;

    setPromiseValues((prevInputs) => ({
      ...prevInputs,
      commonImage: {
        ...prevInputs.commonImage,
        [name]: value,
      },
    }));
    setPromiseErrors((prev) => ({ ...prev, commonImage: false }));
    setShowNavigationAlert(true);
  };

  const handleChangeHWGImage = (event) => {
    const { name, value } = event.target;

    console.log("name, value", name, value);

    setPromiseValues((prevInputs) => ({
      ...prevInputs,
      hwg_image: {
        ...prevInputs.hwg_image,
        [name]: value,
      },
    }));
    setPromiseErrors((prev) => ({ ...prev, hwg_image: false }));
    setShowNavigationAlert(true);
  };

  const handleChangeAudio = (event) => {
    const { name, value } = event.target;

    setPromiseValues((prevInputs) => ({
      ...prevInputs,
      audio: {
        ...prevInputs.audio,
        [name]: value,
      },
    }));
    setPromiseErrors((prev) => ({ ...prev, audio: false }));
    setShowNavigationAlert(true);
  };

  const handleChangeVideo = (event) => {
    const { name, value } = event.target;

    setPromiseValues((prevInputs) => ({
      ...prevInputs,
      video: {
        ...prevInputs.video,
        [name]: value,
      },
    }));
    setPromiseErrors((prev) => ({ ...prev, video: false }));
    setShowNavigationAlert(true);
  };

  const handleChangeThumbnail = (event) => {
    const { name, value } = event.target;

    setPromiseValues((prevInputs) => ({
      ...prevInputs,
      thumbnail: {
        ...prevInputs.thumbnail,
        [name]: value,
      },
    }));
    setPromiseErrors((prev) => ({ ...prev, thumbnail: false }));
    setShowNavigationAlert(true);
  };

  const handleChangeCommonThumbnail = (event) => {
    const { name, value } = event.target;

    setPromiseValues((prevInputs) => ({
      ...prevInputs,
      commonThumbnail: {
        ...prevInputs.commonThumbnail,
        [name]: value,
      },
    }));
    setPromiseErrors((prev) => ({ ...prev, commonThumbnail: false }));
    setShowNavigationAlert(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setPromiseValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    setPromiseErrors((prev) => ({ ...prev, [name]: false }));
    setShowNavigationAlert(true);
  };

  const handleDateChange = (value) => {
    setPromiseValues((prev) => ({
      ...prev,
      date: value,
    }));
    setPromiseErrors((prev) => ({ ...prev, date: false }));
    setShowNavigationAlert(true);
  };

  const handleSave = () => {
    const errors = {};

    if (!promiseValues?.title) {
      errors["title"] = true;
    }
    if (!promiseValues?.date) {
      errors["date"] = true;
    }
    if (!promiseValues?.hearWords) {
      errors["hearWords"] = true;
    }
    if (!promiseValues?.readWords) {
      errors["readWords"] = true;
    }
    if (!promiseValues?.readWords) {
      errors["readWords"] = true;
    }
    if (
      !promiseValues?.image ||
      !promiseValues?.image?.mobileUploadUrl ||
      !promiseValues?.image?.tabletUploadUrl ||
      !promiseValues?.image?.webUploadUrl
    ) {
      errors["image"] = true;
    }
    if (
      !promiseValues?.audio ||
      !promiseValues?.audio?.mobileUploadUrl ||
      !promiseValues?.audio?.tabletUploadUrl ||
      !promiseValues?.audio?.webUploadUrl
    ) {
      errors["audio"] = true;
    }
    if (
      !promiseValues?.video ||
      !promiseValues?.video?.mobileUploadUrl ||
      !promiseValues?.video?.tabletUploadUrl ||
      !promiseValues?.video?.webUploadUrl
    ) {
      errors["video"] = true;
    }
    if (
      !promiseValues?.thumbnail ||
      !promiseValues?.thumbnail?.mobileUploadUrl ||
      !promiseValues?.thumbnail?.tabletUploadUrl ||
      !promiseValues?.thumbnail?.webUploadUrl
    ) {
      errors["thumbnail"] = true;
    }
    if (
      !promiseValues?.hwg_image ||
      !promiseValues?.hwg_image?.mobileUploadUrl ||
      !promiseValues?.hwg_image?.tabletUploadUrl ||
      !promiseValues?.hwg_image?.webUploadUrl
    ) {
      errors["hwg_image"] = true;
    }

    if (Object.keys(errors).some((d) => errors[d])) {
      setPromiseErrors(errors);
      return;
    }

    setPromiseErrors(errors);

    if (promiseValues.id !== undefined) {
      if (
        currentPromise?.title !== promiseValues.title ||
        currentPromise?.hearWords !== promiseValues.hearWords ||
        currentPromise?.readWords !== promiseValues.readWords ||
        currentPromise?.date !== promiseValues.date
      ) {
        dispatch(
          updatePromiseAction({
            apiPayloadRequest: {
              _id: _id,
              twg_title: promiseValues.title,
              hear_gods_word: promiseValues.hearWords,
              read_gods_word: promiseValues.readWords,
              promise_date: promiseValues.date,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Promise Update successful", 1);
                setShowNavigationAlert(false);
              }
            },
          })
        );
      }
      const keysArray = Object.keys(initialStateValues);
      keysArray.map((item) => {
        if (currentPromise?.image[item] === "" && promiseValues.image[item] !== "") {
          dispatch(
            savePromiseBgAction({
              apiPayloadRequest: {
                _id: _id,
                twg_title: promiseValues.title,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.image[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        } else if (currentPromise?.image[item] !== promiseValues.image[item]) {
          dispatch(
            updatePromiseBgAction({
              apiPayloadRequest: {
                _id: _id,
                twg_title: promiseValues.title,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.image[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        }

        if (currentPromise?.commonImage[item] === "" && promiseValues.commonImage[item] !== "") {
          dispatch(
            savePromiseCommonBgAction({
              apiPayloadRequest: {
                _id: _id,
                twg_title: promiseValues.title,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.commonImage[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        } else if (currentPromise?.commonImage[item] !== promiseValues.commonImage[item]) {
          dispatch(
            updatePromiseCommonBgAction({
              apiPayloadRequest: {
                _id: _id,
                twg_title: promiseValues.title,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.commonImage[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        }

        if (currentPromise?.audio[item] === "" && promiseValues.audio[item] !== "") {
          dispatch(
            savePromiseAudioAction({
              apiPayloadRequest: {
                _id: _id,
                twg_title: promiseValues.title,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.audio[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        } else if (currentPromise?.audio[item] !== promiseValues.audio[item]) {
          dispatch(
            updatePromiseAudioAction({
              apiPayloadRequest: {
                _id: _id,
                twg_title: promiseValues.title,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.audio[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        }

        if (currentPromise?.video[item] === "" && promiseValues.video[item] !== "") {
          dispatch(
            savePromiseVideoAction({
              apiPayloadRequest: {
                _id: _id,
                twg_title: promiseValues.title,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.video[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        } else if (currentPromise?.video[item] !== promiseValues.video[item]) {
          dispatch(
            updatePromiseVideoAction({
              apiPayloadRequest: {
                _id: _id,
                twg_title: promiseValues.title,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.video[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        }

        if (currentPromise?.thumbnail[item] === "" && promiseValues.thumbnail[item] !== "") {
          dispatch(
            savePromiseThumbnailAction({
              apiPayloadRequest: {
                _id: _id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.thumbnail[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        } else if (currentPromise?.thumbnail[item] !== promiseValues.thumbnail[item]) {
          dispatch(
            updatePromiseThumbnailAction({
              apiPayloadRequest: {
                _id: _id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.thumbnail[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        }

        if (
          currentPromise?.commonThumbnail[item] === "" &&
          promiseValues.commonThumbnail[item] !== ""
        ) {
          dispatch(
            savePromiseCommonThumbnailAction({
              apiPayloadRequest: {
                _id: _id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.commonThumbnail[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        } else if (currentPromise?.commonThumbnail[item] !== promiseValues.commonThumbnail[item]) {
          dispatch(
            updatePromiseCommonThumbnailAction({
              apiPayloadRequest: {
                _id: _id,
                type_of_url: item === "mobileUploadUrl" ? "0" : item === "webUploadUrl" ? "1" : "2",
                url_link: promiseValues.commonThumbnail[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        }

        if (currentPromise?.hwg_image[item] === "" && promiseValues.hwg_image[item] !== "") {
          dispatch(
            savePromiseHWGImageAction({
              apiPayloadRequest: {
                _id: _id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.hwg_image[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        } else if (currentPromise?.hwg_image[item] !== promiseValues.hwg_image[item]) {
          dispatch(
            updatePromiseHWGImageAction({
              apiPayloadRequest: {
                _id: _id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: promiseValues.hwg_image[item],
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Promise Update successful", 1);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        }
      });
    } else {
      dispatch(
        savePromiseAction({
          apiPayloadRequest: {
            twg_title: promiseValues.title,
            hear_gods_word: promiseValues.hearWords,
            read_gods_word: promiseValues.readWords,
            promise_date: promiseValues.date,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setPromiseValues((prev) => ({
                ...prev,
                id: res?.data[0]?._id,
              }));

              setCurrentPromise((prev) => ({
                ...prev,
                id: res?.data[0]?._id,
              }));

              setPromiseErrors(initialErrorValues);

              const keysArray = Object.keys(initialStateValues);
              keysArray.map((item) => {
                dispatch(
                  savePromiseBgAction({
                    apiPayloadRequest: {
                      _id: res?.data[0]?._id,
                      twg_title: promiseValues.title,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: promiseValues.image[item],
                    },
                  })
                );

                dispatch(
                  savePromiseCommonBgAction({
                    apiPayloadRequest: {
                      _id: res?.data[0]?._id,
                      twg_title: promiseValues.title,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: promiseValues.commonImage[item],
                    },
                  })
                );

                dispatch(
                  savePromiseAudioAction({
                    apiPayloadRequest: {
                      _id: res?.data[0]?._id,
                      twg_title: promiseValues.title,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: promiseValues.audio[item],
                    },
                  })
                );

                dispatch(
                  savePromiseVideoAction({
                    apiPayloadRequest: {
                      _id: res?.data[0]?._id,
                      twg_title: promiseValues.title,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: promiseValues.video[item],
                    },
                  })
                );

                dispatch(
                  savePromiseThumbnailAction({
                    apiPayloadRequest: {
                      _id: res?.data[0]?._id,
                      twg_title: promiseValues.title,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: promiseValues.thumbnail[item],
                    },
                  })
                );

                dispatch(
                  savePromiseCommonThumbnailAction({
                    apiPayloadRequest: {
                      _id: res?.data[0]?._id,
                      twg_title: promiseValues.title,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: promiseValues.commonThumbnail[item],
                    },
                  })
                );

                dispatch(
                  savePromiseHWGImageAction({
                    apiPayloadRequest: {
                      _id: res?.data[0]?._id,
                      twg_title: promiseValues.title,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: promiseValues.hwg_image[item],
                    },
                  })
                );
              });
              setShowNavigationAlert(false);
              sendNotification(res?.data[0]?._id);
            }
          },
        })
      );
    }
  };

  const fetchPromiseOfTheDayData = (languageUpdate = false) => {
    dispatch(
      readCMSPromiseAction({
        apiPayloadRequest: {
          _id: _id,
        },
        callback: (res) => {
          if (res?.type === 1) {
            const data = res?.data[0];
            const languagemetas = data?.languagemetas ? data?.languagemetas : [];
            const commonImages = res?.data[0].hwg__Common_Image ?? [];
            const images = res?.data[0].bg_Images ?? [];
            const audios = res?.data[0].audios ?? [];
            const videos = res?.data[0].videos ?? [];
            const thumbnails = res?.data[0].video_Tumbnails ?? [];
            const commonThumbnails = res?.data[0].video_Common_Tumbnails ?? [];
            const hwg_images = res?.data[0].hwg_Image ?? [];

            if (!languageUpdate) {
              setCurrentPromise({
                id: _id,
                title: data?.twg_title,
                date: data?.promise_date || "",
                createdDate: data?.createdDate || "",
                hearWords: data?.hear_gods_word || "",
                readWords: data?.read_gods_word || "",
                image: {
                  mobileUploadUrl:
                    images.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    images.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    images.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },

                commonImage: {
                  mobileUploadUrl:
                    commonImages.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    commonImages.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    commonImages.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                audio: {
                  mobileUploadUrl:
                    audios.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    audios.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    audios.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                video: {
                  mobileUploadUrl:
                    videos.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    videos.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    videos.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                thumbnail: {
                  mobileUploadUrl:
                    thumbnails.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    thumbnails.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    thumbnails.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                commonThumbnail: {
                  mobileUploadUrl:
                    commonThumbnails.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    commonThumbnails.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    commonThumbnails.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                hwg_image: {
                  mobileUploadUrl:
                    hwg_images.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    hwg_images.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    hwg_images.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
              });

              setPromiseValues({
                id: _id,
                title: data?.twg_title,
                createdDate: data?.createdDate || "",
                date: data?.promise_date || "",
                hearWords: data?.hear_gods_word || "",
                readWords: data?.read_gods_word || "",
                image: {
                  mobileUploadUrl:
                    images.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    images.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    images.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },

                commonImage: {
                  mobileUploadUrl:
                    commonImages.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    commonImages.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    commonImages.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                audio: {
                  mobileUploadUrl:
                    audios.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    audios.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    audios.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                video: {
                  mobileUploadUrl:
                    videos.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    videos.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    videos.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                thumbnail: {
                  mobileUploadUrl:
                    thumbnails.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    thumbnails.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    thumbnails.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                commonThumbnail: {
                  mobileUploadUrl:
                    commonThumbnails.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    commonThumbnails.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    commonThumbnails.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                hwg_image: {
                  mobileUploadUrl:
                    hwg_images.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    hwg_images.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    hwg_images.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
              });

              setPromiseErrors(initialErrorValues);

              setEnglishValues({
                id: _id,
                title: data?.twg_title,
                date: data?.promise_date || "",
                createdDate: data?.createdDate || "",
                hearWords: data?.hear_gods_word || "",
                readWords: data?.read_gods_word || "",
                image: {
                  mobileUploadUrl:
                    images.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    images.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    images.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },

                commonImage: {
                  mobileUploadUrl:
                    commonImages.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    commonImages.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    commonImages.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                audio: {
                  mobileUploadUrl:
                    audios.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    audios.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    audios.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                video: {
                  mobileUploadUrl:
                    videos.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    videos.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    videos.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                thumbnail: {
                  mobileUploadUrl:
                    thumbnails.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    thumbnails.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    thumbnails.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                commonThumbnail: {
                  mobileUploadUrl:
                    commonThumbnails.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    commonThumbnails.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    commonThumbnails.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
                hwg_image: {
                  mobileUploadUrl:
                    hwg_images.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                  webUploadUrl:
                    hwg_images.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                  tabletUploadUrl:
                    hwg_images.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
                },
              });

              setTranslatePromise({
                title: data?.twg_title,
                hearWords: data?.hear_gods_word || "",
                readWords: data?.read_gods_word || "",
              });
            }

            setLanguageData(languagemetas);
          }
        },
      })
    );
  };

  useEffect(() => {
    if (_id !== undefined) {
      fetchPromiseOfTheDayData();
    }
  }, []);

  const combineStateKeys = () => {
    const values = Object.values(translatePromise);
    const combinedString = values.join(" @@@@ ");
    return combinedString;
  };

  const updateStateWithTranslation = () => {
    // const landCode = language.split(' - ');

    const combinedText = combineStateKeys();

    // if (landCode[1]?.length > 0) {
    dispatch(
      translateLanguageAction({
        apiPayloadRequest: {
          targetcode: activeTab,
          translatetext: combinedText,
        },
        callback: (res) => {
          if (res?.type === 1) {
            onTranslateSuccess(res?.data);
          } else if (res?.type === 2) {
            Toastiy("Language Translation failed!!");
            Toastiy(res?.message);
          }
        },
      })
    );
    // }
  };

  const onTranslateSuccess = (data) => {
    const arrayOfValues = data.split(" @@@@ ");
    const arrayOfKeys = Object.keys(translatePromise);

    console.log(arrayOfKeys, arrayOfValues);

    for (let key in arrayOfKeys) {
      setTranslatePromise((preState) => ({
        ...preState,
        [arrayOfKeys[key]]: arrayOfValues[key],
      }));

      setPromiseValues((preState) => ({
        ...preState,
        [arrayOfKeys[key]]: arrayOfValues[key],
      }));
    }
    setShowNavigationAlert(true);
  };

  const getAllLanguageResponse = (response) => {
    if (response?.type === 1) {
      setReadLanguageData(response?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      readLanguageAction({
        callback: getAllLanguageResponse,
      })
    );
  }, []);

  useEffect(() => {
    if (activeTab === "en") {
      if (englishValues) {
        setPromiseValues(englishValues);
        setCurrentPromise(englishValues);
      }
    } else {
      const isPresent = languageData?.some((language) => language?.language_code === activeTab);
      if (isPresent) {
        const data = languageData?.filter((item) => item?.language_code === activeTab)[0];

        const audios = data?.audios;
        const videos = data?.videos;

        setPromiseValues((prev) => ({
          ...prev,
          title: data?.twg_title,
          hearWords: data?.hear_gods_word || "",
          readWords: data?.read_gods_word || "",
          audio: audios?.length
            ? {
                mobileUploadUrl:
                  audios.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                webUploadUrl: audios.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                tabletUploadUrl:
                  audios.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
              }
            : initialStateValues,
          video: videos?.length
            ? {
                mobileUploadUrl:
                  videos.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                webUploadUrl: videos.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                tabletUploadUrl:
                  videos.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
              }
            : initialStateValues,
        }));

        setCurrentPromise((prev) => ({
          ...prev,
          title: data?.twg_title,
          hearWords: data?.hear_gods_word || "",
          readWords: data?.read_gods_word || "",
          audio: audios?.length
            ? {
                mobileUploadUrl:
                  audios.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                webUploadUrl: audios.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                tabletUploadUrl:
                  audios.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
              }
            : initialStateValues,
          video: videos?.length
            ? {
                mobileUploadUrl:
                  videos.filter((item) => item?.type_of_url === "0")[0]?.url_link || "",
                webUploadUrl: videos.filter((item) => item?.type_of_url === "1")[0]?.url_link || "",
                tabletUploadUrl:
                  videos.filter((item) => item?.type_of_url === "2")[0]?.url_link || "",
              }
            : initialStateValues,
        }));
      } else {
        setPromiseValues({
          title: englishValues?.title,
          date: englishValues?.date,
          hearWords: englishValues?.hearWords,
          readWords: englishValues?.readWords,
          image: initialStateValues,
          audio: initialStateValues,
          video: initialStateValues,
          commonImage: initialStateValues,
          commonThumbnail: initialStateValues,
        });
        setCurrentPromise({
          title: englishValues?.title,
          date: englishValues?.date,
          hearWords: englishValues?.hearWords,
          readWords: englishValues?.readWords,
          image: initialStateValues,
          audio: initialStateValues,
          video: initialStateValues,
          commonImage: initialStateValues,
          commonThumbnail: initialStateValues,
        });
      }
    }
  }, [activeTab, languageData]);

  const handleLanguageSave = async () => {
    const requestsList = [];
    const data = languageData?.filter((item) => item?.language_code === activeTab)[0];
    const keysArray = Object.keys(initialStateValues);
    const promiseAudio = promiseValues?.audio;
    const promiseVideo = promiseValues?.video;

    if (isLanguageSave) {
      dispatch(
        savePromiseLanguageAction({
          apiPayloadRequest: {
            _id: _id,
            language_code: activeTab,
            twg_title: promiseValues?.title,
            hear_gods_word: promiseValues?.hearWords,
            read_gods_word: promiseValues?.readWords,
          },
          callback: async (res) => {
            if (res?.type === 1) {
              // Handle audio save
              keysArray.forEach((item, index) => {
                requestsList.push(
                  new Promise((resolve) => {
                    setTimeout(() => {
                      dispatch(
                        savePromiseAudioLanguageAction({
                          apiPayloadRequest: {
                            _id: _id,
                            language_code: activeTab,
                            type_of_url:
                              item === "mobileUploadUrl"
                                ? "0"
                                : item === "webUploadUrl"
                                ? "1"
                                : "2",
                            url_link: promiseAudio[item],
                          },
                          callback: (res) => resolve(true),
                        })
                      );
                    }, index * 200);
                  })
                );
              });

              // Handle video save
              keysArray.forEach((item, index) => {
                requestsList.push(
                  new Promise((resolve) => {
                    setTimeout(() => {
                      dispatch(
                        savePromiseVideoLanguageAction({
                          apiPayloadRequest: {
                            _id: _id,
                            language_code: activeTab,
                            type_of_url:
                              item === "mobileUploadUrl"
                                ? "0"
                                : item === "webUploadUrl"
                                ? "1"
                                : "2",
                            url_link: promiseVideo[item],
                          },
                          callback: (res) => resolve(true),
                        })
                      );
                    }, index * 200);
                  })
                );
              });

              await Promise.all(requestsList);
              setShowNavigationAlert(false);
              fetchPromiseOfTheDayData(true);
            } else {
              Toastiy("Failed to save promise language");
            }
          },
        })
      );
    } else {
      const currentAudio = currentPromise?.audio;
      const currentVideo = currentPromise?.video;

      // Update title, hearWords, and readWords if changed
      if (
        currentPromise?.title !== promiseValues?.title ||
        currentPromise?.hearWords !== promiseValues?.hearWords ||
        currentPromise?.readWords !== promiseValues?.readWords
      ) {
        requestsList.push(
          new Promise((resolve) => {
            dispatch(
              updatePromiseLanguageAction({
                apiPayloadRequest: {
                  _id: _id,
                  language_code: activeTab,
                  twg_title: promiseValues?.title,
                  hear_gods_word: promiseValues?.hearWords,
                  read_gods_word: promiseValues?.readWords,
                },
                callback: (res) => resolve(true),
              })
            );
          })
        );
      }

      // Update audio if changed
      keysArray.forEach((item) => {
        const audioItem = data?.audios?.find(
          (d) =>
            d?.type_of_url == (item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2)
        );
        if (!audioItem && promiseAudio[item]) {
          requestsList.push(
            new Promise((resolve) => {
              dispatch(
                savePromiseAudioLanguageAction({
                  apiPayloadRequest: {
                    _id: _id,
                    language_code: activeTab,
                    type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                    url_link: promiseAudio[item],
                  },
                  callback: (res) => resolve(true),
                })
              );
            })
          );
        } else if (promiseAudio[item] && promiseAudio[item] !== currentAudio[item]) {
          requestsList.push(
            new Promise((resolve) => {
              dispatch(
                updatePromiseAudioLanguageAction({
                  apiPayloadRequest: {
                    _id: _id,
                    language_code: activeTab,
                    type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                    url_link: promiseAudio[item],
                  },
                  callback: (res) => resolve(true),
                })
              );
            })
          );
        }
      });

      // Update video if changed
      keysArray.forEach((item) => {
        const videoItem = data?.videos?.find(
          (d) =>
            d?.type_of_url == (item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2)
        );
        if (!videoItem && promiseVideo[item]) {
          requestsList.push(
            new Promise((resolve) => {
              dispatch(
                savePromiseVideoLanguageAction({
                  apiPayloadRequest: {
                    _id: _id,
                    language_code: activeTab,
                    type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                    url_link: promiseVideo[item],
                  },
                  callback: (res) => resolve(true),
                })
              );
            })
          );
        } else if (promiseVideo[item] && promiseVideo[item] !== currentVideo[item]) {
          requestsList.push(
            new Promise((resolve) => {
              dispatch(
                updatePromiseVideoLanguageAction({
                  apiPayloadRequest: {
                    _id: _id,
                    language_code: activeTab,
                    type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                    url_link: promiseVideo[item],
                  },
                  callback: (res) => resolve(true),
                })
              );
            })
          );
        }
      });

      await Promise.all(requestsList);
      setShowNavigationAlert(false);
      fetchPromiseOfTheDayData(true);
    }
  };

  const sendNotification = (id, showToast = false) => {
    dispatch(
      sendCustomNotificationAction({
        apiPayloadRequest: {
          _id: id,
          type: "5",
        },
        callback: (res) => {
          if (res?.type === 1 && showToast) {
            Toastiy("Notification Sent Successfully", 1);
          }
        },
      })
    );
  };

  return (
    <div className="flex flex-col md:flex-row h-full w-full flex-1 rounded-2xl overflow-hidden">
      <div className="flex h-full flex-1">
        <LanguageDrawer
          languageData={readLanguageData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          languagemeta={languageData}
          setIsLanguageSave={setIsLanguageSave}
          setActiveLanguageDetails={setActiveLanguageDetails}
        />
        <div className="flex h-full flex-1">
          <div className="flex flex-col h-full w-full bg-greyishV2 bg-opacity-50 md:border-l md:border-r border-greyish border-opacity-30">
            <div className="flex justify-between gap-4 items-center h-[64px] px-4 border-b border-[#28292a]">
              <p className="text-white font-semibold text-lg flex whitespace-nowrap">
                {activeTab === "en"
                  ? "Add Promise"
                  : `Translate Promise to ${activeLanguageDetails?.language_name}`}
              </p>
              <div className="flex gap-4 items-center">
                {_id && (
                  <button
                    className="text-yellowish bg-blackish font-medium text-[12px] whitespace-nowrap border border-yellowish rounded-xl py-2 px-6"
                    onClick={() => sendNotification(_id, true)}
                  >
                    Send Notification
                  </button>
                )}
                {activeTab !== "en" && (
                  <p
                    className="flex items-center gap-1 text-greyish text-[11px] font-medium cursor-pointer whitespace-nowrap"
                    onClick={updateStateWithTranslation}
                  >
                    <img src={reload} alt="" /> Auto fill data with google translation
                  </p>
                )}
                {activeTab === "en" ? (
                  <div className="w-full flex items-end justify-end gap-4 mt-2">
                    <button
                      className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                      onClick={handleSave}
                    >
                      {_id ? "Update" : "Save"}
                    </button>
                  </div>
                ) : (
                  <div className="w-full flex items-end justify-end gap-4 mt-2">
                    <button
                      className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                      onClick={handleLanguageSave}
                    >
                      Save Language
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* TAB CONTENT  */}
            {activeTab !== "en" && !_id ? (
              <div className="flex items-center justify-center text-white gap-1 py-28">
                Promise translation in{" "}
                <span className="italic">{activeLanguageDetails?.language_name}</span> is available
                for saved promises
              </div>
            ) : (
              <div className="px-4 my-4">
                <div className="h-[68vh] overflow-y-auto overflow-x-hidden">
                  <div className="grid md:grid-cols-2 grid-cols-1 grid-rows-1 gap-x-4 gap-y-4 mx-1 w-full">
                    <div>
                      <label
                        htmlFor="title"
                        className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                      >
                        Title
                        <FaInfoCircle
                          data-tooltip-id="add_promise"
                          data-tooltip-content="Enter the Title"
                        />
                      </label>
                      <input
                        name="title"
                        placeholder="Enter Title"
                        type="text"
                        className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                        value={promiseValues.title}
                        onChange={handleChange}
                      />
                      {promiseErrors?.title && (
                        <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
                          Promise Title Is Required Field
                        </p>
                      )}
                    </div>
                    {activeTab === "en" ? (
                      <div>
                        <label
                          htmlFor="date"
                          className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                        >
                          Select Date
                          <FaInfoCircle
                            data-tooltip-id="add_promise"
                            data-tooltip-content="Select the Date"
                          />
                        </label>
                        {/* <DateTimePicker
                          name="date"
                          className={
                            "bg-blackish w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10"
                          }
                          value={promiseValues?.date}
                          onChange={handleDateChange}
                        /> */}
                        <DatePicker
                          selected={promiseValues?.date}
                          onChange={handleDateChange}
                          isClearable
                          showIcon={true}
                          showMonthDropdown={true}
                          showYearDropdown={true}
                          dropdownMode="select"
                          toggleCalendarOnIconClick={true}
                          placeholderText="MM/DD/YYYY"
                          icon={<MdOutlineCalendarToday className="mr-2 cursor-pointer" />}
                          wrapperClassName="mt-2 custom-datepicker"
                          className="bg-greyishV2 stroke-white !p-3 !pl-8 w-full text-white text-[14px] font-medium leading-4 rounded-md"
                        />
                        {promiseErrors?.date && (
                          <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
                            Promise Date Is Required Field
                          </p>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="md:col-span-2 col-span-1">
                      <p className="text-[12px] text-greyish font-medium leading-4 mb-3">
                        Promise of the Day Video
                      </p>
                      <div className="p-2 bg-blackish rounded-lg">
                        <UploadControlsV2
                          showVideoLibrarySelection={true}
                          handleLibraryVideoSelection={handleLibraryVideoSelection}
                          tooltipTitle="Upload Promise Video"
                          className={promiseErrors?.video ? "mt-2" : "my-2"}
                          title="Upload Video"
                          subtitle="Upload Video"
                          mobileUploadProgressId="mobileUploadUrlAddTwgPromiseVideo"
                          handleMobileFileUpload={(e) =>
                            handleFileUploadVideo(e, "mobileUploadUrlAddTwgPromiseVideo")
                          }
                          tabletUploadProgressId="tabletUploadUrlAddTwgPromiseVideo"
                          handleTabletFileUpload={(e) =>
                            handleFileUploadVideo(e, "tabletUploadUrlAddTwgPromiseVideo")
                          }
                          webUploadProgressId="webUploadUrlAddTwgPromiseVideo"
                          handleWebFileUpload={(e) =>
                            handleFileUploadVideo(e, "webUploadUrlAddTwgPromiseVideo")
                          }
                          handleChangeUrl={handleChangeVideo}
                          mobileUploadUrl={promiseValues?.video?.mobileUploadUrl}
                          tabletUploadUrl={promiseValues?.video?.tabletUploadUrl}
                          webUploadUrl={promiseValues?.video?.webUploadUrl}
                          accept="video"
                          isFieldRequired={promiseErrors?.video}
                          isFieldRequiredClassName="mb-5"
                        />
                        {activeTab === "en" && (
                          <UploadControlsV2
                            tooltipTitle="Upload Video Thumbnail"
                            className={promiseErrors?.thumbnail ? "mt-2" : "my-2"}
                            title="Upload Thumbnail"
                            subtitle="Upload Thumbnail"
                            mobileUploadProgressId="mobileUploadUrlAddTwgPromiseVideoThumbnail"
                            handleMobileFileUpload={(e) =>
                              handleFileUploadThumbnail(
                                e,
                                "mobileUploadUrlAddTwgPromiseVideoThumbnail"
                              )
                            }
                            mobileUploadCropRequired={true}
                            mobileUploadCropAspect={4 / 3}
                            tabletUploadProgressId="tabletUploadUrlAddTwgPromiseVideoThumbnail"
                            handleTabletFileUpload={(e) =>
                              handleFileUploadThumbnail(
                                e,
                                "tabletUploadUrlAddTwgPromiseVideoThumbnail"
                              )
                            }
                            tabletUploadCropRequired={true}
                            tabletUploadCropAspect={16 / 9}
                            webUploadProgressId="webUploadUrlAddTwgPromiseVideoThumbnail"
                            handleWebFileUpload={(e) =>
                              handleFileUploadThumbnail(
                                e,
                                "webUploadUrlAddTwgPromiseVideoThumbnail"
                              )
                            }
                            handleChangeUrl={handleChangeThumbnail}
                            mobileUploadUrl={promiseValues?.thumbnail?.mobileUploadUrl}
                            tabletUploadUrl={promiseValues?.thumbnail?.tabletUploadUrl}
                            webUploadUrl={promiseValues?.thumbnail?.webUploadUrl}
                            webUploadCropRequired={true}
                            webUploadCropAspect={16 / 9}
                            accept="image"
                            isFieldRequired={promiseErrors?.thumbnail}
                            isFieldRequiredClassName="mb-5"
                          />
                        )}
                        {activeTab === "en" && (
                          <UploadControlsV2
                            showVideoLibrarySelection={false}
                            handleLibraryVideoSelection={handleLibraryVideoSelection}
                            tooltipTitle="Upload Promise Common Thumbnail"
                            className="my-2"
                            title="Upload Common Thumbnail"
                            subtitle="Upload Common Thumbnail"
                            mobileUploadProgressId="mobileUploadUrlAddTwgPromiseCommonThumbnail"
                            handleMobileFileUpload={(e) =>
                              handleFileUploadCommonThumbnail(
                                e,
                                "mobileUploadUrlAddTwgPromiseCommonThumbnail"
                              )
                            }
                            mobileUploadCropRequired={true}
                            mobileUploadCropAspect={3 / 4}
                            tabletUploadProgressId="tabletUploadUrlAddTwgPromiseCommonThumbnail"
                            handleTabletFileUpload={(e) =>
                              handleFileUploadCommonThumbnail(
                                e,
                                "tabletUploadUrlAddTwgPromiseCommonThumbnail"
                              )
                            }
                            tabletUploadCropRequired={true}
                            tabletUploadCropAspect={16 / 9}
                            webUploadProgressId="webtUploadUrlAddTwgPromiseCommonThumbnail"
                            handleWebFileUpload={(e) =>
                              handleFileUploadCommonThumbnail(
                                e,
                                "webtUploadUrlAddTwgPromiseCommonThumbnail"
                              )
                            }
                            webUploadCropRequired={true}
                            webUploadCropAspect={16 / 9}
                            handleChangeUrl={handleChangeCommonThumbnail}
                            mobileUploadUrl={promiseValues?.commonThumbnail?.mobileUploadUrl}
                            tabletUploadUrl={promiseValues?.commonThumbnail?.tabletUploadUrl}
                            webUploadUrl={promiseValues?.commonThumbnail?.webUploadUrl}
                            accept="image"
                          />
                        )}
                      </div>
                    </div>

                    <div className="md:col-span-2 col-span-1">
                      <p className="text-[12px] text-greyish font-medium leading-4 mb-3">
                        Promise of the Day Audio
                      </p>

                      <div className="p-2 bg-blackish rounded-lg">
                        <div className="md:col-span-2 col-span-1">
                          <label
                            htmlFor="hearWords"
                            className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                          >
                            Hear God's Word
                            <FaInfoCircle
                              data-tooltip-id="add_promise"
                              data-tooltip-content="Enter the text for God's Word"
                            />
                          </label>
                          <textarea
                            rows={6}
                            className={`bg-blackishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md`}
                            name="hearWords"
                            placeholder="Enter Here"
                            value={promiseValues.hearWords}
                            onChange={handleChange}
                          />
                          {promiseErrors?.hearWords && (
                            <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
                              God's Word Is Required Field
                            </p>
                          )}
                        </div>

                        <UploadControlsV2
                          className={promiseErrors?.audio ? "col-span-2 mt-2" : "col-span-2 my-2"}
                          tooltipTitle="Upload Audio"
                          title="Upload Audio"
                          subtitle="Upload Audio"
                          mobileUploadProgressId="mobileUploadUrlAddTwgPromiseAudio"
                          handleMobileFileUpload={(e) =>
                            handleFileUploadAudio(e, "mobileUploadUrlAddTwgPromiseAudio")
                          }
                          tabletUploadProgressId="tabletUploadUrlAddTwgPromiseAudio"
                          handleTabletFileUpload={(e) =>
                            handleFileUploadAudio(e, "tabletUploadUrlAddTwgPromiseAudio")
                          }
                          webUploadProgressId="webUploadUrlAddTwgPromiseAudio"
                          handleWebFileUpload={(e) =>
                            handleFileUploadAudio(e, "webUploadUrlAddTwgPromiseAudio")
                          }
                          handleChangeUrl={handleChangeAudio}
                          mobileUploadUrl={promiseValues?.audio?.mobileUploadUrl}
                          tabletUploadUrl={promiseValues?.audio?.tabletUploadUrl}
                          webUploadUrl={promiseValues?.audio?.webUploadUrl}
                          accept="audio"
                          isFieldRequired={promiseErrors?.audio}
                          isFieldRequiredClassName="mb-5"
                        />
                        {activeTab === "en" && (
                          <UploadControlsV2
                            tooltipTitle="Upload Image"
                            className={
                              promiseErrors?.hwg_image ? "col-span-2 mt-2" : "col-span-2 my-2"
                            }
                            title="Upload Image"
                            subtitle="Upload Image"
                            mobileUploadProgressId="mobileUploadUrlAddTwgPromiseHWGImage"
                            handleMobileFileUpload={(e) =>
                              handleFileUploadHWGImage(e, "mobileUploadUrlAddTwgPromiseHWGImage")
                            }
                            mobileUploadCropRequired={true}
                            mobileUploadCropAspect={16 / 9}
                            tabletUploadProgressId="tabletUploadUrlAddTwgPromiseHWGImage"
                            handleTabletFileUpload={(e) =>
                              handleFileUploadHWGImage(e, "tabletUploadUrlAddTwgPromiseHWGImage")
                            }
                            tabletUploadCropRequired={true}
                            tabletUploadCropAspect={16 / 9}
                            webUploadProgressId="webUploadUrlAddTwgPromiseHWGImage"
                            handleWebFileUpload={(e) =>
                              handleFileUploadHWGImage(e, "webUploadUrlAddTwgPromiseHWGImage")
                            }
                            webUploadCropRequired={true}
                            webUploadCropAspect={16 / 9}
                            handleChangeUrl={handleChangeHWGImage}
                            mobileUploadUrl={promiseValues?.hwg_image?.mobileUploadUrl}
                            tabletUploadUrl={promiseValues?.hwg_image?.tabletUploadUrl}
                            webUploadUrl={promiseValues?.hwg_image?.webUploadUrl}
                            accept="image"
                            isFieldRequired={promiseErrors?.hwg_image}
                            isFieldRequiredClassName="mb-5"
                          />
                        )}
                      </div>
                    </div>
                    <div className="md:col-span-2 col-span-1">
                      <div className="p-2 bg-blackish rounded-lg">
                        <label
                          htmlFor="readWords"
                          className="text-[12px] text-greyish font-medium leading-4 flex items-center gap-2"
                        >
                          Promise of the Day Scripture
                          <FaInfoCircle
                            data-tooltip-id="add_promise"
                            data-tooltip-content="Enter Promise of the day Scripture"
                          />
                        </label>
                        <textarea
                          rows={6}
                          className={`bg-blackishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md`}
                          name="readWords"
                          placeholder="Enter Here"
                          value={promiseValues.readWords}
                          onChange={handleChange}
                        />
                        {promiseErrors?.readWords && (
                          <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2">
                            Promise Scripture Is Required Field
                          </p>
                        )}
                        {activeTab === "en" && (
                          <>
                            <UploadControlsV2
                              tooltipTitle="Upload Image"
                              className={
                                promiseErrors?.image ? "col-span-2 mt-2" : "col-span-2 my-2"
                              }
                              title="Upload Image"
                              subtitle="Upload Image"
                              mobileUploadProgressId="mobileUploadUrlAddTwgPromiseImage"
                              handleMobileFileUpload={(e) =>
                                handleFileUploadImage(e, "mobileUploadUrlAddTwgPromiseImage")
                              }
                              mobileUploadCropRequired={true}
                              mobileUploadCropAspect={4 / 3}
                              tabletUploadProgressId="tabletUploadUrlAddTwgPromiseImage"
                              handleTabletFileUpload={(e) =>
                                handleFileUploadImage(e, "tabletUploadUrlAddTwgPromiseImage")
                              }
                              tabletUploadCropRequired={true}
                              tabletUploadCropAspect={16 / 9}
                              webUploadProgressId="webUploadUrlAddTwgPromiseImage"
                              handleWebFileUpload={(e) =>
                                handleFileUploadImage(e, "webUploadUrlAddTwgPromiseImage")
                              }
                              webUploadCropRequired={true}
                              webUploadCropAspect={16 / 9}
                              handleChangeUrl={handleChangeImage}
                              mobileUploadUrl={promiseValues?.image?.mobileUploadUrl}
                              tabletUploadUrl={promiseValues?.image?.tabletUploadUrl}
                              webUploadUrl={promiseValues?.image?.webUploadUrl}
                              accept="image"
                              isFieldRequired={promiseErrors?.image}
                              isFieldRequiredClassName="mb-5"
                            />
                            <UploadControlsV2
                              tooltipTitle="Upload Common Image"
                              className="col-span-2"
                              title="Upload Common Image"
                              subtitle="Upload Common Image"
                              mobileUploadProgressId="mobileUploadUrlAddTwgPromiseCommonImage"
                              handleMobileFileUpload={(e) =>
                                handleFileUploadCommonImage(
                                  e,
                                  "mobileUploadUrlAddTwgPromiseCommonImage"
                                )
                              }
                              mobileUploadCropRequired={true}
                              mobileUploadCropAspect={1}
                              tabletUploadProgressId="tabletUploadUrlAddTwgPromiseCommonImage"
                              handleTabletFileUpload={(e) =>
                                handleFileUploadCommonImage(
                                  e,
                                  "tabletUploadUrlAddTwgPromiseCommonImage"
                                )
                              }
                              tabletUploadCropRequired={true}
                              tabletUploadCropAspect={16 / 9}
                              webUploadProgressId="webUploadUrlAddTwgPromiseCommonImage"
                              handleWebFileUpload={(e) =>
                                handleFileUploadCommonImage(
                                  e,
                                  "webUploadUrlAddTwgPromiseCommonImage"
                                )
                              }
                              webUploadCropRequired={true}
                              webUploadCropAspect={16 / 9}
                              handleChangeUrl={handleChangeCommonImage}
                              mobileUploadUrl={promiseValues?.commonImage?.mobileUploadUrl}
                              tabletUploadUrl={promiseValues?.commonImage?.tabletUploadUrl}
                              webUploadUrl={promiseValues?.commonImage?.webUploadUrl}
                              accept="image"
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Tooltip id="add_promise" className="border border-greyish border-opacity-20" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="md:w-[33%] bg-[#1b1b1b]">
        <CustomTabsV2
          tabHeight={"h-[64px]"}
          tabsTitleArray={["Stats"]}
          tabsContentObject={{
            Stats: !_id ? (
              <div className="mt-8 flex items-center justify-center w-full text-white">
                No stats available for promise
              </div>
            ) : (
              <div className="p-4">
                <div className="p-1 flex justify-between items-center w-full">
                  <div className="flex items-center gap-8">
                    {
                      <p className="font-semibold text-white">
                        Created On :{" "}
                        <strong>
                          {promiseValues?.createdDate
                            ? moment(promiseValues?.createdDate)?.format("DD/MM/YYYY")
                            : "NA"}
                        </strong>
                      </p>
                    }
                  </div>
                </div>
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default AddTWGFields;
