import React, { useEffect, useState } from "react";
import SearchBar from "../../components/universal/SearchBar";
import SquareCard from "../../components/universal/SquareCard";
import squareIcon1 from "../../assets/icons/squareCard/icon1.svg";
import { AiOutlinePlus, AiOutlineReload } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import NotificationsListHooks from "./hooks/NotificationsListHooks";
import CustomTable from "../../components/universal/CustomTable";
import { notificationsListData, notificationsListHeaders } from "./Constants";
import Navbar from "../../components/universal/Navbar";
import { useDispatch } from "react-redux";
import {
  deleteNotificationAction,
  getListOfNotificationsAction,
} from "../../store/Notifications/NotificationAction";
import Toastiy from "../../components/universal/Toastiy";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import ConfirmToast from "../../components/universal/ConfirmToast";
import { downloadExcel } from "../../utils/DownloadExcel";
import moment from "moment";

function NotificationsList() {
  // search bar component state with handler
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { value, onChangeSearchBar, reloadData, setReloadData, fetchAllNotifications } =
    NotificationsListHooks();
  const [notificationData, setNotificationData] = useState([]);
  const [isUpdated, setIsUpdated] = useState(true);
  const [loading, setLoading] = useState(true);

  console.log("notificationData", notificationData);

  const handleDeleteNotification = (id) => {
    dispatch(
      deleteNotificationAction({
        apiPayloadRequest: {
          _id: id,
        },
        callback: (res) => {
          if (res?.type === 1) {
            const updatedData = notificationData.filter((item) => item._id !== id);
            setNotificationData(updatedData);
            Toastiy("Notification Deleted Successfully!", 1);
            setIsUpdated(true);
          }
        },
      })
    );
  };

  useEffect(() => {
    if (isUpdated) {
      setLoading(true);
      const notificationsPromise = new Promise((resolve, reject) => {
        dispatch(
          getListOfNotificationsAction({
            callback: (res) => {
              if (res?.type === 1) {
                setNotificationData(res?.data);
                setIsUpdated(false);
              }
              resolve();
            },
          })
        );
      });

      Promise.all([notificationsPromise]).finally(() => setLoading(false));
    }
  }, [isUpdated]);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium flex items-center gap-3">
                Notifications List
                <AiOutlineReload
                  className={`cursor-pointer text-2xl text-yellowish ${
                    loading ? "animate-spin" : ""
                  }`}
                  onClick={() => setIsUpdated(true)}
                />
              </p>
              <div className="flex gap-4">
                <Link to={"/notificationsList/add"}>
                  <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
                    <AiOutlinePlus /> Create Notifications
                  </button>
                </Link>
              </div>
            </div>

            <div className="flex gap-4 flex-wrap">
              <SquareCard
                heading={"Total Notifications"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={notificationData?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(notificationData?.map((d) => ({
                      "Notification Title": d?.notification_title,
                      "Notification Description": d?.notification_description,
                      "Created At": d?.createdDate
                        ? moment(d?.createdDate)?.format("DD/MM/YYYY, mm:hh A")
                        : "",
                      "Schedule Time": d?.notification_schedule_time
                        ? moment(d?.notification_schedule_time)?.format("DD/MM/YYYY, mm:hh A")
                        : "",
                    })) ?? []),
                  ]);
                }}
                handleOnClick={() => {
                  //do task
                }}
              />
              <SquareCard
                heading={"Notifications Sent Successfully"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={notificationData?.filter((d) => d?.notification_status === 0)?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(notificationData
                      ?.filter((d) => d?.notification_status === 0)
                      ?.map((d) => ({
                        "Notification Title": d?.notification_title,
                        "Notification Description": d?.notification_description,
                        "Created At": d?.createdDate
                          ? moment(d?.createdDate)?.format("DD/MM/YYYY, mm:hh A")
                          : "",
                        "Schedule Time": d?.notification_schedule_time
                          ? moment(d?.notification_schedule_time)?.format("DD/MM/YYYY, mm:hh A")
                          : "",
                      })) ?? []),
                  ]);
                }}
                handleOnClick={() => {
                  //do task
                }}
              />
              <SquareCard
                heading={"Notifications Sent Failed"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={notificationData?.filter((d) => d?.notification_status === 1)?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(notificationData
                      ?.filter((d) => d?.notification_status === 1)
                      ?.map((d) => ({
                        "Notification Title": d?.notification_title,
                        "Notification Description": d?.notification_description,
                        "Created At": d?.createdDate
                          ? moment(d?.createdDate)?.format("DD/MM/YYYY, mm:hh A")
                          : "",
                        "Schedule Time": d?.notification_schedule_time
                          ? moment(d?.notification_schedule_time)?.format("DD/MM/YYYY, mm:hh A")
                          : "",
                      })) ?? []),
                  ]);
                }}
                handleOnClick={() => {
                  //do task
                }}
              />
              {/* <SquareCard
                heading={"Nofication noticed Successfully"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={"0"}
                trail={"Views"}
                percentage={"0%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              />
              <SquareCard
                heading={"Notification Sent Unread"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={"0"}
                trail={"Views"}
                percentage={"0%"}
                handleOnClick={() => {
                  console.log("clicked");
                }}
              /> */}
            </div>
            <div>
              {/* custom table with different data as per the selected carousel  */}
              <CustomTable
                width={"w-24"}
                // giving extra width to 2nd column
                nthChildWidth={
                  "[&>*:nth-child(1)]:w-40 [&>*:nth-child(2)]:w-40 [&>*:nth-child(3)]:w-48"
                }
                nthChildColor={
                  "[&>*:nth-child(2)]:text-greyish [&>*:nth-child(3)]:text-greyish [&>*:nth-child(4)]:text-greyish [&>*:nth-child(5)]:text-greyish"
                }
                nthChildStyle={"[&>*:nth-child(3)]:h-5 [&>*:nth-child(3)]:overflow-hidden"}
                data={[]}
                headers={notificationsListHeaders}
                customDataFetch={true}
                customDataFetchFunction={fetchAllNotifications}
                reloadData={reloadData}
                setReloadData={setReloadData}
                notificationTable={true}
                reverseTable={true}
                handleToggleClick={(row, isActive) => {
                  console.log(isActive, row);
                }}
                handleEyeClick={(row) => {
                  console.log(row);
                  navigate("/notificationsList/add", {
                    state: { _id: row?._id },
                  });
                }}
                handleEditClick={(row) => {
                  navigate("/notificationsList/add", {
                    state: { _id: row?._id },
                  });
                }}
                heightDesktop={"h-[72vh]"}
                handleDeleteClick={(row) => {
                  const options = {
                    customUI: ({ onClose }) => {
                      return (
                        <ConfirmToast
                          title="Are you sure?"
                          message="You want to delete this notification?"
                          onClose={() => onClose()}
                          onSuccess={() => {
                            handleDeleteNotification(row?._id);
                            onClose();
                          }}
                        />
                      );
                    },
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                    keyCodeForClose: [8, 32],
                    overlayClassName: "!bg-gray-500 !bg-opacity-75",
                  };

                  confirmAlert(options);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationsList;
