import Navbar from "../../components/universal/Navbar";
import SearchBar from "../../components/universal/SearchBar";

function Analytics() {
  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={""} onChangeSearchBar={() => {}} />
          <div
            className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4 relative"
            id="reportContainer"
          >
            <iframe
              title="analytics"
              allowFullScreen
              src="https://lookerstudio.google.com/embed/reporting/e864c2d8-f606-484f-9ad2-a5bab3d76249/page/kIV1C"
              sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
              className="border-none w-full h-[84vh]"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
