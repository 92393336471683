import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteVideosAction,
  deleteVideosrelatedAction,
  readLanguageAction,
  readVideosAction,
  saveCommonsUrlsAction,
  saveDownloadVideoUrlLanguageAction,
  saveVideoLanguageAction,
  saveVideoPreviewLanguageAction,
  saveVideoPreviewUrlsAction,
  saveVideoUrlLanguageAction,
  saveVideosAction,
  saveVideosDownloadUrlsAction,
  saveVideosThumbNailUrlsAction,
  saveVideosUrlsAction,
  saveVideosrelatedAction,
  translateLanguageAction,
  updateCommonsUrlsAction,
  updateDownloadVideoUrlLanguageAction,
  updateVideoLanguageAction,
  updateVideoPreviewLanguageAction,
  updateVideoPreviewUrlsAction,
  updateVideoUrlLanguageAction,
  updateVideosAction,
  updateVideosDownloadUrlsAction,
  updateVideosThumbNailUrlsAction,
  updateVideosUrlsAction,
  uploadAssestsAction,
  uploadAssestsV2Action,
  uploadVideosV2Action,
} from "../../../store/Languages/LanguageAction";
import Toastiy from "../../../components/universal/Toastiy";
import { useNavigate } from "react-router-dom";
import {
  deleteVideoLayoutAction,
  readVideoLayoutAction,
  saveVideoLayoutAction,
  updateVideoLayoutAction,
} from "../../../store/Layout/LayoutAction";
import { setShowNavigationAlertAction } from "../../../store/GlobalLoader/GlobalLoaderAction";
import { sendCustomNotificationAction } from "../../../store/Notifications/NotificationAction";

const VideosHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export default VideosHooks;

export const AllVideosHooks = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const [reloadData, setReloadData] = useState(false);

  const fetchAllVideos = (page, limit, searchCriteria = {}, callbackFn) => {
    console.log(
      "page",
      page,
      "limit",
      limit,
      "searchCriteria",
      searchCriteria,
      "callbackFn",
      callbackFn
    );
    dispatch(
      readVideosAction({
        apiPayloadRequest: {
          page: page,
          limit: limit,
          searchCriteria: searchCriteria,
        },
        callback: (Data) => {
          if (Data?.type === 1) {
            if (callbackFn)
              callbackFn({
                data: Data?.data?.map((d) => ({ ...d, views: d?.views ?? 0 })),
                total: Data?.totalRecords ?? 0,
              });
          } else if (callbackFn) callbackFn({ data: [], total: Data?.totalRecords ?? 0 });
        },
      })
    );
  };

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const handleDelete = (row, index) => {
    dispatch(
      deleteVideosAction({
        apiPayloadRequest: { _id: row?._id },
        callback: (res) => {
          setReloadData(true);
          getVideoDeletedResponse(res, "Video Deleted Successfully");
        },
      })
    );
  };

  const getVideoDeletedResponse = (data, message) => {
    if (data?.type === 1) {
      Toastiy(message, data?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", data?.type);
    }
  };
  return {
    value,
    onChangeSearchBar,
    handleDelete,
    fetchAllVideos,
    reloadData,
    setReloadData,
  };
};

export const AddVideosHooks = (setLoading, data) => {
  // search logic here
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [activeTab, setActiveTab] = useState("en");
  const [activeLanguageDetails, setActiveLanguageDetails] = useState({
    language_code: "en",
    language_name: "English",
  });
  const [allLanguageData, setAllLanguageData] = useState([]);
  const [readLanguageData, setReadLanguageData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedRelatedVideoIndex, setSelectedRelatedVideoIndex] = useState(null);
  const [relatedVideos, setRelatedVideos] = useState([]);
  const dispatch = useDispatch();

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };
  // add category logic here

  const initialLayoutValues = {
    _id: "",
    mobile_layout: "1",
    layout_name: "",
    order: "",
    // title: "",
    // subtitle: "",
    // expiry: "",
  };

  const initialVideoValues = {
    video_title: "",
    video_description: "",
    _id: "",
  };

  const [layoutValues, setLayoutValues] = useState(initialLayoutValues);
  const [addedLayout, setAddedLayout] = useState(false);

  const initialStateValues = {
    mobileUploadUrl: "",
    webUploadUrl: "",
    tabletUploadUrl: "",
  };

  const languages = [
    "English",
    "hindi",
    "tamil",
    "telugu",
    "Malayalam",
    "Spanish",
    "Marathi",
    "Urdu",
    "Oriya",
    "kanada",
    "Bengali",
  ]; // List of languages

  console.log(activeTab, "activeTab");
  const [downloadValues, setDownloadValues] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [thumbNailValues, setThumbNailValues] = useState("");
  const [thumbNailUrl, setThumbNailUrl] = useState("");
  const [inputs, setInputs] = useState("");
  const [showVideoLibraryModal, setShowVideoLibraryModal] = useState(false);
  const [videoInputs, setVideoInputs] = useState(initialVideoValues);
  const [videoErrors, setVideoErrors] = useState({
    video_title: false,
    video_description: false,
    video_urls: false,
    video_download_urls: false,
    video_thumbnails_urls: false,
    video_common_thumbnails_urls: false,
    video_preview_urls: false,
    selectedOptions: false,
  });

  const [currentInputs, setCurrentInputs] = useState();
  const [languagemeta, setLanguagemeta] = useState();

  const [videoUrlsInputs, setVideoUrlsInputs] = useState(initialStateValues);
  const [UdownloadrlsInputs, setDownloadUrlsInputs] = useState(initialStateValues);
  const [tumbNailUrlsInputs, setTumbNailUrlsInputs] = useState(initialStateValues);
  const [commonVideoUrlsInputs, setCommonUrlsInputs] = useState(initialStateValues);
  const [previewUrlsInputs, setPreviewUrlsInputs] = useState(initialStateValues);

  const [videoUrlsInputsCheck, setVideoUrlsInputsCheck] = useState();
  const [UdownloadrlsInputsCheck, setDownloadUrlsInputsCheck] = useState();
  const [tumbNailUrlsInputsCheck, setTumbNailUrlsInputsCheck] = useState();
  const [commonUrlsInputsCheck, setCommonUrlsInputsCheck] = useState();
  const [previewUrlsInputsCheck, setPreviewUrlsInputsCheck] = useState();
  const [layoutInfo, setLayoutInfo] = useState(false);
  const [layoutArray, setLayoutArray] = useState([]);
  const [isLayoutEdit, setIsLayoutEdit] = useState(false);
  const [editLayoutIndex, setEditLayoutIndex] = useState(null);
  const [editTargetLayout, setEditTargetLayout] = useState("");

  const navigate = useNavigate();

  console.log("videoInputs89", videoInputs);

  useEffect(() => {
    return () => setShowNavigationAlert(false);
  }, []);

  const setShowNavigationAlert = (value) => {
    dispatch(setShowNavigationAlertAction(value));
  };

  const combineStateKeys = () => {
    const values = Object.values({
      video_title: videoInputs?.video_title,
      video_description: videoInputs?.video_description,
    });
    const combinedString = values.join("///");
    return combinedString;
  };

  const updateStateWithTranslation = () => {
    const combinedText = combineStateKeys();
    console.log("combinedText", combinedText);

    dispatch(
      translateLanguageAction({
        apiPayloadRequest: {
          targetcode: activeTab,
          translatetext: combinedText,
        },
        callback: (res) => {
          if (res?.type === 1) {
            onTranslateSuccess(res?.data);
          } else if (res?.type === 2) {
            Toastiy("Language Translation failed!!");
            Toastiy(res?.message);
          }
        },
      })
    );
  };

  const onTranslateSuccess = (data) => {
    console.log(videoInputs, "myState");
    const arrayOfValues = data.split("///");
    const arrayOfKeys = Object.keys({
      video_title: videoInputs?.video_title,
      video_description: videoInputs?.video_description,
    });

    for (let key in arrayOfKeys) {
      setVideoInputs((preState) => ({
        ...preState,
        [arrayOfKeys[key]]: arrayOfValues[key],
      }));
    }
    setShowNavigationAlert(true);
  };

  const getRelatedVideos = (response) => {
    if (response?.type === 1) {
      response?.data?.map((item) =>
        setOptions((prevData) => [
          ...prevData,
          {
            value: item?._id || "",
            label: item?.video_title || "",
            image: item?.tumbnailurls?.length > 0 ? item?.tumbnailurls[0]?.url_link : "",
          },
        ])
      );
      console.log(response, "response");
    } else {
      Toastiy("Failed To Get Related Videos Data");
    }
  };

  const handleAddLayoutToArray = () => {
    if (videoInputs?._id !== undefined && videoInputs?._id !== "") {
      dispatch(
        saveVideoLayoutAction({
          apiPayloadRequest: {
            _id: layoutValues?._id,
            videoObjId: videoInputs?._id,
            order: layoutValues?.order,
            // expiry: layoutValues?.expiry,
            // title: layoutValues?.title,
            // subtitle: layoutValues?.subtitle,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Layout Added Successfully!", 1);
              setLayoutArray((prev) => [...prev, layoutValues]);
              setLayoutValues(initialLayoutValues);
              setAddedLayout(true);
            }
          },
        })
      );
    } else {
      setLayoutArray((prev) => [...prev, layoutValues]);
      setLayoutValues(initialLayoutValues);
      setAddedLayout(true);
    }
  };

  const handleDeleteLayoutFromArray = (id) => {
    if (videoInputs?._id !== undefined && videoInputs?._id !== "") {
      dispatch(
        deleteVideoLayoutAction({
          apiPayloadRequest: {
            _id: id,
            videoObjId: videoInputs?._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const updatedArray = layoutArray.filter((item) => item._id !== id);
              setLayoutArray(updatedArray);
              Toastiy("Layout Deleted Successfully!", 1);
            }
          },
        })
      );
    } else {
      const updatedArray = layoutArray.filter((item) => item._id !== id);
      setLayoutArray(updatedArray);
    }
  };

  const handleLayoutUpdate = () => {
    if (videoInputs?._id !== undefined && videoInputs?._id !== "") {
      dispatch(
        updateVideoLayoutAction({
          apiPayloadRequest: {
            _id: layoutValues?._id,
            videoObjId: videoInputs?._id,
            order: layoutValues?.order,
            // title: layoutArray._id,
            // subtitle: layoutArray._id,
            // expiry: layoutArray._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setLayoutValues(initialLayoutValues);
              setIsLayoutEdit(false);
              Toastiy("Layout Updated Successfully!", 1);
              setShowNavigationAlert(false);
            }
          },
        })
      );
    } else {
      setLayoutValues(initialLayoutValues);
      setIsLayoutEdit(false);
      setShowNavigationAlert(true);
    }
  };

  const handleDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
    setShowNavigationAlert(true);
  };

  const handleVideoLayoutChange = (event) => {
    if (isLayoutEdit) {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));

      setLayoutArray((prev) => {
        const updatedArray = [...prev];

        updatedArray[editLayoutIndex] = {
          ...updatedArray[editLayoutIndex],
          [name]: value,
        };
        return updatedArray;
      });
    } else {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));
      setShowNavigationAlert(true);
    }
  };

  const handleVideoDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
    setShowNavigationAlert(true);
  };

  const handleDropdownChange = (selectedValues) => {
    console.log("selectedValues", selectedValues);
    console.log("selectedOptions", selectedOptions);
    console.log("selectedRelatedVideoIndex", selectedRelatedVideoIndex);
    const videoItem = {
      value: selectedValues?._id,
      label: selectedValues?.video_title,
      image:
        selectedValues?.tumbnailurls?.length > 0 ? selectedValues?.tumbnailurls[0]?.url_link : "",
      order:
        selectedRelatedVideoIndex !== null
          ? selectedOptions[selectedRelatedVideoIndex]?.order
          : selectedOptions?.length + 1,
    };

    if (data?._id !== "" && data?._id !== undefined) {
      if (selectedRelatedVideoIndex !== null && selectedOptions[selectedRelatedVideoIndex]?.value) {
        dispatch(
          deleteVideosrelatedAction({
            apiPayloadRequest: {
              _id: data?._id,
              videoObjId: selectedOptions[selectedRelatedVideoIndex]?.value,
            },
            callback: (res) => {
              if (res?.type === 1) {
                //show message
                dispatch(
                  saveVideosrelatedAction({
                    apiPayloadRequest: {
                      _id: data?._id,
                      videoObjId: videoItem?.value,
                    },
                    callback: (res) => {
                      if (res?.type === 1) {
                        Toastiy("Related Video Updated Successfully!", 1);
                        setSelectedOptions((prev) =>
                          prev?.map((d, i) => (i === selectedRelatedVideoIndex ? videoItem : d))
                        );
                      } else {
                        Toastiy("Failed to Update Video");
                      }
                    },
                  })
                );
              }
            },
          })
        );
      } else {
        dispatch(
          saveVideosrelatedAction({
            apiPayloadRequest: {
              _id: data?._id,
              videoObjId: videoItem?.value,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Related Video Added Successfully!", 1);
                setSelectedOptions((prev) => [...prev, videoItem]);
              } else {
                Toastiy("Failed to Add Video");
              }
            },
          })
        );
      }
    } else {
      if (selectedRelatedVideoIndex !== null) {
        setSelectedOptions((prev) =>
          prev?.map((d, i) => (i === selectedRelatedVideoIndex ? videoItem : d))
        );
      } else {
        setSelectedOptions((prev) => [...prev, videoItem]);
      }
      setShowNavigationAlert(true);
    }
    setShowVideoLibraryModal(false);
    setVideoErrors({ ...videoErrors, selectedOptions: false });
    setSelectedRelatedVideoIndex(null);
  };

  const deleteRelatedVideo = (videoObjId) => {
    if (data?._id) {
      dispatch(
        deleteVideosrelatedAction({
          apiPayloadRequest: {
            _id: data?._id,
            videoObjId: videoObjId,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Related Video Removed Successfully!", 1);
              setSelectedOptions((prev) => prev.filter((item) => item.value !== videoObjId));
            } else {
              Toastiy("Failed to Delete Video");
            }
          },
        })
      );
    } else {
      setSelectedOptions((prev) => prev.filter((item) => item.value !== videoObjId));
    }
    setSelectedRelatedVideoIndex(null);
  };

  console.log("previewUrlsInputsCheck", previewUrlsInputsCheck);
  console.log("videoUrlsInputsCheck", videoUrlsInputsCheck);
  console.log("UdownloadrlsInputsCheck", UdownloadrlsInputsCheck);

  const getAllLanguageResponse = (response) => {
    if (response?.type === 1) {
      setReadLanguageData(response?.data);
      setLoading(false);
    }
  };

  const handleInputChange = (event, setState) => {
    const { name, value } = event.target;
    setState((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setVideoErrors({
      ...videoErrors,
      video_urls: false,
      video_download_urls: false,
      video_thumbnails_urls: false,
      video_common_thumbnails_urls: false,
      video_preview_urls: false,
    });
    setShowNavigationAlert(true);
  };

  const handleDownloadInputChange = (event) => {
    const { name, value } = event.target;
    setDownloadUrlsInputs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setVideoErrors({ ...videoErrors, video_download_urls: false });
    setShowNavigationAlert(true);
  };

  const handleTumbNailInputChange = (event) => {
    const { name, value } = event.target;
    setTumbNailUrlsInputs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setVideoErrors({ ...videoErrors, video_thumbnails_urls: false });
    setShowNavigationAlert(true);
  };

  const handleCommonTumbNailInputChange = (event) => {
    const { name, value } = event.target;
    setCommonUrlsInputs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setVideoErrors({ ...videoErrors, video_thumbnails_urls: false });
    setShowNavigationAlert(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVideoInputs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setVideoErrors({ ...videoErrors, [name]: false });
    setShowNavigationAlert(true);
  };

  const handleFileInputChange = (
    setState,
    keyName,
    file,
    type,
    uploadProgressId = null,
    downloadable = false,
    errorKeyName = ""
  ) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("downloadable", downloadable);
    if (type === "videos") {
      dispatch(
        uploadVideosV2Action({
          apiPayloadRequest: formData,
          uploadProgressId: uploadProgressId,
          callback: (res) => {
            setState((prevInputs) => ({
              ...prevInputs,
              [keyName]: res?.url,
            }));
            setVideoErrors({ ...videoErrors, [errorKeyName]: false });
            setLoading(false);
            setShowNavigationAlert(true);
          },
        })
      );
    } else {
      dispatch(
        uploadAssestsV2Action({
          apiPayloadRequest: formData,
          uploadProgressId: uploadProgressId,
          callback: (res) => {
            setState((prevInputs) => ({
              ...prevInputs,
              [keyName]: res?.url,
            }));
            setVideoErrors({ ...videoErrors, [errorKeyName]: false });
            setLoading(false);
            setShowNavigationAlert(true);
          },
        })
      );
    }
  };

  const handleFileDownloadInputChange = (language, field, file, value) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => {
          setDownloadUrl(res?.url);
          setDownloadUrlsInputs((prevInputs) => ({
            ...prevInputs,
            [field]: res?.url,
          }));
          setVideoErrors({ ...videoErrors, video_download_urls: false });
          setShowNavigationAlert(true);
        },
      })
    );
    setDownloadValues(value);
  };

  const handleFiletumbNailInputChange = (language, field, file, value) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: formData,
        callback: (res) => {
          setThumbNailUrl(res?.url);
          setTumbNailUrlsInputs((prevInputs) => ({
            ...prevInputs,
            [field]: res?.url,
          }));
          setVideoErrors({ ...videoErrors, video_thumbnails_urls: false });
          setShowNavigationAlert(true);
        },
      })
    );
    setThumbNailValues(value);
  };

  const handleRelatedVideosChange = (selectedOptions) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [activeTab]: {
        ...prevInputs[activeTab],
        relatedVideos: selectedOptions.map((option) => option.value),
      },
    }));
    setShowNavigationAlert(true);
    // setVideoErrors({ ...videoErrors, selectedOptions: false });
  };

  const handleVideoDescriptionChange = (e) => {
    handleInputChange(activeTab, "videoDescription", e.target.value);
    setShowNavigationAlert(true);
  };

  const videoData =
    videoUrlsInputs.mobileUploadUrl !== ""
      ? videoUrlsInputs.mobileUploadUrl
      : videoUrlsInputs.webUploadUrl !== ""
      ? videoUrlsInputs.webUploadUrl
      : videoUrlsInputs.tabletUploadUrl;

  const downloadData =
    UdownloadrlsInputs.mobileUploadUrl !== ""
      ? UdownloadrlsInputs.mobileUploadUrl
      : UdownloadrlsInputs.webUploadUrl !== ""
      ? UdownloadrlsInputs.webUploadUrl
      : UdownloadrlsInputs.tabletUploadUrl;

  const tumbNailData =
    tumbNailUrlsInputs.mobileUploadUrl !== ""
      ? tumbNailUrlsInputs.mobileUploadUrl
      : tumbNailUrlsInputs.webUploadUrl !== ""
      ? tumbNailUrlsInputs.webUploadUrl
      : tumbNailUrlsInputs.tabletUploadUrl;

  const handleSave = () => {
    if (videoInputs?._id === undefined || videoInputs?._id === "") {
      // validating fields
      let inputErrors = videoErrors;
      if (!videoInputs?.video_title) inputErrors = { ...inputErrors, video_title: true };
      if (!videoInputs?.video_description)
        inputErrors = { ...inputErrors, video_description: true };
      if (
        !videoUrlsInputs?.mobileUploadUrl ||
        !videoUrlsInputs?.tabletUploadUrl ||
        !videoUrlsInputs?.webUploadUrl
      )
        inputErrors = { ...inputErrors, video_urls: true };
      if (
        !UdownloadrlsInputs?.mobileUploadUrl ||
        !UdownloadrlsInputs?.tabletUploadUrl ||
        !UdownloadrlsInputs?.webUploadUrl
      )
        inputErrors = { ...inputErrors, video_download_urls: true };
      if (
        !tumbNailUrlsInputs?.mobileUploadUrl ||
        !tumbNailUrlsInputs?.tabletUploadUrl ||
        !tumbNailUrlsInputs?.webUploadUrl
      )
        inputErrors = { ...inputErrors, video_thumbnails_urls: true };
      // if (
      //   !commonVideoUrlsInputs?.mobileUploadUrl ||
      //   !commonVideoUrlsInputs?.tabletUploadUrl ||
      //   !commonVideoUrlsInputs?.webUploadUrl
      // )
      //   inputErrors = { ...inputErrors, video_common_thumbnails_urls: true };
      // if (
      //   !previewUrlsInputs?.mobileUploadUrl ||
      //   !previewUrlsInputs?.tabletUploadUrl ||
      //   !previewUrlsInputs?.webUploadUrl
      // )
      //   inputErrors = { ...inputErrors, video_preview_urls: true };
      // else if (!selectedOptions || selectedOptions?.length === 0)
      //   setVideoErrors({ ...videoErrors, selectedOptions: true });
      setVideoErrors({ ...videoErrors, ...inputErrors });
      if (Object.values(inputErrors)?.some((d) => d)) {
        return;
      } else {
        setLoading(true);
        dispatch(
          saveVideosAction({
            apiPayloadRequest: videoInputs,
            callback: getSaveVideosResponse,
          })
        );
      }
    } else {
      const id = videoInputs?._id;
      const keysArray = Object.keys(videoUrlsInputs);

      if (
        currentInputs?.video_title !== videoInputs?.video_title ||
        currentInputs?.video_description !== videoInputs?.video_description
      ) {
        setLoading(true);
        dispatch(
          updateVideosAction({
            apiPayloadRequest: videoInputs,
            callback: (response) => {
              if (response?.type === 1) {
                console.log(response);
                setTimeout(() => {
                  setLoading(false);
                  Toastiy("Video Updated Successfully!", 1);
                  navigate("/videos/all");
                }, 1000);
                setShowNavigationAlert(false);
              } else {
                Toastiy("Video Update Failed");
              }
              setLoading(false);
            },
          })
        );
      }

      // if (videoUrlsInputsCheck === '') {
      //   keysArray.map((item, index) => {
      //     setTimeout(() => {
      //       dispatch(saveVideosUrlsAction({
      //         apiPayloadRequest: {
      //           _id: id,
      //           type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
      //           url_link: videoUrlsInputs[item]
      //         },
      //         callback: (res) => {
      //           if (res?.type !== 1) {
      //             Toastiy("Failed to Video URLS")
      //           }
      //         }
      //       }))
      //     }, 500 * (index + 1))
      //   })
      // } else {
      keysArray.map((item, index) => {
        setTimeout(() => {
          if (videoUrlsInputs?.[item] !== videoUrlsInputsCheck?.[item]) {
            dispatch(
              updateVideosUrlsAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: videoUrlsInputs[item],
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to update Video URLS");
                  } else {
                    setShowNavigationAlert(false);
                  }
                },
              })
            );
          }
          if (!previewUrlsInputsCheck?.[item] && previewUrlsInputs[item]) {
            dispatch(
              saveVideoPreviewUrlsAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: previewUrlsInputs[item],
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to Update Preview URLS");
                  } else {
                    setShowNavigationAlert(false);
                  }
                },
              })
            );
          } else if (previewUrlsInputsCheck?.[item] !== previewUrlsInputs?.[item]) {
            dispatch(
              updateVideoPreviewUrlsAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: previewUrlsInputs[item],
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to Update Preview URLS");
                  } else {
                    setShowNavigationAlert(false);
                  }
                },
              })
            );
          }
        }, 500 * (index + 1));
      });
      // }

      // if (UdownloadrlsInputsCheck === '') {
      //   keysArray.map((item, index) => {
      //     setTimeout(() => {
      //       dispatch(saveVideosDownloadUrlsAction({
      //         apiPayloadRequest: {
      //           _id: id,
      //           type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
      //           url_link: UdownloadrlsInputs[item]
      //         },
      //         callback: (res) => {
      //           if (res?.type !== 1) {
      //             Toastiy("Failed to Video Download URLS")
      //           }
      //         }
      //       }))
      //     }, 1000 * (index + 1))
      //   })
      // } else {
      keysArray.map((item, index) => {
        setTimeout(() => {
          if (UdownloadrlsInputs?.[item] !== UdownloadrlsInputsCheck?.[item]) {
            dispatch(
              updateVideosDownloadUrlsAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: UdownloadrlsInputs[item],
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to update Video Download URLS");
                  } else {
                    setShowNavigationAlert(false);
                  }
                },
              })
            );
          }
        }, 1000 * (index + 1));
      });

      keysArray.map((item, index) => {
        setTimeout(() => {
          if (tumbNailUrlsInputsCheck?.[item] !== tumbNailUrlsInputs?.[item]) {
            dispatch(
              updateVideosThumbNailUrlsAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: tumbNailUrlsInputs[item],
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to update Tumbnail URLS");
                  } else {
                    setShowNavigationAlert(false);
                    Toastiy("Video Updated Successfully!", 1);
                  }
                },
              })
            );
          }

          if (!commonUrlsInputsCheck?.[item] && commonVideoUrlsInputs[item]) {
            dispatch(
              saveCommonsUrlsAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: commonVideoUrlsInputs[item],
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to update Common Thumbnail URLS");
                  } else {
                    setShowNavigationAlert(false);
                    Toastiy("Video Updated Common Thumbnail Successfully!", 1);
                  }
                },
              })
            );
          } else if (commonUrlsInputsCheck?.[item] !== commonVideoUrlsInputs?.[item]) {
            dispatch(
              updateCommonsUrlsAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: commonVideoUrlsInputs[item],
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to update Common Thumbnail URLS");
                  } else {
                    setShowNavigationAlert(false);
                    Toastiy("Video Common Thumbnail Updated Successfully!", 1);
                  }
                },
              })
            );
          }
        }, 1500 * (index + 1));
      });
      // }
    }
  };

  const getSaveVideosResponse = (response) => {
    const leng = response?.data?.length - 1;
    const id = response?.data[leng]?._id;
    const keysArray = Object.keys(videoUrlsInputs);

    if (response.type === 1) {
      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveVideosUrlsAction({
              apiPayloadRequest: {
                _id: id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: videoUrlsInputs[item],
              },
              callback: (res) => {
                if (res?.type !== 1) {
                  Toastiy("Failed to Video URLS");
                } else {
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        }, 500 * (index + 1));
      });

      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveVideosDownloadUrlsAction({
              apiPayloadRequest: {
                _id: id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: UdownloadrlsInputs[item],
              },
              callback: (res) => {
                if (res?.type !== 1) {
                  Toastiy("Failed to Video Download URLS");
                } else {
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        }, 1000 * (index + 1));
      });

      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveVideosThumbNailUrlsAction({
              apiPayloadRequest: {
                _id: id,
                type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                url_link: tumbNailUrlsInputs[item],
              },
              callback: (res) => {
                if (res?.type !== 1) {
                  Toastiy("Failed to Thumbnail URLS");
                } else {
                  setShowNavigationAlert(false);
                  // Toastiy("Video Saved Successfully!", 1);
                }
              },
            })
          );
        }, 500 * (index + 1));
      });

      keysArray.map((item, index) => {
        setTimeout(() => {
          if (commonVideoUrlsInputs[item]) {
            dispatch(
              saveCommonsUrlsAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: commonVideoUrlsInputs[item],
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to Thumbnail URLS");
                  } else {
                    setShowNavigationAlert(false);
                    // Toastiy("Video Saved Successfully!", 1);
                  }
                },
              })
            );
          }
        }, 600 * (index + 1));
      });

      keysArray.map((item, index) => {
        setTimeout(() => {
          if (previewUrlsInputs[item]) {
            dispatch(
              saveVideoPreviewUrlsAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                  url_link: previewUrlsInputs[item],
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to Preview URLS");
                  } else {
                    setShowNavigationAlert(false);
                    // Toastiy("Video Saved Successfully!", 1);
                  }
                },
              })
            );
          }
        }, 600 * (index + 1));
      });

      selectedOptions.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveVideosrelatedAction({
              apiPayloadRequest: {
                _id: id,
                videoObjId: item?.value,
              },
            })
          );
        }, 600 * (index + 1));
      });

      layoutArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveVideoLayoutAction({
              apiPayloadRequest: {
                _id: item?._id,
                videoObjId: id,
                order: item?.order,
                // expiry: item?.expiry,
                // title: item?.title,
                // subtitle: item?.subtitle,
              },
              callback: (res) => {
                console.log(res);
              },
            })
          );
        }, 700 * (index + 1));
      });
      setTimeout(() => {
        setLoading(false);
        Toastiy("Video Updated Successfully!", 1);
        navigate("/videos/all");
      }, 3000);
    } else {
      Toastiy("Failed To Save Video");
    }
    setLoading(false);
  };

  // mobileUploadUrl: '',
  // webUploadUrl: '',
  // tabletUploadUrl: '', saveVideosThumbNailUrlsAction

  const getSaveVideosResponse1 = (data, id, message) => {
    if (data?.type === 1) {
      Toastiy(message, data?.type);
      dispatch(
        saveVideosDownloadUrlsAction({
          apiPayloadRequest: {
            _id: id,
            type_of_url: downloadValues,
            url_link: downloadData,
          },
          callback: (res) =>
            getSaveVideosResponse2(res, id, "Video Download Url Saved Sucessfully."),
        })
      );
    } else {
      Toastiy("Issue performing the operation, contact admin!!", data?.type);
    }
  };

  const getSaveVideosResponse2 = (data, id, message) => {
    if (data?.type === 1) {
      Toastiy(message, data?.type);
      dispatch(
        saveVideosThumbNailUrlsAction({
          apiPayloadRequest: {
            _id: id,
            type_of_url: thumbNailValues,
            url_link: tumbNailData,
          },
          callback: (res) => getSaveVideosResponse3(res, "Video Tumbnail Url Saved Sucessfully."),
        })
      );
    } else {
      Toastiy("Issue performing the operation, contact admin!!", data?.type);
    }
  };

  const getSaveVideosResponse3 = (data, message) => {
    if (data?.type === 1) {
      Toastiy(message, data?.type);
    } else {
      Toastiy("Issue performing the operation, contact admin!!", data?.type);
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      readLanguageAction({
        callback: getAllLanguageResponse,
      })
    );
  }, []);

  const fetchVideoDetails = () => {
    dispatch(
      readVideosAction({
        apiPayloadRequest: {
          _id: data?._id,
        },
        callback: (res) => {
          const data = res?.data[0];
          // setVideoInputs({
          //   _id: data?._id,
          //   video_title: data?.video_title || "",
          //   video_description: data?.video_description || "",
          // });

          setCurrentInputs({
            _id: data?._id,
            video_title: data?.video_title || "",
            video_description: data?.video_description || "",
            totalLikes: data?.totallikes || 0,
            totalViews: data?.video_views || 0,
          });

          setLanguagemeta(data?.languagemetas);
          // setVideoUrlsInputs({
          //   mobileUploadUrl:
          //     data?.urls?.filter((item) => item.type_of_url === "0")[0]
          //       .url_link || "",
          //   webUploadUrl:
          //     data?.urls?.filter((item) => item.type_of_url === "1")[0]
          //       .url_link || "",
          //   tabletUploadUrl:
          //     data?.urls?.filter((item) => item.type_of_url === "2")[0]
          //       .url_link || "",
          // });

          setVideoUrlsInputsCheck({
            mobileUploadUrl:
              data?.urls?.filter((item) => item.type_of_url === "0")[0].url_link || "",
            webUploadUrl: data?.urls?.filter((item) => item.type_of_url === "1")[0].url_link || "",
            tabletUploadUrl:
              data?.urls?.filter((item) => item.type_of_url === "2")[0].url_link || "",
          });

          // setDownloadUrlsInputs({
          //   mobileUploadUrl:
          //     data?.downloadurls?.filter(
          //       (item) => item.type_of_url === "0"
          //     )[0].url_link || "",
          //   webUploadUrl:
          //     data?.downloadurls?.filter(
          //       (item) => item.type_of_url === "1"
          //     )[0].url_link || "",
          //   tabletUploadUrl:
          //     data?.downloadurls?.filter(
          //       (item) => item.type_of_url === "2"
          //     )[0].url_link || "",
          // });

          setDownloadUrlsInputsCheck({
            mobileUploadUrl:
              data?.downloadurls?.filter((item) => item.type_of_url === "0")[0].url_link || "",
            webUploadUrl:
              data?.downloadurls?.filter((item) => item.type_of_url === "1")[0].url_link || "",
            tabletUploadUrl:
              data?.downloadurls?.filter((item) => item.type_of_url === "2")[0].url_link || "",
          });

          setTumbNailUrlsInputsCheck({
            mobileUploadUrl:
              data?.tumbnailurls?.filter((item) => item.type_of_url === "0")[0].url_link || "",
            webUploadUrl:
              data?.tumbnailurls?.filter((item) => item.type_of_url === "1")[0].url_link || "",
            tabletUploadUrl:
              data?.tumbnailurls?.filter((item) => item.type_of_url === "2")[0].url_link || "",
          });

          setCommonUrlsInputsCheck({
            mobileUploadUrl:
              data?.gnerictumbnailurls?.filter((item) => item.type_of_url === "0")[0].url_link ||
              "",
            webUploadUrl:
              data?.gnerictumbnailurls?.filter((item) => item.type_of_url === "1")[0].url_link ||
              "",
            tabletUploadUrl:
              data?.gnerictumbnailurls?.filter((item) => item.type_of_url === "2")[0].url_link ||
              "",
          });

          setPreviewUrlsInputsCheck({
            mobileUploadUrl:
              data?.trailers?.filter((item) => item.type_of_url === "0")[0].url_link || "",
            webUploadUrl:
              data?.trailers?.filter((item) => item.type_of_url === "1")[0].url_link || "",
            tabletUploadUrl:
              data?.trailers?.filter((item) => item.type_of_url === "2")[0].url_link || "",
          });

          // const isValidRelatedVideos = data?.relatedvideos?.find((v) => v?.videoObjId?.length > 0);
          // const relatedVideos = isValidRelatedVideos ? data?.relatedvideos : [];
          const relatedVideos = data?.relatedvideos ?? [];
          const relatedVideosList = [];
          relatedVideos.map((item) => {
            relatedVideosList.push({
              value: item?.videoObjId?._id,
              label: item?.videoObjId?.video_title,
              image: item?.videoObjId?.tumbnailurls[0].url_link,
            });
          });
          setSelectedOptions(relatedVideosList);
        },
      })
    );
  };

  const fetchVideoLayouts = () => {
    dispatch(
      readVideoLayoutAction({
        apiPayloadRequest: {
          videoObjId: data?._id,
        },
        callback: (res) => {
          if (res?.type === 1) {
            console.log("res?.data", res?.data);
            res?.data.forEach((layout) => {
              const requiredData = layout.videos.filter((item) => item.videoObjId === data?._id);

              const requiredId = layout._id;
              const requiredMobileLayout = layout.mobile_layout;
              const requiredName = layout.layout_name;

              requiredData.map((item) => {
                setLayoutArray((prev) => [
                  ...prev,
                  {
                    _id: requiredId,
                    mobile_layout: requiredMobileLayout,
                    layout_name: requiredName,
                    order: item.order,
                    // title: item.title,
                    // subtitle: item.subtitle,
                    // expiry: item.expiry,
                  },
                ]);
              });
            });
          }
        },
      })
    );
  };

  useEffect(() => {
    if (data?._id !== "" && data?._id !== undefined) {
      // setTimeout(() => {
      //   dispatch(readVideosAction({ callback: getRelatedVideos }));
      // }, 2000);

      fetchVideoDetails();
      fetchVideoLayouts();
    } else {
      dispatch(readVideosAction({ callback: getRelatedVideos }));
    }
  }, []);

  const [isLanguageSave, setIsLanguageSave] = useState(true);
  const [currentLanguageInputs, setCurrentLanguageInputs] = useState();
  const [currentLanguageUrlCheck, setCurrentLanguageUrlCheck] = useState();
  const [currentLanguageDownloadUrlCheck, setCurrentLanguageDownloadUrlCheck] = useState();
  const [currentLanguagePreviewUrlCheck, setCurrentLanguagePreviewUrlCheck] = useState();

  console.log("currentLanguageInputs", currentLanguageInputs);
  console.log("currentLanguageDownloadUrlCheck", currentLanguageDownloadUrlCheck);
  console.log("currentLanguageUrlCheck", currentLanguageUrlCheck);
  console.log("currentLanguagePreviewUrlCheck", currentLanguagePreviewUrlCheck);

  useEffect(() => {
    if (activeTab === "en") {
      if (currentInputs) {
        setVideoInputs(currentInputs);
      }
      if (videoUrlsInputsCheck) {
        setVideoUrlsInputs(videoUrlsInputsCheck);
      }
      if (UdownloadrlsInputsCheck) {
        setDownloadUrlsInputs(UdownloadrlsInputsCheck);
      }
      if (tumbNailUrlsInputsCheck) {
        setTumbNailUrlsInputs(tumbNailUrlsInputsCheck);
      }
      if (commonUrlsInputsCheck) {
        setCommonUrlsInputs(commonUrlsInputsCheck);
      }
      if (previewUrlsInputsCheck) {
        setPreviewUrlsInputs(previewUrlsInputsCheck);
      }
    } else {
      const isPresent = languagemeta?.some((language) => language?.language_code === activeTab);
      if (isPresent) {
        const data = languagemeta?.filter((item) => item?.language_code === activeTab)[0];

        setVideoInputs((prev) => ({
          ...prev,
          video_title: data?.video_title || "",
          video_description: data?.video_description || "",
        }));
        setCurrentLanguageInputs((prev) => ({
          ...prev,
          video_title: data?.video_title || "",
          video_description: data?.video_description || "",
        }));

        if (data?.urls?.length > 0) {
          setVideoUrlsInputs({
            mobileUploadUrl:
              data?.urls?.filter((item) => item?.type_of_url === "0")?.[0]?.url_link || "",
            webUploadUrl:
              data?.urls?.filter((item) => item?.type_of_url === "1")?.[0]?.url_link || "",
            tabletUploadUrl:
              data?.urls?.filter((item) => item?.type_of_url === "2")?.[0]?.url_link || "",
          });
          setCurrentLanguageUrlCheck({
            mobileUploadUrl:
              data?.urls?.filter((item) => item?.type_of_url === "0")?.[0]?.url_link || "",
            webUploadUrl:
              data?.urls?.filter((item) => item?.type_of_url === "1")?.[0]?.url_link || "",
            tabletUploadUrl:
              data?.urls?.filter((item) => item?.type_of_url === "2")?.[0]?.url_link || "",
          });
        } else {
          setVideoUrlsInputs(initialStateValues);
          setCurrentLanguageUrlCheck(initialStateValues);
        }
        if (data?.downloadurls?.length > 0) {
          setDownloadUrlsInputs({
            mobileUploadUrl:
              data?.downloadurls?.filter((item) => item?.type_of_url === "0")?.[0]?.url_link || "",
            webUploadUrl:
              data?.downloadurls?.filter((item) => item?.type_of_url === "1")?.[0]?.url_link || "",
            tabletUploadUrl:
              data?.downloadurls?.filter((item) => item?.type_of_url === "2")?.[0]?.url_link || "",
          });
          setCurrentLanguageDownloadUrlCheck({
            mobileUploadUrl:
              data?.downloadurls?.filter((item) => item?.type_of_url === "0")?.[0]?.url_link || "",
            webUploadUrl:
              data?.downloadurls?.filter((item) => item?.type_of_url === "1")?.[0]?.url_link || "",
            tabletUploadUrl:
              data?.downloadurls?.filter((item) => item?.type_of_url === "2")?.[0]?.url_link || "",
          });
        } else {
          setDownloadUrlsInputs(initialStateValues);
          setCurrentLanguageDownloadUrlCheck(initialStateValues);
        }
        if (data?.trailers?.length > 0) {
          setPreviewUrlsInputs({
            mobileUploadUrl:
              data?.trailers?.filter((item) => item?.type_of_url === "0")?.[0]?.url_link || "",
            webUploadUrl:
              data?.trailers?.filter((item) => item?.type_of_url === "1")?.[0]?.url_link || "",
            tabletUploadUrl:
              data?.trailers?.filter((item) => item?.type_of_url === "2")?.[0]?.url_link || "",
          });
          setCurrentLanguagePreviewUrlCheck({
            mobileUploadUrl:
              data?.trailers?.filter((item) => item?.type_of_url === "0")?.[0]?.url_link || "",
            webUploadUrl:
              data?.trailers?.filter((item) => item?.type_of_url === "1")?.[0]?.url_link || "",
            tabletUploadUrl:
              data?.trailers?.filter((item) => item?.type_of_url === "2")?.[0]?.url_link || "",
          });
        } else {
          setPreviewUrlsInputs(initialStateValues);
          setCurrentLanguagePreviewUrlCheck(initialStateValues);
        }
      } else {
        setVideoInputs(currentInputs);
        setCurrentLanguageInputs(currentInputs);
        setVideoUrlsInputs(initialStateValues);
        setCurrentLanguageUrlCheck(initialStateValues);
        setDownloadUrlsInputs(initialStateValues);
        setCurrentLanguageDownloadUrlCheck(initialStateValues);
        setPreviewUrlsInputs(initialStateValues);
        setCurrentLanguagePreviewUrlCheck(initialStateValues);
      }
    }
  }, [
    activeTab,
    videoUrlsInputsCheck,
    UdownloadrlsInputsCheck,
    tumbNailUrlsInputsCheck,
    commonUrlsInputsCheck,
    previewUrlsInputsCheck,
  ]);

  console.log("isLanguageSave", isLanguageSave);

  const handleSaveLanguage = async () => {
    const requestsList = [];
    if (isLanguageSave) {
      const keysArray = Object.keys(videoUrlsInputs);

      requestsList.push(
        new Promise((resolve, reject) => {
          dispatch(
            saveVideoLanguageAction({
              apiPayloadRequest: {
                _id: data?._id,
                language_code: activeTab,
                video_title: videoInputs?.video_title,
                video_description: videoInputs?.video_description,
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Video Language Saved Successfully", 1);
                  setShowNavigationAlert(false);
                }
                resolve();
              },
            })
          );
        })
      );

      keysArray.map((item, index) => {
        requestsList.push(
          new Promise((resolve, reject) => {
            setTimeout(() => {
              if (videoUrlsInputs[item]) {
                dispatch(
                  saveVideoUrlLanguageAction({
                    apiPayloadRequest: {
                      _id: data?._id,
                      language_code: activeTab,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: videoUrlsInputs[item],
                    },
                    callback: (res) => {
                      if (res?.type === 1) {
                        Toastiy("Video URLs Saved Successfully", 1);
                        setShowNavigationAlert(false);
                      }
                      resolve();
                    },
                  })
                );
              } else {
                resolve();
              }
            }, (index + 1) * 500);
          })
        );
      });

      keysArray.map((item, index) => {
        requestsList.push(
          new Promise((resolve, reject) => {
            setTimeout(() => {
              if (UdownloadrlsInputs[item]) {
                dispatch(
                  saveDownloadVideoUrlLanguageAction({
                    apiPayloadRequest: {
                      _id: data?._id,
                      language_code: activeTab,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: UdownloadrlsInputs[item],
                    },
                    callback: (res) => {
                      if (res?.type === 1) {
                        Toastiy("Video Download URLs Saved Successfully", 1);
                        setShowNavigationAlert(false);
                      }
                      resolve();
                    },
                  })
                );
              } else {
                resolve();
              }
            }, (index + 1) * 600);
          })
        );
      });

      keysArray.map((item, index) => {
        requestsList.push(
          new Promise((resolve, reject) => {
            setTimeout(() => {
              if (previewUrlsInputs[item]) {
                dispatch(
                  saveVideoPreviewLanguageAction({
                    apiPayloadRequest: {
                      _id: data?._id,
                      language_code: activeTab,
                      type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                      url_link: previewUrlsInputs[item],
                    },
                    callback: (res) => {
                      if (res?.type === 1) {
                        Toastiy("Video Preview URLs Saved Successfully", 1);
                        setShowNavigationAlert(false);
                      }
                      resolve();
                    },
                  })
                );
              } else {
                resolve();
              }
            }, (index + 1) * 650);
          })
        );
      });
    } else {
      const keysArray = Object.keys(videoUrlsInputs);

      if (
        videoInputs?.video_title !== currentLanguageInputs?.video_title ||
        videoInputs?.video_description !== currentLanguageInputs?.video_description
      ) {
        requestsList.push(
          new Promise((resolve, reject) => {
            dispatch(
              updateVideoLanguageAction({
                apiPayloadRequest: {
                  _id: data?._id,
                  language_code: activeTab,
                  video_title: videoInputs?.video_title,
                  video_description: videoInputs?.video_description,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Video Language Updated Successfully", 1);
                    setShowNavigationAlert(false);
                  }
                  resolve();
                },
              })
            );
          })
        );
      }

      keysArray.map((item) => {
        if (!currentLanguageUrlCheck[item] && videoUrlsInputs[item]) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                saveVideoUrlLanguageAction({
                  apiPayloadRequest: {
                    _id: data?._id,
                    language_code: activeTab,
                    type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                    url_link: videoUrlsInputs[item],
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Video URLs Updated Successfully", 1);
                      setShowNavigationAlert(false);
                    }
                    resolve();
                  },
                })
              );
            })
          );
        } else if (
          videoUrlsInputs[item] &&
          videoUrlsInputs[item] !== currentLanguageUrlCheck[item]
        ) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                updateVideoUrlLanguageAction({
                  apiPayloadRequest: {
                    _id: data?._id,
                    language_code: activeTab,
                    type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                    url_link: videoUrlsInputs[item],
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Video URLs Updated Successfully", 1);
                      setShowNavigationAlert(false);
                    }
                    resolve();
                  },
                })
              );
            })
          );
        }
      });

      keysArray.map((item) => {
        if (!currentLanguageDownloadUrlCheck[item] && UdownloadrlsInputs[item]) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                saveDownloadVideoUrlLanguageAction({
                  apiPayloadRequest: {
                    _id: data?._id,
                    language_code: activeTab,
                    type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                    url_link: UdownloadrlsInputs[item],
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Video Download URLs Updated Successfully", 1);
                      setShowNavigationAlert(false);
                    }
                    resolve();
                  },
                })
              );
            })
          );
        } else if (
          UdownloadrlsInputs[item] &&
          UdownloadrlsInputs[item] !== currentLanguageDownloadUrlCheck[item]
        ) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                updateDownloadVideoUrlLanguageAction({
                  apiPayloadRequest: {
                    _id: data?._id,
                    language_code: activeTab,
                    type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                    url_link: UdownloadrlsInputs[item],
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Video Download URLs Updated Successfully", 1);
                      setShowNavigationAlert(false);
                    }
                    resolve();
                  },
                })
              );
            })
          );
        }
      });

      keysArray.map((item) => {
        if (!currentLanguagePreviewUrlCheck[item] && previewUrlsInputs[item]) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                saveVideoPreviewLanguageAction({
                  apiPayloadRequest: {
                    _id: data?._id,
                    language_code: activeTab,
                    type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                    url_link: previewUrlsInputs[item],
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Video Preview URLs Updated Successfully", 1);
                      setShowNavigationAlert(false);
                    }
                    resolve();
                  },
                })
              );
            })
          );
        } else if (
          previewUrlsInputs[item] &&
          previewUrlsInputs[item] !== currentLanguagePreviewUrlCheck[item]
        ) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                updateVideoPreviewLanguageAction({
                  apiPayloadRequest: {
                    _id: data?._id,
                    language_code: activeTab,
                    type_of_url: item === "mobileUploadUrl" ? 0 : item === "webUploadUrl" ? 1 : 2,
                    url_link: previewUrlsInputs[item],
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Video Preview URLs Updated Successfully", 1);
                      setShowNavigationAlert(false);
                    }
                    resolve();
                  },
                })
              );
            })
          );
        }
      });
    }

    await Promise.all(requestsList);
    fetchVideoDetails();
  };

  const handleLibraryVideoSelection = (details) => {
    console.log("selected video details", details);
    setVideoInputs((prev) => ({
      ...prev,
      video_title: details?.video_title,
      video_description: details?.video_description,
    }));
    setVideoUrlsInputs({
      mobileUploadUrl: details?.urls?.find((d) => d?.type_of_url === "0")?.url_link,
      webUploadUrl: details?.urls?.find((d) => d?.type_of_url === "1")?.url_link,
      tabletUploadUrl: details?.urls?.find((d) => d?.type_of_url === "2")?.url_link,
    });
    setDownloadUrlsInputs({
      mobileUploadUrl: details?.downloadurls?.find((d) => d?.type_of_url === "0")?.url_link,
      webUploadUrl: details?.downloadurls?.find((d) => d?.type_of_url === "1")?.url_link,
      tabletUploadUrl: details?.downloadurls?.find((d) => d?.type_of_url === "2")?.url_link,
    });
    setTumbNailUrlsInputs({
      mobileUploadUrl: details?.tumbnailurls?.find((d) => d?.type_of_url === "0")?.url_link,
      webUploadUrl: details?.tumbnailurls?.find((d) => d?.type_of_url === "1")?.url_link,
      tabletUploadUrl: details?.tumbnailurls?.find((d) => d?.type_of_url === "2")?.url_link,
    });
    setCommonUrlsInputs({
      mobileUploadUrl: details?.gnerictumbnailurls?.find((d) => d?.type_of_url === "0")?.url_link,
      webUploadUrl: details?.gnerictumbnailurls?.find((d) => d?.type_of_url === "1")?.url_link,
      tabletUploadUrl: details?.gnerictumbnailurls?.find((d) => d?.type_of_url === "2")?.url_link,
    });
    setShowNavigationAlert(true);
  };

  const handleSendNotification = () => {
    dispatch(
      sendCustomNotificationAction({
        apiPayloadRequest: {
          _id: data?._id,
          type: "2",
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Notification Sent Successfully", 1);
          }
        },
      })
    );
  };

  return {
    value,
    onChangeSearchBar,
    languages,
    activeTab,
    setActiveTab,
    activeLanguageDetails,
    setActiveLanguageDetails,
    inputs,
    handleInputChange,
    handleFileInputChange,
    handleRelatedVideosChange,
    handleVideoDescriptionChange,
    handleSave,
    readLanguageData,
    videoInputs,
    videoErrors,
    showVideoLibraryModal,
    setShowVideoLibraryModal,
    setVideoUrlsInputs,
    setDownloadUrlsInputs,
    setTumbNailUrlsInputs,
    videoUrlsInputs,
    handleFileDownloadInputChange,
    handleFiletumbNailInputChange,
    tumbNailUrlsInputs,
    UdownloadrlsInputs,
    handleChange,
    handleTumbNailInputChange,
    handleDownloadInputChange,
    selectedOptions,
    setSelectedOptions,
    deleteRelatedVideo,
    setSelectedRelatedVideoIndex,
    handleDropdownChange,
    setLayoutValues,
    handleVideoLayoutChange,
    layoutValues,
    initialLayoutValues,
    setLayoutInfo,
    layoutInfo,
    handleDateChange,
    handleAddLayoutToArray,
    layoutArray,
    setAddedLayout,
    addedLayout,
    handleVideoDateChange,
    options,
    handleDeleteLayoutFromArray,
    setIsLayoutEdit,
    setEditLayoutIndex,
    isLayoutEdit,
    handleLayoutUpdate,
    setEditTargetLayout,
    editTargetLayout,
    updateStateWithTranslation,
    handleCommonTumbNailInputChange,
    setCommonUrlsInputs,
    commonVideoUrlsInputs,
    previewUrlsInputs,
    setPreviewUrlsInputs,
    languagemeta,
    setIsLanguageSave,
    handleSaveLanguage,
    handleLibraryVideoSelection,
    currentInputs,
    handleSendNotification
  };
};
