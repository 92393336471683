import React from "react";
import SearchBar from "../../components/universal/SearchBar";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineFilter } from "react-icons/hi";
import Navbar from "../../components/universal/Navbar";
import LayoutHooks from "./Hooks/LayoutHooks";
import CustomTable from "../../components/universal/CustomTable";
import { allLayoutHeaders } from "../Languages/Constants";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import ConfirmToast from "../../components/universal/ConfirmToast";

function AllLayouts() {
  const navigate = useNavigate();

  const {
    value,
    onChangeSearchBar,
    allLayouts,
    handleDelete,
    fetchAllLayouts,
    reloadData,
    setReloadData,
  } = LayoutHooks();

  console.log("allLayouts", allLayouts);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium">Layouts</p>
              <div className="flex gap-4">
                {/* <Link to={"/allLanguages"}> */}
                {/* <button className="flex items-center gap-16 text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                  All
                  <HiOutlineFilter />
                </button> */}
                {/* </Link> */}
                <Link to={"/layout/add"}>
                  {" "}
                  <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
                    <AiOutlinePlus /> Add New Layout
                  </button>
                </Link>
              </div>
            </div>
            <div className="">
              <CustomTable
                searchFor={"Layouts"}
                heightDesktop={"h-[72vh]"}
                width={"w-24"}
                // giving extra width to 2nd column
                nthChildWidth={"[&>*:nth-child(2)]:w-40 [&>*:nth-child(4)]:w-40"}
                data={allLayouts}
                headers={allLayoutHeaders}
                customDataFetch={true}
                customDataFetchFunction={fetchAllLayouts}
                reloadData={reloadData}
                setReloadData={setReloadData}
                layoutTable={true}
                reverseTable={true}
                // handleToggleClick={(row, isActive) => {
                //     handleActiveInActiveToggle(row?._id, isActive ? 1 : 0)
                // }}
                handleEyeClick={(row) => {
                  navigate("/layout/add", {
                    state: {
                      _id: row._id,
                    },
                  });
                }}
                handleEditClick={(row) => {
                  navigate("/layout/add", {
                    state: {
                      _id: row._id,
                    },
                  });
                }}
                handleDeleteClick={(row) => {
                  const options = {
                    customUI: ({ onClose }) => {
                      return (
                        <ConfirmToast
                          title="Are you sure?"
                          message="You want to delete this layout?"
                          onClose={() => onClose()}
                          onSuccess={() => {
                            handleDelete(row);
                            onClose();
                          }}
                        />
                      );
                    },
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                    keyCodeForClose: [8, 32],
                    overlayClassName: "!bg-gray-500 !bg-opacity-75",
                  };

                  confirmAlert(options);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllLayouts;
