import HorizontalTile from "../../components/universal/HorizontalTile";
import avatar from "../../assets/icons/squareCard/story1.png";
import avatar2 from "../../assets/icons/StoryCircles/story1.png";

export const tabsTitleArray = ["Users", "Testimonies", "Givings"];
export const tabsContentObject = {
  Users: (
    <>
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
    </>
  ),
  Testimonies: (
    <>
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
      <HorizontalTile
        avatarImage={avatar2}
        headingText={"Simon Greeves"}
        headingColor={"#979592"}
        headingFontSize={"13px"}
        subHeadingText={"@simon89"}
        subHeadingColor={"#808191"}
        subHeadingFontSize={"12px"}
        date={"26 mo"}
        dateColor={"#979592"}
      />
    </>
  ),
};
export const languagesHeaders = [
  {
    headerName: "Language Name",
    headerId: "language_name",
  },
  {
    headerName: "Google Translation Code",
    headerId: "language_code",
  },
  {
    headerName: "Status",
    headerId: "status",
  },
];

export const userByLanguageHeader = [
  {
    headerName: "Language Name",
    headerId: "_id",
  },
  {
    headerName: "User Count",
    headerId: "count",
  },
];

export const layoutExpiryHeader = [
  {
    headerName: "Layout Name",
    headerId: "layout_name",
  },
  {
    headerName: "Days To Expiry",
    headerId: "daysToExpiry",
  },
];

export const mostUsedVideosHeader = [
  {
    headerName: "Title",
    headerId: "title",
  },
  {
    headerName: "Count",
    headerId: "count",
  },
];

export const mostUsedCategoriesHeader = [
  {
    headerName: "Title",
    headerId: "title",
  },
  {
    headerName: "Count",
    headerId: "count",
  },
];

export const mostLikedVideosHeader = [
  {
    headerName: "Title",
    headerId: "title",
  },
  {
    headerName: "Count",
    headerId: "likeCount",
  },
];

export const mostWatchedVideosHeader = [
  {
    headerName: "Title",
    headerId: "title",
  },
  {
    headerName: "Avg Watched",
    headerId: "averageWatched",
  },
];

export const treadingVideosHeader = [
  {
    headerName: "Title",
    headerId: "title",
  },
  {
    headerName: "Recent Likes",
    headerId: "recentLikes",
  },
];

export const mostUsedCurriculumsHeader = [
  {
    headerName: "Title",
    headerId: "title",
  },
  {
    headerName: "Count",
    headerId: "count",
  },
];

export const allLanguagesHeaders = [
  {
    headerName: "Language Name",
    headerId: "language_name",
  },
  {
    headerName: "Google Translation Code",
    headerId: "language_code",
  },
  {
    headerName: "Status",
    headerId: "status",
  },
  {
    headerName: "Actions",
    headerId: "actions",
  },
];

export const allLayoutHeaders = [
  {
    headerName: "Layout Name",
    headerId: "layout_name",
  },
  {
    headerName: "Order",
    headerId: "order",
  },
  {
    headerName: "Mobile Layout",
    headerId: "mobile_layout",
  },
  // {
  //   headerName: "Expiry",
  //   headerId: "expiry",
  //   date: true,
  // },
  {
    headerName: "Actions",
    headerId: "actions",
  },
];
export const allLanguagesData = [
  {
    languageName: "English",
    GTC: "en",
    status: "Active",
    defaultLanguage: "True",
  },
  {
    languageName: "Tamil",
    GTC: "te",
    status: "Inactive",
    defaultLanguage: "False",
  },
  {
    languageName: "Malayalam",
    GTC: "Mal",
    status: "Inactive",
    defaultLanguage: "True",
  },
  {
    languageName: "Urdu",
    GTC: "urdu",
    status: "Active",
    defaultLanguage: "True",
  },
];

// add languages const

export const langArray = [
  { value: "tamil", label: "Tamil" },
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
];
export const LoginInFields = [
  {
    name: "loginId_lbl",
    label: "Login",
    placeholder: "Login Id",
  },
  {
    name: "email_phone_lbl",
    label: "Email or Phone",
    placeholder: "Email or Phone",
  },
  {
    name: "password_lbl",
    label: "Password",
    placeholder: "Password",
  },
  {
    name: "forgot_Password_lbl",
    label: "Forgot Password",
    placeholder: "Submit",
  },
  {
    name: "continue_btn",
    label: "Continue",
    placeholder: "Continue",
  },
  {
    name: "continue_with_google_btn",
    label: "Continue with Google",
    placeholder: "Continue with Google",
  },
  {
    name: "continue_with_facebook_btn",
    label: "Continue with Facebook",
    placeholder: "Continue with Facebook",
  },
  {
    name: "continue_with_apple_btn",
    label: "Continue with Apple",
    placeholder: "Continue with Apple",
  },
  {
    name: "login_to_cotinue_lbl",
    label: "Login to Continue Label",
    placeholder: "Continue with Apple",
  },
  {
    name: "go_back_home_btn",
    label: "Go Back Home Button",
    placeholder: "Continue with Apple",
  },
  {
    name: "cancel_btn",
    label: "Cancel Button",
    placeholder: "Continue with Apple",
  },
];
export const SignUpFields = [
  {
    name: "signup_lbl",
    label: "Sign Up",
    placeholder: "Sign Up",
  },
  {
    name: "phone_lbl",
    label: "Phone",
    placeholder: "Phone",
  },
  {
    name: "emailid_lbl",
    label: "Email Id",
    placeholder: "Email Id",
  },
  {
    name: "password_lbl",
    label: " Password",
    placeholder: " Password",
  },
  {
    name: "repeatePassword_lbl",
    label: "Repeat Password",
    placeholder: "Repeat Password",
  },
  {
    name: "termsandcond_lbl",
    label: "I agree to the tearms & conditions and privacy policy",
    placeholder: "I agree to the tearms & conditions and privacy policy",
  },
  {
    name: "createAccount_btn",
    label: "Create account",
    placeholder: "Create account",
  },
];

export const ForgotPasswordFields = [
  {
    name: "forgotpassword_lbl",
    label: "Forgot Password",
    placeholder: "Forgot Password",
  },
  {
    name: "emailorphone_lbl",
    label: "Email Id",
    placeholder: "Email or Phone",
  },
  {
    name: "resetPassword_btn",
    label: "Reset Password",
    placeholder: "Reset Password",
  },
  {
    name: "cancel_btn",
    label: "Cancel",
    placeholder: "Cancel",
  },
];

export const HomePageFields = [
  {
    name: "welcome_lbl",
    label: "Welcome",
    placeholder: "Welcome",
  },
  {
    name: "todaywithgod_lbl",
    label: "Today with God",
    placeholder: "Today with God",
  },
  {
    name: "top10_lbl",
    label: "Top 10",
    placeholder: "Top 10",
  },
  {
    name: "seeall_btn",
    label: "See all",
    placeholder: "See all",
  },

  {
    name: "pick_where_you_are_left_lbl",
    label: "Pick where you are left",
    placeholder: "The book of john",
  },
  {
    name: "who_is_jesus_lbl",
    label: "who is jesus",
    placeholder: "See all",
  },
  {
    name: "parables_lbl",
    label: "Parables",
    placeholder: "The book of john",
  },
  {
    name: "jesus_speaks_lbl",
    label: "jesus speaks",
    placeholder: "The book of john",
  },
  {
    name: "promises_lbl",
    label: "Promises",
    placeholder: "The book of john",
  },
  {
    name: "testimony_of_the_day_lbl",
    label: "Testimony of the day",
    placeholder: "The book of john",
  },
  {
    name: "more_videos_lbl",
    label: "more videos",
    placeholder: "The book of john",
  },
  {
    name: "support_us_lbl",
    label: "Support Us",
    placeholder: "The book of john",
  },
  {
    name: "support_btn",
    label: "Support",
    placeholder: "The book of john",
  },
  {
    name: "support_feedback_message",
    label: "Support Feedback Message",
    placeholder: "Support Feedback",
  },
  {
    name: "need_prayer_lbl",
    label: "Need prayer",
    placeholder: "The book of john",
  },
  {
    name: "watchnow",
    label: "Watch Now",
    placeholder: "Watch Now",
  },
  {
    name: "readnow",
    label: "Read Now",
    placeholder: "Read Now",
  },
  {
    name: "footer_copyright",
    label: "Footer Copyright",
    placeholder: "Copyright TWG",
  },
  {
    name: "testimony_videos_lbl",
    label: "Testimony Videos",
    placeholder: "Testimony Videos",
  },
  {
    name: "how_you_rate_lbl",
    label: "How do you rate the overall App",
    placeholder: "How do you rate the overall App",
  },
  {
    name: "what_most_you_liked_lbl",
    label: "What did you like the most",
    placeholder: "What did you like the most",
  },
  {
    name: "videos_category_lbl",
    label: "Videos Category Label",
    placeholder: "Videos Category",
  },
  {
    name: "curriculum_category_lbl",
    label: "Curriculum Category Label",
    placeholder: "Videos Category",
  },
  {
    name: "testimonies_category_lbl",
    label: "Testimonies Category Label",
    placeholder: "Videos Category",
  },
  {
    name: "twg_category_lbl",
    label: "TWG Category Label",
    placeholder: "Videos Category",
  },
  {
    name: "category_lbl",
    label: "Category Label",
    placeholder: "Videos Category",
  },
  {
    name: "what_explore_next_lbl",
    label: "What would you like to explore next",
    placeholder: "Videos Category",
  },
  {
    name: "type_here_placeholder_lbl",
    label: "Type Here Placeholder",
    placeholder: "Videos Category",
  },
  {
    name: "send_feedback_btn",
    label: "Send Feedback Button",
    placeholder: "Videos Category",
  },
  {
    name: "healing_category_lbl",
    label: "Healing Category Label",
    placeholder: "Videos Category",
  },
  {
    name: "salvation_category_lbl",
    label: "Salvation Category Label",
    placeholder: "Videos Category",
  },
  {
    name: "general_category_lbl",
    label: "General Category Label",
    placeholder: "Videos Category",
  },
  {
    name: "prayer_submit_btn",
    label: "Prayer Submit Button",
    placeholder: "Videos Category",
  },
  {
    name: "subject_lbl",
    label: "Subject label",
    placeholder: "Videos Category",
  },
  {
    name: "prayer_request_lbl",
    label: "Prayer Request Label",
    placeholder: "Videos Category",
  },
  {
    name: "type_request_placeholder_lbl",
    label: "Type Request Placeholder",
    placeholder: "Videos Category",
  },
  {
    name: "prayer_feedback_message",
    label: "Prayer Feedback Message",
    placeholder: "Prayer Feedback",
  },
  {
    name: "search_lbl",
    label: "Search Label",
    placeholder: "Videos Category",
  },
  {
    name: "want_to_quit_lbl",
    label: "Want to quit page",
    placeholder: "Videos Category",
  },
  {
    name: "cancel_btn",
    label: "Cancel Button Label",
    placeholder: "Videos Category",
  },
  {
    name: "ok_btn",
    label: "Ok Button Label",
    placeholder: "Videos Category",
  },
  {
    name: "preferred_language_lbl",
    label: "Preferred Language",
    placeholder: "Videos Category",
  },
  {
    name: "privacy_policy_description",
    label: "Privacy Policy Description",
    placeholder: "Privacy Policy",
    type: "editor",
  },
];

export const PromiseFields = [
  {
    name: "promises_lbl",
    label: "Welcome",
    placeholder: "Promise",
  },
  {
    name: "promise_ofthe_day_lbl",
    label: "Promise Of the day",
    placeholder: "Promise Of the day",
  },
  {
    name: "hear_godsword_lbl",
    label: "Hear Gods Word",
    placeholder: "Hear Gods Word",
  },
  {
    name: "read_godsword_lbl",
    label: "Read Gods Word",
    placeholder: "Read Gods Word",
  },
  {
    name: "more_lbl",
    label: "More Label",
    placeholder: "More Label",
  },
  {
    name: "watch_today_devotional_lbl",
    label: "Watch Today's Devotional",
    placeholder: "More Label",
  },
  {
    name: "devotions_lbl",
    label: "Devotions",
    placeholder: "More Label",
  },
  {
    name: "more_devotions_lbl",
    label: "More Devotions",
    placeholder: "More Label",
  },
];

export const BookOfJohnFields = [
  {
    name: "testimonies_lbl",
    label: "Testimonies ",
    placeholder: "The book of john",
  },
  {
    name: "your_testimonies_lbl",
    label: "Your testimonies",
    placeholder: "Today with God",
  },
  {
    name: "testimonies_you_liked_lbl",
    label: "Testomonies you liked",
    placeholder: "The book of john",
  },
  {
    name: "testimonies_near_you_lbl",
    label: "Testomonies near you",
    placeholder: "The book of john",
  },
  {
    name: "see_all_btn",
    label: "see All",
    placeholder: "The book of john",
  },
  {
    name: "healing_lbl",
    label: "Healing",
    placeholder: "The book of john",
  },
  {
    name: "salvation_lbl",
    label: "Salvation",
    placeholder: "The book of john",
  },
  {
    name: "share_what_lord_lbl",
    label: "Share what lord",
    placeholder: "The book of john",
  },
  {
    name: "your_approved_testimonies",
    label: "Your approaved testimonies",
    placeholder: "The book of john",
  },
  {
    name: "your_inreview_testimonies",
    label: "Your interview testimonies",
    placeholder: "The book of john",
  },
  {
    name: "start_sharing_lbl",
    label: "Start Sharing",
    placeholder: "The book of john",
  },
  {
    name: "carousel_title_lbl",
    label: "Carousel Title",
    placeholder: "The book of john",
  },
  {
    name: "add_from_gallery_lbl",
    label: "Add from Gallery",
    placeholder: "The book of john",
  },
  {
    name: "recording_lbl",
    label: "Recording Label",
    placeholder: "The book of john",
  },
  {
    name: "cancel_lbl",
    label: "Cancel Button Label",
    placeholder: "The book of john",
  },
  {
    name: "save_lbl",
    label: "Save Button Label",
    placeholder: "The book of john",
  },
  {
    name: "add_title_lbl",
    label: "Add Title Input Label",
    placeholder: "The book of john",
  },
  {
    name: "caption_your_testimony_lbl",
    label: "Caption your testimony Input Label",
    placeholder: "The book of john",
  },
  {
    name: "display_username_lbl",
    label: "Display Username Input Label",
    placeholder: "The book of john",
  },
  {
    name: "display_country_lbl",
    label: "Display Country Input Label",
    placeholder: "The book of john",
  },
  {
    name: "language_recorded_lbl",
    label: "Language Recorded In Input Label",
    placeholder: "The book of john",
  },
  {
    name: "testimony_category_lbl",
    label: "Testimony category Input Label",
    placeholder: "The book of john",
  },
  {
    name: "select_thumbnail_lbl",
    label: "Select Thumbnail Label",
    placeholder: "The book of john",
  },
  {
    name: "capture_thumbnail_lbl",
    label: "Capture Thumbnail Label",
    placeholder: "The book of john",
  },
  {
    name: "upload_thumbnail_lbl",
    label: "Upload Thumbnail Label",
    placeholder: "The book of john",
  },
  {
    name: "submit_review_lbl",
    label: "Submit Review Button Label",
    placeholder: "The book of john",
  },
  {
    name: "testimonial_details_lbl",
    label: "Testimonial Details Label",
    placeholder: "The book of john",
  },
];

export const LanguageCurriculumFields = [
  {
    name: "learn_lbl",
    label: "Learn",
    placeholder: "The book of john",
  },
  {
    name: "yourachievement_lbl",
    label: "Your Achievement",
    placeholder: "Today with God",
  },
  {
    name: "congratulations_lbl",
    label: "Congratulations",
    placeholder: "The book of john",
  },
  {
    name: "learn_the_word_with_us_lbl",
    label: "Learn the word with us",
    placeholder: "The book of john",
  },
  {
    name: "see_all_btn",
    label: "See All",
    placeholder: "The book of john",
  },
  {
    name: "no_data_found_lbl",
    label: "No Data Found",
    placeholder: "The book of john",
  },
  {
    name: "completed_lbl",
    label: "Completed Label",
    placeholder: "The book of john",
  },
  {
    name: "lesson_lbl",
    label: "Lesson Label",
    placeholder: "The book of john",
  },
  {
    name: "watch_preview_btn",
    label: "Watch Preview",
    placeholder: "The book of john",
  },
  {
    name: "start_lesson_lbl",
    label: "Start Lesson",
    placeholder: "The book of john",
  },
  {
    name: "more_lbl",
    label: "More Label",
    placeholder: "The book of john",
  },
  {
    name: "lesson_intro_lbl",
    label: "Lesson Intro",
    placeholder: "The book of john",
  },
  {
    name: "download_certificate_lbl",
    label: "Download Certificate",
    placeholder: "The book of john",
  },
  {
    name: "congratulation_success_lbl",
    label: "Curriculum Completion Congratulations",
    placeholder: "The book of john",
  },
  {
    name: "hello_lbl",
    label: "Curriculum Completion Hello",
    placeholder: "The book of john",
  },
  {
    name: "no_video_url_lbl",
    label: "Slide No Video URL",
    placeholder: "The book of john",
  },
  {
    name: "slide_no_data_lbl",
    label: "Slide No Data",
    placeholder: "The book of john",
  },
  {
    name: "next_lesson_lbl",
    label: "Next Lesson",
    placeholder: "The book of john",
  },
];

export const LanguageFavouriteFields = [
  {
    name: "curriculum_lbl",
    label: "Curriculum",
    placeholder: "The book of john",
  },
  {
    name: "videos_lbl",
    label: "Videos",
    placeholder: "Today with God",
  },
  {
    name: "categories_lbl",
    label: "Categories",
    placeholder: "Today with God",
  },
  {
    name: "testimonies_lbl",
    label: "Testimonies",
    placeholder: "The book of john",
  },
  {
    name: "promise_lbl",
    label: "Promise",
    placeholder: "The book of john",
  },
  {
    name: "see_all_btn",
    label: "See All",
    placeholder: "The book of john",
  },
  {
    name: "no_data_available_lbl",
    label: "No Data Available",
    placeholder: "The book of john",
  },
];

export const LanguageGivingFields = [
  {
    name: "give_lbl",
    label: "Give",
    placeholder: "The book of john",
  },
  {
    name: "give_desc_lbl",
    label: "Give Desc",
    placeholder: "Today with God",
  },
  {
    name: "give_btn",
    label: "Give button",
    placeholder: "The book of john",
  },
  {
    name: "give_now_btn",
    label: "Give now",
    placeholder: "The book of john",
  },
  {
    name: "setup_recurring_btn",
    label: "SetUp recurring",
    placeholder: "The book of john",
  },
  {
    name: "your_past_giving_btn",
    label: "Your past giving",
    placeholder: "The book of john",
  },
  {
    name: "need_help_btn",
    label: "Need help",
    placeholder: "The book of john",
  },
];

export const LanguageMenuFields = [
  {
    name: "home_lbl",
    label: "Home",
    placeholder: "The book of john",
  },
  {
    name: "curriculum_lbl",
    label: "Curriculum",
    placeholder: "Today with God",
  },
  {
    name: "my_fav_lbl",
    label: "My favourites",
    placeholder: "The book of john",
  },
  {
    name: "testimonials_lbl",
    label: "Testimonials",
    placeholder: "The book of john",
  },
  {
    name: "give_lbl",
    label: "Give",
    placeholder: "The book of john",
  },
  {
    name: "menu_lbl",
    label: "Menu",
    placeholder: "The book of john",
  },
  {
    name: "my_profile_lbl",
    label: "My Profile",
    placeholder: "The book of john",
  },
  {
    name: "my_favourites_lbl",
    label: "My Favourites",
    placeholder: "The book of john",
  },
  {
    name: "my_downloads_lbl",
    label: "My downloads",
    placeholder: "The book of john",
  },
  {
    name: "my_givings_lbl",
    label: "My givings",
    placeholder: "The book of john",
  },
  {
    name: "help_n_support",
    label: "Help and support",
    placeholder: "The book of john",
  },
  {
    name: "privacy_policy_lbl",
    label: "Privacy Policy",
    placeholder: "The book of john",
  },
  {
    name: "logout_lbl",
    label: "Logout",
    placeholder: "The book of john",
  },
  {
    name: "about_twg",
    label: "About",
    placeholder: "The book of john",
  },
  {
    name: "login_lbl",
    label: "Login",
    placeholder: "The book of john",
  },
];

export const LanguageMyProfileFields = [
  {
    name: "about_me_lbl",
    label: "About Me",
    placeholder: "The book of john",
  },
  {
    name: "recently_watched_videos_lbl",
    label: "Recent watched",
    placeholder: "Today with God",
  },
  {
    name: "testimonials_lbl",
    label: "Testimonials",
    placeholder: "The book of john",
  },
  {
    name: "curriculum_lbl",
    label: "Curriculum",
    placeholder: "The book of john",
  },
  {
    name: "certificates_lbl",
    label: "Certificates",
    placeholder: "The book of john",
  },
  {
    name: "favourite_videos_lbl",
    label: "Favourite Videos",
    placeholder: "The book of john",
  },
  {
    name: "my_testimonials_lbl",
    label: "My Testimonials",
    placeholder: "The book of john",
  },
  {
    name: "favourite_testimonials_lbl",
    label: "Favourite Testimonials",
    placeholder: "The book of john",
  },
  {
    name: "favourite_curriculum_lbl",
    label: "Favourite Curriculum",
    placeholder: "The book of john",
  },
  {
    name: "my_profile_lbl",
    label: "My Profile",
    placeholder: "The book of john",
  },
  {
    name: "edit_profile_lbl",
    label: "Edit Profile",
    placeholder: "The book of john",
  },
  {
    name: "see_all_btn",
    label: "See All",
    placeholder: "The book of john",
  },
  {
    name: "no_data_found_lbl",
    label: "No Data Found",
    placeholder: "The book of john",
  },
];

export const LanguageMyDownloadFields = [
  {
    name: "my_downloads_lbl",
    label: "My Download",
    placeholder: "The book of john",
  },
  {
    name: "curriculum_lbl",
    label: "Curriculum",
    placeholder: "Today with God",
  },
  {
    name: "certificates_lbl",
    label: "certificates",
    placeholder: "The book of john",
  },
  {
    name: "videos_lbl",
    label: "Videos",
    placeholder: "The book of john",
  },
  {
    name: "promise_lbl",
    label: "Promise",
    placeholder: "The book of john",
  },
  {
    name: "see_all_btn",
    label: "See All",
    placeholder: "The book of john",
  },
  {
    name: "no_data_found_lbl",
    label: "No Data Found",
    placeholder: "The book of john",
  },
];

export const LanguageMyGivingsFields = [
  {
    name: "my_givings_lbl",
    label: "My givings",
    placeholder: "The book of john",
  },
];

export const LanguageHelpAndSupportFields = [
  {
    name: "help_n_support_lbl",
    label: "help and support",
    placeholder: "Enter the text",
  },
  {
    name: "help_center_lbl",
    label: "Help Center",
    placeholder: "Enter the text",
  },
];

export const LanguageAboutTWGFields = [
  {
    name: "about_twg_title",
    label: "About TWG",
    placeholder: "The book of john",
  },
  {
    name: "about_twg_description",
    label: "About TWG description",
    placeholder: "Today with God",
  },
];

export const AchiementsFields = [
  {
    name: "learn",
    label: "Learn",
    placeholder: "Learn",
  },
  {
    name: "achiements",
    label: "Your Achiements",
    placeholder: "Your Achiements",
  },
  {
    name: "congratulations",
    label: "Congratulations",
    placeholder: "Congratulations",
  },
  {
    name: "bookOfJohn",
    label: "The book of john",
    placeholder: "The book of john",
  },
  {
    name: "bookOfLife",
    label: "The book of life",
    placeholder: "The book of life",
  },
  {
    name: "bookOfOdean",
    label: "The book of odean",
    placeholder: "The book of odean",
  },
];

export const VideosLikedFields = [
  {
    name: "videos",
    label: "Videos",
    placeholder: "Videos",
  },
  {
    name: "Liked",
    label: "Liked",
    placeholder: "Liked",
  },
  {
    name: "JesusInTheBoat",
    label: "Jesus in the boat",
    placeholder: "Jesus in the boat",
  },
  {
    name: "bookOfJohnE1",
    label: "The book of john ep 1",
    placeholder: "The book of john ep 1",
  },
  {
    name: "bookOfJohnE2",
    label: "The book of john ep 2",
    placeholder: "The book of john ep 2",
  },
  {
    name: "bookOfOdean",
    label: "The book of odean",
    placeholder: "The book of odean",
  },
];

export const johnInitialState = {
  testimonies_lbl: "Testimonies",
  your_testimonies_lbl: "Your Testimonies",
  testimonies_you_liked_lbl: "Testimonies you Liked",
  testimonies_near_you_lbl: "Testimonies near you",
  see_all_btn: "See All",
  healing_lbl: "Healing",
  salvation_lbl: "Salvation",
  share_what_lord_lbl: "Share what lord?",
  your_approved_testimonies: "your Approved testimonies",
  your_inreview_testimonies: "Your interview testimonies",
  start_sharing_lbl: "Start Sharing",
  carousel_title_lbl: "Carousel Title",
  add_from_gallery_lbl: "Add from Gallery",
  recording_lbl: "Recording Label",
  cancel_lbl: "Cancel Button Label",
  save_lbl: "Save Button Label",
  add_title_lbl: "Add Title Input Label",
  caption_your_testimony_lbl: "Caption your testimony Input Label",
  display_username_lbl: "Display Username Input Label",
  display_country_lbl: "Display Country Input Label",
  language_recorded_lbl: "Language Recorded In Input Label",
  testimony_category_lbl: "Testimony category Input Label",
  select_thumbnail_lbl: "Select Thumbnail Label",
  capture_thumbnail_lbl: "Capture Thumbnail Label",
  upload_thumbnail_lbl: "Upload Thumbnail Label",
  submit_review_lbl: "Submit Review Button Label",
  testimonial_details_lbl: "Testimonial Details Label",
};

export const promisesInitialState = {
  promises_lbl: "Promises",
  promise_ofthe_day_lbl: "Promise of the Day",
  hear_godsword_lbl: "Hear Gods Word",
  read_godsword_lbl: "Read Gods Word",
  more_lbl: "More Label",
  watch_today_devotional_lbl: "Watch Today's Devotional",
  devotions_lbl: "Devotions",
  more_devotions_lbl: "More Devotions",
};
export const achievementInitialState = {
  learn: "Learn",
  achiements: "Achiements",
  congratulations: "Congratulations",
  bookOfJohn: "Book Of John",
  bookOfLife: "Book Of Life",
  bookOfOdean: "Book Of Odean",
};
export const videoLikedInitialState = {
  videos: "Videos",
  Liked: "Liked",
  JesusInTheBoat: "Jesus In The Boat",
  bookOfJohnE1: "Book Of John Ep1",
  bookOfJohnE2: "Book Of John Ep2",
  bookOfOdean: "Book Of Odean",
};

export const CurriculumInitialState = {
  learn_lbl: "Learn",
  yourachievement_lbl: "Your Achievements",
  congratulations_lbl: "Congratulations",
  learn_the_word_with_us_lbl: "Learn the word with us",
  see_all_btn: "See All",
  no_data_found_lbl: "No Data Found",
  completed_lbl: "Completed Label",
  lesson_lbl: "Lesson label",
  watch_preview_btn: "Watch Preview",
  start_lesson_lbl: "Start Lesson",
  more_lbl: "More Label",
  lesson_intro_lbl: "Lesson Intro",
  download_certificate_lbl: "Download Certificate",
  congratulation_success_lbl: "Congratulations on successfully completion of",
  hello_lbl: "Hello",
  no_video_url_lbl: "No Video URL",
  slide_no_data_lbl: "No Data",
  next_lesson_lbl: "Next Lesson",
};

export const FavouritesInitialState = {
  curriculum_lbl: "Curriculum",
  videos_lbl: "Videos",
  categories_lbl: "Categories",
  testimonies_lbl: "Testimonies",
  promise_lbl: "Promise",
  see_all_btn: "See All",
  no_data_available_lbl: "No Data Available",
};

export const GivingsInitialState = {
  give_lbl: "Give",
  give_desc_lbl:
    "We because god first gave .Your Support endower of Earth so gorgeous & different from the boring Moon,. thank you for such as it is my gift. I have made up a morning prayer to you.",
  give_btn: "Give",
  give_now_btn: "Give Now",
  setup_recurring_btn: "Set up Recurring",
  your_past_giving_btn: "Your past Givings",
  need_help_btn: "Need Help?",
};

export const MenuInitialState = {
  home_lbl: "Home",
  curriculum_lbl: "Curriculum",
  my_fav_lbl: "my Fav",
  testimonials_lbl: "Testimonies",
  give_lbl: "Give",
  menu_lbl: "Menu",
  my_profile_lbl: "My Profile",
  my_favourites_lbl: "My Favourites",
  my_downloads_lbl: "My Downloads",
  my_givings_lbl: "My Givings",
  help_n_support: "Help & Support",
  privacy_policy_lbl: "Privacy Policy",
  logout_lbl: "Logout",
  login_lbl: "Login",
  about_twg: "About TWG",
};

export const MyProfileInitialState = {
  about_me_lbl: "About Me",
  recently_watched_videos_lbl: "Recently Watched videos",
  testimonials_lbl: "Testimonials",
  curriculum_lbl: "Curriculum",
  certificates_lbl: "Certificates",
  favourite_videos_lbl: "Favourite Videos",
  my_testimonials_lbl: "My Testimonials",
  favourite_testimonials_lbl: "Favourite Testimonials",
  favourite_curriculum_lbl: "Favourite Curriculum",
  my_profile_lbl: "My Profile",
  edit_profile_lbl: "Edit Profile",
  see_all_btn: "See All",
  no_data_found_lbl: "No Data Found",
};

export const MyDownloadInitialState = {
  my_downloads_lbl: "My Downloads",
  curriculum_lbl: "Curriculum",
  certificates_lbl: "Certificates",
  videos_lbl: "Videos",
  promise_lbl: "Promise",
  see_all_btn: "See All",
  no_data_found_lbl: "No Data Found",
};

export const MyGivingsInitialState = {
  my_givings_lbl: "My Givings",
};

export const HelpAndSupportInitialState = {
  help_n_support_lbl: "Help & Support",
  help_center_lbl: "Help Center",
};

export const AboutTWGInitialState = {
  about_twg_title: "About TWG",
  about_twg_description:
    "The commissioning of the Twelve Apostles during the ministry of Jesus is described in the Synoptic Gospels. After his resurrection, Jesus sent eleven of them",
};
