import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  readLanguageAction,
  saveLayoutLanguageAction,
  translateLanguageAction,
  updateLayoutLanguageAction,
} from "../../../store/Languages/LanguageAction";
import {
  layoutDashboardAction,
  readLayoutAction,
  saveLayoutAction,
  updateLayoutAction,
} from "../../../store/Layout/LayoutAction";
import Toastiy from "../../../components/universal/Toastiy";
import { setShowNavigationAlertAction } from "../../../store/GlobalLoader/GlobalLoaderAction";

export const AddLayoutHooks = ({ _id, setLoading }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("en");
  const [activeLanguageDetails, setActiveLanguageDetails] = useState({
    language_code: "en",
    language_name: "English",
  });
  const [readLanguageData, setReadLanguageData] = useState([]);
  const [isLanguageSave, setIsLanguageSave] = useState(true);
  const [languagemeta, setLanguagemeta] = useState();
  const [layoutStats, setLayoutStats] = useState(null);

  console.log(languagemeta, "languagemeta");

  console.log(readLanguageData, "readLanguageData");

  const initialLayoutValues = {
    _id: _id,
    layout_name: "",
    mobile_layout: "1",
    order: "",
    expiry: "",
    category: "0",
    curriculum: "0",
    // static: "0",
  };

  const [layoutValues, setLayoutValues] = useState(initialLayoutValues);
  const [currentValues, setCurrentValues] = useState(initialLayoutValues);
  const [englishValues, setEnglishValues] = useState(initialLayoutValues);

  useEffect(() => {
    return () => setShowNavigationAlert(false);
  }, []);

  const setShowNavigationAlert = (value) => {
    dispatch(setShowNavigationAlertAction(value));
  };

  const handleSaveLanguage = () => {
    if (isLanguageSave) {
      dispatch(
        saveLayoutLanguageAction({
          apiPayloadRequest: {
            _id: _id,
            language_code: activeTab,
            layout_name: layoutValues?.layout_name,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Layout Language Saved Successfully!", res.type);
              setShowNavigationAlert(false);
              fetchLayoutData();
            }
          },
        })
      );
    } else {
      if (layoutValues.layout_name !== currentValues.layout_name) {
        dispatch(
          updateLayoutLanguageAction({
            apiPayloadRequest: {
              _id: _id,
              language_code: activeTab,
              layout_name: layoutValues?.layout_name,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Layout Language Updated Successfully!", res.type);
                setShowNavigationAlert(false);
                fetchLayoutData();
              }
            },
          })
        );
      } else {
        Toastiy("No changes are made");
      }
    }
  };

  const combineStateKeys = () => {
    const values = [layoutValues?.layout_name];
    const combinedString = values.join(" @@@@ ");
    return combinedString;
  };

  const onTranslateSuccess = (data) => {
    const arrayOfValues = data.split(" @@@@ ");

    setLayoutValues((preState) => ({
      ...preState,
      layout_name: arrayOfValues[0],
    }));
    setShowNavigationAlert(true);
  };

  const updateStateWithTranslation = () => {
    const combinedText = combineStateKeys();

    dispatch(
      translateLanguageAction({
        apiPayloadRequest: {
          targetcode: activeTab,
          translatetext: combinedText,
        },
        callback: (res) => {
          if (res?.type === 1) {
            onTranslateSuccess(res?.data);
          } else if (res?.type === 2) {
            Toastiy("Language Translation failed!!");
            Toastiy(res?.message);
          }
        },
      })
    );
  };

  const handleSave = () => {
    if (layoutValues._id !== undefined) {
      setLoading(true);
      if (
        layoutValues.layout_name !== currentValues.layout_name ||
        layoutValues.category !== currentValues.category ||
        layoutValues.curriculum !== currentValues.curriculum ||
        layoutValues.mobile_layout !== currentValues.mobile_layout ||
        layoutValues.order !== currentValues.order
      ) {
        dispatch(
          updateLayoutAction({
            apiPayloadRequest: {
              ...layoutValues,
              static: ["65572856550e62e40511cccc", "655728b1550e62e40511cccd"].includes(
                layoutValues?._id
              )
                ? "1"
                : "0",
            },
            callback: (res) => {
              setLayoutValues((prev) => ({
                ...prev,
                _id: res?.data[0]?._id,
              }));
              Toastiy("Layout Updated Successfully!", res?.type);
              setLoading(false);
              setShowNavigationAlert(false);
            },
          })
        );
      } else {
        Toastiy("No changed made the existing layout");
      }
    } else {
      setLoading(true);
      dispatch(
        saveLayoutAction({
          apiPayloadRequest: {
            ...layoutValues,
            static: "0",
          },
          callback: (res) => {
            setLayoutValues((prev) => ({
              ...prev,
              _id: res?.data[0]?._id,
            }));
            Toastiy("Layout Added Successfully!", res?.type);
            setLoading(false);
            setShowNavigationAlert(false);
          },
        })
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "category" && value === "1") {
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
        curriculum: "0",
      }));
    } else if (name === "curriculum" && value === "1") {
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
        category: "0",
      }));
    } else {
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    setShowNavigationAlert(true);
  };

  const getAllLanguageResponse = (response) => {
    if (response?.type === 1) {
      setReadLanguageData(response?.data);
    }
    setLoading(false);
  };

  const fetchLayoutData = () => {
    dispatch(
      readLayoutAction({
        apiPayloadRequest: { _id: _id },
        callback: (res) => {
          if (res?.type === 1) {
            const requiredData = res?.data?.filter((item) => item._id === _id)[0];
            console.log("requiredData", requiredData);
            setLayoutValues((prev) => ({
              ...prev,
              _id: requiredData._id,
              layout_name: requiredData?.layout_name,
              mobile_layout: requiredData?.mobile_layout,
              order: requiredData?.order,
              category: requiredData?.category,
              curriculum: requiredData?.curriculum,
              // static: requiredData?.static,
              // expiry: requiredData?.expiry,
            }));

            setEnglishValues((prev) => ({
              ...prev,
              _id: requiredData._id,
              layout_name: requiredData?.layout_name,
              mobile_layout: requiredData?.mobile_layout,
              order: requiredData?.order,
              category: requiredData?.category,
              curriculum: requiredData?.curriculum,
              // static: requiredData?.static,
              // expiry: requiredData?.expiry,
            }));
            setLanguagemeta(requiredData?.languagemetas);
          }
          setLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      readLanguageAction({
        callback: getAllLanguageResponse,
      })
    );
  }, []);

  useEffect(() => {
    if (_id) {
      setLoading(true);
      fetchLayoutData();
      dispatch(
        layoutDashboardAction({
          apiPayloadRequest: { _id: _id },
          callback: (res) => {
            if (res?.type === 1) {
              setLayoutStats(res?.data);
            }
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    const handleLanguageChange = () => {
      if (activeTab === "en") {
        setLayoutValues(englishValues);
      } else {
        const isPresent =
          languagemeta && languagemeta?.some((language) => language?.language_code === activeTab);
        if (isPresent) {
          const data = languagemeta?.filter((item) => item?.language_code === activeTab)[0];

          setLayoutValues((prev) => ({
            ...prev,
            layout_name: data?.layout_name,
          }));

          setCurrentValues((prev) => ({
            ...prev,
            layout_name: data?.layout_name,
          }));
        } else {
          setLayoutValues(englishValues);
          setCurrentValues(englishValues);
        }
      }
    };

    handleLanguageChange();
  }, [activeTab, englishValues]);

  return {
    activeTab,
    setActiveTab,
    readLanguageData,
    handleChange,
    layoutValues,
    setIsLanguageSave,
    handleSave,
    languagemeta,
    updateStateWithTranslation,
    handleSaveLanguage,
    layoutStats,
    activeLanguageDetails,
    setActiveLanguageDetails,
  };
};
