import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoaderSpinner from "../../components/universal/LoaderSpinner";

import { getBibleVersionsAction, setBibleVersion } from "../../store/Bible/BibleAction";
import { PiSpeakerSimpleHighBold } from "react-icons/pi";
import { FaCaretDown } from "react-icons/fa6";

const BibleVersionSearch = () => {
  const dispatch = useDispatch();

  const { bibleLanguage, bibleVersion } = useSelector((state) => state.bible);
  console.log("bibleLanguage", bibleLanguage);

  const [versions, setVersions] = useState([]);
  const [allVersions, setAllVersions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const fetchBibleVersions = (language) => {
    setLoading(true);
    dispatch(
      getBibleVersionsAction({
        apiPayloadRequest: { languagecode: language },
        callback: (res) => {
          setVersions(res?.data);
          setAllVersions(res?.data);
          setLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    fetchBibleVersions(bibleLanguage);
  }, [bibleLanguage]);

  useEffect(() => {
    if (!searchText) {
      setVersions(allVersions);
    } else {
      const text = searchText?.toLowerCase();
      const updatedLanguages = allVersions?.filter((item) => {
        return (
          item?.name?.toLowerCase()?.includes(text) || item?.abbr?.toLowerCase()?.includes(text)
        );
      });
      setVersions(updatedLanguages);
    }
  }, [allVersions, searchText]);

  const handleDropdown = (e) => {
    if (searchText !== "") {
      setSearchText("");
    }
    isDropdownActive ? setIsDropdownActive(false) : setIsDropdownActive(true);
  };

  const handleVersionSelection = (version) => {
    dispatch(setBibleVersion(version));
    setIsDropdownActive(false);
  };

  useEffect(() => {
    const eventHandler = (e) => {
      let preferredLanguageDrawer = document.querySelector("#bibleVersion");
      if (preferredLanguageDrawer && !preferredLanguageDrawer?.contains(e.target)) {
        setIsDropdownActive((preState) => !preState);
        setSearchText("");
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  return (
    <div>
      <div
        className="flex items-center gap-2 bg-greyishV2 outline-none border-none rounded-md px-4 py-2 text-white cursor-pointer"
        onClick={() => handleDropdown()}
      >
        <span className="uppercase">{bibleVersion?.substring(3)}</span>{" "}
        <FaCaretDown className="text-yellowish" />
      </div>
      {isDropdownActive && (
        <div
          className="w-[30vw] text-white absolute bg-greyishV2/80 backdrop-blur-sm p-8 rounded-3xl rounded-ss-none top-16 h-[70vh] z-50 flex flex-col overflow-y-auto custom-scrollbar"
          id="bibleVersion"
        >
          <div className="flex items-center gap-5 pb-7">
            <div className="w-full bg-transparent min-w-[400px] flex items-center gap-3 border-b border-greyish">
              <input
                type="text"
                value={searchText}
                placeholder="Search Version"
                onChange={(e) => handleSearch(e)}
                className="w-full bg-transparent outline-none placeholder:text-greyish text-greyish"
              />
            </div>
          </div>
          <ul className="flex-1 overflow-y-auto custom-scrollbar">
            {versions?.map((item, index) => {
              return (
                <li
                  key={index}
                  className="w-full flex items-center justify-between py-2 hover:bg-black/30 rounded-md  pl-2 pr-8"
                  onClick={() => handleVersionSelection(item?.abbr)}
                >
                  <div className="flex items-center gap-5 cursor-pointer text-sm">
                    <div className="bg-greyish/40 text min-w-20 w-20 py-4 px-2 text-wrap flex items-center justify-center rounded-md">
                      {item?.abbr?.substring(3)}
                    </div>
                    <div className="flex flex-col gap-1 items-start">
                      <PiSpeakerSimpleHighBold />
                      <p className="">{item?.name}</p>
                    </div>
                  </div>
                  {/* <div className="min-w-5">
                    <img src={DownloadIcon} alt="DownloadIcon" />
                  </div> */}
                </li>
              );
            })}
            {loading && (
              <li className="flex items-center justify-center py-5 text-yellowish cursor-pointer">
                <LoaderSpinner />
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default BibleVersionSearch;
