import { FaDownload } from "react-icons/fa6";

function SquareCard({
  heading,
  icon,
  info,
  trail,
  percentage,
  handleOnClick,
  iconStyle,
  downloadIcon = false,
  downloadIconClickHandle = () => {},
}) {
  return (
    <div
      onClick={() => handleOnClick()}
      className="cursor-pointer relative md:w-56 md:h-44 rounded-2xl border border-greyishV2 border-opacity-50 flex flex-col justify-between bg-blackishV2 p-4"
    >
      {downloadIcon && (
        <button
          className="text-yellowish absolute top-2 right-2 text-xs"
          onClick={(e) => {
            e.stopPropagation();
            if (downloadIconClickHandle) downloadIconClickHandle();
          }}
        >
          <FaDownload />
        </button>
      )}
      <p className="text-sm text-greyish">{heading}</p>
      <img className={`${iconStyle} object-contain`} src={icon} alt="" />
      <p className={`font-bold ${percentage ? "text-white" : "text-yellowish text-xl"}`}>
        {info} <span className="text-greyish text-sm">{trail}</span>
      </p>
      <p className="text-yellowish text-sm">{percentage}</p>
    </div>
  );
}

export default SquareCard;
