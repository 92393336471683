import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LoaderSpinner from "../../components/universal/LoaderSpinner";

import { getBibleSearch } from "../../store/Bible/BibleAction";

import { LuSearch } from "react-icons/lu";
import CloseIcon from "../../assets/icons/Cross.svg";

const BilbleSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { versionDetails } = useSelector((state) => state.bible);

  const [loading, setLoading] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (e) => {
    setSearchTxt(e.target.value);

    setLoading(true);
    dispatch(
      getBibleSearch({
        apiPayloadRequest: {
          filesetid: versionDetails?.abbr,
          searchquery: e.target.value,
        },
        callback: (res) => {
          setSearchResults(res?.data?.verses?.data ?? []);
          setLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    const eventHandler = (e) => {
      let preferredLanguageDrawer = document.querySelector("#bibleSearch");
      if (preferredLanguageDrawer && !preferredLanguageDrawer?.contains(e.target)) {
        setIsSearching((preState) => !preState);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  return (
    <div>
      <div
        className={`w-[20vw] flex items-center bg-greyishV2 gap-3 py-2 px-3 rounded-md cursor-pointer`}
        onClick={() => setIsSearching(true)}
      >
        <LuSearch className="text-xl text-yellowish" />
        <input className="w-full bg-greyishV2 text-greyish" placeholder="Search the Bible" />
      </div>
      {isSearching && (
        <div
          id="bibleSearch"
          className="w-9/12 text-white absolute bg-greyishV2/80 backdrop-blur-sm p-5 rounded-md top-16 h-[45vh] overflow-y-auto custom-scrollbar z-50 flex flex-col"
        >
          <div className="flex items-center gap-5 pb-7">
            <div
              className={`w-full min-w-[400px] flex items-center bg-greyish gap-3 py-2 px-3 rounded-md`}
            >
              <LuSearch className="text-xl text-blackish" />
              <input
                type="text"
                value={searchTxt}
                placeholder={"Search the Bible"}
                onChange={(e) => handleSearch(e)}
                className="w-full bg-greyish outline-none placeholder:text-blackish text-blackish"
              />
            </div>
            <img
              src={CloseIcon}
              alt="CloseIcon"
              className="h-4 cursor-pointer"
              onClick={() => {
                setIsSearching(false);
                setSearchTxt("");
              }}
            />
          </div>
          <ul className="flex flex-col gap-1 flex-1 overflow-y-auto custom-scrollbar pr-2">
            {!loading &&
              searchResults?.map((item, index) => {
                return (
                  <div
                    key={`search-item${index}`}
                    className="py-2 hover:bg-black/30 rounded-md px-2 cursor-pointer"
                    onClick={() => {
                      navigate(
                        `/chapter/${versionDetails?.abbr}/${item?.book_id}/${item?.chapter}`
                      );
                      setIsSearching(false);
                    }}
                  >
                    <p className="">{item?.verse_text}</p>
                    <div className="w-full flex items-center justify-between text-greyishV2 text-sm">
                      <p className="">
                        {item?.book_name}{" "}
                        <span className="">
                          {item?.chapter}:{item?.verse_start}
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
            {loading && (
              <li className="flex flex-col gap-2 items-center justify-center py-5 text-yellowish cursor-pointer">
                <LoaderSpinner />
                <p>Loading Results..</p>
              </li>
            )}
            {!loading && searchResults?.length === 0 && (
              <li className="flex flex-col gap-2 items-center justify-center py-10 text-yellowish cursor-pointer">
                <p>No Results Found</p>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default BilbleSearch;
