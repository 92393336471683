import logo from "../../../../assets/icons/logo.png";
import userImg from "../../../../assets/images/profilepic.png";
import InputsOfText from "../../../InputFields/InputsOfText";
import foreignLanguage from "../../../../assets/icons/foreignLanguage.png";
import { AddLanguagesHooks } from "../../../../screens/Languages/hooks/LanguagesHooks";
import screenshot1 from "../../../../assets/images/Screenshot 1new.png";
import screenshot2 from "../../../../assets/images/Screenshot2.png";
import screenshot3 from "../../../../assets/images/Screenshot3.png";
import screenshot4 from "../../../../assets/images/Screenshot4.png";
import screenshot5 from "../../../../assets/images/Screenshot5new.png";
import Screenshotallmenu from "../../../../assets/images/Screenshotallmenunew.png";

const Testimonypreview = (fieldValues) => {
  console.log(fieldValues, "teti");
  const { setShowData, showData } = AddLanguagesHooks();

  return (
    <div className="flex flex-col h-full relative bg-black">
      <div className="bg-zinc-800 absolute  bottom-0 left-0 right-0   rounded-t-xl  z-10 ">
        <img src={Screenshotallmenu} alt="menu" />
      </div>

      <div className="flex flex-col small-bar  overflow-auto pb-14">
        <div className="flex flex-row gap-2 pt-5 px-3 items-center">
          <img className="w-7" src={logo} alt="company logo" />
          <div className="w-24 h-7 bg-slate-50 opacity-40 rounded-3xl"></div>
          <img className="w-7" src={foreignLanguage} alt="language support" />
          <img className="w-8" src={userImg} alt="user avatar" />
        </div>

        <div className="flex flex-row text-center gap-2 justify-center w-full font-semibold opacity-70 py-3">
          <InputsOfText
            value={fieldValues?.fieldValues?.testimonies_lbl}
            inputStyle={
              !showData
                ? "text-yellowish w-18 text-[12px] cursor-pointer"
                : "text-slate-200 w-14 text-[12px] cursor-pointer"
            }
            onClick={() => setShowData(false)}
          />
          <InputsOfText
            value={fieldValues?.fieldValues?.your_testimonies_lbl}
            inputStyle={
              showData
                ? "text-yellowish w-32 text-[12px] cursor-pointer"
                : "text-slate-200 w-24 text-[12px] cursor-pointer"
            }
            onClick={() => setShowData(true)}
          />
        </div>

        {!showData ? (
          <div>
            <div className="flex justify-center h-64 bg-gradient-to-b from-zinc-800 to-black rounded-t-xl">
              {/* <InputsOfText
                value={fieldValues?.fieldValues?.testimonies_you_liked_lbl}
                inputStyle={'text-slate-200 w-40 font-bold text-[12px]'}
            /> */}
              <img className="w-full object-contain" src={screenshot1} alt="section 1" />
            </div>

            <div className="flex flex-col ">
              <div className="flex fex-row justify-between items-center px-3">
                <InputsOfText
                  value={fieldValues?.fieldValues?.testimonies_you_liked_lbl}
                  inputStyle={"text-slate-200 w-40 font-bold text-[12px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.see_all_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>

              <div className="flex justify-center h-56 py-3 ">
                <img className="w-48" src={screenshot2} />
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex fex-row justify-between items-center px-3">
                <InputsOfText
                  value={fieldValues?.fieldValues?.testimonies_near_you_lbl}
                  inputStyle={"text-slate-200 w-full font-bold text-[12px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.see_all_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>

              <div className="flex justify-center h-56 py-3 ">
                <img className="w-48" src={screenshot3} />
              </div>
            </div>

            <div className=" mr-1 mb-1  flex flex-col justify-center gap-4 ">
              <div className="flex flex-row justify-between items-center pt-6 px-3">
                <InputsOfText
                  value={fieldValues?.fieldValues?.healing_lbl}
                  inputStyle={"text-slate-200 w-24 font-semibold text-[14px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.see_all_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>
              <div className="flex justify-center">
                <img className="w-48 h-52 pt-2" src={screenshot4} />
              </div>
            </div>

            <div className=" mr-1 mb-1  flex flex-col justify-center items-center gap-4 ">
              <div className="flex flex-row justify-between items-center pt-6 px-3 w-full">
                <InputsOfText
                  value={fieldValues?.fieldValues?.salvation_lbl}
                  inputStyle={"text-slate-200 w-24 font-semibold text-[14px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.see_all_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>
              <div className="flex justify-center">
                <img className="w-48 h-52 pt-2" src={screenshot3} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col p-1 pl-2">
            {/* <InputsOfText
              value={fieldValues?.fieldValues?.share_what_lord_lbl}
              inputStyle={"text-slate-200 w-36 font-semibold text-[14px]"}
            /> */}
            <div className="flex justify-center">
              <img className="w-48 h-80 pt-3 object-cover" src={screenshot5} alt="section 1" />
            </div>

            <div className=" mr-1 mb-1  flex flex-col justify-center gap-4 ">
              <div className="flex flex-row justify-between pt-6 px-2">
                <InputsOfText
                  value={fieldValues?.fieldValues?.your_approved_testimonies}
                  inputStyle={"text-slate-200 w-40 font-semibold text-[14px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.see_all_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>
              <div className="flex justify-center">
                <img
                  className="h-52 pt-2 object-cover object-left"
                  src={screenshot2}
                  alt="section 2"
                />
              </div>
            </div>

            <div className=" mr-1 mb-1  flex flex-col justify-center gap-4 ">
              <div className="flex flex-row justify-between items-center pt-6 px-2">
                <InputsOfText
                  value={fieldValues?.fieldValues?.your_inreview_testimonies}
                  inputStyle={"text-slate-200 w-36 font-semibold text-[14px]"}
                />
                <InputsOfText
                  value={fieldValues?.fieldValues?.see_all_btn}
                  inputStyle={"text-yellowish w-12 text-[10px]"}
                />
              </div>
              <div className="flex justify-center">
                <img
                  className="h-52 pt-2 object-cover object-left"
                  src={screenshot4}
                  alt="section 1"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Testimonypreview;
