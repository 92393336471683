import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { iso6393 } from "iso-639-3";

import LoaderSpinner from "../../components/universal/LoaderSpinner";

import { getBibleLanguagesAction, setBibleLanguage } from "../../store/Bible/BibleAction";

import { FaAngleDown } from "react-icons/fa6";
import Language from "../../assets/icons/language.png";

const BibleLanguageSearch = () => {
  const dispatch = useDispatch();

  const [languages, setLanguages] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCountryActive, setIsCountryActive] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(-1);
  const [searchText, setSearchText] = useState("");

  const getLanguageName = (code) => {
    const language = iso6393.find((lang) => lang.iso6393 === code);
    return language ? language.name : "Unknown language";
  };

  const fetchLanguages = () => {
    setLoading(true);
    dispatch(
      getBibleLanguagesAction({
        apiPayloadRequest: {},
        callback: (res) => {
          const updatedResults = res?.data?.map((d) => ({
            ...d,
            languages: d?.languages?.map((lang) => ({ code: lang, name: getLanguageName(lang) })),
          }));

          setLanguages(updatedResults);
          setAllLanguages(updatedResults);
          setLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  useEffect(() => {
    if (!searchText) {
      setLanguages(allLanguages);
    } else {
      const text = searchText?.toLowerCase();
      const updatedLanguages = allLanguages?.filter((item) => {
        return (
          item?.name?.toLowerCase()?.includes(text) ||
          item?.languages?.some((d) => d?.name?.toLowerCase().includes(text))
        );
      });
      setLanguages(updatedLanguages);
    }
  }, [allLanguages, searchText]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleLanguageChange = (e) => {
    dispatch(setBibleLanguage(e.target.value));
  };

  useEffect(() => {
    const eventHandler = (e) => {
      let preferredLanguageDrawer = document.querySelector("#bibleLanguage");
      if (preferredLanguageDrawer && !preferredLanguageDrawer?.contains(e.target)) {
        setIsCountryActive((preState) => !preState);
        setSearchText("");
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  return (
    <div>
      <div
        className="px-5 py-1 bg-greyishV2 outline-none border-none rounded-md text-white cursor-pointer"
        onClick={() => setIsCountryActive(true)}
      >
        <img src={Language} alt="Language" className="w-8 aspect-square" />
      </div>
      {isCountryActive && (
        <div
          id="bibleLanguage"
          className="w-[30vw] text-white absolute bg-greyishV2/80 backdrop-blur p-8 rounded-3xl rounded-ss-none flex flex-col top-16 h-[70vh] overflow-y-auto custom-scrollbar z-50 "
        >
          <div className="flex items-center gap-5 pb-7">
            <div className="w-full bg-transparent min-w-[400px] flex items-center gap-3 border-b border-greyish">
              <input
                type="text"
                value={searchText}
                placeholder="Search Language"
                onChange={(e) => handleSearch(e)}
                className="w-full bg-transparent outline-none placeholder:text-greyish text-greyish"
              />
            </div>
          </div>
          <ul className="flex-1 overflow-y-auto custom-scrollbar">
            {languages?.map((country, index) => {
              return (
                <li key={index} className="w-full flex flex-col cursor-pointer">
                  <div
                    className="w-full flex items-center justify-between mb-5 hover:bg-black/30 py-2 rounded-md px-2"
                    onClick={() => {
                      if (selectedCountry === index) {
                        setSelectedCountry(null);
                      } else {
                        setSelectedCountry(index);
                      }
                    }}
                  >
                    <div className="flex items-center gap-5">
                      <img
                        src={`https://flagcdn.com/w160/${country?.codes?.iso_a2?.toLowerCase()}.png`}
                        alt="AudioPlay"
                        className="w-16 aspect-square object-cover rounded-md object-top"
                      />
                      <div className="flex flex-col gap-1 items-start">
                        <p className="text-lg font-semibold">{country?.name}</p>
                        <span className="text-greyish text-sm">
                          {country?.languages?.length} official language
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <FaAngleDown className="text-yellowish" />
                    </div>
                  </div>
                  {selectedCountry === index && (
                    <ul className="flex flex-col gap-4 pb-6">
                      {country?.languages?.map((lang, index) => (
                        <li
                          key={`${country?.name}-${lang?.code}${index}`}
                          className="text-lg flex items-center gap-3 pl-8 cursor-pointer"
                          onClick={() => setIsCountryActive(false)}
                        >
                          <input
                            type="radio"
                            id={lang?.code}
                            name="language"
                            value={lang?.code}
                            className="h-5 aspect-square"
                            onChange={handleLanguageChange}
                          />
                          <label
                            htmlFor={lang?.code}
                            className="text-sm text-white/80 cursor-pointer"
                          >
                            {lang?.name}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
            {loading && (
              <li className="flex items-center justify-center py-5 text-yellowish cursor-pointer">
                <LoaderSpinner />
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default BibleLanguageSearch;
