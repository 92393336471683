import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

import BibleHeader from "./BibleHeader";
import ChapterDetails from "./ChapterDetails";
import Navbar from "../../components/universal/Navbar";
import JournalsHooks from "../Journals/hooks/JournalsHooks";
import SearchBar from "../../components/universal/SearchBar";
import ConfirmToast from "../../components/universal/ConfirmToast";
import LoaderSpinner from "../../components/universal/LoaderSpinner";
import VideoLibraryModal from "../../components/TWG/VideoLibraryModal";

import {
  deleteChapterJournals,
  getChapterJournals,
  getChapterVideos,
  getVerseDetails,
  getVersionBooks,
  saveChapterJournals,
  saveChapterVideos,
  setVersionDetails,
} from "../../store/Bible/BibleAction";
import { deleteChapterVideos } from "../../store/Bible/BibleAction";

import { BsPlus } from "react-icons/bs";
import { FaTrash } from "react-icons/fa6";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import JournalLibraryModal from "./JournalLibraryModal";

const Bible = () => {
  const dispatch = useDispatch();

  const { value, onChangeSearchBar } = JournalsHooks();

  const [activeTab, setActiveTab] = useState(0);
  const [selectedTestament, setSelectedTestament] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [chapterNo, setChapterNo] = useState(null);
  const [bookId, setBookId] = useState(null);
  const [chapterVideos, setChapterVideos] = useState([]);
  const [chapterJournals, setChapterJournals] = useState([]);
  const [showVideoLibraryModal, setShowVideoLibraryModal] = useState(false);
  const [showJournalLibraryModal, setShowJournalLibraryModal] = useState(false);
  const [activeVerseDetails, setActiveVerseDetails] = useState(null);

  const { versionDetails, bibleVersion, bibleLanguage, bookChapterDetails } = useSelector(
    (state) => state.bible
  );

  console.log("versionDetails", versionDetails);
  console.log("chapterNo", chapterNo, typeof chapterNo);

  const fetchChapterVideos = () => {
    dispatch(
      getChapterVideos({
        apiPayloadRequest: {
          languageid: bibleLanguage,
          versionid: versionDetails?.abbr,
          bookid: bookId,
          chapterid: chapterNo,
        },
        callback: (response) => {
          setChapterVideos(response?.data);
        },
      })
    );
  };

  const fetchChapterJournals = () => {
    dispatch(
      getChapterJournals({
        apiPayloadRequest: {
          languageid: bibleLanguage,
          versionid: versionDetails?.abbr,
          bookid: bookId,
          chapterid: chapterNo,
        },
        callback: (response) => {
          setChapterJournals(response?.data);
        },
      })
    );
  };

  const handleVideoSelection = (details) => {
    console.log("selected video details", details);
    setShowVideoLibraryModal(false);

    dispatch(
      saveChapterVideos({
        apiPayloadRequest: {
          chapterid: activeVerseDetails?._id,
          videoObjId: details?._id,
        },
        callback: () => {
          fetchChapterVideos();
        },
      })
    );
  };

  const handleChapterVideoDelete = (chapterid, videoObjId) => {
    const options = {
      customUI: ({ onClose }) => {
        return (
          <ConfirmToast
            title="Are you sure?"
            message="You want to delete this video?"
            onClose={() => onClose()}
            onSuccess={() => {
              dispatch(
                deleteChapterVideos({
                  apiPayloadRequest: {
                    chapterid: chapterid,
                    videoObjId: videoObjId,
                  },
                  callback: () => {
                    fetchChapterVideos();
                  },
                })
              );
              onClose();
            }}
          />
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "!bg-gray-500 !bg-opacity-75",
    };
    confirmAlert(options);
  };

  const handleJournalSelection = (details) => {
    console.log("selected journal details", details);
    setShowJournalLibraryModal(false);

    dispatch(
      saveChapterJournals({
        apiPayloadRequest: {
          chapterid: activeVerseDetails?._id,
          journalObjId: details?._id,
        },
        callback: () => {
          fetchChapterJournals();
        },
      })
    );
  };

  const handleChapterJournalDelete = (chapterid, journalObjId) => {
    const options = {
      customUI: ({ onClose }) => {
        return (
          <ConfirmToast
            title="Are you sure?"
            message="You want to delete this journal?"
            onClose={() => onClose()}
            onSuccess={() => {
              dispatch(
                deleteChapterJournals({
                  apiPayloadRequest: {
                    chapterid: chapterid,
                    journalObjId: journalObjId,
                  },
                  callback: () => {
                    fetchChapterJournals();
                  },
                })
              );
              onClose();
            }}
          />
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "!bg-gray-500 !bg-opacity-75",
    };
    confirmAlert(options);
  };

  const fetchVersionBooks = (version) => {
    setLoading(true);
    dispatch(
      getVersionBooks({
        apiPayloadRequest: { versionid: version },
        callback: (res) => {
          dispatch(setVersionDetails(res?.data));
          setLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    fetchVersionBooks(bibleVersion);
  }, [bibleVersion]);

  const fetchVerseDetails = () => {
    const bookDetails = versionDetails?.books?.find((book) => book?.book_id === bookId);
    const versesList =
      bookChapterDetails?.find(
        (d) => d?.filesetDetail?.type === "text_plain" || d?.filesetDetail?.type === "text_format"
      )?.details ?? [];

    console.log("bookDetails", bookDetails);
    console.log("versesList", versesList);

    dispatch(
      getVerseDetails({
        apiPayloadRequest: {
          languageid: bibleLanguage,
          versionid: versionDetails?.abbr,
          bookid: bookId,
          bookname: bookDetails?.name,
          chapterid: chapterNo,
          verse_start: versesList?.[0]?.verse_start,
          verse_end: versesList?.[0]?.verse_end,
          verseText: versesList?.[0]?.verse_text,
        },
        callback: (response) => {
          setActiveVerseDetails(response?.data?.[0]);
        },
      })
    );
  };

  useEffect(() => {
    if (bookChapterDetails?.length > 0 && chapterNo) fetchVerseDetails();
  }, [chapterNo, bookChapterDetails]);

  useEffect(() => {
    if (activeVerseDetails?._id) {
      fetchChapterVideos();
      fetchChapterJournals();
    }
  }, [activeVerseDetails]);

  const newTestament = useMemo(() => {
    return versionDetails?.books?.filter((book) => book?.testament === "NT") ?? [];
  }, [versionDetails]);

  const oldTestament = useMemo(() => {
    return versionDetails?.books?.filter((book) => book?.testament === "OT") ?? [];
  }, [versionDetails]);

  console.log("newTestament", newTestament);
  console.log("oldTestament", oldTestament);

  return (
    <div className="App bg-[#191919] relative h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex h-full w-full flex-1 rounded-2xl overflow-hidden">
              <div className="flex h-full flex-1">
                <div className="flex h-full flex-1">
                  <div className="flex flex-col h-full w-full row-span-1 bg-greyishV2 bg-opacity-50 md:border-l md:border-r border-greyish border-opacity-30">
                    <div className="px-8 flex flex-col overflow-auto h-full">
                      <div className="w-full mx-auto py-4 h-full">
                        <BibleHeader />
                        {chapterNo ? (
                          <ChapterDetails
                            chapterNo={chapterNo}
                            versionDetails={versionDetails}
                            bookId={bookId}
                            setChapterNo={setChapterNo}
                          />
                        ) : (
                          <>
                            <div className="w-full flex items-center justify-between pb-3">
                              <p
                                className={`text-greyish text-lg mb-2 ${
                                  activeTab === 0 ? "border-b border-yellowish text-yellowish" : ""
                                } cursor-pointer`}
                                onClick={() => {
                                  setActiveTab(0);
                                  setSelectedTestament(-1);
                                }}
                              >
                                Old Testament
                              </p>
                              <p
                                className={`text-greyish text-lg mb-2 ${
                                  activeTab === 1 ? "border-b border-yellowish text-yellowish" : ""
                                } cursor-pointer`}
                                onClick={() => {
                                  setActiveTab(1);
                                  setSelectedTestament(-1);
                                }}
                              >
                                New Testament
                              </p>
                            </div>
                            {!loading && (
                              <div className="max-h-[70vh] overflow-y-auto custom-scrollbar px-4">
                                <div className="w-full">
                                  {[...(activeTab === 0 ? oldTestament : newTestament)]?.map(
                                    (item, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="w-full text-greyish cursor-pointer"
                                          onClick={() => {
                                            if (selectedTestament === index) {
                                              setSelectedTestament(null);
                                            } else {
                                              setSelectedTestament(index);
                                            }
                                          }}
                                        >
                                          <div className="flex items-center justify-between  hover:bg-white/20 py-2 px-2 rounded-md">
                                            <p>{item?.name}</p>
                                            {selectedTestament === index ? (
                                              <FaChevronDown />
                                            ) : (
                                              <FaChevronRight />
                                            )}
                                          </div>
                                          <div className="w-full flex items-center">
                                            {selectedTestament === index && (
                                              <div className="w-full bg-blackish px-14 py-7 overflow-hidden">
                                                <ul className="w-full grid grid-cols-12 gap-2 overflow-y-auto max-h-[250px] custom-scrollbar pr-2 py-1">
                                                  {item?.chapters.map((chapter, index) => {
                                                    return (
                                                      <li
                                                        key={index}
                                                        onClick={() => {
                                                          setChapterNo(chapter);
                                                          setBookId(item?.book_id);
                                                        }}
                                                        className="col-span-1 text-center border border-greyish flex hover:bg-yellowish hover:text-black items-center justify-center cursor-pointer rounded aspect-square text-greyish"
                                                      >
                                                        {chapter}
                                                      </li>
                                                    );
                                                  })}
                                                </ul>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            )}
                            {loading && (
                              <div className="flex flex-col gap-2 items-center justify-center py-28 text-white cursor-pointer">
                                <LoaderSpinner />
                                <p>Loading Books..</p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[33%] bg-[#1b1b1b]">
                <div className="mt-8 flex items-center justify-center w-full text-white px-4">
                  {chapterNo ? (
                    <div className="w-full flex-col gap-4">
                      <div className="w-full flex-col gap-4">
                        <p className="border-b border-white/30 pb-3 flex items-center gap-3">
                          Videos{" "}
                          <button
                            className="flex items-center gap-0.5 pl-2 pr-4 text-xs rounded-xl border border-yellowish py-1"
                            onClick={() => setShowVideoLibraryModal(true)}
                          >
                            <BsPlus className="text-lg" /> Add
                          </button>
                        </p>
                        <ul className="w-full flex flex-col gap-2 py-3 h-[35vh] overflow-y-auto pr-2 mb-4">
                          {chapterVideos?.map((item) => {
                            const thumbnail = item?.tumbnailurls?.find(
                              (d) => d?.type_of_url === "1"
                            );

                            return (
                              <div className="flex gap-2 bg-greyishV2 rounded-xl p-2 w-full border border-white/15">
                                <img
                                  src={thumbnail?.url_link ?? ""}
                                  alt=""
                                  className="rounded-xl overflow-hidden aspect-[16/12] min-w-[25%] w-3/12 object-cover object-top"
                                />
                                <div className="flex flex-col gap-2">
                                  <p className="text-medium">{item?.video_title}</p>
                                  <p className="line-clamp-3 text-xs overflow-hidden overflow-ellipsis">
                                    {item?.video_description}
                                  </p>
                                </div>
                                <div className="py-3 px-2 flex items-center">
                                  <FaTrash
                                    className="text-red-600 cursor-pointer"
                                    onClick={() =>
                                      handleChapterVideoDelete(item?._id, item?.videoObjId)
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                          {chapterVideos?.length === 0 && (
                            <li className="text-center py-10 text-greyish">No Videos Added</li>
                          )}
                        </ul>
                      </div>
                      <div className="w-full flex-col gap-4">
                        <p className="border-b border-white/30 pb-3 flex items-center gap-3">
                          Journals{" "}
                          <button
                            className="flex items-center gap-0.5 pl-2 pr-4 text-xs rounded-xl border border-yellowish py-1"
                            onClick={() => setShowJournalLibraryModal(true)}
                          >
                            <BsPlus className="text-lg" /> Add
                          </button>
                        </p>
                        <ul className="w-full flex flex-col gap-2 py-3 h-[35vh] overflow-y-auto pr-2 mb-4">
                          {chapterJournals?.map((item) => {
                            const thumbnail = item?.bg_Images?.find((d) => d?.type_of_url === "1");

                            return (
                              <div className="flex gap-2 bg-greyishV2 rounded-xl p-2 w-full border border-white/15">
                                <img
                                  src={thumbnail?.url_link ?? ""}
                                  alt=""
                                  className="rounded-xl overflow-hidden aspect-[16/12] min-w-[25%] w-3/12 object-cover object-top"
                                />
                                <div className="flex flex-col gap-2">
                                  <p className="text-medium">{item?.journal_title}</p>
                                  <p className="line-clamp-3 text-xs overflow-hidden overflow-ellipsis">
                                    {item?.journal_description}
                                  </p>
                                </div>
                                <div className="py-3 px-2 flex items-center">
                                  <FaTrash
                                    className="text-red-600 cursor-pointer"
                                    onClick={() =>
                                      handleChapterJournalDelete(item?._id, item?.videoObjId)
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                          {chapterVideos?.length === 0 && (
                            <li className="text-center py-10 text-greyish">No Videos Added</li>
                          )}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <p className="text-center w-full py-16">Select a chapter to add Items</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <VideoLibraryModal
        showVideoLibraryModal={showVideoLibraryModal}
        setShowVideoLibraryModal={setShowVideoLibraryModal}
        handleVideoSelection={handleVideoSelection}
      />

      <JournalLibraryModal
        showVideoLibraryModal={showJournalLibraryModal}
        setShowVideoLibraryModal={setShowJournalLibraryModal}
        handleJournalSelection={handleJournalSelection}
      />
    </div>
  );
};

export default Bible;
