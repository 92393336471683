import * as actionTypes from "../ActionTypes";

export const layoutDashboardAction = (payload) => {
  return {
    type: actionTypes.LAYOUT_DASHBOARD_REQUEST,
    payload: payload,
  };
};

export const videosDashboardAction = (payload) => {
  return {
    type: actionTypes.VIDEOS_DASHBOARD_REQUEST,
    payload: payload,
  };
};

export const saveLayoutAction = (payload) => {
  return {
    type: actionTypes.SAVE_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const updateLayoutAction = (payload) => {
  return {
    type: actionTypes.UPDATE_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const deleteLayoutAction = (payload) => {
  return {
    type: actionTypes.DELETE_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const readLayoutAction = (payload) => {
  return {
    type: actionTypes.READ_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const saveCategoryLayoutAction = (payload) => {
  return {
    type: actionTypes.SAVE_CATEGORY_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const updateCategoryLayoutAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CATEGORY_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const deleteCategoryLayoutAction = (payload) => {
  return {
    type: actionTypes.DELETE_CATEGORY_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const readCategoryLayoutAction = (payload) => {
  return {
    type: actionTypes.READ_CATEGORY_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const saveCurriculumLayoutAction = (payload) => {
  return {
    type: actionTypes.SAVE_CURRICULUM_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const readCurriculumLayoutAction = (payload) => {
  return {
    type: actionTypes.READ_CURRICULUM_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const deleteCurriculumLayoutAction = (payload) => {
  return {
    type: actionTypes.DELETE_CURRICULUM_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const updateCurriculumLayoutAction = (payload) => {
  return {
    type: actionTypes.UPDATE_CURRICULUM_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const saveVideoLayoutAction = (payload) => {
  return {
    type: actionTypes.SAVE_VIDEO_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const updateVideoLayoutAction = (payload) => {
  return {
    type: actionTypes.UPDATE_VIDEO_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const readVideoLayoutAction = (payload) => {
  return {
    type: actionTypes.READ_VIDEO_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const deleteVideoLayoutAction = (payload) => {
  return {
    type: actionTypes.DELETE_VIDEO_LAYOUT_REQUEST,
    payload: payload,
  };
};

export const searchVideoUrlAction = (payload) => {
  return {
    type: actionTypes.SEARCH_VIDEO_URL_REQUEST,
    payload: payload,
  };
};
