import { useEffect, useState } from "react";
import {
  translateLanguageAction,
  uploadAssestsAction,
  uploadAssestsV2Action,
  uploadVideosAction,
} from "../../../store/Languages/LanguageAction";
import { useDispatch } from "react-redux";
import {
  getListOfNotificationsAction,
  sendNotificationAction,
  updatedNotificationsAction,
} from "../../../store/Notifications/NotificationAction";
import Toastiy from "../../../components/universal/Toastiy";
import { setShowNavigationAlertAction } from "../../../store/GlobalLoader/GlobalLoaderAction";

const NotificationsListHooks = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const [reloadData, setReloadData] = useState(false);

  const fetchAllNotifications = (page, limit, searchCriteria = {}, callbackFn) => {
    console.log(
      "page",
      page,
      "limit",
      limit,
      "searchCriteria",
      searchCriteria,
      "callbackFn",
      callbackFn
    );
    dispatch(
      getListOfNotificationsAction({
        apiPayloadRequest: {
          page: page,
          limit: limit,
          searchCriteria: searchCriteria,
        },
        callback: (Data) => {
          if (Data?.type === 1) {
            if (callbackFn)
              callbackFn({
                data: Data?.data?.map((d) => ({ ...d, views: d?.views ?? 0 })),
                total: Data?.totalRecords ?? 0,
              });
          } else if (callbackFn) callbackFn({ data: [], total: Data?.totalRecords ?? 0 });
        },
      })
    );
  };

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar, fetchAllNotifications, reloadData, setReloadData };
};

export default NotificationsListHooks;

export const AddNotificationsListHooks = (id) => {
  // search logic here
  const [value, setValue] = useState("");
  const [selected, setSelected] = useState("en");
  const dispatch = useDispatch();

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };
  // add category logic here

  const languages = ["english", "hindi", "tamil"]; // List of languages
  const [activeTab, setActiveTab] = useState(languages[0]); // Set the first language as active
  const [inputs, setInputs] = useState(
    languages.reduce((acc, lang) => {
      acc[lang] = {
        categoryName: "",
        categoryCode: "",
        categoryIcon: null,
        mobileLayout: "horizontal",
        categoryDescription: "",
        sendTo: "vertical",
      };
      return acc;
    }, {})
  );

  const initialNotificationValues = {
    notification_title: "",
    notification_description: "",
    notification_richtext: "",
    notificaiton_type: "0",
    notification_icon_url: "",
    notification_schedule_time: new Date(),
    notification_asset_url: "",
  };

  const [notificationInputs, setNotificationInputs] = useState(initialNotificationValues);

  useEffect(() => {
    return () => setShowNavigationAlert(false);
  }, []);

  const setShowNavigationAlert = (value) => {
    dispatch(setShowNavigationAlertAction(value));
  };

  const handleChange = (event) => {
    const { name, value } = event?.target;

    setNotificationInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    setShowNavigationAlert(true);
  };

  const handleTime = (value) => {
    console.log("value", value);
    if (id !== undefined) {
      dispatch(
        updatedNotificationsAction({
          apiPayloadRequest: {
            _id: id,
            notification_schedule_time: value,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setNotificationInputs((prev) => ({
                ...prev,
                notification_schedule_time: value,
              }));

              Toastiy("Updated the schedule!", 1);
            } else {
              Toastiy("Failed to update the schedule!");
            }
          },
        })
      );
    } else {
      setNotificationInputs((prev) => ({
        ...prev,
        notification_schedule_time: value,
      }));
    }
    setShowNavigationAlert(true);
  };

  const handleFileUpload = (event, type, uploadProgressId = "") => {
    const { name, files } = event.target;
    const formData = new FormData();
    formData.append("file", files[0]);
    if (type === "3") {
      dispatch(
        uploadAssestsV2Action({
          apiPayloadRequest: formData,
          uploadProgressId: uploadProgressId,
          callback: (res) => {
            setNotificationInputs((prevInputs) => ({
              ...prevInputs,
              notification_asset_url: res?.url,
            }));
            setShowNavigationAlert(true);
          },
        })
      );
    } else {
      dispatch(
        uploadAssestsV2Action({
          apiPayloadRequest: formData,
          uploadProgressId: uploadProgressId,
          callback: (res) => {
            setNotificationInputs((prevInputs) => ({
              ...prevInputs,
              notification_asset_url: res?.url,
            }));
            setShowNavigationAlert(true);
          },
        })
      );
    }
  };

  const handleInputChange = (language, field, value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [language]: {
        ...prevInputs[language],
        [field]: value,
      },
    }));
    setShowNavigationAlert(true);
  };

  const handleFileInputChange = (language, field, file) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [language]: {
        ...prevInputs[language],
        [field]: file,
      },
    }));
    setShowNavigationAlert(true);
  };

  const handleCategoryDescriptionChange = (e) => {
    handleInputChange(activeTab, "categoryDescription", e.target.value);
  };

  const handleSave = () => {
    // Implement your save logic here
    const payload = structuredClone(notificationInputs);
    if (notificationInputs?.notificaiton_type === "1") {
      payload.notification_description = payload?.notification_richtext
        ?.replace(/<\/?[^>]+(>|$)/g, "")
        ?.replace(/\r?\n|\r/g, " ");
    }
    dispatch(
      sendNotificationAction({
        apiPayloadRequest: payload,
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Notication Saved Successfully", 1);
            setNotificationInputs(initialNotificationValues);
            setShowNavigationAlert(false);
          } else {
            Toastiy("Notication Save Failed");
          }
        },
      })
    );
  };

  const combineStateKeys = () => {
    const combinedString = `${notificationInputs?.notification_title}" @@@@ "${notificationInputs?.notification_description}`;
    return combinedString;
  };

  const updateStateWithTranslation = () => {
    const combinedText = combineStateKeys();

    dispatch(
      translateLanguageAction({
        apiPayloadRequest: {
          targetcode: selected,
          translatetext: combinedText,
        },
        callback: (res) => {
          if (res?.type === 1) {
            onTranslateSuccess(res?.data);
          } else if (res?.type === 2) {
            Toastiy("Language Translation failed!!");
            Toastiy(res?.message);
          }
        },
      })
    );
  };

  const onTranslateSuccess = (data) => {
    const arrayOfValues = data.split(" @@@@ ");

    setNotificationInputs((preState) => ({
      ...preState,
      notification_title: arrayOfValues[0],
      notification_description: arrayOfValues[1],
    }));
    setShowNavigationAlert(true);
  };

  useEffect(() => {
    if (id !== undefined) {
      dispatch(
        getListOfNotificationsAction({
          apiPayloadRequest: {
            _id: id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setNotificationInputs({
                notification_title: res?.data?.[0]?.notification_title,
                notification_description: res?.data?.[0]?.notification_description,
                notification_icon_url: res?.data?.[0]?.notification_icon_url,
                notification_asset_url: res?.data?.[0]?.notification_asset_url,
                notification_schedule_time: res?.data?.[0]?.notification_schedule_time,
              });
            }
          },
        })
      );
    }
  }, []);

  return {
    value,
    onChangeSearchBar,
    languages,
    activeTab,
    setActiveTab,
    inputs,
    handleInputChange,
    handleFileInputChange,
    handleCategoryDescriptionChange,
    handleSave,
    notificationInputs,
    handleFileUpload,
    handleTime,
    handleChange,
    selected,
    setSelected,
    updateStateWithTranslation,
  };
};
