import { useEffect, useState } from "react";
import logo from "../../assets/icons/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import {
  cmsAdminIconArray,
  curriculumAdminIconArray,
  iconArray,
  navActiveCss,
  navHoverCss,
  superAdminIconArray,
  testimonialAdminIconArray,
} from "../../constants/NavbarConstants";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileAction } from "../../store/Profile/ProfileAction";
import {
  setUserAccessTokenAction,
  updateUserProfileInfoAction,
} from "../../store/Users/UsersAction";
import NavigationConfirmModal from "./NavigationConfirmModal";
import {
  CMS_ADMIN,
  CURRICULUM_ADMIN,
  SUPER_ADMIN,
  TESTIMONIAL_ADMIN,
} from "../../screens/Users/Constants";
import {
  getLanguageListAction,
  updateRawLanguageListAction,
} from "../../store/Languages/LanguageAction";

function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, logout, getAccessTokenSilently } = useAuth0();
  const { userInfo, accessToken } = useSelector((state) => state.user);
  const { rawLanguagesList } = useSelector((state) => state.language);
  const [checkToken, setCheckToken] = useState(localStorage.getItem("token"));

  const [activeMenus, setActiveMenus] = useState([iconArray]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [hovered, setHovered] = useState(true);

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUDIENCE_URL,
      });

      setCheckToken(token);
      return token;
    } catch (error) {
      console.error("Error getting access token:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchToken = async () => {
      if (!isLoading) {
        if (isAuthenticated) {
          if (checkToken === null) {
            const token = await getToken();
            localStorage.setItem("token", token);
            dispatch(setUserAccessTokenAction(token));
          } else {
            dispatch(setUserAccessTokenAction(checkToken));
          }
        } else {
          localStorage.removeItem("token");
          // logout({ returnTo: window.location.origin });
          navigate("/login");
        }
      }
    };

    fetchToken();
  }, [isAuthenticated, checkToken, isLoading]);

  const toggleMenu = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };

  const fetchUserProfileDetails = () => {
    if (!userInfo?._id && accessToken) {
      dispatch(
        getProfileAction({
          callback: (res) => {
            if (res?.type === 1 && res?.data?.length !== 0) {
              dispatch(updateUserProfileInfoAction(res?.data[0]));
            }
          },
        })
      );
    }
  };

  const fetchLanguageList = () => {
    let languageList = localStorage.getItem("languageList");
    languageList = languageList ? JSON.parse(languageList) : null;
    if ((!languageList || languageList?.length === 0) && accessToken) {
      dispatch(
        getLanguageListAction({
          callback: (res) => {
            if (res?.type === 1 && res?.data?.length !== 0) {
              const languageList = {};
              res?.data?.map((d) => (languageList[d.language_code] = d?.language_name));
              dispatch(updateRawLanguageListAction(languageList));
              localStorage.setItem("languageList", JSON.stringify(languageList));
            }
          },
        })
      );
    } else if (!rawLanguagesList || Object.keys(rawLanguagesList ?? {})?.length === 0) {
      dispatch(updateRawLanguageListAction(languageList));
    }
  };

  useEffect(() => {
    fetchUserProfileDetails();
    fetchLanguageList();
  }, [userInfo, accessToken]);

  useEffect(() => {
    if (userInfo?._id) {
      switch (userInfo?.roleCode) {
        case SUPER_ADMIN:
          setActiveMenus(superAdminIconArray);
          break;
        case CMS_ADMIN:
          setActiveMenus(cmsAdminIconArray);
          break;
        case TESTIMONIAL_ADMIN:
          setActiveMenus(testimonialAdminIconArray);
          break;
        case CURRICULUM_ADMIN:
          setActiveMenus(curriculumAdminIconArray);
          break;
        default:
          setActiveMenus(iconArray);
      }
    }
  }, [userInfo]);

  return (
    <div className="">
      <NavigationConfirmModal />
      <div className="md:block hidden DESKTOP-NAV max-h-[96vh] h-full py-0 w-24 fixed top-5 z-20">
        <div className="h-full first-letter: flex flex-col items-center bg-blackishV2 p-2 rounded-2xl border border-greyishV2 border-opacity-50 overflow-auto">
          <Link to={"/"}>
            <img src={logo} alt="" className="px-2" />
          </Link>

          <p className="text-sm text-greyish mt-4 mb-2">Menus</p>
          <ul className="ICON-DIV flex flex-col items-center gap-4">
            {iconArray.map((item, i) => (
              <NavLink
                key={i}
                to={isAuthenticated ? item.route : "/login"}
                data-tooltip-id="my-tooltip"
                data-tooltip-content={item.title}
                className={({ isActive, isPending }) =>
                  isPending ? "" : isActive ? `${navActiveCss}` : `${navHoverCss}`
                }
              >
                <li className={`relative cursor-pointer p-3`}>
                  <img src={item.icon} alt="menu icon" />
                </li>
                <Tooltip className="z-50" id="my-tooltip" place="right" />
              </NavLink>
            ))}
          </ul>
        </div>
      </div>
      <nav className="MOBILE-NAV md:hidden flex items-center justify-between bg-blackish shadow-lg fixed top-0 left-0 p-4 z-[70] w-full">
        <div className="flex items-center justify-between w-full">
          <div
            className={`hamburger-icon cursor-pointer ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <div className="hamburger-line"></div>
            <div className="hamburger-line"></div>
            <div className="hamburger-line"></div>
          </div>

          <Link className="text-white ml-4 font-bold" to={"/login"}>
            <img className="h-12 w-12 object-contain cursor-pointer" src={logo} alt="" />
          </Link>
        </div>

        <div
          onMouseOver={() => setHovered(true)}
          className={`mobile-menu ${menuOpen ? "open" : ""}`}
        >
          <p className="text-lg text-greyish mt-4 mb-2 text-center">Menus</p>
          <ul className="ICON-DIV flex flex-col items-center gap-4">
            {iconArray.map((item, i) => (
              <NavLink
                key={i}
                to={isAuthenticated ? item.route : "/login"}
                className={({ isActive, isPending }) =>
                  isPending ? "" : isActive ? `${navActiveCss} h-10 ` : `${navHoverCss} h-10 `
                }
              >
                <li className={`relative cursor-pointer p-2`}>
                  <img
                    src={hovered ? (item?.hover ? item?.hover : item.icon) : item.icon}
                    alt="menu icon"
                  />
                </li>
              </NavLink>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
