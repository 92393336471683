import SearchBar from "../../components/universal/SearchBar";
import {
  allUsersHeaders,
  APP_USER,
  CMS_ADMIN,
  CURRICULUM_ADMIN,
  SUPER_ADMIN,
  TESTIMONIAL_ADMIN,
} from "./Constants";
import CustomTable from "../../components/universal/CustomTable";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AllUserHooks } from "./hooks/UsersHooks";
import Navbar from "../../components/universal/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getCMSUserAction, updateCMSUserAction } from "../../store/Users/UsersAction";
import Toastiy from "../../components/universal/Toastiy";
import CustomCarousel from "../../components/universal/CustomCarousel";
import SingleTab from "../../components/universal/SingleTab";
import { useState } from "react";

function AllUsers() {
  // search bar component state with handler
  const [activeTab, setActiveTab] = useState("All");
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    value,
    onChangeSearchBar,
    reloadData,
    setReloadData,
    searchCriteria,
    setSearchCriteria,
    setCurrentPage,
  } = AllUserHooks(activeTab);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);

  console.log("page main activeTab", activeTab);

  const fetchAllUsers = (page, limit, searchCriteriaKeys = {}, callbackFn) => {
    let actualSearchCriteria = searchCriteriaKeys;

    if (searchParams.has("filter")) {
      actualSearchCriteria = { ...actualSearchCriteria, active: searchParams.get("filter") };
    }

    const actualActiveTab =
      searchCriteriaKeys?.hasOwnProperty("activeTab") && searchCriteriaKeys?.activeTab
        ? searchCriteriaKeys?.activeTab
        : activeTab;

    console.log("user activeTab", activeTab);
    console.log("user actualActiveTab", actualActiveTab);
    console.log("user searchCriteriaKeys", searchCriteriaKeys);

    if (searchCriteriaKeys?.hasOwnProperty("activeTab")) {
      delete searchCriteriaKeys["activeTab"];
    }

    if (actualActiveTab) {
      switch (actualActiveTab) {
        case "All":
          break;
        case "Super Admin":
          actualSearchCriteria = { ...actualSearchCriteria, roleCode: SUPER_ADMIN };
          break;
        case "CMS Admin":
          actualSearchCriteria = { ...actualSearchCriteria, roleCode: CMS_ADMIN };
          break;
        case "Testimonial Admin":
          actualSearchCriteria = { ...actualSearchCriteria, roleCode: TESTIMONIAL_ADMIN };
          break;
        case "Curriculum Admin":
          actualSearchCriteria = { ...actualSearchCriteria, roleCode: CURRICULUM_ADMIN };
          break;
        case "App Users":
          actualSearchCriteria = { ...actualSearchCriteria, roleCode: APP_USER };
          break;
        default:
          break;
      }
    }

    console.log("searchCriteriaKeys actualSearchCriteria", actualSearchCriteria);

    dispatch(
      getCMSUserAction({
        apiPayloadRequest: {
          page: page,
          limit: limit,
          searchCriteria: actualSearchCriteria,
        },
        callback: (Data) => {
          if (Data?.type === 1) {
            if (callbackFn)
              callbackFn({
                data: Data?.data?.map((d) => ({ ...d, views: d?.views ?? 0 })),
                total: Data?.totalRecords ?? 0,
              });
          } else if (callbackFn) callbackFn({ data: [], total: Data?.totalRecords ?? 0 });
        },
      })
    );
  };

  const handleToggleStatus = (row) => {
    console.log("row item1", row);
    dispatch(
      updateCMSUserAction({
        apiPayloadRequest: {
          userAuthId: row?.userAuthId,
          firstName: row?.firstName || "",
          lastName: row?.lastName || "",
          mobile: row?.mobile || "",
          email: row?.email || "",
          language_prefference: row?.language_prefference || "",
          profilePicUrl: row?.profilePicUrl || "",
          active: !row?.active ? 1 : 0,
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("User Status Updated!", 1);
            setReloadData(true);
          } else {
            Toastiy("User Status Failed To Updated!");
          }
        },
      })
    );
  };

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <p className=" text-white text-3xl font-medium">Users</p>
              <div className="flex gap-4">
                {/* <Link to={"/allLanguages"}> */}
                {/* <button className="flex items-center gap-16 text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                  All
                  <HiOutlineFilter />
                </button> */}
                {/* </Link> */}
                <Link to={"/users/add"}>
                  {" "}
                  <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
                    <AiOutlinePlus /> Add CMS User
                  </button>
                </Link>
              </div>
            </div>
            <div className="">
              <CustomTable
                searchFor="Users"
                width={"w-24"}
                // giving extra width to 2nd column
                nthChildWidth={"[&>*:nth-child(1)]:w-40 [&>*:nth-child(3)]:w-40"}
                nthChildColor={
                  "[&>*:nth-child(2)]:text-greyish [&>*:nth-child(3)]:text-greyish [&>*:nth-child(4)]:text-greyish"
                }
                data={[]}
                headers={allUsersHeaders}
                customDataFetch={true}
                customDataFetchFunction={fetchAllUsers}
                reloadData={reloadData}
                setReloadData={setReloadData}
                updateCustomCurrentPage={setCurrentPage}
                updateCustomSearchCriteria={setSearchCriteria}
                reverseTable={true}
                handleToggleClick={(row, isActive) => {
                  console.log(isActive, row);
                  handleToggleStatus(row);
                }}
                handleEyeClick={(row) => {
                  console.log(row);
                  navigate("/users/" + row?._id, {
                    state: { _id: row?._id, userAuthId: row?.userAuthId },
                  });
                }}
                {...(userInfo?.roleCode === SUPER_ADMIN
                  ? {
                      handleEditClick: (row) => {
                        console.log(row);
                        navigate("/users/add", { state: { _id: row?._id } });
                      },
                    }
                  : {})}
                heightDesktop={"h-[72vh]"}
                customTabsClickHandler={({
                  e,
                  fetchCustomData,
                  setCurrentPage: setTableCurrentPage,
                  searchCriteria: tableSearchCriteria,
                }) => {
                  console.log("tableSearchCriteria", tableSearchCriteria);
                  fetchCustomData(1, { ...tableSearchCriteria, activeTab: e.target.value });
                  setTableCurrentPage(1);
                }}
                customTabs={
                  <CustomCarousel
                    className="ml-10"
                    rows={1}
                    cols={5}
                    gapX={12}
                    gapY={0}
                    // easier to pass this array like this rather than separating it
                    completeSliders={[
                      <SingleTab activeTab={activeTab} tab={"All"} />,
                      <SingleTab activeTab={activeTab} tab={"Super Admin"} />,
                      <SingleTab activeTab={activeTab} tab={"CMS Admin"} />,
                      <SingleTab activeTab={activeTab} tab={"Testimonial Admin"} />,
                      <SingleTab activeTab={activeTab} tab={"Curriculum Admin"} />,
                      <SingleTab activeTab={activeTab} tab={"App Users"} />,
                    ]}
                    numberOfCardsShownInSingleSlide={5}
                    handleCardOnClick={(card) => {
                      setActiveTab((prev) => card.props.tab);
                    }}
                    topNext={"top-3"}
                    topPrev={"top-9"}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllUsers;
