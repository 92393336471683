import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteCurriculumLessonAction,
  deleteCurriculumLessonSlideAction,
  readCurriculumAction,
  readLanguageAction,
  saveBGCurriculumAction,
  saveBGCurriculumLanguageAction,
  saveCommonBGCurriculumAction,
  saveCurriculumAction,
  saveCurriculumLanguageAction,
  saveCurriculumLanguageLessonSlideAction,
  saveCurriculumLanguageLessonSlideTypeAction,
  saveCurriculumLanguageLessonSlideTypeBGAction,
  saveCurriculumLanguageLessonThumbNailAction,
  saveCurriculumLanguagePreviewAction,
  saveCurriculumLessonAction,
  saveCurriculumLessonBGSlideAction,
  saveCurriculumLessonCommonThumbNailAction,
  saveCurriculumLessonLanguageAction,
  saveCurriculumLessonPreviewAction,
  saveCurriculumLessonPreviewLanguageAction,
  saveCurriculumLessonSlideAction,
  saveCurriculumLessonSlideLanguageAction,
  saveCurriculumLessonSlideTypeAction,
  saveCurriculumLessonSlideTypeBGAction,
  saveCurriculumLessonSlideTypeLinkAction,
  saveCurriculumLessonThumbNailAction,
  saveCurriculumPreviewAction,
  translateLanguageAction,
  updateBGCurriculumAction,
  updateBGCurriculumLanguageAction,
  updateCommonBGCurriculumAction,
  updateCurriculumAction,
  updateCurriculumLanguageAction,
  updateCurriculumLanguageLessonSlideTypeAction,
  updateCurriculumLanguageLessonSlideTypeBGAction,
  updateCurriculumLanguageLessonThumbNailAction,
  updateCurriculumLanguagePreviewAction,
  updateCurriculumLessonAction,
  updateCurriculumLessonBGSlideAction,
  updateCurriculumLessonCommonThumbNailAction,
  updateCurriculumLessonLanguageAction,
  updateCurriculumLessonPreviewAction,
  updateCurriculumLessonPreviewLanguageAction,
  updateCurriculumLessonSlideAction,
  updateCurriculumLessonSlideLanguageAction,
  updateCurriculumLessonSlideTypeAction,
  updateCurriculumLessonSlideTypeBGAction,
  updateCurriculumLessonThumbNailAction,
  updateCurriculumPreviewAction,
  uploadAssestsV2Action,
  uploadVideosV2Action,
} from "../../../store/Languages/LanguageAction";
import Toastiy from "../../../components/universal/Toastiy";
import { useNavigate } from "react-router-dom";
import {
  deleteCurriculumLayoutAction,
  readCurriculumLayoutAction,
  saveCurriculumLayoutAction,
  updateCurriculumLayoutAction,
} from "../../../store/Layout/LayoutAction";
import ConfirmToast from "../../../components/universal/ConfirmToast";
import { confirmAlert } from "react-confirm-alert";
import ReactPlayer from "react-player";

import { setShowNavigationAlertAction } from "../../../store/GlobalLoader/GlobalLoaderAction";
import { sendCustomNotificationAction } from "../../../store/Notifications/NotificationAction";

export const CurriculumHooksNew = (setLoading, curriculum_id, setCurriculumId) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //layout States
  const initialLayoutValues = {
    _id: "",
    mobile_layout: "1",
    layout_name: "",
    order: "",
    // title: "",
    // subtitle: "",
    // expiry: "",
  };
  const [layoutInfo, setLayoutInfo] = useState(false);
  const [layoutValues, setLayoutValues] = useState(initialLayoutValues);
  const [addedLayout, setAddedLayout] = useState(false);
  const [editTargetLayout, setEditTargetLayout] = useState("");
  const [isLayoutEdit, setIsLayoutEdit] = useState(false);
  const [layoutArray, setLayoutArray] = useState([]);
  const [editLayoutIndex, setEditLayoutIndex] = useState(null);
  const [forceEditorUpdate, setForceEditorUpdate] = useState(false);
  const [curriculumStats, setCurriculumStats] = useState({});
  const [curriculumPublishLoading, setCurriculumPublishLoading] = useState(false);

  useEffect(() => {
    return () => setShowNavigationAlert(false);
  }, []);

  const setShowNavigationAlert = (value) => {
    dispatch(setShowNavigationAlertAction(value));
  };

  //save curriculum language
  const handleSaveLanguage = () => {
    dispatch(
      saveCurriculumLanguageAction({
        apiPayloadRequest: {
          _id: curriculum_id,
          language_code: activeTab,
          curriculum_name: curriculumValues.curriculum_name
            ? curriculumValues.curriculum_name
            : "No Data",
          curriculum_description: curriculumValues.curriculum_description,
        },
      })
    );
    currCurriculumValues?.lessons.map((lesson) => {
      dispatch(
        saveCurriculumLessonLanguageAction({
          apiPayloadRequest: {
            _id: curriculum_id,
            language_code: activeTab,
            lessonObjId: lesson.lessonObjId,
            lesson_title: lesson.lesson_title,
            lesson_description: lesson.lesson_description,
          },
          callback: (res) => saveSlideLanguageResponce(res, lesson),
        })
      );
    });
  };

  const saveSlideLanguageResponce = (res, lesson) => {
    if (res?.type === 1) {
      lesson?.slides?.map((slide) => {
        dispatch(
          saveCurriculumLanguageLessonSlideAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              language_code: activeTab,
              lessonObjId: lesson.lessonObjId,
              sldieObjId: slide.sldieObjId,
              slide_title: slide?.slide_title,
              slide_description: slide?.slide_description,
            },
            callback: (res) => console.log(res),
          })
        );
      });
      setShowNavigationAlert(false);
    }
  };

  //slide delete
  const handleDeleteSlide = ({ slideObjId, lessonObjId, lessonIndex, slideIndex }) => {
    dispatch(
      deleteCurriculumLessonSlideAction({
        apiPayloadRequest: {
          _id: curriculum_id,
          lessonObjId: lessonObjId,
          slideObjId: slideObjId,
        },
        callback: (res) => {
          if (res?.type === 1) {
            const updatedCurriculumValues = structuredClone(curriculumValues);
            updatedCurriculumValues?.lessons?.[lessonIndex]?.slides?.splice(slideIndex, 1);
            setCurriculumValues(updatedCurriculumValues);
            Toastiy("Slide Deleted Successfully!", 1);
          } else {
            Toastiy("Something went wrong, please try again");
          }
        },
      })
    );
  };

  //lesson delete
  const handleDeleteLesson = ({ lessonObjId, lessonIndex }) => {
    dispatch(
      deleteCurriculumLessonAction({
        apiPayloadRequest: {
          _id: curriculum_id,
          lessonObjId: lessonObjId,
        },
        callback: (res) => {
          if (res?.type === 1) {
            const updatedCurriculumValues = structuredClone(curriculumValues);
            updatedCurriculumValues?.lessons?.splice(lessonIndex, 1);
            setCurriculumValues(updatedCurriculumValues);
            Toastiy("Lesson Deleted Successfully!", 1);
          } else {
            Toastiy("Something went wrong, please try again");
          }
        },
      })
    );
  };

  //slide video library selection
  const handleSlideLibraryVideoSelection = (details) => {
    const updatedCurriculum = structuredClone(curriculumValues);
    updatedCurriculum.lessons[currLessonIndex].slides[currSlideIndex].slide_title =
      details?.video_title;
    updatedCurriculum.lessons[currLessonIndex].slides[currSlideIndex].slide_description =
      details?.video_description;
    updatedCurriculum.lessons[currLessonIndex].slides[currSlideIndex].slide_type[0].bg_asset_url = [
      {
        type_of_url: "0",
        url_link: details?.urls?.find((d) => d?.type_of_url === "0")?.url_link,
      },
      {
        type_of_url: "1",
        url_link: details?.urls?.find((d) => d?.type_of_url === "1")?.url_link,
      },
      {
        type_of_url: "2",
        url_link: details?.urls?.find((d) => d?.type_of_url === "2")?.url_link,
      },
    ];
    setForceEditorUpdate(true);
    setCurriculumValues(updatedCurriculum);
    setShowNavigationAlert(true);
  };

  //lesson trailer video library selection
  const handleLessonLibraryVideoSelection = (details) => {
    const updatedCurriculum = structuredClone(curriculumValues);
    updatedCurriculum.lessons[currLessonIndex].lesson_trailers[0].bg_asset_url = [
      {
        type_of_url: "0",
        url_link: details?.urls?.find((d) => d?.type_of_url === "0")?.url_link,
      },
      {
        type_of_url: "1",
        url_link: details?.urls?.find((d) => d?.type_of_url === "1")?.url_link,
      },
      {
        type_of_url: "2",
        url_link: details?.urls?.find((d) => d?.type_of_url === "2")?.url_link,
      },
    ];
    setCurriculumValues(updatedCurriculum);
    setShowNavigationAlert(true);
  };

  //curriculum trailer video library selection
  const handleCurriculumLibraryVideoSelection = (details) => {
    const updatedCurriculum = structuredClone(curriculumValues);
    updatedCurriculum.trailers[0].bg_asset_url = [
      {
        type_of_url: "0",
        url_link: details?.urls?.find((d) => d?.type_of_url === "0")?.url_link,
      },
      {
        type_of_url: "1",
        url_link: details?.urls?.find((d) => d?.type_of_url === "1")?.url_link,
      },
      {
        type_of_url: "2",
        url_link: details?.urls?.find((d) => d?.type_of_url === "2")?.url_link,
      },
    ];
    setCurriculumValues(updatedCurriculum);
    setShowNavigationAlert(true);
  };

  //layout handles
  const handleVideoLayoutChange = (event) => {
    if (isLayoutEdit) {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));

      setLayoutArray((prev) => {
        const updatedArray = [...prev];

        updatedArray[editLayoutIndex] = {
          ...updatedArray[editLayoutIndex],
          [name]: value,
        };
        return updatedArray;
      });
    } else {
      const { name, value } = event.target;
      setLayoutValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    setShowNavigationAlert(true);
  };

  const handleVideoDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
    setShowNavigationAlert(true);
  };

  const handleLayoutUpdate = () => {
    if (curriculum_id !== undefined && curriculum_id !== "") {
      dispatch(
        updateCurriculumLayoutAction({
          apiPayloadRequest: {
            _id: layoutValues._id,
            curriculumObjId: curriculum_id,
            order: layoutValues?.order,
            // title: layoutArray._id,
            // subtitle: layoutArray._id,
            // expiry: layoutArray._id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setLayoutValues(initialLayoutValues);
              setIsLayoutEdit(false);
              Toastiy("Layout Updated Successfully!", 1);
            }
          },
        })
      );
    } else {
      setLayoutValues(initialLayoutValues);
      setIsLayoutEdit(false);
      setShowNavigationAlert(true);
    }
  };

  const handleAddLayoutToArray = () => {
    if (curriculum_id !== undefined && curriculum_id !== "") {
      dispatch(
        saveCurriculumLayoutAction({
          apiPayloadRequest: {
            _id: layoutValues?._id,
            curriculumObjId: curriculum_id,
            order: layoutValues?.order,
            // title: layoutValues?.title,
            // subtitle: layoutValues?.subtitle,
            // expiry: layoutValues?.expiry,
          },
          callback: (res) => {
            if (res?.type === 1) {
              Toastiy("Layout Added Successfully!", 1);
              setLayoutArray((prev) => [...prev, layoutValues]);
              setLayoutValues(initialLayoutValues);
              setAddedLayout(true);
            }
          },
        })
      );
    } else {
      setLayoutArray((prev) => [...prev, layoutValues]);
      setLayoutValues(initialLayoutValues);
      setAddedLayout(true);
    }
  };

  const handleDateChange = (value) => {
    setLayoutValues((prev) => ({
      ...prev,
      expiry: value,
    }));
  };

  const handleDeleteLayoutFromArray = (id) => {
    if (curriculum_id !== undefined && curriculum_id !== "") {
      dispatch(
        deleteCurriculumLayoutAction({
          apiPayloadRequest: {
            _id: id,
            curriculumObjId: curriculum_id,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const updatedArray = layoutArray.filter((item) => item._id !== id);
              setLayoutArray(updatedArray);
            }
          },
        })
      );
    } else {
      const updatedArray = layoutArray.filter((item) => item._id !== id);
      setLayoutArray(updatedArray);
    }
  };

  const inititialUrlState = [
    {
      type_of_url: "0",
      url_link: "",
    },
    {
      type_of_url: "1",
      url_link: "",
    },
    {
      type_of_url: "2",
      url_link: "",
    },
  ];

  const initialSlideState = {
    slide_title: "",
    slide_description: "",
    slide_bg: [
      {
        type_of_url: "0",
        url_link: "",
      },
      {
        type_of_url: "1",
        url_link: "",
      },
      {
        type_of_url: "2",
        url_link: "",
      },
    ],
    slide_type: [
      {
        bg_asset_url: [
          {
            type_of_url: "0",
            url_link: "",
          },
          {
            type_of_url: "1",
            url_link: "",
          },
          {
            type_of_url: "2",
            url_link: "",
          },
        ],
        slide_bg_color: "#ffffff",
        type_of_slide: "0",
        slide_link_url: [
          {
            type_of_url: "0",
            url_link: "",
          },
          {
            type_of_url: "1",
            url_link: "",
          },
          {
            type_of_url: "2",
            url_link: "",
          },
        ],
      },
    ],
  };

  const initialLessonState = {
    lesson_title: "",
    lesson_duration: "1",
    sortOrder: "",
    lessonPdfUrl: "",
    lesson_description: "",
    lesson_tumbnail_urls: [
      {
        type_of_url: "0",
        url_link: "",
      },
      {
        type_of_url: "1",
        url_link: "",
      },
      {
        type_of_url: "2",
        url_link: "",
      },
    ],
    lesson_generic_tumbnail_urls: [
      {
        type_of_url: "0",
        url_link: "",
      },
      {
        type_of_url: "1",
        url_link: "",
      },
      {
        type_of_url: "2",
        url_link: "",
      },
    ],
    lesson_trailers: [
      {
        type_of_url: "0",
        url_link: "",
      },
      {
        type_of_url: "1",
        url_link: "",
      },
      {
        type_of_url: "2",
        url_link: "",
      },
    ],
    slides: [initialSlideState],
  };

  const initialCurriculumState = {
    curriculum_name: "",
    curriculum_description: "",
    curriculumPdfUrl: "",
    bg_Images: [
      {
        type_of_url: "0",
        url_link: "",
      },
      {
        type_of_url: "1",
        url_link: "",
      },
      {
        type_of_url: "2",
        url_link: "",
      },
    ],
    generic_bg_Images: [
      {
        type_of_url: "0",
        url_link: "",
      },
      {
        type_of_url: "1",
        url_link: "",
      },
      {
        type_of_url: "2",
        url_link: "",
      },
    ],
    trailers: [
      {
        type_of_url: "0",
        url_link: "",
      },
      {
        type_of_url: "1",
        url_link: "",
      },
      {
        type_of_url: "2",
        url_link: "",
      },
    ],
    lessons: [{ ...initialLessonState }],
  };

  const initialCurriculumErrors = {
    curriculum_name: true,
    curriculum_description: true,
    bg_Images: [
      {
        type_of_url: "0",
        url_link: true,
      },
      {
        type_of_url: "1",
        url_link: true,
      },
      {
        type_of_url: "2",
        url_link: true,
      },
    ],
    generic_bg_Images: [
      {
        type_of_url: "0",
        url_link: true,
      },
      {
        type_of_url: "1",
        url_link: true,
      },
      {
        type_of_url: "2",
        url_link: true,
      },
    ],
    trailers: [
      {
        type_of_url: "0",
        url_link: true,
      },
      {
        type_of_url: "1",
        url_link: true,
      },
      {
        type_of_url: "2",
        url_link: true,
      },
    ],
  };

  const intialLessonErrors = {
    lesson_title: true,
    sortOrder: true,
    lessonPdfUrl: true,
    lesson_duration: true,
    lesson_description: true,
    lesson_tumbnail_urls: [
      {
        type_of_url: "0",
        url_link: true,
      },
      {
        type_of_url: "1",
        url_link: true,
      },
      {
        type_of_url: "2",
        url_link: true,
      },
    ],
    lesson_generic_tumbnail_urls: [
      {
        type_of_url: "0",
        url_link: true,
      },
      {
        type_of_url: "1",
        url_link: true,
      },
      {
        type_of_url: "2",
        url_link: true,
      },
    ],
    lesson_trailers: [
      {
        type_of_url: "0",
        url_link: true,
      },
      {
        type_of_url: "1",
        url_link: true,
      },
      {
        type_of_url: "2",
        url_link: true,
      },
    ],
  };

  const intialSlideErrors = {
    slide_title: true,
    slide_description: true,
    slide_bg: [
      {
        type_of_url: "0",
        url_link: true,
      },
      {
        type_of_url: "1",
        url_link: true,
      },
      {
        type_of_url: "2",
        url_link: true,
      },
    ],
    slide_type: [
      {
        bg_asset_url: [
          {
            type_of_url: "0",
            url_link: true,
          },
          {
            type_of_url: "1",
            url_link: true,
          },
          {
            type_of_url: "2",
            url_link: true,
          },
        ],
        slide_bg_color: true,
        type_of_slide: true,
        slide_link_url: [
          {
            type_of_url: "0",
            url_link: true,
          },
          {
            type_of_url: "1",
            url_link: true,
          },
          {
            type_of_url: "2",
            url_link: true,
          },
        ],
      },
    ],
  };
  const [languagemeta, setLanguagemeta] = useState();
  const [isLanguageSave, setIsLanguageSave] = useState(true);
  const [readLanguageData, setReadLanguageData] = useState([]);
  const [activeTab, setActiveTab] = useState("en");
  const [activeLanguageDetails, setActiveLanguageDetails] = useState({
    language_code: "en",
    language_name: "English",
  });
  const [curriculumValues, setCurriculumValues] = useState(initialCurriculumState);

  const [currCurriculumValues, setCurrCurriculumValues] = useState(initialCurriculumState);

  const [curriculumErrors, setCurriculumErrors] = useState(initialCurriculumErrors);

  const [showCurriculumErrors, setShowCurriculumErrors] = useState(false);

  const [lessonErrors, setLessonErrors] = useState(intialLessonErrors);
  const [showLessonErrors, setShowLessonErrors] = useState(false);

  const [slideErrors, setSlideErrors] = useState(intialSlideErrors);
  console.log("lessonErrors", lessonErrors);
  console.log("slideErrors", slideErrors);
  const [showSlideErrors, setShowSlideErrors] = useState(false);

  const [showLessonBox, setShowLessonBox] = useState(false);
  const [showSlideBox, setShowSlideBox] = useState(false);
  const [showCurriculumBox, setShowCurriculumBox] = useState(false);

  const [currLesson, setCurrLesson] = useState(initialLessonState);
  const [currSlide, setCurrSlide] = useState(initialSlideState);

  const [currLessonIndex, setCurrLessonIndex] = useState(0);
  const [currSlideIndex, setCurrSlideIndex] = useState(0);

  const [isLessonEdit, setIsLessonEdit] = useState(false);
  const [isSlideEdit, setIsSlideEdit] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  const keysArray = Object.keys(inititialUrlState);

  console.log("slideErrors", slideErrors);
  console.log("currSlide", currSlide);
  console.log("curriculumValues", curriculumValues);

  const addNewLesson = () => {
    setShowLessonBox(true);
  };

  const addNewSlide = () => {
    setShowSlideBox(true);
  };

  const cancelLesson = () => {
    setShowLessonErrors(false);
    if (!isLessonEdit) {
      const updatedLesson = [
        ...curriculumValues.lessons.filter((_, idx) => idx !== currLessonIndex),
      ];
      setCurriculumValues((prev) => ({
        ...prev,
        lessons: [...updatedLesson, initialLessonState],
      }));
    } else {
      const prevLessons = [...curriculumValues.lessons];

      prevLessons[currLessonIndex] = currLesson;

      setCurriculumValues((prev) => ({
        ...prev,
        lessons: prevLessons,
      }));
    }

    setLessonErrors(intialLessonErrors);
  };

  const cancelCurriculum = () => {
    setShowCurriculumBox(false);
  };

  const cancelSlide = () => {
    setShowSlideErrors(false);
    setSlideErrors(intialSlideErrors);
    setShowSlideBox(false);
    handleClosePreview();
  };

  const cancelSlideModal = () => {
    setShowSlideErrors(false);
    setCurriculumValues((prev) => ({
      ...prev,
      lessons: prev.lessons.map((lesson, lessonIndex) => {
        if (lessonIndex !== currLessonIndex) {
          return lesson;
        }
        if (!isSlideEdit) {
          const updatedSlides = [...lesson?.slides?.filter((_, idx) => idx !== currSlideIndex)];
          return {
            ...lesson,
            slides: [...updatedSlides, initialSlideState],
          };
        } else {
          return {
            ...lesson,
            slides: lesson?.slides?.map((slide, slideIndex) =>
              slideIndex === currSlideIndex ? currSlide : slide
            ),
          };
        }
      }),
    }));
    setSlideErrors(intialSlideErrors);
    setShowSlideBox(false);
    handleClosePreview();
  };

  const saveLesson = () => {
    setShowLessonErrors(false);
    if (!isLessonEdit || !curriculumValues?.lessons[currLessonIndex]?.lessonObjId) {
      if (curriculum_id !== undefined) {
        dispatch(
          saveCurriculumLessonAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              lesson_title: curriculumValues?.lessons[currLessonIndex]?.lesson_title,
              lesson_description: curriculumValues?.lessons[currLessonIndex]?.lesson_description,
              lesson_duration: curriculumValues?.lessons[currLessonIndex]?.lesson_duration,
              sortOrder: curriculumValues?.lessons[currLessonIndex]?.sortOrder,
              lessonPdfUrl: curriculumValues?.lessons[currLessonIndex]?.lessonPdfUrl,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Lesson Draft Saved Successfully!", 1);
                setShowNavigationAlert(false);
                keysArray.map((item) => {
                  dispatch(
                    saveCurriculumLessonThumbNailAction({
                      apiPayloadRequest: {
                        _id: curriculum_id,
                        lesson_title: curriculumValues?.lessons[currLessonIndex]?.lesson_title,
                        lessonObjId: res?.data?.lessonObjId,
                        type_of_url: parseInt(
                          curriculumValues?.lessons[currLessonIndex]?.lesson_tumbnail_urls[item]
                            ?.type_of_url
                        ),

                        url_link:
                          curriculumValues?.lessons[currLessonIndex]?.lesson_tumbnail_urls[item]
                            .url_link,
                      },
                      callback: (res) => {
                        if (res?.type == 1) {
                          console.log("Lesson Thumbnail Saved!");
                          setShowNavigationAlert(false);
                        } else {
                          Toastiy("Failed to Save Lesson Thumbnails");
                        }
                      },
                    })
                  );
                  if (
                    curriculumValues?.lessons[currLessonIndex]?.lesson_generic_tumbnail_urls[item]
                      .url_link
                  ) {
                    dispatch(
                      saveCurriculumLessonCommonThumbNailAction({
                        apiPayloadRequest: {
                          _id: curriculum_id,
                          lesson_title: curriculumValues?.lessons[currLessonIndex]?.lesson_title,
                          lessonObjId: res?.data?.lessonObjId,
                          type_of_url: parseInt(
                            curriculumValues?.lessons[currLessonIndex]
                              ?.lesson_generic_tumbnail_urls[item]?.type_of_url
                          ),

                          url_link:
                            curriculumValues?.lessons[currLessonIndex]
                              ?.lesson_generic_tumbnail_urls[item].url_link,
                        },
                        callback: (res) => {
                          if (res?.type == 1) {
                            console.log("Lesson Common Thumbnail Saved!");
                            setShowNavigationAlert(false);
                          } else {
                            Toastiy("Failed to Save Lesson Common Thumbnails");
                          }
                        },
                      })
                    );
                  }
                  if (curriculumValues?.lessons[currLessonIndex]?.lesson_trailers[item].url_link) {
                    dispatch(
                      saveCurriculumLessonPreviewAction({
                        apiPayloadRequest: {
                          _id: curriculum_id,
                          lesson_title: curriculumValues?.lessons[currLessonIndex]?.lesson_title,
                          lessonObjId: res?.data?.lessonObjId,
                          type_of_url: parseInt(
                            curriculumValues?.lessons[currLessonIndex]?.lesson_trailers[item]
                              ?.type_of_url
                          ),

                          url_link:
                            curriculumValues?.lessons[currLessonIndex]?.lesson_trailers[item]
                              .url_link,
                        },
                        callback: (res) => {
                          if (res?.type == 1) {
                            console.log("Lesson Preview Saved!");
                            setShowNavigationAlert(false);
                          } else {
                            Toastiy("Failed to Save Lesson Preview");
                          }
                        },
                      })
                    );
                  }
                });

                setCurriculumValues((prev) => ({
                  ...prev,
                  lessons: [
                    ...prev.lessons?.map((lesson, index) =>
                      index === currLessonIndex
                        ? { ...lesson, lessonObjId: res?.data?.lessonObjId }
                        : lesson
                    ),
                    { ...initialLessonState },
                  ],
                }));
                setShowNavigationAlert(false);

                setShowLessonBox(false);
                setCurrLessonIndex(curriculumValues.lessons.length);
                handlePublishCurriculum(false);
              } else {
                Toastiy("Failed to Save Lesson");
              }
            },
          })
        );
      } else {
        setCurriculumValues((prev) => ({
          ...prev,
          lessons: [...prev.lessons, { ...initialLessonState }],
        }));
        setShowLessonBox(false);
        setCurrLessonIndex(curriculumValues.lessons.length);
        setShowNavigationAlert(true);
      }
    } else {
      if (curriculum_id !== undefined) {
        if (
          currLesson.lesson_title !== curriculumValues.lessons[currLessonIndex].lesson_title ||
          currLesson.lesson_description !==
            curriculumValues.lessons[currLessonIndex].lesson_description ||
          currLesson.lesson_duration !==
            curriculumValues.lessons[currLessonIndex].lesson_duration ||
          currLesson.sortOrder !== curriculumValues.lessons[currLessonIndex].sortOrder ||
          currLesson.lessonPdfUrl !== curriculumValues.lessons[currLessonIndex].lessonPdfUrl
        ) {
          dispatch(
            updateCurriculumLessonAction({
              apiPayloadRequest: {
                _id: curriculum_id,
                lesson_title: curriculumValues?.lessons[currLessonIndex]?.lesson_title,
                lessonObjId: curriculumValues?.lessons[currLessonIndex]?.lessonObjId,
                lesson_description: curriculumValues?.lessons[currLessonIndex]?.lesson_description,
                lesson_duration: curriculumValues?.lessons[currLessonIndex]?.lesson_duration,
                sortOrder: curriculumValues?.lessons[currLessonIndex]?.sortOrder,
                lessonPdfUrl: curriculumValues?.lessons[currLessonIndex]?.lessonPdfUrl,
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Lesson Draft Updated Successfully!", 1);
                  setShowLessonBox(false);
                  setCurrLessonIndex(curriculumValues.lessons.length);
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        }

        keysArray.map((item) => {
          if (
            (!currLesson.lesson_tumbnail_urls?.[item]?.url_link ||
              currLesson.lesson_tumbnail_urls?.length === 0) &&
            curriculumValues?.lessons?.[currLessonIndex]?.lesson_tumbnail_urls?.[item]?.url_link
          ) {
            dispatch(
              saveCurriculumLessonThumbNailAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  lessonObjId: curriculumValues?.lessons?.[currLessonIndex]?.lessonObjId,
                  type_of_url: parseInt(
                    curriculumValues?.lessons[currLessonIndex]?.lesson_tumbnail_urls?.[item]
                      ?.type_of_url
                  ),

                  url_link:
                    curriculumValues?.lessons?.[currLessonIndex]?.lesson_tumbnail_urls[item]
                      .url_link,
                },
                callback: (res) => {
                  if (res?.type == 1) {
                    Toastiy("Lesson Thumbnail Saved!", 1);
                    setShowNavigationAlert(false);
                  } else {
                    Toastiy("Failed to Save Lesson Thumbnails");
                  }
                },
              })
            );
          } else if (
            currLesson.lesson_tumbnail_urls?.[item]?.url_link !==
            curriculumValues?.lessons?.[currLessonIndex]?.lesson_tumbnail_urls?.[item]?.url_link
          ) {
            dispatch(
              updateCurriculumLessonThumbNailAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  lessonObjId: curriculumValues?.lessons[currLessonIndex]?.lessonObjId,
                  type_of_url: parseInt(
                    curriculumValues?.lessons[currLessonIndex]?.lesson_tumbnail_urls[item]
                      ?.type_of_url
                  ),

                  url_link:
                    curriculumValues?.lessons[currLessonIndex]?.lesson_tumbnail_urls[item].url_link,
                },
                callback: (res) => {
                  if (res?.type == 1) {
                    Toastiy("Lesson Thumbnail Updated!", 1);
                    setShowNavigationAlert(false);
                  } else {
                    Toastiy("Failed to Update Lesson Thumbnails");
                  }
                },
              })
            );
          }
          if (
            (!currLesson.lesson_generic_tumbnail_urls?.[item]?.url_link ||
              currLesson.lesson_generic_tumbnail_urls?.length === 0) &&
            curriculumValues?.lessons?.[currLessonIndex]?.lesson_generic_tumbnail_urls?.[item]
              ?.url_link
          ) {
            dispatch(
              saveCurriculumLessonCommonThumbNailAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  lessonObjId: curriculumValues?.lessons?.[currLessonIndex]?.lessonObjId,
                  type_of_url: parseInt(
                    curriculumValues?.lessons[currLessonIndex]?.lesson_generic_tumbnail_urls?.[item]
                      ?.type_of_url
                  ),

                  url_link:
                    curriculumValues?.lessons?.[currLessonIndex]?.lesson_generic_tumbnail_urls[item]
                      .url_link,
                },
                callback: (res) => {
                  if (res?.type == 1) {
                    Toastiy("Lesson Common Thumbnail Saved!", 1);
                    setShowNavigationAlert(false);
                  } else {
                    Toastiy("Failed to Save Lesson Common Thumbnails");
                  }
                },
              })
            );
          } else if (
            currLesson.lesson_generic_tumbnail_urls?.[item]?.url_link !==
              curriculumValues?.lessons?.[currLessonIndex]?.lesson_generic_tumbnail_urls?.[item]
                ?.url_link &&
            curriculumValues?.lessons?.[currLessonIndex]?.lesson_generic_tumbnail_urls?.[item]
              ?.url_link
          ) {
            dispatch(
              updateCurriculumLessonCommonThumbNailAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  lessonObjId: curriculumValues?.lessons[currLessonIndex]?.lessonObjId,
                  type_of_url: parseInt(
                    curriculumValues?.lessons[currLessonIndex]?.lesson_generic_tumbnail_urls[item]
                      ?.type_of_url
                  ),

                  url_link:
                    curriculumValues?.lessons[currLessonIndex]?.lesson_generic_tumbnail_urls[item]
                      .url_link,
                },
                callback: (res) => {
                  if (res?.type == 1) {
                    Toastiy("Lesson Common Thumbnail Updated!", 1);
                    setShowNavigationAlert(false);
                  } else {
                    Toastiy("Failed to Update Lesson Common Thumbnails");
                  }
                },
              })
            );
          }
          if (
            (!currLesson.lesson_trailers?.[item]?.url_link ||
              currLesson.lesson_trailers?.length === 0) &&
            curriculumValues?.lessons?.[currLessonIndex]?.lesson_trailers?.[item]?.url_link
          ) {
            dispatch(
              saveCurriculumLessonPreviewAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  lessonObjId: curriculumValues?.lessons?.[currLessonIndex]?.lessonObjId,
                  type_of_url: parseInt(
                    curriculumValues?.lessons[currLessonIndex]?.lesson_trailers?.[item]?.type_of_url
                  ),

                  url_link:
                    curriculumValues?.lessons?.[currLessonIndex]?.lesson_trailers[item].url_link,
                },
                callback: (res) => {
                  if (res?.type == 1) {
                    Toastiy("Lesson Preview Saved!", 1);
                    setShowNavigationAlert(false);
                  } else {
                    Toastiy("Failed to Save Lesson Preview");
                  }
                },
              })
            );
          } else if (
            currLesson.lesson_trailers?.[item]?.url_link !==
              curriculumValues?.lessons?.[currLessonIndex]?.lesson_trailers?.[item]?.url_link &&
            curriculumValues?.lessons?.[currLessonIndex]?.lesson_trailers?.[item]?.url_link
          ) {
            dispatch(
              updateCurriculumLessonPreviewAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  lessonObjId: curriculumValues?.lessons[currLessonIndex]?.lessonObjId,
                  type_of_url: parseInt(
                    curriculumValues?.lessons[currLessonIndex]?.lesson_trailers[item]?.type_of_url
                  ),

                  url_link:
                    curriculumValues?.lessons[currLessonIndex]?.lesson_trailers[item].url_link,
                },
                callback: (res) => {
                  if (res?.type == 1) {
                    Toastiy("Lesson Preview Updated!", 1);
                    setShowNavigationAlert(false);
                  } else {
                    Toastiy("Failed to Update Lesson Preview");
                  }
                },
              })
            );
          }
        });
        setShowLessonBox(false);
        handlePublishCurriculum(false);
      } else {
        setShowLessonBox(false);
        setShowNavigationAlert(true);
        setCurrLessonIndex(curriculumValues?.lessons?.length);
      }
    }
  };

  const saveLessonLanguage = async (selectedLessonIndex, refetchDetails = true, callback) => {
    setShowLessonErrors(false);
    const actualLessonIndex = selectedLessonIndex ?? currLessonIndex;
    const data = languagemeta?.filter((item) => item?.language_code === activeTab)[0];
    const requestsList = [];
    const lessonLangIndex = data?.lessons?.findIndex(
      (d) => d?.lessonObjId === curriculumValues?.lessons[actualLessonIndex]?.lessonObjId
    );
    if (!data?.lessons?.[lessonLangIndex]) {
      requestsList.push(
        new Promise((resolve, reject) => {
          dispatch(
            saveCurriculumLessonLanguageAction({
              apiPayloadRequest: {
                _id: curriculum_id,
                language_code: activeTab,
                lessonObjId: curriculumValues?.lessons[actualLessonIndex]?.lessonObjId,
                lesson_title: curriculumValues?.lessons[actualLessonIndex]?.lesson_title,
                lesson_description:
                  curriculumValues?.lessons[actualLessonIndex]?.lesson_description,
                sortOrder: curriculumValues?.lessons[actualLessonIndex]?.sortOrder,
                lessonPdfUrl: curriculumValues?.lessons[actualLessonIndex]?.lessonPdfUrl,
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Lesson Draft Updated Successfully!", 1);
                  keysArray.map((item) => {
                    if (
                      curriculumValues?.lessons[actualLessonIndex]?.lesson_tumbnail_urls?.[item]
                        ?.url_link
                    ) {
                      requestsList.push(
                        new Promise((resolve, reject) => {
                          dispatch(
                            saveCurriculumLanguageLessonThumbNailAction({
                              apiPayloadRequest: {
                                _id: curriculum_id,
                                language_code: activeTab,
                                lessonObjId:
                                  curriculumValues?.lessons[actualLessonIndex]?.lessonObjId,
                                type_of_url: parseInt(
                                  curriculumValues?.lessons?.[actualLessonIndex]
                                    ?.lesson_tumbnail_urls?.[item]?.type_of_url
                                ),

                                url_link:
                                  curriculumValues?.lessons?.[actualLessonIndex]
                                    ?.lesson_tumbnail_urls?.[item]?.url_link,
                              },
                              callback: (res) => {
                                if (res?.type == 1) {
                                  Toastiy("Lesson Thumbnail Saved!", 1);
                                  setShowNavigationAlert(false);
                                } else {
                                  Toastiy("Failed to Save Lesson Thumbnail");
                                }
                                resolve();
                              },
                            })
                          );
                        })
                      );
                    }
                  });

                  keysArray.map((item) => {
                    if (
                      curriculumValues?.lessons[actualLessonIndex]?.lesson_trailers?.[item]
                        ?.url_link
                    ) {
                      requestsList.push(
                        new Promise((resolve, reject) => {
                          dispatch(
                            saveCurriculumLessonPreviewLanguageAction({
                              apiPayloadRequest: {
                                _id: curriculum_id,
                                language_code: activeTab,
                                lessonObjId:
                                  curriculumValues?.lessons[actualLessonIndex]?.lessonObjId,
                                type_of_url: parseInt(
                                  curriculumValues?.lessons?.[actualLessonIndex]?.lesson_trailers?.[
                                    item
                                  ]?.type_of_url
                                ),

                                url_link:
                                  curriculumValues?.lessons?.[actualLessonIndex]?.lesson_trailers[
                                    item
                                  ].url_link,
                              },
                              callback: (res) => {
                                if (res?.type == 1) {
                                  Toastiy("Lesson Preview Saved!", 1);
                                  setShowNavigationAlert(false);
                                } else {
                                  Toastiy("Failed to Save Lesson Preview");
                                }
                                resolve();
                              },
                            })
                          );
                        })
                      );
                    }
                  });
                }
                resolve();
              },
            })
          );
        })
      );
    } else {
      if (
        curriculumValues?.lessons?.[actualLessonIndex]?.lesson_title !==
          data?.lessons?.[lessonLangIndex]?.lesson_title ||
        curriculumValues?.lessons?.[actualLessonIndex]?.lesson_description !==
          data?.lessons?.[lessonLangIndex]?.lesson_description ||
        curriculumValues?.lessons?.[actualLessonIndex]?.sortOrder !==
          data?.lessons?.[lessonLangIndex]?.sortOrder ||
        curriculumValues?.lessons?.[actualLessonIndex]?.lessonPdfUrl !==
          data?.lessons?.[lessonLangIndex]?.lessonPdfUrl
      ) {
        requestsList.push(
          new Promise((resolve, reject) => {
            dispatch(
              updateCurriculumLessonLanguageAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  language_code: activeTab,
                  lesson_title: curriculumValues?.lessons?.[actualLessonIndex]?.lesson_title,
                  lessonObjId: curriculumValues?.lessons?.[actualLessonIndex]?.lessonObjId,
                  lesson_description:
                    curriculumValues?.lessons?.[actualLessonIndex]?.lesson_description,
                  sortOrder: curriculumValues?.lessons?.[actualLessonIndex]?.sortOrder,
                  lessonPdfUrl: curriculumValues?.lessons?.[actualLessonIndex]?.lessonPdfUrl,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Lesson Draft Updated Successfully!", 1);
                    setCurrLessonIndex(curriculumValues?.lessons?.length);
                    setShowNavigationAlert(false);
                  }
                  resolve();
                },
              })
            );
          })
        );
      }

      keysArray.map((item) => {
        if (
          (!data?.lessons?.[lessonLangIndex]?.lesson_tumbnail_urls?.[item]?.url_link ||
            data?.lessons?.[lessonLangIndex]?.lesson_tumbnail_urls?.length === 0) &&
          curriculumValues?.lessons?.[actualLessonIndex]?.lesson_tumbnail_urls?.[item]?.url_link
        ) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                saveCurriculumLanguageLessonThumbNailAction({
                  apiPayloadRequest: {
                    _id: curriculum_id,
                    language_code: activeTab,
                    lessonObjId: curriculumValues?.lessons?.[actualLessonIndex]?.lessonObjId,
                    type_of_url: parseInt(
                      curriculumValues?.lessons[actualLessonIndex]?.lesson_tumbnail_urls?.[item]
                        ?.type_of_url
                    ),
                    url_link:
                      curriculumValues?.lessons?.[actualLessonIndex]?.lesson_tumbnail_urls?.[item]
                        ?.url_link,
                  },
                  callback: (res) => {
                    if (res?.type == 1) {
                      Toastiy("Lesson Thumbnail Updated!", 1);
                      setShowNavigationAlert(false);
                    } else {
                      Toastiy("Failed to Update Lesson Thumbnail");
                    }
                    resolve();
                  },
                })
              );
            })
          );
        } else if (
          curriculumValues?.lessons?.[actualLessonIndex]?.lesson_tumbnail_urls?.[item]?.url_link !==
            data?.lessons?.[lessonLangIndex]?.lesson_tumbnail_urls?.[item]?.url_link &&
          curriculumValues?.lessons?.[actualLessonIndex]?.lesson_tumbnail_urls?.[item]?.url_link
        ) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                updateCurriculumLanguageLessonThumbNailAction({
                  apiPayloadRequest: {
                    _id: curriculum_id,
                    language_code: activeTab,
                    lessonObjId: curriculumValues?.lessons[actualLessonIndex]?.lessonObjId,
                    type_of_url: parseInt(
                      curriculumValues?.lessons[actualLessonIndex]?.lesson_tumbnail_urls?.[item]
                        ?.type_of_url
                    ),
                    url_link:
                      curriculumValues?.lessons[actualLessonIndex]?.lesson_tumbnail_urls?.[item]
                        .url_link,
                  },
                  callback: (res) => {
                    if (res?.type == 1) {
                      Toastiy("Lesson Thumbnail Updated!", 1);
                      setShowNavigationAlert(false);
                    } else {
                      Toastiy("Failed to Update Lesson Thumbnail");
                    }
                    resolve();
                  },
                })
              );
            })
          );
        }
      });

      keysArray.map((item) => {
        if (
          (!data?.lessons?.[lessonLangIndex]?.lesson_trailers?.[item]?.url_link ||
            data?.lessons?.[lessonLangIndex]?.lesson_trailers?.length === 0) &&
          curriculumValues?.lessons[actualLessonIndex]?.lesson_trailers?.[item]?.url_link
        ) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                saveCurriculumLessonPreviewLanguageAction({
                  apiPayloadRequest: {
                    _id: curriculum_id,
                    language_code: activeTab,
                    lessonObjId: curriculumValues?.lessons?.[actualLessonIndex]?.lessonObjId,
                    type_of_url: parseInt(
                      curriculumValues?.lessons[actualLessonIndex]?.lesson_trailers?.[item]
                        ?.type_of_url
                    ),

                    url_link:
                      curriculumValues?.lessons?.[actualLessonIndex]?.lesson_trailers[item]
                        .url_link,
                  },
                  callback: (res) => {
                    if (res?.type == 1) {
                      Toastiy("Lesson Preview Saved!", 1);
                      setShowNavigationAlert(false);
                    } else {
                      Toastiy("Failed to Save Lesson Preview");
                    }
                    resolve();
                  },
                })
              );
            })
          );
        } else if (
          data?.lessons?.[lessonLangIndex]?.lesson_trailers?.[item]?.url_link !==
            curriculumValues?.lessons?.[actualLessonIndex]?.lesson_trailers?.[item]?.url_link &&
          curriculumValues?.lessons?.[actualLessonIndex]?.lesson_trailers?.[item]?.url_link
        ) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                updateCurriculumLessonPreviewLanguageAction({
                  apiPayloadRequest: {
                    _id: curriculum_id,
                    language_code: activeTab,
                    lessonObjId: curriculumValues?.lessons[actualLessonIndex]?.lessonObjId,
                    type_of_url: parseInt(
                      curriculumValues?.lessons[actualLessonIndex]?.lesson_trailers[item]
                        ?.type_of_url
                    ),
                    url_link:
                      curriculumValues?.lessons[actualLessonIndex]?.lesson_trailers[item].url_link,
                  },
                  callback: (res) => {
                    if (res?.type == 1) {
                      Toastiy("Lesson Preview Updated!", 1);
                      setShowNavigationAlert(false);
                    } else {
                      Toastiy("Failed to Update Lesson Preview");
                    }
                    resolve();
                  },
                })
              );
            })
          );
        }
      });
    }

    await Promise.all(requestsList);
    handlePublishCurriculum(false);
    setShowLessonBox(false);
    setCurrLessonIndex(curriculumValues.lessons.length);
    if (refetchDetails) getCurriculumDetails();
    if (callback) callback();
  };

  const saveSlide = () => {
    setShowSlideErrors(false);
    if (!isSlideEdit) {
      if (curriculum_id !== undefined) {
        dispatch(
          saveCurriculumLessonSlideAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
              slide_title:
                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_title,
              slide_description:
                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                  ?.slide_description,
            },
            callback: (res) => {
              if (res?.type === 1) {
                saveLessonSlideBgColor(
                  curriculum_id,
                  {
                    ...curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex],
                    slideObjId: res?.data?.slideObjId,
                  },
                  curriculumValues?.lessons[currLessonIndex].lesson_title
                );
                saveSlideThumbnails(curriculum_id, {
                  ...curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex],
                  slideObjId: res?.data?.slideObjId,
                });
                cancelSlide();
                handlePublishCurriculum(false);
                Toastiy("Slide Draft Saved Successfully!", 1);
                setShowNavigationAlert(false);
              } else {
                Toastiy("Failed to Save Lesson Slide");
              }
            },
          })
        );
      } else {
        setCurriculumValues((prev) => ({
          ...prev,
          lessons: prev.lessons.map((lesson, index) => {
            if (index === currLessonIndex) {
              return {
                ...lesson,
                slides: [...lesson.slides, { ...initialSlideState }],
              };
            } else {
              return lesson;
            }
          }),
        }));
        setShowNavigationAlert(true);
      }
    } else {
      if (curriculum_id !== undefined) {
        // if (
        //   currSlide?.slide_title !==
        //     curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_title ||
        //   currSlide?.slide_description !==
        //     curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_description
        // ) {
        dispatch(
          updateCurriculumLessonSlideAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
              lesson_title: curriculumValues?.lessons[currLessonIndex].lesson_title,
              slideObjId:
                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slideObjId,
              slide_title:
                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_title,
              slide_description:
                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                  ?.slide_description,
            },
            callback: (response) => {
              if (response?.type === 1) {
                if (
                  !currCurriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                    ?.slide_type ||
                  currCurriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type
                    ?.length === 0
                ) {
                  saveLessonSlideBgColor(
                    curriculum_id,
                    {
                      ...curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex],
                      slideObjId:
                        curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                          ?.slideObjId,
                    },
                    curriculumValues?.lessons[currLessonIndex].lesson_title
                  );
                } else if (
                  currSlide?.slide_type[0]?.type_of_slide !==
                    curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                      ?.slide_type[0]?.type_of_slide ||
                  currSlide?.slide_type[0]?.slide_bg_color !==
                    curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                      ?.slide_type[0]?.slide_bg_color ||
                  !currSlide?.slide_type ||
                  currSlide?.slide_type?.length === 0 ||
                  (curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
                    ?.type_of_slide !== "2" &&
                    (currSlide?.slide_type[0]?.type_of_slide !==
                      curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                        ?.slide_type[0]?.type_of_slide ||
                      currSlide?.slide_type[0]?.bg_asset_url[0]?.url_link !==
                        curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                          ?.slide_type[0]?.bg_asset_url[0]?.url_link ||
                      currSlide?.slide_type[0]?.bg_asset_url[1]?.url_link !==
                        curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                          ?.slide_type[0]?.bg_asset_url[1]?.url_link ||
                      currSlide?.slide_type[0]?.bg_asset_url[2]?.url_link !==
                        curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                          ?.slide_type[0]?.bg_asset_url[2]?.url_link)) ||
                  currSlide?.slide_type?.[0]?.slide_bg_color !==
                    currCurriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                      ?.slide_type[0]?.slide_bg_color
                ) {
                  dispatch(
                    updateCurriculumLessonSlideTypeAction({
                      apiPayloadRequest: {
                        _id: curriculum_id,
                        lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
                        slideObjId:
                          curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                            ?.slideObjId,
                        type_of_slide:
                          curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                            ?.slide_type[0]?.type_of_slide,
                        slide_bg_color:
                          curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                            ?.slide_type[0]?.slide_bg_color,
                      },
                      callback: (res) => {
                        if (res?.type === 1) {
                          if (
                            curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                              ?.slide_type[0]?.type_of_slide !== "2" &&
                            (currSlide?.slide_type[0]?.type_of_slide !==
                              curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                                ?.slide_type[0]?.type_of_slide ||
                              currSlide?.slide_type[0]?.bg_asset_url[0]?.url_link !==
                                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                                  ?.slide_type[0]?.bg_asset_url[0]?.url_link ||
                              currSlide?.slide_type[0]?.bg_asset_url[1]?.url_link !==
                                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                                  ?.slide_type[0]?.bg_asset_url[1]?.url_link ||
                              currSlide?.slide_type[0]?.bg_asset_url[2]?.url_link !==
                                curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                                  ?.slide_type[0]?.bg_asset_url[2]?.url_link)
                          ) {
                            saveLessonSlideBgImgVid(
                              curriculum_id,
                              res?.data?.slidetypeObjId,
                              curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex],
                              curriculumValues?.lessons[currLessonIndex].lesson_title
                            );
                          }
                          handlePublishCurriculum(false);
                          Toastiy("Slide Type Updated Successfully!", 1);
                          setShowNavigationAlert(false);
                        } else {
                          Toastiy("Failed to Save Slide Type");
                        }
                      },
                    })
                  );
                }
                handlePublishCurriculum(false);
                Toastiy("Slide Draft Updated Successfully!", 1);
              } else {
                Toastiy("Failed to Save Lesson Slide");
              }
            },
          })
        );
        saveSlideThumbnails(
          curriculum_id,
          curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        );
        // }

        // if (
        //   !enCurriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type ||
        //   enCurriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type
        //     ?.length === 0
        // ) {
        //   saveLessonSlideBgColor(
        //     curriculum_id,
        //     curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex],
        //     curriculumValues?.lessons[currLessonIndex].lesson_title
        //   );
        // } else if (
        //   currSlide?.slide_title !==
        //     curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_title ||
        //   currSlide?.slide_type[0]?.type_of_slide !==
        //     curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
        //       ?.type_of_slide ||
        //   currSlide?.slide_type[0]?.slide_bg_color !==
        //     curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
        //       ?.slide_bg_color
        // ) {
        //   dispatch(
        //     updateCurriculumLessonSlideTypeAction({
        //       apiPayloadRequest: {
        //         _id: curriculum_id,
        //         lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
        //         lesson_title: curriculumValues?.lessons[currLessonIndex]?.lesson_title,
        //         slideObjId:
        //           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slideObjId,
        //         slide_title:
        //           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_title,
        //         type_of_slide:
        //           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
        //             ?.type_of_slide,
        //         slide_bg_color:
        //           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
        //             ?.slide_bg_color,
        //         slidetypeObjId:
        //           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
        //             ?.slidetypeObjId,
        //       },
        //       callback: (res) => {
        //         if (res?.type === 1) {
        //           if (
        //             curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //               ?.slide_type[0]?.type_of_slide !== "2"
        //           ) {
        //             if (
        //               currSlide?.slide_type[0]?.type_of_slide !==
        //                 curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                   ?.slide_type[0]?.type_of_slide ||
        //               currSlide?.slide_type[0]?.bg_asset_url[0]?.url_link !==
        //                 curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                   ?.slide_type[0]?.bg_asset_url[0]?.url_link ||
        //               currSlide?.slide_type[0]?.bg_asset_url[1]?.url_link !==
        //                 curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                   ?.slide_type[0]?.bg_asset_url[1]?.url_link ||
        //               currSlide?.slide_type[0]?.bg_asset_url[2]?.url_link !==
        //                 curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                   ?.slide_type[0]?.bg_asset_url[2]?.url_link
        //             ) {
        //               keysArray.map((item) => {
        //                 if (
        //                   currSlide?.slide_type?.[0]?.bg_asset_url?.[item]?.url_link !==
        //                   curriculumValues?.lessons[currLessonIndex]?.slides?.[currSlideIndex]
        //                     ?.slide_type?.[0]?.bg_asset_url?.[item]?.url_link
        //                 ) {
        //                   dispatch(
        //                     updateCurriculumLessonSlideTypeBGAction({
        //                       apiPayloadRequest: {
        //                         _id: curriculum_id,
        //                         lesson_title:
        //                           curriculumValues?.lessons[currLessonIndex]?.lesson_title,
        //                         lessonObjId:
        //                           curriculumValues?.lessons[currLessonIndex]?.lessonObjId,
        //                         slide_title:
        //                           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                             ?.slide_title,
        //                         slideObjId:
        //                           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                             ?.slideObjId,
        //                         slidetypeObjId:
        //                           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                             ?.slide_type[0]?.slidetypeObjId,
        //                         type_of_slide:
        //                           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                             ?.slide_type[0]?.type_of_slide,
        //                         type_of_url: parseInt(
        //                           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                             ?.slide_type[0]?.bg_asset_url[item]?.type_of_url
        //                         ),
        //                         url_link:
        //                           curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
        //                             ?.slide_type[0]?.bg_asset_url[item]?.url_link,
        //                       },
        //                       callback: (res) => {
        //                         if (res?.type === 1) {
        //                           Toastiy("Slide BG of Image or Video Updated Successfully!");
        //                         } else {
        //                           Toastiy("Failed to Save slide BG of Image or Video");
        //                         }
        //                       },
        //                     })
        //                   );
        //                 }
        //               });
        //             }
        //           }
        //         } else {
        //           Toastiy("Failed to Save Slide Type");
        //         }
        //       },
        //     })
        //   );
        // }
      }
    }

    setShowSlideBox(false);
    handleClosePreview();
    setCurrSlideIndex(curriculumValues?.lessons[currLessonIndex]?.slides?.length);
  };

  const saveSlideLanguage = async (
    selectedLessonIndex,
    selectedSlideIndex,
    refetchDetails = true
  ) => {
    setShowSlideErrors(false);
    const data = languagemeta?.filter((item) => item?.language_code === activeTab)[0];
    const actualLessonIndex = selectedLessonIndex ?? currLessonIndex;
    const actualSlideIndex = selectedSlideIndex ?? currSlideIndex;
    const lessonLangIndex = data?.lessons?.findIndex(
      (d) => d?.lessonObjId === curriculumValues?.lessons[actualLessonIndex].lessonObjId
    );
    const slideLangIndex = data?.lessons[lessonLangIndex]?.slides?.findIndex(
      (d) =>
        d?.slideObjId ===
        curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]?.slideObjId
    );

    console.log("languagemeta data", data);
    console.log("lessonLangIndex", lessonLangIndex);
    console.log("slideLangIndex", slideLangIndex);
    const requestsList = [];
    if (!data?.lessons?.[lessonLangIndex]?.slides?.[slideLangIndex]) {
      requestsList.push(
        new Promise((resolve, reject) => {
          dispatch(
            saveCurriculumLessonSlideLanguageAction({
              apiPayloadRequest: {
                _id: curriculum_id,
                language_code: activeTab,
                lessonObjId: curriculumValues?.lessons[actualLessonIndex].lessonObjId,
                slideObjId:
                  curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                    ?.slideObjId,
                slide_title:
                  curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                    ?.slide_title,
                slide_description:
                  curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                    ?.slide_description,
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Slide Draft Saved Successfully!", 1);
                  setShowNavigationAlert(false);
                  requestsList.push(
                    new Promise((resolve1, reject1) => {
                      dispatch(
                        saveCurriculumLanguageLessonSlideTypeAction({
                          apiPayloadRequest: {
                            _id: curriculum_id,
                            lessonObjId: curriculumValues?.lessons[actualLessonIndex].lessonObjId,
                            slideObjId:
                              curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                                ?.slideObjId,
                            type_of_slide:
                              curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                                ?.slide_type[0]?.type_of_slide,
                            slide_bg_color:
                              curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                                ?.slide_type[0]?.slide_bg_color,
                          },
                          callback: (slideTypeRes) => {
                            if (slideTypeRes?.type === 1) {
                              Toastiy("Slide Type Saved Successfully!", 1);
                              setShowNavigationAlert(false);
                              if (
                                curriculumValues?.lessons[actualLessonIndex]?.slides[
                                  actualSlideIndex
                                ]?.slide_type[0]?.type_of_slide !== "2"
                              ) {
                                keysArray.map((item, index) => {
                                  if (
                                    currCurriculumValues?.lessons?.[actualLessonIndex]?.slides?.[
                                      actualSlideIndex
                                    ]?.slide_type[0]?.bg_asset_url?.[item]?.url_link
                                  ) {
                                    requestsList.push(
                                      new Promise((resolve2, reject2) => {
                                        dispatch(
                                          saveCurriculumLanguageLessonSlideTypeBGAction({
                                            apiPayloadRequest: {
                                              _id: curriculum_id,
                                              lessonObjId:
                                                curriculumValues?.lessons[actualLessonIndex]
                                                  .lessonObjId,
                                              slideObjId:
                                                curriculumValues?.lessons[actualLessonIndex]
                                                  ?.slides[actualSlideIndex]?.slideObjId,
                                              slidetypeObjId:
                                                curriculumValues?.lessons[actualLessonIndex]
                                                  ?.slides[actualSlideIndex]?.slide_type[0]
                                                  ?.slidetypeObjId,
                                              type_of_url: parseInt(
                                                curriculumValues?.lessons[actualLessonIndex]
                                                  ?.slides[actualSlideIndex]?.slide_type[0]
                                                  ?.bg_asset_url[item].type_of_url
                                              ),
                                              url_link:
                                                curriculumValues?.lessons[actualLessonIndex]
                                                  ?.slides[actualSlideIndex]?.slide_type[0]
                                                  ?.bg_asset_url[item].url_link,
                                            },
                                            callback: (res) => {
                                              if (res?.type !== 1) {
                                                Toastiy(
                                                  "Failed to Save slide BG of Image or Video"
                                                );
                                              } else {
                                                setShowNavigationAlert(false);
                                              }
                                              resolve2();
                                            },
                                          })
                                        );
                                      })
                                    );
                                  } else {
                                    requestsList.push(
                                      new Promise((resolve2, reject2) => {
                                        dispatch(
                                          saveCurriculumLessonSlideTypeBGAction({
                                            apiPayloadRequest: {
                                              _id: curriculum_id,
                                              lessonObjId:
                                                curriculumValues?.lessons[actualLessonIndex]
                                                  .lessonObjId,
                                              slideObjId:
                                                curriculumValues?.lessons[actualLessonIndex]
                                                  ?.slides[actualSlideIndex]?.slideObjId,
                                              slidetypeObjId:
                                                curriculumValues?.lessons[actualLessonIndex]
                                                  ?.slides[actualSlideIndex]?.slide_type[0]
                                                  ?.slidetypeObjId,
                                              type_of_url: parseInt(
                                                curriculumValues?.lessons[actualLessonIndex]
                                                  ?.slides[actualSlideIndex]?.slide_type[0]
                                                  ?.bg_asset_url[item].type_of_url
                                              ),
                                              url_link:
                                                curriculumValues?.lessons[actualLessonIndex]
                                                  ?.slides[actualSlideIndex]?.slide_type[0]
                                                  ?.bg_asset_url[item].url_link,
                                            },
                                            callback: (res) => {
                                              if (res?.type !== 1) {
                                                Toastiy(
                                                  "Failed to Save slide BG of Image or Video"
                                                );
                                              } else {
                                                setShowNavigationAlert(false);
                                              }
                                              resolve2();
                                            },
                                          })
                                        );
                                      })
                                    );
                                  }
                                });
                              }
                            } else {
                              Toastiy("Failed to Save Slide Type");
                            }
                            resolve1();
                          },
                        })
                      );
                    })
                  );
                } else {
                  Toastiy("Failed to Save Lesson Slide");
                }
                resolve();
              },
            })
          );
        })
      );
    } else {
      requestsList.push(
        new Promise((resolve, reject) => {
          dispatch(
            updateCurriculumLessonSlideLanguageAction({
              apiPayloadRequest: {
                _id: curriculum_id,
                language_code: activeTab,
                lessonObjId: curriculumValues?.lessons[actualLessonIndex].lessonObjId,
                slideObjId:
                  curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                    ?.slideObjId,
                slide_title:
                  curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                    ?.slide_title,
                slide_description:
                  curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                    ?.slide_description,
              },
              callback: (res) => {
                if (res?.type === 1) {
                  handlePublishCurriculum(false);
                  Toastiy("Slide Draft Saved Successfully!", 1);
                  setShowNavigationAlert(false);
                } else {
                  Toastiy("Failed to Save Lesson Slide");
                }
                resolve();
              },
            })
          );
        })
      );

      if (
        !data?.lessons?.[lessonLangIndex]?.slides?.[slideLangIndex]?.slide_type?.[0]
          ?.type_of_slide ||
        !data?.lessons?.[lessonLangIndex]?.slides?.[slideLangIndex]?.slide_type?.length === 0
      ) {
        requestsList.push(
          new Promise((resolve, reject) => {
            dispatch(
              saveCurriculumLanguageLessonSlideTypeAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  lessonObjId: curriculumValues?.lessons[actualLessonIndex].lessonObjId,
                  slideObjId:
                    curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                      ?.slideObjId,
                  type_of_slide:
                    curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                      ?.slide_type[0]?.type_of_slide,
                  slide_bg_color:
                    curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                      ?.slide_type[0]?.slide_bg_color,
                },
                callback: (slideTypeRes) => {
                  if (slideTypeRes?.type === 1) {
                    Toastiy("Slide Type Saved Successfully!", 1);
                    setShowNavigationAlert(false);
                    if (
                      curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                        ?.slide_type[0]?.type_of_slide !== "2"
                    ) {
                      keysArray.map((item, index) => {
                        if (
                          currCurriculumValues?.lessons?.[actualLessonIndex]?.slides?.[
                            actualSlideIndex
                          ]?.slide_type[0]?.bg_asset_url?.[item]?.url_link
                        ) {
                          requestsList.push(
                            new Promise((resolve1, reject1) => {
                              dispatch(
                                saveCurriculumLanguageLessonSlideTypeBGAction({
                                  apiPayloadRequest: {
                                    _id: curriculum_id,
                                    lessonObjId:
                                      curriculumValues?.lessons[actualLessonIndex].lessonObjId,
                                    slideObjId:
                                      curriculumValues?.lessons[actualLessonIndex]?.slides[
                                        actualSlideIndex
                                      ]?.slideObjId,
                                    slidetypeObjId:
                                      curriculumValues?.lessons[actualLessonIndex]?.slides[
                                        actualSlideIndex
                                      ]?.slide_type[0]?.slidetypeObjId,
                                    type_of_url: parseInt(
                                      curriculumValues?.lessons[actualLessonIndex]?.slides[
                                        actualSlideIndex
                                      ]?.slide_type[0]?.bg_asset_url[item].type_of_url
                                    ),
                                    url_link:
                                      curriculumValues?.lessons[actualLessonIndex]?.slides[
                                        actualSlideIndex
                                      ]?.slide_type[0]?.bg_asset_url[item].url_link,
                                  },
                                  callback: (res) => {
                                    if (res?.type !== 1) {
                                      Toastiy("Failed to Save slide BG of Image or Video");
                                    } else {
                                      setShowNavigationAlert(false);
                                    }
                                    resolve1();
                                  },
                                })
                              );
                            })
                          );
                        }
                      });
                    }
                  } else {
                    Toastiy("Failed to Save Slide Type");
                  }
                  resolve();
                },
              })
            );
          })
        );
      } else if (
        data?.lessons?.[lessonLangIndex]?.slides?.[slideLangIndex]?.slide_type[0]?.type_of_slide !==
        curriculumValues?.lessons?.[actualLessonIndex]?.slides?.[actualSlideIndex]?.slide_type[0]
          ?.type_of_slide
      ) {
        requestsList.push(
          new Promise((resolve, reject) => {
            dispatch(
              updateCurriculumLanguageLessonSlideTypeAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  lessonObjId: curriculumValues?.lessons[actualLessonIndex].lessonObjId,
                  slideObjId:
                    curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                      ?.slideObjId,
                  type_of_slide:
                    curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                      ?.slide_type[0]?.type_of_slide,
                  slide_bg_color:
                    curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                      ?.slide_type[0]?.slide_bg_color,
                },
                callback: (slideTypeRes) => {
                  if (slideTypeRes?.type === 1) {
                    Toastiy("Slide Type Updated Successfully!", 1);
                    setShowNavigationAlert(false);
                    if (
                      curriculumValues?.lessons[actualLessonIndex]?.slides[actualSlideIndex]
                        ?.slide_type[0]?.type_of_slide !== "2"
                    ) {
                      keysArray.map((item, index) => {
                        if (
                          !data?.lessons?.[lessonLangIndex]?.slides?.[slideLangIndex]?.slide_type[0]
                            ?.bg_asset_url?.[item]?.url_link &&
                          curriculumValues?.lessons[actualLessonIndex].slides?.[actualSlideIndex]
                            ?.slide_type?.[0]?.bg_asset_url[item].type_of_url
                        ) {
                          requestsList.push(
                            new Promise((resolve1, reject1) => {
                              dispatch(
                                saveCurriculumLanguageLessonSlideTypeBGAction({
                                  apiPayloadRequest: {
                                    _id: curriculum_id,
                                    language_code: activeTab,
                                    lessonObjId:
                                      curriculumValues?.lessons[actualLessonIndex].lessonObjId,
                                    slideObjId:
                                      curriculumValues?.lessons[actualLessonIndex].slides?.[
                                        actualSlideIndex
                                      ]?.slideObjId,
                                    slidetypeObjId:
                                      curriculumValues?.lessons[actualLessonIndex]?.slides[
                                        actualSlideIndex
                                      ]?.slide_type[0]?.slidetypeObjId,
                                    type_of_url: parseInt(
                                      curriculumValues?.lessons[actualLessonIndex].slides?.[
                                        actualSlideIndex
                                      ]?.slide_type?.[0]?.bg_asset_url[item].type_of_url
                                    ),
                                    url_link:
                                      curriculumValues?.lessons[actualLessonIndex].slides?.[
                                        actualSlideIndex
                                      ]?.slide_type[0]?.bg_asset_url[item].url_link,
                                  },
                                  callback: (res) => {
                                    if (res?.type !== 1) {
                                      Toastiy("Failed to Save slide BG of Image or Video");
                                    } else {
                                      setShowNavigationAlert(false);
                                      getCurriculumDetails();
                                    }
                                    resolve1();
                                  },
                                })
                              );
                            })
                          );
                        } else if (
                          data?.lessons?.[lessonLangIndex]?.slides?.[slideLangIndex]?.slide_type[0]
                            ?.bg_asset_url?.[item]?.url_link !==
                            curriculumValues?.lessons?.[actualLessonIndex]?.slides?.[
                              actualSlideIndex
                            ]?.slide_type[0]?.bg_asset_url?.[item]?.url_link &&
                          curriculumValues?.lessons?.[actualLessonIndex]?.slides?.[actualSlideIndex]
                            ?.slide_type[0]?.bg_asset_url?.[item]?.url_link
                        ) {
                          requestsList.push(
                            new Promise((resolve1, reject1) => {
                              dispatch(
                                updateCurriculumLanguageLessonSlideTypeBGAction({
                                  apiPayloadRequest: {
                                    _id: curriculum_id,
                                    language_code: activeTab,
                                    lessonObjId:
                                      curriculumValues?.lessons[actualLessonIndex].lessonObjId,
                                    slideObjId:
                                      curriculumValues?.lessons[actualLessonIndex].slides?.[
                                        actualSlideIndex
                                      ]?.slideObjId,
                                    slidetypeObjId:
                                      curriculumValues?.lessons[actualLessonIndex]?.slides[
                                        actualSlideIndex
                                      ]?.slide_type[0]?.slidetypeObjId,
                                    type_of_url: parseInt(
                                      curriculumValues?.lessons[actualLessonIndex].slides?.[
                                        actualSlideIndex
                                      ]?.slide_type?.[0]?.bg_asset_url[item].type_of_url
                                    ),
                                    url_link:
                                      curriculumValues?.lessons[actualLessonIndex].slides?.[
                                        actualSlideIndex
                                      ]?.slide_type[0]?.bg_asset_url[item].url_link,
                                  },
                                  callback: (res) => {
                                    if (res?.type !== 1) {
                                      Toastiy("Failed to Save slide BG of Image or Video");
                                    } else {
                                      setShowNavigationAlert(false);
                                      getCurriculumDetails();
                                    }
                                    resolve1();
                                  },
                                })
                              );
                            })
                          );
                        }
                      });
                    }
                  } else {
                    Toastiy("Failed to Update Slide Type");
                  }
                  resolve();
                },
              })
            );
          })
        );
      }
    }

    await Promise.all(requestsList);
    if (refetchDetails) getCurriculumDetails();
    handleClosePreview();
    cancelSlide();
  };

  const storyData = [
    {
      content: (props) => (
        <div
          className="flex flex-col justify-center relative bg-red-100"
          style={{
            height: "100%",
            width: "100%",
            marginTop: "40px",
            borderRadius: "10px",
          }}
        >
          <div className="px-[5%] h-full flex flex-col justify-center" style={{ zIndex: 100 }}>
            <p
              style={{
                marginTop: 5,
                fontSize: "10px",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              {curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_title}
            </p>
            <p
              style={{
                marginTop: 5,
                fontSize: "25px",
                textTransform: "capitalize",
                fontFamily: "serif",
                fontWeight: "bold",
                maxHeight: "90%",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]
                    ?.slide_description,
              }}
            ></p>
          </div>
          {(curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
            ?.type_of_slide === "0" ||
            curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
              ?.type_of_slide === "3") && (
            <div className="absolute w-full h-full">
              <img
                className="h-full w-full object-cover object-top"
                src={
                  curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                    ?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link
                }
                alt="slide"
              />
            </div>
          )}
          {curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_type?.[0]
            ?.type_of_slide === "1" && (
            <div className="absolute w-full h-full">
              {/* <video className="h-full w-full">
                <source
                  src={
                    curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                      ?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link
                  }
                />
              </video> */}
              <ReactPlayer
                controls={false}
                height={"100%"}
                width={"100%"}
                playing={true}
                muted={true}
                className="object-cover object-top"
                url={
                  curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                    ?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link
                }
              />
            </div>
          )}
          {curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex]?.slide_type[0]
            ?.type_of_slide === "2" && (
            <div
              className="absolute w-full h-full bg-cover bg-top"
              style={{
                backgroundColor: `${curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_type?.[0]?.slide_bg_color}`,
              }}
            ></div>
          )}
        </div>
      ),
    },
  ];

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleOpenPreview = () => {
    setShowPreview(true);
  };

  //HandleChange
  const handleChange = (
    e,
    category,
    urlIndex,
    lessonIndex,
    slideIndex,
    uploadProgressId = null
  ) => {
    const { name, value, files } = e.target;

    setForceEditorUpdate(false);

    const updatedCurriculum = structuredClone(curriculumValues);
    const updatedCurriculumErrors = { ...curriculumErrors };
    const updatedLessonErrors = { ...lessonErrors };
    const updatedSlideErrors = { ...slideErrors };

    switch (category) {
      case "curriculum":
        if (name === "bg_Images" || name === "generic_bg_Images" || name === "trailers") {
          if (!updatedCurriculum[name] || updatedCurriculum[name]?.length === 0) {
            updatedCurriculum[name] = initialCurriculumState[name];
          }
          updatedCurriculum[name][urlIndex].url_link = value;
          if (name === "bg_Images")
            updatedCurriculumErrors[name][urlIndex].url_link = value.length > 0 ? false : true;
        } else {
          updatedCurriculum[name] = value;
          updatedCurriculumErrors[name] = value.length > 0 ? false : true;
        }
        break;
      case "curriculumFile":
        if ((name === "bg_Images" || name === "generic_bg_Images") && files.length > 0) {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);

          dispatch(
            uploadAssestsV2Action({
              apiPayloadRequest: formData,
              uploadProgressId: uploadProgressId,
              callback: (res) => {
                if (res && res.url) {
                  if (!updatedCurriculum[name] || updatedCurriculum[name]?.length === 0) {
                    updatedCurriculum[name] = initialCurriculumState[name];
                  }
                  updatedCurriculum[name][urlIndex].url_link = res.url;
                  updatedCurriculumErrors[name][urlIndex].url_link =
                    res.url.length > 0 ? false : true;

                  setCurriculumValues(updatedCurriculum);
                  setCurriculumErrors(updatedCurriculumErrors);
                }
                setLoading(false);
              },
            })
          );
        }
        break;
      case "curriculumPreviewFile":
        if (name === "trailers" && files.length > 0) {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);

          dispatch(
            uploadVideosV2Action({
              apiPayloadRequest: formData,
              uploadProgressId: uploadProgressId,
              callback: (res) => {
                if (res && res.url) {
                  console.log("updatedCurriculum", updatedCurriculum);
                  console.log("updatedCurriculumErrors", updatedCurriculumErrors);
                  console.log("name", name);
                  if (!updatedCurriculum[name] || updatedCurriculum[name]?.length === 0) {
                    updatedCurriculum[name] = initialCurriculumState[name];
                  }
                  updatedCurriculum[name][urlIndex].url_link = res.url;
                  // updatedCurriculumErrors[name][urlIndex].url_link =
                  //   res.url.length > 0 ? false : true;

                  setCurriculumValues(updatedCurriculum);
                  setCurriculumErrors(updatedCurriculumErrors);
                }
                setLoading(false);
              },
            })
          );
        }
        break;
      case "curriculumPdf":
        if (name === "curriculumPdfUrl" && files.length > 0) {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);

          dispatch(
            uploadAssestsV2Action({
              apiPayloadRequest: formData,
              uploadProgressId: uploadProgressId,
              callback: (res) => {
                if (res && res.url) {
                  updatedCurriculum["curriculumPdfUrl"] = res.url;
                  updatedCurriculumErrors["curriculumPdfUrl"] = res.url.length > 0 ? false : true;

                  setCurriculumValues(updatedCurriculum);
                  setCurriculumErrors(updatedCurriculumErrors);
                }
                setLoading(false);
              },
            })
          );
        }
        break;
      case "curriculumPdfUrl":
        if (name === "curriculumPdfUrl") {
          if (!updatedCurriculum?.curriculumPdfUrl) {
            updatedCurriculum["curriculumPdfUrl"] = "";
          }
          updatedCurriculum[name] = value;
          updatedLessonErrors[name] = value.length > 0 ? false : true;
        }
        break;
      case "lesson":
        if (name === "lesson_tumbnail_urls") {
          if (
            !updatedCurriculum?.lessons?.[lessonIndex]?.lesson_tumbnail_urls ||
            updatedCurriculum?.lessons?.[lessonIndex]?.lesson_tumbnail_urls?.length === 0
          ) {
            updatedCurriculum.lessons[lessonIndex]["lesson_tumbnail_urls"] =
              initialLessonState?.lesson_tumbnail_urls;
          }
          updatedCurriculum.lessons[lessonIndex][name][urlIndex].url_link = value;
          updatedLessonErrors[name][urlIndex].url_link = value.length > 0 ? false : true;
        } else if (name === "lesson_generic_tumbnail_urls") {
          if (
            !updatedCurriculum?.lessons?.[lessonIndex]?.lesson_generic_tumbnail_urls ||
            updatedCurriculum?.lessons?.[lessonIndex]?.lesson_generic_tumbnail_urls?.length === 0
          ) {
            updatedCurriculum.lessons[lessonIndex]["lesson_generic_tumbnail_urls"] =
              initialLessonState?.lesson_generic_tumbnail_urls;
          }
          updatedCurriculum.lessons[lessonIndex][name][urlIndex].url_link = value;
          updatedLessonErrors[name][urlIndex].url_link = value.length > 0 ? false : true;
        } else if (name === "lesson_trailers") {
          console.log("lesson value ", updatedCurriculum?.lessons?.[lessonIndex]);
          if (
            !updatedCurriculum?.lessons?.[lessonIndex]?.lesson_trailers ||
            updatedCurriculum?.lessons?.[lessonIndex]?.lesson_trailers?.length === 0
          ) {
            updatedCurriculum.lessons[lessonIndex]["lesson_trailers"] =
              initialLessonState?.lesson_trailers;
          }
          updatedCurriculum.lessons[lessonIndex][name][urlIndex].url_link = value;
          updatedLessonErrors[name][urlIndex].url_link = value.length > 0 ? false : true;
        } else {
          updatedCurriculum.lessons[lessonIndex][name] = value;
          updatedLessonErrors[name] = value.length > 0 ? false : true;
        }
        break;
      case "lessonPdf":
        if (name === "lessonPdfUrl" && files.length > 0) {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);

          dispatch(
            uploadAssestsV2Action({
              apiPayloadRequest: formData,
              uploadProgressId: uploadProgressId,
              callback: (res) => {
                if (res && res.url) {
                  updatedCurriculum.lessons[lessonIndex]["lessonPdfUrl"] = res.url;
                  updatedLessonErrors["lessonPdfUrl"] = res.url.length > 0 ? false : true;

                  setCurriculumValues(updatedCurriculum);
                  setCurriculumErrors(updatedCurriculumErrors);
                }
                setLoading(false);
              },
            })
          );
        }
        break;
      case "lessonPdfUrl":
        if (name === "lessonPdfUrl") {
          if (!updatedCurriculum.lessons[lessonIndex]?.lessonPdfUrl) {
            updatedCurriculum.lessons[lessonIndex]["lessonPdfUrl"] = "";
          }
          updatedCurriculum.lessons[lessonIndex][name] = value;
          updatedLessonErrors[name] = value.length > 0 ? false : true;
        }
        break;
      case "lessonFile":
        if (name === "lesson_tumbnail_urls" || name === "lesson_generic_tumbnail_urls") {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);
          dispatch(
            uploadAssestsV2Action({
              apiPayloadRequest: formData,
              uploadProgressId: uploadProgressId,
              callback: (res) => {
                if (res && res.url) {
                  if (updatedCurriculum.lessons[lessonIndex][name]?.length === 0) {
                    updatedCurriculum.lessons[lessonIndex][name] = initialLessonState[name];
                  }
                  updatedCurriculum.lessons[lessonIndex][name][urlIndex].url_link = res.url;
                  updatedLessonErrors[name][urlIndex].url_link = res.url.length > 0 ? false : true;

                  setCurriculumValues(updatedCurriculum);
                  setLessonErrors(updatedLessonErrors);
                }
                setLoading(false);
              },
            })
          );
        }
        break;
      case "lessonPreview":
        if (name === "lesson_trailers") {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);
          dispatch(
            uploadVideosV2Action({
              apiPayloadRequest: formData,
              uploadProgressId: uploadProgressId,
              callback: (res) => {
                if (res && res.url) {
                  if (
                    !updatedCurriculum.lessons[lessonIndex][name] ||
                    updatedCurriculum.lessons[lessonIndex][name]?.length === 0
                  ) {
                    updatedCurriculum.lessons[lessonIndex][name] =
                      initialLessonState.lesson_trailers;
                  }
                  updatedCurriculum.lessons[lessonIndex][name][urlIndex].url_link = res.url;
                  updatedLessonErrors[name][urlIndex].url_link = res.url.length > 0 ? false : true;

                  setCurriculumValues(updatedCurriculum);
                  setLessonErrors(updatedLessonErrors);
                }
                setLoading(false);
              },
            })
          );
        }
        break;
      case "slide":
        if (name === "bg_asset_url" || name === "slide_link_url") {
          if (updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length === 0) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type =
              initialSlideState?.slide_type;
          } else if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length > 0 &&
            Object.keys(updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0])
              ?.length === 1
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0] = {
              ...initialSlideState?.slide_type[0],
              ...updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0],
            };
          }

          if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0]?.bg_asset_url
              ?.length === 0
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0].bg_asset_url =
              initialSlideState?.slide_type[0]?.bg_asset_url;
          }

          if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0]?.slide_link_url
              ?.length === 0
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0].slide_link_url =
              initialSlideState?.slide_type[0]?.slide_link_url;
          }

          updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0][name][
            urlIndex
          ].url_link = value;

          updatedSlideErrors.slide_type[0][name][urlIndex].url_link =
            value.length > 0 ? false : true;
        } else if (name === "type_of_slide") {
          if (
            value === "0" &&
            currCurriculumValues?.lessons[lessonIndex].slides[slideIndex].slide_type?.length > 0 &&
            currCurriculumValues?.lessons[lessonIndex].slides[slideIndex].slide_type?.[0]
              ?.type_of_slide !== value
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0].bg_asset_url =
              initialSlideState.slide_type[0].bg_asset_url;
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_description = "";
          }
          if (updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length > 0) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0][name] = value;
          } else {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type = [
              { [name]: value },
            ];
          }

          updatedSlideErrors.slide_type[0][name] = value.length > 0 ? false : true;
        } else if (name === "slide_bg") {
          if (
            !updatedCurriculum?.lessons?.[lessonIndex]?.slides?.[slideIndex]?.slide_bg ||
            updatedCurriculum?.lessons?.[lessonIndex]?.slides?.[slideIndex]?.slide_bg?.length === 0
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex][name] =
              initialSlideState?.slide_bg;
          }
          updatedCurriculum.lessons[lessonIndex].slides[slideIndex][name][urlIndex].url_link =
            value;
          updatedSlideErrors[name][urlIndex].url_link = value.length > 0 ? false : true;
        } else {
          updatedCurriculum.lessons[lessonIndex].slides[slideIndex][name] = value;

          updatedSlideErrors[name] = value.length > 0 ? false : true;
        }
        break;
      case "slideImage":
        if (name === "bg_asset_url") {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);

          dispatch(
            uploadAssestsV2Action({
              apiPayloadRequest: formData,
              uploadProgressId: uploadProgressId,
              callback: (res) => {
                if (res.url) {
                  updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0][name][
                    urlIndex
                  ].url_link = res?.url;
                  updatedSlideErrors.slide_type[0][name][urlIndex].url_link =
                    res.url.length > 0 ? false : true;

                  setCurriculumValues(updatedCurriculum);
                  setSlideErrors(updatedSlideErrors);
                }
                setLoading(false);
              },
            })
          );

          if (updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length === 0) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type =
              initialSlideState?.slide_type;
          } else if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length > 0 &&
            Object.keys(updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0])
              ?.length === 1
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0] = {
              ...initialSlideState?.slide_type[0],
              ...updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0],
            };
          }

          if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0]?.bg_asset_url
              ?.length === 0
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0].bg_asset_url =
              initialSlideState?.slide_type[0]?.bg_asset_url;
          }

          if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0]?.slide_link_url
              ?.length === 0
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0].slide_link_url =
              initialSlideState?.slide_type[0]?.slide_link_url;
          }

          // updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0][name][
          //   urlIndex
          // ].url_link = value;

          // updatedSlideErrors.slide_type[0][name][urlIndex].url_link =
          //   value.length > 0 ? false : true;
        }
        break;
      case "slideFile":
        if (name === "slide_bg") {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);
          dispatch(
            uploadAssestsV2Action({
              apiPayloadRequest: formData,
              uploadProgressId: uploadProgressId,
              callback: (res) => {
                if (res && res?.url) {
                  console.log("res.url", res.url);
                  console.log("slideIndex", slideIndex);
                  console.log("lessonIndex", lessonIndex);
                  console.log("urlIndex", urlIndex);
                  console.log("name", name);
                  console.log("updatedCurriculum2", updatedCurriculum);
                  console.log("updatedCurriculum2", updatedCurriculum);
                  if (
                    updatedCurriculum?.lessons[lessonIndex].slides[slideIndex]?.[name]?.length === 0
                  ) {
                    updatedCurriculum.lessons[lessonIndex].slides[slideIndex][name] =
                      initialSlideState[name];
                  }
                  updatedCurriculum.lessons[lessonIndex].slides[slideIndex][name][
                    urlIndex
                  ].url_link = res.url;
                  console.log("updatedCurriculum1", updatedCurriculum);
                  updatedSlideErrors[name][urlIndex].url_link = res.url.length > 0 ? false : true;
                }
                setLoading(false);
              },
            })
          );
        }
        break;
      case "slideVideo":
        if (name === "bg_asset_url") {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);
          formData.append("downloadable", true);
          formData.append("onlyEnableDownload", true);
          dispatch(
            uploadVideosV2Action({
              apiPayloadRequest: formData,
              uploadProgressId: uploadProgressId,
              callback: (res) => {
                if (res.url) {
                  updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0][name][
                    urlIndex
                  ].url_link = res?.url;
                  updatedSlideErrors.slide_type[0][name][urlIndex].url_link =
                    res.url.length > 0 ? false : true;

                  setCurriculumValues(updatedCurriculum);
                  setSlideErrors(updatedSlideErrors);
                }
                setLoading(false);
              },
            })
          );

          if (updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length === 0) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type =
              initialSlideState?.slide_type;
          } else if (
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type?.length > 0 &&
            Object.keys(updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0])
              ?.length === 1
          ) {
            updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0] = {
              ...initialSlideState?.slide_type[0],
              ...updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0],
            };
          }

          // updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0][name][
          //   urlIndex
          // ].url_link = value;

          // updatedSlideErrors.slide_type[0][name][urlIndex].url_link =
          //   value.length > 0 ? false : true;
        }
        break;
      default:
        break;
    }

    setCurriculumErrors(updatedCurriculumErrors);
    setLessonErrors(updatedLessonErrors);
    setSlideErrors(updatedSlideErrors);
    console.log("updatedCurriculum", updatedCurriculum);
    console.log("updatedCurriculumErrors", updatedCurriculumErrors);
    setCurriculumValues(updatedCurriculum);
    setShowNavigationAlert(true);
  };

  const handleSlideTypeChange = (e) => {
    if (
      currCurriculumValues?.lessons[currLessonIndex].slides[currSlideIndex].slide_type?.length >
        0 &&
      currCurriculumValues?.lessons[currLessonIndex].slides[currSlideIndex].slide_type?.[0]
        ?.type_of_slide !== e.target.value
    ) {
      const options = {
        customUI: ({ onClose }) => {
          return (
            <ConfirmToast
              title="Are you sure?"
              message="You want to delete change slide type, your exiting type changes will be lost?"
              deleteButtonText="Continue"
              onClose={() => onClose()}
              onSuccess={() => {
                handleChange(e, "slide", "", currLessonIndex, currSlideIndex);
                onClose();
              }}
            />
          );
        },
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        overlayClassName: "!bg-gray-500 !bg-opacity-75 !z-[1000]",
      };

      confirmAlert(options);
    } else {
      handleChange(e, "slide", "", currLessonIndex, currSlideIndex);
    }
  };

  const handleSlideBgChange = (value, lessonIndex, slideIndex) => {
    const updatedCurriculum = { ...curriculumValues };

    updatedCurriculum.lessons[lessonIndex].slides[slideIndex].slide_type[0]["slide_bg_color"] =
      value.hex;

    setCurriculumValues(updatedCurriculum);
    setShowNavigationAlert(true);
  };

  //*********************************************//
  const saveCurriculum = () => {
    setShowCurriculumErrors(false);
    if (curriculum_id !== undefined) {
      if (
        curriculumValues?.curriculum_name !== currCurriculumValues?.curriculum_name ||
        curriculumValues?.curriculum_description !== currCurriculumValues?.curriculum_description ||
        curriculumValues?.curriculumPdfUrl !== currCurriculumValues?.curriculumPdfUrl
      ) {
        setLoading(true);
        dispatch(
          updateCurriculumAction({
            apiPayloadRequest: {
              _id: curriculum_id,
              publish: false,
              curriculum_name: curriculumValues?.curriculum_name,
              curriculumPdfUrl: curriculumValues?.curriculumPdfUrl,
              curriculum_description: curriculumValues?.curriculum_description,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Curriculum Draft Updated Successfully!", 1);
                setCurrCurriculumValues((prev) => ({
                  ...prev,
                  _id: curriculum_id,
                  curriculum_name: curriculumValues?.curriculum_name,
                  curriculum_description: curriculumValues?.curriculum_description,
                  curriculumPdfUrl: curriculumValues?.curriculumPdfUrl,
                  publish: "false",
                }));
                setLoading(false);
                setShowCurriculumBox(false);
                setShowNavigationAlert(false);
              }
            },
          })
        );
      }

      curriculumValues?.bg_Images?.map((item, index) => {
        if (
          curriculumValues?.bg_Images?.[index]?.url_link &&
          !currCurriculumValues?.bg_Images?.[index]?.url_link
        ) {
          setTimeout(() => {
            dispatch(
              saveBGCurriculumAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  type_of_url: parseInt(curriculumValues.bg_Images?.[index]?.type_of_url),
                  url_link: curriculumValues.bg_Images?.[index]?.url_link,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Curriculum BG Updated Successfully!", 1);
                    setCurrCurriculumValues((prev) => ({
                      ...prev,
                      bg_Images: prev.bg_Images.map((item, i) => ({
                        type_of_url: curriculumValues.bg_Images?.[i]?.type_of_url,
                        url_link: curriculumValues.bg_Images?.[i]?.url_link,
                      })),
                    }));
                    setShowNavigationAlert(false);
                  } else {
                    Toastiy("Failed to Save Curriculum BG");
                  }
                },
              })
            );
          }, index * 500);
        } else if (
          curriculumValues?.bg_Images?.[index]?.url_link &&
          curriculumValues?.bg_Images?.[index]?.url_link !==
            currCurriculumValues?.bg_Images?.[index]?.url_link
        ) {
          setTimeout(() => {
            dispatch(
              updateBGCurriculumAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  type_of_url: parseInt(curriculumValues.bg_Images?.[index]?.type_of_url),
                  url_link: curriculumValues.bg_Images?.[index]?.url_link,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Curriculum BG Updated Successfully!", 1);
                    setCurrCurriculumValues((prev) => ({
                      ...prev,
                      bg_Images: prev.bg_Images.map((item, i) => ({
                        type_of_url: curriculumValues.bg_Images?.[i]?.type_of_url,
                        url_link: curriculumValues.bg_Images?.[i]?.url_link,
                      })),
                    }));
                    setShowNavigationAlert(false);
                  } else {
                    Toastiy("Failed to Save Curriculum BG");
                  }
                },
              })
            );
          }, index * 500);
        }
      });

      curriculumValues?.generic_bg_Images?.map((item, index) => {
        if (
          !currCurriculumValues?.generic_bg_Images?.[index]?.url_link &&
          curriculumValues.generic_bg_Images?.[index]?.url_link
        ) {
          setTimeout(() => {
            dispatch(
              saveCommonBGCurriculumAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  type_of_url: parseInt(curriculumValues.generic_bg_Images?.[index]?.type_of_url),
                  url_link: curriculumValues.generic_bg_Images?.[index]?.url_link,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Curriculum Common BG Updated Successfully!", 1);
                    setCurrCurriculumValues((prev) => ({
                      ...prev,
                      generic_bg_Images: prev.generic_bg_Images.map((item, i) => ({
                        type_of_url: curriculumValues.generic_bg_Images?.[i]?.type_of_url,
                        url_link: curriculumValues.generic_bg_Images?.[i]?.url_link,
                      })),
                    }));
                    setShowNavigationAlert(false);
                  } else {
                    Toastiy("Failed to Save Curriculum Common BG");
                  }
                },
              })
            );
          }, index * 500);
        } else if (
          currCurriculumValues?.generic_bg_Images?.[index]?.url_link &&
          curriculumValues?.generic_bg_Images?.[index]?.url_link !==
            currCurriculumValues?.generic_bg_Images?.[index]?.url_link
        ) {
          setTimeout(() => {
            dispatch(
              updateCommonBGCurriculumAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  type_of_url: parseInt(curriculumValues.generic_bg_Images?.[index]?.type_of_url),
                  url_link: curriculumValues.generic_bg_Images?.[index]?.url_link,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Curriculum Common BG Updated Successfully!", 1);
                    setCurrCurriculumValues((prev) => ({
                      ...prev,
                      generic_bg_Images: prev.generic_bg_Images.map((item, i) => ({
                        type_of_url: curriculumValues.generic_bg_Images?.[i]?.type_of_url,
                        url_link: curriculumValues.generic_bg_Images?.[i]?.url_link,
                      })),
                    }));
                    setShowNavigationAlert(false);
                  } else {
                    Toastiy("Failed to Save Curriculum Common BG");
                  }
                },
              })
            );
          }, index * 500);
        }
      });

      curriculumValues?.trailers?.map((item, index) => {
        if (
          !currCurriculumValues?.trailers?.[index]?.url_link &&
          curriculumValues.trailers?.[index]?.url_link
        ) {
          setTimeout(() => {
            dispatch(
              saveCurriculumPreviewAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  type_of_url: parseInt(curriculumValues.trailers?.[index]?.type_of_url),
                  url_link: curriculumValues.trailers?.[index]?.url_link,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Curriculum Preview Saved Successfully!", 1);
                    setCurrCurriculumValues((prev) => ({
                      ...prev,
                      trailers: prev.trailers.map((item, i) => ({
                        type_of_url: curriculumValues.trailers?.[i]?.type_of_url,
                        url_link: curriculumValues.trailers?.[i]?.url_link,
                      })),
                    }));
                    setShowNavigationAlert(false);
                  } else {
                    Toastiy("Failed to Save Curriculum Preview");
                  }
                },
              })
            );
          }, index * 500);
        } else if (
          currCurriculumValues?.trailers?.[index]?.url_link &&
          curriculumValues?.trailers?.[index]?.url_link !==
            currCurriculumValues?.trailers?.[index]?.url_link
        ) {
          setTimeout(() => {
            dispatch(
              updateCurriculumPreviewAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  type_of_url: parseInt(curriculumValues.trailers?.[index]?.type_of_url),
                  url_link: curriculumValues.trailers?.[index]?.url_link,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Curriculum Preview Saved Successfully!", 1);
                    setCurrCurriculumValues((prev) => ({
                      ...prev,
                      trailers: prev.trailers.map((item, i) => ({
                        type_of_url: curriculumValues.trailers?.[i]?.type_of_url,
                        url_link: curriculumValues.trailers?.[i]?.url_link,
                      })),
                    }));
                    setShowNavigationAlert(false);
                  } else {
                    Toastiy("Failed to Save Curriculum Preview");
                  }
                },
              })
            );
          }, index * 500);
        }
      });
    } else {
      setLoading(true);
      dispatch(
        saveCurriculumAction({
          apiPayloadRequest: {
            publish: false,
            curriculum_name: curriculumValues?.curriculum_name,
            curriculumPdfUrl: curriculumValues?.curriculumPdfUrl,
            curriculum_description: curriculumValues?.curriculum_description,
          },
          callback: async (response) => {
            await saveCurriculumBg(response);
            await saveCurriculumCommonBg(response);
            await saveCurriculumPreview(response);
            setCurrCurriculumValues((prev) => ({
              ...prev,
              _id: response?.data[0]?._id,
              curriculum_name: curriculumValues?.curriculum_name,
              curriculumPdfUrl: curriculumValues?.curriculumPdfUrl,
              curriculum_description: curriculumValues?.curriculum_description,
            }));
            setCurriculumId(response?.data[0]?._id);
            setTimeout(
              () => navigate("/curriculum/add", { state: { _id: response?.data[0]?._id } }),
              500
            );
            Toastiy("Curriculum Draft Updated Successfully!", 1);
            setLoading(false);
            setShowCurriculumBox(false);
            setShowNavigationAlert(false);
          },
        })
      );
    }
  };

  console.log("isLanguageSave", isLanguageSave);

  const saveCurriculumLanguage = async (refetchDetails = true, callback) => {
    setShowCurriculumErrors(false);
    const data = languagemeta?.filter((item) => item?.language_code === activeTab)[0];
    const requestsList = [];
    if (isLanguageSave) {
      setLoading(true);
      requestsList.push(
        new Promise((resolve, reject) => {
          dispatch(
            saveCurriculumLanguageAction({
              apiPayloadRequest: {
                _id: curriculum_id,
                publish: false,
                language_code: activeTab,
                curriculum_name: curriculumValues?.curriculum_name,
                curriculumPdfUrl: curriculumValues?.curriculumPdfUrl,
                curriculum_description: curriculumValues?.curriculum_description,
              },
              callback: async (response) => {
                Toastiy("Curriculum Draft Updated Successfully!", 1);
                setShowNavigationAlert(false);
                curriculumValues?.bg_Images?.map((item, index) => {
                  if (curriculumValues?.bg_Images?.[index]?.url_link) {
                    requestsList.push(
                      new Promise((resolve1, reject1) => {
                        dispatch(
                          saveBGCurriculumLanguageAction({
                            apiPayloadRequest: {
                              _id: curriculum_id,
                              type_of_url: parseInt(
                                curriculumValues.bg_Images?.[index]?.type_of_url
                              ),
                              url_link: curriculumValues.bg_Images?.[index]?.url_link,
                            },
                            callback: (res) => {
                              if (res?.type === 1) {
                                Toastiy("Curriculum BG Updated Successfully!", 1);
                                setShowNavigationAlert(false);
                              } else {
                                Toastiy("Failed to Save Curriculum BG");
                              }
                              resolve1();
                            },
                          })
                        );
                      })
                    );
                  }
                });

                curriculumValues?.trailers?.map((item, index) => {
                  if (curriculumValues.trailers?.[index]?.url_link) {
                    requestsList.push(
                      new Promise((resolve1, reject1) => {
                        dispatch(
                          saveCurriculumLanguagePreviewAction({
                            apiPayloadRequest: {
                              _id: curriculum_id,
                              language_code: activeTab,
                              type_of_url: parseInt(
                                curriculumValues.trailers?.[index]?.type_of_url
                              ),
                              url_link: curriculumValues.trailers?.[index]?.url_link,
                            },
                            callback: (res) => {
                              if (res?.type === 1) {
                                Toastiy("Curriculum Preview Saved Successfully!", 1);
                                setShowNavigationAlert(false);
                              } else {
                                Toastiy("Failed to Save Curriculum Preview");
                              }
                              resolve1();
                            },
                          })
                        );
                      })
                    );
                  }
                });
                resolve();
              },
            })
          );
        })
      );
    } else {
      if (
        curriculumValues?.curriculum_name !== data?.curriculum_name ||
        curriculumValues?.curriculum_description !== data?.curriculum_description ||
        curriculumValues?.curriculumPdfUrl !== data?.curriculumPdfUrl
      ) {
        setLoading(true);
        requestsList.push(
          new Promise((resolve, reject) => {
            dispatch(
              updateCurriculumLanguageAction({
                apiPayloadRequest: {
                  _id: curriculum_id,
                  publish: false,
                  language_code: activeTab,
                  curriculum_name: curriculumValues?.curriculum_name,
                  curriculumPdfUrl: curriculumValues?.curriculumPdfUrl,
                  curriculum_description: curriculumValues?.curriculum_description,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Curriculum Draft Updated Successfully!", 1);
                    setShowNavigationAlert(false);
                  }
                  setLoading(false);
                  resolve();
                },
              })
            );
          })
        );
      }

      curriculumValues?.trailers?.map((item, index) => {
        if (!data?.trailers?.[index]?.url_link && curriculumValues.trailers?.[index]?.url_link) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                saveCurriculumLanguagePreviewAction({
                  apiPayloadRequest: {
                    _id: curriculum_id,
                    language_code: activeTab,
                    type_of_url: parseInt(curriculumValues.trailers?.[index]?.type_of_url),
                    url_link: curriculumValues.trailers?.[index]?.url_link,
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Curriculum Preview Saved Successfully!", 1);
                      setShowNavigationAlert(false);
                    } else {
                      Toastiy("Failed to Save Curriculum Preview");
                    }
                    resolve();
                  },
                })
              );
            })
          );
        } else if (
          curriculumValues?.trailers?.[index]?.url_link &&
          curriculumValues?.trailers?.[index]?.url_link !== data?.trailers?.[index]?.url_link
        ) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                updateCurriculumLanguagePreviewAction({
                  apiPayloadRequest: {
                    _id: curriculum_id,
                    language_code: activeTab,
                    type_of_url: parseInt(curriculumValues.trailers?.[index]?.type_of_url),
                    url_link: curriculumValues.trailers?.[index]?.url_link,
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Curriculum Preview Saved Successfully!", 1);
                      setShowNavigationAlert(false);
                    } else {
                      Toastiy("Failed to Save Curriculum Preview");
                    }
                    resolve();
                  },
                })
              );
            })
          );
        }
      });

      curriculumValues?.bg_Images?.map((item, index) => {
        if (!data?.bg_Images?.[index]?.url_link && curriculumValues.bg_Images?.[index]?.url_link) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                saveBGCurriculumLanguageAction({
                  apiPayloadRequest: {
                    _id: curriculum_id,
                    language_code: activeTab,
                    type_of_url: parseInt(curriculumValues?.bg_Images?.[index]?.type_of_url),
                    url_link: curriculumValues.bg_Images?.[index]?.url_link,
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Curriculum Bg Updated Successfully!", 1);
                      setShowNavigationAlert(false);
                    } else {
                      Toastiy("Failed to Save Curriculum Bg");
                    }
                    resolve();
                  },
                })
              );
            })
          );
        } else if (
          curriculumValues?.bg_Images?.[index]?.url_link &&
          curriculumValues?.bg_Images?.[index]?.url_link !== data?.bg_Images?.[index]?.url_link
        ) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                updateBGCurriculumLanguageAction({
                  apiPayloadRequest: {
                    _id: curriculum_id,
                    language_code: activeTab,
                    type_of_url: parseInt(curriculumValues?.bg_Images?.[index]?.type_of_url),
                    url_link: curriculumValues?.bg_Images?.[index]?.url_link,
                  },
                  callback: (res) => {
                    if (res?.type === 1) {
                      Toastiy("Curriculum Bg Updated Successfully!", 1);
                      setShowNavigationAlert(false);
                    } else {
                      Toastiy("Failed to Save Curriculum Bg");
                    }
                    resolve();
                  },
                })
              );
            })
          );
        }
      });
    }
    setLoading(false);
    setShowCurriculumBox(false);
    await Promise.all(requestsList);
    if (refetchDetails) getCurriculumDetails();
    if (callback) callback();
  };

  const handlePublishCurriculum = (publishType = true) => {
    setShowCurriculumErrors(false);
    setCurriculumPublishLoading(true);
    if (curriculum_id !== undefined) {
      dispatch(
        updateCurriculumAction({
          apiPayloadRequest: {
            _id: curriculum_id,
            publish: publishType,
          },
          callback: (res) => {
            if (res?.type === 1) {
              if (publishType) {
                Toastiy("Curriculum Published Successfully!", 1);
                setShowNavigationAlert(false);
                setTimeout(() => navigate("/curriculum/all"), 500);
              } else {
                setCurriculumValues((prev) => ({ ...prev, publish: publishType + "" }));
              }
              setCurriculumPublishLoading(false);
            }
          },
        })
      );
    }
  };

  // const handlePublishCurriculum = () => {
  //   setShowCurriculumErrors(false);
  //   if (curriculum_id !== undefined) {
  //     if (
  //       curriculumValues?.curriculum_name !== currCurriculumValues?.curriculum_name ||
  //       curriculumValues?.curriculum_description !== currCurriculumValues?.curriculum_description
  //     ) {
  //       dispatch(
  //         updateCurriculumAction({
  //           apiPayloadRequest: {
  //             _id: curriculum_id,
  //             curriculum_name: curriculumValues?.curriculum_name,
  //             curriculum_description: curriculumValues?.curriculum_description,
  //           },
  //           callback: (res) => {
  //             if (res?.type === 1) {
  //               Toastiy("Curriculum Updated Successfully!", 1);
  //               setCurrCurriculumValues((prev) => ({
  //                 ...prev,
  //                 _id: curriculum_id,
  //                 curriculum_name: curriculumValues?.curriculum_name,
  //                 curriculum_description: curriculumValues?.curriculum_description,
  //               }));
  //               navigate("/curriculum/all");
  //             }
  //           },
  //         })
  //       );
  //     }

  //     const keysArray = Object.keys(inititialUrlState);

  //     curriculumValues?.bg_Images?.map((item, index) => {
  //       if (
  //         curriculumValues?.bg_Images?.[index]?.url_link !==
  //         currCurriculumValues?.bg_Images?.[index]?.url_link
  //       ) {
  //         setTimeout(() => {
  //           dispatch(
  //             updateBGCurriculumAction({
  //               apiPayloadRequest: {
  //                 _id: curriculum_id,
  //                 type_of_url: parseInt(curriculumValues.bg_Images?.[index]?.type_of_url),
  //                 url_link: curriculumValues.bg_Images?.[index]?.url_link,
  //               },
  //               callback: (res) => {
  //                 if (res?.type === 1) {
  //                   Toastiy("Curriculum BG Updated Successfully!", 1);
  //                   setCurrCurriculumValues((prev) => ({
  //                     ...prev,
  //                     bg_Images: prev.bg_Images.map((item, i) => ({
  //                       type_of_url: curriculumValues.bg_Images?.[i]?.type_of_url,
  //                       url_link: curriculumValues.bg_Images?.[i]?.url_link,
  //                     })),
  //                   }));
  //                 } else {
  //                   Toastiy("Failed to Save Curriculum BG");
  //                 }
  //               },
  //             })
  //           );
  //         }, index * 500);
  //       }
  //     });
  //   } else {
  //     setLoading(true);
  //     dispatch(
  //       saveCurriculumAction({
  //         apiPayloadRequest: {
  //           curriculum_name: curriculumValues?.curriculum_name,
  //           curriculum_description: curriculumValues?.curriculum_description,
  //         },
  //         callback: async (response) => {
  //           await saveCurriculumBg(response);
  //           await saveLessons(response);

  //           layoutArray.map((layout, index) => {
  //             setTimeout(() => {
  //               dispatch(
  //                 saveCurriculumLayoutAction({
  //                   apiPayloadRequest: {
  //                     _id: layout?._id,
  //                     curriculumObjId: response?.data[0]?._id,
  //                     order: layout?.order,
  //                     // title: layout?.title || "",
  //                     // subtitle: layout?.subtitle || "",
  //                     // expiry: layout?.expiry,
  //                   },
  //                 })
  //               );
  //             }, index * 500);
  //           });
  //           Toastiy("Curriculum Updated Successfully!", 1);
  //           navigate("/curriculum/all");
  //         },
  //       })
  //     );
  //   }
  // };

  const saveCurriculumBg = async (curriculumResponse) => {
    if (curriculumResponse?.type === 1) {
      const id = curriculumResponse?.data[0]?._id;

      keysArray.map((item, index) => {
        setTimeout(() => {
          dispatch(
            saveBGCurriculumAction({
              apiPayloadRequest: {
                _id: id,
                type_of_url: parseInt(curriculumValues.bg_Images[item].type_of_url),
                url_link: curriculumValues.bg_Images[item].url_link,
              },
              callback: (res) => {
                if (res?.type !== 1) {
                  Toastiy("Failed to Save Curriculum BG");
                } else {
                  setShowNavigationAlert(false);
                }
              },
            })
          );
        }, 500 * (index + 1));
      });
    }
  };

  const saveCurriculumCommonBg = async (curriculumResponse) => {
    if (curriculumResponse?.type === 1) {
      const id = curriculumResponse?.data[0]?._id;

      keysArray.map((item, index) => {
        setTimeout(() => {
          if (curriculumValues.generic_bg_Images[item].url_link) {
            dispatch(
              saveCommonBGCurriculumAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: parseInt(curriculumValues.generic_bg_Images[item].type_of_url),
                  url_link: curriculumValues.generic_bg_Images[item].url_link,
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to Save Curriculum Common BG");
                  } else {
                    setShowNavigationAlert(false);
                  }
                },
              })
            );
          }
        }, 500 * (index + 1));
      });
    }
  };

  const saveCurriculumPreview = async (curriculumResponse) => {
    if (curriculumResponse?.type === 1) {
      const id = curriculumResponse?.data[0]?._id;

      keysArray.map((item, index) => {
        setTimeout(() => {
          if (curriculumValues.trailers[item].url_link) {
            dispatch(
              saveCurriculumPreviewAction({
                apiPayloadRequest: {
                  _id: id,
                  type_of_url: parseInt(curriculumValues.trailers[item].type_of_url),
                  url_link: curriculumValues.trailers[item].url_link,
                },
                callback: (res) => {
                  if (res?.type !== 1) {
                    Toastiy("Failed to Save Curriculum Common BG");
                  } else {
                    setShowNavigationAlert(false);
                  }
                },
              })
            );
          }
        }, 500 * (index + 1));
      });
    }
  };

  const saveLessons = async (curriculumResponse) => {
    if (curriculumResponse?.type === 1) {
      const id = curriculumResponse?.data[0]?._id;
      curriculumValues.lessons.map((lessonItem, index) => {
        if (index !== curriculumValues.lessons.length - 1) {
          setTimeout(() => {
            dispatch(
              saveCurriculumLessonAction({
                apiPayloadRequest: {
                  _id: id,
                  lesson_title: lessonItem?.lesson_title,
                  sortOrder: lessonItem?.sortOrder,
                  lessonPdfUrl: lessonItem?.lessonPdfUrl,
                  lesson_description: lessonItem?.lesson_description,
                  lesson_duration: lessonItem?.lesson_duration,
                },
                callback: (response) => {
                  if (response?.type === 1) {
                    saveLessonThumbnails(id, lessonItem);
                    saveLessonCommonThumbnails(id, lessonItem);
                    saveLessonPreview(id, lessonItem);
                    saveLessonSlides(id, lessonItem);
                    handlePublishCurriculum(false);
                  } else {
                    Toastiy("Failed to Save Curriculum BG");
                  }
                },
              })
            );
          }, 5000 * (index + 1));
        }
      });
    }
  };

  const saveLessonThumbnails = (id, lessonItem) => {
    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          saveCurriculumLessonThumbNailAction({
            apiPayloadRequest: {
              _id: id,
              lesson_title: lessonItem?.lesson_title,
              lessonObjId: lessonItem?.lessonObjId,
              type_of_url: parseInt(lessonItem?.lesson_tumbnail_urls[item].type_of_url),

              url_link: lessonItem?.lesson_tumbnail_urls[item].url_link,
            },
            callback: (res) => {
              if (res?.type !== 1) {
                Toastiy("Failed to Save Lesson Thumbnails");
              } else {
                setShowNavigationAlert(false);
              }
            },
          })
        );
      }, 500 * (index + 1));
    });
  };

  const saveLessonCommonThumbnails = (id, lessonItem) => {
    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          saveCurriculumLessonCommonThumbNailAction({
            apiPayloadRequest: {
              _id: id,
              lesson_title: lessonItem?.lesson_title,
              lessonObjId: lessonItem?.lessonObjId,
              type_of_url: parseInt(lessonItem?.lesson_generic_tumbnail_urls[item].type_of_url),

              url_link: lessonItem?.lesson_generic_tumbnail_urls[item].url_link,
            },
            callback: (res) => {
              if (res?.type !== 1) {
                Toastiy("Failed to Save Lesson Common Thumbnails");
              } else {
                setShowNavigationAlert(false);
              }
            },
          })
        );
      }, 500 * (index + 1));
    });
  };

  const saveLessonPreview = (id, lessonItem) => {
    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          saveCurriculumLessonPreviewAction({
            apiPayloadRequest: {
              _id: id,
              lesson_title: lessonItem?.lesson_title,
              lessonObjId: lessonItem?.lessonObjId,
              type_of_url: parseInt(lessonItem?.lesson_trailers[item].type_of_url),

              url_link: lessonItem?.lesson_trailers[item].url_link,
            },
            callback: (res) => {
              if (res?.type !== 1) {
                Toastiy("Failed to Save Lesson Preview");
              } else {
                setShowNavigationAlert(false);
              }
            },
          })
        );
      }, 500 * (index + 1));
    });
  };

  const saveLessonSlides = (id, lessonsData) => {
    lessonsData?.slides.map((slideItem, index) => {
      if (index !== lessonsData?.slides?.length - 1) {
        setTimeout(() => {
          dispatch(
            saveCurriculumLessonSlideAction({
              apiPayloadRequest: {
                _id: id,
                lesson_title: lessonsData?.lesson_title,
                lessonObjId: lessonsData?.lessonObjId,
                slide_title: slideItem?.slide_title,
                slide_description: slideItem?.slide_description,
              },
              callback: (response) => {
                if (response?.type === 1) {
                  saveLessonSlideBgColor(
                    id,
                    {
                      ...slideItem,
                      slideObjId: response?.data?.slideObjId,
                    },
                    lessonsData?.lesson_title
                  );
                  saveSlideThumbnails(curriculum_id, {
                    ...curriculumValues?.lessons[currLessonIndex]?.slides[currSlideIndex],
                    slideObjId: response?.data?.slideObjId,
                  });
                  cancelSlide();
                  handlePublishCurriculum(false);
                  Toastiy("Slide Draft Saved Successfully!", 1);
                  setShowNavigationAlert(false);
                } else {
                  Toastiy("Failed to Save Lesson Slide");
                }
              },
            })
          );
        }, 3000 * (index + 1));
      }
    });
  };

  const saveLessonSlideBgColor = (id, slideData, lesson_title) => {
    dispatch(
      saveCurriculumLessonSlideTypeAction({
        apiPayloadRequest: {
          _id: id,
          lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
          slideObjId: slideData?.slideObjId,
          type_of_slide: slideData?.slide_type[0]?.type_of_slide,
          slide_bg_color: slideData?.slide_type[0]?.slide_bg_color,
        },
        callback: (res) => {
          if (res?.type === 1) {
            if (slideData?.slide_type[0]?.type_of_slide !== "2") {
              saveLessonSlideBgImgVid(id, res?.data?.slidetypeObjId, slideData, lesson_title);
              // saveLessonSlideLink(id, slideData, lesson_title);
            } else {
              getCurriculumDetails();
              // saveLessonSlideLink(id, slideData, lesson_title);
            }
            handlePublishCurriculum(false);
            Toastiy("Slide Type Saved Successfully!", 1);
            setShowNavigationAlert(false);
          } else {
            Toastiy("Failed to Save Slide Type");
          }
        },
      })
    );
  };

  const saveSlideThumbnails = (id, slideData) => {
    keysArray.map((item) => {
      if (
        (!currSlide?.slide_bg?.[item]?.url_link || currSlide?.slide_bg?.length === 0) &&
        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_bg?.[item]
          ?.url_link
      ) {
        dispatch(
          saveCurriculumLessonBGSlideAction({
            apiPayloadRequest: {
              _id: id,
              lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
              slideObjId: slideData?.slideObjId,
              type_of_url: parseInt(
                curriculumValues?.lessons[currLessonIndex]?.slides?.[currSlideIndex]?.slide_bg[item]
                  ?.type_of_url
              ),

              url_link:
                curriculumValues?.lessons[currLessonIndex]?.slides?.[currSlideIndex]?.slide_bg[item]
                  .url_link,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Slide Thumbnail Save Successfully!", 1);
                setShowNavigationAlert(false);
              } else {
                Toastiy("Failed to Save Slide Thumbnail");
              }
            },
          })
        );
      } else if (
        currSlide?.slide_bg?.[item]?.url_link !==
        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_bg?.[item]
          ?.url_link
      ) {
        dispatch(
          updateCurriculumLessonBGSlideAction({
            apiPayloadRequest: {
              _id: id,
              lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
              slideObjId: slideData?.slideObjId,
              type_of_url: parseInt(
                curriculumValues?.lessons[currLessonIndex]?.slides?.[currSlideIndex]?.slide_bg[item]
                  ?.type_of_url
              ),

              url_link:
                curriculumValues?.lessons[currLessonIndex]?.slides?.[currSlideIndex]?.slide_bg[item]
                  .url_link,
            },
            callback: (res) => {
              if (res?.type === 1) {
                Toastiy("Slide Thumbnail Update Successfully!", 1);
                setShowNavigationAlert(false);
              } else {
                Toastiy("Failed to Update Slide Thumbnail");
              }
            },
          })
        );
      }
    });
  };

  const saveLessonSlideBgImgVid = (id, slidetypeObjId, slideData, lesson_title) => {
    keysArray.map((item, index) => {
      setTimeout(() => {
        if (
          currCurriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_type[0]
            ?.bg_asset_url?.[item]?.url_link
        ) {
          dispatch(
            updateCurriculumLessonSlideTypeBGAction({
              apiPayloadRequest: {
                _id: id,
                lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
                slideObjId: slideData?.slideObjId,
                slidetypeObjId: slidetypeObjId,
                type_of_url: parseInt(slideData?.slide_type[0]?.bg_asset_url[item].type_of_url),
                url_link: slideData?.slide_type[0]?.bg_asset_url[item].url_link,
              },
              callback: (res) => {
                if (res?.type !== 1) {
                  Toastiy("Failed to Save slide BG of Image or Video");
                } else {
                  setShowNavigationAlert(false);
                  getCurriculumDetails();
                }
              },
            })
          );
        } else {
          dispatch(
            saveCurriculumLessonSlideTypeBGAction({
              apiPayloadRequest: {
                _id: id,
                lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
                slideObjId: slideData?.slideObjId,
                slidetypeObjId: slidetypeObjId,
                type_of_url: parseInt(slideData?.slide_type[0]?.bg_asset_url[item].type_of_url),
                url_link: slideData?.slide_type[0]?.bg_asset_url[item].url_link,
              },
              callback: (res) => {
                if (res?.type !== 1) {
                  Toastiy("Failed to Save slide BG of Image or Video");
                } else {
                  setShowNavigationAlert(false);
                  getCurriculumDetails();
                }
              },
            })
          );
        }
      }, 500 * (index + 1));
    });
  };

  const saveLessonSlideLink = (id, slideData, lesson_title) => {
    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          saveCurriculumLessonSlideTypeLinkAction({
            apiPayloadRequest: {
              _id: id,
              lesson_title: lesson_title,
              lessonObjId: curriculumValues?.lessons[currLessonIndex].lessonObjId,
              slideObjId: slideData?.slideObjId,
              slide_title: slideData?.slide_title,
              slidetypeObjId: slideData?.slide_type[0]?.slidetypeObjId,
              type_of_slide: slideData?.slide_type[0]?.type_of_slide,
              type_of_url: parseInt(slideData?.slide_type[0]?.slide_link_url[item].type_of_url),
              url_link: slideData?.slide_type[0]?.slide_link_url[0].url_link,
            },
            callback: (res) => {
              if (res?.type !== 1) {
                Toastiy("Failed to Save Slide Link");
              }
            },
          })
        );
      }, 500 * (index + 1));
    });
  };

  const validateSlideComplete = (slide) => {
    if (slide?.slide_type?.[0]?.type_of_slide !== "0" && !slide?.slide_description) {
      return false;
    } else if (!slide?.slide_title) {
      return false;
    } else if (!slide?.slide_type || slide?.slide_type?.length === 0) {
      return false;
    }
    if (slide?.slide_type?.[0]?.type_of_slide === "2") {
      if (!slide?.slide_type?.[0]?.slide_bg_color) return false;
    } else {
      if (slide?.slide_type?.[0]?.bg_asset_url?.length === 0) {
        return false;
      }
      for (let i = 0; i < slide?.slide_type?.[0]?.bg_asset_url?.length; i++) {
        if (
          !slide?.slide_type?.[0]?.bg_asset_url?.[i]?.type_of_url ||
          !slide?.slide_type?.[0]?.bg_asset_url?.[i]?.url_link
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const validateSlideLanguageComplete = (slide) => {
    if (slide?.slide_type?.[0]?.type_of_slide !== "0" && !slide?.slide_description) {
      return false;
    } else if (!slide?.slide_title) {
      return false;
    } else if (!slide?.slide_type || slide?.slide_type?.length === 0) {
      return false;
    }
    if (["0", "1"].includes(slide?.slide_type?.[0]?.type_of_slide)) {
      if (slide?.slide_type?.[0]?.bg_asset_url?.length === 0) {
        return false;
      }
      for (let i = 0; i < slide?.slide_type?.[0]?.bg_asset_url?.length; i++) {
        if (
          !slide?.slide_type?.[0]?.bg_asset_url?.[i]?.type_of_url ||
          !slide?.slide_type?.[0]?.bg_asset_url?.[i]?.url_link
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const validateLessonComplete = (lesson) => {
    if (!lesson?.lesson_description) {
      return false;
    } else if (!lesson?.lesson_title) {
      return false;
    } else if (lesson?.slides?.length - 1 <= 0) {
      return false;
    }
    for (let i = 0; i < lesson?.lesson_tumbnail_urls?.length; i++) {
      if (
        !lesson?.lesson_tumbnail_urls?.[i]?.type_of_url ||
        !lesson?.lesson_tumbnail_urls?.[i]?.url_link
      ) {
        return false;
      }
    }
    // for (let i = 0; i < lesson?.lesson_generic_tumbnail_urls?.length; i++) {
    //   if (
    //     !lesson?.lesson_generic_tumbnail_urls?.[i]?.type_of_url ||
    //     !lesson?.lesson_generic_tumbnail_urls?.[i]?.url_link
    //   ) {
    //     return false;
    //   }
    // }
    // for (let i = 0; i < lesson?.lesson_trailers?.length; i++) {
    //   if (!lesson?.lesson_trailers?.[i]?.type_of_url || !lesson?.lesson_trailers?.[i]?.url_link) {
    //     return false;
    //   }
    // }
    for (let i = 0; i < lesson?.slides?.length - 1; i++) {
      const slideValid = validateSlideComplete(lesson?.slides?.[i]);
      if (!slideValid) {
        return false;
      }
    }
    return true;
  };

  const validateLessonLanguageComplete = (lesson) => {
    if (!lesson?.lesson_description) {
      return false;
    } else if (!lesson?.lesson_title) {
      return false;
    } else if (lesson?.slides?.length - 1 <= 0) {
      return false;
    }
    for (let i = 0; i < lesson?.slides?.length - 1; i++) {
      const slideValid = validateSlideComplete(lesson?.slides?.[i]);
      if (!slideValid) {
        return false;
      }
    }
    return true;
  };

  const validateCurriculumComplete = (curriculum) => {
    if (!curriculum?.curriculum_name) {
      return false;
    } else if (!curriculum?.curriculum_description) {
      return false;
    } else if (curriculum?.lessons?.length - 1 <= 0) {
      return false;
    }
    for (let i = 0; i < curriculum?.bg_Images?.length; i++) {
      if (!curriculum?.bg_Images?.[i]?.type_of_url || !curriculum?.bg_Images?.[i]?.url_link) {
        return false;
      }
    }
    for (let i = 0; i < curriculum?.lessons?.length - 1; i++) {
      const lessonValid = validateLessonComplete(curriculum?.lessons?.[i]);
      if (!lessonValid) {
        return false;
      }
    }
    return true;
  };

  const validateCurriculumLanguageComplete = (curriculum) => {
    if (!curriculum?.curriculum_name) {
      return false;
    } else if (!curriculum?.curriculum_description) {
      return false;
    } else if (curriculum?.lessons?.length - 1 <= 0) {
      return false;
    }
    for (let i = 0; i < curriculum?.bg_Images?.length; i++) {
      if (!curriculum?.bg_Images?.[i]?.type_of_url || !curriculum?.bg_Images?.[i]?.url_link) {
        return false;
      }
    }
    for (let i = 0; i < curriculum?.lessons?.length - 1; i++) {
      const lessonValid = validateLessonComplete(curriculum?.lessons?.[i]);
      if (!lessonValid) {
        return false;
      }
    }
    return true;
  };

  const getAllLanguageResponse = (response) => {
    if (response?.type === 1) {
      setReadLanguageData(response?.data);
    }
  };

  const convertCompleteCurriculumToString = (curriculumObject) => {
    let curriculumString = `${curriculumObject?.curriculum_name}$#${curriculumObject?.curriculum_description}$#`;

    for (const lesson of curriculumObject?.lessons) {
      curriculumString += `${lesson?.lesson_title}@#${lesson?.lesson_description}@#`;

      for (const slide of lesson?.slides) {
        curriculumString += `${slide?.slide_title}!@${slide?.slide_description}!@`;
        curriculumString += "***";
      }

      curriculumString += "+++";
    }
    return curriculumString;
  };

  const updateCompleteCurriculumWithTranslation = () => {
    const combinedText = convertCompleteCurriculumToString(curriculumValues);

    if (activeTab.length > 0) {
      dispatch(
        translateLanguageAction({
          apiPayloadRequest: {
            targetcode: activeTab,
            translatetext: combinedText,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const restructedData = reconstructCompleteCurriculumFromString(res?.data);
              if (restructedData) {
                setCurriculumValues(restructedData);
                setShowNavigationAlert(true);
              }
            } else if (res?.type === 2) {
              Toastiy("Language Translation failed!!");
              Toastiy(res?.message);
            }
          },
        })
      );
    }
  };

  const reconstructCurriculumFromString = (curriculumString) => {
    if (!curriculumString) return null;

    const [curriculum_name, curriculum_description] = curriculumString.split("///");

    const curriculumObject = {
      curriculum_name,
      curriculum_description,
    };

    const updatedCurriculum = structuredClone(curriculumValues);

    updatedCurriculum.curriculum_name = curriculumObject?.curriculum_name || "";
    updatedCurriculum.curriculum_description = curriculumObject?.curriculum_description || "";

    return updatedCurriculum;
  };

  const convertCurriculumToString = (curriculumObject) => {
    let curriculumString = `${curriculumObject?.curriculum_name}///${curriculumObject?.curriculum_description}`;

    return curriculumString;
  };

  const updateCurriculumWithTranslation = () => {
    const combinedText = convertCurriculumToString(curriculumValues);

    if (activeTab.length > 0) {
      dispatch(
        translateLanguageAction({
          apiPayloadRequest: {
            targetcode: activeTab,
            translatetext: combinedText,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const restructedData = reconstructCurriculumFromString(res?.data);
              if (restructedData) {
                setCurriculumValues(restructedData);
                setShowNavigationAlert(true);
              }
            } else if (res?.type === 2) {
              Toastiy("Language Translation failed!!");
              Toastiy(res?.message);
            }
          },
        })
      );
    }
  };

  const reconstructLessonFromString = (lessonString) => {
    if (!lessonString) return null;

    const [lesson_title, lesson_description] = lessonString.split("///");

    const lessonObject = {
      lesson_title,
      lesson_description,
    };

    const updatedCurriculum = structuredClone(curriculumValues);

    updatedCurriculum.lessons[currLessonIndex].lesson_title = lessonObject?.lesson_title || "";
    updatedCurriculum.lessons[currLessonIndex].lesson_description =
      lessonObject?.lesson_description || "";

    return updatedCurriculum;
  };

  const convertLessonToString = (lessonObject) => {
    let lessonString = `${lessonObject?.lesson_title}///${lessonObject?.lesson_description}`;

    return lessonString;
  };

  const updateLessonWithTranslation = () => {
    const combinedText = convertLessonToString(curriculumValues?.lessons?.[currLessonIndex]);

    if (activeTab.length > 0) {
      dispatch(
        translateLanguageAction({
          apiPayloadRequest: {
            targetcode: activeTab,
            translatetext: combinedText,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const restructedData = reconstructLessonFromString(res?.data);
              if (restructedData) {
                setCurriculumValues(restructedData);
                setShowNavigationAlert(true);
              }
            } else if (res?.type === 2) {
              Toastiy("Language Translation failed!!");
              Toastiy(res?.message);
            }
          },
        })
      );
    }
  };

  const reconstructSlideFromString = (slideString) => {
    if (!slideString) return null;

    const [slide_title, slide_description] = slideString.split("////");

    const slideObject = {
      slide_title,
      slide_description,
    };

    const updatedCurriculum = structuredClone(curriculumValues);

    updatedCurriculum.lessons[currLessonIndex].slides[currSlideIndex].slide_title =
      slideObject?.slide_title || "";
    updatedCurriculum.lessons[currLessonIndex].slides[currSlideIndex].slide_description =
      slideObject?.slide_description || "";

    return updatedCurriculum;
  };

  const convertSlideToString = (slideObject) => {
    let slideString = `${slideObject?.slide_title}////${slideObject?.slide_description}`;

    return slideString;
  };

  const updateSlideWithTranslation = () => {
    const combinedText = convertSlideToString(
      curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
    );

    if (activeTab.length > 0) {
      dispatch(
        translateLanguageAction({
          apiPayloadRequest: {
            targetcode: activeTab,
            translatetext: combinedText,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const restructedData = reconstructSlideFromString(res?.data);
              if (restructedData) {
                setCurriculumValues(restructedData);
                setForceEditorUpdate(true);
                setShowNavigationAlert(true);
              }
            } else if (res?.type === 2) {
              Toastiy("Language Translation failed!!");
              Toastiy(res?.message);
            }
          },
        })
      );
    }
  };

  const reconstructCompleteCurriculumFromString = (curriculumString) => {
    if (!curriculumString) return null;

    const [curriculum_name, curriculum_description, lessonsString] = curriculumString.split("$#");
    const lessonsArray = lessonsString?.split("+++") || [];

    const curriculumObject = {
      curriculum_name,
      curriculum_description,
      lessons: [],
    };

    lessonsArray.forEach((lessonString) => {
      if (!lessonString) return;

      const [lesson_title, lesson_description, slidesString] = lessonString.split("@#");
      const slideArray = slidesString?.split("***") || [];

      const lessonObject = {
        lesson_title,
        lesson_description,
        slides: [],
      };

      slideArray.forEach((slideString) => {
        if (!slideString) return;

        const [slide_title, slide_description] = slideString.split("!@");
        lessonObject.slides.push({
          slide_title,
          slide_description,
        });
      });

      curriculumObject.lessons.push(lessonObject);
    });

    const updatedCurriculum = structuredClone(curriculumValues);

    updatedCurriculum.curriculum_name = curriculumObject?.curriculum_name;
    updatedCurriculum.curriculum_description = curriculumObject?.curriculum_description || "";

    curriculumObject?.lessons?.map((lesson, lessonIndex) => {
      updatedCurriculum.lessons[lessonIndex] = {
        ...(updatedCurriculum.lessons[lessonIndex] || {}),
        lesson_title: lesson?.lesson_title || "",
        lesson_description: lesson?.lesson_description || "",
        slides: lesson?.slides?.map((slide, i) => ({
          ...updatedCurriculum?.lessons[lessonIndex]?.slides[i],
          slide_title: slide?.slide_title || "",
          slide_description: slide?.slide_description || "",
        })),
      };
    });

    return updatedCurriculum;
  };

  useEffect(() => {
    if (activeTab === "en") {
      const updatedCurriculumErrors = {
        curriculum_name: curriculumValues?.curriculum_name?.length > 0 ? false : true,
        curriculum_description: curriculumValues?.curriculum_description?.length > 0 ? false : true,
        bg_Images: [
          {
            type_of_url: "0",
            url_link: curriculumValues?.bg_Images?.[0]?.url_link?.length > 0 ? false : true,
          },
          {
            type_of_url: "1",
            url_link: curriculumValues?.bg_Images?.[1]?.url_link?.length > 0 ? false : true,
          },
          {
            type_of_url: "2",
            url_link: curriculumValues?.bg_Images?.[2]?.url_link?.length > 0 ? false : true,
          },
        ],
        generic_bg_Images: [
          {
            type_of_url: "0",
            // url_link: curriculumValues?.generic_bg_Images?.[0]?.url_link?.length > 0 ? false : true,
            url_link: false,
          },
          {
            type_of_url: "1",
            // url_link: curriculumValues?.generic_bg_Images?.[1]?.url_link?.length > 0 ? false : true,
            url_link: false,
          },
          {
            type_of_url: "2",
            // url_link: curriculumValues?.generic_bg_Images?.[2]?.url_link?.length > 0 ? false : true,
            url_link: false,
          },
        ],
        trailers: [
          {
            type_of_url: "0",
            // url_link: curriculumValues?.trailers?.[0]?.url_link?.length > 0 ? false : true,
            url_link: false,
          },
          {
            type_of_url: "1",
            // url_link: curriculumValues?.trailers?.[1]?.url_link?.length > 0 ? false : true,
            url_link: false,
          },
          {
            type_of_url: "2",
            // url_link: curriculumValues?.trailers?.[2]?.url_link?.length > 0 ? false : true,
            url_link: false,
          },
        ],
      };

      setCurriculumErrors(updatedCurriculumErrors);
    }
  }, [curriculumValues, activeTab]);

  useEffect(() => {
    // if (activeTab === "en") {
    const updatedLessonErrors = {
      lesson_title:
        curriculumValues?.lessons?.[currLessonIndex]?.lesson_title?.length > 0 ? false : true,
      lesson_duration:
        curriculumValues?.lessons?.[currLessonIndex]?.lesson_duration?.length > 0 ? false : true,
      lesson_description:
        curriculumValues?.lessons?.[currLessonIndex]?.lesson_description?.length > 0 ? false : true,
      lesson_tumbnail_urls: [
        {
          type_of_url: "0",
          url_link:
            curriculumValues?.lessons?.[currLessonIndex]?.lesson_tumbnail_urls?.[0]?.url_link
              .length > 0
              ? false
              : true,
        },
        {
          type_of_url: "1",
          url_link:
            curriculumValues?.lessons?.[currLessonIndex]?.lesson_tumbnail_urls?.[1]?.url_link
              .length > 0
              ? false
              : true,
        },
        {
          type_of_url: "2",
          url_link:
            curriculumValues?.lessons?.[currLessonIndex]?.lesson_tumbnail_urls?.[2]?.url_link
              .length > 0
              ? false
              : true,
        },
      ],
      lesson_generic_tumbnail_urls: [
        {
          type_of_url: "0",
          // url_link:
          // curriculumValues?.lessons?.[currLessonIndex]?.lesson_generic_tumbnail_urls[0]?.url_link
          //   .length > 0
          //   ? false
          //   : true,
          url_link: false,
        },
        {
          type_of_url: "1",
          // url_link:
          //   curriculumValues?.lessons?.[currLessonIndex]?.lesson_generic_tumbnail_urls[1]?.url_link
          //     .length > 0
          //     ? false
          //     : true,
          url_link: false,
        },
        {
          type_of_url: "2",
          // url_link:
          //   curriculumValues?.lessons?.[currLessonIndex]?.lesson_generic_tumbnail_urls[2]?.url_link
          //     .length > 0
          //     ? false
          //     : true,
          url_link: false,
        },
      ],
      lesson_trailers: [
        {
          type_of_url: "0",
          // url_link:
          //   curriculumValues?.lessons?.[currLessonIndex]?.lesson_trailers[0]?.url_link.length > 0
          //     ? false
          //     : true,
          url_link: false,
        },
        {
          type_of_url: "1",
          // url_link:
          //   curriculumValues?.lessons?.[currLessonIndex]?.lesson_trailers[1]?.url_link.length > 0
          //     ? false
          //     : true,
          url_link: false,
        },
        {
          type_of_url: "2",
          // url_link:
          //   curriculumValues?.lessons?.[currLessonIndex]?.lesson_trailers[2]?.url_link.length > 0
          //     ? false
          //     : true,
          url_link: false,
        },
      ],
    };

    setLessonErrors(updatedLessonErrors);
    // }
  }, [currLessonIndex, curriculumValues, activeTab]);

  useEffect(() => {
    // if (activeTab === "en") {
    const updateSlideErrors = {
      slide_title:
        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_title.length >
        0
          ? false
          : true,
      slide_description:
        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_type?.[0]
          ?.type_of_slide === "0" ||
        curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_description
          .length > 0
          ? false
          : true,
      slide_bg: [
        {
          type_of_url: "0",
          // url_link:
          //   curriculumValues?.lessons?.[currLessonIndex]?.slides?.slide_bg?.[0]
          //     ?.url_link?.length > 0
          //     ? false
          //     : true,
          url_link: false,
        },
        {
          type_of_url: "1",
          // url_link:
          //   curriculumValues?.lessons?.[currLessonIndex]?.slides[currSlideIndex]
          //     ?.slide_bg?.[1]?.url_link?.length > 0
          //     ? false
          //     : true,
          url_link: false,
        },
        {
          type_of_url: "2",
          // url_link:
          //   curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
          //     ?.slide_bg?.[2]?.url_link?.length > 0
          //     ? false
          //     : true,
          url_link: false,
        },
      ],
      slide_type: [
        {
          bg_asset_url: [
            {
              type_of_url: "0",
              url_link:
                curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                  ?.slide_type?.[0]?.bg_asset_url?.[0]?.url_link?.length > 0
                  ? false
                  : true,
            },
            {
              type_of_url: "1",
              url_link:
                curriculumValues?.lessons?.[currLessonIndex]?.slides[currSlideIndex]
                  ?.slide_type?.[0]?.bg_asset_url?.[1]?.url_link?.length > 0
                  ? false
                  : true,
            },
            {
              type_of_url: "2",
              url_link:
                curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                  ?.slide_type?.[0]?.bg_asset_url?.[2]?.url_link?.length > 0
                  ? false
                  : true,
            },
          ],
          slide_bg_color:
            curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_type?.[0]
              ?.slide_bg_color?.length > 0
              ? false
              : true,
          type_of_slide:
            curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]?.slide_type?.[0]
              ?.type_of_slide?.length > 0
              ? false
              : true,
          slide_link_url: [
            {
              type_of_url: "0",
              url_link:
                curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                  ?.slide_type?.[0]?.slide_link_url?.[0]?.url_link?.length > 0
                  ? false
                  : true,
            },
            {
              type_of_url: "1",
              url_link:
                curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                  ?.slide_type?.[0]?.slide_link_url?.[1]?.url_link?.length > 0
                  ? false
                  : true,
            },
            {
              type_of_url: "2",
              url_link:
                curriculumValues?.lessons?.[currLessonIndex]?.slides?.[currSlideIndex]
                  ?.slide_type?.[0]?.slide_link_url?.[2]?.url_link?.length > 0
                  ? false
                  : true,
            },
          ],
        },
      ],
    };

    setSlideErrors(updateSlideErrors);
    // }
  }, [currLessonIndex, currSlideIndex, curriculumValues, activeTab]);

  useEffect(() => {
    if (activeTab === "en") {
      setCurriculumValues(currCurriculumValues);
    } else {
      const isPresent = languagemeta?.some((language) => language?.language_code === activeTab);
      if (isPresent) {
        const data = languagemeta?.filter((item) => item?.language_code === activeTab)[0];
        const updatedCurriculumValues = {
          curriculum_name: data?.curriculum_name || currCurriculumValues?.curriculum_name || "",
          curriculum_description:
            data?.curriculum_description || currCurriculumValues?.curriculum_description || "",
          curriculumPdfUrl: data?.curriculumPdfUrl || currCurriculumValues?.curriculumPdfUrl || "",
          bg_Images: [
            {
              type_of_url: "0",
              url_link:
                data?.bg_Images?.find((d) => d?.type_of_url === "0")?.url_link ||
                currCurriculumValues?.bg_Images?.find((d) => d?.type_of_url === "0")?.url_link ||
                "",
            },
            {
              type_of_url: "1",
              url_link:
                data?.bg_Images?.find((d) => d?.type_of_url === "1")?.url_link ||
                currCurriculumValues?.bg_Images?.find((d) => d?.type_of_url === "1")?.url_link ||
                "",
            },
            {
              type_of_url: "2",
              url_link:
                data?.bg_Images?.find((d) => d?.type_of_url === "2")?.url_link ||
                currCurriculumValues?.bg_Images?.find((d) => d?.type_of_url === "2")?.url_link ||
                "",
            },
          ],
          trailers: [
            {
              type_of_url: "0",
              url_link:
                data?.trailers?.find((d) => d?.type_of_url === "0")?.url_link ||
                currCurriculumValues?.trailers?.find((d) => d?.type_of_url === "0")?.url_link ||
                "",
            },
            {
              type_of_url: "1",
              url_link:
                data?.trailers?.find((d) => d?.type_of_url === "1")?.url_link ||
                currCurriculumValues?.trailers?.find((d) => d?.type_of_url === "1")?.url_link ||
                "",
            },
            {
              type_of_url: "2",
              url_link:
                data?.trailers?.find((d) => d?.type_of_url === "2")?.url_link ||
                currCurriculumValues?.trailers?.find((d) => d?.type_of_url === "2")?.url_link ||
                "",
            },
          ],
          publish: currCurriculumValues?.publish,
          lessons: currCurriculumValues?.lessons?.map((lesson, index) => {
            const lessonLangIndex = data?.lessons?.findIndex(
              (d) => d?.lessonObjId === lesson?.lessonObjId
            );
            return {
              lesson_title:
                data?.lessons?.[lessonLangIndex]?.lesson_title || lesson?.lesson_title || "",
              lesson_description:
                data?.lessons?.[lessonLangIndex]?.lesson_description ||
                lesson?.lesson_description ||
                "",
              sortOrder: data?.lessons?.[lessonLangIndex]?.sortOrder || lesson?.sortOrder || "",
              lessonPdfUrl:
                data?.lessons?.[lessonLangIndex]?.lessonPdfUrl || lesson?.lessonPdfUrl || "",
              lessonObjId: lesson?.lessonObjId,
              lesson_tumbnail_urls: [
                {
                  type_of_url: "0",
                  url_link:
                    data?.lessons?.[lessonLangIndex]?.lesson_tumbnail_urls?.find(
                      (d) => d?.type_of_url === "0"
                    )?.url_link ||
                    lesson?.lesson_tumbnail_urls?.find((d) => d?.type_of_url === "0")?.url_link ||
                    "",
                },
                {
                  type_of_url: "1",
                  url_link:
                    data?.lessons?.[lessonLangIndex]?.lesson_tumbnail_urls?.find(
                      (d) => d?.type_of_url === "1"
                    )?.url_link ||
                    lesson?.lesson_tumbnail_urls?.find((d) => d?.type_of_url === "1")?.url_link ||
                    "",
                },
                {
                  type_of_url: "2",
                  url_link:
                    data?.lessons?.[lessonLangIndex]?.lesson_tumbnail_urls?.find(
                      (d) => d?.type_of_url === "2"
                    )?.url_link ||
                    lesson?.lesson_tumbnail_urls?.find((d) => d?.type_of_url === "2")?.url_link ||
                    "",
                },
              ],
              lesson_trailers: [
                {
                  type_of_url: "0",
                  url_link:
                    data?.lessons?.[lessonLangIndex]?.lesson_trailers?.find(
                      (d) => d?.type_of_url === "0"
                    )?.url_link ||
                    lesson?.lesson_trailers?.find((d) => d?.type_of_url === "0")?.url_link ||
                    "",
                },
                {
                  type_of_url: "1",
                  url_link:
                    data?.lessons?.[lessonLangIndex]?.lesson_trailers?.find(
                      (d) => d?.type_of_url === "1"
                    )?.url_link ||
                    lesson?.lesson_trailers?.find((d) => d?.type_of_url === "1")?.url_link ||
                    "",
                },
                {
                  type_of_url: "2",
                  url_link:
                    data?.lessons?.[lessonLangIndex]?.lesson_trailers?.find(
                      (d) => d?.type_of_url === "2"
                    )?.url_link ||
                    lesson?.lesson_trailers?.find((d) => d?.type_of_url === "2")?.url_link ||
                    "",
                },
              ],
              slides: lesson?.slides?.map((slide, si) => {
                const slideLangIndex = data?.lessons?.[lessonLangIndex]?.slides?.findIndex(
                  (d) => d?.slideObjId === slide?.slideObjId
                );
                return {
                  slide_title:
                    data?.lessons?.[lessonLangIndex]?.slides?.[slideLangIndex]?.slide_title ||
                    slide?.slide_title ||
                    "",
                  slide_description:
                    data?.lessons?.[lessonLangIndex]?.slides?.[slideLangIndex]?.slide_description ||
                    slide?.slide_description ||
                    "",
                  slideObjId: slide?.slideObjId,
                  slide_type: [
                    {
                      type_of_slide: slide?.slide_type?.[0]?.type_of_slide || "0",
                      slidetypeObjId: slide?.slide_type?.[0]?.slidetypeObjId || "",
                      slide_bg_color: slide?.slide_type?.[0]?.slide_bg_color || "#ffffff",
                      bg_asset_url: [
                        {
                          type_of_url: "0",
                          url_link:
                            data?.lessons?.[lessonLangIndex]?.slides?.[
                              slideLangIndex
                            ]?.slide_type?.[0]?.bg_asset_url?.find((d) => d?.type_of_url === "0")
                              ?.url_link ||
                            slide?.slide_type?.[0]?.bg_asset_url?.find(
                              (d) => d?.type_of_url === "0"
                            )?.url_link ||
                            "",
                        },
                        {
                          type_of_url: "1",
                          url_link:
                            data?.lessons?.[lessonLangIndex]?.slides?.[
                              slideLangIndex
                            ]?.slide_type?.[0]?.bg_asset_url?.find((d) => d?.type_of_url === "1")
                              ?.url_link ||
                            slide?.slide_type?.[0]?.bg_asset_url?.find(
                              (d) => d?.type_of_url === "1"
                            )?.url_link ||
                            "",
                        },
                        {
                          type_of_url: "2",
                          url_link:
                            data?.lessons?.[lessonLangIndex]?.slides?.[
                              slideLangIndex
                            ]?.slide_type?.[0]?.bg_asset_url?.find((d) => d?.type_of_url === "2")
                              ?.url_link ||
                            slide?.slide_type?.[0]?.bg_asset_url?.find(
                              (d) => d?.type_of_url === "2"
                            )?.url_link ||
                            "",
                        },
                      ],
                    },
                  ],
                };
              }),
            };
          }),
        };
        setCurriculumValues(updatedCurriculumValues);
      } else {
        const updatedCurriculumValues = {
          curriculum_name: currCurriculumValues?.curriculum_name || "",
          curriculum_description: currCurriculumValues?.curriculum_description || "",
          curriculumPdfUrl: currCurriculumValues?.curriculumPdfUrl || "",
          bg_Images: [
            {
              type_of_url: "0",
              url_link:
                currCurriculumValues?.bg_Images?.find((d) => d?.type_of_url === "0")?.url_link ||
                "",
            },
            {
              type_of_url: "1",
              url_link:
                currCurriculumValues?.bg_Images?.find((d) => d?.type_of_url === "1")?.url_link ||
                "",
            },
            {
              type_of_url: "2",
              url_link:
                currCurriculumValues?.bg_Images?.find((d) => d?.type_of_url === "2")?.url_link ||
                "",
            },
          ],
          trailers: [
            {
              type_of_url: "0",
              url_link:
                currCurriculumValues?.bg_Images?.find((d) => d?.type_of_url === "0")?.url_link ||
                "",
            },
            {
              type_of_url: "1",
              url_link:
                currCurriculumValues?.bg_Images?.find((d) => d?.type_of_url === "1")?.url_link ||
                "",
            },
            {
              type_of_url: "2",
              url_link:
                currCurriculumValues?.bg_Images?.find((d) => d?.type_of_url === "2")?.url_link ||
                "",
            },
          ],
          publish: currCurriculumValues?.publish,
          lessons: currCurriculumValues?.lessons?.map((lesson, index) => ({
            lesson_title: lesson?.lesson_title || "",
            lesson_description: lesson?.lesson_description || "",
            lessonObjId: lesson?.lessonObjId || "",
            sortOrder: lesson?.sortOrder || "",
            lessonPdfUrl: lesson?.lessonPdfUrl || "",
            lesson_tumbnail_urls: [
              {
                type_of_url: "0",
                url_link:
                  lesson?.lesson_tumbnail_urls?.find((d) => d?.type_of_url === "0")?.url_link || "",
              },
              {
                type_of_url: "1",
                url_link:
                  lesson?.lesson_tumbnail_urls?.find((d) => d?.type_of_url === "1")?.url_link || "",
              },
              {
                type_of_url: "2",
                url_link:
                  lesson?.lesson_tumbnail_urls?.find((d) => d?.type_of_url === "2")?.url_link || "",
              },
            ],
            lesson_trailers: [
              {
                type_of_url: "0",
                url_link:
                  lesson?.lesson_trailers?.find((d) => d?.type_of_url === "0")?.url_link || "",
              },
              {
                type_of_url: "1",
                url_link:
                  lesson?.lesson_trailers?.find((d) => d?.type_of_url === "1")?.url_link || "",
              },
              {
                type_of_url: "2",
                url_link:
                  lesson?.lesson_trailers?.find((d) => d?.type_of_url === "2")?.url_link || "",
              },
            ],
            slides: lesson?.slides?.map((slide, si) => ({
              slide_title: slide?.slide_title || "",
              slide_description: slide?.slide_description || "",
              slideObjId: slide?.slideObjId || "",
              slide_type: [
                {
                  type_of_slide: slide?.slide_type?.[0]?.type_of_slide || "0",
                  slide_bg_color: slide?.slide_type?.[0]?.slide_bg_color || "#ffffff",
                  slidetypeObjId: slide?.slide_type?.[0]?.slidetypeObjId || "",
                  bg_asset_url: [
                    {
                      type_of_url: "0",
                      url_link:
                        slide?.slide_type?.[0]?.bg_asset_url?.find((d) => d?.type_of_url === "0")
                          ?.url_link || "",
                    },
                    {
                      type_of_url: "1",
                      url_link:
                        slide?.slide_type?.[0]?.bg_asset_url?.find((d) => d?.type_of_url === "1")
                          ?.url_link || "",
                    },
                    {
                      type_of_url: "2",
                      url_link:
                        slide?.slide_type?.[0]?.bg_asset_url?.find((d) => d?.type_of_url === "2")
                          ?.url_link || "",
                    },
                  ],
                },
              ],
            })),
          })),
        };
        setCurriculumValues(updatedCurriculumValues);
      }
    }
  }, [activeTab, currCurriculumValues, languagemeta]);

  const getCurriculumDetails = () => {
    dispatch(
      readCurriculumAction({
        apiPayloadRequest: { _id: curriculum_id },
        callback: (res) => {
          if (res.type === 1) {
            const data = res?.data[0];

            const updatedData = {
              curriculum_name: data?.curriculum_name,
              publish: data?.publish,
              curriculum_description: data?.curriculum_description,
              curriculumPdfUrl: data?.curriculumPdfUrl,
              bg_Images: (data.bg_Images || [])
                .map((image, index) => ({
                  type_of_url: image?.type_of_url,
                  url_link: image?.url_link,
                }))
                ?.sort((a, b) => {
                  if (a?.type_of_url?.toLowerCase() < b?.type_of_url?.toLowerCase()) return -1; // Ascending order
                  if (a?.type_of_url?.toLowerCase() > b?.type_of_url?.toLowerCase()) return 1;
                  return 0; // Equal values
                }),
              generic_bg_Images:
                data?.generic_bg_Images?.length > 0
                  ? (data?.generic_bg_Images || [])
                      .map((image, index) => ({
                        type_of_url: image?.type_of_url,
                        url_link: image?.url_link,
                      }))
                      ?.sort((a, b) => {
                        if (a?.type_of_url?.toLowerCase() < b?.type_of_url?.toLowerCase())
                          return -1; // Ascending order
                        if (a?.type_of_url?.toLowerCase() > b?.type_of_url?.toLowerCase()) return 1;
                        return 0; // Equal values
                      })
                  : initialCurriculumState.generic_bg_Images,
              trailers:
                data?.trailers?.length > 0
                  ? (data?.trailers || [])
                      .map((image, index) => ({
                        type_of_url: image?.type_of_url,
                        url_link: image?.url_link,
                      }))
                      ?.sort((a, b) => {
                        if (a?.type_of_url?.toLowerCase() < b?.type_of_url?.toLowerCase())
                          return -1; // Ascending order
                        if (a?.type_of_url?.toLowerCase() > b?.type_of_url?.toLowerCase()) return 1;
                        return 0; // Equal values
                      })
                  : initialCurriculumState.trailers,
              lessons: (data?.lessons || []).map((lesson) => ({
                ...lesson,
                lesson_tumbnail_urls:
                  lesson?.lesson_tumbnail_urls?.length > 0
                    ? (lesson?.lesson_tumbnail_urls || [])
                        .map((thumbnail, index) => ({
                          type_of_url: thumbnail?.type_of_url,
                          url_link: thumbnail?.url_link,
                        }))
                        ?.sort((a, b) => {
                          if (a?.type_of_url?.toLowerCase() < b?.type_of_url?.toLowerCase())
                            return -1; // Ascending order
                          if (a?.type_of_url?.toLowerCase() > b?.type_of_url?.toLowerCase())
                            return 1;
                          return 0; // Equal values
                        })
                    : initialLessonState?.lesson_tumbnail_urls,
                lesson_generic_tumbnail_urls:
                  lesson?.lesson_generic_tumbnail_urls?.length > 0
                    ? (lesson.lesson_generic_tumbnail_urls || [])
                        .map((thumbnail, index) => ({
                          type_of_url: thumbnail?.type_of_url,
                          url_link: thumbnail?.url_link,
                        }))
                        ?.sort((a, b) => {
                          if (a?.type_of_url?.toLowerCase() < b?.type_of_url?.toLowerCase())
                            return -1; // Ascending order
                          if (a?.type_of_url?.toLowerCase() > b?.type_of_url?.toLowerCase())
                            return 1;
                          return 0; // Equal values
                        })
                    : initialLessonState.lesson_generic_tumbnail_urls,
                lesson_trailers:
                  lesson?.lesson_trailers?.length > 0
                    ? (lesson.lesson_trailers || [])
                        .map((thumbnail, index) => ({
                          type_of_url: thumbnail?.type_of_url,
                          url_link: thumbnail?.url_link,
                        }))
                        ?.sort((a, b) => {
                          if (a?.type_of_url?.toLowerCase() < b?.type_of_url?.toLowerCase())
                            return -1; // Ascending order
                          if (a?.type_of_url?.toLowerCase() > b?.type_of_url?.toLowerCase())
                            return 1;
                          return 0; // Equal values
                        })
                    : initialLessonState.lesson_trailers,
                slides: [
                  ...(lesson?.slides || []).map((slide) => {
                    const slide_type =
                      slide?.slide_type && slide?.slide_type?.length > 0 ? slide?.slide_type : [];
                    return {
                      ...slide,
                      slide_bg:
                        slide?.slide_bg?.length > 0
                          ? (slide?.slide_bg || [])
                              .map((thumbnail, index) => ({
                                type_of_url: thumbnail?.type_of_url,
                                url_link: thumbnail?.url_link,
                              }))
                              ?.sort((a, b) => {
                                if (a?.type_of_url?.toLowerCase() < b?.type_of_url?.toLowerCase())
                                  return -1; // Ascending order
                                if (a?.type_of_url?.toLowerCase() > b?.type_of_url?.toLowerCase())
                                  return 1;
                                return 0; // Equal values
                              })
                          : initialSlideState?.slide_bg,
                      slide_type: slide_type.map((type) => {
                        const bg_asset_url =
                          type?.bg_asset_url && type?.bg_asset_url?.length > 0
                            ? type?.bg_asset_url
                            : [];
                        const slide_link_url =
                          type?.slide_link_url && type?.slide_link_url?.length > 0
                            ? type?.slide_link_url
                            : [];
                        return {
                          ...type,
                          bg_asset_url: bg_asset_url
                            .map((asset, index) => ({
                              type_of_url: asset?.type_of_url,
                              url_link: asset?.url_link,
                            }))
                            ?.sort((a, b) => {
                              if (a?.type_of_url?.toLowerCase() < b?.type_of_url?.toLowerCase())
                                return -1; // Ascending order
                              if (a?.type_of_url?.toLowerCase() > b?.type_of_url?.toLowerCase())
                                return 1;
                              return 0; // Equal values
                            }),
                          slide_link_url: slide_link_url
                            .map((link, index) => ({
                              type_of_url: link?.type_of_url,
                              url_link: link?.url_link || "",
                            }))
                            ?.sort((a, b) => {
                              if (a?.type_of_url?.toLowerCase() < b?.type_of_url?.toLowerCase())
                                return -1; // Ascending order
                              if (a?.type_of_url?.toLowerCase() > b?.type_of_url?.toLowerCase())
                                return 1;
                              return 0; // Equal values
                            }),
                        };
                      }),
                    };
                  }),
                  initialSlideState,
                ],
              })),
            };

            setCurriculumStats({
              totalLikes: data?.totallikes ?? 0,
              totalViews: data?.curriculum_views ?? 0,
            });

            updatedData.lessons.push({
              ...initialLessonState,
              slides: [initialSlideState],
            });
            setLanguagemeta(data?.languagemetas);
            setCurriculumValues(updatedData);
            setCurrCurriculumValues(updatedData);
          }
        },
      })
    );
  };

  const fetchCurriculumLayouts = () => {
    dispatch(
      readCurriculumLayoutAction({
        apiPayloadRequest: {
          curriculumObjId: curriculum_id,
        },
        callback: (res) => {
          if (res?.type === 1) {
            console.log("res?.data", res?.data);
            const itemsArray = [];
            res?.data.forEach((layout) => {
              console.log("layout item", layout);
              let requiredData = [];
              if (Array.isArray(layout?.curriculums)) {
                requiredData = layout?.curriculums?.filter(
                  (item) => item?.curriculumObjId === curriculum_id
                );
              } else if (layout?.curriculums?.curriculumObjId) {
                requiredData = [layout?.curriculums];
              }

              console.log("requiredData", requiredData);

              const requiredId = layout?._id;
              const requiredMobileLayout = layout?.mobile_layout;
              const requiredName = layout?.layout_name;

              requiredData.map((item) => {
                itemsArray.push({
                  _id: requiredId,
                  mobile_layout: requiredMobileLayout,
                  layout_name: requiredName,
                  order: item.order,
                  // title: item.title,
                  // subtitle: item.subtitle,
                  // expiry: item.expiry,
                });
              });
              console.log("itemsArray", itemsArray);
            });
            setLayoutArray(itemsArray);
          }
        },
      })
    );
  };

  console.log("languagemetas", languagemeta);
  console.log("curriculumValues", curriculumValues);

  useEffect(() => {
    if (curriculum_id !== undefined) {
      getCurriculumDetails();
      fetchCurriculumLayouts();
    }
  }, []);

  useEffect(() => {
    dispatch(
      readLanguageAction({
        callback: getAllLanguageResponse,
      })
    );
  }, []);

  const saveCompleteCurriculumLanguage = async () => {
    saveCurriculumLanguage(false, () => {
      curriculumValues?.lessons?.forEach((lesson, lessonIndex) => {
        saveLessonLanguage(lessonIndex, false, () => {
          lesson?.slides?.forEach((slide, slideIndex) => {
            saveSlideLanguage(lessonIndex, slideIndex, slideIndex + 1 === lesson?.slides?.length);
          });
        });
      });
    });
  };

  const handleSendNotification = () => {
    dispatch(
      sendCustomNotificationAction({
        apiPayloadRequest: {
          _id: curriculum_id,
          type: "3",
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Notification Sent Successfully", 1);
          }
        },
      })
    );
  };

  return {
    curriculumValues,
    currCurriculumValues,
    handleChange,
    addNewLesson,
    addNewSlide,
    currLessonIndex,
    currSlideIndex,
    setCurrLesson,
    currLesson,
    cancelLesson,
    saveLesson,
    saveLessonLanguage,
    setShowLessonBox,
    showLessonBox,
    setCurrLessonIndex,
    setIsLessonEdit,
    initialLessonState,
    showSlideBox,
    showCurriculumBox,
    initialSlideState,
    cancelSlideModal,
    showPreview,
    storyData,
    handleClosePreview,
    handleOpenPreview,
    setCurrSlideIndex,
    saveSlide,
    saveSlideLanguage,
    setIsSlideEdit,
    setCurrSlide,
    setShowSlideBox,
    setShowCurriculumBox,
    handleSlideBgChange,
    saveCurriculum,
    saveCurriculumLanguage,
    curriculumErrors,
    lessonErrors,
    slideErrors,
    showCurriculumErrors,
    showLessonErrors,
    showSlideErrors,
    setShowCurriculumErrors,
    setShowLessonErrors,
    setShowSlideErrors,
    setLayoutValues,
    setLayoutInfo,
    setAddedLayout,
    addedLayout,
    editTargetLayout,
    isLayoutEdit,
    layoutInfo,
    layoutValues,
    initialLayoutValues,
    handleVideoLayoutChange,
    handleVideoDateChange,
    handleLayoutUpdate,
    handleAddLayoutToArray,
    layoutArray,
    setIsLayoutEdit,
    setEditLayoutIndex,
    setEditTargetLayout,
    readLanguageData,
    setActiveTab,
    activeTab,
    setIsLanguageSave,
    languagemeta,
    updateCompleteCurriculumWithTranslation,
    updateCurriculumWithTranslation,
    updateLessonWithTranslation,
    updateSlideWithTranslation,
    cancelCurriculum,
    isLessonEdit,
    isSlideEdit,
    validateSlideComplete,
    validateSlideLanguageComplete,
    validateLessonComplete,
    validateLessonLanguageComplete,
    validateCurriculumComplete,
    validateCurriculumLanguageComplete,
    handlePublishCurriculum,
    handleDeleteSlide,
    handleDeleteLesson,
    handleSaveLanguage,
    activeLanguageDetails,
    setActiveLanguageDetails,
    handleSlideLibraryVideoSelection,
    handleLessonLibraryVideoSelection,
    handleCurriculumLibraryVideoSelection,
    forceEditorUpdate,
    handleSlideTypeChange,
    isLanguageSave,
    saveCompleteCurriculumLanguage,
    handleDeleteLayoutFromArray,
    curriculumStats,
    curriculumPublishLoading,
    handleSendNotification,
  };
};
