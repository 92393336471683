import {
  Routes,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
// toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-confirm-alert/src/react-confirm-alert.css";

import Dashboard from "./screens/Dashboard/Dashboard";
import Languages from "./screens/Languages/Languages";
import AllLanguages from "./screens/Languages/AllLanguages";
import Categories from "./screens/Categories/Categories";
import AddLanguage from "./screens/Languages/AddLanguage";
import AllCategories from "./screens/Categories/AllCategories";
import AddCategory from "./screens/Categories/AddCategory";
import Users from "./screens/Users/Users";
import AddUser from "./screens/Users/AddUser";
import AllUsers from "./screens/Users/AllUsers";
import Videos from "./screens/Videos/Videos";
import AllVideos from "./screens/Videos/AllVideos";
import Curriculum from "./screens/Curriculum/Curriculum";
import AllCurriculum from "./screens/Curriculum/AllCurriculum";
import Givings from "./screens/Givings/Givings";
import AllGivings from "./screens/Givings/AllGivings";
import Twg from "./screens/TWG/Twg";
import AllTwg from "./screens/TWG/AllTwg";
import TestingTabs from "./components/categories/AddCategoryFields";
import Testimonials from "./screens/Testimonials/Testimonials";
import AllTestimonials from "./screens/Testimonials/AllTestimonials";
import AddCurriculum from "./screens/Curriculum/AddCurriculum";
import NotificationsList from "./screens/NotificationList/NotificationsList";
import AddNotifications from "./screens/NotificationList/AddNotifications";
import AddVideos from "./screens/Videos/AddVideos";
import AddTwg from "./screens/TWG/AddTwg";
import LoginPage from "./screens/login/LoginPage";
import MyProfile from "./components/profileMenu/MyProfile";
import Layout from "./screens/Layout/Layout";
import AddLayout from "./screens/Layout/AddLayout";
import AllLayouts from "./screens/Layout/AllLayouts";
import Feedback from "./screens/Feedback/Feedback";
import Prayers from "./screens/Prayers/Prayers";
import SessionExpired from "./components/universal/SessionExpired";
import UserProfileV2 from "./components/profileMenu/UserProfileV2";
import Analytics from "./screens/Analytics/Analytics";
import CloudflareVideos from "./screens/Cloudflare/CloudflareVideos";
import TestimonyCategory from "./screens/TestimonyCategory/TestimonyCategory";
import Journals from "./screens/Journals/Journals";
import AllJournals from "./screens/Journals/AllJournals";
import AddJournals from "./screens/Journals/AddJournals";
import Bible from "./screens/Bible/Bible";

function App() {
  return (
    <div>
      <RouterProvider
        router={createBrowserRouter(
          createRoutesFromElements(
            <>
              <Route exact path="/login" element={<LoginPage />} />
              {/* logout on session or token expire */}
              <Route exact path="/logout" element={<SessionExpired />} />
              <Route exact path="/" element={<Dashboard />}></Route>
              {/* languages routes  */}
              <Route exact path="/languages" element={<Languages />}></Route>
              <Route exact path="/languages/all" element={<AllLanguages />}></Route>
              <Route exact path="/languages/add" element={<AddLanguage />}></Route>
              <Route exact path="/layout" element={<Layout />}></Route>
              <Route exact path="/layout/all" element={<AllLayouts />}></Route>
              <Route exact path="/layout/add" element={<AddLayout />}></Route>
              {/* ends here */}
              {/* categories routes  */}
              <Route exact path="/categories" element={<Categories />}></Route>
              <Route exact path="/testing" element={<TestingTabs />}></Route>
              <Route exact path="/categories/all" element={<AllCategories />}></Route>
              <Route exact path="/categories/add" element={<AddCategory />}></Route>
              <Route exact path="/users" element={<Users />}></Route>
              <Route exact path="/users/add" element={<AddUser />}></Route>
              <Route exact path="/users/all" element={<AllUsers />}></Route>
              <Route path="/users/:userID" element={<UserProfileV2 />}></Route>
              <Route exact path="/videos" element={<Videos />}></Route>
              <Route exact path="/videos/all" element={<AllVideos />}></Route>
              <Route exact path="/videos/add" element={<AddVideos />}></Route>
              <Route exact path="/curriculum" element={<Curriculum />}></Route>
              <Route exact path="/curriculum/all" element={<AllCurriculum />}></Route>
              <Route exact path="/curriculum/add" element={<AddCurriculum />}></Route>
              <Route exact path="/testimonials" element={<Testimonials />}></Route>
              <Route exact path="/testimonials/all" element={<AllTestimonials />}></Route>
              <Route exact path="/givings" element={<Givings />}></Route>
              <Route exact path="/givings/all" element={<AllGivings />}></Route>
              <Route exact path="/notificationsList" element={<NotificationsList />}></Route>
              <Route exact path="/notificationsList/add" element={<AddNotifications />}></Route>
              <Route exact path="/twg" element={<Twg />}></Route>
              <Route exact path="/twg/all" element={<AllTwg />}></Route>
              <Route exact path="/twg/add" element={<AddTwg />}></Route>
              <Route exact path="/profile" element={<MyProfile />}></Route>
              <Route exact path="/prayers" element={<Prayers />}></Route>
              <Route exact path="/cloudflarevideos" element={<CloudflareVideos />}></Route>
              <Route exact path="/feedback" element={<Feedback />}></Route>
              <Route exact path="/analytics" element={<Analytics />}></Route>
              <Route exact path="/testimonycategory" element={<TestimonyCategory />}></Route>
              <Route exact path="/journals" element={<Journals />}></Route>
              <Route exact path="/journals/all" element={<AllJournals />}></Route>
              <Route exact path="/journals/add" element={<AddJournals />}></Route>
              <Route exact path="/bible" element={<Bible />}></Route>
            </>
          )
        )}
      />

      <ToastContainer />
    </div>
  );
}

export default App;
