import Axios from "../../utils/axios";

//Get Country data
export const updatedNotifications = (payload) => {
  const URL = "/notification/updateSchedule";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const sendNotification = (payload) => {
  const URL = "/notification/send";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const sendCustomNotification = (payload) => {
  const URL = "/notification/customnotify";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const getListOfNotifications = (payload) => {
  const URL = "/notification/list";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const getListOfFeedback = (payload) => {
  const URL = "/support/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const getListOfPrayer = (payload) => {
  const URL = "/prayers/read";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const deleteNotification = (payload) => {
  const URL = "/notification/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};
