import BilbleSearch from "./BilbleSearch";
import BibleVersionSearch from "./BibleVersionSearch";
import BibleLanguageSearch from "./BibleLanguageSearch";

const BibleHeader = ({ showParallelMode = false }) => {
  return (
    <div className="w-full flex items-center justify-between relative py-3 border-b border-greyish/50 mb-2 text-white">
      <div className="flex items-center gap-3">
        <BilbleSearch />
        <BibleVersionSearch />
        <BibleLanguageSearch />
      </div>
    </div>
  );
};

export default BibleHeader;
