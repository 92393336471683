import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ReactModal from "react-modal";
import ReactPlayer from "react-player";

import { readJournalAction } from "../../store/Languages/LanguageAction";

import { FaPlay } from "react-icons/fa";
import { GoMute, GoUnmute } from "react-icons/go";

import close from "../../assets/icons/close.svg";
import LensSearch from "../../assets/icons/LensSearch.svg";

const JournalLibraryModal = ({
  showVideoLibraryModal,
  setShowVideoLibraryModal,
  handleJournalSelection,
  perViewImages = 5,
}) => {
  const dispatch = useDispatch();

  const [activeItem, setActiveItem] = useState(null);
  const [cardVisible, setCardVisible] = useState(false);
  const [allJournalsData, setAllJournalsData] = useState([]);
  const [journalsData, setJournalsData] = useState([]);
  const [searchText, setSearchText] = useState("");

  console.log("journalsDatassss", journalsData);

  const handleSearchChange = (e) => {
    const text = e.target.value?.toLowerCase();
    setSearchText(e.target.value);
    if (text === "") {
      setJournalsData(allJournalsData);
    } else {
      setJournalsData(
        allJournalsData?.filter((d) => {
          return (
            d?.journal_title?.toLowerCase()?.includes(text) ||
            d?.journal_description?.toLowerCase()?.includes(text)
          );
        })
      );
    }
  };

  const fetchAllJournals = () => {
    dispatch(
      readJournalAction({
        apiPayloadRequest: {},
        callback: (res) => {
          console.log("res45", res);
          if (res?.type === 1) {
            setAllJournalsData(res?.data);
            setJournalsData(res?.data);
          }
        },
      })
    );
  };

  useEffect(() => {
    fetchAllJournals();
  }, []);

  useEffect(() => {
    if (activeItem) {
      setCardVisible(true);
    }

    return () => setCardVisible(false);
  }, [activeItem]);

  const CachedHoverCard = useMemo(
    () => (
      <HoverCard
        cardVisible={cardVisible}
        setCardVisible={setCardVisible}
        perViewImages={perViewImages}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        setSearchText={setSearchText}
        setJournalsData={setJournalsData}
        allJournalsData={allJournalsData}
        handleJournalSelection={handleJournalSelection}
      />
    ),
    [
      cardVisible,
      setCardVisible,
      activeItem,
      setActiveItem,
      setSearchText,
      setJournalsData,
      perViewImages,
      allJournalsData,
      handleJournalSelection,
    ]
  );

  return (
    <ReactModal
      isOpen={showVideoLibraryModal}
      onRequestClose={() => {
        setShowVideoLibraryModal(false);
        setSearchText("");
      }}
      contentLabel="Custom Modal"
      className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
      ariaHideApp={false}
    >
      <div className="MODAL-BODY h-[95%] w-[90%] md:absolute bg-blackishV2 text-white rounded-2xl py-4 px-6 flex flex-col">
        <div className="flex items-center justify-between">
          <p className="text-white text-lg font-semibold">Select from library</p>
          <div className="flex flex-1 justify-center items-center gap-3">
            <div className="relative flex gap-3 px-4 py-2 font-[450] rounded-[12px] bg-[#131313] w-5/12 border border-gray-400 border-opacity-5">
              <img className="" src={LensSearch} alt="" />
              <input
                className="text-white placeholder-white placeholder-opacity-20 text-[15px] bg-black bg-opacity-0 w-full focus:outline-none"
                value={searchText}
                placeholder="Search"
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="flex space-x-2">
            <img
              className="cursor-pointer"
              onClick={() => {
                setShowVideoLibraryModal(false);
                setSearchText("");
              }}
              src={close}
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col overflow-y-auto relative mt-10">
          {CachedHoverCard}
          <div
            className={`grid p-3 pt-0 gap-6`}
            style={{ gridTemplateColumns: `repeat(${perViewImages}, minmax(0, 1fr))` }}
          >
            {journalsData?.map((item, index) => (
              <CardDetails
                key={item?._id}
                item={item}
                index={index}
                actualIndex={index}
                setActiveItem={setActiveItem}
              />
            ))}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default JournalLibraryModal;

const CardDetails = ({ item, index, actualIndex, setActiveItem }) => {
  const thumbnail = item?.bg_Images?.find((d) => d?.type_of_url === "1")?.url_link;

  return (
    <div
      className="flex flex-col gap-3 cursor-pointer"
      onMouseEnter={(e) => {
        setActiveItem({ ...item, activeIndex: index, offsetTop: e.currentTarget.offsetTop });
      }}
    >
      <img
        src={thumbnail}
        className="w-full rounded-lg object-cover object-top aspect-video"
        alt="carousel thumbnail"
      />
      {item?.journal_title && (
        <p className="text-white font-bold">
          {item?.journal_title?.length < 20
            ? item?.journal_title
            : item?.journal_title?.substring(0, 20) + ".."}
        </p>
      )}
      {item?.journal_description && (
        <p className="text-white text-opacity-50 text-sm line-clamp-2 overflow-hidden overflow-ellipsis">
          {item?.journal_description}
        </p>
      )}
    </div>
  );
};

const HoverCard = ({
  cardVisible,
  setCardVisible,
  perViewImages,
  activeItem,
  setActiveItem,
  setSearchText,
  setJournalsData,
  allJournalsData,
  handleJournalSelection,
}) => {
  const thumbnail = activeItem?.bg_Images?.find((d) => d?.type_of_url === "1")?.url_link;

  return (
    <div
      className={`absolute w-3/12 z-[1000] flex-col transition-all duration-700 delay-[2000] animate-grow rounded-xl overflow-hidden bg-blackishV2 custom-drop-shadowV1 hidden ${
        cardVisible ? "!block" : ""
      }`}
      onMouseLeave={() => {
        setActiveItem(null);
        setCardVisible(false);
      }}
      style={
        (activeItem?.activeIndex + 1) % perViewImages === 0
          ? {
              right: `${(activeItem?.activeIndex + 1) % perViewImages === 0 ? "1rem" : ""}`,
              top: `${activeItem?.offsetTop}px`,
            }
          : {
              left: `${
                (activeItem?.activeIndex + 1) % perViewImages === 1
                  ? "1rem"
                  : ((activeItem?.activeIndex % perViewImages) * 100) / perViewImages + "%"
              }`,
              top: `${activeItem?.offsetTop}px`,
            }
      }
    >
      <div className={`w-full aspect-video relative`}>
        <img
          key={activeItem?._id}
          src={thumbnail}
          className={`aspect-video object-cover object-top z-10 relative w-full h-full`}
          alt={activeItem?.journal_title}
        />
      </div>
      <div className="py-3 px-3.5">
        <button
          onClick={() => {
            setSearchText("");
            setJournalsData(allJournalsData);
            if (handleJournalSelection) handleJournalSelection(activeItem);
          }}
          className="border-none bg-yellowish text-black px-[12%] lg:py-[10px] md:py-[8px] py-1 rounded-md font-semibold text-sm mb-4 w-full flex items-center justify-center gap-2 transition-all duration-700 hover:scale-[102%]"
        >
          Select Journal
        </button>
        <div className="px-0.5">
          <p className="mt-2 text-white text-sm font-semibold overflow-hidden overflow-ellipsis text-start text-wrap w-[95%]">
            {activeItem?.journal_title}
          </p>
          {activeItem?.journal_description && (
            <p className="mt-2 text-white text-sm text-opacity-60 text-wrap mb-1 line-clamp-[10] overflow-ellipsis overflow-hidden">
              {activeItem?.journal_description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
