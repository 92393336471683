import logo from "../../../../assets/icons/logo.png";
import userImg from "../../../../assets/images/profilepic.png";
import Screenshotallmenu from "../../../../assets/images/Screenshotallmenu.png";
import screenshot1 from "../../../../assets/images/Screenshot 1.png";
import screenshot2 from "../../../../assets/images/Screenshot2.png";
import screenshot3 from "../../../../assets/images/Screenshot3.png";
import screenshot4 from "../../../../assets/images/Screenshot4.png";
import screenshot5 from "../../../../assets/images/Screenshot5.png";
import InputsOfText from "../../../InputFields/InputsOfText";
import { AddLanguagesHooks } from "../../../../screens/Languages/hooks/LanguagesHooks";

const AboutTWGPreview = (fieldValues) => {
  console.log(fieldValues, "teti");
  const { setShowData, showData } = AddLanguagesHooks();

  return (
    <div className="flex flex-col h-full relative bg-zinc-900">
      <div className="bg-zinc-800 absolute  bottom-0 left-0 right-0   rounded-t-xl  z-10 ">
        <img src={Screenshotallmenu} />
      </div>

      <div className="flex flex-col small-bar  overflow-auto pb-14">
        <div className="flex flex-row gap-2 pt-8 px-3 items-center">
          <img className="w-10 " src={logo} />
          <div className="w-24 h-7 bg-slate-50 opacity-40 rounded-3xl"></div>
          <img className="w-8 " src={logo} />
          <img className="w-8 " src={userImg} />
        </div>

        <div className="flex flex-col justify-center   bg-zinc-800 rounded-t-3xl mt-4 px-2 pb-60 pt-5">
          <InputsOfText
            value={fieldValues?.fieldValues?.about_twg_title}
            inputStyle={"text-slate-200 w-60 pl-16 font-bold text-[15px]"}
          />
          <p className="text-white text-[10px] pl-2 font-semibold opacity-60 pt-3">
            {fieldValues?.fieldValues?.about_twg_description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutTWGPreview;
