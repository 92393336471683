import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactModal from "react-modal";
import ReactPlayer from "react-player";

import { readLanguageAction, readVideosAction } from "../../store/Languages/LanguageAction";

import close from "../../assets/icons/close.svg";
import LensSearch from "../../assets/icons/LensSearch.svg";
import { GoMute, GoUnmute } from "react-icons/go";
import { FaPlay } from "react-icons/fa";
import { setActiveLanguage, setLanguagesList } from "../../store/Dashboard/DashboardAction";
import DropdownField from "../InputFields/DropDown";

const VideoLibraryModal = ({
  showVideoLibraryModal,
  setShowVideoLibraryModal,
  handleVideoSelection,
  perViewImages = 5,
}) => {
  const dispatch = useDispatch();

  const { languagesList, activeLanguage } = useSelector((state) => state.language);

  console.log(" languagesList", languagesList);

  const [activeItem, setActiveItem] = useState(null);
  const [cardVisible, setCardVisible] = useState(false);
  const [allVideosData, setAllVideosData] = useState([]);
  const [videosData, setVideosData] = useState([]);
  const [searchText, setSearchText] = useState("");

  console.log("videosDatassss", videosData);

  const handleSearchChange = (e) => {
    const text = e.target.value?.toLowerCase();
    setSearchText(e.target.value);
    if (text === "") {
      setVideosData(allVideosData);
    } else {
      setVideosData(
        allVideosData?.filter((d) => {
          return (
            d?.video_title?.toLowerCase()?.includes(text) ||
            d?.video_description?.toLowerCase()?.includes(text)
          );
        })
      );
    }
  };

  console.log("allVideos45", allVideosData);

  const fetchAllVideos = () => {
    dispatch(
      readVideosAction({
        apiPayloadRequest: {
          language_code: activeLanguage,
        },
        callback: (res) => {
          console.log("res45", res);
          if (res?.type === 1) {
            setAllVideosData(res?.data);
            setVideosData(res?.data);
          }
        },
      })
    );
  };

  const fetchAllLanguages = () => {
    dispatch(
      readLanguageAction({
        callback: (res) => {
          console.log("res46", res);
          if (res?.type === 1) {
            dispatch(setLanguagesList(res?.data));
          }
        },
      })
    );
  };

  useEffect(() => {
    fetchAllVideos();
    fetchAllLanguages();
  }, [activeLanguage]);

  useEffect(() => {
    if (activeItem) {
      setCardVisible(true);
    }

    return () => setCardVisible(false);
  }, [activeItem]);

  const CachedHoverCard = useMemo(
    () => (
      <HoverCard
        cardVisible={cardVisible}
        setCardVisible={setCardVisible}
        perViewImages={perViewImages}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        setSearchText={setSearchText}
        setVideosData={setVideosData}
        allVideosData={allVideosData}
        handleVideoSelection={handleVideoSelection}
      />
    ),
    [
      cardVisible,
      setCardVisible,
      activeItem,
      setActiveItem,
      setSearchText,
      setVideosData,
      perViewImages,
      allVideosData,
      handleVideoSelection,
    ]
  );

  return (
    <ReactModal
      isOpen={showVideoLibraryModal}
      onRequestClose={() => {
        setShowVideoLibraryModal(false);
        setSearchText("");
      }}
      contentLabel="Custom Modal"
      className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
      ariaHideApp={false}
    >
      <div className="MODAL-BODY h-[95%] w-[90%] md:absolute bg-blackishV2 text-white rounded-2xl py-4 px-6 flex flex-col">
        <div className="flex items-center justify-between">
          <p className="text-white text-lg font-semibold">Select from library</p>
          <div className="flex flex-1 justify-center items-center gap-3">
            <div className="relative flex gap-3 px-4 py-2 font-[450] rounded-[12px] bg-[#131313] w-5/12 border border-gray-400 border-opacity-5">
              <img className="" src={LensSearch} alt="" />
              <input
                className="text-white placeholder-white placeholder-opacity-20 text-[15px] bg-black bg-opacity-0 w-full focus:outline-none"
                value={searchText}
                placeholder="Search"
                onChange={handleSearchChange}
              />
            </div>
            <DropdownField
              value={activeLanguage}
              options={languagesList?.map((d) => ({
                value: d?.language_code,
                shortCode: d?.language_code,
                roleName: d?.language_name,
              }))}
              labelText={""}
              width={"w-full !mt-0"}
              handleInputChange={(e) => dispatch(setActiveLanguage(e.target.value))}
            />
          </div>
          <div className="flex space-x-2">
            <img
              className="cursor-pointer"
              onClick={() => {
                setShowVideoLibraryModal(false);
                setSearchText("");
              }}
              src={close}
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col overflow-y-auto relative mt-10">
          {CachedHoverCard}
          <div
            className={`grid p-3 pt-0 gap-6`}
            style={{ gridTemplateColumns: `repeat(${perViewImages}, minmax(0, 1fr))` }}
          >
            {videosData?.map((item, index) => (
              <CardDetails
                key={item?._id}
                item={item}
                index={index}
                actualIndex={index}
                setActiveItem={setActiveItem}
              />
            ))}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default VideoLibraryModal;

const CardDetails = ({ item, index, actualIndex, setActiveItem }) => {
  const thumbnail = item?.tumbnailurls?.find((d) => d?.type_of_url === "1")?.url_link;

  return (
    <div
      className="flex flex-col gap-3 cursor-pointer"
      onMouseEnter={(e) => {
        setActiveItem({ ...item, activeIndex: index, offsetTop: e.currentTarget.offsetTop });
      }}
    >
      <img
        src={thumbnail}
        className="w-full rounded-lg object-cover object-top aspect-video"
        alt="carousel thumbnail"
      />
      {item?.video_title && (
        <p className="text-white font-bold">
          {item?.video_title?.length < 20
            ? item?.video_title
            : item?.video_title?.substring(0, 20) + ".."}
        </p>
      )}
      {item?.video_description && (
        <p className="text-white text-opacity-50 text-sm">
          {item?.video_description?.length < 70
            ? item?.video_description
            : item?.video_description?.substring(0, 70) + ".."}
        </p>
      )}
    </div>
  );
};

const HoverCard = ({
  cardVisible,
  setCardVisible,
  perViewImages,
  activeItem,
  setActiveItem,
  setSearchText,
  setVideosData,
  allVideosData,
  handleVideoSelection,
}) => {
  const videoRef = useRef();
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [videoMuted, setVideoMuted] = useState(true);

  const videoUrl = activeItem?.url?.find((d) => d?.type_of_url === "1")?.url_link;
  const thumbnail = activeItem?.tumbnailurls?.find((d) => d?.type_of_url === "1")?.url_link;

  useEffect(() => {
    let timeout = null;
    if (cardVisible && videoUrl) {
      timeout = setTimeout(() => {
        setVideoPlayed(true);
        // videoRef.current.play();
      }, 2500);
    }

    return () => {
      clearTimeout(timeout);
      setVideoPlayed(false);
    };
  }, [cardVisible]);

  return (
    <div
      className={`absolute w-3/12 z-[1000] flex-col transition-all duration-700 delay-[2000] animate-grow rounded-xl overflow-hidden bg-blackishV2 custom-drop-shadowV1 hidden ${
        cardVisible ? "!block" : ""
      }`}
      onMouseLeave={() => {
        setActiveItem(null);
        setCardVisible(false);
      }}
      style={
        (activeItem?.activeIndex + 1) % perViewImages === 0
          ? {
              right: `${(activeItem?.activeIndex + 1) % perViewImages === 0 ? "1rem" : ""}`,
              top: `${activeItem?.offsetTop}px`,
            }
          : {
              left: `${
                (activeItem?.activeIndex + 1) % perViewImages === 1
                  ? "1rem"
                  : ((activeItem?.activeIndex % perViewImages) * 100) / perViewImages + "%"
              }`,
              top: `${activeItem?.offsetTop}px`,
            }
      }
    >
      <div className={`w-full aspect-video relative`}>
        {!videoPlayed && (
          <img
            key={activeItem?._id}
            src={thumbnail}
            className={`aspect-video object-cover object-top z-10 relative`}
            alt={activeItem?.video_title}
          />
        )}
        <ReactPlayer
          ref={videoRef}
          url={videoUrl}
          className={`custom-player absolute top-0 left-0 aspect-video bg-blackishV2 object-cover object-top`}
          width={"100%"}
          height={"100%"}
          muted={videoMuted}
          playing={videoPlayed}
        />
        <button
          className={`bg-transparent border-none absolute text-xl text-white z-50 right-4 bottom-4 ${
            videoPlayed ? "" : "hidden"
          }`}
          onClick={() => {
            if (videoRef.current) {
              setVideoMuted((prev) => !prev);
            }
          }}
        >
          {videoMuted ? <GoMute /> : <GoUnmute />}
        </button>
        <div className="absolute w-full h-[103%] top-0 left-0 bg-gradient-to-t from-blackishV2 z-20 to-black/0 from-5% to-20%"></div>
      </div>
      <div className="py-3 px-3.5">
        <button
          onClick={() => {
            setSearchText("");
            setVideosData(allVideosData);
            if (handleVideoSelection) handleVideoSelection(activeItem);
          }}
          className="border-none bg-yellowish text-black px-[12%] lg:py-[10px] md:py-[8px] py-1 rounded-md font-semibold text-sm mb-4 w-full flex items-center justify-center gap-2 transition-all duration-700 hover:scale-[102%]"
        >
          {activeItem?.type === "videos" && <FaPlay />}
          Select Video
        </button>
        <div className="px-0.5">
          <p className="mt-2 text-white text-sm font-semibold overflow-hidden overflow-ellipsis text-start text-wrap w-[95%]">
            {activeItem?.video_title}
          </p>
          {activeItem?.video_description && (
            <p className="mt-2 text-white text-sm text-opacity-60 text-wrap mb-1">
              {activeItem?.video_description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
