import { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";

import CustomTable from "../../components/universal/CustomTable";
import Navbar from "../../components/universal/Navbar";
import SearchBar from "../../components/universal/SearchBar";
import RatingStars from "../../components/universal/RatingStars";

import NotificationsListHooks from "../NotificationList/hooks/NotificationsListHooks";
import {
  deleteTestimonyCategoriesAction,
  readTestimonyCategoriesAction,
  saveTestimonyCategoriesAction,
  updateTestimonyCategoriesAction,
} from "../../store/Testimony/TestimonyAction";
import { testimonyCategoryHeaders } from "../NotificationList/Constants";

import close from "../../assets/icons/close.svg";
import { AiOutlinePlus } from "react-icons/ai";
import ConfirmToast from "../../components/universal/ConfirmToast";
import { confirmAlert } from "react-confirm-alert";
import Toastiy from "../../components/universal/Toastiy";

const TestimonyCategory = () => {
  const dispatch = useDispatch();
  const { value, onChangeSearchBar } = NotificationsListHooks();
  const [reloadData, setReloadData] = useState(false);
  const [showAddTestimonyCategoryModal, setShowAddTestimonyCategoryModal] = useState(false);
  const [currentTestimonyCategory, setCurrentTestimonyCategory] = useState({});

  console.log("currentTestimonyCategory", currentTestimonyCategory);

  const fetchAllFeedbacks = (page, limit, searchCriteria = {}, callbackFn) => {
    dispatch(
      readTestimonyCategoriesAction({
        apiPayloadRequest: {
          page: page,
          limit: limit,
          searchCriteria: searchCriteria,
        },
        callback: (Data) => {
          if (Data?.type === 1) {
            if (callbackFn)
              callbackFn({
                data: Data?.data?.map((d) => ({ ...d, views: d?.views ?? 0 })),
                total: Data?.totalRecords ?? 0,
              });
          } else if (callbackFn) callbackFn({ data: [], total: Data?.totalRecords ?? 0 });
        },
      })
    );
  };

  const handleDelete = (row, index) => {
    dispatch(
      deleteTestimonyCategoriesAction({
        apiPayloadRequest: { _id: row?._id },
        callback: (res) => {
          Toastiy("Testimony Category Deleted", 1);
          setReloadData(true);
        },
      })
    );
  };

  const handleSave = () => {
    if (currentTestimonyCategory?._id) {
      dispatch(
        updateTestimonyCategoriesAction({
          apiPayloadRequest: {
            _id: currentTestimonyCategory?._id,
            categoryName: currentTestimonyCategory?.categoryName,
          },
          callback: (res) => {
            Toastiy("Testimony Updated Added Successfully!!", 1);
            setReloadData(true);
            setShowAddTestimonyCategoryModal(false);
            setCurrentTestimonyCategory({});
          },
        })
      );
    } else {
      dispatch(
        saveTestimonyCategoriesAction({
          apiPayloadRequest: { categoryName: currentTestimonyCategory?.categoryName },
          callback: (res) => {
            Toastiy("Testimony Category Added Successfully!!", 1);
            setReloadData(true);
            setShowAddTestimonyCategoryModal(false);
            setCurrentTestimonyCategory({});
          },
        })
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentTestimonyCategory((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <div className=" text-white text-3xl font-medium flex w-full justify-between">
                Testimony Category List{" "}
                <button
                  onClick={() => setShowAddTestimonyCategoryModal(true)}
                  className="flex items-center gap-2 h-fit text-[13px] text-yellowish border px-4 py-0 border-yellowish bg-blackish rounded-xl"
                >
                  <AiOutlinePlus /> Add Testimony Category
                </button>
              </div>
            </div>

            <div>
              {/* custom table with different data as per the selected carousel  */}
              <CustomTable
                width={"w-24"}
                // giving extra width to 2nd column
                nthChildWidth={
                  "[&>*:nth-child(1)]:w-40 [&>*:nth-child(2)]:w-40 [&>*:nth-child(3)]:w-48"
                }
                nthChildColor={
                  "[&>*:nth-child(2)]:text-greyish [&>*:nth-child(3)]:text-greyish [&>*:nth-child(4)]:text-greyish [&>*:nth-child(5)]:text-greyish"
                }
                nthChildStyle={"[&>*:nth-child(3)]:h-5 [&>*:nth-child(3)]:overflow-hidden"}
                data={[]}
                headers={testimonyCategoryHeaders}
                customDataFetch={true}
                customDataFetchFunction={fetchAllFeedbacks}
                reloadData={reloadData}
                setReloadData={setReloadData}
                reverseTable={true}
                handleEditClick={(row) => {
                  setShowAddTestimonyCategoryModal(true);
                  setCurrentTestimonyCategory(row);
                }}
                handleDeleteClick={(row) => {
                  const options = {
                    customUI: ({ onClose }) => {
                      return (
                        <ConfirmToast
                          title="Are you sure?"
                          message="You want to delete this testimony category?"
                          onClose={() => onClose()}
                          onSuccess={() => {
                            handleDelete(row);
                            onClose();
                          }}
                        />
                      );
                    },
                    closeOnEscape: true,
                    closeOnClickOutside: true,
                    keyCodeForClose: [8, 32],
                    overlayClassName: "!bg-gray-500 !bg-opacity-75",
                  };

                  confirmAlert(options);
                }}
                heightDesktop={"h-[72vh]"}
              />

              <Modal
                isOpen={showAddTestimonyCategoryModal}
                onRequestClose={() => {
                  setShowAddTestimonyCategoryModal((prev) => !prev);
                }}
                contentLabel="Custom Modal"
                className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
                ariaHideApp={false}
              >
                <div className="MODAL-BODY h-fit md:w-[40%] w-[90%] md:absolute rounded-2xl p-4 bg-blackishV2 ">
                  <div className="w-full h-full flex flex-col">
                    <div className="flex items-center justify-between mb-4">
                      <p className="text-white text-lg font-semibold">
                        {currentTestimonyCategory?._id ? "Update" : "Add"} Testimony Category
                      </p>
                      <div className="flex">
                        <div className="flex space-x-2">
                          <img
                            className="cursor-pointer"
                            onClick={() => {
                              setShowAddTestimonyCategoryModal((prev) => !prev);
                            }}
                            src={close}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bg-greyishV2 flex-1 overflow-y-auto p-4 flex flex-col justify-between">
                      <div>
                        <div className="flex flex-col">
                          <label
                            htmlFor="topicTitle"
                            className="text-[12px] text-greyish font-medium leading-4 "
                          >
                            Category Name
                          </label>
                          <input
                            name="categoryName"
                            className={`bg-blackish p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 placeholder:text-greyish `}
                            placeholder="Enter Category Name"
                            value={currentTestimonyCategory?.categoryName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="py-1 w-full mt-14">
                        <div className="flex justify-end gap-5">
                          <button
                            className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                            onClick={() => {
                              setShowAddTestimonyCategoryModal((prev) => !prev);
                            }}
                          >
                            Close
                          </button>
                          <button
                            className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                            onClick={handleSave}
                          >
                            {currentTestimonyCategory?._id ? "Update" : "Save"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonyCategory;
