import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addJournalNoteAction,
  addJournalNoteItemAction,
  deleteJournalNoteAction,
  deleteJournalNoteItemAction,
  readJournalAction,
  readLanguageAction,
  saveBGJournalAction,
  saveCurriculumLanguageLessonThumbNailAction,
  saveCurriculumLessonLanguageAction,
  saveCurriculumLessonPreviewLanguageAction,
  saveJournalAction,
  saveJournalBannerAction,
  translateLanguageAction,
  updateBGJournalAction,
  updateCurriculumLanguageLessonThumbNailAction,
  updateCurriculumLessonLanguageAction,
  updateCurriculumLessonPreviewLanguageAction,
  updateJournalAction,
  updateJournalBannerAction,
  updateJournalNoteItemAction,
  uploadAssestsAction,
  uploadAssestsV2Action,
} from "../../../store/Languages/LanguageAction";
import Toastiy from "../../../components/universal/Toastiy";
import { useNavigate } from "react-router-dom";

import { setShowNavigationAlertAction } from "../../../store/GlobalLoader/GlobalLoaderAction";
import { sendCustomNotificationAction } from "../../../store/Notifications/NotificationAction";
import { JOURNAL_NOTE_ITEM_TYPES } from "../../../constants/Common";

export const JournalsHookNew = (setLoading, journal_id, setJournalId) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [forceEditorUpdate, setForceEditorUpdate] = useState(false);
  const [journalStats, setJournalStats] = useState({});
  const [journalPublishLoading, setJournalPublishLoading] = useState(false);

  useEffect(() => {
    return () => setShowNavigationAlert(false);
  }, []);

  const setShowNavigationAlert = (value) => {
    dispatch(setShowNavigationAlertAction(value));
  };

  //notes delete
  const handleDeleteNotes = ({ noteObjId, notesIndex }) => {
    console.log("noteObjId , notesIndex", noteObjId, notesIndex);
    dispatch(
      deleteJournalNoteAction({
        apiPayloadRequest: {
          _id: journal_id,
          noteObjId: noteObjId,
        },
        callback: (res) => {
          // if (res?.type === 1) {
          const updatedCurriculumValues = structuredClone(journalValues);
          updatedCurriculumValues?.notes?.splice(notesIndex, 1);
          setJournalValues(updatedCurriculumValues);
          setShowNotesBox(false);
          setCurrNotes(initialNotesState);
          Toastiy("Notes Deleted Successfully!", 1);
          // } else {
          //   Toastiy("Something went wrong, please try again");
          // }
        },
      })
    );
  };

  //lesson trailer video library selection
  const handleNotesLibraryVideoSelection = (details) => {
    const updatedJournals = structuredClone(journalValues);
    updatedJournals.notes[currNotesIndex].lesson_trailers[0].bg_asset_url = [
      {
        type_of_url: "0",
        url_link: details?.urls?.find((d) => d?.type_of_url === "0")?.url_link,
      },
      {
        type_of_url: "1",
        url_link: details?.urls?.find((d) => d?.type_of_url === "1")?.url_link,
      },
      {
        type_of_url: "2",
        url_link: details?.urls?.find((d) => d?.type_of_url === "2")?.url_link,
      },
    ];
    setJournalValues(updatedJournals);
    setShowNavigationAlert(true);
  };

  const inititialUrlState = [
    {
      type_of_url: "0",
      url_link: "",
    },
    {
      type_of_url: "1",
      url_link: "",
    },
    {
      type_of_url: "2",
      url_link: "",
    },
  ];

  const initialNotesState = { text: "", noteitems: [] };

  const initialJournalState = {
    journal_title: "",
    journal_description: "",
    bg_Images: [
      {
        type_of_url: "0",
        url_link: "",
      },
      {
        type_of_url: "1",
        url_link: "",
      },
      {
        type_of_url: "2",
        url_link: "",
      },
    ],
    banner_Images: [
      {
        type_of_url: "0",
        url_link: "",
      },
      {
        type_of_url: "1",
        url_link: "",
      },
      {
        type_of_url: "2",
        url_link: "",
      },
    ],
    notes: [],
  };

  const initialJournalErrors = {
    journal_title: true,
    journal_description: true,
    bg_Images: [
      {
        type_of_url: "0",
        url_link: true,
      },
      {
        type_of_url: "1",
        url_link: true,
      },
      {
        type_of_url: "2",
        url_link: true,
      },
    ],
    banner_Images: [
      {
        type_of_url: "0",
        url_link: true,
      },
      {
        type_of_url: "1",
        url_link: true,
      },
      {
        type_of_url: "2",
        url_link: true,
      },
    ],
  };

  const [languagemeta, setLanguagemeta] = useState();
  const [isLanguageSave, setIsLanguageSave] = useState(true);
  const [readLanguageData, setReadLanguageData] = useState([]);
  const [activeTab, setActiveTab] = useState("en");
  const [activeLanguageDetails, setActiveLanguageDetails] = useState({
    language_code: "en",
    language_name: "English",
  });
  const [journalValues, setJournalValues] = useState(initialJournalState);
  const [currJournalValues, setCurrJournalValues] = useState(initialJournalState);
  const [journalsErrors, setJournalsErrors] = useState(initialJournalErrors);

  const [showJournalsErrors, setShowJournalsErrors] = useState(false);
  const [showNotesErrors, setShowNotesErrors] = useState(false);

  const [showJournalBox, setShowJournalBox] = useState(false);
  const [showNotesBox, setShowNotesBox] = useState(false);

  const [currNotes, setCurrNotes] = useState(initialNotesState);

  const [currNotesIndex, setCurrNotesIndex] = useState(0);

  const [isNotesEdit, setIsNotesEdit] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  const keysArray = Object.keys(inititialUrlState);

  console.log("journalValues", journalValues);
  console.log("currJournalValues", currJournalValues);

  const addNewNotes = () => {
    setShowNotesBox(true);
  };

  const cancelNotes = () => {
    setShowNotesErrors(false);
    if (!isNotesEdit) {
      const updatedNotes = [...journalValues.notes.filter((_, idx) => idx !== currNotesIndex)];
      setJournalValues((prev) => ({
        ...prev,
        notes: [...updatedNotes],
      }));
    } else {
      const prevNotes = [...journalValues.notes];

      prevNotes[currNotesIndex] = currNotes;

      setJournalValues((prev) => ({
        ...prev,
        notes: prevNotes,
      }));
    }
  };

  const cancelJournal = () => {
    setShowJournalBox(false);
  };

  const saveNotes = () => {
    setShowNotesErrors(false);
    const updatedCurrNotes = structuredClone(currNotes);
    if (updatedCurrNotes?.text?.length > 0) {
      updatedCurrNotes.noteitems = [
        ...(updatedCurrNotes?.noteitems ?? []),
        { notes: updatedCurrNotes?.text, type_of_notes: JOURNAL_NOTE_ITEM_TYPES.TEXT },
      ];
    }
    updatedCurrNotes.lastUpdated = new Date().toISOString();
    if (!isNotesEdit) {
      if (journal_id !== undefined) {
        const promiseArray = [];
        promiseArray.push(
          new Promise((resolve, reject) => {
            dispatch(
              addJournalNoteAction({
                apiPayloadRequest: {
                  _id: journal_id,
                  notesTitle: " ",
                },
                callback: (response) => {
                  resolve();
                  if (response?.type === 1) {
                    Toastiy("Notes Draft Saved Successfully!", 1);
                    currNotes?.noteitems?.map((item) => {
                      promiseArray.push(
                        new Promise((res, rej) => {
                          dispatch(
                            addJournalNoteItemAction({
                              apiPayloadRequest: {
                                _id: journal_id,
                                noteObjId: response?.noteObjId,
                                type_of_notes: item?.type_of_notes,
                                url_link: item?.url_link ?? " ",
                                notes: item?.notes ?? "",
                              },
                              callback: (resp) => {
                                res();
                              },
                            })
                          );
                        })
                      );
                    });

                    setShowNotesBox(false);
                    setCurrNotesIndex(journalValues?.notes?.length);
                  } else {
                    Toastiy("Failed to Save Notes");
                  }
                },
              })
            );
          })
        );

        Promise.allSettled(promiseArray).then(() => {
          getJournalDetails();
        });
      } else {
        setJournalValues((prev) => ({
          ...prev,
          notes: [...(prev.notes ?? []), currNotes],
        }));
        setShowNotesBox(false);
        setCurrNotesIndex(journalValues?.notes?.length);
        setShowNavigationAlert(true);
      }
    } else {
      if (journal_id !== undefined) {
        const promiseArray = [];
        currNotes?.noteitems?.map((item) => {
          promiseArray.push(
            new Promise((res, rej) => {
              if (item?.notesItemObjId) {
                dispatch(
                  updateJournalNoteItemAction({
                    apiPayloadRequest: {
                      _id: journal_id,
                      noteObjId: currNotes?.noteObjId,
                      type_of_notes: item?.type_of_notes,
                      url_link: item?.url_link ?? " ",
                      notes: item?.notes ?? "",
                    },
                    callback: (resp) => {
                      res();
                    },
                  })
                );
              } else {
                dispatch(
                  addJournalNoteItemAction({
                    apiPayloadRequest: {
                      _id: journal_id,
                      noteObjId: currNotes?.noteObjId,
                      type_of_notes: item?.type_of_notes,
                      url_link: item?.url_link ?? " ",
                      notes: item?.notes ?? "",
                    },
                    callback: (resp) => {
                      res();
                    },
                  })
                );
              }
            })
          );
        });

        Promise.allSettled(promiseArray).then(() => {
          getJournalDetails();
        });

        setShowNotesBox(false);
      } else {
        setShowNotesBox(false);
        setShowNavigationAlert(true);
        setCurrNotesIndex(journalValues?.notes?.length);
      }
    }
  };

  const saveLessonLanguage = async (selectedLessonIndex, refetchDetails = true, callback) => {
    setShowNotesErrors(false);
    const actualLessonIndex = selectedLessonIndex ?? currNotesIndex;
    const data = languagemeta?.filter((item) => item?.language_code === activeTab)[0];
    const requestsList = [];
    const lessonLangIndex = data?.lessons?.findIndex(
      (d) => d?.lessonObjId === journalValues?.lessons[actualLessonIndex]?.lessonObjId
    );
    if (!data?.lessons?.[lessonLangIndex]) {
      requestsList.push(
        new Promise((resolve, reject) => {
          dispatch(
            saveCurriculumLessonLanguageAction({
              apiPayloadRequest: {
                _id: journal_id,
                language_code: activeTab,
                lessonObjId: journalValues?.lessons[actualLessonIndex]?.lessonObjId,
                lesson_title: journalValues?.lessons[actualLessonIndex]?.lesson_title,
                lesson_description: journalValues?.lessons[actualLessonIndex]?.lesson_description,
                sortOrder: journalValues?.lessons[actualLessonIndex]?.sortOrder,
                lessonPdfUrl: journalValues?.lessons[actualLessonIndex]?.lessonPdfUrl,
              },
              callback: (res) => {
                if (res?.type === 1) {
                  Toastiy("Lesson Draft Updated Successfully!", 1);
                  keysArray.map((item) => {
                    if (
                      journalValues?.lessons[actualLessonIndex]?.lesson_tumbnail_urls?.[item]
                        ?.url_link
                    ) {
                      requestsList.push(
                        new Promise((resolve, reject) => {
                          dispatch(
                            saveCurriculumLanguageLessonThumbNailAction({
                              apiPayloadRequest: {
                                _id: journal_id,
                                language_code: activeTab,
                                lessonObjId: journalValues?.lessons[actualLessonIndex]?.lessonObjId,
                                type_of_url: parseInt(
                                  journalValues?.lessons?.[actualLessonIndex]
                                    ?.lesson_tumbnail_urls?.[item]?.type_of_url
                                ),

                                url_link:
                                  journalValues?.lessons?.[actualLessonIndex]
                                    ?.lesson_tumbnail_urls?.[item]?.url_link,
                              },
                              callback: (res) => {
                                if (res?.type == 1) {
                                  Toastiy("Lesson Thumbnail Saved!", 1);
                                  setShowNavigationAlert(false);
                                } else {
                                  Toastiy("Failed to Save Lesson Thumbnail");
                                }
                                resolve();
                              },
                            })
                          );
                        })
                      );
                    }
                  });

                  keysArray.map((item) => {
                    if (
                      journalValues?.lessons[actualLessonIndex]?.lesson_trailers?.[item]?.url_link
                    ) {
                      requestsList.push(
                        new Promise((resolve, reject) => {
                          dispatch(
                            saveCurriculumLessonPreviewLanguageAction({
                              apiPayloadRequest: {
                                _id: journal_id,
                                language_code: activeTab,
                                lessonObjId: journalValues?.lessons[actualLessonIndex]?.lessonObjId,
                                type_of_url: parseInt(
                                  journalValues?.lessons?.[actualLessonIndex]?.lesson_trailers?.[
                                    item
                                  ]?.type_of_url
                                ),

                                url_link:
                                  journalValues?.lessons?.[actualLessonIndex]?.lesson_trailers[item]
                                    .url_link,
                              },
                              callback: (res) => {
                                if (res?.type == 1) {
                                  Toastiy("Lesson Preview Saved!", 1);
                                  setShowNavigationAlert(false);
                                } else {
                                  Toastiy("Failed to Save Lesson Preview");
                                }
                                resolve();
                              },
                            })
                          );
                        })
                      );
                    }
                  });
                }
                resolve();
              },
            })
          );
        })
      );
    } else {
      if (
        journalValues?.lessons?.[actualLessonIndex]?.lesson_title !==
          data?.lessons?.[lessonLangIndex]?.lesson_title ||
        journalValues?.lessons?.[actualLessonIndex]?.lesson_description !==
          data?.lessons?.[lessonLangIndex]?.lesson_description ||
        journalValues?.lessons?.[actualLessonIndex]?.sortOrder !==
          data?.lessons?.[lessonLangIndex]?.sortOrder ||
        journalValues?.lessons?.[actualLessonIndex]?.lessonPdfUrl !==
          data?.lessons?.[lessonLangIndex]?.lessonPdfUrl
      ) {
        requestsList.push(
          new Promise((resolve, reject) => {
            dispatch(
              updateCurriculumLessonLanguageAction({
                apiPayloadRequest: {
                  _id: journal_id,
                  language_code: activeTab,
                  lesson_title: journalValues?.lessons?.[actualLessonIndex]?.lesson_title,
                  lessonObjId: journalValues?.lessons?.[actualLessonIndex]?.lessonObjId,
                  lesson_description:
                    journalValues?.lessons?.[actualLessonIndex]?.lesson_description,
                  sortOrder: journalValues?.lessons?.[actualLessonIndex]?.sortOrder,
                  lessonPdfUrl: journalValues?.lessons?.[actualLessonIndex]?.lessonPdfUrl,
                },
                callback: (res) => {
                  if (res?.type === 1) {
                    Toastiy("Lesson Draft Updated Successfully!", 1);
                    setCurrNotesIndex(journalValues?.lessons?.length);
                    setShowNavigationAlert(false);
                  }
                  resolve();
                },
              })
            );
          })
        );
      }

      keysArray.map((item) => {
        if (
          (!data?.lessons?.[lessonLangIndex]?.lesson_tumbnail_urls?.[item]?.url_link ||
            data?.lessons?.[lessonLangIndex]?.lesson_tumbnail_urls?.length === 0) &&
          journalValues?.lessons?.[actualLessonIndex]?.lesson_tumbnail_urls?.[item]?.url_link
        ) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                saveCurriculumLanguageLessonThumbNailAction({
                  apiPayloadRequest: {
                    _id: journal_id,
                    language_code: activeTab,
                    lessonObjId: journalValues?.lessons?.[actualLessonIndex]?.lessonObjId,
                    type_of_url: parseInt(
                      journalValues?.lessons[actualLessonIndex]?.lesson_tumbnail_urls?.[item]
                        ?.type_of_url
                    ),
                    url_link:
                      journalValues?.lessons?.[actualLessonIndex]?.lesson_tumbnail_urls?.[item]
                        ?.url_link,
                  },
                  callback: (res) => {
                    if (res?.type == 1) {
                      Toastiy("Lesson Thumbnail Updated!", 1);
                      setShowNavigationAlert(false);
                    } else {
                      Toastiy("Failed to Update Lesson Thumbnail");
                    }
                    resolve();
                  },
                })
              );
            })
          );
        } else if (
          journalValues?.lessons?.[actualLessonIndex]?.lesson_tumbnail_urls?.[item]?.url_link !==
            data?.lessons?.[lessonLangIndex]?.lesson_tumbnail_urls?.[item]?.url_link &&
          journalValues?.lessons?.[actualLessonIndex]?.lesson_tumbnail_urls?.[item]?.url_link
        ) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                updateCurriculumLanguageLessonThumbNailAction({
                  apiPayloadRequest: {
                    _id: journal_id,
                    language_code: activeTab,
                    lessonObjId: journalValues?.lessons[actualLessonIndex]?.lessonObjId,
                    type_of_url: parseInt(
                      journalValues?.lessons[actualLessonIndex]?.lesson_tumbnail_urls?.[item]
                        ?.type_of_url
                    ),
                    url_link:
                      journalValues?.lessons[actualLessonIndex]?.lesson_tumbnail_urls?.[item]
                        .url_link,
                  },
                  callback: (res) => {
                    if (res?.type == 1) {
                      Toastiy("Lesson Thumbnail Updated!", 1);
                      setShowNavigationAlert(false);
                    } else {
                      Toastiy("Failed to Update Lesson Thumbnail");
                    }
                    resolve();
                  },
                })
              );
            })
          );
        }
      });

      keysArray.map((item) => {
        if (
          (!data?.lessons?.[lessonLangIndex]?.lesson_trailers?.[item]?.url_link ||
            data?.lessons?.[lessonLangIndex]?.lesson_trailers?.length === 0) &&
          journalValues?.lessons[actualLessonIndex]?.lesson_trailers?.[item]?.url_link
        ) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                saveCurriculumLessonPreviewLanguageAction({
                  apiPayloadRequest: {
                    _id: journal_id,
                    language_code: activeTab,
                    lessonObjId: journalValues?.lessons?.[actualLessonIndex]?.lessonObjId,
                    type_of_url: parseInt(
                      journalValues?.lessons[actualLessonIndex]?.lesson_trailers?.[item]
                        ?.type_of_url
                    ),

                    url_link:
                      journalValues?.lessons?.[actualLessonIndex]?.lesson_trailers[item].url_link,
                  },
                  callback: (res) => {
                    if (res?.type == 1) {
                      Toastiy("Lesson Preview Saved!", 1);
                      setShowNavigationAlert(false);
                    } else {
                      Toastiy("Failed to Save Lesson Preview");
                    }
                    resolve();
                  },
                })
              );
            })
          );
        } else if (
          data?.lessons?.[lessonLangIndex]?.lesson_trailers?.[item]?.url_link !==
            journalValues?.lessons?.[actualLessonIndex]?.lesson_trailers?.[item]?.url_link &&
          journalValues?.lessons?.[actualLessonIndex]?.lesson_trailers?.[item]?.url_link
        ) {
          requestsList.push(
            new Promise((resolve, reject) => {
              dispatch(
                updateCurriculumLessonPreviewLanguageAction({
                  apiPayloadRequest: {
                    _id: journal_id,
                    language_code: activeTab,
                    lessonObjId: journalValues?.lessons[actualLessonIndex]?.lessonObjId,
                    type_of_url: parseInt(
                      journalValues?.lessons[actualLessonIndex]?.lesson_trailers[item]?.type_of_url
                    ),
                    url_link:
                      journalValues?.lessons[actualLessonIndex]?.lesson_trailers[item].url_link,
                  },
                  callback: (res) => {
                    if (res?.type == 1) {
                      Toastiy("Lesson Preview Updated!", 1);
                      setShowNavigationAlert(false);
                    } else {
                      Toastiy("Failed to Update Lesson Preview");
                    }
                    resolve();
                  },
                })
              );
            })
          );
        }
      });
    }

    await Promise.all(requestsList);
    setShowNotesBox(false);
    setCurrNotesIndex(journalValues.lessons.length);
    if (refetchDetails) getJournalDetails();
    if (callback) callback();
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const handleOpenPreview = () => {
    setShowPreview(true);
  };

  //HandleChange
  const handleChange = (e, category, urlIndex, notesIndex, slideIndex, uploadProgressId = null) => {
    const { name, value, files } = e.target;

    setForceEditorUpdate(false);

    const updatedJournals = structuredClone(journalValues);
    const updatedJournalsErrors = { ...journalsErrors };

    switch (category) {
      case "journal":
        if (name === "bg_Images" || name === "banner_Images") {
          if (!updatedJournals[name] || updatedJournals[name]?.length === 0) {
            updatedJournals[name] = initialJournalState[name];
          }
          updatedJournals[name][urlIndex].url_link = value;
          if (name === "bg_Images" || name === "banner_Images")
            updatedJournalsErrors[name][urlIndex].url_link = value.length > 0 ? false : true;
        } else {
          updatedJournals[name] = value;
          updatedJournalsErrors[name] = value.length > 0 ? false : true;
        }
        break;
      case "journalFile":
        if ((name === "bg_Images" || name === "banner_Images") && files.length > 0) {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);

          dispatch(
            uploadAssestsV2Action({
              apiPayloadRequest: formData,
              uploadProgressId: uploadProgressId,
              callback: (res) => {
                if (res && res.url) {
                  if (!updatedJournals[name] || updatedJournals[name]?.length === 0) {
                    updatedJournals[name] = initialJournalState[name];
                  }
                  updatedJournals[name][urlIndex].url_link = res.url;
                  updatedJournalsErrors[name][urlIndex].url_link =
                    res.url.length > 0 ? false : true;

                  setJournalValues(updatedJournals);
                  setJournalsErrors(updatedJournalsErrors);
                }
                setLoading(false);
              },
            })
          );
        }
        break;
      case "notes":
        if (name === "lesson_tumbnail_urls") {
          if (
            !updatedJournals?.notes?.[notesIndex]?.lesson_tumbnail_urls ||
            updatedJournals?.notes?.[notesIndex]?.lesson_tumbnail_urls?.length === 0
          ) {
            updatedJournals.notes[notesIndex]["lesson_tumbnail_urls"] =
              initialNotesState?.lesson_tumbnail_urls;
          }
          updatedJournals.notes[notesIndex][name][urlIndex].url_link = value;
        } else {
          updatedJournals.notes[notesIndex][name] = value;
        }
        break;
      case "notesFile":
        if (
          name === "notes_video_urls" ||
          name === "notes_audio_urls" ||
          name === "notes_image_urls"
        ) {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", files[0]);
          dispatch(
            uploadAssestsV2Action({
              apiPayloadRequest: formData,
              uploadProgressId: uploadProgressId,
              callback: (res) => {
                if (res && res.url) {
                  if (updatedJournals.notes?.[notesIndex]?.[name]?.length === 0) {
                    updatedJournals.notes[notesIndex][name] = initialNotesState[name];
                  }
                  updatedJournals.notes[notesIndex][name] = { url_link: res.url };

                  setJournalValues(updatedJournals);
                }
                setLoading(false);
              },
            })
          );
        }
        break;
      default:
        break;
    }

    setJournalsErrors(updatedJournalsErrors);
    console.log("updatedJournals", updatedJournals);
    console.log("updatedJournalsErrors", updatedJournalsErrors);
    setJournalValues(updatedJournals);
    setShowNavigationAlert(true);
  };

  //*********************************************//
  const saveJournals = () => {
    setShowJournalsErrors(false);
    if (journal_id !== undefined) {
      if (
        journalValues?.journal_title !== currJournalValues?.journal_title ||
        journalValues?.journal_description !== currJournalValues?.journal_description
      ) {
        setLoading(true);
        dispatch(
          updateJournalAction({
            apiPayloadRequest: {
              _id: journal_id,
              journal_title: journalValues?.journal_title,
              journal_description: journalValues?.journal_description,
            },
            callback: (res) => {
              Toastiy("Journal Draft Updated Successfully!", 1);
              setCurrJournalValues((prev) => ({
                ...prev,
                _id: journal_id,
                journal_title: journalValues?.journal_title,
                journal_description: journalValues?.journal_description,
              }));
              setLoading(false);
              setShowJournalBox(false);
              setShowNavigationAlert(false);
            },
          })
        );
      }

      journalValues?.bg_Images?.map((item, index) => {
        if (
          journalValues?.bg_Images?.[index]?.url_link &&
          !currJournalValues?.bg_Images?.[index]?.url_link
        ) {
          setTimeout(() => {
            dispatch(
              saveBGJournalAction({
                apiPayloadRequest: {
                  _id: journal_id,
                  type_of_url: parseInt(journalValues?.bg_Images?.[index]?.type_of_url),
                  url_link: journalValues?.bg_Images?.[index]?.url_link,
                },
                callback: (res) => {
                  // if (res?.type === 1) {
                  Toastiy("Journal BG Updated Successfully!", 1);
                  setCurrJournalValues((prev) => ({
                    ...prev,
                    bg_Images: (prev?.bg_Images ?? []).map((item, i) => ({
                      type_of_url: journalValues?.bg_Images?.[i]?.type_of_url,
                      url_link: journalValues?.bg_Images?.[i]?.url_link,
                    })),
                  }));
                  setShowNavigationAlert(false);
                  // } else {
                  //   Toastiy("Failed to Save Journal BG");
                  // }
                },
              })
            );
          }, index * 500);
        } else if (
          journalValues?.bg_Images?.[index]?.url_link &&
          journalValues?.bg_Images?.[index]?.url_link !==
            currJournalValues?.bg_Images?.[index]?.url_link
        ) {
          setTimeout(() => {
            dispatch(
              updateBGJournalAction({
                apiPayloadRequest: {
                  _id: journal_id,
                  type_of_url: parseInt(journalValues?.bg_Images?.[index]?.type_of_url),
                  url_link: journalValues?.bg_Images?.[index]?.url_link,
                },
                callback: (res) => {
                  // if (res?.type === 1) {
                  Toastiy("Journal BG Updated Successfully!", 1);
                  setCurrJournalValues((prev) => ({
                    ...prev,
                    bg_Images: (prev?.bg_Images ?? []).map((item, i) => ({
                      type_of_url: journalValues?.bg_Images?.[i]?.type_of_url,
                      url_link: journalValues?.bg_Images?.[i]?.url_link,
                    })),
                  }));
                  setShowNavigationAlert(false);
                  // } else {
                  //   Toastiy("Failed to Save Journal BG");
                  // }
                },
              })
            );
          }, index * 500);
        }
      });
      journalValues?.banner_Images?.map((item, index) => {
        if (
          journalValues?.banner_Images?.[index]?.url_link &&
          !currJournalValues?.banner_Images?.[index]?.url_link
        ) {
          setTimeout(() => {
            dispatch(
              saveJournalBannerAction({
                apiPayloadRequest: {
                  _id: journal_id,
                  type_of_url: parseInt(journalValues?.banner_Images?.[index]?.type_of_url),
                  url_link: journalValues?.banner_Images?.[index]?.url_link,
                },
                callback: (res) => {
                  // if (res?.type === 1) {
                  Toastiy("Journal Banner Updated Successfully!", 1);
                  setCurrJournalValues((prev) => ({
                    ...prev,
                    banner_Images: (prev?.banner_Images ?? []).map((item, i) => ({
                      type_of_url: journalValues?.banner_Images?.[i]?.type_of_url,
                      url_link: journalValues?.banner_Images?.[i]?.url_link,
                    })),
                  }));
                  setShowNavigationAlert(false);
                  // } else {
                  //   Toastiy("Failed to Save Journal BG");
                  // }
                },
              })
            );
          }, index * 500);
        } else if (
          journalValues?.banner_Images?.[index]?.url_link &&
          journalValues?.banner_Images?.[index]?.url_link !==
            currJournalValues?.banner_Images?.[index]?.url_link
        ) {
          setTimeout(() => {
            dispatch(
              updateJournalBannerAction({
                apiPayloadRequest: {
                  _id: journal_id,
                  type_of_url: parseInt(journalValues?.banner_Images?.[index]?.type_of_url),
                  url_link: journalValues?.banner_Images?.[index]?.url_link,
                },
                callback: (res) => {
                  // if (res?.type === 1) {
                  Toastiy("Journal Banner Updated Successfully!", 1);
                  setCurrJournalValues((prev) => ({
                    ...prev,
                    banner_Images: (prev?.banner_Images ?? []).map((item, i) => ({
                      type_of_url: journalValues?.banner_Images?.[i]?.type_of_url,
                      url_link: journalValues?.banner_Images?.[i]?.url_link,
                    })),
                  }));
                  setShowNavigationAlert(false);
                  // } else {
                  //   Toastiy("Failed to Save Journal BG");
                  // }
                },
              })
            );
          }, index * 500);
        }
      });
    } else {
      setLoading(true);
      dispatch(
        saveJournalAction({
          apiPayloadRequest: {
            public: true,
            journal_title: journalValues?.journal_title,
            journal_description: journalValues?.journal_description,
          },
          callback: async (response) => {
            await saveJournalBg(response);
            await saveJournalBanner(response);
            setCurrJournalValues((prev) => ({
              ...prev,
              _id: response?.data[0]?._id,
              journal_title: journalValues?.journal_title,
              journal_description: journalValues?.journal_description,
              bg_Images: journalValues?.bg_Images,
              banner_Images: journalValues?.banner_Images,
            }));
            setJournalId(response?.data[0]?._id);
            setTimeout(
              () => navigate("/journals/add", { state: { _id: response?.data[0]?._id } }),
              500
            );
            Toastiy("Journals Draft Updated Successfully!", 1);
            setLoading(false);
            setShowJournalBox(false);
            setShowNavigationAlert(false);
          },
        })
      );
    }
  };

  console.log("isLanguageSave", isLanguageSave);

  const saveJournalBg = async (journalResponse) => {
    // if (journalResponse?.type === 1) {
    const id = journalResponse?.data[0]?._id;

    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          saveBGJournalAction({
            apiPayloadRequest: {
              _id: id,
              type_of_url: parseInt(journalValues.bg_Images[item].type_of_url),
              url_link: journalValues.bg_Images[item].url_link,
            },
            callback: (res) => {
              // if (res?.type !== 1) {
              // Toastiy("Failed to Save Journals BG");
              // } else {
              setShowNavigationAlert(false);
              // }
            },
          })
        );
      }, 500 * (index + 1));
    });
    // }
  };

  const saveJournalBanner = async (journalResponse) => {
    // if (journalResponse?.type === 1) {
    const id = journalResponse?.data[0]?._id;

    keysArray.map((item, index) => {
      setTimeout(() => {
        dispatch(
          saveJournalBannerAction({
            apiPayloadRequest: {
              _id: id,
              type_of_url: parseInt(journalValues?.banner_Images?.[item]?.type_of_url),
              url_link: journalValues?.banner_Images?.[item]?.url_link,
            },
            callback: (res) => {
              // if (res?.type !== 1) {
              // Toastiy("Failed to Save Journals BG");
              // } else {
              setShowNavigationAlert(false);
              // }
            },
          })
        );
      }, 500 * (index + 1));
    });
    // }
  };

  const validateNotesComplete = (lesson) => {
    if (!lesson?.lesson_description) {
      return false;
    } else if (!lesson?.lesson_title) {
      return false;
    } else if (lesson?.slides?.length - 1 <= 0) {
      return false;
    }
    for (let i = 0; i < lesson?.lesson_tumbnail_urls?.length; i++) {
      if (
        !lesson?.lesson_tumbnail_urls?.[i]?.type_of_url ||
        !lesson?.lesson_tumbnail_urls?.[i]?.url_link
      ) {
        return false;
      }
    }
    return true;
  };

  const validateLessonLanguageComplete = (lesson) => {
    if (!lesson?.lesson_description) {
      return false;
    } else if (!lesson?.lesson_title) {
      return false;
    } else if (lesson?.slides?.length - 1 <= 0) {
      return false;
    }
    return true;
  };

  const getAllLanguageResponse = (response) => {
    if (response?.type === 1) {
      setReadLanguageData(response?.data);
    }
  };

  const convertCompleteJournalToString = (journalObject) => {
    let journalString = `${journalObject?.journal_title}||${journalObject?.journal_description}`;

    for (const note of journalObject?.notes) {
      journalString += `${note?.lesson_title}@#${note?.lesson_description}@#`;
      journalString += "+++";
    }
    return journalString;
  };

  const updateCompleteCurriculumWithTranslation = () => {
    const combinedText = convertCompleteJournalToString(journalValues);

    if (activeTab.length > 0) {
      dispatch(
        translateLanguageAction({
          apiPayloadRequest: {
            targetcode: activeTab,
            translatetext: combinedText,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const restructedData = reconstructCompleteCurriculumFromString(res?.data);
              if (restructedData) {
                setJournalValues(restructedData);
                setShowNavigationAlert(true);
              }
            } else if (res?.type === 2) {
              Toastiy("Language Translation failed!!");
              Toastiy(res?.message);
            }
          },
        })
      );
    }
  };

  const reconstructCurriculumFromString = (curriculumString) => {
    if (!curriculumString) return null;

    const [curriculum_name, curriculum_description] = curriculumString.split("///");

    const journalObject = {
      curriculum_name,
      curriculum_description,
    };

    const updatedJournals = structuredClone(journalValues);

    updatedJournals.curriculum_name = journalObject?.curriculum_name || "";
    updatedJournals.curriculum_description = journalObject?.curriculum_description || "";

    return updatedJournals;
  };

  const convertCurriculumToString = (journalObject) => {
    let curriculumString = `${journalObject?.curriculum_name}///${journalObject?.curriculum_description}`;

    return curriculumString;
  };

  const updateCurriculumWithTranslation = () => {
    const combinedText = convertCurriculumToString(journalValues);

    if (activeTab.length > 0) {
      dispatch(
        translateLanguageAction({
          apiPayloadRequest: {
            targetcode: activeTab,
            translatetext: combinedText,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const restructedData = reconstructCurriculumFromString(res?.data);
              if (restructedData) {
                setJournalValues(restructedData);
                setShowNavigationAlert(true);
              }
            } else if (res?.type === 2) {
              Toastiy("Language Translation failed!!");
              Toastiy(res?.message);
            }
          },
        })
      );
    }
  };

  const reconstructLessonFromString = (lessonString) => {
    if (!lessonString) return null;

    const [lesson_title, lesson_description] = lessonString.split("///");

    const lessonObject = {
      lesson_title,
      lesson_description,
    };

    const updatedJournals = structuredClone(journalValues);

    updatedJournals.lessons[currNotesIndex].lesson_title = lessonObject?.lesson_title || "";
    updatedJournals.lessons[currNotesIndex].lesson_description =
      lessonObject?.lesson_description || "";

    return updatedJournals;
  };

  const convertLessonToString = (lessonObject) => {
    let lessonString = `${lessonObject?.lesson_title}///${lessonObject?.lesson_description}`;

    return lessonString;
  };

  const updateLessonWithTranslation = () => {
    const combinedText = convertLessonToString(journalValues?.lessons?.[currNotesIndex]);

    if (activeTab.length > 0) {
      dispatch(
        translateLanguageAction({
          apiPayloadRequest: {
            targetcode: activeTab,
            translatetext: combinedText,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const restructedData = reconstructLessonFromString(res?.data);
              if (restructedData) {
                setJournalValues(restructedData);
                setShowNavigationAlert(true);
              }
            } else if (res?.type === 2) {
              Toastiy("Language Translation failed!!");
              Toastiy(res?.message);
            }
          },
        })
      );
    }
  };

  const convertSlideToString = (slideObject) => {
    let slideString = `${slideObject?.slide_title}////${slideObject?.slide_description}`;

    return slideString;
  };

  const reconstructCompleteCurriculumFromString = (curriculumString) => {
    if (!curriculumString) return null;

    const [curriculum_name, curriculum_description, lessonsString] = curriculumString.split("$#");
    const lessonsArray = lessonsString?.split("+++") || [];

    const journalObject = {
      curriculum_name,
      curriculum_description,
      lessons: [],
    };

    lessonsArray.forEach((lessonString) => {
      if (!lessonString) return;

      const [lesson_title, lesson_description, slidesString] = lessonString.split("@#");
      const slideArray = slidesString?.split("***") || [];

      const lessonObject = {
        lesson_title,
        lesson_description,
        slides: [],
      };

      slideArray.forEach((slideString) => {
        if (!slideString) return;

        const [slide_title, slide_description] = slideString.split("!@");
        lessonObject.slides.push({
          slide_title,
          slide_description,
        });
      });

      journalObject.lessons.push(lessonObject);
    });

    const updatedJournals = structuredClone(journalValues);

    updatedJournals.curriculum_name = journalObject?.curriculum_name;
    updatedJournals.curriculum_description = journalObject?.curriculum_description || "";

    journalObject?.lessons?.map((lesson, notesIndex) => {
      updatedJournals.lessons[notesIndex] = {
        ...(updatedJournals.lessons[notesIndex] || {}),
        lesson_title: lesson?.lesson_title || "",
        lesson_description: lesson?.lesson_description || "",
        slides: lesson?.slides?.map((slide, i) => ({
          ...updatedJournals?.lessons[notesIndex]?.slides[i],
          slide_title: slide?.slide_title || "",
          slide_description: slide?.slide_description || "",
        })),
      };
    });

    return updatedJournals;
  };

  useEffect(() => {
    if (activeTab === "en") {
      const updatedJournalsErrors = {
        journal_title: journalValues?.journal_title?.length > 0 ? false : true,
        journal_description: journalValues?.journal_description?.length > 0 ? false : true,
        bg_Images: [
          {
            type_of_url: "0",
            url_link: journalValues?.bg_Images?.[0]?.url_link?.length > 0 ? false : true,
          },
          {
            type_of_url: "1",
            url_link: journalValues?.bg_Images?.[1]?.url_link?.length > 0 ? false : true,
          },
          {
            type_of_url: "2",
            url_link: journalValues?.bg_Images?.[2]?.url_link?.length > 0 ? false : true,
          },
        ],
        banner_Images: [
          {
            type_of_url: "0",
            url_link: journalValues?.banner_Images?.[0]?.url_link?.length > 0 ? false : true,
          },
          {
            type_of_url: "1",
            url_link: journalValues?.banner_Images?.[1]?.url_link?.length > 0 ? false : true,
          },
          {
            type_of_url: "2",
            url_link: journalValues?.banner_Images?.[2]?.url_link?.length > 0 ? false : true,
          },
        ],
      };

      setJournalsErrors(updatedJournalsErrors);
    }
  }, [journalValues, activeTab]);

  useEffect(() => {
    // if (activeTab === "en") {
    const updatedNotesErrors = {
      note_description:
        journalValues?.lessons?.[currNotesIndex]?.lesson_description?.length > 0 ? false : true,
      lesson_tumbnail_urls: [
        {
          type_of_url: "0",
          url_link:
            journalValues?.lessons?.[currNotesIndex]?.lesson_tumbnail_urls?.[0]?.url_link.length > 0
              ? false
              : true,
        },
        {
          type_of_url: "1",
          url_link:
            journalValues?.lessons?.[currNotesIndex]?.lesson_tumbnail_urls?.[1]?.url_link.length > 0
              ? false
              : true,
        },
        {
          type_of_url: "2",
          url_link:
            journalValues?.lessons?.[currNotesIndex]?.lesson_tumbnail_urls?.[2]?.url_link.length > 0
              ? false
              : true,
        },
      ],
    };
    // }
  }, [currNotesIndex, journalValues, activeTab]);

  useEffect(() => {
    if (activeTab === "en") {
      setJournalValues(currJournalValues);
    } else {
      const isPresent = languagemeta?.some((language) => language?.language_code === activeTab);
      if (isPresent) {
        const data = languagemeta?.filter((item) => item?.language_code === activeTab)[0];
        const updatedCurriculumValues = {
          curriculum_name: data?.curriculum_name || currJournalValues?.curriculum_name || "",
          curriculum_description:
            data?.curriculum_description || currJournalValues?.curriculum_description || "",
          curriculumPdfUrl: data?.curriculumPdfUrl || currJournalValues?.curriculumPdfUrl || "",
          bg_Images: [
            {
              type_of_url: "0",
              url_link:
                data?.bg_Images?.find((d) => d?.type_of_url === "0")?.url_link ||
                currJournalValues?.bg_Images?.find((d) => d?.type_of_url === "0")?.url_link ||
                "",
            },
            {
              type_of_url: "1",
              url_link:
                data?.bg_Images?.find((d) => d?.type_of_url === "1")?.url_link ||
                currJournalValues?.bg_Images?.find((d) => d?.type_of_url === "1")?.url_link ||
                "",
            },
            {
              type_of_url: "2",
              url_link:
                data?.bg_Images?.find((d) => d?.type_of_url === "2")?.url_link ||
                currJournalValues?.bg_Images?.find((d) => d?.type_of_url === "2")?.url_link ||
                "",
            },
          ],
          banner_Images: [
            {
              type_of_url: "0",
              url_link:
                data?.banner_Images?.find((d) => d?.type_of_url === "0")?.url_link ||
                currJournalValues?.banner_Images?.find((d) => d?.type_of_url === "0")?.url_link ||
                "",
            },
            {
              type_of_url: "1",
              url_link:
                data?.banner_Images?.find((d) => d?.type_of_url === "1")?.url_link ||
                currJournalValues?.banner_Images?.find((d) => d?.type_of_url === "1")?.url_link ||
                "",
            },
            {
              type_of_url: "2",
              url_link:
                data?.banner_Images?.find((d) => d?.type_of_url === "2")?.url_link ||
                currJournalValues?.banner_Images?.find((d) => d?.type_of_url === "2")?.url_link ||
                "",
            },
          ],
          publish: currJournalValues?.publish,
        };
        setJournalValues(updatedCurriculumValues);
      } else {
        const updatedCurriculumValues = {
          curriculum_name: currJournalValues?.curriculum_name || "",
          curriculum_description: currJournalValues?.curriculum_description || "",
          curriculumPdfUrl: currJournalValues?.curriculumPdfUrl || "",
          bg_Images: [
            {
              type_of_url: "0",
              url_link:
                currJournalValues?.bg_Images?.find((d) => d?.type_of_url === "0")?.url_link || "",
            },
            {
              type_of_url: "1",
              url_link:
                currJournalValues?.bg_Images?.find((d) => d?.type_of_url === "1")?.url_link || "",
            },
            {
              type_of_url: "2",
              url_link:
                currJournalValues?.bg_Images?.find((d) => d?.type_of_url === "2")?.url_link || "",
            },
          ],
          banner_Images: [
            {
              type_of_url: "0",
              url_link:
                currJournalValues?.banner_Images?.find((d) => d?.type_of_url === "0")?.url_link ||
                "",
            },
            {
              type_of_url: "1",
              url_link:
                currJournalValues?.banner_Images?.find((d) => d?.type_of_url === "1")?.url_link ||
                "",
            },
            {
              type_of_url: "2",
              url_link:
                currJournalValues?.banner_Images?.find((d) => d?.type_of_url === "2")?.url_link ||
                "",
            },
          ],
          publish: currJournalValues?.publish,
        };
        setJournalValues(updatedCurriculumValues);
      }
    }
  }, [activeTab, currJournalValues, languagemeta]);

  const getJournalDetails = () => {
    dispatch(
      readJournalAction({
        apiPayloadRequest: { _id: journal_id },
        callback: (res) => {
          if (res.type === 1) {
            const data = res?.data[0];
            setJournalStats({
              totalLikes: data?.totallikes ?? 0,
              totalViews: data?.curriculum_views ?? 0,
            });
            setLanguagemeta(data?.languagemetas);
            setJournalValues(data);
            setCurrJournalValues(data);
          }
        },
      })
    );
  };

  console.log("languagemetas", languagemeta);
  console.log("journalValues", journalValues);

  useEffect(() => {
    if (journal_id !== undefined) {
      getJournalDetails();
    }
  }, []);

  useEffect(() => {
    dispatch(
      readLanguageAction({
        callback: getAllLanguageResponse,
      })
    );
  }, []);

  const handleSendNotification = () => {
    dispatch(
      sendCustomNotificationAction({
        apiPayloadRequest: {
          _id: journal_id,
          type: "3",
        },
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Notification Sent Successfully", 1);
          }
        },
      })
    );
  };

  console.log("currNotes", currNotes);

  const deleteNoteItem = (index) => {
    dispatch(
      deleteJournalNoteItemAction({
        apiPayloadRequest: {
          _id: journal_id,
          noteObjId: currNotes?.noteObjId,
          notesItemObjId: currNotes?.noteitems?.[index]?.notesItemObjId,
        },
        callback: (res) => {
          Toastiy("Item Removed Successfully!", 1);
        },
      })
    );
    setCurrNotes((prev) => {
      prev = {
        ...prev,
        noteitems: prev?.noteitems?.filter((_, i) => i !== index),
      };
      return prev;
    });
  };

  const handleAddNoteText = () => {
    setCurrNotes((prev) => {
      prev = {
        text: "",
        noteitems: [
          ...(prev?.noteitems ?? []),
          { notes: prev?.text, type_of_notes: JOURNAL_NOTE_ITEM_TYPES.TEXT },
        ],
      };
      setForceEditorUpdate(true);
      setTimeout(() => setForceEditorUpdate(false), 500);
      return prev;
    });
  };

  const handleAddNotesAsset = (notesIndex, type_of_notes, files, url = null) => {
    let index;
    setCurrNotes((prev) => {
      index = prev?.noteitems?.length ?? 0;
      if (prev?.text?.length > 0) {
        prev = {
          text: "",
          noteitems: [
            ...(prev?.noteitems ?? []),
            { notes: prev?.text, type_of_notes: JOURNAL_NOTE_ITEM_TYPES.TEXT },
          ],
        };
        setForceEditorUpdate(true);
        index = prev?.noteitems?.length ?? 0;
      }

      prev = {
        ...prev,
        noteitems: [
          ...(prev?.noteitems ?? []),
          {
            url_link: url ?? "",
            type_of_notes: type_of_notes,
            isMulti: Array.isArray(url),
            isLoading: !url,
          },
        ],
      };
      return prev;
    });

    const isMulti = files?.length > 1;

    console.log("url", url);
    console.log("files", files);
    if (!url) {
      const formData = new FormData();
      formData.append("file", files?.[0]);
      console.log("formData", formData);
      dispatch(
        uploadAssestsAction({
          apiPayloadRequest: formData,
          callback: (res) => {
            console.log("res", res);
            if (res && res?.url) {
              setCurrNotes((prev) => {
                console.log("prev6454", prev, index);
                const updatedData = structuredClone(prev);
                updatedData.noteitems[index] = {
                  ...(updatedData?.noteitems?.[index] ?? {}),
                  url_link: res?.url,
                  isLoading: false,
                };
                return updatedData;
              });
            }
          },
        })
      );
    }

    setTimeout(() => setForceEditorUpdate(false), 500);
  };

  const handlePublishJournal = (publishType = true) => {
    setJournalPublishLoading(true);
    if (journal_id !== undefined) {
      dispatch(
        updateJournalAction({
          apiPayloadRequest: {
            _id: journal_id,
            journal_title: journalValues?.journal_title,
            publish: publishType,
          },
          callback: (res) => {
            // if (res?.type === 1) {
            if (publishType) {
              Toastiy("Journal Published Successfully!", 1);
              getJournalDetails();
              setShowNavigationAlert(false);
            } else {
              setJournalValues((prev) => ({ ...prev, publish: publishType + "" }));
            }
            setJournalPublishLoading(false);
            // }
          },
        })
      );
    }
  };

  return {
    handleAddNoteText,
    handleAddNotesAsset,
    journalValues,
    currJournalValues,
    handleChange,
    addNewNotes,
    currNotesIndex,
    setCurrNotes,
    currNotes,
    cancelNotes,
    saveNotes,
    saveLessonLanguage,
    setShowNotesBox,
    showNotesBox,
    setCurrNotesIndex,
    setIsNotesEdit,
    initialNotesState,
    showJournalBox,
    showPreview,
    handleClosePreview,
    handleOpenPreview,
    setShowJournalBox,
    saveJournals,
    journalsErrors,
    showJournalsErrors,
    showNotesErrors,
    setShowJournalsErrors,
    setShowNotesErrors,
    readLanguageData,
    setActiveTab,
    activeTab,
    setIsLanguageSave,
    languagemeta,
    updateCompleteCurriculumWithTranslation,
    updateCurriculumWithTranslation,
    updateLessonWithTranslation,
    cancelJournal,
    isNotesEdit,
    validateNotesComplete,
    validateLessonLanguageComplete,
    handleDeleteNotes,
    activeLanguageDetails,
    setActiveLanguageDetails,
    handleNotesLibraryVideoSelection,
    forceEditorUpdate,
    isLanguageSave,
    journalStats,
    handleSendNotification,
    journalPublishLoading,
    handlePublishJournal,
    deleteNoteItem,
  };
};
