import Axios from "../../utils/axios";

export const getProfile = (payload) => {
  const URL = "/profile";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const saveUserProfile = () => {
  const URL = "/user/save";
  return Axios.post(URL).then((res) => {
    return res.data;
  });
};

export const updateUserProfile = (payload) => {
  const URL = "/user/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const getPickWhereLeft = (payload) => {
  const URL = "/user/getPickups";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getAchievements = (payload) => {
  const URL = "/user/getAchievement";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const readUserFav = (payload) => {
  const URL = "/user/getFav";
  return Axios.post(URL, payload).then((res) => res.data);
};
