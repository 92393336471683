import { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { Tooltip } from "react-tooltip";
import ReactModal from "react-modal";

import VideoLibraryModal from "./VideoLibraryModal";

import { acceptAudio, acceptImage, acceptVideo } from "../../constants/ValidationConstants";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaInfoCircle } from "react-icons/fa";
import { MdVideoLibrary } from "react-icons/md";

const UploadControlsV2 = ({
  title,
  helpText = null,
  subtitle,
  className,
  tooltipTitle = "",
  handleFileUpload,
  handleMobileFileUpload,
  handleTabletFileUpload,
  handleWebFileUpload,
  handleChangeUrl,
  mobileUploadProgressId,
  tabletUploadProgressId,
  webUploadProgressId,
  handleMobileChangeUrl,
  handleTabletChangeUrl,
  handleWebChangeUrl,
  mobileUploadUrl,
  mobileUploadName = "mobileUploadUrl",
  tabletUploadUrl,
  tabletUploadName = "tabletUploadUrl",
  webUploadUrl,
  webUploadName = "webUploadUrl",
  showMobileUpload = true,
  handleMobilePreview,
  showTabletUpload = true,
  handleTabletPreview,
  showWebUpload = true,
  handleWebPreview,
  showPreview = false,
  accept = "image",
  isFieldRequired = false,
  isMobileFieldRequired = false,
  isTabletFieldRequired = false,
  isWebFieldRequired = false,
  requiredMessage = "Required Field",
  showVideoLibrarySelection = false,
  handleLibraryVideoSelection = null,
  isFieldRequiredClassName = "",
  webUploadCropRequired,
  webUploadCropAspect,
  mobileUploadCropRequired,
  mobileUploadCropAspect,
  tabletUploadCropRequired,
  tabletUploadCropAspect,
  defaultCollapse = true
}) => {
  const [collapse, setCollapse] = useState(defaultCollapse);
  const [showVideoLibraryModal, setShowVideoLibraryModal] = useState(false);
  const [cropImage, setCropImage] = useState(null);
  const [cropOriginalImage, setCropOriginalImage] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [activeItemObject, setActiveItemObject] = useState(null);
  const [cropAspect, setCropAspect] = useState();
  const imageRef = useRef();

  console.log("activeItemObject", activeItemObject);
  console.log("cropAspect", cropAspect);

  const fileAccept =
    accept === "video" ? acceptVideo : accept === "audio" ? acceptAudio : acceptImage;

  const handleFileRemove = (UploadProgressId) => {
    const uploadProgressContainer = document.getElementById(`${UploadProgressId}Container`);
    if (uploadProgressContainer) uploadProgressContainer.classList.add("hidden");
    const uploadProgress = document.getElementById(`${UploadProgressId}Progress`);
    if (uploadProgress) uploadProgress.textContent = "0%";
    const progressBar = document.getElementById(UploadProgressId);
    if (progressBar) progressBar.value = "0";
  };

  // Function to create the cropped image
  const getCroppedImageFile = () => {
    if (!imageRef.current) return;

    const canvas = document.createElement("canvas");
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;

    // Adjust the canvas to the crop's dimensions at original image size
    const cropWidth = cropImage.width * scaleX;
    const cropHeight = cropImage.height * scaleY;

    canvas.width = cropWidth;
    canvas.height = cropHeight;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      imageRef.current,
      cropImage.x * scaleX,
      cropImage.y * scaleY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    // Convert canvas to Blob (or File) and download or use it further
    canvas.toBlob(
      (blob) => {
        const file = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
        });
        console.log("Cropped image file: ", file);

        handleFileUploadStart(
          { file: file },
          activeItemObject?.UploadProgressId,
          activeItemObject?.handleDeviceFileUpload,
          activeItemObject?.webUploadName
        );

        // Optionally, trigger a download
        // const link = document.createElement("a");
        // link.href = URL.createObjectURL(file);
        // link.download = "cropped-image.jpg";
        // link.click();
      },
      "image/jpeg",
      1
    );
  };

  const closeCropModal = () => {
    setShowCropModal(false);
    setActiveItemObject(null);
    setCropImage(null);
    setCropOriginalImage(null);
  };

  const handleFileUploadStart = (
    file,
    UploadProgressId,
    handleDeviceFileUpload,
    webUploadName,
    cropRequired,
    cropAspectRatio
  ) => {
    if (cropRequired && !cropImage) {
      setShowCropModal(true);
      setCropAspect(cropAspectRatio);
      setCropImage({
        unit: "px", // Can be 'px' or '%'
        x: 25,
        y: 25,
        width: 100 * (cropAspectRatio ?? 1),
        height: 100,
      });
      setCropOriginalImage(URL.createObjectURL(file.file));
      setActiveItemObject({ file, UploadProgressId, handleDeviceFileUpload, webUploadName });
      return;
    }
    const uploadProgressContainer = document.getElementById(`${UploadProgressId}Container`);
    const uploadProgressBar = document.getElementById(`${UploadProgressId}Progress`);
    if (uploadProgressBar) uploadProgressBar.classList.remove("error");
    if (uploadProgressContainer) uploadProgressContainer.classList.remove("hidden");
    if (handleDeviceFileUpload) {
      handleDeviceFileUpload({ target: { files: [file.file], name: webUploadName } });
      closeCropModal();
      return;
    }
    handleFileUpload({ target: { files: [file.file], name: webUploadName } });
    closeCropModal();
  };

  const handleVideoSelection = (details) => {
    if (handleLibraryVideoSelection) handleLibraryVideoSelection(details);
    setShowVideoLibraryModal(false);
  };

  return (
    <>
      <div
        className={`bg-blackishV2 rounded-lg border border-opacity-10 border-white p-2 ${
          className ?? ""
        }`}
      >
        <div
          className="flex justify-between items-center text-greyish cursor-pointer "
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          <label
            htmlFor="videoURLs"
            className="text-[12px] font-medium leading-4 mb-2 flex flex-col"
          >
            <span className="flex items-center gap-2">
              {title}{" "}
              <FaInfoCircle
                {...(tooltipTitle
                  ? { "data-tooltip-id": "upload_controls", "data-tooltip-content": tooltipTitle }
                  : {})}
              />
            </span>
            {helpText && <p className="flex items-center gap-2">{helpText}</p>}
          </label>
          {collapse ? <IoIosArrowDown /> : <IoIosArrowUp />}
        </div>
        <div
          className={`p-4 w-full flex flex-col  justify-between pb-6 rounded-lg bg-blackish md:col-span-2 col-span-1 ${
            collapse ? "hidden" : ""
          }`}
        >
          {showVideoLibrarySelection && (
            <p
              className="flex justify-end items-center cursor-pointer gap-1.5 text-white text-sm m-0"
              onClick={() => setShowVideoLibraryModal(true)}
            >
              <MdVideoLibrary className="text-base" />
              Select from library
            </p>
          )}
          {showMobileUpload && (
            <div className="flex flex-row gap-3 items-center ">
              <div className="w-1/2">
                <label
                  htmlFor={mobileUploadName}
                  className="text-[12px] text-greyish font-medium leading-4 "
                >
                  {subtitle} <span className="text-[10px]">( For Mobile )</span>
                </label>
                <div className="flex flex-col">
                  <FilePond
                    className="!mb-1"
                    acceptedFileTypes={fileAccept}
                    name={mobileUploadName}
                    onremovefile={() => handleFileRemove(mobileUploadProgressId)}
                    onaddfilestart={(file) =>
                      handleFileUploadStart(
                        file,
                        mobileUploadProgressId,
                        handleMobileFileUpload,
                        mobileUploadName,
                        mobileUploadCropRequired,
                        mobileUploadCropAspect
                      )
                    }
                  />
                  <div
                    className="flex w-full items-center hidden"
                    id={`${mobileUploadProgressId}Container`}
                  >
                    <progress
                      defaultValue="0"
                      max="100"
                      className="flex-1 h-1"
                      id={mobileUploadProgressId}
                    ></progress>
                    <span
                      className="text-white text-[0.5rem] ml-1"
                      id={`${mobileUploadProgressId}Progress`}
                    >
                      0%
                    </span>
                  </div>
                </div>
              </div>
              <p className="pt-10 md:col-span-2 col-span-1 text-center text-white">Or</p>
              <div className="w-1/2 md:col-span-2 col-span-1">
                <div className="flex justify-between mt-2">
                  <label
                    htmlFor={mobileUploadName}
                    className="text-[12px] text-greyish font-medium leading-4 "
                    data-tooltip-id="upload_controls"
                    data-tooltip-content={`Enter the ${accept === "image" ? "Image" : "Video"} URL`}
                  >
                    Upload URL
                  </label>
                  {showPreview && (
                    <button
                      className="text-[8px] text-green-500 font-medium leading-4 cursor-pointer z-50"
                      onClick={handleMobilePreview}
                    >
                      View Preview
                    </button>
                  )}
                </div>
                <input
                  name={mobileUploadName}
                  placeholder="Enter URL"
                  type="text"
                  className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                  value={mobileUploadUrl}
                  onChange={handleMobileChangeUrl ?? handleChangeUrl}
                />
              </div>
            </div>
          )}

          {isMobileFieldRequired && (
            <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2 mb-2">
              {requiredMessage}
            </p>
          )}

          {showTabletUpload && (
            <div className="flex flex-row gap-3 items-center ">
              <div className="w-1/2">
                <label
                  htmlFor={tabletUploadName}
                  className="text-[12px] text-greyish font-medium leading-4 "
                >
                  {subtitle} <span className="text-[10px]">( For Tablet )</span>
                </label>
                <div className="flex flex-col">
                  <FilePond
                    className="!mb-1"
                    acceptedFileTypes={fileAccept}
                    name={tabletUploadName}
                    onremovefile={() => handleFileRemove(tabletUploadProgressId)}
                    onaddfilestart={(file) =>
                      handleFileUploadStart(
                        file,
                        tabletUploadProgressId,
                        handleTabletFileUpload,
                        tabletUploadName,
                        tabletUploadCropRequired,
                        tabletUploadCropAspect
                      )
                    }
                  />
                  <div
                    className="flex w-full items-center hidden"
                    id={`${tabletUploadProgressId}Container`}
                  >
                    <progress
                      defaultValue="0"
                      max="100"
                      className="flex-1 h-1"
                      id={tabletUploadProgressId}
                    ></progress>
                    <span
                      className="text-white text-[0.5rem] ml-1"
                      id={`${tabletUploadProgressId}Progress`}
                    >
                      0%
                    </span>
                  </div>
                </div>
                {/* <input
                  name={tabletUploadName}
                  className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                  type="file"
                  accept={fileAccept}
                  onChange={handleTabletFileUpload ?? handleFileUpload}
                /> */}
              </div>
              <p className="pt-10 md:col-span-2 col-span-1 text-center text-white">Or</p>
              <div className="w-1/2 md:col-span-2 col-span-1">
                <div className="flex justify-between mt-2">
                  <label
                    htmlFor={tabletUploadName}
                    className="text-[12px] text-greyish font-medium leading-4 "
                    data-tooltip-id="upload_controls"
                    data-tooltip-content={`Enter the ${accept === "image" ? "Image" : "Video"} URL`}
                  >
                    Upload URL
                  </label>
                  {showPreview && (
                    <button
                      className="text-[8px] text-green-500 font-medium leading-4 cursor-pointer z-50"
                      onClick={handleTabletPreview}
                    >
                      View Preview
                    </button>
                  )}
                </div>
                <input
                  name={tabletUploadName}
                  placeholder="Enter URL"
                  type="text"
                  className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                  value={tabletUploadUrl}
                  onChange={handleTabletChangeUrl ?? handleChangeUrl}
                />
              </div>
            </div>
          )}

          {isTabletFieldRequired && (
            <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2 mb-2">
              {requiredMessage}
            </p>
          )}

          {showWebUpload && (
            <div className="flex flex-row gap-3 items-center ">
              <div className="w-1/2">
                <label
                  htmlFor={webUploadName}
                  className="text-[12px] text-greyish font-medium leading-4 "
                >
                  {subtitle} <span className="text-[10px]">( For Web )</span>
                </label>
                <div className="flex flex-col">
                  <FilePond
                    className="!mb-1"
                    acceptedFileTypes={fileAccept}
                    name={webUploadName}
                    onremovefile={() => handleFileRemove(webUploadProgressId)}
                    onaddfilestart={(file) =>
                      handleFileUploadStart(
                        file,
                        webUploadProgressId,
                        handleWebFileUpload,
                        webUploadName,
                        webUploadCropRequired,
                        webUploadCropAspect
                      )
                    }
                  />
                  <div
                    className="flex w-full items-center hidden"
                    id={`${webUploadProgressId}Container`}
                  >
                    <progress
                      defaultValue="0"
                      max="100"
                      className="flex-1 h-1"
                      id={webUploadProgressId}
                    ></progress>
                    <span
                      className="text-white text-[0.5rem] ml-1"
                      id={`${webUploadProgressId}Progress`}
                    >
                      0%
                    </span>
                  </div>
                </div>
                {/* <input
                  name={webUploadName}
                  className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                  type="file"
                  accept={fileAccept}
                  onChange={handleWebFileUpload ?? handleFileUpload}
                /> */}
              </div>
              <p className="pt-10 md:col-span-2 col-span-1 text-center text-white">Or</p>
              <div className="w-1/2 md:col-span-2 col-span-1">
                <div className="flex justify-between mt-2">
                  <label
                    htmlFor={webUploadName}
                    className="text-[12px] text-greyish font-medium leading-4 "
                    data-tooltip-id="upload_controls"
                    data-tooltip-content={`Enter the ${accept === "image" ? "Image" : "Video"} URL`}
                  >
                    Upload URL
                  </label>
                  {showPreview && (
                    <button
                      className="text-[8px] text-green-500 font-medium leading-4 cursor-pointer z-50"
                      onClick={handleWebPreview}
                    >
                      View Preview
                    </button>
                  )}
                </div>
                <input
                  name={webUploadName}
                  placeholder="Enter URL"
                  type="text"
                  className={`bg-greyishV2 p-2 w-full text-white text-[14px] font-medium mt-2 leading-4 rounded-md h-10 `}
                  value={webUploadUrl}
                  onChange={handleWebChangeUrl ?? handleChangeUrl}
                />
              </div>
            </div>
          )}
          {isWebFieldRequired && (
            <p className="text-red-500 relative bottom-[-6px] text-[10px] left-2 mb-2">
              {requiredMessage}
            </p>
          )}
        </div>
      </div>
      {showVideoLibrarySelection && (
        <VideoLibraryModal
          showVideoLibraryModal={showVideoLibraryModal}
          setShowVideoLibraryModal={setShowVideoLibraryModal}
          handleVideoSelection={handleVideoSelection}
        />
      )}
      <Tooltip id="upload_controls" className="border border-greyish border-opacity-20" />
      {isFieldRequired && (
        <p
          className={`text-red-500 relative bottom-[-10px] text-[10px] left-2 mb-1 ${isFieldRequiredClassName}`}
        >
          {requiredMessage}
        </p>
      )}

      <ReactModal
        isOpen={showCropModal}
        onRequestClose={() => setShowCropModal(false)}
        className="relative"
        bodyOpenClassName="z-50"
        // style={{
        //   content: {
        //     zIndex: 50000,
        //   },
        // }}
      >
        <div className="flex justify-center items-center h-screen w-screen relative">
          <div className="w-[60vw] h-fit max-h-[80vh] py-10 px-10 bg-blackishV2 relative flex flex-col items-center rounded-xl">
            <ReactCrop
              crop={cropImage}
              onChange={(c) => setCropImage(c)}
              aspect={cropAspect}
              className="flex justify-center w-fit"
            >
              <img
                ref={imageRef}
                src={cropOriginalImage}
                alt="crop canvas"
                className="max-w-full w-auto !h-full object-contain !max-h-[65vh]"
              />
            </ReactCrop>
            <div className="flex w-full justify-end gap-4 mt-5">
              <button
                className="text-yellowish bg-blackish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                onClick={() => {
                  setShowCropModal(false);
                  setCropImage(null);
                  setCropOriginalImage(null);
                }}
              >
                Cancel
              </button>
              <button
                className="text-yellowish hover:text-white bg-blackish hover:bg-yellowish font-medium text-[12px] border border-yellowish rounded-xl py-2 px-6"
                onClick={() => getCroppedImageFile()}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default UploadControlsV2;
