import { useState } from "react";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";

const ToggleStar = ({ handleToggleClick, status }) => {
  const [myStatus, setMyStatus] = useState(status === 1 ? true : false);

  const handleClick = () => {
    setMyStatus(!myStatus);
    handleToggleClick(!myStatus);
  };

  return (
    <div onClick={handleClick}>
      {myStatus ? (
        <AiOutlineStar className="text-yellow-500 h-5" />
      ) : (
        <AiFillStar className="text-yellow-500 h-5" />
      )}
    </div>
  );
};

export default ToggleStar;
