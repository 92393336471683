import React from "react";

function CustomProgressBar({ parentClassName, width, data, title, clickHandler }) {
  return (
    <div className="w-full">
      <div
        className={`w-full ${parentClassName ?? ""}`}
        onClick={() => {
          if (clickHandler) clickHandler();
        }}
      >
        <div className="w-full flex justify-between items-center text-greyish">
          <p>{title}</p>
          <p>{data}</p>
        </div>
        <div className="relative mt-2">
          <div className={`${width} bg-yellowish h-1 z-[1] absolute top-0`}></div>
          <div className="w-full bg-[#e4e4e4] bg-opacity-20 h-1 relative top-0 z-0"></div>
        </div>
      </div>
    </div>
  );
}

export default CustomProgressBar;
