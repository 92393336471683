import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const MyStatefulEditor = ({
  labelText,
  labelColor = "white",
  darkMode = false,
  errorMessage = "",
  placeholder = "",
  isFieldRequired = false,
  htmlFor,
  name,
  value,
  handleInputChange,
  isSubmitted = false,
  margin,
  bgColor = "",
  forceEditorUpdate = false,
  minimalToolbar = false,
  showToolbarOnFocus = false,
}) => {
  //   const [innerValue, setInnerValue] = useState(RichTextEditor.createValueFromString(value, "html"));
  const [innerValue, setInnerValue] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(value || "")?.contentBlocks)
    )
  );

  const [ifInvalid, setIfInvalid] = useState(false);
  const [editorFocus, setEditorFocus] = useState(false);

  const handleChange = (newValue) => {
    setInnerValue(newValue); // Update the value state
    if (handleInputChange) {
      // Pass the correct parameters to the parent's handleInputChange function
      handleInputChange({
        target: {
          name: name,
          value: draftToHtml(convertToRaw(innerValue.getCurrentContent())), // Assuming you want to pass the content as HTML
        },
      });
    }
  };

  useEffect(() => {
    if (forceEditorUpdate) {
      setInnerValue(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(value || "")?.contentBlocks)
        )
      );
    }
  }, [forceEditorUpdate, value]);

  const toolbarOptions = minimalToolbar
    ? {
        options: ["inline", "list", "textAlign", "history"],
        inline: {
          options: ["bold", "italic", "underline"],
        },
        list: {
          options: ["unordered", "ordered"],
        },
        textAlign: {
          options: ["left", "center", "right"],
        },
        history: {
          options: ["undo", "redo"],
        },
      }
    : {
        fontFamily: {
          options: ["Lato"],
        },
      };

  return (
    <div className="relative w-full ">
      <label
        htmlFor={htmlFor}
        style={{
          color: `${isSubmitted && isFieldRequired ? "border-red-500" : labelColor}`,
        }}
        className="text-[12px] text-gray-600 pb-4 font-medium leading-4 "
      >
        {labelText}
        {isFieldRequired ? <span className="text-red-500">*</span> : ""}
      </label>

      <Editor
        toolbar={toolbarOptions}
        toolbarHidden={showToolbarOnFocus && !editorFocus}
        placeholder={placeholder}
        editorState={innerValue}
        toolbarClassName="toolbarClassName text-black"
        wrapperClassName={`wrapperClassName ${darkMode ? "bg-greyishV2" : "bg-white"} ${
          showToolbarOnFocus && !editorFocus ? "border-t border-greyish" : "border p-3"
        } mt-3`}
        editorClassName={`editorClassName ${
          darkMode ? "text-white bg-greyishV2" : "bg-white text-black"
        }`}
        onEditorStateChange={handleChange}
        onFocus={() => setEditorFocus(true)}
        onBlur={() => setEditorFocus(false)}
      />

      {isSubmitted &&
        //  !validationFunctionName(innerValue) &&
        isFieldRequired && (
          <p className="text-red-500  relative text-[13px] left-0 top-[-10px]">{errorMessage}</p>
        )}
      {ifInvalid && (
        <p className="text-red-500  relative text-[13px] left-0 top-[-10px] ">{errorMessage}</p>
      )}
    </div>
  );
};

MyStatefulEditor.propTypes = {
  labelText: PropTypes.string,
  labelColor: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  isFieldRequired: PropTypes.bool,
  htmlFor: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool,
  // validationFunctionName: PropTypes.string,
  margin: PropTypes.string,
};

MyStatefulEditor.defaultProps = {
  isSubmitted: false,
  margin: "10px", // You can set a default value for margin if not provided
};

export default MyStatefulEditor;
