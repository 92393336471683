import edit from "../../assets/icons/edit.svg";
import close from "../../assets/icons/close.svg";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useState } from "react";
import ConfirmToast from "../universal/ConfirmToast";
import { confirmAlert } from "react-confirm-alert";

const LessonPreviewCard = ({
  lesson,
  activeTab,
  addNewSlide,
  setCurrSlideIndex,
  curriculumValues,
  index,
  setCurrLessonIndex,
  setIsSlideEdit,
  setCurrLesson,
  setShowLessonBox,
  setIsLessonEdit,
  setCurrSlide,
  setShowSlideBox,
  validateSlideComplete,
  validateLessonComplete,
  handleDeleteSlide,
  handleDeleteLesson,
}) => {
  const [lessonCollapse, setLessonCollapse] = useState(true);

  const toggleCollapse = () => {
    setLessonCollapse(!lessonCollapse);
  };

  console.log("lesson1", lesson);
  console.log("lesson1 validate", validateLessonComplete(lesson));

  return (
    <div
      className={`flex flex-col gap-3 relative p-4 border bg-black bg-opacity-30 rounded-lg border-opacity-25 ${
        validateLessonComplete(lesson)
          ? "border-white"
          : "border-red-500 border-2 border-opacity-75"
      }`}
    >
      <div className="bg-yellowish h-3 w-3 border-2  border-white  absolute -left-[30px] top-1 -translate-y-1/2 rounded-full"></div>
      <div className="cursor-pointer" onClick={toggleCollapse}>
        <div className="flex justify-between">
          <p className="text-sm font-white font-medium text-white w-9/12">{lesson?.lesson_title}</p>
          <div className="flex gap-2 justify-end items-center cursor-pointer">
            <img
              className="h-4"
              src={edit}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                setCurrLesson({ ...lesson });
                setShowLessonBox(true);
                setCurrLessonIndex(index);
                setIsLessonEdit(true);
              }}
            />
            <img
              className="h-4 mr-4"
              src={close}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                const options = {
                  customUI: ({ onClose }) => {
                    return (
                      <ConfirmToast
                        title="Are you sure?"
                        message="You want to delete this lesson?"
                        onClose={() => onClose()}
                        onSuccess={() => {
                          handleDeleteLesson({
                            lessonObjId: lesson?.lessonObjId,
                            lessonIndex: index,
                          });
                          onClose();
                        }}
                      />
                    );
                  },
                  closeOnEscape: true,
                  closeOnClickOutside: true,
                  keyCodeForClose: [8, 32],
                  overlayClassName: "!bg-gray-500 !bg-opacity-75",
                };
                confirmAlert(options);
              }}
            />
            {activeTab === "en" && (
              <button
                className="text-yellow-500 text-[10px] px-3 py-1 border border-yellow-500 rounded-md"
                onClick={(e) => {
                  e.stopPropagation();
                  addNewSlide();
                  setCurrSlideIndex(curriculumValues?.lessons?.[index]?.slides?.length - 1);
                  setCurrLessonIndex(index);
                  setIsSlideEdit(false);
                }}
              >
                Add Slide
              </button>
            )}
          </div>
        </div>
      </div>

      <div className={`${lessonCollapse ? "hidden" : ""}`}>
        <div className="grid grid-cols-3 gap-2 justify-between">
          <img
            className="aspect-[16/9] object-cover object-top rounded-md"
            src={lesson?.lesson_tumbnail_urls?.[0]?.url_link}
            alt=""
          />
          <img
            className="aspect-[16/9] object-cover object-top rounded-md"
            src={lesson?.lesson_tumbnail_urls?.[1]?.url_link}
            alt=""
          />
          <img
            className="aspect-[16/9] object-cover object-top rounded-md"
            src={lesson?.lesson_tumbnail_urls?.[2]?.url_link}
            alt=""
          />
        </div>
        <div className="pr-12  text-greyish text-[13px] font-medium relative mt-2">
          {lesson?.lesson_description}
        </div>

        {/* <p className="text-greyish text-[10px] font-medium">{lesson?.lesson_duration} min</p> */}

        <div className="flex flex-col gap-2 p-2">
          {lesson?.slides &&
            lesson?.slides?.length > 0 &&
            lesson?.slides?.map(
              (slide, idx) =>
                idx !== lesson?.slides?.length - 1 && (
                  <button
                    className={`flex justify-between p-1 px-2 border-2 rounded-sm bg-yellow-500 text-black font-bold ${
                      validateSlideComplete(slide) ? "border-white" : "border-red-500"
                    }`}
                    key={slide?.slideObjId}
                  >
                    <p className="text-xs">{slide?.slide_title}</p>
                    <div className="flex gap-2">
                      <AiOutlineEdit
                        onClick={() => {
                          setIsSlideEdit(true);
                          setCurrSlideIndex(idx);
                          setCurrLessonIndex(index);
                          setCurrSlide(structuredClone(slide));
                          setShowSlideBox(true);
                        }}
                      />
                      <AiOutlineDelete
                        onClick={() => {
                          const options = {
                            customUI: ({ onClose }) => {
                              return (
                                <ConfirmToast
                                  title="Are you sure?"
                                  message="You want to delete this slide?"
                                  onClose={() => onClose()}
                                  onSuccess={() => {
                                    handleDeleteSlide({
                                      slideObjId: slide?.slideObjId,
                                      lessonObjId: lesson?.lessonObjId,
                                      lessonIndex: index,
                                      slideIndex: idx,
                                    });
                                    onClose();
                                  }}
                                />
                              );
                            },
                            closeOnEscape: true,
                            closeOnClickOutside: true,
                            keyCodeForClose: [8, 32],
                            overlayClassName: "!bg-gray-500 !bg-opacity-75",
                          };
                          confirmAlert(options);
                        }}
                      />
                    </div>
                  </button>
                )
            )}
        </div>
      </div>
    </div>
  );
};

export default LessonPreviewCard;
