import { useEffect, useState } from "react";
import CustomTable from "../../components/universal/CustomTable";
import { cloudflareVideosHeaders } from "../NotificationList/Constants";
import Navbar from "../../components/universal/Navbar";
import { useDispatch } from "react-redux";
import NotificationsListHooks from "../NotificationList/hooks/NotificationsListHooks";
import SearchBar from "../../components/universal/SearchBar";
import axios from "axios";
import { searchVideoUrlAction } from "../../store/Layout/LayoutAction";
import LoaderSpinner from "../../components/universal/LoaderSpinner";
import moment from "moment/moment";
import Toastiy from "../../components/universal/Toastiy";

const processCount = 20;

const CloudflareVideos = () => {
  const dispatch = useDispatch();
  const { value, onChangeSearchBar } = NotificationsListHooks();
  const [videosData, setVideosData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [batchProcessing, setBatchProcessing] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [firstBatch, setFirstBatch] = useState(true);

  console.log("videosData", videosData);
  console.log("startIndex", startIndex);
  console.log("endIndex", endIndex);
  console.log("batchProcessing", batchProcessing);

  const getVideoStatus = (videoData) => {
    return new Promise((resolve, reject) => {
      dispatch(
        searchVideoUrlAction({
          apiPayloadRequest: {
            searchString: videoData?.uid,
          },
          callback: (res) => {
            if (res?.type === 1 && res?.data?.length > 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          },
        })
      );
    });
  };

  const deleteVideo = (video) => {
    axios(
      `https://api.cloudflare.com/client/v4/accounts/${process.env.REACT_APP_CLOUDFLARE_ACCOUNTID}/stream/${video?.uid}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CLOUDFLARE_TOKEN}`,
        },
      }
    )
      .then(({ data }) => {
        console.log("delete data", data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const checkStatusAndProcessBatch = async () => {
    console.log("startIndex", startIndex, "endIndex", endIndex);
    const promiseArray = [];
    setBatchProcessing(true);
    for (let i = startIndex; i <= endIndex && i < videosData?.length; i++) {
      promiseArray.push(getVideoStatus(videosData[i]));
    }

    await Promise.allSettled(promiseArray).then((results) => {
      console.log("status results", results);
      console.log(
        "startIndex",
        startIndex,
        "endIndex",
        endIndex,
        "videosData?.length",
        videosData?.length
      );
      for (let i = 0; i < results?.length; i++) {
        if (results[i]?.value === false) {
          deleteVideo(videosData[startIndex + i]);
        }
      }
      Toastiy("Batch Processed Successfully", 1);
      setBatchProcessing(false);
      setFirstBatch(false);

      //Update the indexes

      if (endIndex === videosData?.length - 1) {
        setStartIndex(endIndex);
        Toastiy("All Processing Completed", 1);
        return;
      }

      let startingIndex;
      let endingIndex;
      startingIndex = endIndex + 1;
      setStartIndex(startingIndex);
      endingIndex =
        endIndex + processCount > videosData?.length - 1
          ? videosData?.length - 1
          : endIndex + processCount;
      setEndIndex(endingIndex);
      console.log("startingIndex", startingIndex, "endingIndex", endingIndex);
      checkStatusAndProcessBatch(startingIndex, endingIndex);
    });
  };

  useEffect(() => {
    setLoading(true);
    axios(
      `https://api.cloudflare.com/client/v4/accounts/${process.env.REACT_APP_CLOUDFLARE_ACCOUNTID}/stream`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CLOUDFLARE_TOKEN}`,
        },
      }
    )
      .then(({ data }) => {
        console.log("stream data", data);
        const videoList = data?.result
          ?.filter((d) => d?.status?.state !== "ready" || Number(d?.status?.pctComplete ?? 0) < 100)
          ?.map((d) => ({
            uid: d?.uid,
            video: d?.playback?.hls,
            thumbnail: d?.thumbnail,
            duration: d?.duration + "s",
            uploaded: moment(d?.uploaded)?.format("DD-MM-YYYY HH:MM"),
            pctComplete: Number(d?.status?.pctComplete ?? 0)?.toFixed(2),
            status: d?.status,
            videostatus: d?.status?.errorReasonCode,
            tooltip: d?.status?.errorReasonText,
          }));
        setVideosData(videoList);
        setStartIndex(0);
        setEndIndex(videoList?.length - 1 > processCount ? processCount : videoList?.length - 1);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <div className="flex justify-between items-center w-full">
                <p className="text-white text-3xl font-medium">
                  Video Cleanup Utility <small>(Cloudflare Videos)</small>
                </p>
                {endIndex === videosData?.length ? (
                  ""
                ) : (
                  <button
                    className="bg-yellowish text-blackishV2 flex items-center gap-2 py-1 px-4"
                    disabled={batchProcessing}
                    onClick={() => {
                      checkStatusAndProcessBatch();
                    }}
                  >
                    {batchProcessing && <LoaderSpinner className="!text-black" />}{" "}
                    {startIndex === endIndex
                      ? "No Videos to Process"
                      : batchProcessing
                      ? "Processing"
                      : firstBatch
                      ? "Process Current Batch"
                      : "Release and Process Next Batch"}
                  </button>
                )}
              </div>
            </div>

            <div>
              {loading ? (
                <div className="w-full flex flex-col h-[70vh] justify-center items-center">
                  <LoaderSpinner className="" />
                  <p className="text-white font-medium mt-2">
                    Loading data from <i>Cloudflare</i> Stream and checking video status...
                  </p>
                  <p className="text-white/50 font-normal text-sm my-1">
                    (This might take some time as per the total number of videos available on stream
                    library)
                  </p>
                </div>
              ) : (
                <CustomTable
                  searchFor="Videos"
                  width={"w-24"}
                  nthChildWidth={
                    "[&>*:nth-child(1)]:w-40 [&>*:nth-child(2)]:w-40 [&>*:nth-child(3)]:w-48"
                  }
                  nthChildColor={
                    "[&>*:nth-child(2)]:text-greyish [&>*:nth-child(3)]:text-greyish [&>*:nth-child(4)]:text-greyish [&>*:nth-child(5)]:text-greyish"
                  }
                  nthChildStyle={"[&>*:nth-child(3)]:h-5 [&>*:nth-child(3)]:overflow-hidden"}
                  data={videosData?.filter(
                    (d, i) =>
                      i >= startIndex && i <= startIndex + processCount && i < videosData?.length
                  )}
                  headers={cloudflareVideosHeaders}
                  notificationTable={true}
                  reverseTable={true}
                  handleDeleteClick={(row) => {
                    console.log("delete data", row);
                    getVideoStatus(row)?.then((response) => {
                      console.log("response", response);
                      if (response === false) {
                        deleteVideo(row);
                        Toastiy("Video Deleted Successfully", 1);
                      } else {
                        Toastiy("Unable to delete Video as its being used in module");
                      }
                    });
                  }}
                  heightDesktop={"h-[68vh]"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudflareVideos;
