import React from "react";
import LoginBg from "../../../../assets/images/langPreview4.png";
import Input from "../../../InputFields/Input";
import SignInButton from "../../../Buttons/SignInButton";

const SignUp = ({ currentTab, fieldValues }) => {
  return (
    <div
      className=" h-full overflow-y-auto no-scrollbar flex flex-col justify-end bg-cover bg-center"
      style={{ background: `url(${LoginBg})` }}
    >
      <div className="flex flex-col gap-6  items-center bg-black/70 px-2 py-3 m-1.5 rounded-md">
        <div className="flex flex-col items-center justify-between">
          {/* <img className='opacity-40' src={logo} alt="" /> */}
          <p className="text-2xl font-semibold text-yellowish">{fieldValues?.signup_lbl}</p>
        </div>

        <div className="flex flex-col gap-2 items-center w-full">
          <Input value={fieldValues?.phone_lbl} placeholder={"Phone"} inputClass="bg-transparent" />
          <Input
            value={fieldValues?.emailid_lbl}
            placeholder={"Email ID"}
            inputClass="bg-transparent"
          />
          <Input
            value={fieldValues?.password_lbl}
            placeholder={" Password"}
            inputClass="bg-transparent"
          />
          <Input
            value={fieldValues?.repeatePassword_lbl}
            placeholder={"Repeat Password"}
            inputClass="bg-transparent"
          />
          <p className="text-white text-[8px] opacity-80 font-bold">
            {fieldValues?.termsandcond_lbl}
          </p>
        </div>

        <div className="w-full px-5">
          <SignInButton Label={fieldValues?.createAccount_btn} style={"bg-yellow-300"} />
          <p className="text-white text-[8px] py-1 font-semibold text-center">
            Already have an account?
            <span className="text-yellowish"> Login</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
