import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import ReactModal from "react-modal";

import { FaRecordVinyl, FaStop } from "react-icons/fa6";

import close from "../../assets/icons/close.svg";

const videoConstraints = {
  width: 400,
  height: 800,
  facingMode: "user",
};

const WebCamModal = ({
  isOpen = false,
  onClose,
  handleVideoSave,
  onCaptureImage,
  type = "video",
}) => {
  const mediaRecorderRef = useRef(null);
  const webcamRef = useRef(null);

  const [capturing, setCapturing] = useState(false);
  const [startUpload, setStartUpload] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const byteString = atob(imageSrc.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/jpeg" });
    const file = new File([blob], "captured-image.jpg", { type: "image/jpeg" });

    onCaptureImage(file);
    onClose(false)
  }, [webcamRef]);

  const handleReset = useCallback(() => {
    setRecordedChunks([]);
    setCapturing(false);
    setStartUpload(false);
  }, []);

  const onSave = () => {
    const blob = new Blob(recordedChunks, {
      type: "video/webm",
    });
    const file = new File([blob], "react-webcam-stream-capture.mp4", {
      type: "video/mp4",
    });

    console.log("file78", file);
    handleVideoSave(file);
    handleReset();
    onClose(false);
  };

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data));
    }
  }, []);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setStartUpload(true);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose(false);
      }}
      contentLabel="Custom Modal"
      className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
      ariaHideApp={false}
    >
      <div className="MODAL-BODY h-[95%] relative flex items-center justify-center w-fit md:absolute rounded-2xl p-4 bg-blackishV2">
        <img
          className="cursor-pointer w-7 aspect-square absolute rounded-full bg-yellowish -top-2 -right-2 z-50"
          onClick={() => {
            onClose(false);
          }}
          src={close}
          alt=""
        />
        <Webcam
          style={{
            height: "100%",
            borderRadius: "10px",
          }}
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          mirrored={true}
        />
        {type === "video" ? (
          <>
            {" "}
            {capturing ? (
              <button
                className="text-red-200 p-6 rounded-full border-4 border-red-500 bg-red-800 bg-opacity-60 absolute bottom-[12%] md:bottom-[5%]"
                onClick={handleStopCaptureClick}
              >
                <FaStop size={20} />
              </button>
            ) : (
              <button
                className="text-red-200 p-6 rounded-full border-4 border-red-500 bg-red-800 bg-opacity-60 absolute bottom-[12%] md:bottom-[5%]"
                onClick={handleStartCaptureClick}
              >
                <FaRecordVinyl size={20} />
              </button>
            )}
          </>
        ) : (
          <button
            className="text-red-200 p-6 rounded-full border-4 border-red-500 bg-red-800 bg-opacity-60 absolute bottom-[12%] md:bottom-[5%]"
            onClick={capture}
          >
            <FaStop size={20} className="invisible" />
          </button>
        )}

        {startUpload && recordedChunks.length > 0 && (
          <button
            onClick={handleReset}
            className="absolute bottom-14 left-[25%] -translate-x-1/2 bg-red-600 drop-shadow-lg rounded-md px-4 py-2 text-white"
          >
            Reset
          </button>
        )}
        {startUpload && recordedChunks.length > 0 && (
          <button
            onClick={onSave}
            className="absolute bottom-14 right-[25%] translate-x-1/2 bg-red-600 drop-shadow-lg rounded-md px-4 py-2 text-white"
          >
            Save
          </button>
        )}
      </div>
    </ReactModal>
  );
};

export default WebCamModal;
