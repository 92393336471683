import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import avatar from "../../assets/icons/avatar.svg";
import Cross from "../../assets/icons/Cross.svg";
import Back from "../../assets/icons/Back.svg";
import { setIsNavigationAlertActiveAction } from "../../store/GlobalLoader/GlobalLoaderAction";

function SearchBar({ placeholder, value, onChangeSearchBar, showBackBtn = true }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const { isAuthenticated, logout } = useAuth0();
  const { userInfo } = useSelector((state) => state.user);
  const { showNavigationAlert } = useSelector((state) => state.globalLoader);

  const onLogout = () => {
    console.log("Logged out");
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  const onBack = () => {
    if (showNavigationAlert) {
      dispatch(setIsNavigationAlertActiveAction(true));
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    const eventHandler = (e) => {
      let headerSideNavbar = document.querySelector("#headerSideNavbar");
      if (headerSideNavbar && !headerSideNavbar?.contains(e.target)) {
        setShowMenu((preState) => !preState);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  return (
    <div className="w-full flex  items-center justify-between mb-4">
      {showBackBtn && <img className="cursor-pointer" src={Back} onClick={onBack} alt="back" />}
      <div className="relative">
        {/* <BsSearch className="text-white fill-[#909090] absolute top-3 left-3" />
        <input
          className="pl-8 text-[#e3e3e3] text-sm placeholder:text-sm placeholder:text-[#909090] md:w-80 bg-[#131313] p-2 border border-greyishV2 border-opacity-50 rounded-lg"
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={(e) => { if(onChangeSearchBar) onChangeSearchBar(e) }}
        /> */}
      </div>
      <div className="flex gap-4">
        <div className="relative z-50">
          <img
            className="cursor-pointer w-[50px] h-[50px] object-cover object-top overflow-hidden rounded-full"
            onClick={() => setShowMenu((preState) => !preState)}
            src={userInfo?.profilePicUrl ?? avatar}
            alt=""
          />
          {showMenu && (
            <div
              className="absolute w-[380px] rounded-2xl bg-[#1F1F1F] right-0 top-16 shadow-xl p-6 z-[101]"
              id="headerSideNavbar"
            >
              <div className="flex justify-between">
                <p className="text-xs text-yellow-400 font-medium">Menu</p>
                <img
                  className="cursor-pointer"
                  src={Cross}
                  onClick={() => setShowMenu((preState) => !preState)}
                  alt="close-button"
                />
              </div>
              <ul className="pt-6 flex flex-col gap-4">
                <li
                  className="text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer"
                  onClick={() => {
                    if (isAuthenticated) {
                      navigate("/profile");
                      setShowMenu((preState) => !preState);
                    } else {
                      localStorage.removeItem("token");
                      navigate("/login");
                    }
                  }}
                >
                  My Profile
                </li>
                <li
                  className="text font-normal text-white text-opacity-50 hover:text-opacity-100 cursor-pointer"
                  onClick={() => onLogout()}
                >
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
