import React, { useState, useRef } from "react";
import ReactModal from "react-modal";

import close from "../../assets/icons/close.svg";
import { AudioRecorder } from "react-audio-voice-recorder";

const AudioRecorderModal = ({ isOpen, onClose, onSave }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose(false);
      }}
      contentLabel="Custom Modal"
      className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
      ariaHideApp={false}
    >
      <div className="MODAL-BODY h-fit relative flex flex-col items-center justify-center w-fit md:absolute rounded-2xl p-8 bg-blackishV2 text-white">
        <img
          className="cursor-pointer w-7 aspect-square absolute rounded-full bg-yellowish -top-2 -right-2 z-50"
          onClick={() => {
            onClose(false);
          }}
          src={close}
          alt=""
        />

        <h2 className="text-2xl font-semibold mb-6">Audio Recorder</h2>

        <AudioRecorder
          onRecordingComplete={(blob) => {
            const audioFile = new File([blob], "audio-capture.webm", {
              type: "audio/webm",
            });
            onSave(audioFile);
            onClose();
          }}
          showVisualizer={true}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          downloadFileExtension="webm"
        />
      </div>
    </ReactModal>
  );
};

export default AudioRecorderModal;
