import React, { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";

const DropdownField = ({
  tooltipId,
  tooltipText,
  labelText,
  labelColor,
  errorMessage,
  placeholder,
  isFieldRequired,
  htmlFor,
  name,
  value,
  handleInputChange,
  isSubmitted,
  validateFunction,
  options = [],
  defaultValue,
  width,
}) => {
  const [ifInvalid, setIfInvalid] = useState(false);
  useEffect(() => {
    if (value?.length > 0) {
      setIfInvalid(false);
    }
  }, [value]);
  return (
    <div className="relative flex flex-col">
      {labelText && <label
        htmlFor={htmlFor}
        style={{
          color: `${
            isSubmitted && !validateFunction(value) && isFieldRequired
              ? "border-red-500"
              : labelColor
          }`,
        }}
        className="text-greyish text-[12px] flex items-center gap-2"
      >
        {labelText}
        {isFieldRequired ? <span className="text-red-500">*</span> : ""}
        {tooltipId && tooltipText && (
          <FaInfoCircle data-tooltip-id={tooltipId} data-tooltip-content={tooltipText} />
        )}
      </label>}
      <select
        id={name}
        name={name}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        onInvalid={(e) => {
          e.preventDefault();
          setIfInvalid(true);
          e.target.setCustomValidity("");
        }}
        defaultValue={defaultValue}
        onInput={(e) => {
          if (isSubmitted && !validateFunction(value) && isFieldRequired) {
            setIfInvalid(false);
          }
        }}
        className={`focus:outline-none  bg-blackish p-2 text-white text-[14px] font-medium leading-4 mt-2 rounded-xl h-11 border border-greyishV2 ${
          (isSubmitted && !validateFunction(value) && isFieldRequired) || ifInvalid
            ? "border-red-500"
            : ""
        } ${width}`}
      >
        {placeholder ? (
          <option value="placeholder" disabled>
            {placeholder}
          </option>
        ) : (
          ""
        )}
        {options.map((option) => (
          <option key={option.value} value={option.shortCode}>
            {option.roleName}
          </option>
        ))}
      </select>
      {isSubmitted && !validateFunction(value) && isFieldRequired && (
        <p className="text-red-500  relative text-[13px] left-0 top-[-10px]">{errorMessage}</p>
      )}
      {ifInvalid && (
        <p className="text-red-500  relative text-[13px] left-0 top-[-10px]">{errorMessage}</p>
      )}
    </div>
  );
};

export default DropdownField;
