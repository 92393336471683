import logo from "../../../../assets/icons/logo.png";
import userImg from "../../../../assets/images/profilepic.png";
import Screenshotallmenu from "../../../../assets/images/Screenshotallmenu.png";
import Screenshotmenu1 from "../../../../assets/images/Screenshotmenu1.png";
import screenshot2 from "../../../../assets/images/Screenshot2.png";
import screenshot3 from "../../../../assets/images/Screenshot3.png";
import screenshot4 from "../../../../assets/images/Screenshot4.png";
import screenshot5 from "../../../../assets/images/Screenshot5.png";
import InputsOfText from "../../../InputFields/InputsOfText";
import { AddLanguagesHooks } from "../../../../screens/Languages/hooks/LanguagesHooks";

const MenuPreview = (fieldValues) => {
  console.log(fieldValues, "teti");
  const { setShowData, showData } = AddLanguagesHooks();

  return (
    <div className="flex flex-col h-full relative bg-zinc-900">
      <div className="bg-zinc-900 absolute bottom-1 left-0 right-0 rounded-t-xl z-10">
        <img src={Screenshotmenu1} />
        <div className="flex flex-row gap-2 text-[8px] text-white justify-around">
          <p className="ml-1">{fieldValues?.fieldValues?.home_lbl}</p>
          <p className="ml-1">{fieldValues?.fieldValues?.curriculum_lbl}</p>
          <p className="ml-1">{fieldValues?.fieldValues?.my_fav_lbl}</p>
          <p className="ml-1">{fieldValues?.fieldValues?.testimonials_lbl}</p>
          <p className="ml-1">{fieldValues?.fieldValues?.give_lbl}</p>
          <p className="ml-1">{fieldValues?.fieldValues?.menu_lbl}</p>
        </div>
      </div>

      <div className="flex flex-col small-bar  overflow-auto pb-14">
        <div className="flex flex-row gap-2 pt-8 px-3 items-center">
          <img className="w-10 " src={logo} />
          <div className="w-24 h-7 bg-slate-50 opacity-40 rounded-3xl"></div>
          <img className="w-8 " src={logo} />
          <img className="w-8 " src={userImg} />
        </div>

        <div className="flex flex-col justify-center ml-20 rounded-l-3xl pb-7 mt-4 bg-zinc-900 items-center pt-5">
          <div className="pb-3">
            <InputsOfText
              value={fieldValues?.fieldValues?.menu_lbl}
              inputStyle={"text-yellowish w-40 font-bold text-[15px]"}
            />
          </div>

          <InputsOfText
            value={fieldValues?.fieldValues?.my_profile_lbl}
            inputStyle={"text-slate-200 w-40 font-bold text-[12px]"}
          />

          <InputsOfText
            value={fieldValues?.fieldValues?.my_favourites_lbl}
            inputStyle={"text-slate-200 w-40 font-bold text-[12px]"}
          />

          <InputsOfText
            value={fieldValues?.fieldValues?.my_downloads_lbl}
            inputStyle={"text-slate-200 w-40 font-bold text-[12px]"}
          />
          <InputsOfText
            value={fieldValues?.fieldValues?.my_givings_lbl}
            inputStyle={"text-slate-200 w-40 font-bold text-[12px]"}
          />
          <InputsOfText
            value={fieldValues?.fieldValues?.help_n_supports}
            inputStyle={"text-slate-200 w-40 font-bold text-[12px]"}
          />
          <InputsOfText
            value={fieldValues?.fieldValues?.about_twg}
            inputStyle={"text-slate-200 w-40 font-bold text-[12px]"}
          />

          <InputsOfText
            value={fieldValues?.fieldValues?.home_lbl}
            inputStyle={"text-slate-200 w-40 font-bold text-[12px]"}
          />

          <InputsOfText
            value={fieldValues?.fieldValues?.curriculum_lbl}
            inputStyle={"text-slate-200 w-40 font-bold text-[12px]"}
          />

          <InputsOfText
            value={fieldValues?.fieldValues?.testimonials_lbl}
            inputStyle={"text-slate-200 w-40 font-bold text-[12px]"}
          />
          <InputsOfText
            value={fieldValues?.fieldValues?.give_lbl}
            inputStyle={"text-slate-200 w-40 font-bold text-[12px]"}
          />
        </div>
      </div>
    </div>
  );
};

export default MenuPreview;
