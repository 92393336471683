import React, { useEffect, useState } from "react";
import SearchBar from "../../components/universal/SearchBar";
import SquareCard from "../../components/universal/SquareCard";
import CustomCarousel from "../../components/universal/CustomCarousel";
import SingleTab from "../../components/universal/SingleTab";
import { AiOutlinePlus, AiOutlineReload } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import CurriculumHooks from "./hooks/CurriculumHooks";
import Navbar from "../../components/universal/Navbar";
import { curriculumDashboardAction } from "../../store/Dashboard/DashboardAction";
import { useDispatch } from "react-redux";
import squareIcon1 from "../../assets/icons/squareCard/icon1.svg";
import userIcon from "../../assets/icons/userIcon.svg";
import { FaDownload, FaRegArrowAltCircleLeft } from "react-icons/fa";
import ReactECharts from "echarts-for-react";
import DateFilter from "../../components/universal/DateFilter";
import { subDays } from "date-fns";
import { downloadExcel } from "../../utils/DownloadExcel";

function Curriculum() {
  // search bar component state with handler
  const dispatch = useDispatch();
  const { value, onChangeSearchBar } = CurriculumHooks();
  // tab clicking state for language table
  const [activeTab, setActiveTab] = useState("Trending");
  const [userTab, setUserTab] = useState("By Language");
  const [loading, setLoading] = useState(true);
  const [byLanguage, setByLanguage] = useState({ xAxis: [], yAxis: [] });
  const [byCountry, setByCountry] = useState({ xAxis: [], yAxis: [] });
  const [byLanguageData, setByLanguageData] = useState({ xAxis: [], yAxis: [] });
  const [trendingCurriculum, setTrendingCurriculum] = useState({ xAxis: [], yAxis: [] });
  const [mostLikedCurriculum, setMostLikedCurriculum] = useState({ xAxis: [], yAxis: [] });
  const [languageTabData, setLanguageTabData] = useState({ xAxis: [], yAxis: [] });
  const [animate, setAnimate] = useState(false);

  const navigate = useNavigate();

  const [filterRange, setFilterRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  console.log("mostLikedCurriculum", mostLikedCurriculum);
  console.log("languageTabData", languageTabData);
  console.log("byLanguageData", byLanguageData);

  const fetchReportsData = ({ startDate, endDate }) => {
    setLoading(true);
    const curriculumPromise = new Promise((resolve, reject) => {
      dispatch(
        curriculumDashboardAction({
          apiPayloadRequest: {
            startDate: startDate ?? null,
            endDate: endDate ?? null,
          },
          callback: (res) => {
            const data = res?.data ? res?.data : [];

            console.log("data", data);

            const tempByLanguageData = {
              xAxis: data
                ?.filter((item) => item.dash === "Language based Curriculum likes")[0]
                ?.data?.map((item) => ({ ...item, value: item?.count })),
              yAxis: data
                ?.filter((item) => item.dash === "Language based Curriculum likes")[0]
                ?.data?.map((item) => ({ ...item, name: item?._id, value: item?.count })),
            };

            console.log("tempByLanguageData", tempByLanguageData);

            setByLanguageData(tempByLanguageData);

            setByLanguage(tempByLanguageData);
            setByCountry({
              xAxis: data
                ?.filter((item) => item.dash === "Country based Curriculum Likes")[0]
                ?.data?.map((item) => ({ ...item, value: item?._id })),
              yAxis: data
                ?.filter((item) => item.dash === "Country based Curriculum Likes")[0]
                ?.data?.map((item) => ({ ...item, name: item?._id, value: item?.count })),
            });

            console.log(
              "first",
              data?.filter((item) => item.dash === "Country based Curriculum Likes")[0]
            );

            const tempTrendingCurriculum = {
              xAxis: data
                ?.filter((item) => item?.dash === "Trending Curriculum")[0]
                ?.data?.map((d) => ({ ...d, value: d?.recentViews })),
              yAxis: data
                ?.filter((item) => item?.dash === "Trending Curriculum")[0]
                ?.data?.map((d) => ({ ...d, value: d?.title })),
            };

            setLanguageTabData(tempTrendingCurriculum);
            setTrendingCurriculum(tempTrendingCurriculum);

            setMostLikedCurriculum({
              xAxis: data
                ?.filter((item) => item?.dash === "Most Like Curriculum")[0]
                ?.data?.map((d) => ({ ...d, value: d?.likeCount })),
              yAxis: data
                ?.filter((item) => item?.dash === "Most Like Curriculum")[0]
                ?.data?.map((d) => ({ ...d, value: d?.title })),
            });
            resolve();
          },
        })
      );
    });

    Promise.all([curriculumPromise]).finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchReportsData({
      startDate: filterRange?.[0]?.startDate,
      endDate: filterRange?.[0]?.endDate,
    });
  }, [filterRange]);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div
            className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4 relative"
            id="reportContainer"
          >
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <div className="flex justify-between items-center flex-1">
                <p className=" text-white text-3xl font-medium flex items-center gap-3">
                  Curriculum
                  <AiOutlineReload
                    className={`cursor-pointer text-2xl text-yellowish ${
                      loading ? "animate-spin" : ""
                    }`}
                    onClick={() =>
                      fetchReportsData({
                        startDate: filterRange?.[0]?.startDate,
                        endDate: filterRange?.[0]?.endDate,
                      })
                    }
                  />
                </p>
                <p className="flex gap-3 items-center">
                  <DateFilter range={filterRange} setRange={setFilterRange} />
                </p>
              </div>
              <div className="flex gap-4">
                <Link to={"/curriculum/all"}>
                  <button className=" text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                    See All Curriculum
                  </button>
                </Link>
                <Link to={"/curriculum/add"}>
                  <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
                    <AiOutlinePlus /> Create Curriculum
                  </button>
                </Link>
              </div>
            </div>

            <div className="flex gap-4 flex-wrap">
              <SquareCard
                heading={"No. of Trending Curriculums"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={trendingCurriculum?.xAxis?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(trendingCurriculum?.xAxis?.map((d) => ({
                      "Curriculum Name": d?.title,
                      "Total Likes": d?.value,
                    })) ?? []),
                  ]);
                }}
                trail={""}
                handleOnClick={() => {
                  setAnimate((prev) => !prev);
                  setActiveTab("Trending");
                  setLanguageTabData(trendingCurriculum);
                }}
              />
              <SquareCard
                heading={"No. of most liked Curriculums"}
                icon={userIcon}
                iconStyle={"h-11 w-11"}
                info={mostLikedCurriculum?.xAxis?.length}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(mostLikedCurriculum?.xAxis?.map((d) => ({
                      "Curriculum Name": d?.title,
                      "Total Likes": d?.value,
                    })) ?? []),
                  ]);
                }}
                trail={""}
                handleOnClick={() => {
                  setAnimate((prev) => !prev);
                  setActiveTab("Most Liked");
                  setLanguageTabData(mostLikedCurriculum);
                }}
              />
              {/* <SquareCard
                heading={"No. of most watched Curriculums"}
                icon={userIcon}
                iconStyle={"h-11 w-11"}
                info={mostWatchedVideos?.length || 0}
                trail={""}
                percentage={"0%"}
                handleOnClick={() => {
                  setAnimate((prev) => !prev);
                  setLanguageTabHeaders(mostWatchedVideosHeader);
                  setLanguageTabData(mostWatchedVideos);
                }}
              /> */}
            </div>
            <div className="grid grid-rows-1 md:grid-cols-6 grid-cols-1 flex-1 h-full gap-x-4 gap-y-4 my-4">
              <div className="md:col-span-3 row-span-1 bg-blackishV2 px-6 py-4 rounded-2xl flex justify-between">
                <div className="text-white w-full">
                  <div className="flex justify-between items-center w-full">
                    <div className="flex items-center gap-8">
                      <p className="text-lg font-semibold text-white">Curriculum Completions</p>
                    </div>
                    <div className="md:w-[15vw] w-full">
                      <div className="md:block hidden">
                        <CustomCarousel
                          rows={1}
                          cols={2}
                          gapX={0}
                          gapY={0}
                          // easier to pass this array like this rather than separating it
                          completeSliders={[
                            <SingleTab
                              activeTab={userTab}
                              tab={"By Language"}
                              tabClassName="whitespace-nowrap"
                            />,
                            <SingleTab
                              activeTab={userTab}
                              tab={"By Country"}
                              tabClassName="whitespace-nowrap"
                            />,
                          ]}
                          numberOfCardsShownInSingleSlide={3}
                          handleCardOnClick={(card) => {
                            setUserTab(card.props.tab);
                            switch (card.props.tab) {
                              case "By Language":
                                setByLanguageData(byLanguage);
                                break;
                              case "By Country":
                                setByLanguageData(byCountry);
                                break;
                              // add other tabs here
                              default:
                                break;
                            }
                          }}
                          topNext={"hidden"}
                          topPrev={"hidden"}
                        />
                      </div>
                      <div className="md:hidden block">
                        <CustomCarousel
                          rows={1}
                          cols={2}
                          gapX={0}
                          gapY={0}
                          // easier to pass this array like this rather than separating it
                          completeSliders={[
                            <SingleTab
                              activeTab={userTab}
                              tab={"By Language"}
                              tabClassName="whitespace-nowrap"
                            />,
                            <SingleTab
                              activeTab={userTab}
                              tab={"By Country"}
                              tabClassName="whitespace-nowrap"
                            />,
                          ]}
                          numberOfCardsShownInSingleSlide={2}
                          handleCardOnClick={(card) => {
                            setUserTab(card.props.tab);
                            switch (card.props.tab) {
                              case "By Language":
                                setByLanguageData(byLanguage);
                                break;
                              case "By Country":
                                setByLanguageData(byCountry);
                                break;
                              // add other tabs here
                              default:
                                break;
                            }
                          }}
                          topNext={"hidden"}
                          topPrev={"hidden"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="block relative">
                    <button
                      className="text-yellowish absolute top-2 right-2 z-50"
                      onClick={() => {
                        downloadExcel([
                          ...(byLanguageData?.yAxis?.map((d) => ({
                            "Curriculum Name": d?.name,
                            "Total Likes": d?.value,
                          })) ?? []),
                        ]);
                      }}
                    >
                      <FaDownload />
                    </button>
                    <ReactECharts
                      className="py-4 bg-blackish mt-5 !h-[350px]"
                      option={{
                        tooltip: {
                          trigger: "item",
                          formatter: "{b} ({c})",
                        },
                        series: [
                          {
                            name: "Language",
                            type: "pie",
                            radius: [20, 140],
                            center: ["50%", "50%"],
                            roseType: "area",
                            itemStyle: {
                              borderRadius: 5,
                            },
                            label: {
                              color: "#fff",
                            },
                            data: [...byLanguageData?.yAxis],
                            color: [
                              ...byLanguageData?.yAxis?.map(
                                (item, index) =>
                                  `rgba(239,188,81, ${(0.5 + Math.random() / 2).toFixed(2)})`
                              ),
                            ],
                          },
                        ],
                        responsive: true,
                      }}
                      notMerge={true}
                      lazyUpdate={true}
                      theme={"halloween"}
                      onChartReady={() => console.log("chart loaded")}
                    />
                  </div>
                </div>
              </div>
              <div className="md:col-span-3 row-span-1 bg-blackishV2 px-6 py-4 rounded-2xl flex flex-col gap-4">
                <div className="flex justify-between md:items-center md:flex-row flex-col gap-4">
                  <p className="text-lg font-semibold text-white">Curriculum List</p>
                  <div className="md:w-[20vw] w-full">
                    <CustomCarousel
                      rows={1}
                      cols={2}
                      gapX={0}
                      gapY={0}
                      // easier to pass this array like this rather than separating it
                      completeSliders={[
                        <SingleTab activeTab={activeTab} tab={"Trending"} />,
                        <SingleTab activeTab={activeTab} tab={"Most Liked"} />,
                      ]}
                      numberOfCardsShownInSingleSlide={2}
                      handleCardOnClick={(card) => {
                        setActiveTab(card.props.tab);
                        switch (card.props.tab) {
                          case "Trending":
                            setLanguageTabData(trendingCurriculum);
                            break;
                          case "Most Liked":
                            setLanguageTabData(mostLikedCurriculum);
                            break;
                          // add other tabs here
                          default:
                            break;
                        }
                      }}
                      topNext={"top-3"}
                      topPrev={"top-9"}
                    />
                  </div>
                </div>
                <div
                  className={`${
                    animate
                      ? "absolute bg-blackishV2 w-full h-full left-0  top-0 p-5 rounded-lg z-50 transition-all duration-500 ease-out overflow-y-auto"
                      : "transition-all duration-500 ease-in-out"
                  }`}
                >
                  {animate && (
                    <button
                      className="flex gap-2 font-bold text-xs px-4 py-2 bg-yellowish rounded-md"
                      onClick={() => setAnimate((prev) => !prev)}
                    >
                      <FaRegArrowAltCircleLeft size={16} /> <p>Back</p>
                    </button>
                  )}

                  <div className="w-full h-full relative">
                    <button
                      className="text-yellowish absolute top-2 right-2 z-50"
                      onClick={() => {
                        downloadExcel([
                          ...(languageTabData?.xAxis?.map((d) => ({
                            "Curriculum Name": d?.title,
                            "Total Likes": d?.value,
                          })) ?? []),
                        ]);
                      }}
                    >
                      <FaDownload />
                    </button>
                    <ReactECharts
                      key={languageTabData?.yAxis?.length}
                      className="py-4 bg-blackish mt-5"
                      onEvents={{
                        click: (e) => {
                          if (e?.componentType === "series") {
                            navigate("/curriculum/add", {
                              state: {
                                _id: e?.data?._id || "",
                              },
                            });
                          }
                        },
                      }}
                      option={{
                        resposive: true,
                        // maintainAspectRatio: true,
                        tooltip: {
                          trigger: "axis",
                          axisPointer: {
                            type: "shadow",
                          },
                        },
                        grid: {
                          left: "3%",
                          right: "4%",
                          bottom: "3%",
                          containLabel: true,
                        },
                        yAxis: [
                          {
                            type: "category",
                            data: [...languageTabData?.yAxis],
                            axisLabel: { width: 100, overflow: "truncate" },
                            axisTick: { show: false },
                            // triggerEvent: true,
                          },
                        ],
                        xAxis: [
                          {
                            // triggerEvent: true,
                            type: "value",
                            splitLine: {
                              show: false,
                              lineStyle: {
                                type: "dashed",
                              },
                            },
                          },
                        ],
                        series: [
                          {
                            name: "Most Liked",
                            type: "bar",
                            barWidth: "65%",
                            data: [...languageTabData?.xAxis],
                            color: ["#EFBC51"],
                            itemStyle: {
                              normal: {
                                shadowColor: "rgb(239,188,81, 0.3)",
                                shadowBlur: 15,
                              },
                            },
                          },
                        ],
                        graph: {},
                      }}
                      notMerge={true}
                      lazyUpdate={true}
                      theme={"halloween"}
                      onChartReady={() => console.log("chart loaded")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Curriculum;
