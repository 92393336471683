import { useState } from "react";
import { useDispatch } from "react-redux";
import { deletePromiseAction, readPromiseAction } from "../../../store/TWG/TWGAction";
import Toastiy from "../../../components/universal/Toastiy";

const TwgHooks = () => {
  const [value, setValue] = useState("");
  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  return { value, onChangeSearchBar };
};

export default TwgHooks;

export const AllTwgHooks = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const [reloadData, setReloadData] = useState(false);

  const fetchAllTwg = (page, limit, searchCriteria = {}, callbackFn) => {
    console.log(
      "page",
      page,
      "limit",
      limit,
      "searchCriteria",
      searchCriteria,
      "callbackFn",
      callbackFn
    );
    dispatch(
      readPromiseAction({
        apiPayloadRequest: {
          page: page,
          limit: limit,
          searchCriteria: searchCriteria,
        },
        callback: (Data) => {
          if (Data?.type === 1) {
            if (callbackFn)
              callbackFn({
                data: Data?.data?.map((d) => ({ ...d, views: d?.views ?? 0 })),
                total: Data?.totalRecords ?? 0,
              });
          } else if (callbackFn) callbackFn({ data: [], total: Data?.totalRecords ?? 0 });
        },
      })
    );
  };

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };

  const handleDelete = (row, index) => {
    dispatch(
      deletePromiseAction({
        apiPayloadRequest: { _id: row?._id },
        callback: (res) => {
          if (res?.type === 1) {
            Toastiy("Promise Deleted Successfully", res?.type);
            setReloadData(true);
          } else {
            Toastiy("Issue performing the operation, contact admin!!", res?.type);
          }
        },
      })
    );
  };

  return { value, onChangeSearchBar, handleDelete, reloadData, setReloadData, fetchAllTwg };
};

// export const AddTwgHooks = () => {
//   const [value, setValue] = useState("");
//   const onChangeSearchBar = (e) => {
//     setValue(e.target.value);
//   };

//   return { value, onChangeSearchBar };
// };

export const AddTwgHooks = () => {
  // search logic here
  const [value, setValue] = useState("");

  const onChangeSearchBar = (e) => {
    setValue(e.target.value);
  };
  // add category logic here

  const languages = [
    "english",
    "hindi",
    "tamil",
    "telugu",
    "Malayalam",
    "Spanish",
    "Marathi",
    "Urdu",
    "Oriya",
    "kanada",
    "Bengali",
  ]; // List of languages
  // Set the first language as active

  const [inputs, setInputs] = useState(
    languages.reduce((acc, lang) => {
      acc[lang] = {
        categoryName: "",
        categoryCode: "",
        categoryIcon: null,
        mobileLayout: "horizontal",
        categoryDescription: "",
        sendTo: "vertical",
      };
      return acc;
    }, {})
  );

  const handleInputChange = (language, field, value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [language]: {
        ...prevInputs[language],
        [field]: value,
      },
    }));
  };

  const handleFileInputChange = (language, field, file) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [language]: {
        ...prevInputs[language],
        [field]: file,
      },
    }));
  };

  const handleCategoryDescriptionChange = (e) => {
    // handleInputChange(activeTab, "categoryDescription", e.target.value);
  };

  const handleSave = () => {
    // Implement your save logic here
    console.log("Inputs saved:", inputs);
  };

  return {
    value,
    onChangeSearchBar,
    languages,
    inputs,
    handleInputChange,
    handleFileInputChange,
    handleCategoryDescriptionChange,
    handleSave,
  };
};
