import * as actionTypes from "../ActionTypes";

// export const actionUpdateGlobalLoaderSagaAction = (payload) => {
//   return {
//     type: actionTypes.APP_LOADING,
//     payload: payload,
//   };
// };

export const setLanguagesList = (payload) => {
  return {
    type: actionTypes.SET_LANGUAGES_LIST,
    payload: payload,
  };
};

export const setActiveLanguage = (payload) => {
  return {
    type: actionTypes.SET_ACTIVE_LANGUAGE,
    payload: payload,
  };
};

export const getCountryStateData = (payload) => {
  return {
    type: actionTypes.DASHBOARD_ACTION_GET_NEW_DATA,
    payload: payload,
  };
};

export const categoriesDashboardAction = (payload) => {
  return {
    type: actionTypes.CATEGORIES_DASHBOARD_REQUEST,
    payload: payload,
  };
};

export const curriculumDashboardAction = (payload) => {
  return {
    type: actionTypes.CURRICULUM_DASHBOARD_REQUEST,
    payload: payload,
  };
};
