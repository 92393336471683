import React, { useState } from "react";

import Profile from "../../assets/images/profilepic.png";

import TextInputField from "../InputFields/TextInputField";
import SearchBar from "../universal/SearchBar";
import Navbar from "../universal/Navbar";
import ImageUpload from "../universal/ImageUpload";
import { ProfileHooks } from "./Hooks/ProfileHooks";

const MyProfile = () => {
  const {
    value,
    editProfile,
    setEditProfile,
    profileValues,
    handleChange,
    onChangeSearchBar,
    imageUrl,
    setImageUrl,
    handleUpdateProfile,
  } = ProfileHooks();

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4">
            <div className="flex gap-4 items-center justify-center pb-10">
              <div className="p-6 bg-[#1F1F1F] w-[50vw] rounded-3xl py-10">
                <div className="flex justify-between">
                  <p className="text-white text-2xl font-semibold">
                    {profileValues?.firstName} {profileValues.lastName}
                  </p>
                  <button
                    className="py-3 px-6 rounded-xl text-sm font-semibold border border-yellow-400 text-yellow-400"
                    onClick={() => {
                      editProfile
                        ? setEditProfile((prevState) => !prevState)
                        : handleUpdateProfile();
                    }}
                  >
                    {editProfile ? " Update Profile" : "Confirm"}
                  </button>
                </div>
                <div className="flex flex-col items-center justify-center p-4 gap-3">
                  <ImageUpload
                    placeholderImage={profileValues?.profilePicUrl ?? Profile}
                    imageUrl={imageUrl}
                    setImageUrl={(value) => {
                      setImageUrl(value);
                      handleChange({ target: { name: "profilePicUrl", value: value } });
                    }}
                    isUpload={!editProfile}
                  />
                  {/* <p className='text-xs text-white text-opacity-50'> Upload Picture</p> */}
                </div>
                <div className="flex justify-between gap-8 mt-4">
                  <TextInputField
                    name={"firstName"}
                    labelText={"First Name"}
                    placeholder={"First Name"}
                    value={profileValues.firstName}
                    handleInputChange={handleChange}
                    disabled={editProfile}
                  />
                  <TextInputField
                    name={"lastName"}
                    labelText={"Last Name"}
                    placeholder={"Last Name"}
                    value={profileValues.lastName}
                    handleInputChange={handleChange}
                    disabled={editProfile}
                  />
                </div>
                <div className="flex mt-6">
                  <TextInputField
                    name={"aboutMe"}
                    labelText={"About me"}
                    placeholder={"About me"}
                    value={profileValues.aboutMe}
                    handleInputChange={handleChange}
                    disabled={editProfile}
                  />
                </div>
                <div className="flex justify-between gap-8 mt-4">
                  <TextInputField
                    name={"mobile"}
                    labelText={"Phone Number"}
                    placeholder={"Phone Number"}
                    value={profileValues.mobile}
                    handleInputChange={handleChange}
                    disabled={editProfile}
                  />
                  <TextInputField
                    name={"email"}
                    labelText={"Email ID"}
                    placeholder={"Email ID"}
                    value={profileValues.email}
                    handleInputChange={handleChange}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
