import React, { useEffect, useState } from "react";
import LanguagesHooks from "./hooks/LanguagesHooks";
import SearchBar from "../../components/universal/SearchBar";
import SquareCard from "../../components/universal/SquareCard";
import squareIcon1 from "../../assets/icons/squareCard/icon1.svg";
import LanguageIcon from "../../assets/icons/languageIcon.svg";
import CustomCarousel from "../../components/universal/CustomCarousel";
import { languagesHeaders, userByLanguageHeader } from "./Constants";
import SingleTab from "../../components/universal/SingleTab";
import CustomTable from "../../components/universal/CustomTable";
import { AiOutlinePlus, AiOutlineReload } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/universal/Navbar";
import { useDispatch } from "react-redux";
import { languageDashboardAction, readLanguageAction } from "../../store/Languages/LanguageAction";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { FaDownload } from "react-icons/fa6";
import ReactECharts from "echarts-for-react";
import DateFilter from "../../components/universal/DateFilter";
import { subDays } from "date-fns";
import { downloadExcel } from "../../utils/DownloadExcel";

function Languages() {
  // search bar component state with handler
  const { value, onChangeSearchBar } = LanguagesHooks();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // tab clicking state for language table
  const [activeTab, setActiveTab] = useState("Languages");
  const [loading, setLoading] = useState(true);
  const [userCountByLang, setUserCountByLang] = useState([]);
  const [userSignUpVsTimePeriod, setUserSignUpVsTimePeriod] = useState({ xAxis: [], yAxis: [] });
  const [langActiveVsInactive, setLangActiveVsInactive] = useState([]);

  const [languages, setLanguages] = useState([]);

  const [languageTabHeaders, setLanguageTabHeaders] = useState(languagesHeaders);
  const [languageTabData, setLanguageTabData] = useState();
  const [totalNoOfLang, setTotalNoOfLang] = useState(0);

  const [animate, setAnimate] = useState(false);
  const [animateLanguageGraph, setAnimateLanguageGraph] = useState(false);

  console.log("languageTabData", languageTabData);
  console.log("languages2", languages);
  console.log("userCountByLang", userCountByLang);

  const [filterRange, setFilterRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const fetchReportsData = async ({ startDate, endDate }) => {
    setLoading(true);
    const languagePromise = new Promise((resolve, reject) => {
      dispatch(
        languageDashboardAction({
          apiPayloadRequest: {
            startDate: startDate ?? null,
            endDate: endDate ?? null,
          },
          callback: (res) => {
            if (res?.type === 1) {
              const data = res?.data;
              setUserCountByLang(
                data.filter((item) => item.dash === "Users count by language")[0].data
              );
              setUserSignUpVsTimePeriod({
                xAxis: data
                  .filter((item) => item.dash === "Users signup vs time period")[0]
                  .data?.map((d) => ({ ...d, value: d?._id?.date })),
                yAxis: data
                  .filter((item) => item.dash === "Users signup vs time period")[0]
                  .data?.map((d) => ({ ...d, value: d?.count })),
              });
              setLangActiveVsInactive(
                data.filter((item) => item.dash === "Language Counts by active or inactive")[0].data
              );
              let languageCount = 0;
              data
                .filter((item) => item.dash === "Language Counts by active or inactive")[0]
                .data.map((item) => (languageCount = languageCount + item.count));
              setTotalNoOfLang(languageCount);
            }
            resolve();
          },
        })
      );
    });
    const readLanguagePromise = new Promise((resolve, reject) => {
      dispatch(
        readLanguageAction({
          apiPayloadRequest: {
            startDate: startDate ?? null,
            endDate: endDate ?? null,
          },
          callback: (res) => {
            if (res?.type === 1) {
              setLanguages(res?.data);
              setLanguageTabData(res?.data);
            }
            resolve();
          },
        })
      );
    });

    Promise.all([readLanguagePromise, languagePromise]).finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchReportsData({
      startDate: filterRange?.[0]?.startDate,
      endDate: filterRange?.[0]?.endDate,
    });
  }, [filterRange]);

  return (
    <div className="App bg-[#191919] h-fit min-h-screen flex p-4">
      <Navbar />
      <div className="flex-1 md:pl-32 md:pt-0 pt-20">
        <div className="flex flex-col gap-4 h-full">
          <SearchBar placeholder={"Search"} value={value} onChangeSearchBar={onChangeSearchBar} />
          <div
            className="MAIN-CONTENT flex-1 flex flex-col h-full gap-4 relative"
            id="reportContainer"
          >
            <div className="flex w-full justify-between md:items-center md:flex-row flex-col gap-4 md:mb-0 mb-2">
              <div className="flex justify-between items-center flex-1">
                <p className=" text-white text-3xl font-medium flex items-center gap-3">
                  Languages
                  <AiOutlineReload
                    className={`cursor-pointer text-2xl text-yellowish ${
                      loading ? "animate-spin" : ""
                    }`}
                    onClick={() =>
                      fetchReportsData({
                        startDate: filterRange?.[0]?.startDate,
                        endDate: filterRange?.[0]?.endDate,
                      })
                    }
                  />
                </p>
                <p className="flex gap-3 items-center">
                  <DateFilter range={filterRange} setRange={setFilterRange} />
                </p>
              </div>
              <div className="flex gap-4">
                <Link to={"/languages/all"}>
                  <button className=" text-[13px] text-greyish border px-4 py-2 border-greyish bg-blackish rounded-xl">
                    See All Languages
                  </button>
                </Link>
                <Link to={"/languages/add"}>
                  <button className="flex items-center gap-2 text-[13px] text-yellowish border px-4 py-2 border-yellowish bg-blackish rounded-xl">
                    <AiOutlinePlus /> Add New Language
                  </button>
                </Link>
              </div>
            </div>

            <div className="flex gap-4 flex-wrap">
              <SquareCard
                heading={"Total No. of Languages"}
                icon={squareIcon1}
                iconStyle={"h-11 w-11"}
                info={totalNoOfLang}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(languages?.map((d) => ({
                      "Language Name": d?.language_name,
                      "Language Code": d?.language_code,
                    })) ?? []),
                  ]);
                }}
                trail={""}
                handleOnClick={() => {
                  // setAnimateLanguageGraph((prev) => !prev);
                  navigate("/languages/all");
                }}
              />
              <SquareCard
                heading={"Active Languages"}
                icon={LanguageIcon}
                iconStyle={"h-11 w-11"}
                info={langActiveVsInactive?.filter((item) => item._id === 1)[0]?.count || 0}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(languages
                      ?.filter((d) => d?.status === 1)
                      ?.map((d) => ({
                        "Language Name": d?.language_name,
                        "Language Code": d?.language_code,
                      })) ?? []),
                  ]);
                }}
                trail={""}
                handleOnClick={() => {
                  navigate("/languages/all?filter=Active");
                }}
              />
              <SquareCard
                heading={"Inactive Languages"}
                icon={LanguageIcon}
                iconStyle={"h-11 w-11"}
                info={langActiveVsInactive?.filter((item) => item._id === 0)[0]?.count || 0}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(languages
                      ?.filter((d) => d?.status === 0)
                      ?.map((d) => ({
                        "Language Name": d?.language_name,
                        "Language Code": d?.language_code,
                      })) ?? []),
                  ]);
                }}
                trail={""}
                handleOnClick={() => {
                  navigate("/languages/all?filter=In-Active");
                }}
              />
              <SquareCard
                heading={"No of Languages with Users"}
                icon={LanguageIcon}
                iconStyle={"h-11 w-11"}
                info={userCountByLang.length || 0}
                downloadIcon={true}
                downloadIconClickHandle={() => {
                  downloadExcel([
                    ...(userCountByLang?.map((d) => ({
                      Language: d?._id,
                      "Total Users": d?.count,
                    })) ?? []),
                  ]);
                }}
                trail={""}
                handleOnClick={() => {
                  setAnimate((prev) => !prev);
                  setActiveTab("No. of Users");
                  setLanguageTabHeaders(userByLanguageHeader);
                  setLanguageTabData(userCountByLang);
                }}
              />
            </div>
            <div className="grid grid-rows-1 md:grid-cols-6 grid-cols-1 flex-1 h-full gap-x-4 gap-y-4 my-4">
              <div className="md:col-span-3 row-span-1 bg-blackishV2 px-6 py-4 rounded-2xl flex justify-between">
                <div className="text-white w-full">
                  <div className="block relative">
                    {/* <CustomLineChart
                      customHeight="300"
                      customWidth="600"
                      titles={userSignUpVsTimePeriod.map((item) => item._id.date)}
                      data={userSignUpVsTimePeriod.map((item) => item.count)}
                    /> */}
                    <button
                      className="text-yellowish absolute top-2 right-2 z-50"
                      onClick={() => {
                        downloadExcel([
                          ...(userCountByLang?.map((d) => ({
                            Language: d?._id,
                            "Total Users": d?.count,
                          })) ?? []),
                        ]);
                      }}
                    >
                      <FaDownload />
                    </button>

                    <ReactECharts
                      className="py-4 bg-blackish !h-[40 0px]"
                      option={{
                        title: {
                          text: "Users count by language",
                          textStyle: {
                            color: "#fff",
                          },
                        },
                        tooltip: {
                          trigger: "item",
                          formatter: "{b} ({c})",
                        },
                        xAxis: {
                          type: "category",
                          boundaryGap: false,
                          axisTick: { show: false },
                          axisLabel: {
                            interval: 0,
                            rotate: 30,
                          },
                          data: [...(userCountByLang?.map((d) => d?._id ?? "") ?? [])],
                        },
                        yAxis: {
                          type: "value",
                          axisLabel: { show: false },
                          axisTick: { show: false },
                          splitLine: { show: false },
                        },
                        series: [
                          {
                            data: [...(userCountByLang?.map((d) => d?.count ?? 0) ?? [])],
                            type: "bar",
                            showBackground: true,
                            color: ["rgba(239,188,81)"],
                            backgroundStyle: {
                              color: "rgba(180, 180, 180, 0.2)",
                            },
                          },
                        ],
                      }}
                      notMerge={true}
                      lazyUpdate={true}
                      theme={"halloween"}
                      onChartReady={() => console.log("chart loaded")}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`md:col-span-3 row-span-1  bg-blackishV2 px-6 py-4 rounded-2xl flex flex-col gap-4`}
              >
                <div className="flex justify-between md:items-center md:flex-row flex-col gap-4">
                  <p className="text-lg font-semibold text-white">Languages</p>
                  <div className="md:w-[28vw] w-full">
                    <div className={"md:block hidden "}>
                      <CustomCarousel
                        rows={1}
                        cols={3}
                        gapX={0}
                        gapY={0}
                        // easier to pass this array like this rather than separating it
                        completeSliders={[
                          <SingleTab activeTab={activeTab} tab={"Languages"} />,
                          <SingleTab activeTab={activeTab} tab={"No. of Users"} />,
                        ]}
                        numberOfCardsShownInSingleSlide={3}
                        handleCardOnClick={(card) => {
                          setActiveTab(card.props.tab);
                          switch (card.props.tab) {
                            case "Languages":
                              setLanguageTabHeaders(languagesHeaders);
                              setLanguageTabData(languages);
                              break;

                            case "No. of Users":
                              setLanguageTabHeaders(userByLanguageHeader);
                              setLanguageTabData(userCountByLang);
                              break;
                            // add other tabs here
                            default:
                              break;
                          }
                        }}
                        topNext={"top-3"}
                        topPrev={"top-9"}
                      />
                    </div>
                    <div className="md:hidden block">
                      <CustomCarousel
                        rows={1}
                        cols={2}
                        gapX={0}
                        gapY={0}
                        // easier to pass this array like this rather than separating it
                        completeSliders={[
                          <SingleTab activeTab={activeTab} tab={"Languages"} />,
                          <SingleTab activeTab={activeTab} tab={"No. of Users"} />,
                        ]}
                        numberOfCardsShownInSingleSlide={2}
                        handleCardOnClick={(card) => {
                          setActiveTab(card.props.tab);
                          switch (card.props.tab) {
                            case "Languages":
                              // change data for trending tab
                              break;
                            // add other tabs here
                            default:
                              break;
                          }
                        }}
                        topNext={"top-3"}
                        topPrev={"top-9"}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    animate
                      ? "absolute bg-blackishV2 w-full h-full left-0  top-0 p-5 rounded-lg z-50 transition-all duration-500 ease-out overflow-y-auto"
                      : "transition-all duration-500 ease-in-out"
                  }`}
                >
                  {animate && (
                    <button
                      className="flex gap-2 font-bold text-xs px-4 py-2 bg-yellowish rounded-md"
                      onClick={() => setAnimate((prev) => !prev)}
                    >
                      <FaRegArrowAltCircleLeft size={16} /> <p>Back</p>
                    </button>
                  )}
                  <CustomTable
                    searchFor={"Languages"}
                    data={languageTabData}
                    headers={languageTabHeaders}
                    width={"w-24"}
                    handleEyeClick={(row) => {
                      console.log(row, "Calling");
                      navigate("/languages/add", {
                        state: {
                          targetLanguage: row?.language_name + " - " + row?.language_code,
                        },
                      });
                    }}
                    heightDesktop={animate ? "h-full" : "h-[32vh]"}
                    // giving extra width to 2nd column
                    nthChildWidth={"[&>*:nth-child(2)]:w-40"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Languages;
