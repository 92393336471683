import * as actionTypes from "../ActionTypes";

const INITIAL = {
  userInfo: {},
  accessToken: null,
};

const UsersReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_PROFILE_INFO:
      return { ...state, userInfo: action.payload };
    case actionTypes.SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };
    default:
      return state;
  }
};

export default UsersReducer;
