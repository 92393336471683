import React, { useEffect, useRef, useState } from "react";
import { FaImage, FaRegImage, FaVideo } from "react-icons/fa6";
import { IoCamera } from "react-icons/io5";
import { LuGalleryVerticalEnd } from "react-icons/lu";
import WebCamModal from "./WebCamModal";

const UploadImageButton = ({ handleImageUpload, handleImageCapture }) => {
  const [isActive, setIsActive] = useState(false);
  const [cameraModalOpen, setCameraModalOpen] = useState(false);

  const containerRef = useRef();
  const imageInputRef = useRef();

  useEffect(() => {
    const eventHandler = (e) => {
      if (containerRef?.current && !containerRef?.current?.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  return (
    <>
      <div className="relative">
        <button
          className="px-4 py-2 rounded-xl border border-white/30 text-white"
          onClick={() => setIsActive(true)}
        >
          <FaRegImage className="text-xl" />
        </button>
        <input
          ref={imageInputRef}
          className="hidden"
          multiple={true}
          type="file"
          accept="images/*"
          onChange={handleImageUpload}
        />
        <ul
          className={`${
            isActive ? "flex flex-col gap-1" : "hidden"
          } absolute -top-2 -translate-y-full text-xs transition-all duration-300 z-50`}
          ref={containerRef}
          onClick={() => setIsActive(false)}
        >
          <li
            onClick={() => imageInputRef.current.click()}
            className="flex items-center gap-2 bg-yellowish text-blackishV2 px-2.5 py-2 w-fit rounded-md whitespace-nowrap cursor-pointer"
          >
            <LuGalleryVerticalEnd className="text-base" />
            Select from Files
          </li>
          <li
            onClick={() => setCameraModalOpen(true)}
            className="flex items-center gap-2 bg-yellowish text-blackishV2 px-2.5 py-2 w-fit rounded-md whitespace-nowrap cursor-pointer"
          >
            <IoCamera className="text-base" />
            Open Camera
          </li>
        </ul>
      </div>

      <WebCamModal
        isOpen={cameraModalOpen}
        onClose={() => {
          setCameraModalOpen(false);
        }}
        type="image"
        onCaptureImage={(file) => handleImageCapture(file)}
      />
    </>
  );
};

export default UploadImageButton;
